import { Field, FieldArray, Form, useFormikContext } from "formik";

const IpoDrhpForm = ({ result, loading }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Form id="myForm" className=" mt-3">
      {result !== undefined &&
        result.id !== undefined &&
        (
          <>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">
                ID:
              </label>
              <div className="col-lg-4">{result.id}</div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">
                Company Name:
              </label>
              <div className="col-lg-4">{result.ipo[0].company_name}</div>
            </div>
          </>
        )}
      <table className="table table-striped table-bordered text-center align-middle">
        <thead className="table-dark">
          <tr>
            <th style={{ color: "white", backgroundColor: "grey" }}>ID</th>
            <th style={{ color: "white", backgroundColor: "grey" }}>
              DRHP Code
            </th>
            <th style={{ color: "white", backgroundColor: "grey" }}>
              DRHP Date
            </th>
            <th style={{ color: "white", backgroundColor: "grey" }}>
              Display Status
            </th>
            <th style={{ color: "white", backgroundColor: "grey" }}>Action</th>
          </tr>
        </thead>
        <FieldArray
          name="ipoDRHPData"
          render={({ push, remove }) => (
            <>
              <tbody>
                {values.ipoDRHPData &&
                  values.ipoDRHPData.map((peer_group, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Field
                          name={`ipoDRHPData.${index}.drhp_code`}
                          as="select"
                          className="form-select"
                        >
                          <option value="">Select Code</option>
                          {result &&
                            result.dhrpCurrentStatusList &&
                            result.dhrpCurrentStatusList.map((item) => (
                              <option key={item.code} value={item.code}>

                                {item.code_desc}
                              </option>
                            ))}
                          {/* Add options dynamically if needed */}
                        </Field>
                      </td>
                      <td>
                        <Field
                          name={`ipoDRHPData.${index}.drhp_code_date`}
                          type="date"
                          className="form-control"
                        />
                      </td>
                      <td>
                        <Field
                          name={`ipoDRHPData.${index}.display_status`}
                          onChange={(e) => {
                            setFieldValue(
                              `ipoDRHPData.${index}.display_status`,
                              e.target.checked
                            );
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => remove(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="9" className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm text-center mt-2 mb-2"
                      onClick={() =>
                        push({
                          drhp_code: 0,
                          drhp_code_date: "",
                          display_status: "",
                        })
                      }
                    >
                      Add +
                    </button>
                  </td>
                </tr>
              </tfoot>
            </>
          )}
        />
      </table>
      <div className="mb-3 text-center">
        {result?.ipo?.[0]?.lock_status === 0 && (
          <button type="submit" disabled={loading} className="btn btn-primary">
            Save Data
          </button>
        )}
      </div>
    </Form>
  );
};
export default IpoDrhpForm;
