import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import FinancialForm from './FinancialForm';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings } from '../Common/CommonFunction';
import IPOPageTabs from '../IPONew/IPONewPageTabs';
import NCDTabs from '../NCD/NCDTabs';
import BBTabs from '../Buyback/BBTabs';
import RITabs from '../RightIssue/RITabs.js';
import OFSTabs from '../OFS/OFSTabs.js';


const Financial = () => {

    const validationSchema = Yup.object({
    });

    const validate = (e) => {
        for (let i = 0; i < e.financial.length; i++) {
            if (e.financial[i].financial_date == "") {
                alert(`Date at Sr No.${i + 1} cannot be blank`);
                return false;
            }
            if (new Date(e.financial[i].financial_date) > new Date()) {
                alert(`Date cannot be greater than today's date.`);
                return false;
            }
        }
        return true;
    }


    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { category, id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const previousValues = useRef({});

    const [initialValues, setInitialValues] = useState({
        id: "",
        category_cd: "",
        digitInput: "",
        financial_stmt: "Restated Consolidated",
        issue_category: "",
        ipo_confirmation_status: false,
        lock_status: false,
        financial: [
            { total_assets: '', financial_date: '', total_revenue: '', profit_after_tax: '', net_worth: '', reserves_and_surplus: '', total_borrowing: '', display_status: false },
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Edit Financial" })

            api.post('/admin-financial-read', {
                id: id,
                category: category
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);

                    const initialValues = {};
                    response.data.financialData.forEach((financial, index) => {
                        initialValues[`financial.${index}.total_assets`] = financial.total_assets;
                        initialValues[`financial.${index}.total_revenue`] = financial.total_revenue;
                        initialValues[`financial.${index}.profit_after_tax`] = financial.profit_after_tax;
                        initialValues[`financial.${index}.net_worth`] = financial.net_worth;
                        initialValues[`financial.${index}.reserves_and_surplus`] = financial.reserves_and_surplus;
                        initialValues[`financial.${index}.total_borrowing`] = financial.total_borrowing;
                    });
                    previousValues.current = initialValues;

                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        digitInput: response.data.topic[0].issue_category == "SME" ? 100000 : 1000000,
                        category_cd: response.data.category,
                        financial_stmt: response.data.financialData[0] !== undefined ? response.data.financialData[0].financial_stmt : "Restated Consolidated",
                        financial: response.data.financialData
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);

        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());

            api.post('/admin-financial-save', {
                values: trimmedValues
            }
            ).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
        }
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {category == "ipo" && <IPOPageTabs idAtr={id} ipo_confirm={result?.topic?.[0]?.ipo_confirmation_status} />}
            {category == "ncd" && <NCDTabs idAtr={id} />}
            {category == "ri" && <RITabs idAtr={id} />}
            {category == "bb" && <BBTabs idAtr={id} />}
            {category == "ofs" && <OFSTabs idAtr={id} />}

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.topic !== undefined &&
                result.topic.length > 0 &&
                result.topic[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                    </>
                )}

            {result !== undefined && result.topic !== undefined && result.topic.length > 0 &&
                <>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {id}
                        </div>
                    </div>
                    <div className="my-3 row">
                        <label htmlFor="" className="col-2">Company Name:</label>
                        <div className="col-8">
                            <b>{result.topic[0].company_name}</b>
                        </div>
                    </div>
                </>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form id='myForm'>
                        <div className="row mb-3">
                            <label htmlFor="" className="col-2">Number input in:</label>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput1" value="1" tabIndex={1} checked={values.digitInput === 1}
                                        onChange={(e) => { setFieldValue(e.target.name, 1) }} />
                                    <label className="form-check-label" htmlFor="digitInput1">
                                        Normal Digits
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput5" value="1000" tabIndex={5} checked={values.digitInput === 1000}
                                        onChange={(e) => { setFieldValue(e.target.name, 1000) }} />
                                    <label className="form-check-label" htmlFor="digitInput5">
                                        In Thousands
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput4" value="100000" tabIndex={10} checked={values.digitInput === 100000}
                                        onChange={(e) => { setFieldValue(e.target.name, 100000) }} />
                                    <label className="form-check-label" htmlFor="digitInput4">
                                        In Lakhs
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput2" value="1000000" tabIndex={15} checked={values.digitInput === 1000000}
                                        onChange={(e) => { setFieldValue(e.target.name, 1000000) }} />
                                    <label className="form-check-label" htmlFor="digitInput2">
                                        In Millions
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput3" value="10000000" tabIndex={20} checked={values.digitInput === 10000000}
                                        onChange={(e) => { setFieldValue(e.target.name, 10000000) }} />
                                    <label className="form-check-label" htmlFor="digitInput3">
                                        In Crores
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 row" id="Financial_div">
                            <label htmlFor="financial_stmt" className="col-lg-2 ">Financial Statement:</label>
                            <div className="col-lg-6">
                                {result.financialStatements !== undefined && result.financialStatements.map((option, index) => (
                                    <div className='d-inline' key={option.code} >
                                        <Field type="radio" className="form-check-input" name="financial_stmt" value={option.code} id={`financial_stmt_${index}`} tabIndex={25} />
                                        <label className="form-check-label" htmlFor={`financial_stmt_${index}`}>
                                            &nbsp;&nbsp;{option.code_desc}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <table className="table table-bordered table-striped" id="repeater1" tabIndex={30}>
                            <thead>
                                <tr className="bg-secondary text-white">
                                    <th style={{ width: "190px" }}>Date (Period Ended) (DD/MM/YYYY)</th>
                                    <th>Total Assets</th>
                                    <th>Total Revenue</th>
                                    <th>Profit after tax</th>
                                    <th>Net Worth</th>
                                    <th>Reserves and Surplus</th>
                                    <th>Total Borrowing</th>
                                    <th>Display Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>


                            <FieldArray
                                name="financial"
                                render={({ push, remove }) => (
                                    <>
                                        <tbody>
                                            <FinancialForm remove={remove} previousValues={previousValues} />
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="9" className="text-end">
                                                    <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({ total_assets: '', financial_date: '', total_revenue: '', profit_after_tax: '', net_worth: '', reserves_and_surplus: '', total_borrowing: '', display_status: true })}>
                                                        Add +
                                                    </button>
                                                </td></tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </table>

                        <div className="mb-3 text-center">
                            {result !== undefined && result.topic !== undefined && result.topic.length > 0 && result.topic[0].lock_status !== 1 && (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={35}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        Update
                                    </span>
                                </button>
                            )}

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={40}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>
                )}
            </Formik>


        </>
    )
}

export default Financial
