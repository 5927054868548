import React, { useEffect, useRef } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import FileUpload from '../../admintool9x/Common/FileUpload';
import { useScript, useStyle } from '../../admintool9x/Common/CommonFunction';
import { Helmet } from 'react-helmet-async';
import useApi from '../../../common/useApi';
import { debounce } from 'lodash';
// import ReCAPTCHA from 'react-google-recaptcha';
// import { funCreateSlug } from '../Common/CommonFunction';
// import CharacterCountInput from '../Common/CharacterCountInput';
// import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const IPOPostCommentForm = ({ result, btnClick, loading }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();

    const inputRef = useRef(null);
    const tagifyInstance = useRef(null);
    let abortController;
    const api = useApi();
    useStyle("https://cdn.jsdelivr.net/npm/@yaireo/tagify/dist/tagify.css", "tagify-style");
    useScript("https://cdn.jsdelivr.net/npm/@yaireo/tagify", "tagify-script");
    useScript("https://cdn.jsdelivr.net/npm/@yaireo/tagify/dist/tagify.polyfills.min.js", "tagify-polyfills");

    useEffect(() => {
        const checkTagify = setInterval(() => {
            if (window.Tagify) {
                clearInterval(checkTagify);
                initializeTagify();
            }
            // recaptchaRef.current.execute();

        }, 100);

        return () => {
            clearInterval(checkTagify);
            if (tagifyInstance.current) {
                tagifyInstance.current.destroy();
                tagifyInstance.current = null;
            }
            if (abortController) {
                abortController.abort(); // Clear the abort controller on cleanup
                abortController = null;
            }
        };
    }, []);

    const initializeTagify = () => {
        const input = inputRef.current;
        if (!input) return;

        tagifyInstance.current = new window.Tagify(input, {
            whitelist: [],
            mode: 'mix',
            pattern: /@/,
            enforceWhitelist: true,
            dropdown: {
                enabled: 1,
                position: 'text',
                mapValueTo: 'text',
                highlightFirst: true,
            },
        });

        tagifyInstance.current.on('input', onInput);
    };

    const onInput = debounce((e) => {
        const prefix = e.detail.prefix;
        const value = e.detail.value;

        if (value && !value.trim()) {
            tagifyInstance.current.loading(false);
            return;
        }

        if (prefix) {
            // console.log(`${prefix} -- ${value}`);

            if (value.startsWith(' ')) {
                return;
            }

            tagifyInstance.current.loading(false);
            tagifyInstance.current.whitelist = null; // reset the whitelist

            if ((value === '@' || prefix === '@') && value.slice(-1) !== '@') {
                if (value !== "" && value.length > 2) {
                    tagifyInstance.current.loading(true).dropdown.hide();
                    if (abortController) {
                        abortController.abort();
                    }
                    abortController = new AbortController();

                    api.post('/tag-member-search', {
                        search: value
                    }).then((response) => {
                        tagifyInstance.current.whitelist = response.data.memberList; // update whitelist Array in-place
                        tagifyInstance.current.loading(false).dropdown.show(value); // render the suggestions dropdown
                    })
                        .catch(error => {
                            tagifyInstance.current.loading(false);
                            if (error.name === 'AbortError') {
                                console.log('Fetch aborted'); // Handle the abort gracefully
                            } else {
                                console.error('Error fetching data:', error);
                            }
                        });
                }
            }
        }
    }, 300); // Debounce with 300ms delay

    return (
        <>
            <Helmet>
                <style>
                    {`
                        @media screen and (min-width: 800px) {
                            .img-feedback {
                                width: 300px;
                            }
                        }

                        @media screen and (max-width: 450px) {
                            .tagify__dropdown {
                                left: 20% !important;
                                width: auto !important;
                                /* top: 25% !important; */
                            }
                        }

                        .tagify--mix.form-control {
                            min-height: 200px;
                        }

                        body.darkpagetheme .tagify--mix.form-control {
                            background: #102f4e;
                            color: white;
                        }

                        body.darkpagetheme .tagify--empty .tagify__input::before {
                            color: #918e8e;
                        }

                        body.darkpagetheme .tagify__dropdown__wrapper {
                            background: #1d2a35 !important;
                            color: white;
                        }`
                    }
                </style>
            </Helmet>
            <Form id='myForm'>
                {loading ? (
                    <div
                        className="spinner-container d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }} // Full viewport height for vertical centering
                    >
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    result && result.parentMessages && result.parentMessages.length > 0 && (
                        <div className="card mt-2">
                            <div className="card-header">
                                <h3 className="mb-0">Parent Message</h3>
                            </div>
                            <div className="card-body">
                                {result.parentMessages.map((option) => (
                                    <div key={option.id} style={option.parent_message_id === 0 ? null : { paddingLeft: "25px" }}>
                                        <h3>{option.posted_by_name} &nbsp;&nbsp; (<small>{option.date_posted}</small>)</h3>
                                        {option.rank_title_ipo != "" &&
                                            <p className='mb-1' dangerouslySetInnerHTML={{ __html: option.rank_title_ipo }}></p>
                                        }
                                        <p className='mb-2'>For: <b>{option.company_name}</b></p>
                                        <p className='mb-2' dangerouslySetInnerHTML={{ __html: option.message }}></p>
                                        <hr className='mt-2 mb-3' style={{ color: "#bfbfbf" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )}
                <div className="my-2 row">
                    <label htmlFor="IPO_ID" className="col-sm-2">IPO</label>
                    <div className="col-sm-10">
                        <Field as="select" name="ipo_id" className="form-select">
                            <option className="text-danger" value={""}>Select Company Name...</option>
                            {result.ipoList && result.ipoList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.company_name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="controls">
                        <Field as="textarea"
                            id="message_content"
                            rows="7"
                            cols="70"
                            name="message"
                            className="form-control"
                            placeholder="Your Message"
                            innerRef={inputRef}
                        />
                    </div>
                </div>

                <div className="mb-2 row">
                    <label htmlFor="FILE2" className="col-md-2 col-4">Add Image</label>
                    <div className="col-md-4 col-8">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_file_name" hideOverwrite="1" fileConfirmation={0} />
                        <small>(jpg, png, jpeg, gif, bmp)</small>
                    </div>
                </div>
                {/* <div className="mb-2 row">
                    <label htmlFor="" className="col-md-2">
                        Verify Code
                    </label>
                    <div className="col-md-3">
                        <span id="divLoading">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size='invisible'
                                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                onChange={(value) => setFieldValue('recaptcha', value)}
                            />
                        </span>
                    </div>
                </div> */}

                <div className="mb-2 row">
                    <label>
                        <Field className="form-check-input" type="checkbox" name="track_replies" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('track_replies', newValue);
                        }} /> <mark>Track replies</mark>
                    </label>
                    <small style={{ color: '#828282' }}>(Get an email when someone responds to this message in 30 days.)</small>
                </div>

                <hr />
                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Post Comment
                        </span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={btnClick}
                    >Close</button>
                </div>
            </Form>
        </>
    );
}

export default IPOPostCommentForm;
