import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPORecommendationForm from './IPORecommendationForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow, validateId, validateInput } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const IPORecommendation = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ipo_id = queryParams.get("a") ?? "";
    var main_ref = queryParams.get("main_ref") ?? "";

    // const recaptchaRef = useRef(null);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState({
        memberRecommendationDetails: {},
        ipoList: [],
        recommendationType: [],
        subscribeAtPriceBand: [],
        holdPosition: [],
        pageDataLoaded: 0,
        ipo_id: 0
    });
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);

    const validationSchema = Yup.object({
        ipo_id: Yup.string().trim().required('Issuer Company is required'),
        recommendation: Yup.string().trim().required('Your Recommendation is required'),
        recommendation_desc: Yup.string().trim().required('Your rationals behind your recommendation is required'),
        // recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
    });

    const [initialValues, setInitialValues] = useState({
        id: "",
        ipo_id: "",
        recommendation: "",
        posted_date: "",
        last_updated_date: "",
        hold_position: "",
        subscribe_at_price_band: "",
        recommendation_desc: "",
        editbtn: 0,
        // recaptcha: '',
    });

    const btnClick = () => {
        if (main_ref != "") {
            funCloseWebWindow(main_ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            if (!validateId(ipo_id)) {
                ipo_id = 0;
            }
            getData(ipo_id);
        } else {
            didMountRef.current = "true";
        }
    }, [dispatch, api]);

    const getData = (currentIPOId) => {
        dispatch(startLoading());

        setMetaData({ "title": "My Recommendation Manager" });

        api.post('member-ipoRecommendation-read', {
            ipo_id: currentIPOId
        })
            .then(response => {
                if (response.data.msg === 1) {
                    if (response.data.memberRecommendationDetails.length > 0) {
                        setInitialValues(response.data.memberRecommendationDetails[0]);
                    } else {
                        setInitialValues({
                            id: "",
                            ipo_id: ipo_id,
                            recommendation: "",
                            posted_date: "",
                            last_updated_date: "",
                            hold_position: "",
                            subscribe_at_price_band: "",
                            recommendation_desc: "",
                            editbtn: 0,
                            // recaptcha: '',
                        });
                    }
                    setResult((prevState) => ({
                        ...prevState,
                        memberRecommendationDetails: response.data.memberRecommendationDetails[0],
                        ipoList: response.data.ipoList,
                        recommendationType: response.data.recommendationType,
                        subscribeAtPriceBand: response.data.subscribeAtPriceBand,
                        holdPosition: response.data.holdPosition,
                        pageDataLoaded: 1
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }
    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            getData(result.ipo_id);
        }
    }, [result.ipo_id]);

    const handleSubmit = async (values, { setSubmitting }) => {
        if (values.recommendation == "subscribe" || values.recommendation == "mayapply") {
            if (values.hold_position == "") {
                dispatch(setMessage('Hold Position is required'));
                setSubmitting(false);
                return;
            }
            if (values.subscribe_at_price_band == "") {
                dispatch(setMessage('Subscribe at Price Band is required'));
                setSubmitting(false);
                return;
            }
        }

        // const token = recaptchaRef.current.getValue();
        // if (!token) {
        //     alert('Please complete the reCAPTCHA');
        //     setSubmitting(false);
        //     return;
        // }
        // recaptchaRef.current.reset();
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // trimmedValues.recaptchaToken = token;

        dispatch(startLoading());

        api.post('member-iporecommendation-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=recommendation&main_ref=` + main_ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h2>
                Post Your Recommendations
            </h2>
            <hr />


            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IPORecommendationForm result={result} setResult={setResult} btnClick={btnClick} loading={loading} initialValues={initialValues} currentUser={currentUser} />
            </Formik>


        </>
    )
}

export default IPORecommendation
