import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const ErrorReportForm = ({ result, handleGoBack, loading }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.errorreport !== undefined && result.errorreport.length > 0 &&
                  <div className="mb-3 row">
                    <label htmlhtmlhtmlFor="" className="col-lg-2">ID:</label>
                    <div className="col-lg-4">
                      {result.errorreport[0].id}
                    </div>
                  </div>
                }

                <div className="mb-3 row">
                    <label htmlhtmlFor="" className="col-lg-2 required">Referrer Page</label>
                    <div className="col-lg-4">
                      <Field className="form-control" type="text" maxLength="200" tabIndex={1} name="referrer_page" readOnly={true}/>
                    </div>

                    <label htmlhtmlFor="" className="col-lg-2 required">Page URL</label>
                    <div className="col-lg-4">
                      <Field className="form-control" type="text" maxLength="200" tabIndex={5} name="page_url" readOnly={true}/>
                    </div>
                </div>

                <div className="mb-3 row">
                  <label htmlhtmlFor="" className="col-lg-2 required">IP Address</label>
                  <div className="col-lg-4">
                    <Field className="form-control" type="text" maxLength="50" tabIndex={10} name="ip_address" readOnly={true}/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label htmlhtmlFor="" className="col-lg-2 required">Error Message</label>
                  <div className="col-lg-10">
                    <CharacterCountTextarea
                      className="form-control"
                      nameattr="error_message"
                      type="textarea"
                      tabIndex={15}
                      rows="5"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label htmlhtmlFor="" className="col-lg-2 required">Error Source</label>
                  <div className="col-lg-4">
                    <Field className="form-control" type="text" maxLength="400" tabIndex={20} name="error_source" readOnly={true}/>
                  </div>

                  <label htmlhtmlFor="" className="col-lg-2 required">Error Type</label>
                  <div className="col-lg-4">
                    <Field className="form-control" type="text" maxLength="25" tabIndex={25} name="error_type" readOnly={true}/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label htmlhtmlFor="" className="col-lg-2">Error Time</label>
                  <div className="col-lg-4">
                    <Field className="form-control" type='text' tabIndex={30} name="error_time" readOnly={true}/>
                  </div>

                  <label htmlhtmlFor="" className="col-lg-2">Display Status</label>
                  <div className="col-lg-4">
                    <Field className="form-check-input" type="checkbox" tabIndex={35} name="display_status" onChange={(e) => {
                        const newValue = e.target.checked;
                        setFieldValue('display_status', newValue);
                    }}/>
                  </div>
                </div>
                <hr />
                <div className="mb-3 row">
                  <label htmlFor="error_resolution_status" className="col-lg-2">Error Resloution Status</label>
                  <div className="col-lg-4">
                    <Field as="select" name="error_resolution_status" className="form-select" tabIndex={40}>
                  <option value="">--SELECT--</option>
                  {result && result.errorResolutionStatusList && result.errorResolutionStatusList.length !== 0 && result.errorResolutionStatusList.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="admin_comment" className="col-lg-2">Comment</label>
                  <div className="col-lg-10">
                    <CharacterCountTextarea
                      className="form-control"
                      nameattr="admin_comment"
                      maxLength="1000"
                      type="textarea"
                      tabIndex={45}
                      rows="5"
                    />
                  </div>
                </div>
              
                <div className="mb-3 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={loading}
                    tabIndex={50}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )}
                    <span>
                        {'Update'}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-block ms-2"
                    onClick={handleGoBack}
                    tabIndex={55}
                  >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default ErrorReportForm
