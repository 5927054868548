import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import FaqPageForm from './FaqPageForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { funNoSpaces } from '../Common/CommonFunction';


const validationSchema = Yup.object({
    request_type_id: Yup.string().trim().required('Plese select the request type').test(funNoSpaces('Request Type Id')),
    topic_id: Yup.string().required('Topic id is required').test(funNoSpaces('Topic Id')),
    question: Yup.string().trim().required('Please enter the question'),
    answer: Yup.string().trim().required('Please enter the answer'),
    urlrewrite_folder_name: Yup.string().trim().required('urlrewrite Folder Name is required'),
    display_order: Yup.number().typeError('Display Order should be numeric')
        .integer('Display Order should be a whole number')
        .required('Please enter the display order').test(funNoSpaces('Display Order'))

});

const FaqPage = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        request_type_id: "",
        topic_id: "",
        topic_id_2: "",
        question: "",
        answer: "",
        urlrewrite_folder_name: "",
        image_name: "",
        image_width: "",
        image_height: "",
        report_ids: "",
        display_order: "",
        lock_status: false,
        display_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/2");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New FAQ" })
            } else {
                setMetaData({ "title": "Edit FAQ" })
            }
            api.post('/admin-faqpage-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.faqpage);
                    if (!isAddMode) {
                        setInitialValues(response.data.faqpage[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-faqpage-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/2");
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });

        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.faqpage !== undefined &&
                result.faqpage.length > 0 &&
                result.faqpage[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                    </>
                )}

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <FaqPageForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} />
            </Formik>
        </>
    )
}

export default FaqPage
