import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings } from '../Common/CommonFunction';
import AutocompleteField from '../Common/AutocompleteField';
import BuybackHistoryForm from './BuybackHistoryForm';

const BuybackHistory = () => {

    const validationSchema = Yup.object({
    });

    const validate = (e) => {
        console.log("Compant Name is : ", companyName)
        if (id == undefined && companyName == "") {
            alert("Company name is required...please select it from the autofill field")
            return false;
        }
        for (let i = 0; i < e.buybackHistoryChild.length; i++) {
            console.log("The length is:", e.buybackHistoryChild.length)
            if (!e.buybackHistoryChild[i].bb_dt) {
                alert(`Date in the row ${i + 1} cannot be blank`);
                return false;
            }
            else if (!e.buybackHistoryChild[i].bb_share_capital) {
                alert(`Shared capital bought back % in the row ${i + 1} cannot be blank`);
                return false;
            }
            else if (!e.buybackHistoryChild[i].bb_price) {
                alert(`Share buyback price in the row ${i + 1} cannot be blank`);
                return false;
            }
            else if (!e.buybackHistoryChild[i].bb_total_amt) {
                alert(`Total buyback amount in the row ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();

    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        list_of_script_id: "",
        display_status: false,
        company_name_auto: "",
        buybackHistoryChild: [
            { bb_dt: '', bb_share_capital: '', bb_price: '', bb_total_amt: '', bb_acceptance_ratio: '', display_status: '' },
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (id) setMetaData({ "title": "Edit BuyBack History" })
            else setMetaData({ "title": "Add BuyBack History" })
            if (id) {
                api.post('/admin-buybackhistory-read', {
                    id: id,
                }).then(response => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        console.log(response.data);
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: response.data.id,
                            list_of_script_id: response.data.buybackHistory[0] !== undefined ? response.data.buybackHistory[0].list_of_script_id : "",
                            buybackHistoryChild: response.data.buybackHistoryChild
                        }));
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                }).catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                }).finally(() => {
                    dispatch(finishLoading());
                });
            }
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);
        if (!validate(trimmedValues)) return;

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-buybackhistory-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        setSubmitting(false);
    };

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

    const [formState, setFormState] = useState({
        autoComplteOptionsCompany: [],
        isAutocompleteCompanyLoading: false,
        issueSizeInShareInCrore: "",
        issueSizeInAmtInCrore: "",
        checkOpenAndCloseDate: ""
    });

    const handleAutoSearchCompany = async (query) => {
        setFormState((prevState) => ({
            ...prevState,
            isAutocompleteCompanyLoading: true,
        }));


        api.post('/admin-stock-list', {
            search: query,
            type: "history"
        }).then(response => {
            if (response.data.msg === 1) {
                let result_array = response.data.list.map((value) => Object.values(value).join(' | '));
                setFormState((prevState) => ({
                    ...prevState,
                    autoComplteOptionsCompany: result_array,
                }));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
            setFormState((prevState) => ({
                ...prevState,
                isAutocompleteCompanyLoading: false,
            }));
        });
    };

    const [companyName, setCompanyName] = useState('');
    const handleAutoSearchCompanySelect = (selected) => {
        if (selected != "" && selected !== undefined) {

            const companyName = selected.split('|')[1].trim();
            setCompanyName(companyName);

            setInitialValues((prevValues) => ({
                ...prevValues,
                list_of_script_id: selected.split('|')[0].trim()
            }));
        } else {
            setCompanyName('')
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined && result.listOfScript !== undefined && result.listOfScript.length > 0 &&
                <>
                    <div className="mb-3 row">
                        <label className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.buybackHistory[0].id}
                        </div>
                    </div>
                    <div className="my-3 row">
                        <label className="col-2">Company Name:</label>
                        <div className="col-8">
                            <b>{result.listOfScript[0].company_name}</b>
                        </div>
                    </div>
                </>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form id='myForm'>

                    {(id == null || id === undefined) && (
                        <div className="mb-3 row">
                            <label htmlFor="company_name_auto" className="col-lg-2">Company Name Search</label>
                            <div className="col-lg-8">
                                <Field
                                    name="company_name_auto"
                                    as={AutocompleteField}
                                    options={formState.autoComplteOptionsCompany}
                                    onInputChange={(query) => handleAutoSearchCompany(trimValue(query))}
                                    onInputSelect={handleAutoSearchCompanySelect}
                                    onChange={(e) => handleAutoSearchCompanySelect(e.target.value)}
                                    tabIndex={1}
                                    isAutocomplteLoading={formState.isAutocompleteCompanyLoading}
                                    nameatr="company_name_auto"
                                    idatr="company_name_auto_txt"
                                />
                            </div>

                            <div className="mt-3 row">
                                <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
                                <div className="col-lg-4">
                                    <Field as="input" name="company_name" className="form-control" tabIndex={5} maxLength="100" value={companyName} readOnly />
                                </div>
                            </div>
                        </div>
                    )}

                    <table className="table table-bordered table-striped" id="repeater1" tabIndex={10}>
                        <thead>
                            <tr className="bg-secondary text-white">
                                <th style={{ width: "20%" }}>Date (Period Ended) (DD/MM/YYYY)</th>
                                <th>Share paid-up capital bought back (%)</th>
                                <th>Share Buyback price In Rs.</th>
                                <th>Total Buyback Amount In Rs.</th>
                                <th>Acceptance Ratio (Retail %)</th>
                                <th>Display Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <FieldArray
                            name="buybackHistoryChild"
                            render={({ push, remove }) => (
                                <>
                                    <tbody>
                                        <BuybackHistoryForm remove={remove} />
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="9" className="text-end">
                                                <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({ bb_dt: '', bb_share_capital: '', bb_price: '', bb_total_amt: '', bb_acceptance_ratio: '', display_status: '' })}>
                                                    Add +
                                                </button>
                                            </td></tr>
                                    </tfoot>
                                </>
                            )}
                        />
                    </table>

                    <div className="mb-3 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={20}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.buybackHistoryChild !== undefined && result.buybackHistoryChild.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>

                        <button
                            type="button"
                            className="btn btn-secondary btn-block ms-2"
                            onClick={handleGoBack}
                            tabIndex={25}
                        >Cancel</button>
                    </div>


                    <div className="row">
                        <div className="col-6">
                            {result !== undefined && result.buyback !== undefined && (
                                <table className="table table-bordered table-striped" >
                                    <thead>
                                        <tr className="bg-secondary text-white">
                                            <th style={{ width: '10%' }}>Id</th>
                                            <th style={{ width: "60%" }}>Company Name</th>
                                            <th style={{ width: "30%" }}>Date Added</th>
                                        </tr>
                                    </thead>
                                    {result.buyback.length > 0 && result.buyback[0].id !== undefined && result.buyback[0].company_name !== undefined && result.buyback[0].date_added !== undefined && (
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a className="content" href="#" onClick={() => navigate(`/admintool9x/buyback/${result.buyback[0].id}/`)} >
                                                        <div>{result.buyback[0].id}</div>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a className="content" href={"http://localhost:3000/admintool9x/buybackhistory/1/"} > <div>{result.buyback[0].company_name}</div> </a>
                                                </td>
                                                <td>
                                                    <p>{result.buyback[0].date_added}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            )}
                        </div>
                    </div>
                    <ValidateFormFields />
                </Form>
            </Formik>


        </>
    )
}

export default BuybackHistory
