import { Field, Form, useFormikContext, FieldArray } from 'formik';
import React, { useState, useEffect } from 'react';
import ValidateFormFields from '../Common/ValidateFormFields';
import ReportCDNDataComponent from './ReportCDNDataComponent';

const ReportCDNForm = ({ result, handleGoBack, initialValues, loading, formState, setFormState, isAddMode, numberOfReports, setnumberOfReports }) => {
  const { values, setFieldValue } = useFormikContext();

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (values.category_cd == 55) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ipoDisabled: false
      }));
      setFieldValue('ncd_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is IPO, IPO field is mandatory!")
    }
    if (values.category_cd == 56) {
      setFormState((prevState) => ({
        ...prevState,
        ipoDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ncdDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is NCD, NCD field is mandatory!")
    }
    if (values.category_cd == 83) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        ipoDisabled: true,
        bbDisabled: true,
        riDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ncd_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is RI, RI field is mandatory!")
    }
    if (values.category_cd == 57) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        ipoDisabled: true,
        bbDisabled: true
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('ncd_id', "");
      setFieldValue('bb_id', "");
    }
    if (values.category_cd == 84) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        ipoDisabled: true,
        bbDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('ncd_id', "");
      // alert("Since the chosen category is BB, BB field is mandatory!")
    }
  }, [values.category_cd])

  function funSetReportTitle(selectedOption, author_name = "", index = -1) {
    const repeater_count = values.ReportCDNData.length;

    for (let i = 0; i < repeater_count; i++) {
      var sAuthor = "";
      if (index === i) {
        sAuthor = author_name;
      } else {
        sAuthor = values.ReportCDNData[i].author_name;
      }

      setFieldValue(`ReportCDNData.${i}.title`, `${selectedOption} Analysis by ${sAuthor}`);
    }
  }

  return (
    <>
      <Form id='myForm'>
        <fieldset>
          {result !== undefined && result.reportCDN !== undefined && result.reportCDN.length > 0 &&
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">ID:</label>
              <div className="col-lg-4">
                {result.reportCDN[0].id}
              </div>
            </div>
          }

          <div className="mb-3 row">
            <label htmlFor="category_cd" className="col-lg-2 required">Category</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={5} name="category_cd" >
                <option value=""></option>
                {result.categoryList !== undefined && result.categoryList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.cat_name}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="ipo_id" className="col-lg-2 required">IPO Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={10} name="ipo_id" disabled={formState.ipoDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  const selectedOption = result.ipoList.find(option => option.id == e.target.value).ipo_news_title;
                  setFieldValue('ipo_id', e.target.value);
                  setCompanyName(selectedOption);
                  funSetReportTitle(selectedOption);
                }}>
                <option value=""></option>
                {result.ipoList !== undefined && result.ipoList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.ipo_news_title}
                  </option>
                ))}
              </Field >
            </div>

            <label htmlFor="ncd_id" className="col-lg-2 required">NCD Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={15} name="ncd_id" disabled={formState.ncdDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  const selectedOption = result.ncdList.find(option => option.id == e.target.value).news_title;
                  setFieldValue('ncd_id', e.target.value);
                  setCompanyName(selectedOption);
                  funSetReportTitle(selectedOption);
                }}>
                <option value=""></option>
                {result.ncdList !== undefined && result.ncdList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.news_title}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="ri_id" className="col-lg-2 required">RI Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={20} name="ri_id" disabled={formState.riDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  const selectedOption = result.riList.find(option => option.id == e.target.value).news_title;
                  setFieldValue('ri_id', e.target.value);
                  setCompanyName(selectedOption);
                  funSetReportTitle(selectedOption);
                }}>
                <option value=""></option>
                {result.riList !== undefined && result.riList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.news_title}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="bb_id" className="col-lg-2 required">BB Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={25} name="bb_id" disabled={formState.bbDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  const selectedOption = result.bbList.find(option => option.id == e.target.value).news_title;
                  setFieldValue('bb_id', e.target.value);
                  setCompanyName(selectedOption);
                  funSetReportTitle(selectedOption);
                }}>
                <option value=""></option>
                {result.bbList !== undefined && result.bbList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.news_title}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </fieldset>
        <hr />

        {/* {isAddMode || !isAddMode && ( */}
        <fieldset id="repeater1">
          <FieldArray
            name="ReportCDNData"
            render={({ push, remove }) => (
              <>
                <ReportCDNDataComponent remove={remove} result={result} companyName={companyName} numberOfReports={numberOfReports} setnumberOfReports={setnumberOfReports} funSetReportTitle={funSetReportTitle} setFieldValue={setFieldValue} />
                {isAddMode && (
                  <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => {
                    push({
                      title: "",
                      file_name: "",
                      report_link: "",
                      author_name: "",
                      recommendation: ""
                    })
                    setnumberOfReports(values.ReportCDNData.length + 1)
                  }}>
                    Add +
                  </button>
                )}
              </>
            )}
          />
        </fieldset>
        {/* )} */}
        <br />
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={55} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
          <label htmlFor="lock_status" className="col-lg-2 ">Lock Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={60} name="lock_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('lock_status', newValue);
            }} />
          </div>
        </div>

        <div className="mb-3 text-center">
          {!initialValues.lock_status == 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={65}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.reportCDN !== undefined && result.reportCDN.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={70}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>

    </>
  )
}

export default ReportCDNForm
