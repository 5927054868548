import React, { useEffect, useState } from "react";
import { useFormikContext, Field, Form } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import CheckboxGroup from "../Common/CheckboxGroup";
import NumberInput from "../Common/NumberInput";
import NumberDecimalInput from "../Common/NumberDecimalInput";

const IpoListingNewForm = ({
  result,
  handleGoBack,
  loading,
  initialValues,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [formState, setFormState] = useState({
    nseReadOnly: false,
    bseReadOnly: false,
  });

  useEffect(() => {
    let arr = values.ipo_listing_at_array;
    if (arr.includes("NSE") || arr.includes("NSE SME")) {
      setFormState((prevState) => ({
        ...prevState,
        nseReadOnly: false,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        nseReadOnly: true,
      }));
    }
    if (arr.includes("BSE") || arr.includes("BSE SME")) {
      setFormState((prevState) => ({
        ...prevState,
        bseReadOnly: false,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        bseReadOnly: true,
      }));
    }
  }, [values.ipo_listing_at]);

  return (
    <>
      <Form id="myForm">
        <div className="my-3 row">
          <label htmlFor="company_name" className="col-lg-2">
            IPO
          </label>
          <div className="col-lg-10">
            <div>{values.company_name}</div>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="current_company_name" className="col-lg-2">
            Current Company Name
          </label>
          <div className="col-lg-4">
            <Field
              className="form-control"
              type="text"
              maxLength="100"
              name="current_company_name"
              tabIndex={1}
            />
          </div>
        </div>
        <div className="mb-4 row">
          <label htmlFor="ipo_listing_at_array" className="col-lg-2">
            IPO Listing At
          </label>
          <div className="col-lg-4">
            {result.issueStockExchangeList !== undefined && (
              <Field
                name="ipo_listing_at_array"
                label="IPO Listing At"
                component={CheckboxGroup}
                options={result.issueStockExchangeList}
                className="form-check-input Stock_Exchange_Type_Checkbox me-2"
                tabIndex={5}
                attrDisabled={true}
              />
            )}
          </div>
        </div>
        <div className="mb-4 row">
          <label htmlFor="issue_close_date" className="col-lg-2">
            IPO Closing Date
          </label>
          <div className="col-lg-4">
            <Field
              className="form-control"
              type="date"
              name="issue_close_date"
              title="YYYY/MM/DD"
              tabIndex={10}
              readOnly
            />
          </div>
        </div>
        <div className="mb-4 row">
          <label htmlFor="ipo_listing_date" className="col-lg-2">
            Listing Date
          </label>
          <div className="col-lg-4">
            <Field
              className="form-control"
              type="date"
              name="ipo_listing_date"
              title="YYYY/MM/DD"
              tabIndex={15}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="isin" className="col-lg-2">
            ISIN
          </label>
          <div className="col-lg-4">
            <Field
              className="form-control"
              type="text"
              maxLength="15"
              name="isin"
              tabIndex={20}
            />
          </div>
        </div>
        <hr />
        <div className="mb-3 row">
          <label htmlFor="bse_script_code" className="col-lg-2">
            BSE Security Code
          </label>
          <div className="col-lg-2">
            <NumberInput
              classNameAtr="form-control"
              tabIndexAtr={25}
              maxLength="10"
              nameAtr="bse_script_code"
              idAtr="bseScriptCode"
              disabledAtr={formState.bseReadOnly}
            />
            <p>(i.e. 512323)</p>
          </div>
          <label htmlFor="bse_script_id" className="col-lg-2">
            BSE Symbol
          </label>
          <div className="col-lg-2">
            <Field
              className="form-control"
              type="text"
              size="10"
              name="bse_script_id"
              tabIndex={30}
              disabled={formState.bseReadOnly}
            />
            <p>(i.e. PATNI) </p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_listing_in_group" className="col-lg-2">
            BSE Listing In Group
          </label>
          <div className="col-lg-2">
            <Field
              as="select"
              name="bse_listing_in_group"
              className="form-select"
              tabIndex={35}
              disabled={formState.bseReadOnly}
            >
              <option></option>
              {result.scriptGroupList !== undefined &&
                result.scriptGroupList
                  .filter((option) => {
                    let codesToFilter = [];
                    if (initialValues.ipo_listing_at_array.includes("BSE")) {
                      codesToFilter.push("A");
                      codesToFilter.push("B");
                      codesToFilter.push("T");
                    }
                    if (
                      initialValues.ipo_listing_at_array.includes("BSE SME")
                    ) {
                      codesToFilter.push("M");
                      codesToFilter.push("MT");
                    }
                    return codesToFilter.includes(option.code);
                  })
                  .map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code_desc}
                    </option>
                  ))}
            </Field>
          </div>
        </div>
        <hr />
        <div className="mb-3 row">
          <label htmlFor="nse_script_symbol" className="col-lg-2">
            NSE Symbol
          </label>
          <div className="col-lg-2">
            <Field
              className="form-control"
              type="text"
              maxLength="25"
              name="nse_script_symbol"
              tabIndex={40}
              disabled={formState.nseReadOnly}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="nse_listing_in_group" className="col-lg-2">
            NSE Listing In Group
          </label>
          <div className="col-lg-2">
            <Field
              as="select"
              name="nse_listing_in_group"
              className="form-select"
              tabIndex={45}
              disabled={formState.nseReadOnly}
            >
              <option></option>
              {result.scriptGroupList !== undefined &&
                result.scriptGroupList
                  .filter((option) => {
                    let codesToFilter = [];
                    if (initialValues.ipo_listing_at_array.includes("NSE")) {
                      codesToFilter.push("EQ");
                      codesToFilter.push("BE");
                    }
                    if (
                      initialValues.ipo_listing_at_array.includes("NSE SME")
                    ) {
                      codesToFilter.push("SM");
                      codesToFilter.push("ST");
                    }
                    return codesToFilter.includes(option.code);
                  })
                  .map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code_desc}
                    </option>
                  ))}
            </Field>
          </div>
        </div>
        <hr />
        <div style={{ display: "flex", flexDirection: "row" }} className="mb-3">
          <h3 className="col-lg-3">Listing date price</h3>
          {console.log(!formState.bseReadOnly, formState.bseReadOnly)}
          {!formState.bseReadOnly && !formState.nseReadOnly && (
            <h3 className="col-lg-3">NSE</h3>
          )}
          {!formState.bseReadOnly && !formState.bseReadOnly && (
            <h3 className="col-lg-3">BSE</h3>
          )}
        </div>

        <div className="mb-3 row">
          <label htmlFor="open_price" className="col-lg-2 ">
            Open Price
          </label>
          {!formState.nseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={50}
                nameAtr="open_price"
                maxLength="10"
              />
            </div>
          )}
          {!formState.bseReadOnly && (
            <div className="col-lg-3">
             
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"

                tabIndexAtr={50}
                nameAtr="open_price_bse"
                maxLength="10"
              />
            </div>
          )}
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">
            High Price
          </label>
          {!formState.nseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={55}
                nameAtr="high_price"
                maxLength="10"
              />
            </div>
          )}
          {!formState.bseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={55}
                nameAtr="high_price_bse"
                maxLength="10"
              />
            </div>
          )}
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">
            Low Price
          </label>
          {!formState.nseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={60}
                nameAtr="low_price"
                maxLength="10"
              />
            </div>
          )}
          {!formState.bseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={60}
                nameAtr="low_price_bse"
                maxLength="10"
              />
            </div>
          )}
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">
            Close Price
          </label>
          {!formState.nseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={65}
                nameAtr="close_price"
                maxLength="10"
              />
            </div>
          )}
          {!formState.bseReadOnly && (
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={65}
                nameAtr="close_price_bse"
                maxLength="10"
              />
            </div>
          )}
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">
            Volume Traded
          </label>
          {!formState.nseReadOnly && (
            <div className="col-lg-3">
              <NumberInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={70}
                nameAtr="volume_traded"
                maxLength="10"
              />
            </div>
          )}
          {!formState.bseReadOnly && (
            <div className="col-lg-3">
              <NumberInput
                classNameAtr="form-control"
                type="text"
                tabIndexAtr={70}
                nameAtr="volume_traded_bse"
                maxLength="10"
              />
            </div>
          )}
        </div>
        <hr />

        <div className="mb-3 row">
          <label htmlFor="issue_price" className="col-lg-2 required">
            Issue Price
          </label>
          <div className="col-lg-2">
            <NumberInput
              classNameAtr={"form-control"}
              maxLength={"10"}
              nameAtr={"issue_price"}
              tabIndexAtr={75}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="ipo_fv" className="col-lg-2">
            Face Value
          </label>
          <div className="col-lg-2">{values.ipo_fv}</div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="current_face_value" className="col-lg-2">
            Current Face Value (Rs.)
          </label>
          <div className="col-lg-2">
            <NumberInput
              classNameAtr={"form-control"}
              maxLength={"3"}
              nameAtr={"current_face_value"}
              tabIndexAtr={80}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">
            Display Status
          </label>
          <div className="col-lg-1">
            <Field
              className="form-check-input"
              type="checkbox"
              name="display_status"
              tabIndex={85}
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.checked);
              }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="suspended_delisted" className="col-lg-2">
            Suspended / Delisted?
          </label>
          <div className="col-lg-10">
            <Field
              type="checkbox"
              className="form-check-input"
              name="suspended_delisted"
              tabIndex={90}
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.checked);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="mb-3 text-center">
          {result != undefined &&
            result.ipolisting != undefined &&
            result.ipolisting[0].lock_status !== 1 && (
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
                tabIndex={95}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                <span>
                  {result.ipolisting !== undefined &&
                  result.ipolisting.length > 0
                    ? "Update"
                    : "Create"}
                </span>
              </button>
            )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={100}
          >
            Cancel
          </button>
        </div>

        <p>
          <strong>Logic</strong>
        </p>
        <ul className="mb-5">
          <li>
            <p>
              If Issue Type = IPO and Issue category is Mainboard and total
              issue size is &gt; Rs. 250 crores , then listing group for BSE
              should be 'B' and for NSE should be 'EQ'
            </p>
          </li>
          <li>
            <p>
              If Issue Type = IPO and Issue category is Mainboard and total
              issue size is &lt; Rs. 250 crores , then listing group for BSE
              should be 'T' and for NSE should be 'BE'
            </p>
          </li>
          <li>
            <p>
              If Issue Type = IPO and Issue category is SME then listing group
              for BSE should be 'MT' and for NSE should be 'ST'
            </p>
          </li>
          <li>
            <p>
              If Issue Type = InVITs and Issue category is Mainboard and total
              issue size is &gt; Rs. 250 crores , then listing group for BSE
              should be 'IF' and for NSE should be 'IV'
            </p>
          </li>
        </ul>
        <ValidateFormFields />
      </Form>
    </>
  );
};

export default IpoListingNewForm;
