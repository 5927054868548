import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NCDTabs = ({ idAtr }) => {

  return (

    <Nav variant="tabs" className='mb-4'>
      <Nav.Item>
        <NavLink to={`/admintool9x/ncd/${idAtr}`} className={`nav-link`}>NCD Info</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/admintool9x/ncdcompany/${idAtr}`} className={`nav-link`}>Company Info</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/admintool9x/ncdcouponrate/${idAtr}`} className={`nav-link`}>Coupon Rate</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/admintool9x/financial/ncd/${idAtr}`} className={`nav-link`}>Financial</NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default NCDTabs;
