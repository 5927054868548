import React from 'react';
import { Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const TodoForm = ({ result, handleGoBack, loading }) => {
    return (
        <>
            <Form id='myForm'>
                <div className="mb-3 row">
                    <div className="col-lg-12 controls">
                        <Field as="textarea" className="form-control" rows="30" tabIndex={1} name="config_value" placeholder="Todo" />
                    </div>
                </div>

                <div className="mb-3 text-center">

                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={5}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>Update</span>
                    </button>


                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={10}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default TodoForm;
