import React from 'react';
import { useField } from 'formik';
import { isValidVariable } from './CommonFunction';

const DynamicCharacterCountInput = ({ nameattr, maxLength = 200, ...props }) => {
  const [field, meta, helpers] = useField(nameattr);

  // Default value to an empty string if undefined
  const fieldValue = isValidVariable(field.value) ? field.value : '';

  const handleChange = (e) => {
    field.onChange(e); // Formik's onChange method
  };
  const getColor = () => {
    if (fieldValue.length >= maxLength) return 'red';
    if (fieldValue.length >= maxLength * 0.8) return 'orange';
    return '#6c757d'; // Default muted color
  };
  return (
    <div className="position-relative">
      <input
        {...field}
        {...props}
        name={nameattr}
        value={fieldValue}
        maxLength={maxLength}
        onChange={handleChange}
        className="form-control"
        style={{ paddingRight: '3rem' }}
      />
      <div
        className="position-absolute end-0 top-50 translate-middle-y pe-2"
        style={{ pointerEvents: 'none', color: getColor(), fontSize: '0.9rem' }}
      >
        {fieldValue.length}/{maxLength}
      </div>
    </div>
  );
};

export default DynamicCharacterCountInput;
