import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import NumberDecimalInput from '../Common/NumberDecimalInput';

const validationSchema = Yup.object({
  ipo_id: Yup.string().required('Please select IPO'),
  bidding_time: Yup.string().required('Please enter Bidding Time'),
  bidding_date: Yup.string().required('Please enter Bidding Date'),
  qib_shares_bid_for: Yup.number().typeError('QIB Shares must be a number').nullable().min(0, 'QIB Shares must be greater than or equal to 0'),
  qib: Yup.number().typeError('QIB must be a number').nullable().min(0, 'QIB must be greater than or equal to 0')
});

const IPOBiddingForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (

          <Form id='myForm'>
            {result !== undefined && result.ipoBidDetail !== undefined && result.ipoBidDetail.length > 0 &&
              <div>
                <div className="mb-3 row">
                  <label htmlFor="" className="col-lg-2">ID:</label>
                  <div className="col-lg-4">
                    {result.ipoBidDetail[0].id}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="" className="col-lg-2 required">IPO ID:</label>
                  <div className="col-lg-4">
                    {result.ipoBidDetail[0].ipo_id}
                  </div>
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="ipo_id" className="col-lg-2 required">Issuer Company</label>
              <div className="col-lg-4">
                <Field className="form-select" as="select" tabIndex={1} name="ipo_id">
                  <option></option>
                  {result.ipoList !== undefined && result.ipoList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.company_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            {result.ipoBidDetail?.[0] &&
              <>
                <div className="mb-3 row">
                  <label htmlFor="" className="col-lg-2">Issue Category</label>
                  <div className="col-lg-2">
                    <p>{result.ipoBidDetail?.[0]?.issue_category}</p>
                  </div>
                  <label htmlFor="" className="col-lg-2">Listing Exchange</label>
                  <div className="col-lg-4">
                    <p>{result.ipoBidDetail?.[0]?.ipo_listing_at}</p>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="" className="col-lg-2">NSE Symbol</label>
                  <div className="col-lg-2">
                    <p>{result.ipoBidDetail?.[0]?.nse_symbol}</p>
                  </div>
                  <label htmlFor="" className="col-lg-2">BSE Scripcode</label>
                  <div className="col-lg-2">
                    <p>{result.ipoBidDetail?.[0]?.bse_scripcode}</p>
                  </div>
                </div>
              </>
            }


            <div className="mb-3 row">
              <label htmlFor="bidding_date" className="col-lg-2 required">Bid Date</label>
              <div className="col-lg-2">
                <Field type="date" tabIndex={2} name="bidding_date" className="form-control" placeholderText="yyyy/mm/dd" />
              </div>

              <label htmlFor="bidding_time" className="col-lg-2 required">Bid Time (HH:MM) IST</label>
              <div className="col-lg-1">
                <Field className="form-control" type="time" tabIndex={5} name="bidding_time" defaultValue="17:00:00" />
              </div>
            </div>
            <hr />
            <br />
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2"></label>
              <div className="col-lg-2"><b>Shares Offered (Total)</b></div>
              <div className="col-lg-2"><b>Shares Bid For (BSE + NSE)</b></div>
              <div className="col-lg-2"><b>Number of Time Subscribed (BSE + NSE)</b></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2" required>QIB</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={15} nameAtr='qib_offered' maxLength="15"
                />
              </div>
              <div className="col-lg-2 ">
                <NumberInput classNameAtr="form-control" tabIndexAtr={20} nameAtr='qib_shares_bid_for' maxLength="15"
                />
              </div>
              <div className="col-lg-2 ">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={25} nameAtr={'qib'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={30} nameAtr='nii_offered' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={35} nameAtr='nii_shares_bid_for' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={40} nameAtr={'nii'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII (Bid&lt;10L)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={45} nameAtr='nii_offered_big' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={50} nameAtr='nii_shares_bid_for_big' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={55} nameAtr={'nii_big'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII (Bid&lt;10L)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={60} nameAtr='nii_offered_small' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={65} nameAtr='nii_shares_bid_for_small' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={70} nameAtr={'nii_small'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Retail</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={75} nameAtr='rii_offered' maxLength="15" />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={80} nameAtr='rii_shares_bid_for' maxLength="15" />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={85} nameAtr={'rii'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Employee</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={90} nameAtr='emp_offered' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={95} nameAtr='emp_shares_bid_for' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={100} nameAtr={'emp'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Others</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={105} nameAtr='other_offered' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={110} nameAtr='other_shares_bid_for' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={115} nameAtr={'other'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Total</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={120} nameAtr='total_offered' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={125} nameAtr='total_shares_bid_for' maxLength="15"
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={130} nameAtr={'total'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">RII By Applications (x)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={135} nameAtr='rii_by_application' maxLength="15" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Total Application</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={140} nameAtr='total_application' maxLength="10" />

              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">No Of Application (x)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} tabIndexAtr={145} nameAtr={"no_of_application"} maxLength="10" />
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Display Status</label>
              <div className="col-lg-4">
                <Field className="form-check-input" type="checkbox" tabIndex={150} name="display_status"
                  onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
              </div>
            </div>

            <div className="mb-3 text-center">
              {initialValues.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={155}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.ipoBidDetail !== undefined && result.ipoBidDetail.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              )}

              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={160}
              >Cancel</button>
            </div>
            <ValidateFormFields />
          </Form>

        )}
      </Formik>
    </>
  )
}

export default IPOBiddingForm





