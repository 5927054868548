import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingCompanyMasterForm from './TradingCompanyMasterForm.js';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TradingCompanyTabs from './TradingCompanyTabs.js';
import { funTrimNestedStrings, isValidImageFileName } from '../Common/CommonFunction.js';

const validationSchema = Yup.object({
    company_name: Yup.string().trim().required('Please enter Company Name'),
    company_type: Yup.string().trim().required('Please enter Company Type'),
    // company_desc: Yup.string().trim().required('Please write Company Description'),
    // trading_platforms: Yup.string().trim().required('Please Enter Trading Platform Details'),
    // account_types: Yup.string().trim().required('Please Provide account type details'),
    urlrewrite_folder_name: Yup.string().trim().required('Please enter URL Folder name'),
});

const TradingCompanyMaster = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_type: "",
        company_name: "",
        company_short_name: "",
        logo_file_name: "",
        logo_length: "",
        logo_height: "",
        company_desc: "",
        trading_platforms: "",
        account_types: "",
        brokerage_fees: "",
        how_to_open_account: "",
        advantages: "",
        disadvantages: "",
        useful_links: "",
        others: "",
        urlrewrite_folder_name: "",
        display_order: "",
        lock_status: false,
        display_status: false,
        broker_calculator_tab: false,
        app_name: "",
        google_play_store_link: "",
        google_play_store_rating: "",
        apple_app_store_link: "",
        apple_app_store_rating: "",
        // last_modified_dt : "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/19");
    };

    useEffect(() => {
        console.log("triggred")
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Broker Detail" })
            } else {
                setMetaData({ "title": "Update Broker Detail" })
            }
            api.post('/admin-tradingcompany-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        setInitialValues(response.data.tradingCompany[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);

        if (!isValidImageFileName(trimmedValues.logo_file_name)) {
            return false;
        }
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());


        api.post('/admin-tradingcompany-save', {
            values: trimmedValues,

        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/19");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());

        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {!isAddMode && (<TradingCompanyTabs idAtr={result.id} />)}

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.tradingCompany !== undefined &&
                result.tradingCompany.length > 0 &&
                result.tradingCompany[0].lock_status == 1 && (
                    <h3 className="text-danger mb-3">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <TradingCompanyMasterForm handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} result={result} />
            </Formik>

        </>
    )
}

export default TradingCompanyMaster
