import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ReportCDNForm from './ReportCDNForm';

const ReportCDN = () => {

    const [formState, setFormState] = useState({
        ipoDisabled: false,
        ncdDisabled: false,
        riDisabled: false,
        bbDisabled: false
    })

    const [submitForm, setSubmitForm] = useState(false);
    const [numberOfReports, setnumberOfReports] = useState("1");

    const validationSchema = Yup.object({
        category_cd: Yup.string().required('Category is required'),
        topic_id: Yup.lazy((value, context) => {
            return context.parent.category_cd != "57"
                ? Yup.string().trim().required('topic id is required')
                : Yup.string().trim(); // Optional field for excluded types
        }),
    })
        .test(
            function (value) {
                const { ipo_id, ncd_id, ri_id, bb_id } = value;
                if ((!formState.ipoDisabled && ipo_id == null) || (!formState.ncdDisabled && ncd_id == null) ||
                    (!formState.riDisabled && ri_id == null) || (!formState.bbDisabled && bb_id == null)) {
                    setSubmitForm(false);
                    return false;
                }
                setSubmitForm(true)
                return true;
            }
        )

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [pageInfo, setPageInfo] = useState({ id: "", title: "", getUrl: "", saveUrl: "" });
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const location = useLocation();
    const { id } = useParams();
    let isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    function isValidURL(url) {
        // Regular expression for URL validation
        var re_http = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
        return re_http.test(url);
    }

    const validate = (e) => {
        console.log("The length of reports is:", numberOfReports)
        for (let i = 0; i < numberOfReports; i++) {
            if (!e.ReportCDNData[i].author_name) {
                alert(`Author Name for entry number: ${i + 1}, cannot be blank`);
                return;
            }
            if (!e.ReportCDNData[i].title) {
                alert(`Title for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
            if (!e.ReportCDNData[i].recommendation) {
                alert(`Recommendation for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
            for (let j = 0; j < numberOfReports; j++) {
                if (e.ReportCDNData[i].author_name == e.ReportCDNData[j].author_name && i != j) {
                    alert(`Duplicate entries for author name is entry number ${i + 1} and ${j + 1}`);
                    return false;
                }
                if (e.ReportCDNData[i].title == e.ReportCDNData[j].title && i != j) {
                    alert(`Duplicate entries for title is entry number ${i + 1} and ${j + 1}`);
                    return false;
                }
            }
            if (e.ReportCDNData[i].report_link !== "" && !isValidURL(e.ReportCDNData[i].report_link)) {
                alert(`Please enter a valid report link in entry number: ${i + 1}`);
                return false;
            }
            if (e.ReportCDNData[i].file_name == null) e.ReportCDNData[i].file_name = "";
            if (e.ReportCDNData[i].file_name !== "" && e.ReportCDNData[i].file_name.includes(" ")) {
                alert(`Space is not allowed in filename, please check entry number: ${i + 1}`);
                return false;
            }
            if (e.ReportCDNData[i].file_name !== "" && !e.ReportCDNData[i].file_name.endsWith(".pdf")) {
                alert(`File name should have extention .pdf, please check entry number: ${i + 1}`);
                return false;
            }

            if (e.ReportCDNData[i].report_link !== "" && e.ReportCDNData[i].file_name !== "") {
                alert(`Enter either the filename or report link, check entry number: ${i + 1}`);
                return false;
            }
            if (e.ReportCDNData[i].report_link == "" && e.ReportCDNData[i].file_name == "") {
                alert(`Enter either the filename or report link, check entry number: ${i + 1}`);
                return false;
            }
        }

        return true;
    }

    const [initialValues, setInitialValues] = useState({
        id: "",
        category_cd: "",
        topic_id: "",
        title: "",
        file_name: "",
        report_link: "",
        author_name: "",
        recommendation: "",
        lock_status: "",
        display_status: "",
        ipo_id: "",
        ncd_id: "",
        ri_id: "",
        bb_id: "",
        ReportCDNData: [{
            title: "",
            file_name: "",
            filebase64: null,
            fileoverwrite: false,
            file_path: "",
            report_link: "",
            author_name: "",
            recommendation: "",
        }]
    });
    const checkCurrentPage = () => {
        if (location.pathname.includes("admintool9x/reportscdn")) {
            let title = ""

            if (isAddMode) {
                title = "Add New Report"
            } else {
                title = "Update New Report"
            }
            return {
                id: id,
                title: title,
                getUrl: "/admin-reportcdn-read",
                saveUrl: "/admin-reportcdn-save"
            }
        }
        else if (location.pathname.includes("/ipo-report/AuthorReportEdit.asp")) {
            let title = ""
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id');

            isAddMode = !id
            if (isAddMode) {
                title = "Add New Author Report"
            } else {
                title = "Update Author Report"
            }
            return {

                id: id,
                title: title,
                getUrl: "/ipo-report/authorlist-read",
                saveUrl: "/ipo-report/authorlist-save"
            }
        }
    }
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            let page = checkCurrentPage();
            setPageInfo(page);
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Report" })
            } else {
                setMetaData({ "title": "Update New Report" })
            }
            api.post(page.getUrl, {
                id: page.id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.reportCDN);
                    if (!isAddMode) {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: response.data.id,
                            category_cd: response.data.reportCDN[0].category_cd,
                            topic_id: response.data.reportCDN[0].topic_id,
                            title: response.data.reportCDN[0].title,
                            file_name: response.data.reportCDN[0].file_name,
                            report_link: response.data.reportCDN[0].report_link,
                            author_name: response.data.reportCDN[0].author_name,
                            recommendation: response.data.reportCDN[0].recommendation,
                            display_status: response.data.reportCDN[0].display_status,
                            lock_status: response.data.reportCDN[0].lock_status,
                            ipo_id: response.data.reportCDN[0].category_cd == 55 ? response.data.reportCDN[0].topic_id : "",
                            ncd_id: response.data.reportCDN[0].category_cd == 56 ? response.data.reportCDN[0].topic_id : "",
                            ri_id: response.data.reportCDN[0].category_cd == 83 ? response.data.reportCDN[0].topic_id : "",
                            bb_id: response.data.reportCDN[0].category_cd == 84 ? response.data.reportCDN[0].topic_id : "",
                            ReportCDNData: [{
                                ...prevValues.ReportCDNData[0],  // Clone the first element of ReportCDNData
                                title: response.data.reportCDN[0].title,
                                file_name: response.data.reportCDN[0].file_name,
                                report_link: response.data.reportCDN[0].report_link,
                                author_name: response.data.reportCDN[0].author_name,
                                recommendation: response.data.reportCDN[0].recommendation,
                                file_path: response.data.reportCDN[0].file_path,
                            }]
                        }))
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        if (submitForm == false) {
            dispatch(setMessage('Please fill the field related to the category selected!'));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());

            setSubmitting(true);

            api.post(pageInfo.saveUrl, {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        }
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{pageInfo.title}</title>
                <meta name="title" content={pageInfo.title} />
            </Helmet>

            <h1>
                {pageInfo.title}
            </h1>

            {result !== undefined &&
                result.reportCDN !== undefined &&
                result.reportCDN.length > 0 &&
                result.reportCDN[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            <hr></hr>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ReportCDNForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} formState={formState} setFormState={setFormState} isAddMode={isAddMode} numberOfReports={numberOfReports} setnumberOfReports={setnumberOfReports} />
            </Formik>

            <p>IPO recommendation categories explained:</p>
            <ol>
                <li>Apply - The reviewer believes that investors of all categories can apply for this IPO.</li>
                <li>Neutral - The IPO analyst couldn't decide whether to apply or not.</li>
                <li>Avoid - For all investors, it's a clear avoid.</li>
                <li>May apply (or subscribe for long term) - In the IPO analyst's opinion, this IPO is apply for certain investors, while for others it should be avoided due to the risk profile and holding period.</li>
                <li>Not rated - The IPO analyst hasn't made a&nbsp;recommendation.</li>
            </ol>
        </>
    )
}

export default ReportCDN
