import React from 'react'
import { Field, Form } from 'formik';

import ValidateFormFields from '../Common/ValidateFormFields';
import { useFormikContext } from 'formik';
import { funCreateSlug } from "../Common/CommonFunction";
import NumberInput from '../Common/NumberInput';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const PhotoGalleryForm = ({ result, handleGoBack, loading, initialValues }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.photoGallery !== undefined && result.photoGallery.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.photoGallery[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="title" className="col-lg-2 required">Title:</label>
          <div className="col-lg-4">
            <Field as="input" name="title" className="form-control" tabIndex={1} maxLength="50" onBlur={(e) => {
              var title = e.target.value;
              if (title != "") {
                const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');

                if (userConfirmed) {

                  var slug = funCreateSlug(title)
                  setFieldValue('urlrewrite_folder_name', slug);
                } else {
                  setFieldTouched('title', true);
                }
              }

            }} />
          </div>

          <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name:</label>
          <div className="col-lg-4">
            <Field className="form-control" type='text' tabIndex={5} name="urlrewrite_folder_name" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="category_cd" className="col-lg-2 required">Picture Type:</label>
          <div className="col-lg-2">
            <Field as="select" className="form-select" tabIndex={10} name="category_cd">
              <option></option>
              {result.photoGalleryCategory !== undefined && result.photoGalleryCategory.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.cat_name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <hr />
        <div className='mb-3 row'>
          <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
          <div className="col-lg-10">
            <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="image_name" className="col-lg-2">Image:</label>
          <div className="col-lg-2">
            <Field className="form-control" type="text" tabIndex={15} name="image_name" />
          </div>
          <label htmlFor="image_height" className="col-lg-1" >Height:</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={20} maxLength="5" nameAtr="image_height" />
          </div>
          <label htmlFor="image_width" className="col-lg-1" >Width:</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={25} maxLength="5" nameAtr="image_width" />
          </div>
        </div>

        <div className="mb-3 row">
          <p align="center"></p>
        </div>

        <div className="mb-3 row">
          <label htmlFor="description" className="col-lg-2 required">Description:</label>
          <div className="col-lg-8">
            <CharacterCountTextarea
              nameattr="description"
              type="textarea"
              tabIndex="35"
              rows="7"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status:</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={40} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>


        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={40}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.photoGallery !== undefined && result.photoGallery.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={45}
          >Cancel</button>
        </div>

        <div>
          <p>Note:</p>
          <p><strong>Thumbnail</strong> is required for all the images.</p>
          <ol>
            <li><a href="http://makethumbnails.com/#dropzone" target="_blank">Create thumbnail</a> Make sure to <b>set options</b> and deselect black border.</li>
            <li>For xyz.gif, thumbnail file name should be xyz_small.gif</li>
            <li>Upload the thumbnail along with last picture.</li>
          </ol>
          <p>&nbsp;</p>
        </div>
        <ValidateFormFields />
      </Form>



    </>
  )
}

export default PhotoGalleryForm

