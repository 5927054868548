import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';


const NCDListingForm = ({ result, initialValues, handleGoBack, loading }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.ncdlisting !== undefined && result.ncdlisting.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.ncdlisting[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="company_name" className="col-lg-2">NCD</label>
          <div className="col-lg-10">
            {result !== undefined && result.ncdlisting !== undefined && result.ncdlisting.length > 0 &&
              <div className="mb-3 row">
                <div className="col-lg-4">
                  {result.ncdlisting[0].company_name}
                </div>
              </div>
            }
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="ipo_listing_date" className="col-lg-2">Listing Date</label>
          <div className="col-lg-4">
            <Field className="form-control" type="date" tabIndex={1} name="ipo_listing_date" />
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_code" className="col-lg-2">BSE Security Code</label>
          <div className="col-lg-6">
            <NumberInput classNameAtr={"form-control"} tabIndexAtr={5} nameAtr={"bse_script_code"} />(i.e. 512323)
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_id" className="col-lg-2">BSE Symbol</label>
          <div className="col-lg-6">
            <Field className="form-control" type="text" tabIndex={10} name="bse_script_id" />(i.e. PATNI)
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="nse_script_symbol" className="col-lg-2">NSE Symbol</label>
          <div className="col-lg-6">
            <Field className="form-control" type="text" maxLength="25" tabIndex={15} name="nse_script_symbol" />
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_listing_in_group" className="col-lg-2">BSE Listing In Group</label>
          <div className="col-lg-4">
            <Field as="select" name="bse_listing_in_group" className="form-select" tabIndex={20}>
              <option>Choose BSE listing group</option>
              {result.scriptGroupList !== undefined && result.scriptGroupList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="isin" className="col-lg-2">ISIN</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="15" tabIndex={25} name="isin" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Issue Price</label>
          <div className="col-lg-2">

            {result !== undefined && result.ncdlisting !== undefined && result.ncdlisting.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="" className="col-lg-1">Rs</label>
                <div className="col-lg-4">
                  {result.ncdlisting[0].issue_price}
                </div>
              </div>
            }
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Face Value</label>
          <div className="col-lg-2">
            {result !== undefined && result.ncdlisting !== undefined && result.ncdlisting.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="" className="col-lg-1">Rs</label>
                <div className="col-lg-4">
                  {result.ncdlisting[0].ipo_fv}
                </div>
              </div>
            }
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display status</label>
          <div className="col-lg-10">
            <Field type="checkbox" className="form-check-input" tabIndex={30} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>
        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 &&
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={35}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.ncdlisting !== undefined && result.ncdlisting.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          }

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={40}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default NCDListingForm
