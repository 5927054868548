import { useFormikContext, Field } from 'formik';
import FileUpload from '../Common/FileUpload';

const ReportCDNDataComponent = ({ remove, result, companyName, numberOfReports, setnumberOfReports, funSetReportTitle, setFieldValue, hideOverwrite = 0 }) => {
    const { values } = useFormikContext();
    return (
        <>
            {values.ReportCDNData !== undefined && values.ReportCDNData.map((reportCDN, index) => (
                <div key={index}>
                    <div className="r-group">
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="mb-3 row">
                                    <label htmlFor={`ReportCDNData.${index}.author_name`} className="col-lg-2 required">Author Name</label>
                                    <div className="col-lg-4">
                                        <Field as="select" className="form-select" name={`ReportCDNData.${index}.author_name`} onChange={(e) => {
                                            const selectedCode = e.target.value;
                                            const selectedAuthor = result.authorList.find(option => option.code === selectedCode);
                                            setFieldValue(`ReportCDNData.${index}.author_name`, e.target.value);
                                            funSetReportTitle(companyName, selectedAuthor?.code_desc, index);
                                        }}>
                                            <option ></option>
                                            {result.authorList !== undefined && result.authorList.map((option) => (
                                                <option key={option.code} value={option.code}>
                                                    {option.code_desc}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor={`ReportCDNData.${index}.title`} className="col-lg-2 required">Report Title</label>
                                    <div className="col-lg-4">
                                        <Field className="form-control" type='text' name={`ReportCDNData.${index}.title`} maxLength="100" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor={`ReportCDNData.${index}.file`} className='col-lg-2'>Choose an image:</label>
                                    <div className="col-lg-10">
                                        <FileUpload indexattr={`ReportCDNData.${index}.fileoverwrite`} nameattr={`ReportCDNData.${index}.file_name`} namebase64attr={`ReportCDNData.${index}.filebase64`} imageField={`ReportCDNData.${index}.file_name`} pdfOnly={true} hideOverwrite={hideOverwrite} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor={`ReportCDNData.${index}.file_name`} className="col-lg-2 required">File Name</label>
                                    <div className="col-lg-2">
                                        <Field className="form-control" type='text' name={`ReportCDNData.${index}.file_name`} maxLength="250" />
                                    </div>
                                    <div className="col-lg-1">
                                        <center><img src="/images/or.gif" /></center>
                                    </div>
                                    <label htmlFor={`ReportCDNData.${index}.report_link`} className="col-lg-2">Report Link (External)</label>
                                    <div className="col-lg-2">
                                        <Field className="form-control" type='text' name={`ReportCDNData.${index}.report_link`} maxLength="350" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor={`ReportCDNData.${index}.recommendation`} className="col-lg-2 required">Recommendation</label>
                                    <div className="col-lg-4">
                                        <Field as="select" className="form-select" name={`ReportCDNData.${index}.recommendation`}>
                                            <option ></option>
                                            {result.recommendationList !== undefined && result.recommendationList.map((option) => (
                                                <option key={option.code} value={option.code}>
                                                    {option.code_desc}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                                <p>
                                    {values.ReportCDNData.length != 1 && (
                                        <button type="button" className="r-btnRemove btn btn-danger btn-sm"
                                            onClick={() => {
                                                setnumberOfReports(values.ReportCDNData.length - 1)
                                                remove(index)
                                            }}
                                        >Remove</button>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ReportCDNDataComponent
