import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import IpoTabs from "../IPONew/IPONewPageTabs";
import { finishLoading, selectLoading, startLoading } from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../common/useApi";
import { Formik } from "formik";
import IpoFinalBiddingForm from "./IpoFinalBiddingForm";

const IpoFinalBidding = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [extraObjects, setExtraObjects] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });

    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        id: "",
        finalIpoBiddingData: [
            {
                bidding_category: 0,
                no_of_shares: "",
                updated: "",
                no_of_applications: "",
                shares_applied: "",
                no_of_shares_allotted: "",
                no_of_allottees: "",
                offered_price: "",
                times_subscribed: "",
                display_status: false,
            }
        ]
    });

    //1. check if the object is a part of missingObjects
    //2. check if any of the values is changed ( if yes then add else remove)

    const validate = (e) => {
        // for (let i = 0; i < e.finalIpoBiddingData.length; i++) {
        //     if (!e.finalIpoBiddingData[i].bidding_category) {
        //         alert(`Final Bidding Category at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].offered_price) {
        //         alert(`offer price at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].shares_applied) {
        //         alert(`shares applied at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].no_of_allottees) {
        //         alert(`No of Allotees at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].updated) {
        //         alert(`Updated at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].no_of_applications) {
        //         alert(`No of Applications at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].no_of_shares_allotted) {
        //         alert(`No of Shares alloted at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.finalIpoBiddingData[i].times_subscribed) {
        //         alert(`Subscription at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        // }
        return true;
    }
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Edit Bidding" })

            api.post('/admin-final-ipo-bidding-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setInitialValues((prev) => ({
                        ...prev,
                        id: id,
                        finalIpoBiddingData: response.data.finalIpoBiddingData,
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {

                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);


    const handleSubmit = (values, { setSubmitting }) => {
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});


        console.log("The trimmed values is: ", trimmedValues)

        if (validate(trimmedValues)) {
            dispatch(startLoading());
            trimmedValues.id = id;
            api.post('/admin-final-ipo-bidding-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    // navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again


            setSubmitting(false);
        }
        setSubmitting(false);

    };
    return (
        <>
            <IpoTabs idAtr={result.id} ipo_confirm={result?.ipocompany?.[0]?.ipo_confirmation_status} />

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <IpoFinalBiddingForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} />
            </Formik>
        </>
    )
}
export default IpoFinalBidding;