import React, { useState, useEffect } from 'react'
import { useFormikContext, Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CheckboxGroup from '../Common/CheckboxGroup';
import { funCreateSlug, isValidVariable, compareDates } from '../Common/CommonFunction';
import MultiSelectTransfer from '../Common/MultiSelectTransfer';
import AutocompleteField from '../Common/AutocompleteField';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';
import useApi from '../../../common/useApi';
import FileUpload from '../Common/FileUpload';
import PhoneInput from '../Common/PhoneInput';

const BuybackForm = ({ result, handleGoBack, initialValues, loading, isAddMode }) => {

  const [isTenderDisabled, setTenderDisabled] = useState(false);
  const [isStockFieldsDisabled, setisStockFieldsDisabled] = useState(true);

  let isBuybackRatioReadOnly = isAddMode || initialValues.id === "" || parseInt(initialValues.id) > 100
  let isBuybackRatioOldReadOnly = !isAddMode && initialValues.id !== "" && parseInt(initialValues.id) <= 100 && parseInt(initialValues.id) !== 0



  const [formState, setFormState] = useState({
    autoComplteOptionsCompany: [],
    isAutocompleteCompanyLoading: false,
    checkOpenAndCloseDate: "",
    recordDtPage: initialValues.record_dt,
    offerClosureDatePage: initialValues.offer_closure_date
  });

  const { values, setFieldValue, setFieldTouched, getFieldProps } = useFormikContext();

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      recordDtPage: initialValues.record_dt,
      offerClosureDatePage: initialValues.offer_closure_date
    }));

  }, [initialValues.record_dt, initialValues.offer_closure_date]);


  useEffect(() => {
    if (values.type_of_buyback == "tender-offer-buyback") {
      setTenderDisabled(false);
    } else {
      setFieldValue("record_dt", "")
      setFieldValue("last_day_to_buy_shares_dt", "")
      setFieldValue("last_date_on_zerodha", "")
      setFieldValue("issue_size_in_shares", "")
      setFieldValue("percentage_of_equity_shares", "")
      setFieldValue("ratio_small_shareholders", "")
      setFieldValue("ratio_small_fullypaid", "")
      setFieldValue("ratio_general_shareholders", "")
      setFieldValue("ratio_general_shareholders", "")
      setFieldValue("reservation_general_shareholders", "")
      setFieldValue("reservation_small_shareholders", "")
      setFieldValue("last_date_for_receipt_of_tender_forms", "")
      setFieldValue("tender_form_url", "")
      setFieldValue("finalisation_of_buyback", "")
      setFieldValue("last_date_for_settlment_of_bids", "")
      setFieldValue("last_date_for_extinguishment_of_shares", "")
      setTenderDisabled(true);
    }
  }, [values.type_of_buyback]);

  const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);
  const api = useApi();

  const handleAutoSearchCompany = async (query) => {
    setFormState((prevState) => ({
      ...prevState,
      isAutocompleteCompanyLoading: true,
    }));

    api.post('/admin-stock-list', {
      search: query
    }).then(response => {
      if (response.data.msg === 1) {
        let result_array = response.data.list.map((value) => Object.values(value).join(' | '));

        setFormState((prevState) => ({
          ...prevState,
          autoComplteOptionsCompany: result_array,
        }));
      }
    })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        setFormState((prevState) => ({
          ...prevState,
          isAutocompleteCompanyLoading: false,
        }));
      });
  };
  const handleAutoSearchCompanySelect = async (selected) => {
    // Handle form submission
    if (selected != "" && selected !== undefined) {
      let itemArr = selected.split("|").map((item) => item.trim())
      setFieldValue("company_name", itemArr[1]);
      setFieldValue("list_of_script_id", parseInt(itemArr[0]));
      const userConfirmed = window.confirm('Do you want to update the stock details too?');
      if (userConfirmed) {
        callStockApi(itemArr[1]);
      }
    }

  };

  const callStockApi = (selected) => {

    api.post('/admin-listofscript-detail', {
      search: selected
    }).then(response => {
      if (response.data.msg === 1) {
        setisStockFieldsDisabled(false);
        let nse_symbol = response.data.list[0].nse_script_id ?? "";
        let bse_scripcode = response.data.list[0].bse_script_code ?? ''
        setFieldValue('isin', response.data.list[0].isin ?? '');
        setFieldValue('nse_symbol', nse_symbol);
        setFieldValue('bse_scripcode', bse_scripcode);

        setFieldValue('buyback_listing_at_array', []);
        if (isValidVariable(nse_symbol) && isValidVariable(bse_scripcode))
          setFieldValue("buyback_listing_at_array", ["BSE", "NSE"]);
        else if (isValidVariable(nse_symbol)) setFieldValue("buyback_listing_at_array", ["NSE"]);
        else if (isValidVariable(bse_scripcode)) setFieldValue("buyback_listing_at_array", ["BSE"]);

      }
    })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {

      });
  }

  const handleLastDateToBuyShareZerodha = (e) => {

    if (e.target.value != "" && e.target.value !== formState.offerClosureDatePage) {
      setFormState((prevState) => ({
        ...prevState,
        offerClosureDatePage: e.target.value,
      }));
      setFieldValue('offer_closure_date', e.target.value);
      const userConfirmed = window.confirm('Would you like to update Last Day to buy shares on zerodha date?');
      if (userConfirmed) {
        api.post('/admin-buyback-last-day-to-buy-share', {
          date: e.target.value
        }).then(response => {
          if (response.data.msg === 1) {
            console.log(response.data.last_day_buy_share_dt)
            setFieldValue('last_date_on_zerodha', response.data.last_day_buy_share_dt ?? '');
          } else {
            alert(response.data.error);
          }
        })
          .catch(error => {
            console.error('Error:', error);
          }).finally(() => {

          });
      }
    }

  };

  const handleLastDateToBuyShare = (e) => {
    if (e.target.value != "" && e.target.value !== formState.recordDtPage) {
      setFieldValue('record_dt', e.target.value);
      setFormState((prevState) => ({
        ...prevState,
        recordDtPage: e.target.value,
      }));

      const userConfirmed = window.confirm('Would you like to update Last Day to buy shares date?');
      if (userConfirmed) {
        api.post('/admin-buyback-last-day-to-buy-share', {
          date: e.target.value
        }).then(response => {
          if (response.data.msg === 1) {
            console.log(response.data.last_day_buy_share_dt)
            setFieldValue('last_day_to_buy_shares_dt', response.data.last_day_buy_share_dt ?? '');
          } else {
            alert(response.data.error);
          }
        })
          .catch(error => {
            console.error('Error:', error);
          }).finally(() => {

          });
      }
    }

  };

  const checkAverageMarketPrice = (e, lowMarketPrice, highMarketPrice) => {
    const targetVal = e.target.value
    setFieldValue(e.target.name, targetVal)
    if (e.target.value !== "" && lowMarketPrice !== "" && targetVal < parseInt(lowMarketPrice)) {
      setFieldValue(e.target.name, "")
      alert("Average market price should be greater than or equal to low market price")
    }
    if (e.target.value !== "" && highMarketPrice !== "" && targetVal > parseInt(highMarketPrice)) {
      setFieldValue(e.target.name, "")
      alert("Average market price should be less than or equal to high market price")
    }
  }

  useEffect(() => {

    console.log(values.offer_closure_date);
    if (
      values.record_dt != initialValues.record_dt ||
      values.offer_closure_date != initialValues.offer_closure_date
    ) {
      setFormState((prevState) => ({
        ...prevState,
        checkOpenAndCloseDate: "Date modified. Please check display order.",
      }));
    }
  }, [values.record_dt, values.offer_closure_date]);

  const handleInputChange = (fieldName, value) => {
    if ((fieldName === 'ratio_small_shareholders' && value !== '' && values.ratio_small_fullypaid == "") ||
      (fieldName === 'ratio_small_fullypaid' && value !== '' && values.ratio_small_shareholders == "")) {
      alert("Please enter both Ratio Small Shareholders, one value is empty.")
    }
  };


  return (
    <>

      <Form id='myForm'>
        {result !== undefined && result.buyback !== undefined && result.buyback.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.buyback[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="company_name_auto" className="col-lg-2">Company Name Search</label>
          <div className="col-lg-8">
            {/* {(initialValues.company_name_auto[0].label != "" || isAddMode) && */}
            <Field
              name="company_name_auto"
              as={AutocompleteField}
              options={formState.autoComplteOptionsCompany}
              onInputChange={(query) => handleAutoSearchCompany(trimValue(query))}
              onInputSelect={handleAutoSearchCompanySelect}
              selected={values.company_name_auto ? [values.company_name_auto] : []}
              tabIndex={1}
              maxLength="10"
              isAutocomplteLoading={formState.isAutocompleteCompanyLoading}
              nameatr="company_name_auto"
              idatr="company_name_auto_txt"
            />
            {/* } */}
          </div>
          <br />
          <br />

        </div>

        <div className="mb-3 row">
          <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
          <div className="col-lg-4">
            <Field as="input" name="company_name" className="form-control" tabIndex={2} maxLength="100" readOnly />
          </div>
          <label htmlFor="news_title" className="col-lg-2 required">News Title</label>
          <div className="col-lg-4">
            <Field as="input" name="news_title" className="form-control" tabIndex={5} maxLength="50" onBlur={(e) => {
              var news_title = e.target.value;
              if (news_title !== "") {
                const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                if (userConfirmed) {
                  var slug = funCreateSlug(news_title);
                  setFieldValue('urlrewrite_folder_name', slug);
                } else {
                  setFieldTouched('news_title', true);
                }
              }
            }} />
          </div>
        </div >

        <div className="mb-3 row">
          <label htmlFor="buyback_listing_at_array" className="col-lg-2">Issue Listing At</label>
          <div className="col-lg-4">
            {result.issueStockExchangeList !== undefined &&
              <Field
                tabIndex={8}
                name="buyback_listing_at_array"
                label="Buyback Listing At"
                component={CheckboxGroup}
                options={result.issueStockExchangeList}
                className='form-check-input Stock_Exchange_Type_Checkbox me-2'
              />
            }
          </div>

          <label htmlFor="URLRewrite_Folder_Name" className="col-lg-2 required">URLRewrite Folder Name</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={9} type="text" maxLength="50" name="urlrewrite_folder_name" id="urlrewrite_folder_name_txt"
            />
          </div>
        </div>
        <hr />
        <h2>Stock Details</h2>

        <div className="mb-3 row">
          <label htmlFor="isin" className="col-lg-2">ISIN</label>
          <div className="col-lg-4">
            <Field className="form-control stock_list_input" tabIndex={18} type="text" maxLength="15" name="isin" disabled={isStockFieldsDisabled} />
          </div>
          <label htmlFor="nse_symbol" className="col-lg-2">NSE Symbol</label>
          <div className="col-lg-4">
            <Field className="form-control stock_list_input" tabIndex={20} type="text" maxLength="15" name="nse_symbol" disabled={isStockFieldsDisabled}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_scripcode" className="col-lg-2">BSE Scripcode</label>
          <div className="col-lg-4">
            <Field className="form-control stock_list_input" tabIndex={22} type="text" maxLength="15" name="bse_scripcode" disabled={isStockFieldsDisabled}
            />
          </div>
        </div>
        <hr />
        <div className="mb-3 row">
          <label htmlFor="offer_open_date" className="col-lg-2">Offer Open Date</label>
          <div className="col-lg-2">
            <Field className="form-control dateInput" tabIndex={30} type="date" name="offer_open_date" title="YYYY/MM/DD"
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.value);
                if (e.target.value !== "" && values.offer_closure_date !== "" && values.offer_closure_date !== null && e.target.value >= values.offer_closure_date) {
                  alert("Buyback opening date should be before closing date")
                  setFieldValue("offer_closure_date", "");
                }
              }} />
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="record_dt" className="col-lg-2">Record Date</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={35} type="date" name="record_dt" id="txtrecord_dt" data-value="{record_dt}"
              title="YYYY/MM/DD" disabled={isTenderDisabled} onBlur={(e) => {
                if (compareDates(e.target.value, new Date().toLocaleString("en-CA", { timeZone: "Asia/Kolkata" }).split(",")[0]) == -1) {
                  alert("Record date cannot be an earlier date")
                  setFieldValue("record_dt", initialValues.record_dt)
                  return;
                }
                handleLastDateToBuyShare(e);
              }} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="offer_closure_date" className="col-lg-2">Offer Closure date</label>
          <div className="col-lg-2">
            <Field className="form-control dateInput" tabIndex={40} type="date" data-value="{offer_closure_date}" id="txtoffer_closure_dt" name="offer_closure_date"
              title="YYYY/MM/DD" onBlur={handleLastDateToBuyShareZerodha}
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.value);
                if (e.target.value !== "" && values.offer_open_date !== "" && values.offer_open_date !== null && e.target.value <= values.offer_open_date) {
                  alert("Buyback opening date should be before closing date")
                  setFieldValue("offer_open_date", "");
                }
              }} />
            <p className="text-danger" id="display_order_message">{formState.checkOpenAndCloseDate}</p>
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="last_day_to_buy_shares_dt" className="col-lg-2">Last Day to buy shares date</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={45} type="date" data-value="{last_day_to_buy_shares_dt}"
              name="last_day_to_buy_shares_dt" title="YYYY/MM/DD"
              id="txtlast_day_to_buy_shares_dt" disabled={isTenderDisabled} />
            <p className="text-danger mb-0">(Check for T+1 day settlement stocks)</p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="type_of_buyback" className="col-lg-2">Type of Buyback</label>
          <div className="col-lg-2">
            <Field as="select" name="type_of_buyback" id="type_of_buyback" className="form-select" tabIndex={46}>
              <option></option>
              {result.buybackTypeList !== undefined && result.buybackTypeList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="last_date_on_zerodha" className="col-lg-2">Last Date on Zerodha</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={50} type="date"
              name="last_date_on_zerodha" title="YYYY/MM/DD"
              id="txtlast_date_on_zerodha" disabled={isTenderDisabled} />
          </div>
        </div>
        <hr />
        <div className="mb-3 row">
          <label htmlFor="face_value" className="col-lg-2">Face Value</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={55} maxLength="5" nameAtr="face_value"
              dynamicOnBlur={(e) => {
                const targetVal = e.target.value
                setFieldValue(e.target.name, targetVal)
                if (e.target.value !== "" && values.buyback_price !== "" && targetVal > parseInt(values.buyback_price)) {
                  setFieldValue('buyback_price', "")
                  alert("Buyback price should be greater than or equal to face value")
                }
              }} />
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="issue_size_in_shares" className="col-lg-2">Issue Size (Shares)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25"
              nameAtr="issue_size_in_shares" disabledAtr={isTenderDisabled}
              dynamicOnInput={(e) => {
                const val = e.target.value;
                const buybackPrice = values.buyback_price;
                if (val !== "" && buybackPrice !== "") {
                  const sizeInAmt = parseFloat(val) * parseFloat(buybackPrice);
                  setFieldValue("issue_size_in_amt", sizeInAmt)
                }
                else setFieldValue("issue_size_in_amt", "")
              }} />
          </div>
          <div className="col-lg-2">
            <div style={{ color: '#0000FF' }} >{parseFloat(values.issue_size_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="buyback_price" className="col-lg-2">Buyback Price</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={65} maxLength="10" nameAtr="buyback_price"

              dynamicOnBlur={(e) => {
                const targetVal = e.target.value
                setFieldValue(e.target.name, targetVal)
                if (e.target.value !== "" && targetVal < parseFloat(values.face_value)) {
                  setFieldValue('buyback_price', "")
                  alert("Buyback price should be greater than or equal to face value")
                }
              }}

              dynamicOnInput={(e) => {
                const val = e.target.value;
                const sizeInShares = values.issue_size_in_shares;
                if (sizeInShares !== "" && val !== "") {
                  const sizeInAmt = parseFloat(val) * parseFloat(sizeInShares);
                  setFieldValue("issue_size_in_amt", sizeInAmt)
                }
                else setFieldValue("issue_size_in_amt", "")
              }} />
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="issue_size_in_amt" className="col-lg-2">Issue Size (Amount)</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25"
              nameAtr="issue_size_in_amt" />
          </div>
          <div className="col-lg-2">
            <div style={{ color: '#0000FF' }}>Rs.&nbsp;{parseFloat(values.issue_size_in_amt / 10000000).toFixed(2)}&nbsp;Crore</div>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="necessity_of_the_buyback" className="col-lg-2">Necessity of the buyback (HTML)</label>
          <div className="col-lg-4">
            <Field as="textarea" rows="5" cols="55" name="necessity_of_the_buyback" tabIndex={71}
              className="form-control" />
          </div>
          <label htmlFor="percentage_of_equity_shares" className="col-lg-2">% of total equity for buyback</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={75} maxLength="10"
              nameAtr="percentage_of_equity_shares" disabledAtr={isTenderDisabled} />
          </div>

        </div>
        <hr />

        <h2>Buyback Ratio</h2>
        <div className="mb-3 row">
          <label htmlFor="ratio_small_shareholders" className="col-lg-2">Ratio Small Shareholders (Number)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={80} maxLength="8" nameAtr="ratio_small_shareholders" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled}
              onBlur={(value) => handleInputChange('ratio_small_shareholders', value)}
            />
          </div>
          <div className="col-lg-2">
            <label>Equity Shares out of every </label>
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={85} maxLength="8" nameAtr="ratio_small_fullypaid" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled}
              onBlur={(value) => handleInputChange('ratio_small_fullypaid', value)}
            />
          </div>
          <div className="col-lg-4"><label>Fully paid-up Equity Shares held on the Record Date.</label></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="ratio_general_shareholders" className="col-lg-2">Ratio General Shareholders (Number)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={90} maxLength="8" nameAtr="ratio_general_shareholders" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled}
            />
          </div>
          <div className="col-lg-2"><label>Equity Shares out of every </label></div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={95} maxLength="8" nameAtr="ratio_general_fullypaid" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled}
            />
          </div>
          <div className="col-lg-4"><label>Fully paid-up Equity Shares held on the Record Date.</label></div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="reservation_small_shareholders" className="col-lg-2">Reservation Small Shareholders (Number)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={100} maxLength="30" nameAtr="reservation_small_shareholders" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled} />
          </div>
          <div className="col-lg-2"></div>
          <label htmlFor="reservation_general_shareholders" className="col-lg-2">Reservation General Shareholders (Number)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={105} maxLength="30" nameAtr="reservation_general_shareholders" readOnlyAtr={isBuybackRatioOldReadOnly} disabledAtr={isTenderDisabled} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="buyback_ratio" className="col-lg-2">Buyback Ratio (HTML)</label>
          <div className="col-lg-4">
            <Field as="textarea" rows="5" cols="55" className="form-control" tabIndex={106}
              name="buyback_ratio"
              readOnly={isBuybackRatioReadOnly} disabled={isTenderDisabled}
            />
          </div>
        </div>

        <hr />
        <h2>Schedule of Activities</h2>

        <div className="mb-3 row">
          <label htmlFor="last_date_for_receipt_of_tender_forms" className="col-lg-3">Last date for receipt of Tender Forms</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={110} type="date"
              name="last_date_for_receipt_of_tender_forms" title="YYYY/MM/DD" disabled={isTenderDisabled} />
          </div>
          <div className="col-lg-1">
          </div>
          <label htmlFor="finalisation_of_buyback" className="col-lg-3">Finalisation of Buyback Acceptance/Non-Acceptance</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={115} type="date" name="finalisation_of_buyback"
              title="YYYY/MM/DD" disabled={isTenderDisabled} />
          </div>
          <div className="col-lg-1">
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="last_date_for_settlment_of_bids" className="col-lg-3">Last date for settlment of bids</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={120} type="date" name="last_date_for_settlment_of_bids"
              title="YYYY/MM/DD" disabled={isTenderDisabled} />
          </div>
          <div className="col-lg-1">

          </div>
          <label htmlFor="last_date_for_extinguishment_of_shares" className="col-lg-3">Last date for Extinguishment of Shares</label>
          <div className="col-lg-2">
            <Field className="form-control disableText" tabIndex={125} type="date"
              name="last_date_for_extinguishment_of_shares"
              title="YYYY/MM/DD" disabled={isTenderDisabled} />
          </div>
          <div className="col-lg-1">
          </div>
        </div>

        <hr />
        <h2>Document Links</h2>

        <div className="mb-3 row">
          <label htmlFor="tender_form_url" className="col-lg-2">Tender Form URL (only in case of tender offer)</label>
          <div className="col-lg-4">
            <Field className="form-control disableText" tabIndex={130} type="text" maxLength="150" name="tender_form_url" disabled={isTenderDisabled}
            />
          </div>
          <label htmlFor="letter_of_offer_url" className="col-lg-2">Letter of Offer / Public Announcement URL</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={135} type="text" maxLength="150" name="letter_of_offer_url"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="faqs_url" className="col-lg-2">FAQs URL</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={140} type="text" maxLength="150" name="faqs_url" />
          </div>
        </div>



        <hr />
        <h2>Public Offer:</h2>
        <div className="mb-3 row">
          <label htmlFor="shares_offered_qib" className="col-lg-2">QIB</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={170} type="text" name="shares_offered_qib"
            />
          </div>
          <label htmlFor="shares_offered_nii" className="col-lg-2">NII</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={175} type="text" name="shares_offered_nii"
            />
          </div>
          <label htmlFor="shares_offered_rii" className="col-lg-2">RII</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={180} type="text" name="shares_offered_rii"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="Shares_Offered_EMP" className="col-lg-2">Employee</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={185} type="text" name="shares_offered_emp"
            />
          </div>
          <label htmlFor="Shares_Offered_Others" className="col-lg-2">Others</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={190} type="text" name="shares_offered_others"
            />
          </div>
          <label htmlFor="Shares_Offered_Total" className="col-lg-2">Total For Public <br />(including employee)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={195} maxLength="15" nameAtr="Shares_Offered_Total" />
          </div>
        </div>
        <hr />
        <h2>Registrar, Lead Managers & Company Brokers</h2>
        <div className="mb-3 row">
          <label htmlFor="registrar_1" className="col-lg-2">Registrar 1</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" name="registrar_1" tabIndex={199}>
              <option ></option>
              {result.registrarList !== undefined && result.registrarList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.comp_name}
                </option>
              ))}
            </Field>
          </div>
          <label htmlFor="registrar_1_email" className="col-lg-2">Registrar Email 1</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={200} type="text" maxLength="50" name="registrar_1_email"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="registrar_2" className="col-lg-2">Registrar 2</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" name="registrar_2" tabIndex={201}>
              <option ></option>
              {result.registrarList !== undefined && result.registrarList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.comp_name}
                </option>
              ))}
            </Field>
          </div>
          <label htmlFor="registrar_2_email" className="col-lg-2">Registrar Email 2</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={205} type="text" maxLength="50" name="registrar_2_email"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="company_registered_broker" className="col-lg-2">Company Registered Broker 1</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={210} type="text" maxLength="50" name="company_registered_broker"
            />
          </div>
          <label htmlFor="broker_email" className="col-lg-2">Broker email 1</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={215} type="text" maxLength="50" name="broker_email"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="company_registered_broker_2" className="col-lg-2">Company Registered Broker 2</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={220} type="text" maxLength="50" name="company_registered_broker_2"
            />
          </div>
          <label htmlFor="broker_email_2" className="col-lg-2">Broker email 2</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={225} type="text" maxLength="50" name="broker_email_2"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <MultiSelectTransfer dataList={result.leadManagerList} labelAttr={"Lead Managers"} initialItemsNameAttr={"initialItems"} selectedItemsNameAttr={"selectedItems"} selectedItemsIdsAttr={"selectedItemsIds"} />
        </div>
        <hr />
        <h2>Company Info</h2>
        <div className='mb-3 row'>
          <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
          <div className="col-lg-10">
            <FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="logo_url" className="col-lg-2">Logo Image Name:</label>
          <div className="col-lg-3">
            <Field className="form-control" tabIndex={240} type="text" maxLength="100" name="logo_url" />
          </div>
          <label htmlFor="logo_length" className="col-lg-1">Width:</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={245} maxLength="3" nameAtr="logo_length" />
          </div>
          <label htmlFor="logo_height" className="col-lg-1">Height:</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={250} maxLength="3" nameAtr="logo_height" />
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="address_1" className="col-lg-2">Address 1</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={255} type="text" name="address_1" maxLength="50" />
          </div>
          <label htmlFor="address_2" className="col-lg-2">Address 2</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={260} type="text" name="address_2" maxLength="50" />
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="address_3" className="col-lg-2">Address 3</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={265} type="text" name="address_3" maxLength="50" />
          </div>
          <label htmlFor="State" className="col-lg-2">State</label>
          <div className="col-lg-4">
            <Field as="select" name="state" className="form-select" tabIndex={266} >
              <option></option>
              {result.stateList !== undefined && result.stateList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="phone" className="col-lg-2">Phone</label>
          <div className="col-lg-4">
            <PhoneInput classNameAtr={"form-control"} nameAtr={"phone"} tabIndex={270} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="website" className="col-lg-2">Website</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={275} type="text" name="website" maxLength="50" />
          </div>
          <label htmlFor="email" className="col-lg-2">Email</label>
          <div className="col-lg-4">
            <Field className="form-control" tabIndex={280} type="text" name="email" maxLength="50" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="company_desc" className="col-lg-2">Company Description (HTML)</label>
          <div className="col-lg-4">
            <Field as="textarea" className="form-control" rows="8" name="company_desc" tabIndex={281} />
          </div>
          <label htmlFor="financial" className="col-lg-2">Company Financial (HTML)</label>
          <div className="col-lg-4">
            <Field as="textarea" className="form-control" rows="8" name="financial" tabIndex={282} />
          </div>
        </div>
        <hr />
        <h2>Stock Market for Preceding 3 Months</h2>
        <h5>( *Date 1 = YYYY-MM-01 till previous day before Record Date )</h5>

        <div className="mb-3 row">
          <label htmlFor="stock_preceding_dt1" className="col-lg-1">Date 1</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={285} type="date" name="stock_preceding_dt1"
              title="YYYY/MM/DD" />
          </div>
          <div className="col-lg-1">

          </div>
          <label htmlFor="stock_preceding_dt2" className="col-lg-1">Date 2</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={290} type="date" name="stock_preceding_dt2"
              title="YYYY/MM/DD" />
          </div>
          <div className="col-lg-1">

          </div>
          <label htmlFor="stock_preceding_dt3" className="col-lg-1">Date 3</label>
          <div className="col-lg-2">
            <Field className="form-control" tabIndex={295} type="date" name="stock_preceding_dt3"
              title="YYYY/MM/DD" />
          </div>
          <div className="col-lg-1">

          </div>
        </div>

        <h2>For BSE [ Market Prices for preceding 3 months (High, low and average) ]</h2>

        <div className="mb-3 row">
          <label htmlFor="bse_high_market_price1" className="col-lg-2">High Market Price 1 </label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={300} maxLength="10" nameAtr="bse_high_market_price1" />
          </div>
          <label htmlFor="bse_low_market_price1" className="col-lg-2">Low Market Price 1</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={305} maxLength="10" nameAtr="bse_low_market_price1" />
          </div>
          <label htmlFor="bse_average_market_price1" className="col-lg-2">Average Market Price 1</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={310} maxLength="10" nameAtr="bse_average_market_price1"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.bse_low_market_price1, values.bse_high_market_price1) }} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_high_market_price2" className="col-lg-2">High Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={315} maxLength="10" nameAtr="bse_high_market_price2" />
          </div>
          <label htmlFor="bse_low_market_price2" className="col-lg-2">Low Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={320} maxLength="10" nameAtr="bse_low_market_price2" />
          </div>
          <label htmlFor="bse_average_market_price2" className="col-lg-2">Average Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={325} maxLength="10" nameAtr="bse_average_market_price2"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.bse_low_market_price2, values.bse_high_market_price2) }} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_high_market_price3" className="col-lg-2">High Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={330} maxLength="10" nameAtr="bse_high_market_price3" />
          </div>
          <label htmlFor="bse_low_market_price3" className="col-lg-2">Low Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={335} maxLength="10" nameAtr="bse_low_market_price3" />
          </div>
          <label htmlFor="bse_average_market_price3" className="col-lg-2">Average Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={340} maxLength="10" nameAtr="bse_average_market_price3"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.bse_low_market_price3, values.bse_high_market_price3) }} />
          </div>
        </div>

        <hr />

        <h2>For NSE [ Market Prices for preceding 3 months (High, low and average) ]</h2>

        <div className="mb-3 row">
          <label htmlFor="nse_high_market_price1" className="col-lg-2">High Market Price 1</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={345} maxLength="10" nameAtr="nse_high_market_price1" />
          </div>
          <label htmlFor="nse_low_market_price1" className="col-lg-2">Low Market Price 1</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={350} maxLength="10" nameAtr="nse_low_market_price1" />
          </div>
          <label htmlFor="nse_average_market_price1" className="col-lg-2">Average Market Price 1</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={355} maxLength="10" nameAtr="nse_average_market_price1"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.nse_low_market_price1, values.nse_high_market_price1) }} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="nse_high_market_price2" className="col-lg-2">High Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={360} maxLength="10" nameAtr="nse_high_market_price2" />
          </div>
          <label htmlFor="nse_low_market_price2" className="col-lg-2">Low Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={365} maxLength="10" nameAtr="nse_low_market_price2" />
          </div>
          <label htmlFor="nse_average_market_price2" className="col-lg-2">Average Market Price 2</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={370} maxLength="10" nameAtr="nse_average_market_price2"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.nse_low_market_price2, values.nse_high_market_price2) }} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="nse_high_market_price3" className="col-lg-2">High Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={375} maxLength="10" nameAtr="nse_high_market_price3" />
          </div>
          <label htmlFor="nse_low_market_price3" className="col-lg-2">Low Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={380} maxLength="10" nameAtr="nse_low_market_price3" />
          </div>
          <label htmlFor="nse_average_market_price3" className="col-lg-2">Average Market Price 3</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={385} maxLength="10" nameAtr="nse_average_market_price3"
              dynamicOnBlur={(e) => { checkAverageMarketPrice(e, values.nse_low_market_price3, values.nse_high_market_price3) }} />
          </div>
        </div>

        <hr />

        <div className="mb-3 row">
          <label htmlFor="display_order" className="col-lg-2">Display Order</label>
          <div className="col-lg-1">
            <NumberInput classNameAtr="form-control" tabIndexAtr={390} maxLength="4" nameAtr="display_order" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className='col-lg-4'>
            <Field className="form-check-input " type="checkbox" name="display_status" tabIndex={395} onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue ? 1 : 0);
            }} />
          </div>

          <label htmlFor="issue_withdraw_status" className="col-lg-2">Issue Withdraw</label>
          <div className="col-lg-4">
            <Field className="form-check-input" tabIndex={400} type="checkbox" name="issue_withdraw_status"
              onChange={(e) => { setFieldValue('issue_withdraw_status', e.target.checked) }} /> (Check if issue is withdrawn from the market)
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
          <div className="col-lg-4">
            <Field
              className="form-check-input"
              tabIndex={405}
              type="checkbox"
              name="lock_status"
              onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue('lock_status', newValue ? 1 : 0);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 && (
            <button
              tabIndex={405} type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.buyback !== undefined && result.buyback.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            tabIndex={410} type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}



export default BuybackForm;
