import { Field, useFormikContext } from 'formik';
import React from 'react'

const PhoneInput = ({ 
    classNameAtr, 
    tabIndexAtr, 
    placeholderAtr, 
    maxLength, 
    nameAtr, 
    idAtr, 
    dynamicOnChange = () => {}, 
    disabledAtr, 
    readOnlyAtr, 
    dynamicOnBlur = () => {}, 
    dynamicOnInput = () => {} 
}) => {

    const formik = useFormikContext();

    const handleChange = (event) => {
        const newValue = event.target.value;
        // Remove any characters that are not digits, +, space, or -
        const sanitizedValue = newValue.replace(/[^0-9+\s-]/g, '');
        formik.setFieldValue(nameAtr, sanitizedValue);

        // Call the dynamically provided onChange function
        dynamicOnChange(sanitizedValue);
    };

    return (
        <Field 
            type="tel"
            className={classNameAtr} 
            tabIndex={tabIndexAtr !== undefined ? tabIndexAtr : null} 
            maxLength={maxLength || "16"} 
            name={nameAtr}
            onChange={handleChange} 
            disabled={disabledAtr} 
            id={idAtr} 
            readOnly={readOnlyAtr}
            onBlur={dynamicOnBlur}
            placeholder={placeholderAtr}
            onKeyUp={(e) => {
                // Allow only numeric input, +, space, and -
                const value = e.target.value;
                const sanitizedValue = value.replace(/[^0-9+\s-]/g, '');
                formik.setFieldValue(nameAtr, sanitizedValue);
            }}
            onInput={dynamicOnInput}
        />
    )
}

export default PhoneInput