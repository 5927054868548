import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './Home.css';
import { Link } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { setMessage } from '../../../state/slices/message';
import routeConfig from '../../../routeConfig';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { Spinner } from 'react-bootstrap';

const Home = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const api = useApi();
    const dispatch = useDispatch();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const [result, setResult] = useState({});
    const loading = useSelector(selectLoading);

    useEffect(() => {
        if (didMountRef.current == "true") {
            console.log("Hello API")
            dispatch(startLoading());
            api.post('/admin-home-page-notification').then(response => {
                if (response.data.msg === 1) {
                    console.log(response.data)
                    setResult(response.data);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const funCallCommonHomePageActionAPIs = (data) => {
        if (window.confirm("Are you sure to perform " + data.action_name)) {
            callApi(data);
        }
    }
    const saveConfigValue = (config_value) => {
        config_value = config_value == 1 ? 0 : 1
        api.post("/admin-cronjob-configuration", {
            config_value: config_value
        }).then((res) => {
            if (res.data.msg == 1) {
                setResult((prev) => ({ ...prev, configSchedule: config_value }))
            }
        }).catch((ex) => {
            console.log(ex)
        })
    }
    const callApi = async (data) => {
        try {
            dispatch(startLoading());
            // Example API call
            const response = await api.post(data.api_name, data);
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
            } else {
                // Dispatch error message if condition not met
                dispatch(setMessage(response.data.error));
            }

        } catch (error) {
            dispatch(setMessage(error));
        } finally {
            dispatch(finishLoading());
        }
    };
    return (
        <div className={`${loading ? 'blur' : ''}`}>
            {currentUser.role_id == 3 &&
                <div className='float-end'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='badge bg-danger fs-6'>App Version: {process.env.REACT_APP_VERSION}</span>
                        <div className='form-check form-switch d-flex align-items-center ms-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='flexSwitchCheckDefault'
                                checked={result?.configSchedule == 1}
                                onChange={() => {
                                    if (window.confirm('Are you sure you want to toggle schedule?')) {
                                        saveConfigValue(result.configSchedule);
                                    }
                                }}
                            />
                            <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault'>
                                Schedule Jobs
                            </label>
                        </div>
                    </div>
                </div>
            }
            <h1>Hello {currentUser.display_name}</h1>


            {result.iIPOMessageSpamCount !== undefined && result.iIPOMessageSpamCount > 0 &&
                <div className='row mt-2'><div className='col-12 mt-2'><div className='alert alert-danger'><strong>Alert!</strong> IPO messages for review. <a href='/admintool9x/spamfeedback' className='btn btn-danger btn-sm ms-2'>{result.iIPOMessageSpamCount} Review</a></div></div></div>
            }
            {result.iIPORecomSpamCount !== undefined && result.iIPORecomSpamCount > 0 &&
                <div className='row'><div className='col-12 mt-2'><div className='alert alert-danger'><strong>Alert!</strong>  IPO Recommendation messages for review. <a href='/admintool9x/spamrecommendation' className='btn btn-danger btn-sm ms-2'>{result.iIPORecomSpamCount} Review</a></div></div></div>
            }

            {result.missingIPOListingDayTrading !== undefined && result.missingIPOListingDayTrading.length > 0 &&
                <div className='row'><div className='col-12 mt-2'><div className='alert alert-danger'><strong>Alert!</strong> IPO's doesn't have IPO Listing Day Trading Info Updated: <br /><br />{result.missingIPOListingDayTrading.map((item) => (
                    <p className='mb-0' key={item.ID}>{item.Company_Name}</p>
                ))} </div></div></div>
            }
            {loading && (
                <div className="spinner-overlay">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            {currentUser.role_id == 3 ? (
                <>
                    <section>
                        <div className="row">
                            <div className="col-md-3">
                                <b>Maintenance</b>
                                <ul>
                                    <li><Link to="/nosuchpageexist.asp">Test Error Page</Link></li>
                                    <li><Link to="/admintool9x/sql_findinalltables">Find Text in All Tables</Link></li>
                                    <li><a onClick={(e) => {
                                        e.preventDefault();
                                    }}>Purge IPOFeedback Temp Folder (Disabled Now)</a></li>
                                    <li><a onClick={(e) => {
                                        e.preventDefault();
                                        funCallCommonHomePageActionAPIs({
                                            action_name: "Purge Subscription Page Temp Folder",
                                            api_name: "admin-delete-temp-files",
                                            folder_name: "subscription"
                                        })
                                    }} href="#" >Purge Subscription Page Temp Folder</a></li>
                                    <li><Link to={routeConfig.subscriptionfile()}>Subscriptions Folders</Link></li>
                                    <li><Link to={routeConfig.logsfile()}>Logs Folders</Link></li>
                                    <li><Link to={routeConfig.tmpsfile()}>Temporary Folders</Link></li>
                                    <li><a onClick={(e) => {
                                        e.preventDefault();
                                        funCallCommonHomePageActionAPIs({
                                            action_name: "Purge Website (All Pages)",
                                            api_name: "admin-cloudflare-site-purge"
                                        })
                                    }} href="#" >Purge CloudFlare Website (All Pages)</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <b>Configurations</b>
                                <ul>
                                    <li><Link to="/admintool9x/report/155">Portal Conf</Link></li>
                                    <li><Link to="/admintool9x/report/156">Codes</Link></li>
                                    <li><Link to="/admintool9x/report/157">Categories (Article, FAQ, Glossary)</Link></li>
                                    <li><Link to="/admintool9x/report/40">Broker Leads Submission Manager</Link></li>
                                    <li><Link to="/admintool9x/report/120">Holidays</Link></li>
                                    <li><Link to="/admintool9x/report/12">Picture Gallery</Link></li>
                                    <li><Link to="/admintool9x/report/10">Google Sitemap</Link></li>
                                    <li><a>CIPL to CSB Data Migration (on Hold)</a></li>
                                    <li><Link to="report/159">Monitoring Master</Link></li>
                                    <li><Link to="/admintool9x/report/189">Cache Master</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <b>Manual Tools</b>
                                <ul>
                                    <li><Link to="/admintool9x/report/154">IPO Message & Rank Cleanup (To reuse IPO ID)</Link></li>
                                    <li><Link to="/tools/gmp.asp">IPO GMP Form</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <b>Cronjobs (Automated Tools)</b>
                                <ul>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_action_hourly.asp`} target="_blank">Data Maintenance - Hourly</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_action.asp`} target="_blank">Data Maintenance - Daily</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_and_notification_action.asp`} target="_blank">Data Maintenance - (DB Hourly)</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/monitoring_general_action.asp`} target="_blank">Monitor Jobs</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/list_of_scripts_loader.asp`} target="_blank">Load List of Script (NSE and NSE SME)</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/alerts_action.asp`} target="_blank">Alerts</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ipo_bidding.asp`} target="_blank">IPO Bidding Download</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ipo_bidding.asp?debug=1`} target="_blank">IPO Bidding Download (After Hours)</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ipo_boa.asp`} target="_blank">IPO Basis of Allotment</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/allotment_status_action.asp`} target="_blank">IPO Allotment Status</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ncd_bidding.asp`} target="_blank">NCD Bidding Download</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ncd_bidding.asp?debug=1`} target="_blank">NCD Bidding Download (After Hours)</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/capital_market_reports.asp`} target="_blank">Capital Market Reports Download</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/ReportsMonitoringAction.asp`} target="_blank">Reports Keywords Monitoring</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="row">
                            <div className="col-md-3">
                                <b>Misc</b>
                                <br />
                                <b>Bhav Copy Manual Import</b>
                                <ol>
                                    <li>Check <Link to="/admintool9x/report/100">NSE</Link> | <Link to="/admintool9x/report/99">BSE</Link> Bhavcopy Data.</li>
                                    <li>Download .CSV from <Link to="http://www.bseindia.com/markets/equity/EQReports/BhavCopyDebt.aspx" target="_blank">BSE</Link> | <Link to="https://nseindia.com/all-reports" target="_blank">NSE</Link></li>
                                    <li>Upload using File Import</li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/bhav_copy_import_action.asp`} target="_blank">Import Bhavcopy Data</Link></li>
                                </ol>
                            </div>

                            <div className="col-md-3">
                                <ul>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <ul>
                                </ul>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <>
                    <section>
                        <div className="row">
                            <div className="col-md-3">
                                <b>Tools (Automated)</b>
                                <ul>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_action.asp`} target="_blank">Data Maintenance - Daily</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/monitoring_general_action.asp`} target="_blank">Monitor Jobs</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/list_of_scripts_loader.asp`} target="_blank">Load List of Script (NSE and NSE SME)</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <b>Tools (Automated)</b>
                                <ul>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ipo_bidding.asp`} target="_blank">IPO Bidding</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ipo_boa.asp`} target="_blank">IPO Basis of Allotment</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/allotment_status_action.asp`} target="_blank">IPO Allotment Status Notification</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/data_maintenance_ncd_bidding.asp`} target="_blank">NCD Bidding</Link></li>
                                    <li><Link to={`${process.env.REACT_APP_NODE_URL}cronjobs/capital_market_reports.asp`} target="_blank">Capital Market Reports Download</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>

    )
}

export default Home