import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import AuthService from "../../services/auth.service";
import Cookies from 'js-cookie';
import { googleLogout } from '@react-oauth/google';
const user = JSON.parse(Cookies.get("user") || null);

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message = getErrorMessage(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password, recaptcha, googleToken, loginType = "M" }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setMessage(""));

      const isSecondVerificationNeeded = Cookies.get("isSecondVerificationNeeded") === "true";

      if (isSecondVerificationNeeded) {
        const data = await AuthService.login2(username, password);
        if (data.msg === 1) {
          const user = Cookies.get("user");
          return user ? { user: JSON.parse(user) } : thunkAPI.rejectWithValue("User data missing.");
        } else {
          thunkAPI.dispatch(setMessage(data.error));
          return thunkAPI.rejectWithValue(data.error);
        }
      }

      let data;
      if (loginType === "G") {
        data = await AuthService.googleLoginFunc(googleToken);
      } else {
        data = await AuthService.login(username, password, recaptcha);
      }

      if (data.msg === 1) {
        return { user: data };
      } else {
        thunkAPI.dispatch(setMessage(data.error));
        return thunkAPI.rejectWithValue(data.error);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Utility Function for Error Messages
const getErrorMessage = (error) => {
  if (!error.response) {
    // Network or server connection issue
    return "We’re having trouble connecting to the server. This could be due to a network issue or the server might be temporarily unavailable. Please try again later.";
  }
  return (
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString()
  );
};

export const checkTokenExpiration = createAsyncThunk(
  'auth/checkTokenExpiration',
  async (_, { getState, dispatch }) => {
    const { user } = getState().auth; // Accessing the auth slice of the state
    if (user && AuthService.isTokenExpired(user.accessToken)) {
      dispatch(logout());
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  AuthService.logout();
  Cookies.remove("user"); // Clear user cookie
  Cookies.remove("isSecondVerificationNeeded"); // Clear verification cookie
  googleLogout()
  localStorage.setItem('logoutEvent', Date.now());
  // window.dispatchEvent(new CustomEvent("customLogoutEvent")); 
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    syncAuthState(state) {
      const user = JSON.parse(Cookies.get('user') || null);
      state.isLoggedIn = !!user;
      state.user = user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      });
  },
});

export const { syncAuthState } = authSlice.actions;
export default authSlice.reducer;
