import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../state/slices/loadingSlice';
import { setMessage } from '../../state/slices/message';
import { funFormatDateTime, validateId } from '../admintool9x/Common/CommonFunction';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';
import routeConfig from '../../routeConfig';

const IPOReport = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    var search = queryParams.get("search");
    const dispatch = useDispatch();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const loading = useSelector(selectLoading);

    let columns = [
        {
            Header: 'ID',
            accessor: 'id',
            Cell: ({ cell }) => (
                <Link to={routeConfig.author_report_edit() + "?id=" + parseInt(cell.row.original.id)}>
                    {parseInt(cell.row.original.id)}
                </Link>
            ),
        },

        {
            Header: 'Category',
            accessor: 'Category',
            Cell: ({ cell }) => (
                <>
                    <div>
                        {cell.row.original.Category}
                    </div>
                </>
            ),
        },

        {
            Header: 'Title',
            accessor: 'Title',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Title}
                </>
            ),
        },

        {
            Header: 'Topic',
            accessor: 'Topic',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Topic}
                </>
            ),
        },

        {
            Header: 'File',
            accessor: 'File',
            Cell: ({ cell }) => (
                <div dangerouslySetInnerHTML={{ __html: cell.row.original.File }}></div>
            ),
        },

        {
            Header: 'Author',
            accessor: 'Author',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Author}
                </>
            ),
        },

        {
            Header: 'Recommendation',
            accessor: 'Recommendation',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Recommendation}
                </>
            ),
        },

        {
            Header: 'Date Added',
            accessor: 'r.Date_Added',
            Cell: ({ cell }) => (
                <>
                    {funFormatDateTime(cell.row.original['Date Added'])}
                </>
            ),
        },

        {
            Header: 'Last Modified Date',
            accessor: 'r.last_modified_dt',
            Cell: ({ cell }) => (
                <>
                    {funFormatDateTime(cell.row.original['Last modified date'])}
                </>
            ),
        },

        {
            Header: 'Last Modified By',
            accessor: 'Last modified by',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original['Last modified by']}
                </>
            ),
            disableSortBy: true,
        },

        {
            Header: 'Unlock',
            accessor: 'Unlock',
            Cell: ({ cell }) => (
                <>
                    <div dangerouslySetInnerHTML={{ __html: cell.row.original.Unlock }}></div>

                </>
            ),
            disableSortBy: true,
        },

        {
            Header: 'Display Status',
            accessor: 'display_status',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.display_status}
                </>
            ),
        },
    ];

    const [result, setResult] = useState({
        tableColumns: columns,
        tableData: [],
        pageNumber: 1,
        totalPages: 1,
        totalRecords: 0,
        pageIndex: 0,
        recordPerPage: 20,
        orderBy: "",
        pageDataLoaded: 0,
        currentSort: "1"
    });

    const getTableData = () => {
        dispatch(startLoading());
        api.post('/ipo-report/authorlist', {
            search: search,
            pagenumber: result.pageIndex + 1,
            orderby: result.currentSort,
        }).then(response => {
            if (response.data.msg === 1) {
                //console.log("This is the Reuslt : ", response.data.reviewList)
                setResult((prevState) => ({
                    ...prevState,
                    tableColumns: columns,
                    tableData: response.data.reviewList,
                    totalRecords: response.data.TotalRecords,
                    recordPerPage: response.data.RecordPerPage,
                    pageNumber: response.data.PageNumber,
                    orderBy: response.data.Orderby,
                    totalPages: response.data.TotalPages,
                    pageDataLoaded: 1,
                }))
            } else {
                dispatch(setMessage(response.data.error));
                if (response.data.msg == -2) {
                    navigate("/")
                }
            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "Author Review List" })
            getTableData();
        } else {
            didMountRef.current = "true";
        }
    }, [location, result.pageIndex, result.currentSort]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },

    } = useTable(
        {
            columns: result.tableColumns,
            data: result.tableData,
            manualSortBy: true,
            initialState: {
                pageIndex: result.pageIndex,
                pageSize: result.recordPerPage,
                sortBy: [{ id: 'id', desc: false }],
            },
            manualPagination: true,
            pageCount: result.totalPages,
        },
        useSortBy,
        usePagination
    );

    const [metaData, setMetaData] = useState({
        title: ""
    });

    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            let currentSort = "";
            if (sortBy.length > 0) {
                currentSort = sortBy[0].id + (sortBy[0].desc ? " desc" : " asc");
            }
            setResult((prevState) => ({
                ...prevState,
                currentSort: currentSort
            }));
        }
    }, [sortBy]);

    // Search Button


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchReport();
        }
    };

    const searchReport = () => {
        if (searchTerm !== null && searchTerm !== undefined) {
            navigate(`?search=${searchTerm}`);
        }
        else {
            navigate(location.pathname);
        }
    };

    const clearSearchReport = () => {
        setSearchTerm("");
        navigate(`?search=${searchTerm}`);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {/* Begin Search Button */}

            <div className='row main mt-3'>
                <div className='col-sm-12'>
                    <h1 className="mb-3">IPO Author Review</h1>

                    <div className="row g-3">
                        <div className="col-auto">
                            <input
                                type="text"
                                className="form-control search-report-txt"
                                id="txtsearch"
                                placeholder="Search"
                                name="search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-3 me-2" onClick={searchReport}>
                                Search
                            </button>
                            <button type="button" className="btn btn-secondary mb-3" onClick={clearSearchReport}>
                                Clear
                            </button>
                        </div>
                    </div>

                    {/* End SEarch Button */}

                    <div className="mb-2">
                        <Link to={routeConfig.author_report_edit()}>Add New Report </Link>
                        | <Link to={routeConfig.dd_review_list()}>Dilip Davda Sir Review</Link>
                    </div>
                    <div className='table-responsive'>
                        <div className={`table-container ${loading ? 'blur' : ''}`}>
                            <Table {...getTableProps()} striped bordered hover id='report_table'>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th className={column.className} {...column.getHeaderProps()}>
                                                    <div
                                                        {...column.getSortByToggleProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {column.render('Header')}
                                                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                                    </div>
                                                </th>

                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length}>
                                                No Record<br /><br /></td>
                                        </tr>
                                    ) : (
                                        rows.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className={cell.column.className}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </div>

                    {result.totalPages > 1 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Prev onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex - 1 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Item
                                key={result.pageIndex}
                                active={true}
                                onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex }))}
                            >
                                {result.pageIndex + 1}
                            </Pagination.Item>

                            <Pagination.Next onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                            <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                        </Pagination>
                    }
                </div>
            </div >

        </>
    )
}

export default IPOReport
