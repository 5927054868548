import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import AlertActionMasterForm from './AlertActionMasterForm';

const AlertActionMaster = () => {

    const validationSchema = Yup.object({
        name: Yup.string().trim().required('Name is required'),
        condition_cd: Yup.string().trim().required('Please Select the Cron Job'),
        alert_title: Yup.string().trim().required('Alert Title is Required'),
        alert_url: Yup.string().trim().required('Alert URL is required'),
        alert_desc: Yup.string().trim().required('Alert Description is required'),
        sql_query: Yup.string().trim().required('SQL Query is required'),
        start_time: Yup.string().trim().required('Start time is required'),
        end_time: Yup.string().trim().required('End Time is required'),
        sending_time: Yup.string().trim().required('Sending time is required'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        name: "",
        condition_cd: "",
        sql_query: "",
        alert_title: "",
        alert_desc: "",
        alert_url: "",
        mobile_alert: "",
        homepage_alert: false,
        highlight_status: false,
        sticky_status: false,
        start_time: "",
        end_time: "",
        sending_time: "",
        display_status: false,
        lock_status: false,
        run_on_holiday: false,
        is_empty_fields: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            if (isAddMode) {
                setMetaData({ "title": "Alert Action" })
            } else {
                setMetaData({ "title": "Update Alert Action" })
            }
            api.post('/admin-alertdb-read', {
                id: id
            }).then(response => {
                console.log(response)
                if (response.data.msg === 1) {
                    setResult(response.data);

                    if (!isAddMode) {
                        setInitialValues(response.data.alertdb[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        dispatch(startLoading());

        api.post('/admin-alertdb-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.alertdb !== undefined &&
                result.alertdb.length > 0 &&
                result.alertdb[0].lock_status == 1 && (
                    <h3 className="text-danger mt-3 mb-0">
                        Record Is Locked , you can't update it.
                    </h3>

                )}

            <br />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <AlertActionMasterForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues} />
            </Formik>
        </>
    )
}
export default AlertActionMaster;


