import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { Formik } from 'formik';
import CompareReportForm from './CompareReportForm';

const validationSchema = Yup.object({
    report_id: Yup.string().trim().required('Please choose the Report Name.'),
    meta_title: Yup.string().trim().required('Meta Title is Required'),
    meta_desc: Yup.string().trim().required('Meta Description is Required'),
    sql_query: Yup.string().trim().required('SQL Query is Required')
});

const CompareReport = () => {

    const validate = (e) => {
        // console.log("The length is: ", e.compareReportChild)
        for (let i = 0; i < e.compareReportChild.length; i++) {
            console.log("section title is: ", e.compareReportChild[i].section_title)
            if (!e.compareReportChild[i].section_title) {
                alert(`Section title for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
            if (!e.compareReportChild[i].section_desc) {
                alert(`Section description for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
            if (!e.compareReportChild[i].columns_name) {
                alert(`Column name for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
            if (!e.compareReportChild[i].display_order) {
                alert(`Display order for entry number: ${i + 1}, cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        report_id: "",
        sql_query: "",
        ddl_search_query: "",
        breadcrumb_desc: "",
        compare_list_query: "",
        meta_title: "",
        meta_desc: "",
        display_status: false,
        lock_status: false,
        compareReportChild: [
            {
                section_title: '',
                section_desc: '',
                columns_name: '',
                display_order: '',
                display_status: false
            }
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add Compare Report" })
            } else {
                setMetaData({ "title": "Edit Compare Report" })
            }
            api.post('/admin-comparereport-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data);

                    if (!isAddMode) {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: response.data.id,
                            report_id: response.data.compareReport[0].report_id,
                            sql_query: response.data.compareReport[0].sql_query,
                            ddl_search_query: response.data.compareReport[0].ddl_search_query,
                            breadcrumb_desc: response.data.compareReport[0].breadcrumb_desc,
                            compare_list_query: response.data.compareReport[0].compare_list_query,
                            meta_title: response.data.compareReport[0].meta_title,
                            meta_desc: response.data.compareReport[0].meta_desc,
                            display_status: response.data.compareReport[0].display_status,
                            lock_status: response.data.compareReport[0].lock_status,
                            compareReportChild: response.data.compareReportChild

                        }));
                    }

                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request

        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());


            api.post('/admin-comparereport-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());

                });
        }
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.compareReport !== undefined &&
                result.compareReport.length > 0 &&
                result.compareReport[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger mt-3 mb-0">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>

                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <CompareReportForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading}
                />
            </Formik>
        </>
    )
}

export default CompareReport

