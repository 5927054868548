import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ErrorReportForm from './ErrorReportForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const ErrorReport = () => {

    const validationSchema = Yup.object({
       error_resolution_status: Yup.string().trim().required('Please enter the resolution status.'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
      id: "",
      referrer_page: "",
      page_url: "",
      ip_address: "",
      error_message: "",
      error_type: "",
      error_source: "",
      error_time: "",
      error_resolution_status: "",
      admin_comment: "",
      display_status: false,
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            
            if (isAddMode) {
              setMetaData({ "title": "Add Error Detail" })
            } else {
              setMetaData({ "title": "Edit Error Detail" })
            }

            api.post('/admin-errorreport-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data);
                    if (!isAddMode) {
                        setInitialValues(response.data.errorReport[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.articleNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
         console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-errorreport-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ErrorReportForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues}/>
            </Formik>


        </>
    )
}

export default ErrorReport
