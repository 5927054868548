import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import NumberInput from '../Common/NumberInput';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const validationSchema = Yup.object({
	website_cd: Yup.string().required('Please Select Website'),
	trading_company_id: Yup.string().required('Company Name is Mandatory'),
	platform_name: Yup.string().required('Please mention the trading platform Name'),
	urlrewrite_folder_name: Yup.string().required('Please enter urlrewrite_folder_name'),
	description_brief: Yup.string().required('Please enter brief description'),
	description: Yup.string().required('Please enter description'),
});

const TradingPlatform = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>

				{({ setFieldValue, setFieldTouched }) => (

					<Form id='myForm'>
						{result !== undefined && result.tradingplatform !== undefined && result.tradingplatform.length > 0 &&
							<div className="mb-3 row">
								<label htmlFor="id" className="col-lg-2">ID:</label>
								<div className="col-lg-4">
									<p className='mb-0'><b>{result.tradingplatform[0].id}</b></p>
								</div>
							</div>
						}

						<div className="mb-3 row">
							<label htmlFor="website_cd" className="col-lg-2 required">Website</label>
							<div className="col-lg-4">

								<Field as="select" className="form-select" name="website_cd">
									<option>Select</option>
									{result.websiteList !== undefined && result.websiteList.map((option) => (
										<option key={option.code} value={option.code}>
											{option.code_desc}
										</option>
									))}
								</Field>
							</div>
							<label htmlFor="trading_company_id" className="col-lg-2 required">Trading Company Name</label>
							<div className="col-lg-4">
								<Field as="select" className="form-select" name="trading_company_id">
									<option>Select</option>
									{result.brokerList !== undefined && result.brokerList.map((option) => (
										<option key={option.id} value={option.id}>
											{option.company_name}
										</option>
									))}
								</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="platform_name" className="col-lg-2 required">Trading Platform Name</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" maxLength="100" type='text' name="platform_name" onBlur={(e) => {
									const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
									if (userConfirmed) {
										var platform_name = e.target.value;
										var slug = funCreateSlug(platform_name)

										setFieldValue('urlrewrite_folder_name', slug);
									} else {
										setFieldTouched('platform_name', true);
									}
								}} />
							</div>
							<label htmlFor="urlrewrite_folder_name" className="col-lg-2 required" >URLRewrite Folder Name</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="urlrewrite_folder_name" maxLength="100" />
							</div>
						</div>
						<hr />
						<div className='mb-3 row'>
							<label htmlFor="image" className='col-lg-2'>Choose an image:</label>
							<div className="col-lg-10">
								<FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_file_name" />
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="logo_file_name" className="col-lg-2 control-label">Logo Image Name:</label>
							<div className="col-lg-3">
								<Field as="input" className="form-control" type="text" maxLength="100" name="logo_file_name" />
							</div>
							<label htmlFor="logo_length" className="col-lg-1 control-label">Width:</label>
							<div className="col-lg-2">
								<NumberInput classNameAtr="form-control" maxLength="5" nameAtr="logo_length" />
							</div>
							<label htmlFor="logo_height" className="col-lg-1 control-label">Height:</label>
							<div className="col-lg-2">
								<NumberInput classNameAtr="form-control" maxLength="5" nameAtr="logo_height" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="owner" className="col-lg-2">Platform Owner</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="owner" maxLength="50" />
							</div>
							<label htmlFor="owner_description" className="col-lg-2">Owner Description</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="owner_description" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="description_brief" className="col-lg-2 required">Brief Description<br /> (in HTML)</label>
							<div className="col-lg-10">
								<CharacterCountTextarea
									nameattr="description_brief"
									maxLength="1000"
									type="textarea"
									rows="3"
									className="form-control"
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="description" className="col-lg-2 required">Description<br /> (in HTML)</label>
							<div className="col-lg-10">
								<CharacterCountTextarea
									nameattr="description"
									type="textarea"
									rows="5"
									className="form-control"
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="highlight_list" className="col-lg-2">Platform Highlights</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="highlight_list" rows="4" />
							</div>
							<label htmlFor="shortcut_keys" className="col-lg-2">Shortcut Keys</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="shortcut_keys" rows="4" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="feature_list" className="col-lg-2">Features List</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="feature_list" rows="5" />
							</div>
							<label htmlFor="feature_desc" className="col-lg-2">Features Detail</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="feature_desc" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="faqs" className="col-lg-2">FAQs</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="faqs" rows="4" />
							</div>
							<label htmlFor="demo" className="col-lg-2">Demo</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="demo" rows="4" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="advantage_brief" className="col-lg-2">Advantage (Brief)</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="advantage_brief" rows="4" />
							</div>
							<label htmlFor="advantage" className="col-lg-2">Advantage</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="advantage" rows="4" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="disadvantage_brief" className="col-lg-2">Disadvantage (Brief)</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="disadvantage_brief" rows="4" />
							</div>
							<label htmlFor="disadvantage" className="col-lg-2">Disadvantage</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="disadvantage" rows="4" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="customer_feedback" className="col-lg-2">Customer Feedback</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="customer_feedback" rows="4" />
							</div>
							<label htmlFor="resources" className="col-lg-2">Resources (User Guide Links, Downloads)</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="resources" rows="4" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="broker_list" className="col-lg-2">Popular Broker Users List</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="broker_list" rows="4" ></Field>
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="oms" className="col-lg-2">Order Management System</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="oms" maxLength="50" />
							</div>
							<label htmlFor="rms" className="col-lg-2">Risk Management System</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="rms" maxLength="50" />
							</div>
						</div>


						<div className="mb-3 row">
							<label htmlFor="segments" className="col-lg-2">Segments Offered</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="segments" maxLength="100" />
							</div>
							<label htmlFor="segment_desc" className="col-lg-2">Segment Description</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="segment_desc" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="products" className="col-lg-2">Products Offered</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="products" maxLength="100" />
							</div>
							<label htmlFor="product_desc" className="col-lg-2">Products Description</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="product_desc" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="exchanges_supported" className="col-lg-2">Exchanges Supported</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="exchanges_supported" maxLength="100" />
							</div>
							<label htmlFor="user_customization" className="col-lg-2">User Customization</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="user_customization" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="reports" className="col-lg-2">Reports</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="reports" rows="5" />
							</div>
							<label htmlFor="charges" className="col-lg-2">Charges Fees</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="charges" rows="5" />
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="order_type" className="col-lg-2">Order Types Offered</label>
							<div className="col-lg-4">
								<Field as="input" className="form-control" type='text' name="order_type" maxLength="100" />
							</div>
							<label htmlFor="order_type_desc" className="col-lg-2">Order Types Description</label>
							<div className="col-lg-4">
								<Field as="textarea" className="form-control" name="order_type_desc" rows="5" />
							</div>
						</div>

						<h2>Features</h2>
						<div className="mb-3 row">
							<label htmlFor="feature_desktop" className="col-lg-2">Trading Teminal</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_desktop">
									{result.optionList !== undefined && result.optionList.map((option) => (
										<option key={option.code} value={option.code}>
											{option.code_desc}
										</option>
									))}
								</Field>
							</div>
							<label htmlFor="feature_web" className="col-lg-2">Website</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_web">
									{result.optionList !== undefined && result.optionList.map((option) => (
										<option key={option.code} value={option.code}>
											{option.code_desc}
										</option>
									))}
								</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="feature_mobile" className="col-lg-2">Mobile App</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_mobile">
									{result.optionList !== undefined && result.optionList.map((option) => (
										<option key={option.code} value={option.code}>
											{option.code_desc}
										</option>
									))}
								</Field>
							</div>
							<label htmlFor="feature_api" className="col-lg-2">API</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_api">
									{result.optionList !== undefined && result.optionList.map((option) => (
										<option key={option.code} value={option.code}>
											{option.code_desc}
										</option>
									))}
								</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="feature_multi_broker" className="col-lg-2">Multiple Brokers </label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_multi_broker">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="feature_equity" className="col-lg-2">Trading in Equity</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_equity">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
							<label htmlFor="feature_currency_derivatives" className="col-lg-2">Currency F&O</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_currency_derivatives">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="feature_commodity" className="col-lg-2">Commodity</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_commodity">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
							<label htmlFor="feature_ipo" className="col-lg-2">IPO</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_ipo">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="feature_mutual_funds" className="col-lg-2">Mutual Funds</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_mutual_funds">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
							<label htmlFor="feature_integrated_backoffice" className="col-lg-2">Integrated Backoffice</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="feature_integrated_backoffice">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
						</div>

						<h2>Charts</h2>
						<div className="mb-3 row">
							<label htmlFor="chart_indicator_count" className="col-lg-2">Indicator Count</label>
							<div className="col-lg-2">
								<NumberInput classNameAtr="form-control" maxLength={20} nameAtr= {"chart_indicator_count"} />
							</div>
							<label htmlFor="chart_type_count" className="col-lg-2">Chart Type Count</label>
							<div className="col-lg-2">
								<NumberInput classNameAtr="form-control" maxLength={20} nameAtr= {"chart_type_count"} />
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="chart_in_same_window_count" className="col-lg-2">Charts in Same Window Count</label>
							<div className="col-lg-2">
								<Field as="input" className="form-control" type='text' name="chart_in_same_window_count" maxLength="20" />
							</div>
							<label htmlFor="trading_from_charts" className="col-lg-2">Trading from Charts</label>
							<div className="col-lg-2">
								<Field as="select" className="form-select" name="trading_from_charts">{result.optionList !== undefined && result.optionList.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}</Field>
							</div>
						</div>

						<div className="mb-3 row">
							<label htmlFor="display_order" className="col-lg-2">Display Order</label>
							<div className="col-lg-2">
								<NumberInput classNameAtr="form-control" maxLength="5" nameAtr="display_order" />
							</div>
							<label htmlFor="display_status" className="col-lg-2">Display Status</label>
							<div className="col-lg-2">
								<Field as="input" className="form-check-input" type="checkbox" name="display_status" onChange={(e) => {
									setFieldValue('display_status', e.target.checked ? true : false);
								}} />
							</div>
						</div>

						<div className="mb-3 text-center">
							{initialValues.lock_status !== 1 && <button
								type="submit"
								className="btn btn-primary btn-block"
							>
								{loading && (
									<span className="spinner-border spinner-border-sm me-2"></span>
								)}
								<span>
									{result.tradingplatform !== undefined && result.tradingplatform.length > 0 ? 'Update' : 'Create'}
								</span>
							</button>
							}

							<button
								type="button"
								className="btn btn-secondary btn-block ms-2"
								onClick={handleGoBack}
							>Cancel</button>
						</div>

						<ValidateFormFields />
					</Form>
				)}
			</Formik>
		</>
	)
}

export default TradingPlatform
