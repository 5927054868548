import React, { useState } from 'react'
import { useFormikContext, Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CheckboxGroup from '../Common/CheckboxGroup';
import { funCreateSlug, isValidVariable } from '../Common/CommonFunction';
import AutocompleteField from '../Common/AutocompleteField';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';
import useApi from '../../../common/useApi';
import FileUpload from '../Common/FileUpload';
import PhoneInput from '../Common/PhoneInput';
import DynamicCharacterCountInputText from "../Common/CharacterCountTextarea";
import MultiSelectTransfer from '../Common/MultiSelectTransfer';

const OFSForm = ({ result, handleGoBack, initialValues, loading }) => {

    const [isStockFieldsDisabled, setisStockFieldsDisabled] = useState(true);

    const [formState, setFormState] = useState({
        autoComplteOptionsCompany: [],
        isAutocompleteCompanyLoading: false,
        checkOpenAndCloseDate: "",
        recordDtPage: initialValues.record_dt,
        offerClosureDatePage: initialValues.offer_closure_date
    });

    const { values, setFieldValue, setFieldTouched } = useFormikContext();

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);
    const api = useApi();

    const handleAutoSearchCompany = async (query) => {
        setFormState((prevState) => ({
            ...prevState,
            isAutocompleteCompanyLoading: true,
        }));
        api.post('/admin-stock-list', {
            search: query
        }).then(response => {
            if (response.data.msg === 1) {
                let result_array = response.data.list.map((value) => Object.values(value).join(' | '));

                setFormState((prevState) => ({
                    ...prevState,
                    autoComplteOptionsCompany: result_array,
                }));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                setFormState((prevState) => ({
                    ...prevState,
                    isAutocompleteCompanyLoading: false,
                }));
            });
    };
    const handleAutoSearchCompanySelect = async (selected) => {
        // Handle form submission
        if (selected != "" && selected !== undefined) {
            let itemArr = selected.split("|").map((item) => item.trim())
            setFieldValue("company_name", itemArr[1]);
            setFieldValue("list_of_script_id", parseInt(itemArr[0]));
            const userConfirmed = window.confirm('Do you want to update the stock details too?');
            if (userConfirmed) {
                callStockApi(itemArr[1]);
            }
        }

    };

    const callStockApi = (selected) => {

        api.post('/admin-listofscript-detail', {
            search: selected
        }).then(response => {
            if (response.data.msg === 1) {
                setisStockFieldsDisabled(false);
                let nse_symbol = response.data.list[0].nse_script_id ?? "";
                let bse_scripcode = response.data.list[0].bse_script_code ?? ''
                setFieldValue('nse_symbol', nse_symbol);
                setFieldValue('bse_scripcode', bse_scripcode);
                setFieldValue('ofs_listing_at_array', []);
                if (isValidVariable(nse_symbol) && isValidVariable(bse_scripcode))
                    setFieldValue("ofs_listing_at_array", ["BSE", "NSE"]);
                else if (isValidVariable(nse_symbol)) setFieldValue("ofs_listing_at_array", ["NSE"]);
                else if (isValidVariable(bse_scripcode)) setFieldValue("ofs_listing_at_array", ["BSE"]);
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
            });
    }

    const handleRetailValue = (e) => {
        e.preventDefault()
        let value = e.target.value;
        let retail_date = new Date(value);
        let dayOfWeek = retail_date.getDay();
        if (dayOfWeek > 5) {
            alert("Date should not be saturday or sunday")
            return;
        }
        setFieldValue("offer_open_date_non_retail", e.target.value)
        if (dayOfWeek == 5) {
            retail_date.setDate(retail_date.getDate() + 2)
        }
        else {
            retail_date.setDate(retail_date.getDate() + 1)
        }
        setFieldValue("offer_open_date_retail", retail_date.toISOString().split('T')[0])
    }

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.buyback !== undefined && result.buyback.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.buyback[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="company_name_auto" className="col-lg-2">Company Name Search</label>
                    <div className="col-lg-8">
                        {/* {(initialValues.company_name_auto[0].label != "" || isAddMode) && */}
                        <Field
                            name="company_name_auto"
                            as={AutocompleteField}
                            options={formState.autoComplteOptionsCompany}
                            onInputChange={(query) => handleAutoSearchCompany(trimValue(query))}
                            onInputSelect={handleAutoSearchCompanySelect}
                            selected={values.company_name_auto ? [values.company_name_auto] : []}
                            tabIndex={1}
                            maxLength="10"
                            isAutocomplteLoading={formState.isAutocompleteCompanyLoading}
                            nameatr="company_name_auto"
                            idatr="company_name_auto_txt"
                        />
                        {/* } */}
                    </div>
                    <br />
                    <br />
                </div>

                <div className="mb-3 row">
                    <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
                    <div className="col-lg-4">
                        <Field as="input" name="company_name" className="form-control" tabIndex={2} maxLength="100" readOnly />
                    </div>
                    <label htmlFor="news_title" className="col-lg-2 required">News Title</label>
                    <div className="col-lg-4">
                        <Field as="input" name="news_title" className="form-control" tabIndex={5} maxLength="50" onBlur={(e) => {
                            var news_title = e.target.value;
                            if (news_title !== "") {
                                const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                if (userConfirmed) {
                                    var slug = funCreateSlug(news_title);
                                    setFieldValue('urlrewrite_folder_name', slug);
                                } else {
                                    setFieldTouched('news_title', true);
                                }
                            }
                        }} />
                    </div>
                </div >

                <div className="mb-3 row">

                    <label htmlFor="URLRewrite_Folder_Name" className="col-lg-2 required">URLRewrite Folder Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={9} type="text" maxLength="50" name="urlrewrite_folder_name" id="urlrewrite_folder_name_txt"
                        />
                    </div>
                </div>
                <hr />

                <h2>OFS Bidding Auto Load</h2>
                <div className="mb-3 row">
                    <label htmlFor="bse_scripcode" className="col-lg-2">BSE Scripcode</label>
                    <div className="col-lg-4">
                        <Field className="form-control stock_list_input" tabIndex={22} type="text" maxLength="15" name="bse_scripcode" disabled={isStockFieldsDisabled}
                        />
                    </div>
                    <label htmlFor="nse_symbol" className="col-lg-2">NSE Symbol</label>
                    <div className="col-lg-4">
                        <Field className="form-control stock_list_input" tabIndex={20} type="text" maxLength="15" name="nse_symbol" disabled={isStockFieldsDisabled}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="bse_ipo_no" className="col-lg-2 ">BSE IPO No</label>
                    <div className="col-lg-4">
                        <Field as="input" name="bse_ipo_no" className="form-control" tabIndex={30}>
                        </Field>
                        <p className="mb-0"><a href="https://www.bseindia.com/publicissue.html" target="_blank">BSE</a> | Ex : In URL={">"} bseindia.com IPONo=<span className="text-primary">6282</span></p>
                    </div>
                </div>

                <hr />
                <h2>OFS Detail</h2>
                <div className="mb-4 row">
                    <label htmlFor="issue_type" className="col-lg-2">Issue Type</label>
                    <div className="col-lg-2">
                        <Field as="select" name="issue_type" id="issue_type" className="form-select" tabIndex={25}>
                            <option value="Offer For Sale">Offer For Sale</option>
                        </Field>
                    </div>
                </div>
                <div className="mb-4 row">
                    <label htmlFor="ofs_listing_at_array" className="col-lg-2">OFS Listing At</label>
                    <div className="col-lg-4">
                        {result && result.issueStockExchangeList !== undefined &&
                            <Field
                                tabIndex={30}
                                name="ofs_listing_at_array"
                                label="OFS Listing At"
                                component={CheckboxGroup}
                                options={result.issueStockExchangeList}
                                className='form-check-input Stock_Exchange_Type_Checkbox me-2'
                            />
                        }
                    </div>
                </div>

                <div className='mb-4 row'>
                    <label htmlFor="issue_process_type" className="col-lg-2">
                        Allocation Methodology
                    </label>
                    <div className="col-lg-4">
                        {result.allocMethodologyList !== undefined && result.allocMethodologyList.map((option, index) => (
                            <div key={option.code} className="d-inline">
                                <Field className="form-check-input" type="radio" name="issue_process_type" tabIndex={35 + index} value={option.code} />&nbsp;{option.code_desc} &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        ))}
                    </div>
                </div>

                <div className='mb-3 row'>
                    <label htmlFor="base_issue_size_shares" className="col-lg-2">Base Issue Size (Shares)</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={50} maxLength="25" nameAtr="base_issue_size_shares" />
                    </div>
                    <div className="col-lg-2">
                        <div style={{ color: '#0000FF' }} >{parseFloat(values.base_issue_size_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>

                    <label htmlFor="total_issue_size_share" className="col-lg-2">Total Issue Size (Shares)</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={55} maxLength="25"
                            nameAtr="total_issue_size_share" />
                    </div>
                    <div className="col-lg-2">
                        <div style={{ color: '#0000FF' }} >{parseFloat(values.total_issue_size_share / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="oversubscription_shares" className="col-lg-2">OverSubscription Option</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25" nameAtr="oversubscription_shares" />
                    </div>
                    <div className="col-lg-2">
                        <div style={{ color: '#0000FF' }} >{parseFloat(values.oversubscription_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>

                    <label htmlFor="face_value" className="col-lg-2">Face Value</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={65} maxLength="5" nameAtr="face_value" />
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="retail_shares" className="col-lg-2">Retail Share</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25" nameAtr="retail_shares" />
                    </div>
                    <div className="col-lg-2">
                        <div style={{ color: '#0000FF' }} >{parseFloat(values.retail_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>

                    <label htmlFor="non_retail_Shares" className="col-lg-2">Non Retail Share</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="25" nameAtr="non_retail_Shares" />
                    </div>
                    <div className="col-lg-2">
                        <div style={{ color: '#0000FF' }} >{parseFloat(values.non_retail_Shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="cutoff_price" className="col-lg-2">Cut Off Price</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={85} maxLength="5" nameAtr="cutoff_price" />
                    </div>
                    <div className="col-lg-2"></div>

                    <label htmlFor="floor_price" className="col-lg-2">Floor Price</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={80} maxLength="5" nameAtr="floor_price" />
                    </div>

                </div>

                <div className='mb-3 row'>
                    <label htmlFor="seller_name" className="col-lg-2">Seller Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={90} type="text" maxLength="150" name="seller_name" />
                    </div>
                    <label htmlFor="percentage_of_equity_shares" className="col-lg-2">% of total equity for ofs</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={80} maxLength="5" nameAtr="percentage_of_equity_shares" />
                    </div>
                </div>
                <hr />

                <h2>Document Links</h2>

                <div className="mb-3 row">
                    <label htmlFor="opening_ofs_link" className="col-lg-2">Opening of Offer for Sale</label>
                    <div className="col-lg-4">
                        <Field className="form-control disableText" tabIndex={95} type="text" maxLength="150" name="opening_ofs_link" />
                    </div>
                    <label htmlFor="live_activities_schedule" className="col-lg-2">Live Activities Schedule</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={100} type="text" maxLength="150" name="live_activities_schedule"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="oversubscription_notice" className="col-lg-2">OverSubscription Notice</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={105} type="text" maxLength="150" name="oversubscription_notice" />
                    </div>
                    <label htmlFor="undersubscription_notice" className="col-lg-2">UnderSubscription Notice</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={105} type="text" maxLength="150" name="undersubscription_notice" />
                    </div>
                </div>
                <hr />

                <h2>Issue Date</h2>

                <div className='mb-3 row'>
                    <label htmlFor="offer_open_date_non_retail" className="col-lg-2">Offer Open Date For Non Retail (T)</label>
                    <div className="col-lg-2">
                        <Field className="form-control dateInput" onChange={handleRetailValue} tabIndex={110} type="date" name="offer_open_date_non_retail" title="YYYY/MM/DD" />
                    </div>
                    <div className="col-lg-2"></div>
                    <label htmlFor="record_dt" className="col-lg-2">Offer Open Date For Retail (T+1)</label>
                    <div className="col-lg-2">
                        <Field disabled={true} className="form-control dateInput" tabIndex={115} type="date" name="offer_open_date_retail" title="YYYY/MM/DD" />
                    </div>
                </div>
                <hr />

                <h2>Shares Offered by Category (For Bidding)</h2>
                <div className='mb-3 row'>
                    <label htmlFor="gen_category" className="col-lg-2">General Category</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr='170' nameAtr="gen_category" />
                    </div>
                    <label htmlFor="retail_category" className="col-lg-2">Retail Category</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr='175' nameAtr="retail_category" />
                    </div>
                    <label htmlFor="emp_category" className="col-lg-2">Employee Category</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr='180' nameAtr="emp_category" />
                    </div>
                </div>
                <hr />

                <h2>Brokers</h2>
                <div className="mb-3 row">
                    <label htmlFor="broker_1" className="col-lg-2">Broker 1</label>

                    <div className="mb-3 row">
                        <MultiSelectTransfer dataList={result.brokerList} initialItemsNameAttr={"initialBrokerValues"} selectedItemsNameAttr={"selectedBrokerValues"} selectedItemsIdsAttr={"selectedBrokerIds"} />
                    </div>

                </div>

                <hr />
                <h2>Company Info</h2>
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="logo_url" className="col-lg-2">Logo Image Name:</label>
                    <div className="col-lg-3">
                        <Field className="form-control" tabIndex={240} type="text" maxLength="100" name="logo_url" />
                    </div>
                    <label htmlFor="logo_length" className="col-lg-1">Width:</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={245} maxLength="3" nameAtr="logo_length" />
                    </div>
                    <label htmlFor="logo_height" className="col-lg-1">Height:</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={250} maxLength="3" nameAtr="logo_height" />
                    </div>

                </div>
                <div className="mb-3 row">
                    <label htmlFor="address_1" className="col-lg-2">Address 1</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={255} type="text" name="address_1" maxLength="50" />
                    </div>
                    <label htmlFor="address_2" className="col-lg-2">Address 2</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={260} type="text" name="address_2" maxLength="50" />
                    </div>

                </div>
                <div className="mb-3 row">
                    <label htmlFor="address_3" className="col-lg-2">Address 3</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={265} type="text" name="address_3" maxLength="50" />
                    </div>
                    <label htmlFor="State" className="col-lg-2">State</label>
                    <div className="col-lg-4">
                        <Field as="select" name="state" className="form-select" tabIndex={266} >
                            <option></option>
                            {result.stateList !== undefined && result.stateList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="phone" className="col-lg-2">Phone</label>
                    <div className="col-lg-4">
                        <PhoneInput classNameAtr={"form-control"} nameAtr={"phone"} tabIndex={270} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="website" className="col-lg-2">Website</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={275} type="text" name="website" maxLength="50" />
                    </div>
                    <label htmlFor="email" className="col-lg-2">Email</label>
                    <div className="col-lg-4">
                        <Field className="form-control" tabIndex={280} type="text" name="email" maxLength="50" />
                    </div>
                </div>
                <div className='mb-3 row'>
                    <label htmlFor="other_issue_detail" className="col-lg-2" >Other Issue Detail</label>
                    <div className="col-lg-8">
                        <DynamicCharacterCountInputText className="form-control" rows="5" maxLength={2000} nameattr={"other_issue_detail"} tabIndex={90} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="company_desc" className="col-lg-2">Company Description (HTML)</label>
                    <div className="col-lg-8">
                        <DynamicCharacterCountInputText className="form-control" rows="8" nameattr={"company_desc"} tabIndex={281} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="financial" className="col-lg-2">Company Financial</label>
                    <div className="col-lg-8">
                        <DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"financial"} tabIndex={300} disabled={true} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">
                        Display Status
                    </label>
                    <div className="col-lg-1">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            name="display_status"
                            tabIndex={335}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="lock_status" className="col-lg-2">
                        Lock Status
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-check-input me-2"
                            type="checkbox"
                            name="lock_status"
                            tabIndex={350}
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }}
                        />
                    </div>
                </div>

                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            tabIndex={405} type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.ofs !== undefined && result.ofs.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}

                    <button
                        tabIndex={410} type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default OFSForm;
