import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearMessage, setMessage } from '../../../state/slices/message';

const ValidateFormFields = () => {
    var className = "error-input";
    const formikContext = useFormikContext();
    const {
        values,
        errors,
        touched
    } = formikContext;
    const dispatch = useDispatch();

    useEffect(() => {
        var elements = document.querySelectorAll('.' + className);
        elements.forEach(function (element) {
            element.classList.remove(className);
        });

        let myform = document.getElementById("myForm");

        var errorMessage = "";
        Object.keys(touched).forEach((fieldName) => {
            if (touched[fieldName] && errors[fieldName]) {
                errorMessage = errorMessage + (errors[fieldName] ?? '') + "<br>";

                var firstFormErrorField = myform[fieldName];
                if (firstFormErrorField !== undefined && firstFormErrorField.classList !== undefined) {
                    firstFormErrorField.classList.add(className);
                }
            }
        });

        if (errorMessage.length !== 0) {
            dispatch(setMessage(errorMessage));
        }else {
            dispatch(clearMessage());
        }
    }, [touched, errors, dispatch]);

    return null;
}

export default ValidateFormFields;
