import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPOFaqAskQuestionForm from './IPOFaqAskQuestionForm.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow, validateInput } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';
import { checkTokenExpiration } from '../../../state/slices/auth.js';

const IPOFaqAskQuestion = () => {
    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";

    const recaptchaRef = useRef(null);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const validationSchema = (user) => {
        return Yup.object({
            faq_added_by_email: user ? "" : Yup.string().trim().email('Invalid email address').required('Your Email is required'),
            faq_added_by_phone: user ? "" : Yup.string().trim().matches(/^\d{10}$/, 'Phone number must be 10 digits').required('Your phone Number is required'),
            faq_question: Yup.string().required('Question is required').min(3, 'Message must be at least 3 characters long'),
        });
    };

    const [initialValues, setInitialValues] = useState({
        faq_added_by_phone: '',
        faq_added_by_email: "",
        faq_question: '',
        recaptcha: '',
    });

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }


    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "Ask Your Question" });
        } else {
            didMountRef.current = "true";
        }
    }, [dispatch, api]);

    const handleSubmit = async (values, { setSubmitting }) => {
        if (!currentUser) {
            let phoneError = validateInput(values.faq_added_by_phone, 'phoneNumber');
            if (phoneError) {
                alert(phoneError);
                setSubmitting(false);
                return;
            }
        }

        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;
        trimmedValues.referrer = document.referrer || location.state?.referrer || '';

        console.log("This is the Values : ", trimmedValues)

        dispatch(startLoading());

        api.post('/ipofaqaskquestion-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=contactus&ref=` + ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row main mt-2'>
                <div className='col-12'>
                    <h1 className='mt-2'>
                        Ask Your Question
                    </h1>
                    <hr />

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema(currentUser)}
                        onSubmit={handleSubmit}
                    >
                        <IPOFaqAskQuestionForm result={result} btnClick={btnClick} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} registeredUser={currentUser} isLoggedIn={isLoggedIn} />
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default IPOFaqAskQuestion
