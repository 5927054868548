import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../common/useApi";
import {
    finishLoading,
    startLoading,
    selectLoading,
} from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import ArticleForm from "./ArticleForm";
import { Formik } from "formik";
import * as Yup from "yup";

const Article = () => {
    const validationSchema = Yup.object({
        category_cd: Yup.string().trim().required("Category is required"),
        title: Yup.string().trim().required("Title is required"),
        urlrewrite_folder_name: Yup.string()
            .trim()
            .required("URLRewrite Folder Name is required"),
        meta_title: Yup.string().trim().required("Meta Title is required"),
        meta_desc: Yup.string().trim().required("Meta Description is required"),
        description: Yup.string().trim().required("Description is required"),
    });
    const location = useLocation();
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [pageData, setPageData] = useState({});
    const [metaData, setMetaData] = useState({
        title: "",
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth)

    const [initialValues, setInitialValues] = useState({
        id: "",
        category_cd: "",
        title: "",
        urlrewrite_folder_name: "",
        description: "",
        author_name: "",
        author_signature_cd: "",
        broker_id: "",
        display_order: "",
        display_status: false,
        featured: "",
        image_name: "",
        image_width: "",
        image_height: "",
        report_ids: "",
        meta_title: "",
        meta_desc: "",
        meta_parent_page_url: "",
        lock_status: false,
        sponsoured_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };
    const getPageData = () => {
        if (location.pathname.includes("admintool9x/article")) {
            return {
                getUrl: "/admin-article-read",
                saveUrl: "/admin-article-save",
                disabled: false
            }
        }
        else if (location.pathname.includes("author/article-list")) {
            return {
                getUrl: "/author-article-read",
                saveUrl: "/author-article-save",
                disabled: true
            }
        }
    }
    useEffect(() => {
        if (didMountRef.current == "true") {
            const page = getPageData();
            setPageData(page)
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ title: "Add New Article" });
            } else {
                setMetaData({ title: "Edit Article" });
            }
            api
                .post(page.getUrl, {
                    id: id,
                })
                .then((response) => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        if (!isAddMode) {
                            setInitialValues(response.data.article[0]);
                        } else {
                            setInitialValues((prevState) => ({
                                ...prevState,
                                display_order: response.data.articleNewDisplayOrder ?? 0,
                                author_name: page.disabled ? (currentUser.name ?? "") : "",
                                author_signature_cd: page.disabled ? (currentUser.signature ?? "") : ""
                            }));
                        }
                    } else {
                        dispatch(setMessage(response.data.error));
                        if (response.data.msg == -2) {
                            navigate("/")
                        }
                    }

                    dispatch(finishLoading());
                })
                .catch((error) => {
                    console.error("Error:", error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] =
                typeof values[key] === "string"
                    ? values[key].trim()
                    : typeof values[key] === "boolean"
                        ? values[key] === true
                            ? 1
                            : 0
                        : values[key];
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        // console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api
            .post(pageData.saveUrl, {
                values: trimmedValues,
            })
            .then((response) => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>{metaData.title}</h1>
            <hr />
            {result !== undefined &&
                result.article !== undefined &&
                result.article.length > 0 &&
                result.article[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>
                )}

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ArticleForm
                    result={result}
                    handleGoBack={handleGoBack}
                    loading={loading}
                    initialValues={initialValues}
                    pageData={pageData}
                />
            </Formik>
        </>
    );
};

export default Article;
