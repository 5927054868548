import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../state/slices/loadingSlice';
import { setMessage } from '../../state/slices/message';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';
import { funFormatDateTime } from '../admintool9x/Common/CommonFunction';
import routeConfig from '../../routeConfig';
{/*Add a new function for Date Format only, Till Now Function is available for both (Date + Time)*/ }

const IPOReviewList = () => {
    const dispatch = useDispatch();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const loading = useSelector(selectLoading);
    const navigate = useNavigate();

    let columns = [
        {
            Header: 'ID',
            accessor: 'ID',
            Cell: ({ cell }) => (<>
                <div>
                    <Link to={routeConfig.dd_review_edit() + "?id=" + cell.row.original.ID} title='Preview Review'>{cell.row.original.ID} </Link>
                </div>
            </>
            )
        },

        {
            Header: 'Type',
            accessor: 'Type',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Type}
                </>
            ),
        },

        {
            Header: 'Issue',
            accessor: 'Company_Name',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Company_Name}
                </>
            ),
        },

        {
            Header: 'Title',
            accessor: 'Title',
            Cell: ({ cell }) => (
                <>
                    <div>
                        <Link to={process.env.REACT_APP_WEB_URL + "ipo_review/" + cell.row.original.URLRewrite_Folder_Name + "/" + cell.row.original.ID} title='Preview Review' target='_blank'>{cell.row.original.Title} <img src="/images/external_link.gif" alt="External Link"></img></Link>
                    </div>
                </>
            ),
        },

        {
            Header: 'Recommendation',
            accessor: 'Recommendation',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Recommendation}
                </>
            ),
        },

        {
            Header: 'Posted On',
            accessor: 'Posted_Date',
            Cell: ({ cell }) => (
                <>
                    {funFormatDateTime(cell.row.original.Posted_Date)}
                </>
            ),
        },

        {
            Header: 'Last Modified On',
            accessor: 'IR.Last_Modified_Date',
            Cell: ({ cell }) => (
                <>
                    {funFormatDateTime(cell.row.original.Last_Modified_Date)}
                </>
            ),
        },

        {
            Header: 'Display Status',
            accessor: 'Display_status',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.Display_status}
                </>
            ),
        },
    ];

    const [result, setResult] = useState({
        tableColumns: columns,
        tableData: [],
        pageNumber: 1,
        totalPages: 1,
        totalRecords: 0,
        pageIndex: 0,
        recordPerPage: 20,
        orderBy: "",
        pageDataLoaded: 0,
        currentSort: "1"
    });

    const getTableData = () => {
        dispatch(startLoading());
        api.post('/ipo-report/dd-reviewlist', {
            pagenumber: result.pageIndex + 1,
            orderby: result.currentSort,
        }).then(response => {
            if (response.data.msg === 1) {
                console.log("This is the Reuslt : ", response.data.reviewList)
                setResult((prevState) => ({
                    ...prevState,
                    tableColumns: columns,
                    tableData: response.data.reviewList,
                    totalRecords: response.data.TotalRecords,
                    recordPerPage: response.data.RecordPerPage,
                    pageNumber: response.data.PageNumber,
                    orderBy: response.data.Orderby,
                    totalPages: response.data.TotalPages,
                    pageDataLoaded: 1,
                }))
            } else {
                dispatch(setMessage(response.data.error));
                if (response.data.msg == -2) {
                    navigate("/")
                }

            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "IPO / NCD Reviews List" })
            getTableData();
        } else {
            didMountRef.current = "true";
        }
    }, [result.pageIndex, result.currentSort]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },

    } = useTable(
        {
            columns: result.tableColumns,
            data: result.tableData,
            manualSortBy: true,
            initialState: {
                pageIndex: result.pageIndex,
                pageSize: result.recordPerPage,
                sortBy: [{ id: 'ID', desc: false }],
            },
            manualPagination: true,
            pageCount: result.totalPages,
        },
        useSortBy,
        usePagination
    );

    const [metaData, setMetaData] = useState({
        title: ""
    });

    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            let currentSort = "";
            if (sortBy.length > 0) {
                currentSort = sortBy[0].id + (sortBy[0].desc ? " desc" : " asc");
            }
            setResult((prevState) => ({
                ...prevState,
                currentSort: currentSort
            }));
        }
    }, [sortBy]);

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
                <meta name="description" content="IPO / NCD Reviews List" />
                <link rel="icon" href="/favicon.ico" type="image/x-icon" />
            </Helmet>

            <div className='row main mt-2'>
                <div className='col-sm-12'>
                    <h1>Reviews by Dilip Davda</h1>

                    <div className="mb-2">
                        <Link to={routeConfig.dd_review_edit()}>Add New Report </Link>
                    </div>
                    <div className='table-responsive'>
                        <div className={`table-container ${loading ? 'blur' : ''}`}>
                            <Table {...getTableProps()} striped bordered hover id='report_table'>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th className={column.className} {...column.getHeaderProps()}>
                                                    <div
                                                        {...column.getSortByToggleProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {column.render('Header')}
                                                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                                    </div>
                                                </th>

                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {rows.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length}>
                                                No Record<br /><br /></td>
                                        </tr>
                                    ) : (
                                        rows.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className={cell.column.className}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </div>

                    {result.totalPages > 1 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Prev onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex - 1 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Item
                                key={result.pageIndex}
                                active={true}
                                onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex }))}
                            >
                                {result.pageIndex + 1}
                            </Pagination.Item>
                            <Pagination.Next onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                            <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                        </Pagination>
                    }

                </div>
            </div >

        </>
    )
}

export default IPOReviewList
