import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import { funCreateSlug } from '../Common/CommonFunction';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const CategoryForm = ({ result, handleGoBack, loading }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.article !== undefined && result.article.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.article[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="category" className="col-lg-2">Category</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type="text" name="cat_type" maxLength="50" tabIndex={5} size="50" />
                    </div>

                    <label htmlFor="website" className="col-lg-2 ">Website</label>
                    <div className="col-lg-4">
                        <Field type='text' className="form-control" name="website" tabIndex={10} size="50"
                            maxLength="50"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="cat_name" className="col-lg-2 ">Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' name="cat_name" maxLength="50" tabIndex={15}
                            size="50"
                            onBlur={(e) => {
                                const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                if (userConfirmed) {
                                    var name = e.target.value;
                                    var slug = funCreateSlug(name)

                                    setFieldValue('urlrewrite_folder_name', slug);
                                } else {
                                    setFieldTouched('cat_name', true);
                                }
                            }} />
                    </div>

                    <label htmlFor="urlrewrite_folder_name" className="col-lg-2 ">URLRewrite</label>
                    <div className="col-lg-4">
                        <Field type='text' className="form-control" name="urlrewrite_folder_name" tabIndex={20}
                            maxLength="100"
                        />
                    </div>
                </div>
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="image_name" className="col-lg-2">Logo URL</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="text" maxLength="100" name="image_name" tabIndex={25} />
                    </div>
                    <label htmlFor="image_width" className="col-lg-1">Width</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} maxLength="5" nameAtr={"image_width"} tabIndex={30} />
                    </div>
                    <label htmlFor="image_height" className="col-lg-1">Height</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} maxLength="5" nameAtr={"image_height"} tabIndex={35} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="cat_description" className="col-lg-2">Description</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="cat_description"
                            type="textarea"
                            tabIndex="40"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={45} maxLength="5" nameAtr="display_order" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={50} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={55}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            {result.category !== undefined && result.category.length > 0 ? 'Update' : 'Create'}
                        </span>
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={60}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default CategoryForm
