import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ProfileForm from './ProfileForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const Profile = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";

    const validationSchema = Yup.object({
        name: Yup.string().trim().required('Your Name is required'),
        display_name: Yup.string().trim().required('Your Display Name is required'),
        email: Yup.string().trim().email('Invalid email address').required('Your Email is required'),
        phone: Yup.string().trim().matches(/^\d{10}$/, 'Phone number must be 10 digits').required('Your phone Number is required'),
        city: Yup.string().trim().required('Your city is required'),
        state: Yup.string().trim().required('Your state is required').notOneOf([''], 'Please select a state'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        login_id: "",
        name: "",
        display_name: "",
        email: "",
        city: "",
        state: "",
        phone: "",
        signature: "",
        email_notification: 0,
        signature_status: 0,
        liked_count_ipo: "",
        message_count_ipo: "",
    });
    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            setMetaData({ "title": "Update You Profile" })

            api.post('/member-profile-read', {
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setInitialValues(response.data.memberInfo[0]);

                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        dispatch(startLoading());

        api.post('/member-profile-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=profile_update&ref=` + ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ProfileForm result={result} loading={loading} initialValues={initialValues} btnClick={btnClick} refValue={ref}/>
            </Formik>


        </>
    )
}

export default Profile;
