import React, { useEffect } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CalendarForm = ({ result, handleGoBack, loading, initialValues }) => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {

    if (values.topic_id !== initialValues.topic_id || values.cal_category_id !== initialValues.cal_category_id) {
      if (result !== undefined && result.topicList !== undefined && result.topicList.length > 0 && result.calendarCategory !== undefined && result.calendarCategory.length > 0) {
        let topic = "";
        if (values.topic_id !== "") topic = result.topicList.find(topic => topic.id == values.topic_id).news_title;
        let category = "";
        if (values.cal_category_id !== "") category = result.calendarCategory.find(category => category.code == values.cal_category_id).code_desc;
        let str = topic + " - " + category
        setFieldValue('cal_title', str)
      }
    }
  }, [values.topic_id, values.cal_category_id])

  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.calendar !== undefined && result.calendar.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.id}
            </div>
          </div>
        }
        <div className="mb-3 row">
          <label htmlFor="topic_id" className="col-lg-2">Topic (News Title)</label>
          <div className="col-lg-4">

            <Field as="select" className="form-select" tabIndex={1} name="topic_id" >
              <option ></option>
              {result.topicList !== undefined && result.topicList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.news_title}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="cal_category_id" className="col-lg-2">Event Category</label>
          <div className="col-lg-2">

            <Field as="select" className="form-select" tabIndex={5} name="cal_category_id">
              <option ></option>
              {result.calendarCategory !== undefined && result.calendarCategory.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="cal_title" className="col-lg-2">Event Title (Max length 100) </label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" tabIndex={10} name="cal_title" maxLength="100" />
          </div>
          <div className="col-lg-2"><p>Number of characters: {values.cal_title.length}</p></div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="cal_desc" className="col-lg-2">Event Description (Max length 4000) </label>
          <div className="col-lg-6">
            <Field tabIndex={15} name="cal_desc" maxLength="4000">
              {({ field }) => (
                <CKEditor
                  editor={ClassicEditor}
                  name={field.name}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'outdent',
                      'indent',
                      '|',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      '|',
                      'undo',
                      'redo',
                      '|',
                      'sourceEditing',
                    ],
                  }}
                  data={initialValues.cal_desc}
                  onChange={(event, editor) => {
                    const updatedData = editor.getData();
                    field.onChange({ target: { name: field.name, value: updatedData } });
                  }}
                />
              )}
            </Field>
          </div>
          <div className="col-lg-2"><p>Number of characters: {values.cal_desc.length}</p></div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="cal_date" className="col-lg-2">Event Date</label>
          <div className="col-lg-2">
            <Field className="form-control" type="date" tabIndex={20} name="cal_date" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-6">
            <Field className="form-check-input" type="checkbox" tabIndex={25} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>

        <div className="mb-3 text-center">
          {result !== undefined && result.lockStatus !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={35}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.calendar !== undefined && result.calendar.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={40}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default CalendarForm
