import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { selectLoading } from '../../../state/slices/loadingSlice';
import { useSelector } from 'react-redux';
const IpoAnchorTabs = ({ idAtr }) => {

  const loading = useSelector(selectLoading);
  return (
    <>
      {loading ? (
        <div className='ms-4 mb-4' style={{ height: '42px' }}>
          <span className="spinner-border spinner-border-sm me-2"></span>
        </div>
      ) :

        <Nav variant="tabs" className='mb-4'>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipoanchor/${idAtr}`} className={`nav-link`}>Anchor Info</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipoanchorscheme/${idAtr}`} className={`nav-link`}>Anchor Scheme</NavLink>
          </Nav.Item>
        </Nav>
      }
    </>
  );
};

export default IpoAnchorTabs;
