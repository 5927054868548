import React, { useEffect, useRef, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import { funCreateSlug } from "../Common/CommonFunction";
import NumberInput from "../Common/NumberInput";
import FileUpload from '../Common/FileUpload';
import CharacterCountInput from '../Common/CharacterCountInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const BookChapterForm = ({
  result,
  handleGoBack,
  initialValues,
  loading,
}) => {

  const { values, setFieldValue, setFieldTouched } =
    useFormikContext();


  const previousTitleValueRef = useRef();
  const previousDescriptionValue = useRef();

  const [blurState, setBlurState] = useState({
    isBlurring: false,
    blurredTextbox: null,
    blurredTextValue: null,
  });

  const handleBlurWrapper = (e) => {
    if (!blurState.isBlurring) {
      setBlurState({ isBlurring: true, blurredTextbox: e.target.name, blurredTextValue: e.target.value });
    }
  };

  useEffect(() => {
    if (result && result.bookChapter && result.bookChapter.length > 0) {
      previousTitleValueRef.current = result.bookChapter[0].title;
      previousDescriptionValue.current = result.bookChapter[0].description;
    }
  }, [result])

  useEffect(() => {
    const handleBlurLogic = async () => {
      if (blurState.blurredTextbox === "title") {
        if (blurState.blurredTextValue != "" && previousTitleValueRef.current !== blurState.blurredTextValue) {
          const userConfirmed = window.confirm(
            "Do you want to update urlrewrite_folder_name?"
          );
          if (userConfirmed) {
            setFieldValue("urlrewrite_folder_name", funCreateSlug(blurState.blurredTextValue));
          } else {
            setFieldTouched("urlrewrite_folder_name", true);
          }
        }
        previousTitleValueRef.current = blurState.blurredTextValue;
      }
      if (blurState.blurredTextbox == "description") {
        if (previousDescriptionValue.current !== blurState.blurredTextValue) {
          changeDescription();
        }
        previousDescriptionValue.current = blurState.blurredTextValue;
      }
      setBlurState({ isBlurring: false, blurredTextbox: null, blurredTextValue: null });
    };
    if (blurState.isBlurring) {
      handleBlurLogic();
    }
  }, [blurState]);

  const changeDescription = () => {
    if (values.description && values.description.trim() !== "") {
      const userConfirmed = window.confirm(
        "Do you want to update the topic header list?"
      );

      if (userConfirmed) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(values.description, "text/html");

        const divToRemove = doc.querySelector(".content-header");
        if (divToRemove) {
          divToRemove.remove();
        }

        const contentHeader = document.createElement("p");
        contentHeader.textContent = "Content:";

        const ol = document.createElement("ol");
        doc.querySelectorAll("h2").forEach((element) => {
          let h2Text = element.textContent.trim();
          h2Text = h2Text.replace(/^\d+\.\s*/, "");
          const currentH2 = funCreateSlug(h2Text);
          element.setAttribute("id", currentH2);

          const li = document.createElement("li");
          const a = document.createElement("a");
          a.href = `#${currentH2}`;
          a.title = h2Text;
          a.textContent = h2Text;

          li.appendChild(a);
          ol.appendChild(li);
        });

        const newContent = document.createElement("div");
        newContent.className = "content-header";
        newContent.appendChild(contentHeader);
        newContent.appendChild(ol);

        const body = doc.body;
        body.insertBefore(newContent, body.firstChild);

        const updatedHTML = body.innerHTML.trim();
        const formattedHTML = beautifyHTML(updatedHTML);
        setFieldValue("description", formattedHTML);
      }
    }
  };

  const beautifyHTML = (html) => {
    const indentSize = 4; // Number of spaces for indentation
    let indentLevel = 0;   // Current indentation level

    // This regex will match all HTML tags
    const regex = /(<\/?[^>]+>)/g;

    return html
      .replace(/>\s+</g, '><') // Remove unnecessary spaces between tags
      .split(regex) // Split HTML by tags, keeping the tags in the resulting array
      .map((fragment) => {
        // Trim whitespace around the fragment
        const trimmedFragment = fragment.trim();
        if (trimmedFragment === '') return ''; // Skip empty fragments

        const isTag = /<\/?[^>]+>/.test(trimmedFragment);
        const isClosingTag = trimmedFragment.startsWith('</');
        const isSelfClosing = trimmedFragment.endsWith('/>');

        // Add indentation for tags
        let indent = ' '.repeat(indentSize * indentLevel);

        if (isTag) {

          if (isClosingTag) {
            indentLevel--; // Decrease indentation for closing tags
          }

          // Add indentation before the tag
          let formattedTag = `${indent}${trimmedFragment}`;

          formattedTag += '\n'; // Add newline after tag
          // Adjust indentation level
          if (!isClosingTag && !isSelfClosing) {
            indentLevel++; // Increase indentation for opening tags
          }

          return formattedTag; // Return formatted tag
        } else {
          // It's a text fragment; just return it with indentation
          return `${indent}${trimmedFragment}\n`;
        }
      })
      .join('') // Join the formatted fragments back into a single string
      .trim(); // Trim any leading/trailing whitespace
  };
  return (
    <Form id="myForm">
      {result !== undefined &&
        result.bookChapter !== undefined &&
        result.bookChapter.length > 0 && (
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">
              ID:
            </label>
            <div className="col-lg-4">{result.bookChapter[0].id}</div>
          </div>
        )}

      <div className="mb-3 row">
        <label htmlFor="book_module_id" className="col-lg-2 required">
          Module
        </label>
        <div className="col-lg-4">
          <Field
            as="select"
            name="book_module_id"
            className="form-select"
            tabIndex={1}
          >
            <option></option>
            {result.bookModuleList !== undefined &&
              result.bookModuleList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
          </Field>
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="title" className="col-lg-2 required">
          Title
        </label>
        <div className="col-lg-4">
          <Field
            className="form-control"
            type="text"
            tabIndex={5}
            name="title"
            maxLength="200"
            onBlur={(e) => {
              handleBlurWrapper(e);
            }}
          />
        </div>

        <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">
          URLRewrite Folder Name
        </label>
        <div className="col-lg-4">
          <Field
            className="form-control"
            type="text"
            tabIndex={10}
            name="urlrewrite_folder_name"
            maxLength="50"
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="category_id" className="col-lg-2 required">
          Category
        </label>
        <div className="col-lg-4">
          <Field
            as="select"
            name="category_id"
            className="form-select"
            tabIndex={15}
          >
            <option></option>
            {result.chapterCategoryList !== undefined &&
              result.chapterCategoryList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
          </Field>
        </div>
      </div>

      <hr />
      <h3>Meta Data</h3>

      <div className="mb-3 row">
        <label htmlFor="meta_title" className="col-lg-2">
          Meta Title (Max 60)
        </label>
        <div className="col-lg-4">
          <CharacterCountInput
            nameattr="meta_title"
            type="text"
            maxLength="60"
            tabIndex="20"
            className="form-control"
          />

        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="meta_desc" className="col-lg-2 required">
          Meta Description
          <br /> (Text 200 Char)
        </label>
        <div className="col-lg-10">
          <CharacterCountTextarea
            nameattr="meta_desc"
            maxLength="200"
            type="textarea"
            tabIndex="25"
            rows="5"
            className="form-control"
          />
        </div>
      </div>

      <hr />
      <div className='mb-3 row'>
        <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
        <div className="col-lg-10">
          <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="image_name" className="col-lg-2">
          Image Name:
        </label>
        <div className="col-lg-2">
          <Field
            className="form-control"
            type="text"
            maxLength="100"
            tabIndex={30}
            name="image_name"
          />
        </div>
        <label htmlFor="image_width" className="col-lg-1">
          Width:
        </label>
        <div className="col-lg-2">
          <NumberInput classNameAtr="form-control" tabIndexAtr={35} maxLength="5" nameAtr="image_width" />
        </div>
        <label htmlFor="image_height" className="col-lg-1">
          Height:
        </label>
        <div className="col-lg-2">
          <NumberInput classNameAtr="form-control" tabIndexAtr={40} maxLength="5" nameAtr="image_height" />
        </div>

      </div>
      <div className="mb-3 row">
        <label htmlFor="brief_description" className="col-lg-2 required">
          Brief Description
          <br /> (HTML)
        </label>
        <div className="col-lg-10">
          <CharacterCountTextarea
            nameattr="brief_description"
            type="textarea"
            maxLength="800"
            tabIndex="45"
            rows="3"
            className="form-control"
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="description" className="col-lg-2 required">
          Description
          <br /> (HTML)
        </label>
        <div className="col-lg-10">
          <CharacterCountTextarea
            nameattr="description"
            type="textarea"
            tabIndex="50"
            rows="10"
            className="form-control"
            onBlur={(e) => {
              handleBlurWrapper(e);
            }}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="key_takeaways" className="col-lg-2">
          KeysTakeaways
          <br /> (HTML)
        </label>
        <div className="col-lg-10">
          <CharacterCountTextarea
            nameattr="key_takeaways"
            type="textarea"
            maxLength="1200"
            tabIndex="55"
            rows="5"
            className="form-control"
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="report_ids" className="col-lg-2">
          Report IDs
        </label>
        <div className="col-lg-2">
          <Field
            className="form-control"
            type="text"
            maxLength="100"
            tabIndex={60}
            name="report_ids"
          />
        </div>
        <label htmlFor="glossary_ids" className="col-lg-2">
          Glossary IDs
        </label>
        <div className="col-lg-2">
          <Field
            className="form-control"
            type="text"
            maxLength="100"
            tabIndex={65}
            name="glossary_ids"
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="display_order" className="col-lg-2">
          Display Order
        </label>
        <div className="col-lg-2">
          <Field
            className="form-control"
            type="text"
            tabIndex={70}
            name="display_order"
            maxLength="6"
          />
        </div>
        <label htmlFor="display_status" className="col-lg-2">
          Display Status
        </label>
        <div className="col-lg-2">
          <Field
            className="form-check-input"
            type="checkbox"
            tabIndex={75}
            name="display_status"
            onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
          />
        </div>
        <label htmlFor="lock_status" className="col-lg-2">
          Lock Status
        </label>
        <div className="col-lg-2">
          <Field
            className="form-check-input"
            type="checkbox"
            tabIndex={80}
            name="lock_status"
            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }}
          />
        </div>
      </div>
      <hr />
      <div className="mb-3 text-center">
        {initialValues.lock_status != 1 && (
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={85}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              {result.bookChapter !== undefined && result.bookChapter.length > 0
                ? "Update"
                : "Create"}
            </span>
          </button>
        )}

        <button
          type="button"
          className="btn btn-secondary btn-block ms-2"
          onClick={handleGoBack}
          tabIndex={90}
        >
          Cancel
        </button>
      </div>
      <ValidateFormFields />
    </Form>
  );
};

export default BookChapterForm;
