import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoAnchorInvestorForm from './IpoAnchorInvestorForm';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings, isValidImageFileName } from '../Common/CommonFunction';
import IpoTabs from './IPONewPageTabs';
import FileUpload from '../Common/FileUpload';
//PENDING: Things related to file upload like validations and url update are pending

const IpoAnchorInvestor = () => {

    const validationSchema = Yup.object({
        anchor_investor_url: Yup.string().trim().required('Select Anchor file to upload'),
    });

    const validate = (e) => {
        if (e.issue_category != "Mainline") {
            for (let i = 0; i < e.newIpoAnchorInvestor.length; i++) {
                if (!e.newIpoAnchorInvestor[i].anchor_investor_id) {
                    alert(`Anchor Investor Name at Sr No.${i + 1} cannot be blank`);
                    return false;
                } else if (!e.newIpoAnchorInvestor[i].shares_allocated) {
                    alert(`No. of Shares Allocated at Sr No.${i + 1} cannot be blank`);
                    return false;
                }
            }
        }

        // if (e.issue_category != "SME") {
        //     if (e.newIpoAnchorInvestor.length == 0) {
        //         alert(`Please enter atlest one anchor investor!`);
        //         return false;
        //     }
        // }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        issue_price_upper: "",
        issue_price_final: "",
        issue_category: "",
        anchor_investor_status: false,
        ipo_confirmation_status: false,
        anchor_investor_url: "",
        anchor_investor_url_full: "",
        file_name: "",
        filebase64: null,
        fileoverwrite: false,
        newIpoAnchorInvestor: [
            {
                ipo_id: "",
                series: "",
                anchor_investor_id: "",
                anchor_scheme_name: "",
                shares_allocated: "",
                bid_price: "",
                anchor_investor_portion: "",
                total_amount_allocated: "",
                display_status: false
            }
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Anchor Investor" })
            api.post('/admin-new-ipoanchorinvestor-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    console.log("The result is: ", response.data);
                    setResult(response.data);
                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        company_name: response.data.ipoDetail[0].company_name,
                        lock_status: response.data.ipoDetail[0].lock_status,
                        ipo_confirmation_status: response.data.ipoDetail[0].ipo_confirmation_status,
                        issue_price_upper: response.data.ipoDetail[0].issue_price_upper,
                        issue_price_final: response.data.ipoDetail[0].issue_price_final,
                        issue_category: response.data.ipoDetail[0].issue_category,
                        anchor_investor_status: response.data.ipoDetail[0].anchor_investor_status,
                        anchor_investor_url: response.data.ipoDetail[0].anchor_investor_url,
                        anchor_investor_url_full: response.data.ipoDetail[0].anchor_investor_url_full,
                        file_name: response.data.ipoDetail[0].file_name,
                        newIpoAnchorInvestor: response.data.newIpoAnchorInvestor
                    }))
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);
        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            if (trimmedValues.filebase64 != "") {

                if (trimmedValues.anchor_investor_url == "") {
                    dispatch(setMessage("Error! Enter Anchor investor url"));
                    return;
                } else {
                    trimmedValues.anchor_investor_url = trimmedValues.anchor_investor_url.toLowerCase();
                    // if (!isValidImageFileName(trimmedValues.anchor_investor_url)) {
                    //     return false;
                    // }
                }
            }
            dispatch(startLoading());

            api.post('/admin-new-ipoanchorinvestor-save', {
                values: trimmedValues
            }
            ).then(response => {
                console.log(response.data);
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }
        setSubmitting(false);
    };

    return (
        <>
            <IpoTabs idAtr={id} ipo_confirm={result?.ipoDetail?.[0]?.ipo_confirmation_status} />
            {initialValues !== undefined && initialValues.anchor_investor_status === 1 && (
                <>
                    <Helmet>
                        <title>{metaData.title}</title>
                        <meta name="title" content={metaData.title} />
                    </Helmet>

                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />

                    {result !== undefined &&
                        result.ipoDetail !== undefined &&
                        result.ipoDetail.length > 0 &&
                        result.ipoDetail[0].lock_status == 1 && (
                            <h3 className="text-danger">
                                Record Is Locked , you can't update it.
                            </h3>
                        )}

                    {result !== undefined && result.id !== undefined && result.id.length > 0 &&

                        <div className="mb-3 row">
                            <label htmlFor="" className="col-lg-2">ID:</label>
                            <div className="col-lg-4">
                                {id}
                            </div>
                        </div>
                    }

                    {result !== undefined && result.ipoDetail !== undefined && result.ipoDetail[0].company_name !== undefined &&

                        <div className="my-3 row">
                            <label htmlFor="" className="col-2">Company Name:</label>
                            <div className="col-8" tabIndex={5}>
                                <b>{result.ipoDetail[0].company_name}</b>
                            </div>
                        </div>

                    }

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >

                        {({ }) => (
                            <Form id='myForm'>
                                <div className='mb-3 row'>
                                    <label htmlFor="image" className='col-lg-2'>
                                        Anchor File Name (only pfd allowed) <br />
                                        <span className="text-warning">(Add .pdf in file name)</span>
                                    </label>
                                    <div className="col-lg-10">
                                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="anchor_investor_url" pdfOnly={true} />
                                    </div>
                                </div>
                                <br></br>
                                <div className="row mb-3">
                                    <label htmlFor="anchor_investor_url" className="col-lg-2">
                                        Anchor Investor File Name
                                    </label>
                                    <div className="col-lg-4">
                                        <Field
                                            className="form-control"
                                            name="anchor_investor_url"
                                            id="txtAnchor_Investor_Url"
                                            tabIndex={20}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="anchor_investor_url_full" className="col-lg-2">
                                        Anchor Investor Url
                                    </label>
                                    <div className="col-lg-8">
                                        <Field
                                            className="form-control"
                                            name="anchor_investor_url_full"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <table className="table table-bordered table-striped" id="repeater1" tabIndex={25}>
                                    <thead>
                                        <tr className="bg-secondary text-white">
                                            <th style={{ width: "5%" }}>S.No.</th>
                                            <th style={{ width: "35%" }}>Anchor Investor Name</th>
                                            <th style={{ width: "10%" }}>No. Of Shares Allocated</th>
                                            <th style={{ width: "10%" }}>Bid Price (per share) <br></br> (Rs)</th>
                                            <th style={{ width: "10%" }}>Anchor Investor Portion <br></br> (%)</th>
                                            <th style={{ width: "10%" }}>Total Amount Allocated <br></br> (Rs)</th>
                                            <th style={{ width: "10%" }}>Display Status</th>
                                            <th style={{ width: "10%" }}>Action</th>
                                        </tr>
                                    </thead>

                                    <FieldArray
                                        name="newIpoAnchorInvestor"
                                        render={({ push, remove }) => (
                                            <>
                                                <tbody>
                                                    <IpoAnchorInvestorForm remove={remove} result={result} />
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="9" className="text-end">
                                                            <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({
                                                                ipo_id: "", series: "", anchor_investor_id: "", anchor_scheme_name: "",
                                                                shares_allocated: "", bid_price: initialValues.issue_price_final || initialValues.issue_price_upper, anchor_investor_portion: "", total_amount_allocated: "", display_status: false
                                                            })}>
                                                                Add +
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </>
                                        )}
                                    />
                                </table>
                                <br></br>

                                <div className="mb-3 text-center">
                                    {initialValues.lock_status !== 1 && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                            disabled={loading}
                                            tabIndex={30}
                                        >
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm me-2"></span>
                                            )}
                                            <span>
                                                Update
                                            </span>
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block ms-2"
                                        onClick={handleGoBack}
                                        tabIndex={35}
                                    >Cancel</button>
                                </div>
                                <ValidateFormFields />
                            </Form>
                        )}
                    </Formik>
                </>
            )}

            {result !== undefined && initialValues.anchor_investor_status == 0 && (
                <div className="alert alert-warning" role="alert">
                    No Anchor Investor
                </div>
            )}
        </>
    )
}

export default IpoAnchorInvestor;
