import React from 'react'
import { Link } from 'react-router-dom'
import routeConfig from '../../routeConfig'

const Home = () => {
  return (

    <div className='row main'>
      <div className='col-sm-12 pt-2'>


        <h2>What would you like to do?</h2>
        <ol>
          <li><Link to="/ipo_post_comment.asp">IPO's - Post Comment</Link></li>
          <li><Link to="/grey_market_post_comment.asp">IPO Grey Market Forum - Post Comment</Link></li>
          <li><Link to="/post_comment_chr.asp?t=16&a=1">Share Broker - Post Review or Comments</Link></li>
          <li><Link to="/ipo_faq_ask_question.asp">IPO FAQ's - Ask your question. </Link></li>
          <li><Link to="/post_comment_chr.asp?t=11&a=1">Sign our Guestbook</Link></li>
        </ol>
        <h3>Manage your profile</h3>
        <ol>
          <li><Link to={routeConfig.registration()}>Register with us</Link></li>
          <li><Link to={routeConfig.forgot_password()}>Forgot Password</Link></li>
          <li><Link to={routeConfig.change_pwd()}>Change Password</Link></li>
          <li><Link to={routeConfig.profile()}>Update Profile</Link></li>
        </ol>
      </div>
    </div>

  )
}

export default Home
