import { isValidVariable } from "./components/admintool9x/Common/CommonFunction";

const routeConfig = {
  submission: () => `/Submission_Success.asp`,
  accessdenied: () => `/accessdenied.asp`,
  profile: () => `/profile.asp`,
  contactus: () => `/contactus.asp`,
  registration: () => `/registration.asp`,
  change_pwd: () => `/change_pwd.asp`,
  forgot_password: () => `/login_forgot_password.asp`,
  ipo_recommendation_list: () => `/ipo_recommendation_list.asp`,
  ipo_recommendation_post: () => `/ipo_recommendation_post.asp`,
  post_comment_chr: () => `/post_comment_chr.asp`,
  likeUnlike: (id,pageType,actionType) => `/LikeSpamFeed/${id}/${pageType}/${actionType}`,
  broker_rating: () => `/broker_rating.asp`,
  ipo_post_comment: () => `/ipo_post_comment.asp`,
  ipo_edit_comment: () => `/ipo_edit_comment.asp`,
  ipo_faq_ask_question: () => `/ipo_faq_ask_question.asp`,
  ipo_edit_bookmark: () => `/ipo_edit_bookmark.asp`,
  dd_review_list: () => '/ipo-report/ipo_review_list_publishers.asp',
  dd_review_edit: () => '/ipo-report/ipo_review_edit_publishers.asp',
  author_report_home: () => '/ipo-report/',
  author_report: () => '/ipo-report/default.asp',
  author_report_edit: () => '/ipo-report/AuthorReportEdit.asp',
  tool_gmp_list: () => "/tools/gmp.asp",
  tool_gmp_edit: () => "/tools/gmp_edit.asp",
  leads_sme_ipo_lm: () => "/leads_sme_ipo_lm.asp",
  sqlfind: () => "/admintool9x/sql_findinalltables",
  sql_editor: () => "/admintool9x/sql_editor.asp",
  subscriptionfile: () => "/admintool9x/subscriptionfileedit.asp",
  logsfile: () => "/admintool9x/logsfileedit.asp",
  zerodha_upload: () => "/admintool9x/zerodha_leads_status_upload.asp",
  tmpsfile: () => "/admintool9x/tmpsfileedit.asp",
  report: (id, parameter_id) => isValidVariable(parameter_id) ? `/admintool9x/report/${id}/${parameter_id}/` : `/admintool9x/report/${id}/`,
  report_create: (id) => `/admintool9x/report-create/${id}/`,
  
};

export default routeConfig;