import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import { Link } from 'react-router-dom';
import routeConfig from '../../../routeConfig';
import NumberInput from '../../admintool9x/Common/NumberInput';

const ProfileForm = ({ result, btnClick, loading, initialValues, refValue }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Form id='myForm' className='main'>
        <div className="row">
          <div className="col-6">
            <legend className="text-start">Update Your Profile</legend>
          </div>
          <div className="col-6 text-end">
            {result !== undefined && result.memberInfo !== undefined && result.memberInfo.length > 0 &&
              <p className="mb-0 mt-2">IPO Messages: {result.memberInfo[0].message_count_ipo}, Likes: {result.memberInfo[0].liked_count_ipo}</p>
            }
          </div>
        </div>

        <hr className="mt-0" />
        <p>Welcome {result && result.memberInfo && result.memberInfo[0].name}, Please fill the below information to update your profile.</p>
        <div className="mb-3 row">

          {result !== undefined && result.memberInfo !== undefined && result.memberInfo.length > 0 &&
            <>
              <label htmlFor="" className="col-lg-2">Login ID</label>
              <div className="col-lg-6">
                {result.memberInfo[0].email}

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to={`${routeConfig.change_pwd()}?ref=${refValue}`} alt="Change Password" className="btn btn-success btn-xs">Change password
                </Link>
              </div>
            </>
          }
        </div>

        <div className="mb-3 row">
          <label htmlFor="name" className="col-md-2">Name</label>
          <div className="col-md-4">
            <Field type="text" id="name" name="name" placeholder="Your Full Name" className="form-control" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_name" className="col-md-2">
            Display Name
            <OverlayTrigger
              trigger="focus"
              placement="bottom"
              overlay={
                <Popover id="popover-display-name">
                  <Popover.Header as="h3">Display Name</Popover.Header>
                  <Popover.Body>
                    Your display name is only visible to all the visitors.
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link" size="sm">
                <img
                  src="/images/question_inactive.gif"
                  style={{ marginLeft: '5px', height: '16px', width: '16px' }}
                  alt="What is this?"
                  title="What is this?"
                />
              </Button>
            </OverlayTrigger>
          </label>
          <div className="col-md-4">
            <Field
              type="text"
              id="display_name"
              name="display_name"
              maxLength="50"
              placeholder="Your Display Name"
              className="form-control"
            />
            <p className="text-warning">Note: Restricted Display Name (Admin, Chittor, Chittorgarh)</p>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="phone" className="col-md-2">Phone</label>
          <div className="col-md-4">
            <NumberInput classNameAtr="form-control" tabIndexAtr={15} maxLength="13" nameAtr="phone" placeholder="Your Phone Number" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="email" className="col-md-2">Email</label>
          <div className="col-md-4">
            <Field type="text" id="email" name="email" maxLength={50} placeholder="Your Email Address" className="form-control" readOnly />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="city" className="col-md-2">City</label>
          <div className="col-md-4">
            <Field type="text" id="city" name="city" placeholder="Your City Name" className="form-control" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="state" className="col-md-2">State</label>
          <div className="col-md-4">
            <Field as="select" className="form-select" tabIndex={30} maxLength="30" name="state">
              <option >Select State</option>
              {result.stateList !== undefined && result.stateList.map((option) => (
                <option key={option.name} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Field>
          </div>
        </div>


        <div className="mb-3 row">
          <label className="form-check-label col-md-2" htmlFor="SwitchEmailNotification">Email Notification</label>
          <div className="col-md-2">
            <div className="form-check form-switch ">
              <Field className="form-check-input " name="email_notification" type="checkbox" role="switch" id="SwitchEmailNotification" onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue('email_notification', newValue);
              }}
              />
            </div>
          </div>
        </div>

        {result && result.memberInfo && result.memberInfo[0].signature_status == 1 ? (
          <div className="mb-3 row">
            <label htmlFor="signature" className="col-md-2">
              Signature
              <OverlayTrigger
                trigger="focus"
                placement="bottom"
                overlay={
                  <Popover id="popover-signature">
                    <Popover.Header as="h3">Signature</Popover.Header>
                    <Popover.Body>
                      Your signature is displayed below all your messages.
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant="link" size="sm" tabIndex={0}>
                  <img
                    src="/images/question_inactive.gif"
                    className="ms-2"
                    width="16"
                    height="16"
                    alt="What is this?"
                  />
                </Button>
              </OverlayTrigger>
            </label>
            <div className="col-md-4">
              <Field
                as="textarea"
                rows="5"
                cols="55"
                name="signature"
                className="form-control"
                placeholder="Your Signature"
              />
            </div>
          </div>
        ) : null
        }


        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                Update Profile
              </span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={btnClick}
          >Close</button>
        </div>
        <ValidateFormFields />
      </Form >

    </>
  )
}

export default ProfileForm
