import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../state/slices/auth";
import { setMessage } from "../state/slices/message";
import authService from '../services/auth.service';
import Cookies from 'js-cookie';

const ApiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
    baseURL: ApiUrl,
});

const useApi = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Add a request interceptor
    api.interceptors.request.use(
        (config) => {
            const user = JSON.parse(Cookies.get("user") || null);
            if (user) {
                if (authService.isTokenExpired()) {
                    dispatch(logout());
                    navigate("/accessdenied.asp");
                    dispatch(setMessage("Session expired. Please log in again."));
                    return Promise.reject('Session expired. Please log in again.');
                }
                config.headers['x-access-token'] = user.accessToken;
            }else{
                // dispatch(logout());
                // navigate("/accessdenied.asp");
                // dispatch(setMessage("Session expired. Please log in again."));
                // return Promise.reject('Session expired. Please log in again.');
            }
            return config;
        },
        (error) => Promise.reject(error)
    );


    // Add a response interceptor
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (!error.response) {
                // Network or server issue
                dispatch(setMessage("Unable to connect to the server. Please check your network connection."));
            } else if (error.response.status === 401) {
                // Unauthorized error
                dispatch(setMessage("Session expired. Please log in again."));
                try {
                    dispatch(logout());
                    navigate("/accessdenied.asp");
                } catch (err) {
                    console.log("Catch Error: " + err);
                }
            } else if (error.response.status === 500) {
                // Internal server error
                dispatch(setMessage(error.response.data.error || "An unexpected server error occurred."));
            } else {
                // General error handler
                dispatch(setMessage(error.response.data.message || "An unexpected error occurred. Please try again later."));
            }

            return Promise.reject(error);
        }
    );

    return api;
};

export default useApi;
