import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../common/useApi";
import {
  finishLoading,
  startLoading,
  selectLoading,
} from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import NCDCouponRateForm from "./NCDCouponRateForm";
import { FieldArray, Formik, Form, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import ValidateFormFields from "../Common/ValidateFormFields";
import NCDTabs from "./NCDTabs";
import { funTrimNestedStrings } from '../Common/CommonFunction';

const NCDCouponRate = () => {
  const validationSchema = Yup.object({});

  const validate = (e) => {
    for (let i = 0; i < e.couponData.length; i++) {
      if (!e.couponData[i].frequency_of_interest_payment) {
        alert(`Frequency of interest payment at Sr No.${i + 1} cannot be blank`);
        return false;
      }
      else if (!e.couponData[i].nature) {
        alert(`Nature at Sr No.${i + 1} cannot be blank`);
        return false;
      }
      else if (!e.couponData[i].tenor) {
        alert(`Tenor at Sr No.${i + 1} cannot be blank`);
        return false;
      }
      else if (!e.couponData[i].amount_maturity) {
        alert(`Amount on maturity at Sr No.${i + 1} cannot be blank`);
        return false;
      }
      else if (e.couponData[i].coupon_rate !== "" && parseFloat(e.couponData[i].coupon_rate) < 0) {
        alert("The values in coupon rate(%) per annum cannot be negetive. Please check all the values and remove negetives if any.");
        return false;
      }
      else if (e.couponData[i].effective_yield !== "" && parseFloat(e.couponData[i].effective_yield) < 0) {
        alert("The values in effective yield(% per annum) cannot be negetive. Please check all the values and remove negetives if any.");
        return false;
      }
    }
    return true;
  }

  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [metaData, setMetaData] = useState({
    title: "",
  });
  const { id } = useParams();
  const isAddMode = !id;
  const api = useApi();
  const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    id: "",
    company_name: "",
    ncd_coupon_note: "",
    digitInput: "1",
    lock_status: false,
    couponData: [
      {
        id: '',
        ncd_id: '',
        series: "",
        frequency_of_interest_payment: "",
        nature: "",
        tenor: "",
        tenor_unit: "",
        coupon_rate: "",
        effective_yield: "",
        amount_maturity: "",
        display_status: false,
      },
    ],
  });

  const handleGoBack = () => {
    dispatch(setMessage(""));
    navigate("/admintool9x/report/90"); // Navigate back one step in history
  };

  const [couponDataLength, setCouponDataLength] = useState(0);

  useEffect(() => {
    if (didMountRef.current == "true") {
      dispatch(startLoading());
      console.log("id : " + id);
      if (isAddMode) {
        setMetaData({ title: "Add New NCD Coupon Rate" });
      } else {
        setMetaData({ title: "Edit NCD Coupon Rate" });
        api
          .post("/admin-ncdcoupon-read", {
            id: id,
          })
          .then((response) => {
            if (response.data.msg === 1) {
              setResult(response.data);
              console.log(response.data);

              setInitialValues((prevState) => ({
                ...prevState,
                id: response.data.id,
                ncd_coupon_note: response.data.ncd[0].ncd_coupon_note,
                display_order: response.data.ncdNewDisplayOrder ?? 0,
                couponData: response.data.couponData,
              }));
              console.log('length is :', response.data.couponData.length)
              setCouponDataLength(response.data.couponData.length)
            }
            else {
              dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
          })
          .catch((error) => {
            console.error("Error:", error);
            dispatch(finishLoading());
          });
      }
    } else {
      didMountRef.current = "true";
    }
  }, []); // Run once on mount


  const handleSubmit = (values, { setSubmitting }) => {
    const trimmedValues = funTrimNestedStrings(values);
    if (validate(trimmedValues)) {
      // Perform actions here, such as making an API request
      console.log("Form submitted with values:", trimmedValues);
      dispatch(startLoading());

      api.post("/admin-ncdcoupon-save", {
        values: trimmedValues,
      })
        .then((response) => {
          if (response.data.msg === 1) {
            dispatch(setMessage(response.data.success));
            //navigate("/admintool9x/report/90");
          } else {
            dispatch(setMessage(response.data.error));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          dispatch(finishLoading());
        });
    }
    // Set submitting to false to allow the form to be submitted again
    setSubmitting(false);
  };


  return (
    <>
      <NCDTabs idAtr={result.id} />
      <br />
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
      </Helmet>

      <h1>{metaData.title}</h1>
      <hr />

      {result !== undefined && result.ncd !== undefined && result.ncd.length > 0 &&
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">ID:</label>
          <div className="col-lg-4">
            <b>{result.id}</b>
          </div>

          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">Company Name:</label>
            <div className="col-lg-6">
              <b>{result.ncd[0].company_name}</b>
            </div>
          </div>
        </div>

      }

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form id="myForm">
          <table className="table table-bordered table-striped" id="repeater1">
            <thead>
              <tr className="bg-secondary text-white">
                <th style={{ width: "190px", background: "grey", color: "white" }}>Series</th>
                <th style={{ background: "grey", color: "white" }}>Frequency of Interest Payment</th>
                <th style={{ background: "grey", color: "white" }}>Nature</th>
                <th style={{ background: "green", color: "white" }}>Tenor</th>
                <th style={{ background: "green", color: "white" }}>Tenor Unit</th>
                <th style={{ background: "grey", color: "white" }}>Coupon (%) per annum</th>
                <th style={{ background: "grey", color: "white" }}>Effective Yield (% Per Annum)</th>
                <th style={{ background: "grey", color: "white" }}>Amount on Maturity (In Rs)</th>
                <th style={{ background: "grey", color: "white" }}>Display Status</th>
                <th style={{ background: "grey", color: "white" }}>Action</th>
              </tr>
            </thead>

            <FieldArray
              name="couponData"
              render={({ push, remove }) => (
                <>
                  <tbody>
                    <NCDCouponRateForm remove={remove} result={result} setCouponDataLength={setCouponDataLength} />
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="10" className="text-end">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm text-center mt-2 mb-2"
                          onClick={() => {
                            let newSeries = couponDataLength === 0 ? 1 : couponDataLength + 1;
                            push({
                              series: newSeries, // Update the series value when adding a new entry
                              frequency_of_interest_payment: "",
                              nature: "",
                              tenor: "",
                              tenor_unit: "",
                              coupon_rate: "",
                              effective_yield: "",
                              amount_maturity: "",
                              display_status: true,
                            });
                            setCouponDataLength(newSeries);
                          }}
                        >
                          Add +
                        </button>

                      </td>
                    </tr>
                  </tfoot>
                </>
              )}
            />
          </table>

          <br></br>

          <div className="my-3 row">
            <label htmlFor="" className="col-2">Note (HTML)</label>
            <div className="col-8">
              <Field className="form-control" rows="5" as="textarea" tabIndex={20}
                name="ncd_coupon_note"></Field>
            </div>
          </div>


          <div className="mb-3 text-center">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>Update</span>
            </button>

            <button
              type="button"
              className="btn btn-secondary btn-block ms-2"
              onClick={handleGoBack}
            >
              Cancel
            </button>
          </div>
          <ValidateFormFields />
        </Form>
      </Formik>
    </>
  );
};

export default NCDCouponRate;
