import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { closePopUpWindow, formatNewLineMessage, funCloseWebWindow, validateId } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';

const IPOEditBookmark = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);

    let columns = [
        {
            Header: 'Message',
            accessor: 'message',
            className: "align-middle",
            Cell: ({ cell }) => (
                <>
                    <div className='my-2'><Link to={`/${cell.row.original.URLRewrite_Folder_Name}/${cell.row.original.ipo_id}`}>{cell.row.original.Company_Name}</Link></div>
                    <div className='w-auto p-2 border border-1 message-bkg' dangerouslySetInnerHTML={{ __html: formatNewLineMessage(cell.row.original.message) }}></div>
                    <div className="text-end mt-2"><a className="btn btn-sm btn-link" href={`${process.env.REACT_APP_WEB_URL}ipo/ipo_discussions.asp?a=${cell.row.original.ipo_id}&c=${cell.row.original.parent_message_id}&ap=0`}><small>Read Thread</small></a>
                    </div>
                </>
            ),
        },
    ];

    const [result, setResult] = useState({
        tableColumns: columns,
        tableData: [],
        pageNumber: 1,
        totalPages: 1,
        totalRecords: 0,
        pageIndex: 0,
        recordPerPage: 20,
        orderBy: "",
        pageDataLoaded: 0,
        currentSort: "1"
    });

    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },

    } = useTable(
        {
            columns: result.tableColumns,
            data: result.tableData,
            manualSortBy: true,
            initialState: {
                pageIndex: result.pageIndex,
                pageSize: result.recordPerPage
            },
            manualPagination: true,
            pageCount: result.totalPages,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "My BookMark" })
            getTableData();
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const getTableData = () => {
        dispatch(startLoading());
        api.post('/ipoeditbookmark-read', {
            pagenumber: result.pageIndex + 1,
            orderby: result.currentSort,
        }).then(response => {
            if (response.data.msg === 1) {
                setResult((prevState) => ({
                    ...prevState,
                    tableColumns: columns,
                    tableData: response.data.commentList,
                    totalRecords: response.data.TotalRecords,
                    recordPerPage: response.data.RecordPerPage,
                    pageNumber: response.data.PageNumber,
                    orderBy: response.data.Orderby,
                    totalPages: response.data.TotalPages,
                    pageDataLoaded: 1,
                }))
            } else {
                dispatch(setMessage(response.data.error));
            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    useEffect(() => {
        if (result.pageDataLoaded === 1) {
            getTableData();
        }
    }, [result.pageIndex, result.currentSort]);

    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            let currentSort = "";
            if (sortBy.length > 0) {
                currentSort = sortBy[0].id + (sortBy[0].desc ? " desc" : " asc");
            }
            setResult((prevState) => ({
                ...prevState,
                currentSort: currentSort
            }));
        }
    }, [sortBy]);

    // All checked and unchecked

    const [checkedState, setCheckedState] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const handleCheckAll = () => {
        const newCheckedState = {};
        const newSelectedIds = [];

        rows.forEach(row => {
            newCheckedState[row.id] = !isCheckAll;
            if (!isCheckAll) {
                newSelectedIds.push(row.original.bookmark_id);
            }
        });

        setCheckedState(newCheckedState);
        setIsCheckAll(!isCheckAll);
        setSelectedIds(!isCheckAll ? newSelectedIds : []);
    };

    // Saving Data in API after Removing the rows

    const handleDelete = () => {
        if (selectedIds.length === 0) {
            alert("Please select the messages to delete");
            return;
        }
        console.log("The selected ids are: ", selectedIds);

        const confirmDelete = window.confirm("Are you sure you want to delete this message?");
        if (confirmDelete) {
            dispatch(startLoading());

            api.post('/ipoeditbookmark-save', {
                values: {
                    bookmark_ids: selectedIds.join(',')
                }
            })
                .then(response => {
                    if (response.data.success) {
                        let tempArray = result.tableData.filter((value, index) => !selectedIds.includes(value.bookmark_id))
                        setResult((prevState) => ({
                            ...prevState,
                            tableData: tempArray
                        }));
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(setMessage('An error occurred while deleting the message.'));
                })
                .finally(() => {
                    dispatch(finishLoading());
                });
        } else {
            console.log("Deletion canceled");
        }

        // setResult((prevState) => ({
        //     ...prevState,
        //     tableData: tempArray
        // }));

        setSelectedIds([]);
        setCheckedState({});
    };

    const handleCheck = (rowId, bookmark_id) => {
        const isChecked = checkedState[rowId];
        setCheckedState(prevState => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));

        if (!isChecked) {
            setSelectedIds(prevIds => [...prevIds, bookmark_id]);
        } else {
            setSelectedIds(prevIds => prevIds.filter(prevId => prevId !== bookmark_id));
        }
        // console.log("This is the ID for respective msg : ",rowId)
    };

    // Handle Next Page

    const handleNextPage = () => {
        setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1 }));
        setSelectedIds([]);
        setCheckedState({});
    }

    const handlePreviousPage = () => {
        setResult((prevState) => ({ ...prevState, pageIndex: prevState.pageIndex - 1 }));
        setSelectedIds([]);
        setCheckedState({});
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
                <style>
                    {
                        `  p {
                        margin - bottom: 2px !important;
		                }`
                    }
                </style>
            </Helmet>

            <div className='row main mt-3'>
                <div className='col-sm-12'>
                    <h1 className="mb-3">My Bookmarks</h1>

                    <div className='table-responsive'>
                        <div className={`table-container ${loading ? 'blur' : ''}`}>
                            <Table {...getTableProps()} className='table table-bordered table-hover table-sm' id='report_table'>
                                <thead >
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            <th className="text-center align-middle" style={{ width: '70px' }}>
                                                <input
                                                    type="checkbox"
                                                    className="m-2"
                                                    id="checkall"
                                                    checked={isCheckAll}
                                                    onChange={handleCheckAll}
                                                />
                                            </th>
                                            {headerGroup.headers.map(column => (
                                                <th className={column.className} {...column.getHeaderProps()}>
                                                    <div
                                                        {...column.getSortByToggleProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {column.render('Header')}
                                                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (<>
                                            <tr {...row.getRowProps()}>
                                                <td className="text-center align-middle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkTable"
                                                        checked={checkedState[row.id] || false}
                                                        onChange={() => {
                                                            handleCheck(row.id, row.original.bookmark_id)
                                                        }}
                                                    />
                                                </td>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()} className={cell.column.className}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </>)

                                    }
                                    )}



                                    {(result && result.tableData && Object.keys(result.tableData).length == 0) ?
                                        <tr>
                                            <td colspan="11" className='p-3'>No Bookmarks</td>
                                        </tr>
                                        :
                                        <tr>
                                            <td className="text-center">
                                                <button
                                                    className="btn btn-xs btn-primary"
                                                    onClick={handleDelete}
                                                >
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            {loading && (
                                <div className="spinner-overlay">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}
                        </div>
                    </div>

                    {result.totalPages > 1 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Prev onClick={handlePreviousPage} disabled={result.pageIndex === 0} />
                            <Pagination.Item
                                key={result.pageIndex}
                                active={true}
                                onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex }))}
                            >
                                {result.pageIndex + 1}
                            </Pagination.Item>
                            <Pagination.Next onClick={handleNextPage} disabled={result.pageIndex === result.totalPages - 1} />
                            <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                        </Pagination>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-end">
                    <button
                        type="button"
                        onClick={btnClick}
                        className="btn btn-secondary btn-sm"
                        name="closeButton"
                        value="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    )
}

export default IPOEditBookmark;