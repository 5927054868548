import { useLocation, useParams } from "react-router-dom";
import useApi from "../../../common/useApi";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../state/slices/message";
import Cookies from 'js-cookie';

const LikeSpamFeed = () => {

    const location = useLocation();
    const { id, pageType, actionType } = useParams();
    console.log(id, pageType, actionType)
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const dispatch = useDispatch();
    const api = useApi();
    const user = JSON.parse(Cookies.get("user"));
    useEffect(() => {
        if (didMountRef.current === "true") {

            let param = {
                values: {

                    id: id,
                    userId: user.id,
                    message_type: pageType ?? "Feedback"
                }

            }

            console.log("param", param)
            let endPoint = "";
            if (actionType == "spam") {
                // alert("spam")
                endPoint = "/spam-action";
            }
            else if (actionType == "like") {
                endPoint = "/like-unlike-action";
            }
            else if (actionType == "bookmark") {
                endPoint = "/member-ipofeedbackbookmark-action"
            }
            else {
                return;
            }


            api.post(endPoint, param).then((res) => {
                console.log(res)
                if (res.data.msg == 1) {
                    window.self.close();
                }
                else {
                    dispatch(setMessage("There is an error in your request. We will solve this error soon"))
                }
            }).catch((err) => {
                console.log(err)
            })

        }
        else {
            didMountRef.current = "true";
        }
    }, [])
    return <>
        Thank you for response.
    </>
}
export default LikeSpamFeed