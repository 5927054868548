import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BookChapterForm from './BookChapterForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { funTrimNestedStrings, isValidImageFileName } from '../Common/CommonFunction';

const BookChapter = () => {

    const validationSchema = Yup.object({
        title: Yup.string().required('Please enter Chapter title.'),
        book_module_id: Yup.string().required('Please choose Module.'),
        urlrewrite_folder_name: Yup.string().required('Please enter URLRewrite Folder Name.'),
        category_id: Yup.string().required('Please select the Category.'),
        meta_desc: Yup.string().required('Please enter meta description.'),
        brief_description: Yup.string().required('Please enter brief description.'),
        description: Yup.string().required('Please enter description.'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        chapter_id: "",
        book_module_id: "",
        category_id: "",
        title: "",
        urlrewrite_folder_name: "",
        description: "",
        brief_description: "",
        key_takeaways: "",
        date_added: "",
        display_order: "",
        display_status: false,
        image_name: "",
        image_width: "",
        image_height: "",
        report_ids: "",
        glossary_ids: "",
        meta_title: "",
        meta_desc: "",
        lock_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New BookChapter" })
            } else {
                setMetaData({ "title": "Edit BookChapter" })
            }
            api.post('/admin-bookchapter-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.bookChapter);
                    if (!isAddMode) {
                        setInitialValues(response.data.bookChapter[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.bookChapterNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        console.log("insdie handle submit FOrm Vluaess: ", values)
        const trimmedValues = funTrimNestedStrings(values);

        if(!isValidImageFileName(trimmedValues.image_name)){
            return false;
        }


        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-bookchapter-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.bookChapter !== undefined &&
                result.bookChapter.length > 0 &&
                result.bookChapter[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                    </>
                )}

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >

                <BookChapterForm
                    result={result}
                    handleGoBack={handleGoBack}
                    initialValues={initialValues}
                    loading={loading}
                />

            </Formik>
        </>
    )
}

export default BookChapter