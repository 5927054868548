import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoObjectivesForm from './ExpensesForm';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings, FormatNumber } from '../Common/CommonFunction';
import IpoTabs from '../IPONew/IPONewPageTabs';
import { Button, Modal } from 'react-bootstrap';

const IpoObjectives = () => {

    const validationSchema = Yup.object({});
    const previousValues = useRef({});
    const [showModal, setShowModal] = useState(false);

    const validate = (e) => {
        for (let i = 0; i < e.ipoExpenses.length; i++) {
            if (e.ipoExpenses[i].expense_desc == "" || e.ipoExpenses[i].expense_desc == null) {
                alert(`Expenses Desc ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);

    const [metaData, setMetaData] = useState({
        title: ""
    });

    const { id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        ipo_confirmation_status: false,
        digitInput: 1000000,
        ipoExpenses: [{
            series: "", expense_desc: "", amount: "", display_status: false
        }],
        expense_table: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "IPO Expenses" })
            api.post('/admin-expenses-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    console.log("The result is: ", response.data);
                    setResult(response.data);
                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        digitInput: response.data.ipoDetail[0].issue_category == "SME" ? 100000 : 1000000,
                        company_name: response.data.ipoDetail[0].company_name,
                        lock_status: response.data.ipoDetail[0].lock_status,
                        ipo_confirmation_status: response.data.ipoDetail[0].ipo_confirmation_status,
                        ipoExpenses: response.data.ipoExpenses
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true"
        }
    }, []);

    const generateHTMLTable = (values) => {
        // console.log("chk values : ", values);
        let htmlOutput = `<p>The Company ${values.company_name} proposes to utilise the Net Proceeds from the Issue towards the following Expenses:</p>
                            <div class='table-responsive'>
                                <table class='table table-bordered table-striped w-auto' id='ObjectiveIssue'>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Objects of the Issue</th>
                                            <th>Expected Amount <small style="font-style: italic;">(in Million)</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>`;

        values.ipoExpenses.forEach(({ series, expense_desc, amount, display_status }, index) => {
            if (display_status == 1) {
                const formattedAmount = amount ? FormatNumber((amount / values.digitInput), 2) : '';
                htmlOutput += `<tr>
                                <td>${series || index + 1}</td>
                                <td>${expense_desc}</td>
                                <td>${formattedAmount}</td>
                            </tr>`;
            }
        });

        htmlOutput += "</tbody></table></div>";

        return htmlOutput;
    };

    const handleSubmit = (values, { setFieldValue }) => {
        let expense_table = generateHTMLTable(values);
        setFieldValue("expense_table", expense_table);
        if (!values.ipoExpenses || values.ipoExpenses.length === 0) {
            alert("No data is inserted into Expense Table.");
            return;
        }
        if (validate(values)) {
            setShowModal(true);
        }
    };

    const handleConfirmSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);
        if (validate(trimmedValues)) {
            dispatch(startLoading());
            api.post('/admin-expenses-save', {
                values: trimmedValues
            }
            ).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            setShowModal(false);
        }
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <IpoTabs idAtr={id} ipo_confirm={result?.ipoDetail?.[0]?.ipo_confirmation_status} />

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.ipoDetail !== undefined &&
                result.ipoDetail.length > 0 &&
                result.ipoDetail[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            {result !== undefined && result.id !== undefined && result.id.length > 0 &&
                <>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4" tabIndex={1}>
                            {id}
                        </div>
                    </div>
                </>
            }

            {result !== undefined && result.ipoDetail !== undefined && result.ipoDetail.length > 0 &&
                <>
                    <div className="my-3 row">
                        <label htmlFor="" className="col-2">Company Name:</label>
                        <div className="col-8" tabIndex={5}>
                            <b>{result.ipoDetail[0].company_name}</b>
                        </div>
                    </div>
                </>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >

                {({ setFieldValue, values, setSubmitting }) => (
                    <Form id='myForm'>

                        <div className="row mb-3">
                            <label htmlFor="" className="col-2">Number input in:</label>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput1" value="1" tabIndex={1} checked={values.digitInput === 1}
                                        onChange={(e) => { setFieldValue(e.target.name, 1) }} />
                                    <label className="form-check-label" htmlFor="digitInput1">
                                        Normal Digits
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput5" value="1000" tabIndex={5} checked={values.digitInput === 1000}
                                        onChange={(e) => { setFieldValue(e.target.name, 1000) }} />
                                    <label className="form-check-label" htmlFor="digitInput5">
                                        In Thousands
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput4" value="100000" tabIndex={10} checked={values.digitInput === 100000}
                                        onChange={(e) => { setFieldValue(e.target.name, 100000) }} />
                                    <label className="form-check-label" htmlFor="digitInput4">
                                        In Lakhs
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput2" value="1000000" tabIndex={15} checked={values.digitInput === 1000000}
                                        onChange={(e) => { setFieldValue(e.target.name, 1000000) }} />
                                    <label className="form-check-label" htmlFor="digitInput2">
                                        In Millions
                                    </label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-check">
                                    <Field type="radio" className="form-check-input" name="digitInput" id="digitInput3" value="10000000" tabIndex={20} checked={values.digitInput === 10000000}
                                        onChange={(e) => { setFieldValue(e.target.name, 10000000) }} />
                                    <label className="form-check-label" htmlFor="digitInput3">
                                        In Crores
                                    </label>
                                </div>
                            </div>
                        </div>

                        <table className="table table-bordered table-striped" id="repeater1" tabIndex={10}>
                            <thead>
                                <tr className="bg-secondary text-white">
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "5%" }}>S.No.</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "30%" }}>Issue Expenses</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Amount</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Display Status</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "5%" }}>Action</th>
                                </tr>
                            </thead>
                            <FieldArray
                                name="ipoExpenses"
                                render={({ push, remove }) => (
                                    <>
                                        <tbody>
                                            <IpoObjectivesForm remove={remove} previousValues={previousValues} />
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="9" className="text-end">
                                                    <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({
                                                        series: "", expense_desc: "", amount: "", display_status: false
                                                    })}>
                                                        Add +
                                                    </button>
                                                </td></tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </table>

                        <br></br>

                        <div className="mb-3 text-center">
                            {initialValues.lock_status !== 1 && (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={20}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        Update
                                    </span>
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={25}
                            >Cancel</button>
                        </div>

                        <h2>Current IPO Expense </h2>
                        {result && result.ipoDetail?.[0]?.expense_table && <div dangerouslySetInnerHTML={{ __html: result.ipoDetail[0].expense_table }}></div>}

                        <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
                            <Modal.Header closeButton>
                                <Modal.Title>Do you want to update this Expense Table ?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {<div dangerouslySetInnerHTML={{ __html: values.expense_table }}></div>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    No
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => handleConfirmSubmit(values, { setSubmitting })}
                                >
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <ValidateFormFields />

                    </Form>
                )}
            </Formik>
        </>
    )
}

export default IpoObjectives;
