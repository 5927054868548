import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BuybackForm from './BuybackForm';
import { Formik } from 'formik';
import BBTabs from './BBTabs';
import { isValidImageFileName } from '../Common/CommonFunction';

const Buyback = () => {

    const [submitForm, setSubmitForm] = useState(true);

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('company_name is required'),
        news_title: Yup.string().trim().required('news title is required').matches(/.*buyback.*/i, 'News Title should contain the word "Buyback"'),
        urlrewrite_folder_name: Yup.string().trim().required('URLRewrite_Folder_Name is required'),
        registrar_1_email: Yup.string().email('Please Enter valid "Registrar_1_Email"').nullable(),
        registrar_2_email: Yup.string().email('Please Enter valid registrar_2_email is required').nullable(),
        broker_email: Yup.string().email('Enter valid Email for 1st broker').nullable(),
        broker_email_2: Yup.string().email('Enter vaild Email for 2nd broker').nullable(),
        email: Yup.string().email("Please Enter Valid Email").nullable(),
        ratio_small_shareholders: Yup.number().nullable(),
        ratio_small_fullypaid: Yup.number().nullable(),
        ratio_general_shareholders: Yup.number().nullable(),
        ratio_general_fullypaid: Yup.number().nullable(),
        website: Yup.string().url("Please Enter Valid URL for company website").nullable(),
    }).test(
        'oneFilled',
        'Fill either "Ratio Small Shareholders" or "Equity Shares out of every" field, not both.',
        function (value) {
            const { ratio_small_shareholders, ratio_small_fullypaid, ratio_general_shareholders, ratio_general_fullypaid } = value;
            if (((ratio_small_shareholders != null && ratio_small_fullypaid == null) || (!ratio_small_shareholders && ratio_small_fullypaid)) ||
                ((ratio_general_shareholders && !ratio_general_fullypaid) || (!ratio_general_shareholders && ratio_general_fullypaid))) {
                setSubmitForm(false);
                return false;
            }
            else setSubmitForm(true);
            return true;
        }
    )

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name_auto: "",
        company_name: "",
        offer_open_date: "",
        offer_closure_date: "",
        face_value: "",
        buyback_price: "",
        issue_size_in_shares: "",
        issue_size_in_amt: "",
        percentage_of_equity_shares: "",
        necessity_of_the_buyback: "",
        listing_at: "",
        type_of_buyback: "",
        buyback_ratio: "",
        registrar_1: "",
        registrar_1_email: "",
        registrar_2: "",
        registrar_2_email: "",
        lead_managers: "",
        tender_form_url: "",
        letter_of_offer_url: "",
        faqs_url: "",
        news_title: "",
        urlrewrite_folder_name: "",
        record_dt: "",
        last_day_to_buy_shares_dt: "",
        last_date_for_receipt_of_tender_forms: "",
        finalisation_of_buyback: "",
        last_date_for_settlment_of_bids: "",
        last_date_for_extinguishment_of_shares: "",
        last_date_on_zerodha: "",
        isin: "",
        nse_symbol: "",
        bse_scripcode: "",
        logo_url: "",
        logo_length: "",
        logo_height: "",
        company_desc: "",
        financial: "",
        company_registered_broker: "",
        broker_email: "",
        company_registered_broker_2: "",
        broker_email_2: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        phone: "",
        email: "",
        website: "",
        stock_preceding_dt1: "",
        stock_preceding_dt2: "",
        stock_preceding_dt3: "",
        bse_high_market_price1: "",
        bse_high_market_price2: "",
        bse_high_market_price3: "",
        bse_low_market_price1: "",
        bse_low_market_price2: "",
        bse_low_market_price3: "",
        bse_average_market_price1: "",
        bse_average_market_price2: "",
        bse_average_market_price3: "",
        nse_high_market_price1: "",
        nse_high_market_price2: "",
        nse_high_market_price3: "",
        nse_low_market_price1: "",
        nse_low_market_price2: "",
        nse_low_market_price3: "",
        nse_average_market_price1: "",
        nse_average_market_price2: "",
        nse_average_market_price3: "",
        display_order: "",
        issue_withdraw_status: false,
        lock_status: false,
        display_status: false,
        retail_reservation: "",
        retail_holding_date: "",
        retail_holdings: "",
        shares_offered_qib: "",
        shares_offered_nii: "",
        shares_offered_rii: "",
        shares_offered_emp: "",
        shares_offered_others: "",
        shares_offered_total: "",
        reservation_small_shareholders: "",
        reservation_general_shareholders: "",
        ratio_small_shareholders: "",
        ratio_small_fullypaid: "",
        ratio_general_fullypaid: "",
        ratio_general_shareholders: "",
        list_of_script_id: "",
        buyback_listing_at_array: [],
        initialItems: [],
        selectedItems: [],
        selectedItemsIds: [],
        stock_name_auto: "",
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/132"); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Buyback" })
            } else {
                setMetaData({ "title": "Edit Buyback" })
            }
            api.post('/admin-buyback-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        if (response.data.buyback[0].lead_managers !== undefined && response.data.buyback[0].lead_managers !== null && response.data.buyback[0].lead_managers.trim().length > 0) {
                            response.data.buyback[0].selectedItemsIds = response.data.buyback[0].lead_managers.split(",").map(item => parseInt(item.trim()));
                        } else {
                            response.data.buyback[0].selectedItemsIds = [];
                        }
                        if (response.data.buyback[0].listing_at !== undefined && response.data.buyback[0].listing_at !== null && response.data.buyback[0].listing_at.trim().length > 0) {
                            response.data.buyback[0].buyback_listing_at_array = response.data.buyback[0].listing_at.split(",").map(item => item.trim());
                        } else {
                            response.data.buyback[0].buyback_listing_at_array = [];
                        }
                        response.data.buyback[0].company_name_auto = response.data.buyback[0].company_name;
                        setInitialValues(response.data.buyback[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.buybackNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        if (submitForm == false) {
            dispatch(setMessage('Please check Buyback Ratio section below. Either both, the ratio shareholders and the corresponding Equity shres out of every... should be empty or should be filled. One value empty and the other filled is not allowed.'));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        //return;
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        // trimmedValues.display_status = trimmedValues.display_status == 1 ? true : false
        console.log('Form submitted with values:', trimmedValues);

        //if(trimmedValues.buyback_listing_at_array.length>0){
        if (trimmedValues.buyback_listing_at_array.length == 0) {
            alert("Select at least on exchange");
            return;
        }
        console.log("Listing at itmes are", trimmedValues.buyback_listing_at_array)
        trimmedValues.listing_at = trimmedValues.buyback_listing_at_array.join(', ');

        console.log("These are lead managers", trimmedValues.selectedItemsIds)
        trimmedValues.lead_managers = trimmedValues.selectedItemsIds.join(',');
        console.log("These are lead managers", trimmedValues.lead_managers);

        if (trimmedValues.buyback_price < trimmedValues.face_value) {
            alert("Buyback price should be greater than or equal to face value");
            return;
        }

        if (trimmedValues.logo_url != "") {
            if (!isValidImageFileName(trimmedValues.logo_url)) {
                return false;
            }
        }

        const currentDate = new Date().toISOString().split('T')[0];

        // Compare record_dt to current date
        if (trimmedValues.record_dt && trimmedValues.record_dt < currentDate) {
            if (!window.confirm("The record date is in the past. Do you want to continue?")) {
                setSubmitting(false);
                return;
            }
        }


        dispatch(startLoading());

        api.post('/admin-buyback-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/132");
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            {!isAddMode && <BBTabs idAtr={id} />}
            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.buyback !== undefined &&
                result.buyback.length > 0 &&
                result.buyback[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <BuybackForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} />
            </Formik>

        </>
    )
}

export default Buyback