import React, { useState, useEffect, useRef } from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import { funCreateSlug } from "../Common/CommonFunction";
import NumberInput from "../Common/NumberInput";
import CharacterCountInput from '../Common/CharacterCountInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';
import FileUpload from '../Common/FileUpload';

const BookModuleForm = ({ result, handleGoBack, loading, initialValues }) => {

	const { values, setFieldValue, setFieldTouched } = useFormikContext();
	const previousTitleValueRef = useRef();

	const [blurState, setBlurState] = useState({
		isBlurring: false,
		blurredTextbox: null,
		blurredTextValue: null,
	});

	const handleBlurWrapper = (e) => {
		if (!blurState.isBlurring) {
			setBlurState({ isBlurring: true, blurredTextbox: e.target.name, blurredTextValue: e.target.value });
		}
	};

	useEffect(() => {
		const handleBlurLogic = async () => {
			if (blurState.blurredTextbox === "title") {
				if (blurState.blurredTextValue != "" && previousTitleValueRef.current !== blurState.blurredTextValue) {
					const userConfirmed = window.confirm(
						"Do you want to update urlrewrite_folder_name?"
					);
					if (userConfirmed) {
						setFieldValue("urlrewrite_folder_name", funCreateSlug(blurState.blurredTextValue));
					} else {
						setFieldTouched("urlrewrite_folder_name", true);
					}
				}
				previousTitleValueRef.current = blurState.blurredTextValue;
			}
			setBlurState({ isBlurring: false, blurredTextbox: null, blurredTextValue: null });
		};

		if (blurState.isBlurring) {
			handleBlurLogic();
		}
	}, [blurState]);

	return (
		<Form id="myForm">

			{result !== undefined &&
				result.bookModule !== undefined &&
				result.bookModule.length > 0 &&
				result.bookModule[0].lock_status == 1 && (
					<>
						<h3 className="text-danger mt-3 mb-0">
							Record Is Locked , you can't update it.
						</h3>
						<br />
					</>
				)}

			{result !== undefined && result.bookModule !== undefined && result.bookModule.length > 0 && (
				<div className="mb-3 row">
					<label htmlFor="" className="col-lg-2">
						Book ID:
					</label>
					<div className="col-lg-4">
						{result.bookModule[0].id}
					</div>
				</div>
			)}

			<div className="mb-3 row">
				<label htmlFor="book_id" className="col-lg-2 required">Category</label>
				<div className="col-lg-4">
					<Field as="select" name="book_id" className="form-select" rows="5" tabIndex={1}>
						<option> </option>
						{result.bookList !== undefined && result.bookList.map((option) => (
							<option key={option.id} value={option.id}>
								{option.title}
							</option>
						))}
					</Field>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="title" className="col-lg-2 required">Title</label>
				<div className="col-lg-4">
					<Field as="input" name="title" className="form-control" tabIndex={5} maxLength="50" onBlur={(e) => { handleBlurWrapper(e) }}
					/>

				</div>
				<label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
				<div className="col-lg-4">
					<Field as='input' className="form-control" type='text' tabIndex={10} name="urlrewrite_folder_name" maxLength="50" />
				</div>
			</div>

			<hr />

			<h3>Meta Data</h3>

			<div className="mb-3 row">
				<label htmlFor="meta_title" className="col-lg-2">Meta Title (Max 60)</label>
				<div className="col-lg-4">
					<CharacterCountInput
						nameattr="meta_title"
						type="text"
						maxLength="60"
						tabIndex="15"
						className="form-control"
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="meta_desc" className="col-lg-2">Meta Description<br /> (Text 200 Char)</label>
				<div className="col-lg-6">
					<CharacterCountTextarea
						nameattr="meta_desc"
						maxLength="200"
						type="textarea"
						tabIndex="20"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>

			<hr />
			<div className='mb-3 row'>
				<label htmlFor="image" className='col-lg-2'>Choose an image:</label>
				<div className="col-lg-10">
					<FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="image_name" className="col-lg-2">Image Name:</label>
				<div className="col-lg-2">
					<Field as='input' className="form-control" type="text" maxLength="100" name="image_name" tabIndex={25} />
				</div>
				<label htmlFor="image_width" className="col-lg-1">Width:</label>
				<div className="col-lg-2">
					<NumberInput classNameAtr={"form-control"} nameAtr={"image_width"} maxLength={"3"} tabIndex={30} />
				</div>
				<label htmlFor="image_height" className="col-lg-1">Height:</label>
				<div className="col-lg-2">
					<NumberInput classNameAtr={"form-control"} nameAtr={"image_height"} maxLength={"3"} tabIndex={35} />
				</div>

			</div>

			<div className="mb-3 row">
				<label htmlFor="description" className="col-lg-2">Description<br /> (HTML)</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="description"
						type="textarea"
						tabIndex="45"
						rows="15"
						className="form-control"
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="display_order" className="col-lg-2">Display Order</label>
				<div className="col-lg-2">
					<Field as='input' className="form-control" type='text' name="display_order" maxLength="10" tabIndex={50} />
				</div>
				<label htmlFor="display_status" className="col-lg-2">Display Status</label>
				<div className="col-lg-2">
					<Field as='input' className="form-check-input" type="checkbox" name="display_status" tabIndex={55}
						onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
				</div>
				<label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
				<div className="col-lg-2">
					<Field as='input' className="form-check-input" type="checkbox" name="lock_status" tabIndex={60}
						onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
				</div>
			</div>

			<hr />
			<div className="mb-3 text-center">
				{initialValues.lock_status !== 1 && (
					<button
						type="submit"
						className="btn btn-primary btn-block"
						disabled={loading}
						tabIndex={65}
					>
						{loading && (
							<span className="spinner-border spinner-border-sm me-2"></span>
						)}
						<span>
							{result.bookModule !== undefined && result.bookModule.length > 0
								? "Update"
								: "Create"}
						</span>
					</button>
				)}

				<button
					type="button"
					className="btn btn-secondary btn-block ms-2"
					onClick={handleGoBack}
					tabIndex={70}
				>
					Cancel
				</button>
			</div>
			<ValidateFormFields />
		</Form>
	);
};

export default BookModuleForm;
