import React from "react";
import { useFormikContext, Field, Form } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import NumberInput from "../Common/NumberInput";
import DynamicCharacterCountInputText from "../Common/CharacterCountTextarea";
import DynamicCharacterCountInput from "../Common/CharacterCountInput";
import { funCreateSlug } from "../Common/CommonFunction";

const WebReportForm = ({ result, handleGoBack, initialValues, loading }) => {
	const { setFieldValue, setFieldTouched } = useFormikContext();


	return (
		<>
			<Form id="myForm">
				{result != undefined && result.webReport != undefined &&
					result.webReport.length > 0 &&
					result.webReport[0].lock_status !== undefined &&
					result.webReport[0].lock_status == 1 && (
						<h3 className="text-danger">
							Record Is Locked , you can't update it.
						</h3>
					)}

				<fieldset>
					<div className="mb-3 row">
						<label htmlFor="report_name" className="col-lg-2">Report Name (Max 100 char)</label>
						<div className="col-lg-6">
							<DynamicCharacterCountInput className="form-control" type="text" maxLength="100" nameattr={"report_name"} tabIndex={1} />
						</div>
						<div className="col-lg-4">
							Only used for admin purpose
						</div>
					</div>
					<hr />
					<div className="mb-3 row">
						<label htmlFor="report_title" className="col-lg-2">Reports Title (Max 100 char)</label>
						<div className="col-lg-4">
							<DynamicCharacterCountInput className="form-control" type="text" maxLength="100" nameattr={"report_title"} tabIndex={5}
								onBlur={(e) => {
									const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
									if (userConfirmed) {
										var title = e.target.value;
										var slug = funCreateSlug(title)
										setFieldValue('urlrewrite_folder_name', slug);
									} else {
										setFieldTouched('report_title', true);
									}
								}} />

						</div>
						<label htmlFor="report_type_id" className="col-lg-2">Reports Type</label>

						<div className="col-lg-4">
							<Field as="select" name="report_type_id" className="form-select" tabIndex={10}>
								<option></option>
								{result.reportTypes !== undefined && result.reportTypes.map((option) => (
									<option key={option.code} value={option.code}>
										{option.code_desc}
									</option>
								))}
							</Field>
						</div>
					</div>

					<div className="mb-3 row">
						<label htmlFor="category_cd" className="col-lg-2">Category</label>
						<div className="col-lg-4">
							<Field as="select" name="category_cd" className="form-select" tabIndex={15}>
								<option></option>
								{result.reportCategory !== undefined && result.reportCategory.map((option) => (
									<option key={option.id} value={option.id}>
										{option.cat_name}
									</option>
								))}
							</Field>
						</div>
					</div>

					<div className="mb-3 row">
						<label htmlFor="logo_url" className="col-lg-2">Logo URL</label>
						<div className="col-lg-3">
							<Field className="form-control" type="text" maxLength="100" name="logo_url" id="Logo_URL" tabIndex={20} />
						</div>
						<label htmlFor="logo_width" className="col-lg-2">Width</label>
						<div className="col-lg-1">
							<NumberInput classNameAtr="form-control" tabIndexAtr={25} maxLength="5" nameAtr="logo_width" />
						</div>
						<label htmlFor="logo_height" className="col-lg-2">Height</label>
						<div className="col-lg-1">
							<NumberInput classNameAtr="form-control" tabIndexAtr={30} maxLength="5" nameAtr="logo_height" />
						</div>
					</div>
				</fieldset>

				<fieldset>
					<legend>Meta Tags</legend>
					<hr />
					<p>Note: If <mark>meta tags are empty</mark>, the report will create meta tags from report title and description.</p>
					<div className="mb-3 row">
						<label htmlFor="meta_title" className="col-lg-2">Meta title (50 to 60 Char)</label>
						<div className="col-lg-5">
							<DynamicCharacterCountInput className="form-control" maxLength="100" nameattr="meta_title" id="meta_title" tabIndex={35} />
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="meta_desc" className="col-lg-2">Meta Description (150 to 200 Char)</label>
						<div className="col-lg-9">
							<DynamicCharacterCountInputText className="form-control" rows="3" nameattr={"meta_desc"} id="meta_desc" maxLength="300" tabIndex={40} />
						</div>
					</div>
				</fieldset>

				<fieldset>
					<legend>Query Setup</legend>
					<hr></hr>
					<p><b>Order of Execution</b>: FROM, WHERE, GROUP BY, HAVING, SELECT, ORDER BY</p>
					<div className="mb-3 row">
						<label htmlFor="table_heading" className="col-lg-2">Table Heading</label>
						<div className="col-lg-6">
							<Field className="form-control" type="text" maxLength="100" name="table_heading" tabIndex={45} />
						</div>
						<div className="col-lg-4">
							(Keep empty for default 'Report Title' as Table heading.)
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="report_sql_count" className="col-lg-2">Report Count Query</label>
						<div className="col-lg-8">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"report_sql_count"} tabIndex={48} />
						</div>
						<div className="col-lg-2">
							(i.e. use count column only, <br /> Use [[Parameter1]] for additional parameter
							)
						</div>
					</div>

					<div className="mb-3 row">
						<label htmlFor="report_sql" className="col-lg-2">Report Query</label>
						<div className="col-lg-8">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"report_sql"} tabIndex={50} />
						</div>
						<div className="col-lg-2">
							<span className="text-info">(i.e. use ~ for hidden fields,  <br /> Use [[Parameter1]] for additional parameter )</span>
							<br />
							<span className="text-warning">(For compare feature enable this fields is mandatory "~compare_image","~compare_name","~compare_id")</span>
						</div>
					</div>

					<div className="mb-3 row">
						<label htmlFor="group_by" className="col-lg-2">Group By</label>
						<div className="col-lg-2">
							<Field className="form-control" type="text" maxLength="100" name="group_by" tabIndex={55} />
						</div>
						<label htmlFor="default_sort" className="col-lg-2">Order By</label>
						<div className="col-lg-2">
							<Field className="form-control" type="text" maxLength="100" name="default_sort" tabIndex={60} />
						</div>
						<div className="col-lg-2">
							(i.e. idasc or id) Default: <strong>1desc</strong>. No space allowed.
						</div>
					</div>

					<div className="mb-3 row">
						<label htmlFor="having_clause" className="col-lg-2">Having</label>
						<div className="col-lg-2">
							<Field className="form-control" type="text" maxLength="100" name="having_clause" tabIndex={65} />
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="pre_sql" className="col-lg-2">Pre Query (Semicolon (;) seperated)</label>
						<div className="col-lg-10">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"pre_sql"} tabIndex={70} />
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="field_mapping_for_sorting" className="col-lg-2">Field Mapping For Sorting</label>
						<div className="col-lg-8">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"field_mapping_for_sorting"} tabIndex={75} />
						</div>
						<div className="col-lg-2">
							(i.e. alias name1~field name1, alias name2~field name2)
						</div>
					</div>
				</fieldset>

				<fieldset>
					<legend>Description</legend>
					<hr />
					<div className="mb-3 row">
						<label htmlFor="report_description_keyword" className="col-lg-2">Reports Description with Keywords (HTML)</label>
						<div className="col-lg-10">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"report_description_keyword"} tabIndex={80} />
							<p className="text-warning">Use Keywords from Report Config Keywords like [[keyword_name]] , keywords will replace in report description</p>
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
						<div className="col-lg-10">
							<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"report_description"} tabIndex={85} />
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
						<div className="col-lg-10">
							<DynamicCharacterCountInputText className="form-control" rows="3" nameattr={"report_disclaimer"} tabIndex={90} />
						</div>
					</div>
					<div className="mb-3 row">
						<label htmlFor="sponsored_rows" className="col-lg-2">Sponsored Rows (HTML)</label>
						<div className="col-lg-10">
							<DynamicCharacterCountInputText className="form-control" rows="3" nameattr={"sponsored_rows"} tabIndex={95} />
						</div>
					</div>
				</fieldset>

				<legend>Others</legend>
				<hr />
				<div className="mb-3 row">
					<label htmlFor="minimum_nav_year" className="col-lg-2">Minimum Navigation Year</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={100} maxLength="4" nameAtr="minimum_nav_year" />
					</div>
					<label htmlFor="page_size" className="col-lg-2">Page Size</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={105} maxLength="4" nameAtr="page_size" />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="link_field_name" className="col-lg-2">Link Field Name</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" maxLength="50" name="link_field_name" tabIndex={110} />
					</div>
					<div className="col-lg-6">
						(i.e. id)
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="link_file_name" className="col-lg-2">Link URL</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" maxLength="250" name="link_file_name" tabIndex={115} />
					</div>
					<div className="col-lg-6">
						[AppPath]newportal/test.asp?a=[~id]<br /> [AppPath]stockbroker/[~urlrewrite_folder_name]/[~id]/
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="search_column_names" className="col-lg-2">Search Column Names</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" maxLength="250" name="search_column_names" tabIndex={120} />
					</div>
					<div className="col-lg-6">
						(i.e. company_name,display_status)
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="sorting_disable_columns" className="col-lg-2">Disable Sorting Columns</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput
							nameattr="sorting_disable_columns"
							type="text"
							maxLength="500"
							tabIndex="125"
							className="form-control"
						/>
						{/* <Field className="form-control" type="text" maxLength="500" name="sorting_disable_columns" tabIndex={125} /> */}
					</div>
					<div className="col-lg-6">
						(i.e. Broker, Clients)
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="disable_formating_columns" className="col-lg-2">Disable Formating Columns</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput
							nameattr="disable_formating_columns"
							type="text"
							maxLength="500"
							tabIndex="125"
							className="form-control"
						/>
						{/* <Field className="form-control" type="text" maxLength="500" name="disable_formating_columns" tabIndex={125} /> */}
					</div>
					<div className="col-lg-6">
						(i.e. Broker, Clients)
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="additional_link" className="col-lg-2">Additional Link</label>
					<div className="col-lg-10">
						<Field className="form-control" type="text" maxLength="1000" name="additional_link" tabIndex={130} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="status_field_name" className="col-lg-2">Status Field Name</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" maxLength="50" name="status_field_name" tabIndex={135} />
					</div>
					<div className="col-lg-6">
						(i.e. display_status)
					</div>
				</div>
				<legend>Additional Param</legend>
				<hr />
				<div className="mb-3 row row">
					<label htmlFor="additional_parameter" className="col-lg-2">Additional Parameter</label>
					<div className="col-lg-4">
						<Field as="select" name="additional_parameter" className="form-select" tabIndex={140} id="additional_parameterddl">
							<option value="0">No</option>
							<option value="1">Yes</option>
						</Field>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="sql_dropdown" className="col-lg-2">Dropdown Report Query</label>
					<div className="col-lg-8">
						<Field as="textarea" className="form-control" rows="5" name="sql_dropdown" tabIndex={145} />
					</div>
					<div className="col-lg-2">
						(i.e. use alias id , name for fields)
					</div>
				</div>

				<legend>Charts</legend>
				<hr />

				<div className="mb-3 row row">
					<label htmlFor="chart_type_id" className="col-lg-2">Select Chart Type</label>
					<div className="col-lg-4">

						<Field as="select" name="chart_type_id" className="form-select" tabIndex={150}>
							<option></option>
							{result.reportChartList !== undefined && result.reportChartList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.code_desc}
								</option>
							))}
						</Field>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="chart_x_axis" className="col-lg-2">Chart X Axis</label>
					<div className="col-lg-2">
						<Field className="form-control" type="text" maxLength="50" name="chart_x_axis" tabIndex={155} />
					</div>
					<label htmlFor="chart_y_axis" className="col-lg-2">Chart Y Axis</label>
					<div className="col-lg-2">
						<Field className="form-control" type="text" size="50" maxLength="50" name="chart_y_axis" tabIndex={160} />
					</div>
				</div>

				<legend>Compare Feature</legend>
				<hr />
				<span className="text-warning mb-4">(For compare feature enable this fields is mandatory "~compare_image","~compare_name" in query)</span><br />
				<span className="text-warning">(if compare feature is yes then default Compare Link "compare/[[URLRewrite_Folder_Name1]]-vs-[[URLRewrite_Folder_Name2]]/[[report_id]]")</span>
				<div className="my-3 row">
					<label htmlFor="compare_status" className="col-lg-2">Compare Feature</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="compare_status" tabIndex={165}
							onChange={(e) => {
								setFieldValue('compare_status', e.target.checked)
							}} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="compare_link" className="col-lg-2">Compare Link</label>
					<div className="col-lg-8">
						<Field className="form-control" type="text" maxLength="100" name="compare_link" tabIndex={170} />
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<div className="col-lg-2"></div>
					<div className="col-lg-10">
						<span className="text-info">compare/[[URLRewrite_Folder_Name1]]-vs-[[URLRewrite_Folder_Name2]]/[[report_id]]<br />
							comparebroker/[[URLRewrite_Folder_Name1]]-vs-[[URLRewrite_Folder_Name2]]/[[id1]]/[[id2]]/</span>
					</div>
				</div>

				<legend>Display Setup</legend>
				<hr />
				<div className="mb-3 row">
					<label htmlFor="display_order" className="col-lg-2">Display Order</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={175} maxLength="4" nameAtr="display_order" />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="report_ids" className="col-lg-2">Additional Report IDs</label>
					<div className="col-lg-2">
						<Field className="form-control" type="text" maxLength="100" name="report_ids" tabIndex={180} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="urlrewrite_folder_name" className="col-lg-2">URLRewrite Folder Name</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" size="55" maxLength="50" name="urlrewrite_folder_name" tabIndex={185} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="list_view_status" className="col-lg-2">Show in Reports List?</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="list_view_status" tabIndex={190}
							onChange={(e) => {
								setFieldValue('list_view_status', e.target.checked)
							}} />
					</div>
					<label htmlFor="featured_status" className="col-lg-2">Featured</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="featured_status" tabIndex={195}
							onChange={(e) => {
								setFieldValue('featured_status', e.target.checked)
							}} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="display_status" className="col-lg-2">Display Status</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="display_status" tabIndex={200}
							onChange={(e) => {
								setFieldValue('display_status', e.target.checked)
							}} />
					</div>
					<label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="lock_status" tabIndex={205}
							onChange={(e) => {
								setFieldValue('lock_status', e.target.checked)
							}} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="page_faqs_status" className="col-lg-2">FAQ Status</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="page_faqs_status" tabIndex={210}
							onChange={(e) => {
								setFieldValue('page_faqs_status', e.target.checked)
							}} />
					</div>
					<label htmlFor="feedback_status" className="col-lg-2">Feedback Status</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" name="feedback_status" tabIndex={215}
							onChange={(e) => {
								setFieldValue('feedback_status', e.target.checked)
							}} />
					</div>
					<span className="text-info">(For Additional Parameter yes "FAQ Status" and "Feedback Status" is always disabled)</span>
				</div>


				<div className="mb-3 text-center">
					{initialValues.lock_status !== 1 && (
						<button
							type="submit"
							className="btn btn-primary btn-block"
							tabIndex={220}
							disabled={loading}
						>
							{loading && (
								<span className="spinner-border spinner-border-sm me-2"></span>
							)}
							<span>
								{result.webReport != undefined && result.webReport.length > 0 ? 'Update' : 'create'}
							</span>
						</button>
					)}

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={225}
					>
						Cancel
					</button>
				</div>
				<ValidateFormFields />
			</Form>
		</>
	)
};

export default WebReportForm;
