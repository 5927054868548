import React from 'react'

const Footer = () => {
  return (
    <div className='col-sm-12'>
      <div className="footer">
        <p><b>Trouble submitting form?</b> <a href="contactus.asp" title="Contact Us"><u>Contact Us</u></a> today or email at <b>support@chittorgarh.com</b><br /><a href="https://www.chittorgarh.com/article/disclaimer-and-privacy-statement/238/" className="footer" target="_blank"><em><u>Disclaimer and Privacy Statement</u></em></a> | <a href="https://www.chittorgarh.com/article/rules-for-posting-comments/250/" className="footer" target="_blank"><em><u>Rules for posting message</u></em></a>
          | <span className='footer'><em><u> V : {process.env.REACT_APP_VERSION} </u> </em></span></p>
      </div>
    </div>
  )
}

export default Footer
