import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ForthcomingIpoForm from './ForthcomingIPOForm'
import { Formik } from 'formik';
import * as Yup from 'yup';
import IpoTabs from './IPONewPageTabs';
import { convertNullToBlank, isValidVariable } from '../Common/CommonFunction';

const validationSchema = Yup.object({
    company_name: Yup.string().trim().required("Company name is required").max(100, "Company name must be at most 100 characters"),
    ipo_news_title: Yup.lazy((value, context) => {
        const excludedTypes = ['InvIT', 'REIT', 'SM REIT', 'FPO'];
        return !excludedTypes.includes(context.parent.issue_type)
            ? Yup.string()
                .trim()
                .required("ipo_news_title is required")
                .max(50, "ipo_news_title must be at most 50 characters")
                .matches(/.*ipo.*/i, 'IPO News Title should contain the word "IPO"')
            : Yup.string()
                .trim()
                .required("ipo_news_title is required")
                .max(50, "ipo_news_title must be at most 50 characters");
    }),
    urlrewrite_folder_name: Yup.string().trim().required("urlrewrite_folder_name is required").max(50, "urlrewrite_folder_name must be at most 50 characters"),
    issue_category: Yup.mixed().required("Please select an Issue Category"),
    issue_type: Yup.mixed().required("Please select an Issue Type"),
    issue_process_type: Yup.string().trim().required("Please select IPO Process Type"),
    ipo_listing_at_array: Yup.array().of(Yup.string().required("Please select at least one IPO Listing At option")).min(1, "Please select at least one IPO Listing At option"),
});

const ForthcomingIPO = () => {

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [stockExchage, setStockExchage] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        ipo_industry: "",
        ipo_industry_value: "",
        anchor_investor_status: "0",
        natc: "",

        issue_category: "Mainline",
        issue_type: "IPO",
        issue_process_type: "BB",
        offer_type: "1",

        ipo_listing_at: "BSE,NSE",
        ipo_listing_at_array: ["BSE", "NSE"],

        ipo_app_form_url: "",
        issue_withdraw: "",
        prospectus_drhp: "",
        prospectus_rhp: "",
        addendum_drhp: "",
        final_prospectus: "",
        ipo_news_title: "",
        urlrewrite_folder_name: "",

        display_order: "",

        firm_emp_status: false,
        mf_status: false,
        market_makar_status: false,
        private_promotor_status: false,
        shareholders_status: false,
        Preferential_emp_status: false,
        others_status: false,

        lock_status: false,
        display_status: false,
        hide_temporary: false,

        ipo_confirmation_status: false,
        hidden_drhp_status: false,

    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current === "true") {
            dispatch(startLoading());

            api.post('/admin-forthcomingipo-read', { id: id }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setStockExchage(response.data.issueStockExchangeList);

                    if (!isAddMode) {
                        let ipoData = response.data.ipo[0];

                        // Ensure `ipo_listing_at` is handled correctly
                        if (ipoData.ipo_listing_at) {
                            ipoData.ipo_listing_at_array = ipoData.ipo_listing_at.split(',').map(v => v.trim());
                        } else {
                            ipoData.ipo_listing_at_array = [];
                        }

                        setInitialValues({
                            ...ipoData
                        });

                        setMetaData({ "title": "Edit Forthcoming IPO" })

                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ipoNewDisplayOrder ?? 0,
                        }));
                        setMetaData({ "title": "Add New Forthcoming IPO" })
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                dispatch(finishLoading());
            });

        } else {
            didMountRef.current = "true";
        }
    }, []);

    const validate = (e) => {
        e = convertNullToBlank(e);

        if (e.issue_category == 'SME') {
            var count_check = 0;

            e.ipo_listing_at_array.forEach(element => {
                if (element == "BSE SME" || element == "NSE SME") {
                    count_check = count_check + 1;
                }
            });

            if (count_check > 1) {
                alert("Please select only one IPO Listing At");
                return false;
            }
        }

        if (e.prospectus_rhp != "") {
            if (e.market_maker_id == "" && e.issue_category == 'SME') {
                alert('Market maker update required');
            }
        }
        return true;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        console.log("handle submit called .. ")

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (Array.isArray(trimmedValues.ipo_listing_at_array)) {
            trimmedValues.ipo_listing_at = trimmedValues.ipo_listing_at_array.join(', ');
        }

        // console.log("chk trimmedValue : ", trimmedValues);

        if (validate(trimmedValues)) {
            dispatch(startLoading());

            api.post('/admin-forthcomingipo-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again
        }

        setSubmitting(false);
    };

    return (
        <>
            {
                isAddMode ? "" :
                    <IpoTabs idAtr={result.id} ipo_confirm={result?.ipo && result?.ipo[0]?.ipo_confirmation_status} />
            }

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ForthcomingIpoForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} stockExchage={stockExchage} setStockExchage={setStockExchage} />
            </Formik>
        </>
    )
}

export default ForthcomingIPO;
