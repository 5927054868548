import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import NumberDecimalInput from '../Common/NumberDecimalInput';

const NCDBiddingForm = ({ result, handleGoBack, loading, initialValues }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <Form id='myForm'>

        {result !== undefined && result.ncdBidDetail !== undefined && result.ncdBidDetail.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.ncdBidDetail[0].id}
            </div>
          </div>
        }
        <div className="mb-3 row">
          <label htmlFor="ncd_id" className="col-lg-2">Issuer Company</label>
          <div className="col-lg-6">
            {(
              result !== undefined &&
              result.ncdBidDetail !== undefined &&
              result.ncdBidDetail.length > 0 &&
              result.ncdBidDetail[0].lock_status == 1
            )
              ?
              <h4>{result.ncdBidDetail[0].company_name}</h4>
              :
              (

                <Field as="select" name="ncd_id" className="form-select" tabIndex={1}>
                  <option></option>
                  {result.ncdList !== undefined && result.ncdList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.company_name}
                    </option>
                  ))}
                </Field>

              )
            }
          </div>
        </div>

        {result !== undefined && result.ncdBidDetail !== undefined && result.ncdBidDetail.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">Listing Exchange</label>
            <div className="col-lg-2">
              <div className="col-lg-4">
                {result.ncdBidDetail[0].listing_at}
              </div>
            </div>
          </div>
        }

        {result !== undefined && result.ncdBidDetail !== undefined && result.ncdBidDetail.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">NSE Symbol</label>
            <div className="col-lg-2">
              {result.ncdBidDetail[0].nse_symbol}
            </div>
            <label htmlFor="" className="col-lg-2">BSE Scripcode</label>
            <div className="col-lg-2">
              {result.ncdBidDetail[0].bse_scriptcode}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="bid_date" className="col-lg-2">Bid Date</label>
          <div className="col-lg-2">
            <Field className="form-control" type="date" tabIndex={5} name="bid_date" /></div>
          <div className="col-lg-1"></div>
          <label htmlFor="bidding_time" className="col-lg-2">Bid Time (HH:MM) IST</label>
          <div className="col-lg-1">
            <Field className="form-control" type="time" tabIndex={5} name="bid_time" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2"></label>
          <div className="col-lg-2"><b>Shares Offered (Total)</b></div>
          <div className="col-lg-2"><b>Shares Bid For (BSE + NSE)</b></div>
          <div className="col-lg-2"><b>Number of Time Subscribed (BSE + NSE)</b></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Category1</label>

          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={10} maxLength={3} nameAtr="category1_offered" />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={5} maxLength={5} nameAtr="category1_shares_bid_for" />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={35} type="text" nameAtr="category1" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Category2</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={25} nameAtr="category2_offered" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={30} nameAtr="category2_shares_bid_for" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={35} type="text" nameAtr="category2" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Category3</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={40} nameAtr="category3_offered" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={45} nameAtr="category3_shares_bid_for" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={50} type="text" nameAtr="category3" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Category4</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={55} nameAtr="category4_offered" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={60} nameAtr="category4_shares_bid_for" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={65} type="text" nameAtr="category4" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Others</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={70} nameAtr="other_offered" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={75} nameAtr="other_shares_bid_for" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={80} type="text" nameAtr="other" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Total</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={85} nameAtr="total_offered" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={90} nameAtr="total_shares_bid_for" maxLength={5} />
          </div>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" tabIndex={80} type="text" nameAtr="total" maxLength="15" decimalPoint="4" />
            <span className="form-control-feedback">times</span></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Display Status</label>
          <div className="col-lg-4">
            <Field className="form-check-input" type="checkbox" tabIndex={105} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>

          <label htmlFor="" className="col-lg-2">Lock Status</label>
          <div className="col-lg-4">
            <Field className="form-check-input" type="checkbox" tabIndex={106} name="lock_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('lock_status', newValue);
            }} />
          </div>

        </div>

        <div className="mb-3 text-center">
          {result.ncdBidDetail !== undefined && result.ncdBidDetail.length > 0 && result.ncdBidDetail[0].lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={110}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.ncdBidDetail !== undefined && result.ncdBidDetail.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={115}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>

    </>
  )
}

export default NCDBiddingForm
