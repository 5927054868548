import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import IpoTabs from "./IPONewPageTabs";
import { finishLoading, selectLoading, startLoading } from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../common/useApi";
import { Formik } from "formik";
import IpoDrhpForm from "./IpoDrhpForm";

const IpoDrhp = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });

    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: "",
        ipo_confirmation_status: "",
        ipoDRHPData: [
            {
                drhp_code: 0,
                drhp_code_date: "",
                display_status: false,
            }
        ]
    });
    const validate = (e) => {
        for (let i = 0; i < e.ipoDRHPData.length; i++) {
            if (!e.ipoDRHPData[i].drhp_code) {
                alert(`DRHP Code ${i + 1} cannot be blank`);
                return false;
            }
            if (!e.ipoDRHPData[i].drhp_code_date) {
                alert(`DRHP Code Date ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Edit DRHP" })

            api.post('/admin-ipodrhp-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (response.data.ipoDRHPData.length > 0) {
                        const { ipo, ipoDRHPData } = response.data;
                        setInitialValues(prevValues => ({
                            ...prevValues,
                            id: id || "",
                            company_name: ipo[0]?.company_name || "",
                            lock_status: ipo[0]?.lock_status || "",
                            ipo_confirmation_status: ipo[0]?.ipo_confirmation_status || "",
                            ipoDRHPData: Array.isArray(ipoDRHPData) && ipoDRHPData.length > 0
                                ? ipoDRHPData.map(item => ({
                                    drhp_code: item.drhp_code || 0,
                                    drhp_code_date: item.drhp_code_date || "",
                                    display_status: item.display_status || false,
                                }))
                                : prevValues.ipoDRHPData
                        }));
                    }

                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {

                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        if (validate(trimmedValues)) {
            dispatch(startLoading());
            trimmedValues.id = id;
            api.post('/admin-ipodrhp-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    // navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again


            setSubmitting(false);
        }

    };
    return (
        <>
            <IpoTabs idAtr={result.id} ipo_confirm={result?.ipo && result?.ipo[0]?.ipo_confirmation_status} />

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <IpoDrhpForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} />
            </Formik>
        </>
    )
}
export default IpoDrhp;