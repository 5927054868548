import React, { useEffect } from 'react';
import useApi from '../../common/useApi';

const ErrorPage = ({ errorDetails }) => {
    const api = useApi();
    useEffect(() => {
        const sendErrorEmail = () => {
            api
                .post(`ajax/error`)
                .data({ errorDetails })
                .then((response) => {
                    if (response.data.msg === 1) {
                        console.log('Error notification email sent successfully');
                    } else {
                        console.log('Error !');
                    }
                })
                .catch((error) => {
                    console.log('Error ');
                });
        };

        if (errorDetails) {
            // sendErrorEmail();
        }
    }, [errorDetails]);

    return (
        <div className="containner">
            <div className="row main">
                <div className="col-md-12 p-4">
                    <h1>Something went wrong.</h1>
                    <p>{process.env.REACT_APP_ERROR_MESSAGE}</p>
                    <p>{JSON.stringify(errorDetails)}</p>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
