import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TodoForm from './TodoForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const Todo = () => {
    const validationSchema = Yup.object({
        config_value: Yup.string().trim().required('Todo is required'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({ title: '' });
    const api = useApi();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        config_value: ''
    });

    const handleGoBack = () => {
        dispatch(setMessage(''));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        dispatch(startLoading());

        const fetchData = async () => {
            try {
                const response = await api.post('/admin-todos-read');
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setMetaData({ title: 'Update Todo List' });
                    setInitialValues(response.data.todos[0]);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            } catch (error) {
                console.error('Error:', error);
                dispatch(setMessage('Error fetching data'));
            } finally {
                dispatch(finishLoading());
            }
        };

        fetchData();
    }, []); // Run once on mount


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            dispatch(startLoading());
            const trimmedValues = { config_value: values.config_value.trim() };
            const response = await api.post('/admin-todos-save', {
                values: trimmedValues
            });
            if (response.data.msg === 1) {
                dispatch(setMessage('Todo Updated Successfully'));
            } else {
                dispatch(setMessage(response.data.error));
            }
        } catch (error) {
            console.error('Error:', error);
            dispatch(setMessage('Error updating todo'));
        } finally {
            dispatch(finishLoading());
            setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>{metaData.title}</h1>
            <hr />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <TodoForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    );
};

export default Todo;
