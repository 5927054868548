import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoMutualFundForm from './IpoMutualFundForm';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings } from '../Common/CommonFunction';
import IpoTabs from './IPONewPageTabs';

const IpoMutualFund = () => {

    const validationSchema = Yup.object({});

    const validate = (e) => {
        for (let i = 0; i < e.ipoMutualFund.length; i++) {
            if (e.ipoMutualFund[i].mf_scheme_name == "" || e.ipoMutualFund[i].mf_scheme_name == null) {
                alert(`Mutual Fund Scheme ${i + 1} cannot be blank`);
                return false;
            } else if (e.ipoMutualFund[i].shares_allocated == "" || e.ipoMutualFund[i].shares_allocated == null) {
                alert(`No. of Shares Allocated for Mutual Fund Scheme ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        ipo_confirmation_status: false,
        issue_price_upper: "",
        issue_price_final: "",
        ipoMutualFund: [{
            ipo_id: "", series: "", anchor_investor_id: "", mf_scheme_name: "", no_of_mutual_funds: "", shares_allocated: "",
            bid_price: "", anchor_investor_portion: "", total_amount_allotted: "", display_status: false
        }]
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/822");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Mutual Fund Scheme" })

            api.post('/admin-ipomutualfund-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    console.log("The result is: ", response.data);
                    setResult(response.data);

                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        company_name: response.data.ipoDetail[0].company_name,
                        lock_status: response.data.ipoDetail[0].lock_status,
                        issue_price_upper: response.data.ipoDetail[0].issue_price_upper,
                        issue_price_final: response.data.ipoDetail[0].issue_price_final,
                        ipoMutualFund: response.data.ipoMutualFund
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true"
        }
    }, []);



    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);

        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());

            api.post('/admin-ipomutualfund-save', {
                values: trimmedValues
            }
            ).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <IpoTabs idAtr={id} ipo_confirm={result?.ipoDetail?.[0]?.ipo_confirmation_status} />

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.ipoDetail !== undefined &&
                result.ipoDetail.length > 0 &&
                result.ipoDetail[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            {result !== undefined && result.id !== undefined && result.id.length > 0 &&
                <>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4" tabIndex={1}>
                            {id}
                        </div>
                    </div>
                </>
            }

            {result !== undefined && result.ipoDetail !== undefined && result.ipoDetail.length > 0 &&
                <>
                    <div className="my-3 row">
                        <label htmlFor="" className="col-2">Company Name:</label>
                        <div className="col-8" tabIndex={5}>
                            <b>{result.ipoDetail[0].company_name}</b>
                        </div>
                    </div>
                </>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >

                {({ values }) => (
                    <Form id='myForm'>
                        <table className="table table-bordered table-striped" id="repeater1" tabIndex={10}>
                            <thead>
                                <tr className="bg-secondary text-white">
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "5%" }}>S.No.</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "30%" }}>Mutual Fund Scheme</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>No. of Shares Allocated</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Bid Price Per Share (Rs)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Anchor Investor Portion (%)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Total Amount Allocated (Rs)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "12%" }}>Display Status</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "5%" }}>Action</th>
                                </tr>
                            </thead>


                            <FieldArray
                                name="ipoMutualFund"
                                render={({ push, remove }) => (
                                    <>
                                        <tbody>
                                            <IpoMutualFundForm remove={remove} />
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="9" className="text-end">
                                                    <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({
                                                        ipo_id: "", series: "", anchor_investor_id: "", mf_scheme_name: "", no_of_mutual_funds: "", shares_allocated: "",
                                                        bid_price: "", anchor_investor_portion: "", total_amount_allotted: "", display_status: false
                                                    })}>
                                                        Add +
                                                    </button>
                                                </td></tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </table>

                        <br></br>

                        <div className="my-3 row">
                            <label htmlFor="" className="col-2">No. of Mutual Funds</label>
                            <div className="col-8">
                                <Field className="form-control" rows="5" tabIndex={15}
                                    name="mutual_fund_number" ></Field>
                            </div>
                        </div>

                        <div className="mb-3 text-center">
                            {initialValues.lock_status !== 1 && (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={20}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        Update
                                    </span>
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={25}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default IpoMutualFund;
