import React, { useEffect, useRef, useState } from 'react'
import { Formik, Field, Form } from 'formik';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ReportCalendarWebForm from './ReportCalendarWebForm';
import { Button, Modal, Nav, Tab, Table } from 'react-bootstrap';

const ReportCalendarWeb = () => {

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const validationSchema = Yup.object({
        report_title: Yup.string().trim().required('Please enter report title'),
        short_name: Yup.string().trim().required('Please enter short name'),
        report_sql: Yup.string().trim().required('Please enter report SQL.'),
        id_field: Yup.string().trim().required('Please enter id field'),
        title_field: Yup.string().trim().required('Please enter title field'),
        desc_field: Yup.string().trim().required('Please enter desc field'),
        date_field: Yup.string().trim().required('Please enter date field'),
        topic_id_field: Yup.string().trim().required('Please enter topic id field'),
        urlrewrite_folder_name: Yup.string().trim().required('Please enter urlrewrite_folder_name'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        report_title: "",
        table_heading: "",
        search_column: "",
        link_file_name: "",
        select_list_function_name: "",
        minimum_nav_year: 0,
        minimum_nav_month: 0,
        short_name: "",
        meta_title: "",
        meta_desc: "",
        report_sql: "",
        urlrewrite_folder_name: "",
        id_field: "",
        title_field: "",
        desc_field: "",
        date_field: "",
        topic_id_field: "",
        report_description: "",
        report_breadcrumb: "",
        report_disclaimer: "",
        display_status: 0,
        stock_holiday: 0,
        additional_parameter: 0,
        lock_status: 0,
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    const setReportCalendarWebPage = () => {
        dispatch(startLoading());
        console.log("id : " + id);
        if (isAddMode) {
            setMetaData({ "title": "Add New Web Report" })
        } else {
            setMetaData({ "title": "Edit Existing Web Report" })
        }
        api.post('/admin-reportwebcalendar-read', {
            id: id
        }).then(response => {
            if (response.data.msg === 1) {
                setResult(response.data);

                if (!isAddMode) {
                    setInitialValues(response.data.reportwebcalendar[0]);
                }
            } else {
                dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
        }).catch(error => {
            console.error('Error:', error);
            dispatch(finishLoading());
        });
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            setReportCalendarWebPage();
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-reportwebcalendar-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    const editParam = (id) => {
        dispatch(startLoading());
        console.log("id : " + id);
        const parameter = result.reportwebcalendarParameter.find(param => param.id === id);
        if (parameter) {
            // Setting the initial modal values for editing
            setInitialModalValues(parameter);
            handleShow(); // Showing the modal for editing
        } else {
            console.error("Parameter with id:", id, "not found!");
        }
        dispatch(finishLoading());
    };
    const handleShowModal = () => {
        setInitialModalValues({
            reports_calendar_web_id: id,
            code: "",
            code_desc: "",
            report_title: "",
            table_heading: "",
            meta_title: "",
            meta_desc: "",
            report_description: "",
            report_disclaimer: "",
            display_order: "",
            display_status: 0
        });
        handleShow();
    }
    const [initialModalValues, setInitialModalValues] = useState({
        id: "",
        reports_calendar_web_id: id,
        code: "",
        code_desc: "",
        report_title: "",
        table_heading: "",
        meta_title: "",
        meta_desc: "",
        report_description: "",
        report_disclaimer: "",
        display_order: "",
        display_status: 0
    })

    const handleModalSubmit = (values, { setSubmitting, }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (!trimmedValues.hasOwnProperty('meta_desc')) {
            trimmedValues['meta_desc'] = "";
        }

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-reportwebcalendar-parameter-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                setReportCalendarWebPage();
                handleClose();
                setInitialModalValues({
                    id: "",
                    reports_calendar_web_id: id,
                    code: "",
                    code_desc: "",
                    report_title: "",
                    table_heading: "",
                    meta_title: "",
                    meta_desc: "",
                    report_description: "",
                    report_disclaimer: "",
                    display_order: "",
                    display_status: 0
                })
                dispatch(setMessage(response.data.success));
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);

            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {(result !== undefined && result.reportwebcalendar !== undefined && result.reportwebcalendar.length > 0 && result.reportwebcalendar[0].additional_parameter == 1) ?
                <Tab.Container defaultActiveKey="link-1">
                    <Nav variant="tabs" className="mt-2">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2">Parameter</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="link-1" className='pt-3 px-2'>
                            <h1>
                                {metaData.title}
                            </h1>
                            {result.reportwebcalendar != undefined && result.reportwebcalendar &&
                                result.reportwebcalendar.length > 0 &&
                                result.reportwebcalendar[0].lock_status !== undefined &&
                                result.reportwebcalendar[0].lock_status == 1 && (
                                    <h3 className="text-danger mt-3 mb-0">
                                        Record Is Locked , you can't update it.
                                    </h3>
                                )}
                            <hr />
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                <ReportCalendarWebForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                            </Formik>
                        </Tab.Pane>

                        <Tab.Pane eventKey="link-2" className='pt-3'>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    {!initialValues.lock_status == 1 &&
                                        <button type="button" className="btn btn-primary btn-sm mt-2"
                                            onClick={handleShowModal}>
                                            Add New
                                        </button>
                                    }
                                </div>
                                <div className="col-12">
                                    {result.reportwebcalendarParameter !== undefined && result.reportwebcalendarParameter.length > 0 &&
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    {Object.keys(result.reportwebcalendarParameter[0]).map((key) => (
                                                        <th key={key}>{key}</th>
                                                    ))}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.reportwebcalendarParameter.map((item) => (
                                                    <tr key={item.id}>
                                                        {Object.values(item).map((value, index) => (
                                                            <td key={index}>
                                                                <div dangerouslySetInnerHTML={{ __html: value }} />
                                                            </td>
                                                        ))}
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs mt-2 mr-3" onClick={() => editParam(item.id)}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            </div>

                            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                                <Modal.Header closeButton>
                                    <Modal.Title>Report Calendar Web Parameter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={initialModalValues}
                                        onSubmit={handleModalSubmit}
                                    >
                                        {({ setFieldValue }) => (
                                            <Form id='myModalForm'>

                                                <Field type="hidden" name="reportWeb-calendar" />

                                                <div className="mb-3 row">
                                                    <label htmlFor="code" className="col-lg-2">Code</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code" maxLength="100" required />
                                                    </div>

                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="code_desc" className="col-lg-2">Code Desc (For Admin Only)</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code_desc" maxLength="150" required />
                                                    </div>

                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_title" className="col-lg-2">Report Title</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="report_title" maxLength="100" />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label htmlFor="table_heading" className="col-lg-2">Table Heading</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="table_heading" maxLength="100" />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label htmlFor="meta_title" className="col-lg-2">Meta Title</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="meta_title" maxLength="100" />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label htmlFor="meta_desc" className="col-lg-2">Meta Desc</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="meta_desc" maxLength="100" />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="5" name="report_description" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="3"
                                                            name="report_disclaimer"
                                                            id="report_disclaimer" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='number' name="display_order" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={80} onChange={(e) => {
                                                            setFieldValue('display_status', e.target.checked ? true : false);
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <div className="col-lg-12 text-end">
                                                        <Button variant="secondary" onClick={handleClose} className='me-2'>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" type='submit' >Submit</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal.Body>
                            </Modal>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                :
                <>
                    <h1>
                        {metaData.title}
                    </h1>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <ReportCalendarWebForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                    </Formik>
                </>
            }
        </>



    )
}

export default ReportCalendarWeb
