
import React from 'react';
import useApi from '../../../common/useApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../state/slices/message';
import { Link } from 'react-router-dom';

const SpamFeedbackTable = ({ spam_message, index, updateArray, setUpdateArray, callSpamList }) => {
    const api = useApi();
    const dispatch = useDispatch();

    const handleArray = (c) => {
        if (c) {
            if (!updateArray.includes(`${spam_message.id}-${spam_message.source}`)) {
                setUpdateArray([...updateArray, `${spam_message.id}-${spam_message.source}`]);
            }
        }
        if (!c && updateArray.length > 0) {
            setUpdateArray(prevArray => prevArray.filter(item => item != `${spam_message.id}-${spam_message.source}`));
        }
    }

    const fnAction = (spam) => {
        if (window.confirm("Are you sure")) {
            api.post('/admin-feedback-spam-action', {
                "values": {
                    "id": spam_message.id,
                    "spam": spam,
                    "source": spam_message.source
                }
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    callSpamList();
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
            });
        }

    }
    return (
        <>
            <tr key={index}>
                <td>
                    <div><p>
                        {spam_message.source == "IPO" &&
                            <Link to={`/admintool9x/ipofeedback/${spam_message.id}/`}>{spam_message.id}</Link>
                        }
                        {spam_message.source == "Feedback" &&
                            <Link to={`/admintool9x/feedbackupdate/${spam_message.id}/`}>{spam_message.id}</Link>
                        }
                        {spam_message.source == "GMP" &&
                            <span>{spam_message.id}</span>
                        }

                    </p></div>
                </td>
                <td>
                    <div>{spam_message.source}</div>
                </td>
                <td>
                    <div><p>{spam_message.topic}</p></div>
                </td>
                <td>
                    <a href="#" style={{ color: 'red' }} title="Yes, It's spam." onClick={() => fnAction(1)}>Yes</a> &nbsp;| &nbsp;
                    <a href="#" style={{ color: 'green' }} title="No, It's not spam." onClick={() => fnAction(0)}>No</a>
                </td>
                <td>
                    <div><p>{spam_message.Code_Desc}</p></div>
                </td>
                <td><div className="w-auto p-3 border border-1" style={{ backgroundColor: "#fff" }} dangerouslySetInnerHTML={{ __html: spam_message.message }}></div></td>
                <td>
                    <div>{spam_message.date_posted}</div>
                </td>
                <td>
                    {
                        spam_message.member_id != "" &&
                        <Link to={`/admintool9x/member/${spam_message.member_id}/`}>{spam_message.display_name} ({spam_message.member_id})</Link>
                    }
                </td>
                <td>
                    <div>{spam_message.posted_by_ip}</div>
                </td>
                <td className="text-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={updateArray.includes(`${spam_message.id}-${spam_message.source}`)}
                        onChange={(e) => {
                            handleArray(e.target.checked);
                        }}
                    />

                </td>
            </tr>
        </>
    )
}

export default SpamFeedbackTable;
