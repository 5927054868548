import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BrokerComplaintForm from './BrokerComplaintForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const BrokerComplaint = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('Company Name is Mandetory.'),
        broker_id: Yup.string().trim().required('Trading Company ID is required.'),
        exchange: Yup.string().trim().required('Exchange is required.'),
        financial_year: Yup.string().trim().required('Financial Year is required.'),
        broker_unique_id: Yup.string().trim().required(`Please Enter the Broker's Unique ID.`),
        ucc_count: Yup.string().trim().required(`Please Enter UCC Count.`),
        complaint_count: Yup.string().trim().required(`Please Enter Complaint count.`)
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        ucc_count: "",
        complaint_count: "",
        exchange: "",
        financial_year: "",
        broker_unique_id: "",
        broker_id: "",
        display_status: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Broker Complaint" })
            } else {
                setMetaData({ "title": "Edit Broker Complaint" })
                api.post('/admin-brokercomplaint-read', {
                    id: id
                }).then(response => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        console.log(response.data.brokerComplaint);
                        if (!isAddMode) {
                            setInitialValues(response.data.brokerComplaint[0]);
                        } else {
                            setInitialValues((prevState) => ({
                                ...prevState,
                                display_order: response.data.brokerComplaint[0].display_status ?? 0,
                            }));
                        }
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                    dispatch(finishLoading());
                })
                    .catch(error => {
                        console.error('Error:', error);
                        dispatch(finishLoading());
                    });
            }

        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-brokercomplaint-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <BrokerComplaintForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}

export default BrokerComplaint