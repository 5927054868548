
import React, { useEffect, useState, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';

const IpoMutualFundForm = ({ remove }) => {
    const { setFieldValue, values } = useFormikContext();
    const [mannualMode, setMannualMode] = useState(false);

    useEffect(() => {
        if (!mannualMode) {
            calculateAnchorPortion();
            calculateTotalAmtAllocated();
        }
    }, [values.ipoMutualFund])

    const calculateAnchorPortion = () => {
        const repeaterCount = values.ipoMutualFund.length;
        let totalShares = 0;
        // Calculate totalShares
        for (let i = 0; i < repeaterCount; i++) {
            if (values.ipoMutualFund[i].display_status) {
                const noOfShares = parseFloat(values.ipoMutualFund[i].shares_allocated) || 0;
                totalShares += noOfShares;
            }
        }

        // Calculate and update percentage
        for (let i = 0; i < repeaterCount; i++) {
            if (values.ipoMutualFund[i].display_status) {
                const noOfShares = parseFloat(values.ipoMutualFund[i].shares_allocated) || 0;
                const percentage = (noOfShares / totalShares) * 100 || 0;
                setFieldValue(`ipoMutualFund.[${i}].anchor_investor_portion`, percentage.toFixed(2));
            } else {
                setFieldValue(`ipoMutualFund.[${i}].anchor_investor_portion`, '0');
            }
        }
    };

    const calculateTotalAmtAllocated = () => {
        const repeaterCount = values.ipoMutualFund.length;

        for (let i = 0; i < repeaterCount; i++) {
            const noOfShares = parseFloat(values.ipoMutualFund[i].shares_allocated) || 0;
            const bidPrice = parseFloat(values.ipoMutualFund[i].bid_price) || 0;

            setFieldValue(`ipoMutualFund[${i}].total_amount_allotted`, (noOfShares * bidPrice).toFixed(2));
        }
    };


    let sr_no = 1;

    return (
        <>
            {values.ipoMutualFund.map((mutual_fund, index) => (
                <tr key={index}>
                    <td>
                        <Field className="form-control" maxLength="3" value={sr_no++} readOnly />
                    </td>
                    <td>
                        <Field type="text" name={`ipoMutualFund.${index}.mf_scheme_name`} className="form-control" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10"
                            nameAtr={`ipoMutualFund.${index}.shares_allocated`} placeholder="Number of shares allocated"
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10"
                            nameAtr={`ipoMutualFund.${index}.bid_price`} placeholder="Bid price per share"
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10"
                            nameAtr={`ipoMutualFund.${index}.anchor_investor_portion`} placeholder="Anchor Investor Portion"
                            dynamicOnChange={(e) => { setMannualMode(true) }}
                            dynamicOnBlur={(e) => { setMannualMode(false) }}
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10"
                            nameAtr={`ipoMutualFund.${index}.total_amount_allotted`} placeholder="Total Amount Allotted"
                            dynamicOnChange={(e) => { setMannualMode(true) }}
                            dynamicOnBlur={(e) => { setMannualMode(false) }}
                        />
                    </td>

                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`ipoMutualFund.${index}.display_status`} onChange={(e) => {
                                    console.log("Target is checked: ", e.target.checked)
                                    setFieldValue(`ipoMutualFund.${index}.display_status`, e.target.checked)
                                }
                                }
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default IpoMutualFundForm;