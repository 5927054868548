import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const TradingCompanyDematChargesForm = ({ result, handleGoBack, loading }) => {
  const { setFieldValue } = useFormikContext();
  return (

    <>
      <Form id='myForm'>

        <div className="mb-3 row">
          <label htmlhtmlfor="depository_service_provider" className="col-lg-2">Depository</label>
          <div className="col-lg-2">
            <Field type='text' className="form-control" tabIndex={5} name="depository_service_provider" />
          </div>
          <div className="col-lg-4">
            i.e. CDSL, NSDL
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="dp_id" className="col-lg-2">DP ID</label>
          <div className="col-lg-2">
            <Field type='text' className="form-control" tabIndex={10} name="dp_id" />
          </div>
          <div className="col-lg-4">
            i.e. 83200
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="address" className="col-lg-2">Address</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={15} name="address" rows="3" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="telephone_number" className="col-lg-2">Phone</label>
          <div className="col-lg-4">
            <Field type='text' className="form-control" tabIndex={20} name="telephone_number" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="email" className="col-lg-2">Email</label>
          <div className="col-lg-6">
            <Field type='text' className="form-control" tabIndex={25} name="email" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="about_us" className="col-lg-2">About Us</label>
          <div className="col-lg-8">
            <Field as="textarea" className="form-control" tabIndex={30} name="about_us" rows="3" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="services" className="col-lg-2">Services Offered</label>
          <div className="col-lg-8">
            <Field as="textarea" className="form-control" tabIndex={35} name="services" rows="3" />
          </div>
        </div>
        <div className="mb-3 row">
          <h3>Tariff</h3>
        </div>
        <div className="mb-3 row">
          <label htmlFor="account_opening_fee" className="col-lg-2">Account Opening Fee</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={40} name="account_opening_fee" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 300
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="advance_deposite" className="col-lg-2">Advance / Deposite</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={45} name="advance_deposite" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 1000 Onetime Refundable Deposit
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="amc" className="col-lg-2">AMC</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={50} name="amc" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 600 per year
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="dematerialisation_charges" className="col-lg-2">Dematerialisation</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={55} name="dematerialisation_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 50 per set up + Rs 50 courier charges
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="rematerialisation_charges" className="col-lg-2">Rematerialisation</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={60} name="rematerialisation_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 50 per set up + Rs 50 courier charges
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="transaction_debit_charges" className="col-lg-2">Transaction (Debit)</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={65} name="transaction_debit_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 8 + Rs 5.50(CDSL charges) per ISIN
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="pledge_creation_charges" className="col-lg-2">Pledge Creation Charges</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={70} name="pledge_creation_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 50 per set up
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="pledge_creation_confirmation_charges" className="col-lg-2">Pledge Creation Confirmation</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={75} name="pledge_creation_confirmation_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Nil
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="pledge_invocation_charges" className="col-lg-2">Pledge Invocation</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={80} name="pledge_invocation_charges" rows="3" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 50 per set up
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="failed_instruction_charges" className="col-lg-2">Failed Instruction</label>
          <div className="col-lg-6">
            <Field type='text' className="form-control" tabIndex={85} name="failed_instruction_charges" />
          </div>
          <div className="col-lg-4">
            i.e. Rs 50 per ISIN
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="other_charges" className="col-lg-2">Other Charges</label>
          <div className="col-lg-6">
            <Field as="textarea" className="form-control" tabIndex={90} name="other_charges" rows="3" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-4">
            <Field className="form-check-input" type="checkbox" tabIndex={95} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>
        <hr />
        <div className="mb-3 text-center">
          {result != undefined && result.tradingCompany != undefined && result.tradingCompany[0].lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={100}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                Update
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={105}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default TradingCompanyDematChargesForm





