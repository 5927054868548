import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { closePopUpWindow, funCloseWebWindow, validateInput } from '../../admintool9x/Common/CommonFunction';
import LeadSmeIpoLmForm from './LeadSmeIpoLmForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import routeConfig from '../../../routeConfig';

const LeadSmeIpoLm = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const main_ref = queryParams.get("main_ref") ?? "";

    const loading = useSelector(selectLoading);
    const recaptchaRef = useRef(null);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });

    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        enquiry_type: Yup.string().trim().required('Select enquiry type'),
        author_name: Yup.string().trim().required('Name is required').matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
        phone: Yup.string().trim().required('Phone is required').min(10, 'Please enter a valid phone number'),
        author_email: Yup.string().trim().required('Email Address is required').email('Invalid email format'),
        city: Yup.string().trim().required('City is required'),
        state: Yup.string().trim().required('State is required'),
        company_name: Yup.string().trim().required('Company Name is required'),
        message_content: Yup.string().trim().required('Message is required').test('no-bad-words', 'Message contains inappropriate content', value => !/badword/i.test(value)),
        recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
    });
    const [initialValues, setInitialValues] = useState({
        author_name: "",
        author_email: "",
        city: "",
        state: "",
        phone: "",
        message_content: "",
        company_name: "",
        referrer: main_ref,
        lead_source: "",
        responded: "",
        recaptcha: ''
    });

    const btnClick = () => {
        if (main_ref != "") {
            funCloseWebWindow(main_ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            setMetaData({ "title": "SME IPO Enquiry" })
            api.post('/leadssmeipo-read'
            ).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = async (values, { setSubmitting }) => {
        let phoneError = validateInput(values.phone, 'phoneNumber');
        if (phoneError) {
            alert(phoneError);
            setSubmitting(false);
            return;
        }

        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();

        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;

        console.log("Form submited with values: ", trimmedValues)

        dispatch(startLoading());

        api.post('/leadssmeipo-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=ContactUs&main_ref=` + main_ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>SME IPO Enquiry</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row main mt-2'>
                <div className='col-12'>
                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />


                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <LeadSmeIpoLmForm result={result} btnClick={btnClick} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} />
                    </Formik>

                </div>
            </div>
        </>
    )
}

export default LeadSmeIpoLm
