import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ReportCalendarForm from './ReportCalendarForm';

const ReportCalendar = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        report_title: "",
        security_level_id: "",
        report_sql: "",
        link_file_name: "",
        id_field: "",
        title_field: "",
        date_field: "",
        status_field: "",
        additional_link: "",
        report_description: "",
        report_disclaimer: "",
        display_status: false,
        lock_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/142"); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New ReportCalendarEdit" })
            } else {
                setMetaData({ "title": "Edit ReportCalendarEdit" })
            }
            api.post('/admin-reportcalendar-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.reportcalendar);
                    if (!isAddMode) {
                        setInitialValues(response.data.reportcalendar[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());


        api.post('/admin-reportcalendar-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/142");
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.reportcalendar !== undefined &&
                result.reportcalendar.length > 0 &&
                result.reportcalendar[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <br />
            <ReportCalendarForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
        </>
    )
}

export default ReportCalendar
