import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../common/useApi';
import './Report.css';
import { startLoading, finishLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from "../../../state/slices/message";
import { useTable, usePagination, useSortBy, defaultGroupByFn } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { customReplace, handlePopupWin, isValidMonth, isValidVariable, isValidYear, validateId } from '../Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const parseSortParam = (sortParam) => {
  if (!sortParam) return [];

  // Determine the position of the sort direction
  const sortDirection = sortParam.endsWith('desc') ? 'desc' : 'asc';
  // Extract column name by removing the direction part
  const sortColumn = sortParam.replace(/(asc|desc)$/, '');

  return [{
    id: sortColumn,
    desc: sortDirection == 'desc',
  }];
};
const Report = () => {
  const loading = useSelector(selectLoading);
  const { user: currentUser } = useSelector((state) => state.auth);
  const api = useApi();
  const currentDate = new Date();
  const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
  const dispatch = useDispatch();
  let { id, parameter_id } = useParams();
  const tableRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [result, setResult] = useState({
    prevId: 0,
    prev_parameter_id: 0,
    parameter_id: parameter_id,
    tableColumns: [],
    tableData: [],
    pageIndex: 0,
    pageSize: 50,
    totalPages: 1,
    totalRecords: 0,
    masterDataCalled: 0,
    pageInitalDataCalled: 0,
    readyToCallChildDataAPI: 0,
    finalPageDataLoaded: 0,
    firstTimeDataLoaded: 0,
    callDataAPIFromPaging: 0,

    reportInfo: {

    },
    reportParameterData: [

    ],
    pageFullURL: "",
    currentDay: null,
    currentMonth: null,
    currentYear: null,
    currentFY: null,
    currentSort: "",
    currentSearch: {},
    currentSearchExact: 0,
    currentCounter: 0,
    headerDateNavigationCalled: 0,
    reportTitle: "",
    tableHeading: "",
    currentLabel: "",
    reportDescription: "",
    reportDisclaimer: "",
    metaTitle: "",
    metaDescription: "",
    pageURL: "",
    imageUrl: "",
    imageWidth: "",
    imageHeight: "",
  });

  const initialSortBy = useMemo(() => parseSortParam(result.currentSort), [result.currentSort]);

  var day, month, year, fy, sortParam, pageNumber = 1, search_exact;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },

  } = useTable(
    {
      columns: result.tableColumns,
      data: result.tableData,
      manualSortBy: true,
      initialState: {
        pageIndex: result.pageIndex,
        pageSize: result.pageSize,
        sortBy: initialSortBy
      },
      manualPagination: true,
      pageCount: result.totalPages,
    },
    useSortBy,
    usePagination
  );

  const apiButton = (jsonData) => {
    const userConfirmed = window.confirm("Are you sure you want to proceed?");
    if (!userConfirmed) {
      return;
    }
    dispatch(startLoading());
    jsonData.report_id = parseInt(id);
    api.post("/admin-report-api-action", { "values": jsonData })
      .then(response => {
        if (response.data.msg == 1) {
          console.log(response.data);
          dispatch(setMessage(response.data.success));
          setResult((prevState) => ({
            ...prevState,
            currentCounter: result.currentCounter + 1,
          }));
        } else {
          dispatch(setMessage(response.data.error));
        }
        dispatch(finishLoading());
      })
      .catch(error => {
        console.error('Error:', error);
        dispatch(finishLoading());
      });
  };

  useEffect(() => {
    window.apiButton = apiButton;
  }, [apiButton]);

  const parseSearchParams = (searchURL) => {
    let searchValues = {};

    if (searchURL) {
      const urlParams = new URLSearchParams(searchURL);

      urlParams.forEach((value, key) => {
        if (key.startsWith('search_')) {
          searchValues[key] = decodeURIComponent(value);
        }
      });
    }

    return searchValues;
  };

  useEffect(() => {
    if (didMountRef.current == "true" && id != "") {
      if (!validateId(id)) {
        dispatch(setMessage("Wrong Input"));
        navigate("/admintool9x");
      } else {

        let queryParams = new URLSearchParams(location.search);
        day = queryParams.get("day");
        month = queryParams.get("month");
        year = queryParams.get("year");
        fy = queryParams.get("fy");
        sortParam = queryParams.get("sort") ?? "";
        pageNumber = queryParams.get("page") ?? 1;
        search_exact = queryParams.get("search_exact") ?? 0;

        if (!isValidVariable(day)) {
          day = currentDate.getDate();
        }
        if (month == null || !isValidMonth(month)) {
          month = currentDate.getMonth() + 1;
        }
        if (month > 12 || month < 1) {
          month = currentDate.getMonth() + 1;
        }
        if (year == null && !isValidYear(year)) {
          year = currentDate.getFullYear();
        }

        month = String(month).padStart(2, '0');
        day = String(day).padStart(2, '0');

        pageNumber = isValidVariable(pageNumber) && validateId(pageNumber) ? pageNumber : 1;

        let searchValues = parseSearchParams(queryParams.toString());
        console.log(searchValues);
        setResult((prevState) => ({
          ...prevState,
          masterDataCalled: 0,
          prevId: id,
          pageInitalDataCalled: 0,
          readyToCallChildDataAPI: 0,
          finalPageDataLoaded: 0,
          currentDay: day,
          currentMonth: month,
          currentYear: year,
          currentFY: fy,
          pageIndex: pageNumber - 1,
          currentSort: sortParam,
          currentSearch: searchValues,
          currentSearchExact: search_exact,
          currentCounter: 0
        }));

      }

    } else {
      didMountRef.current = "true";
    }
  }, [id]);


  useEffect(() => {

    if (result.prevId == id && result.masterDataCalled == 0) {
      fetchMasterData();
    }
  }, [result.prevId]);


  const fetchMasterData = () => {
    dispatch(startLoading());
    api.post("/admin-report-master-read", {
      reportId: id,
    })
      .then((response) => {

        if (response.data.msg == 1) {
          setResult((prevState) => ({
            ...prevState,
            reportInfo: response.data.reportInfo[0],
            reportParameterData: response.data.reportParameterData,
            tableColumns: [],
            tableData: [],
            masterDataCalled: 1,
            prevId: id,
          }));
        } else {
          dispatch(setMessage(response.data.error));
        }
      }).finally(() => {
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(setMessage("Error fetching data:" + error));
      });
  }

  useEffect(() => {
    if (result.masterDataCalled == 1) {
      console.log("calling First APi " + result.masterDataCalled)
      setPageInitailData();
    }
  }, [result.masterDataCalled]);



  const setPageInitailData = () => {

    if (result.reportInfo.additional_parameter == "1") {
      if (result.reportParameterData.length == 0) {
        navigate("/admintool9x");
      } else {
        if (isValidVariable(parameter_id)) {
          if (parameter_id == "") parameter_id = 0;
          const idExists = result.reportParameterData.some(item => item.id == parameter_id);
          if (!idExists) {
            parameter_id = result.reportParameterData[0].id;
          }
        } else {
          parameter_id = result.reportParameterData[0].id;
        }
      }
    } else {
      parameter_id = 0;
    }

    let currentLabel = getMonthName(result.currentMonth) + " " + result.currentYear;
    let sreport_title = result.reportInfo.report_title ?? "";
    let sreport_description = result.reportInfo.report_description ?? "";
    let sreport_disclaimer = result.reportInfo.report_disclaimer ?? "";

    if (result.reportInfo.additional_parameter == "1") {
      let parameterJSON = result.reportParameterData.find(item => item.id == parameter_id);

      if (isValidVariable(parameterJSON.report_title)) {
        sreport_title = parameterJSON.report_title;
      }
      if (isValidVariable(parameterJSON.report_description)) {
        sreport_description = parameterJSON.report_description;
      }
      if (isValidVariable(parameterJSON.report_disclaimer)) {
        sreport_disclaimer = parameterJSON.report_disclaimer;
      }

      let sSelectedDDLValue = parameterJSON.code_desc;
      sSelectedDDLValue = sSelectedDDLValue.trim().toLowerCase();
      if (sSelectedDDLValue == "all") {
        sSelectedDDLValue = "";
      }

      sreport_title = customReplace(sreport_title, "[[Title]]", sSelectedDDLValue)
      sreport_description = customReplace(sreport_description, "[[Title]]", sSelectedDDLValue)
      sreport_disclaimer = customReplace(sreport_disclaimer, "[[Title]]", sSelectedDDLValue)
    }

    sreport_title = customReplace(sreport_title, "[[mm]] [[yyyy]]", currentLabel)
    sreport_description = customReplace(sreport_description, "[[mm]] [[yyyy]]", currentLabel)
    sreport_disclaimer = customReplace(sreport_disclaimer, "[[mm]] [[yyyy]]", currentLabel)

    sreport_title = customReplace(sreport_title, "[[yyyy]]", result.currentYear)
    sreport_description = customReplace(sreport_description, "[[yyyy]]", result.currentYear)
    sreport_disclaimer = customReplace(sreport_disclaimer, "[[yyyy]]", result.currentYear)

    sreport_title = customReplace(sreport_title, "[[mm]]", result.currentMonth)
    sreport_description = customReplace(sreport_description, "[[mm]]", result.currentMonth)
    sreport_disclaimer = customReplace(sreport_disclaimer, "[[mm]]", result.currentMonth)

    setResult((prevState) => ({
      ...prevState,
      reportDescription: sreport_description,
      reportDisclaimer: sreport_disclaimer,
      currentLabel: currentLabel,
      reportTitle: sreport_title,
      pageURL: result.reportInfo.additional_parameter == "1" ? `${routeConfig.report(id, parameter_id)}` : `${routeConfig.report(id)}`,
      pageInitalDataCalled: result.pageInitalDataCalled + 1,
      parameter_id: parameter_id,
      prev_parameter_id: parameter_id
    }));
  }

  const finalChildData = () => {
    dispatch(startLoading());

    var search_exact = document.getElementById("search_exact");
    if (search_exact) {
      const checked = search_exact.checked;
      // Do something with the checked value
      if (checked) {
        search_exact = true;
      } else {
        search_exact = false;
      }
    } else {
      search_exact = false;
    }
    const textboxes = document.getElementsByClassName('search-report-txt');
    const textboxArray = Array.from(textboxes);

    var searchTextBox = [];
    textboxArray.forEach((textbox) => {
      if (textbox.value !== "") {
        var item = {};
        item.key = textbox.getAttribute('name')
        item.value = textbox.value.trim();
        searchTextBox.push(item);
      }
    });

    const jsonString = JSON.stringify(searchTextBox, null, 2);
    api.post("/admin-report-child-read", {
      reportId: id,
      parameterId: isValidVariable(result.parameter_id) ? result.parameter_id : "0",
      ipage: (isValidVariable(result.pageIndex) ? result.pageIndex + 1 : 1),
      sort: isValidVariable(result.currentSort) ? result.currentSort : '0',
      search_exact: search_exact,
      searchTextBox: jsonString,
      year: result.currentYear,
      month: result.currentMonth,
      day: result.currentDay,
    })
      .then((response) => {

        if (response.data.msg == 1) {
          var columns;
          if (response.data.reportTableData.length > 0) {
            columns = Object.keys(response.data.reportTableData[0]).map((key, id) => {
              return {
                Header: key,
                accessor: key,
                Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />,
              }
            })
          } else {
            columns = [
              {
                Header: 'No Record',
                accessor: 'No Record',
                disableSortBy: true,
              },
            ];
          }

          if (result.reportInfo.update_status_field_name != "" && result.reportInfo.update_status_id_field != "") {
            setCheckboxes(response.data.reportTableData.reduce((acc, item) => {
              acc[item[result.reportInfo.update_status_id_field]] = false;
              return acc;
            }, {}));
          }

          setResult((prevState) => ({
            ...prevState,
            tableData: response.data.reportTableData,
            tableColumns: columns,
            pageSize: response.data.pageSize,
            totalPages: response.data.totalPages,
            totalRecords: response.data.totalRecords,
            readyToCallChildDataAPI: 0,
            finalPageDataLoaded: 1,
            firstTimeDataLoaded: 1,
            callDataAPIFromPaging: response.data.totalPages > 1 ? 1 : 0,
          }));
          console.log("calling data table ")
        } else {
          dispatch(setMessage("Error : " + response.data.error));

          setResult((prevState) => ({
            ...prevState,
            tableData: [],
            tableColumns: [
              {
                Header: 'Error',
                accessor: 'Error',
              },
            ],
            pageSize: 0,
            totalPages: 1,
            totalRecords: 0,
            callDataAPI: 0,
            callDataAPIFromPaging: 0,
          }));
        }
      }).catch((error) => {
        console.error("Error fetching data:", error);
      }).finally(() => {
        dispatch(finishLoading());

      });
  }

  useEffect(() => {
    console.log("calling APi " + result.masterDataCalled)

    if (result.masterDataCalled == 1 && id == result.prevId && result.pageInitalDataCalled > 0) {
      finalChildData();
    }
  }, [result.pageInitalDataCalled]);

  useEffect(() => {
    console.log("Location Called")
    if (!isValidVariable(parameter_id)) {
      parameter_id = 0;
    }
    if (result.masterDataCalled == 1 && result.pageInitalDataCalled > 0 && id == result.prevId && (parameter_id == result.parameter_id || parameter_id == 0)) {

      console.log(redirectPage())
      finalChildData();
      setResult((prevState) => ({
        ...prevState,
        pageFullURL: redirectPage()
      }));

    } else if (result.masterDataCalled == 1 && result.pageInitalDataCalled > 0 && id == result.prevId && parameter_id != result.parameter_id) {
      setPageInitailData();
    }
  }, [location]);

  useEffect(() => {

    if (result.masterDataCalled == 1 && id == result.prevId && result.finalPageDataLoaded == 1) {
      console.log("result.currentCounter : " + result.currentCounter)
      navigate(redirectPage());
    }
  }, [result.currentCounter]);

  useEffect(() => {
    if (result.finalPageDataLoaded == 1) {

      let currentSort = "";
      if (sortBy.length > 0) {
        currentSort = sortBy[0].id + (sortBy[0].desc ? "desc" : "asc");
      }
      setResult((prevState) => ({
        ...prevState,
        pageIndex: 0,
        currentCounter: result.currentCounter + 1,
        currentSort: currentSort
      }));
    }
  }, [sortBy]);

  // useEffect(() => {
  //   if (result.finalPageDataLoaded == 1) {

  //   }
  // }, [result.currentSort]);

  // search all function
  const funSearchReport = (e) => {
    e.preventDefault();
    setResult((prevState) => ({
      ...prevState,
      pageIndex: 0,
      currentCounter: result.currentCounter + 1,
    }));
  }

  const clearSearchTextboxes = () => {
    const textboxes = document.getElementsByClassName('search-report-txt');
    const textboxArray = Array.from(textboxes);
    textboxArray.forEach((textbox) => {
      textbox.value = "";
    });
  }

  const handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      setResult((prevState) => ({
        ...prevState,
        pageIndex: 0,
        currentCounter: result.currentCounter + 1,
      }));
    }
  };

  const clearsearchReport = (e) => {
    e.preventDefault();
    setResult((prevState) => ({
      ...prevState,
      pageIndex: 0,
      currentSearchExact: 0,
      currentSearch: {},
      currentCounter: result.currentCounter + 1,
    }));
  }


  const redirectPage = (seachType = "search") => {

    let newParams = new URLSearchParams();
    if (result.reportInfo.report_type_id == "monthly") {
      newParams.set("month", result.currentMonth);
    }
    if (result.reportInfo.report_type_id == "monthly" || result.reportInfo.report_type_id == "yearly") {
      newParams.set("year", result.currentYear);
    }
    if (result.currentSort !== "") {
      newParams.set("sort", result.currentSort);
    }
    if (isValidVariable(result.pageIndex)) {
      newParams.set("page", result.pageIndex + 1);
    } else {
      newParams.set("page", 1);
    }

    if (seachType == "search") {
      const textboxes = document.getElementsByClassName('search-report-txt');
      const textboxArray = Array.from(textboxes);
      textboxArray.forEach((textbox, index) => {
        const key = `${textbox.name}`;
        const value = textbox.value.trim();
        if (value) {
          newParams.set(key, value);
        }
      });
    }

    newParams.set("search_exact", result.currentSearchExact);

    return result.pageURL + "?" + newParams.toString();
  }


  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState();

  const handleCheckAll = () => {
    const updatedCheckboxes = {};
    for (const id in checkboxes) {
      updatedCheckboxes[id] = !isCheckedAll;
    }
    setCheckboxes(updatedCheckboxes);
    setIsCheckedAll(!isCheckedAll);
  };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = { ...checkboxes, [id]: !checkboxes[id] };
    setCheckboxes(updatedCheckboxes);
    setIsCheckedAll(Object.values(updatedCheckboxes).every(checkbox => checkbox));
  };

  const handleCheckAllButtonClick = () => {
    const updatedCheckboxes = {};
    const newIsCheckedAll = !isCheckedAll;
    for (const id in checkboxes) {
      updatedCheckboxes[id] = newIsCheckedAll;
    }
    setCheckboxes(updatedCheckboxes);
    setIsCheckedAll(newIsCheckedAll);
  };

  const handleClearAllButtonClick = () => {
    const updatedCheckboxes = {};
    for (const id in checkboxes) {
      updatedCheckboxes[id] = false;
    }
    setCheckboxes(updatedCheckboxes);
    setIsCheckedAll(false);
  };

  const getCaseInsensitivePropertyValue = (obj, keyToFind) => {
    const key = Object.keys(obj).find(currentKey => currentKey.toLowerCase() == keyToFind.toLowerCase());
    return key ? obj[key] : undefined; // Return undefined if key not found
  }
  const funChangeDisplayStatus = () => {
    const checkedValues = Object.keys(checkboxes).filter(id => checkboxes[id]);
    var rowids = checkedValues.join(',');

    console.log("rowids : " + rowids);

    if (rowids != "") {
      dispatch(startLoading());

      api.post('/admin-report-display-status', {
        // Your request payload
        values: { id: id, rowids: rowids },
      }).then(response => {
        dispatch(finishLoading());
        if (response.data.msg == 1) {
          finalChildData();
        } else {
          dispatch(setMessage(response.data.error));
        }
      })
        .catch(error => {
          console.error('Error:', error);

        }).finally(() => {
          dispatch(finishLoading());
        });
    }
  }
  const funUnlock = (iID, iReportId, type) => {

    const check = window.confirm('Are you sure you want to proceed?');
    if (check) {
      if (iID != "" && iReportId != "") {
        dispatch(startLoading());
        api.post('/admin-report-lock-status', {
          // Your request payload
          values: { id: iID, report_id: iReportId },
        }).then(response => {
          dispatch(finishLoading());
          if (response.data.msg == 1) {
            finalChildData();
            if (type == "page") {
              setResult((prevState) => ({
                ...prevState,
                reportInfo: {
                  ...prevState.reportInfo,
                  lock_status: 0
                }
              }));
            }
          } else {
            dispatch(setMessage(response.data.error));
          }
        })
          .catch(error => {
            console.error('Error:', error);

          }).finally(() => {
            dispatch(finishLoading());
          });
      }
    }
  }


  const handlePrevMonth = () => {
    var previousMonth = result.currentMonth == 1 ? 12 : result.currentMonth - 1;
    var previousYear = result.currentMonth == 1 ? result.currentYear - 1 : result.currentYear;
    setResult((prevState) => ({
      ...prevState,
      currentMonth: previousMonth,
      currentYear: previousYear,
      pageIndex: 0,
      headerDateNavigationCalled: result.headerDateNavigationCalled + 1,
      currentCounter: result.currentCounter + 1
    }));
  };

  // Function to handle navigation to the next month
  const handleNextMonth = () => {
    const nextMonth = result.currentMonth == 12 ? 1 : result.currentMonth + 1;
    const nextYear = result.currentMonth == 12 ? result.currentYear + 1 : result.currentYear;
    setResult((prevState) => ({
      ...prevState,
      currentMonth: nextMonth,
      currentYear: nextYear,
      pageIndex: 0,
      headerDateNavigationCalled: result.headerDateNavigationCalled + 1,
      currentCounter: result.currentCounter + 1
    }));

  };

  const handleNextYear = () => {
    setResult((prevState) => ({
      ...prevState,
      currentYear: parseInt(result.currentYear) + 1,
      pageIndex: 0,
      headerDateNavigationCalled: result.headerDateNavigationCalled + 1,
      currentCounter: result.currentCounter + 1
    }));
  };
  const handlePrevYear = () => {
    setResult((prevState) => ({
      ...prevState,
      currentYear: parseInt(result.currentYear) - 1,
      pageIndex: 0,
      headerDateNavigationCalled: result.headerDateNavigationCalled + 1,
      currentCounter: result.currentCounter + 1
    }));
  };

  // useEffect(() => {
  //   if (result.headerDateNavigationCalled > 0) {
  //     console.log("calling APi " + result.headerDateNavigationCalled)
  //     setPageInitailData();
  //   }
  // }, [result.headerDateNavigationCalled]);



  const getMonthName = (monthIndex) => {
    // Create a date object with the given month index (1-based)
    const date = new Date(Date.UTC(2000, monthIndex - 1, 1));

    // Get the month name using toLocaleString() method
    const monthName = date.toLocaleString('default', { month: 'long' });

    return monthName;
  };

  function getPrevMonthYearName() {
    // Create a date object for the first day of the given month and year
    let date = new Date(result.currentYear, result.currentMonth - 1, 1);
    // Subtract one day to get the last day of the previous month
    date.setDate(date.getDate() - 1);
    // Return the year of the resulting date
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',  // Full month name
      year: 'numeric' // Year in numeric format
    });

    return formatter.format(date);
  }

  function getNextMonthYearName() {
    // Create a date object for the first day of the current month
    let date = new Date(result.currentYear, result.currentMonth - 1, 1);

    // Move to the first day of the next month
    date.setMonth(date.getMonth() + 1);

    // Format the resulting date to "MMMM, YYYY"
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',  // Full month name
      year: 'numeric' // Year in numeric format
    });

    return formatter.format(date);
  }
  function getCurrentMonthYearName() {
    // Create a date object for the first day of the current month
    let date = new Date(result.currentYear, result.currentMonth - 1, 1);

    // Format the resulting date to "MMMM, YYYY"
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',  // Full month name
      year: 'numeric' // Year in numeric format
    });

    return formatter.format(date);
  }



  const handleAdditionalLinkClick = (event) => {
    // Handle link clicks manually
    const target = event.target;

    // Check if the clicked element is an anchor tag
    if (target.tagName == 'A') {
      event.preventDefault();

      // Get the href attribute from the anchor tag
      const href = target.getAttribute('href');

      // Check if the href is an external link (starts with http or https)
      const isExternalLink = href.startsWith('http://') || href.startsWith('https://');

      if (isExternalLink) {
        // For external links, open them in the same or new tab
        window.open(href, '_blank');  // Change '_blank' to '_self' if you want to open in the same tab
      } else {
        // For internal links, use navigate
        navigate(href);
      }
    }
  };

  const handleSearchInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name == "search_exact") {
      setResult(prevResult => ({
        ...prevResult,
        currentSearchExact: checked ? 1 : 0
      }));
    } else {
      setResult(prevResult => ({
        ...prevResult,
        currentSearch: {
          ...prevResult.currentSearch,
          [name]: value
        }
      }));
    }
  };

  // Export to CSV Function

  const exportTableToCSV = (e) => {
    e.preventDefault();
    const csv = [];
    const rows = tableRef.current.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) {
        let tdData = cols[j].innerText;
        tdData = tdData.replace("₹", "Rs. ");
        tdData = tdData.replace(/↓/g, "");
        row.push(tdData);
      }
      csv.push(row.join(","));
    }
    downloadCSV(csv.join("\n"), result.reportTitle);
  };

  const downloadCSV = (csv, filename) => {
    const csvFile = new Blob(["\ufeff", csv], {
      type: "text/csv; charset=ISO-8859-1",
    });
    const downloadLink = document.createElement("a");

    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <>
      <Helmet>
        <title>{result.reportTitle ?? ''}</title>
      </Helmet>
      {currentUser.role_id == 3 &&
        <div className="float-end">
          {result.reportInfo.lock_status == 1 &&
            <div id="dv_unlock_reports_master_{id}" className="d-inline me-3">
              <a href="#" title="Unlock" onClick={(e) => {
                e.preventDefault();
                funUnlock(id, 57, "page");
              }}
                className="text-decoration-none">
                <img src="/images/unlock-icon-16x16.png" alt="Unlock" />
              </a>
            </div>
          }
          <div className="d-inline">
            <Link to={`${routeConfig.report_create(id)}`}>Edit Report</Link>
          </div>
        </div>
      }
      <h1>{result.reportTitle ?? ''}</h1>
      <p><b>Report Type:</b> {result.reportInfo.reportType ?? ""} | <b>Total Records:</b> {result.totalRecords}</p>
      {
        result.reportInfo.search_column_names ? (
          <form className='form-inline my-3' name='frmSearch'>
            <div className='row'>
              {result.reportInfo.search_column_names.split(',').map((item, index) => (
                <div className='col-6 col-md-1 mt-1' key={index}><input className='form-control me-3 search-report-txt' type='text' name={`search_${item.trim()}`} placeholder={item.trim()} maxLength='100' onKeyDown={handleKeyPress} value={result.currentSearch[`search_${item.trim()}`] || ''} onChange={handleSearchInputChange} /></div>
              ))}
              <div className="col-6 col-md-2 mt-2 mb-1">
                <input className='form-check-input me-2' type='checkbox' id='search_exact' name='search_exact' onChange={handleSearchInputChange} checked={result.currentSearchExact == 1 ? true : false} />Exact
                <a className='btn btn-xs btn-success mb-1 mx-2' href='#' onClick={(e) => funSearchReport(e)} >Search</a>
                <a className='btn btn-xs btn-danger mb-1' href='#' onClick={(e) => clearsearchReport(e)}>Clear</a>


                <a
                  href='#'
                  className="btn btn-xs btn-secondary mx-2 mb-1"
                  id="export_btn"
                  onClick={(e) => exportTableToCSV(e)}
                >
                  Export to CSV
                </a>{" "}

              </div>
            </div>
          </form>
        ) : ""
      }
      {(() => {
        switch (result.reportInfo.report_type_id) {
          case 'daily':
            return (
              <>
              </>
            );

          case 'monthly':
            return (
              <>
                <div className='table table-responsive'>
                  <table className='table table-sm'><thead><tr>
                    <th style={{ width: "40%" }}>
                      <b>
                        <a className="text-decoration-none" href='#' onClick={(e) => {
                          e.preventDefault();
                          handlePrevMonth();
                        }} title='Previous Month'> &lt;&lt; {getPrevMonthYearName()} </a>
                      </b>
                    </th>
                    <th className='text-center' style={{ fontSize: "1.4em", color: "#329138", width: "20%" }}><b>{getCurrentMonthYearName()}</b></th>
                    <th className='text-end' style={{ width: "40%" }}><b>
                      <a className="text-decoration-none" onClick={(e) => {
                        e.preventDefault();
                        handleNextMonth();
                      }} title='Next Month' href="#"> {getNextMonthYearName()} &gt;&gt;</a>
                    </b></th></tr></thead>
                  </table>
                </div>
              </>
            );

          case 'yearly':
            return (
              <>
                <div className='table table-responsive'>
                  <table className='table table-sm'><thead><tr><th style={{ width: "40%" }}><b>
                    <a href='#' onClick={(e) => {
                      e.preventDefault();
                      handlePrevYear();
                    }} title='Previous Year' className='text-decoration-none'>&lt;&lt; Year {parseInt(result.currentYear) - 1}</a>

                  </b></th><th className='text-center' style={{ fontSize: "1.4em", color: "#329138", width: "20%" }}><b>{result.currentYear}</b></th><th className='text-end' style={{ width: "40%" }}><b>
                    <a href='#' onClick={(e) => {
                      e.preventDefault();
                      handleNextYear();
                    }} title='Next Year' className='text-decoration-none'>Year {parseInt(result.currentYear) + 1} &gt;&gt;</a>
                  </b></th></tr></thead>
                  </table>
                </div>
              </>
            );
          case 'fy':
            return (
              <>
                <h1>View 4</h1>
                <p>This is the content for View 4.</p>
              </>
            );

          default:
            return (
              <>
              </>
            );
        }
      })()}

      {result.reportInfo.additional_link !== "" &&
        <div className="mt-3 mb-1" dangerouslySetInnerHTML={{ __html: result.reportInfo.additional_link }} onClick={handleAdditionalLinkClick} />
      }

      <div className="my-3" dangerouslySetInnerHTML={{ __html: result.reportDescription }} />


      <div className={`table-responsive  ${loading ? 'blur' : ''}  mb-3 report_table_wrapper`}>
        <Table {...getTableProps()} striped bordered hover id='report_table' className='w-auto report_data_table' ref={tableRef}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (

                  column.id.startsWith('~') ? null :

                    <th className={`text-center`} {...column.getHeaderProps()} style={{ borderBottom: '1px solid black', padding: '8px' }}>
                      <div
                        {...column.getSortByToggleProps()}
                        style={{ cursor: 'pointer' }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                      </div>

                      {result.reportInfo.disable_display_status_feature == 0 &&
                        result.reportInfo.link_field_name.toLowerCase() == column.render('Header').toLowerCase() && result.reportInfo.update_status_id_field != "" &&
                        <>
                          <div className='mb-2'>
                            <a
                              onClick={handleCheckAllButtonClick}
                              href='#'>Check</a> |
                            <a
                              onClick={handleClearAllButtonClick}
                              href='#'>Clear</a>
                          </div>

                          <button className='btn btn-xs btn-success mx-2 fs-12' type='submit'
                            onClick={funChangeDisplayStatus} disabled={loading} style={{ whiteSpace: "nowrap" }}
                          >Change Status
                          </button>

                        </>
                      }
                    </th>

                ))}
                {/* {
                  (result.reportInfo.updateStatusTable !== "" && result.reportInfo.update_status_id_field !== "" && result.reportInfo.updateDisplayOrderFieldName !== "") ?
                    <th className='text-center'>Display Order</th>
                    : ""
                } */}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              var row_highlight = "";
              if (result.reportInfo.update_status_field_name !== "") {
                row_highlight = getCaseInsensitivePropertyValue(row.values, result.reportInfo.update_status_field_name.toLowerCase()) ?? '';
                if (row_highlight == "" || row_highlight == 0) row_highlight = "danger";
              }

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    cell.column.id.startsWith('~') ? null :
                      <td {...cell.getCellProps()} className={row_highlight}>

                        {(() => {
                          switch ((cell.column.id).toLowerCase()) {
                            case (result.reportInfo.link_field_name).toLowerCase():
                              var cell_value = getCaseInsensitivePropertyValue(row.values, result.reportInfo.link_field_name.toLowerCase());
                              var cell_update_field = getCaseInsensitivePropertyValue(row.values, result.reportInfo.update_status_id_field.toLowerCase());
                              return (
                                <>
                                  {result.reportInfo.link_file_name !== "" && <>
                                    {result.reportInfo.disable_display_status_feature == 0 && result.reportInfo.update_status_field_name != "" && result.reportInfo.update_status_id_field != "" &&
                                      <div className='float-end mt-1'><input type='checkbox' name={`${result.reportInfo.update_status_id_field.toLowerCase()}_check`} value={cell_update_field} checked={checkboxes[cell_update_field] || false}
                                        onChange={() => handleCheckboxChange(cell_update_field)} /></div>
                                    }
                                    <span className='d-flex'>
                                      <Link to={`/admintool9x/${result.reportInfo.link_file_name}/${cell_value}/`} >{cell.render('Cell')}</Link>
                                    </span>
                                  </>
                                  }
                                </>
                              );

                            case (result.reportInfo.text_area_field_name).toLowerCase():
                              return (
                                <textarea className='form-control' rows='8' style={{ width: "500px", fontSize: "12px" }} value={cell.value} readOnly />
                              );
                            case "lock_status":
                              if (row.values['lock_status'] == 1) {
                                return (
                                  <>
                                    <a href='#' onClick={() => funUnlock(getCaseInsensitivePropertyValue(row.values, result.reportInfo.update_status_id_field.toLowerCase()), id, "table")}><img src='/images/unlock-icon-16x16.png' alt='Unlock' /></a>
                                  </>
                                );
                              } else {
                                return "";
                              }

                            default:
                              return (
                                <>
                                  {typeof cell.value === 'string' && /^\d{4}-\d{2}-\d{2}T00:00:00.000Z$/.test(cell.value)
                                    ? (() => {
                                      const date = new Date(cell.value);
                                      const year = date.getFullYear();
                                      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                                      const day = String(date.getDate()).padStart(2, '0');
                                      return `${year}-${month}-${day}`;
                                    })()
                                    : cell.render('Cell')}
                                </>
                              );
                          }
                        })()}

                      </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {result.totalPages > 1 &&
        <Pagination className='justify-content-center'>

          <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0, currentCounter: result.currentCounter + 1, }))} disabled={result.pageIndex == 0} />
          <Pagination.Prev onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex - 1, currentCounter: result.currentCounter + 1, }))} disabled={result.pageIndex == 0} />
          <Pagination.Item
            key={result.pageIndex}
            active={true}
            onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex, currentCounter: result.currentCounter + 1, }))}
          >
            {result.pageIndex + 1}
          </Pagination.Item>

          <Pagination.Next onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1, currentCounter: result.currentCounter + 1, }))} disabled={result.pageIndex == result.totalPages - 1} />
          <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1, currentCounter: result.currentCounter + 1, }))} disabled={result.pageIndex == result.totalPages - 1} />
        </Pagination>
      }

      <div className="my-3" dangerouslySetInnerHTML={{ __html: result.reportDisclaimer }} />


    </>
  );
}

export default Report
