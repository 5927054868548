import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPOPostCommentForm from './IPOPostCommentForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow, isNumeric, isValidVariable } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const IPOPostComment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var main_ref = queryParams.get("main_ref") ?? "";
    var topicId = queryParams.get("a") ?? "";
    var parentMessageId = queryParams.get("pid") ?? "";
    // const recaptchaRef = useRef(null);

    const validationSchema = Yup.object({
        ipo_id: Yup.string().trim().required('Company name is required'),
        message: Yup.string().trim().required('Your comment is required'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState({
        ipoList: [],
        imageUploadAllow: 0,
        parentMessages: [],
        ipo_company_id: 0,
        track_replies: 0
    });
    const [metaData, setMetaData] = useState({
        title: "IPO Message Board - Leave your comment"
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        ipo_id: "",
        message: "",
        recaptcha: "",
        track_replies: 0,
        parent_message_id: 0,
        file: null,
        filebase64: null,
        image_file_name: "",
        recaptcha: '',
    });

    const btnClick = () => {
        if (main_ref != "") {
            funCloseWebWindow(main_ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {

            if (!isValidVariable(topicId)) {
                topicId = 0;
            }

            if (!isNumeric(topicId)) {
                topicId = 0;
            }

            if (!isValidVariable(parentMessageId)) {
                parentMessageId = 0;
            }

            if (!isNumeric(parentMessageId)) {
                parentMessageId = 0;
            }

            dispatch(startLoading());

            api.post('/member-ipocomment-read', {
                parent_message_id: parentMessageId
            }).then(response => {
                if (response.data.msg === 1) {
                    if (topicId == 0 && parentMessageId != 0) {
                        topicId = response.data.ipo_company_id;
                    }
                    parentMessageId = response.data.parentMessageID;
                    queryParams.set("pid", parentMessageId);
                    setResult(prevValues => ({
                        ...prevValues,
                        ipo_company_id: response.data.ipo_company_id,
                        ipoList: response.data.ipoList,
                        imageUploadAllow: response.data.imageUploadAllow,
                        parentMessages: response.data.parentMessages,
                        track_replies: response.data.track_replies
                    }));

                    setInitialValues(prevValues => ({
                        ...prevValues,
                        ...response.data.pageData,
                        ipo_id: topicId,
                        parent_message_id: parentMessageId,
                        request_type_id: 1,
                        track_replies: response.data.track_replies
                    }));

                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        if (values.ipo_id == "") {
            alert("Select Company");
            setSubmitting(false);
            return;
        }
        if (values.message == "") {
            alert("Enter your comment");
            setSubmitting(false);
            return;
        }
        if (values.message.length < 2) {
            alert("Your comment must be at least three characters long.");
            setSubmitting(false);
            return;
        }
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // const token = recaptchaRef.current.getValue();
        // if (!token) {
        //     alert('Please complete the reCAPTCHA');
        //     setSubmitting(false);
        //     return;
        // }
        // recaptchaRef.current.reset();
        dispatch(startLoading());

        const trimmedContent = trimmedValues.message.replace(/\n\s*\n\s*\n/g, '\n\n').trim();

        if (trimmedContent === "") {
            alert('Enter your comment.');
            setSubmitting(false);
            return;
        }

        trimmedValues.message = html_encode(trimmedValues.message);
        // trimmedValues.recaptchaToken = token;

        api.post('/member-ipocomment-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=ipo&main_ref=` + main_ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    const html_encode = (s) => {
        let ret_val = '';
        for (let char of s) {
            const code = char.codePointAt(0);
            if (code > 127) {
                ret_val += '&#' + code + ';';
            } else {
                ret_val += char;
            }
        }
        return ret_val;
    };

    return (
        <>

            <Helmet>
                <title>Leave Your Comment</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <legend>
                {metaData.title}
            </legend>
            <hr />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IPOPostCommentForm result={result} btnClick={btnClick} loading={loading} initialValues={initialValues} />
            </Formik>


        </>
    )
}

export default IPOPostComment
