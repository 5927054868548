import { Field, useFormikContext } from 'formik';
import React from 'react'

const NumberInput = ({ classNameAtr, tabIndexAtr, placeholderAtr, maxLength, nameAtr, idAtr, dynamicOnChange = () => { }, disabledAtr, readOnlyAtr, dynamicOnBlur = () => { }, dynamicOnInput = () => { } }) => {

    const formik = useFormikContext();

    const handleChange = (event) => {
        const newValue = event.target.value;
        formik.setFieldValue(nameAtr, newValue);

        // Call the dynamically provided onChange function
        dynamicOnChange(newValue);
    };

    return (
        <Field type="text"
            className={classNameAtr} tabIndex={tabIndexAtr !== undefined ? tabIndexAtr : null} maxLength={maxLength} name={nameAtr}
            onChange={handleChange} disabled={disabledAtr} id={idAtr} readOnly={readOnlyAtr}
            //onBlur={(event) => handleBlur(event)}
            onBlur={dynamicOnBlur}
            placeholder={placeholderAtr}
            onKeyUp={(e) => {
                // Allow only numeric input with up to two decimal places
                const value = e.target.value;
                let newValue = value.replace(/,/g, '');
                newValue = newValue.replace(/[^0-9]/g, '');

                formik.setFieldValue(nameAtr, newValue);
            }}
            onInput={dynamicOnInput}
        />
    )
}

export default NumberInput
