import React, { useEffect, useCallback, useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CheckboxGroup from '../Common/CheckboxGroup';
import { funCreateSlug, isValidVariable, compareDates } from '../Common/CommonFunction';
import NumberInput from '../Common/NumberInput';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import AutocompleteField from '../Common/AutocompleteField';
import useApi from '../../../common/useApi';
import MultiSelectTransfer from '../Common/MultiSelectTransfer';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';
import DynamicCharacterCount from '../Common/CharacterCountInput';
import FileUpload from '../Common/FileUpload';
import { debounce } from 'lodash';

const RightIssueForm = ({ result, handleGoBack, loading, initialValues }) => {

    const [checkOpenAndCloseDate, setCheckOpenAndCloseDate] = useState("");
    const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);
    const [autoComplteOptions, setAutoComplteOptions] = useState([]);

    const api = useApi();
    const { values, setFieldValue, setFieldTouched } = useFormikContext();

    const [fieldDisabled, setFieldDisabled] = useState({
        isISINDisabled: false,
        isNSESymbolDisabled: false,
        isBSEScripcodeDisabled: false
    });

    const createEntitlement = () => {
        let x = document.getElementById("entitlement_rights_equity_sharetxt").value;
        let y = document.getElementById("entitlement_fully_paid_equity_sharetxt").value;
        if ((x != "" && x != null) && (y != "" && y != null)) {
            setFieldValue('entitlement', x + ' Rights Equity Share(s) for every ' + y + ' fully paid-up Equity Shares held on Record Date');
        } else {
            setFieldValue('entitlement', "");
        }
    }

    const handleAutoSearch = async (query) => {
        debouncedHandleAutoSearch(query);
        console.log("The options are: ", autoComplteOptions)
    };
    const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
        console.log(query);
        setIsAutocomplteLoading(true);
        try {
            const response = await api.post('/admin-listofscript-list', { search: query });
            if (response.data.msg === 1) {

                const options = response.data.details.map(item => ({
                    label: `${item.company_name} (${item.bse_script_id ?? ""}) (${item.bse_isin ?? ""}) (${item.bse_script_code ?? ""}) (${item.nse_script_id ?? ""}) (${item.nse_isin ?? ""})`,
                    value: item
                }));
                setAutoComplteOptions(options);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsAutocomplteLoading(false);
        }
    }, 300), []); // 300 ms debounce delay

    const handleAutoBlur = async (selected) => {
        if (selected) {
            let item = selected.value;
            setFieldValue("company_name", item.company_name ?? "")
            setFieldValue("company_name_auto", item.company_name ?? "")
            let nse_symbol = item.nse_script_id ?? "";
            let bse_scripcode = item.bse_script_code ?? ''
            let isin = item.bse_isin ?? item.nse_isin;
            setFieldValue('isin', isin ?? '');
            setFieldValue('nse_symbol', nse_symbol);
            setFieldValue('bse_scripcode', bse_scripcode);

            setFieldValue('listing_at_array', []);
            if (isValidVariable(nse_symbol) && isValidVariable(bse_scripcode))
                setFieldValue("listing_at_array", ["BSE", "NSE"]);
            else if (isValidVariable(nse_symbol)) setFieldValue("listing_at_array", ["NSE"]);
            else if (isValidVariable(bse_scripcode)) setFieldValue("listing_at_array", ["BSE"]);
        }

    };

    useEffect(() => {
        setFieldDisabled(() => ({
            isISINDisabled: values.isin !== "" && values.isin !== undefined,
            isNSESymbolDisabled: values.nse_symbol !== "" && values.nse_symbol !== undefined,
            isBSEScripcodeDisabled: values.bse_scripcode !== "" && values.bse_scripcode !== undefined
        }));
    }, [values.company_name_auto, values.isin, values.nse_symbol, values.bse_scripcode]);

    const handleLastDateToBuyShare = (e) => {
        if (e.target.value != "") {
            setFieldValue('record_dt', e.target.value);
            const userConfirmed = window.confirm('Would you like to update Last Day to buy shares date?');
            if (userConfirmed) {
                api.post('/admin-buyback-last-day-to-buy-share', {
                    date: e.target.value
                }).then(response => {
                    if (response.data.msg === 1) {
                        console.log(response.data.last_day_buy_share_dt)
                        setFieldValue('last_day_to_buy_shares_dt', response.data.last_day_buy_share_dt ?? '');
                    } else {
                        alert(response.data.error);
                    }
                })
                    .catch(error => {
                        console.error('Error:', error);
                    }).finally(() => {
                        setIsAutocomplteLoading(false);
                    });
            }
        }

    };

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

    useEffect(() => {
        if (values && values.issue_open_date && values.issue_close_date && (values.issue_open_date !== initialValues.issue_open_date || values.issue_close_date !== initialValues.issue_close_date)) {
            setCheckOpenAndCloseDate("Date modified. Please check display order.");
        }
        else {
            setCheckOpenAndCloseDate("");
        }
    }, [values.issue_open_date, values.issue_close_date]);

    useEffect(() => {
        if (values && values.issue_open_date && values.issue_close_date && (values.issue_open_date !== initialValues.issue_open_date || values.issue_close_date !== initialValues.issue_close_date)) {
            setCheckOpenAndCloseDate("Date modified. Please check display order.");
        }
        else {
            setCheckOpenAndCloseDate("");
        }
    }, [values.issue_open_date, values.issue_close_date]);

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.rightissue !== undefined && result.rightissue.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.rightissue[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="company_name_auto" className="col-lg-2">Company Name Search</label>
                    <div className="col-lg-8">
                        <Field
                            name="company_name_auto"
                            as={AutocompleteField}
                            options={autoComplteOptions}
                            onInputChange={(query) => handleAutoSearch(trimValue(query))}
                            onInputSelect={handleAutoBlur}
                            selected={values.company_name_auto ? [values.company_name_auto] : []}
                            tabIndex={1}
                            maxLength="10"
                            isAutocomplteLoading={isAutocomplteLoading}
                            idatr="company_name_txt"
                            nameatr="company_name_auto"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
                    <div className="col-lg-4">
                        <Field as="input" name="company_name" className="form-control" tabIndex={5} maxLength="100" />
                    </div>
                    <label htmlFor="news_title" className="col-lg-2 required">News Title (Max 50 chars)</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCount as="input" nameattr={"news_title"} className="form-control" tabIndex={10} maxLength="50" onBlur={(e) => {
                            const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                            if (userConfirmed) {
                                var news_title = e.target.value;
                                var slug = funCreateSlug(news_title)

                                setFieldValue('urlrewrite_folder_name', slug);
                            } else {
                                setFieldTouched('news_title', true);
                            }
                        }} />
                    </div>
                </div >

                <div className="mb-3 row">
                    <label htmlFor="listing_at_array" className="col-lg-2">Issue Listing At</label>
                    <div className="col-lg-4">
                        {result.issueStockExchangeList !== undefined &&
                            <Field
                                name="listing_at_array"
                                label="Issue Listing At"
                                component={CheckboxGroup}
                                options={result.issueStockExchangeList}
                                className='form-check-input me-2'
                                tabIndex={15}
                            />
                        }
                    </div>

                    <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
                    <div className="col-lg-4">
                        <Field as="input" name="urlrewrite_folder_name" className="form-control" maxLength="50" tabIndex={20} />
                    </div>
                </div>
                <hr />
                <div className="mb-3 row">
                    <h2>Stock Details</h2>

                    <label htmlFor="isin" className="col-lg-2 ">ISIN</label>
                    <div className="col-lg-4">
                        <Field as="input" name="isin" className="form-control" tabIndex={25} readOnly={fieldDisabled.isISINDisabled}>
                        </Field>
                    </div>

                    <label htmlFor="bse_ipo_no" className="col-lg-2 ">BSE IPO No</label>
                    <div className="col-lg-4">
                        <Field as="input" name="bse_ipo_no" className="form-control" tabIndex={30}>
                        </Field>
                        <p className="mb-0"><a href="https://www.bseindia.com/publicissue.html" target="_blank">BSE</a> | Ex : In URL={">"} bseindia.com IPONo=<span className="text-primary">6282</span></p>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="nse_symbol" className="col-lg-2 reqired">NSE Symbol</label>
                    <div className="col-lg-4">
                        <Field as="input" name="nse_symbol" className="form-control" tabIndex={35} readOnly={fieldDisabled.isNSESymbolDisabled}>
                        </Field>
                    </div>

                    <label htmlFor="bse_scripcode" className="col-lg-2 ">BSE Script Code</label>
                    <div className="col-lg-4">
                        <NumberInput nameAtr={"bse_scripcode"} classNameAtr={"form-control"} tabIndexAtr={"40"} readOnlyAtr={fieldDisabled.isBSEScripcodeDisabled} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="re_nse_symbol" className="col-lg-2 ">RE NSE Symbol</label>
                    <div className="col-lg-4">
                        <Field as="input" name="re_nse_symbol" className="form-control" tabIndex={45}>
                        </Field>
                    </div>

                    <label htmlFor="re_bse_scripcode" className="col-lg-2 ">RE BSE ScripCode</label>
                    <div className="col-lg-4">
                        <NumberInput nameAtr={"re_bse_scripcode"} classNameAtr={"form-control"} tabIndexAtr={"50"} />
                    </div>
                </div>

                <hr></hr>

                <div className="mb-3 row">
                    <label htmlFor="face_value" className="col-lg-2 ">Face Value</label>
                    <div className="col-lg-1">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={55} maxLength="12" nameAtr="face_value"
                            dynamicOnBlur={(e) => {
                                const targetVal = e.target.value
                                setFieldValue(e.target.name, targetVal)
                                if (e.target.value !== "" && values.issue_price !== "" && targetVal > parseInt(values.issue_price)) {
                                    setFieldValue('issue_price', "")
                                    alert("Issue price should be greater than or equal to face value")
                                }
                            }} />
                    </div>
                </div>

                <div className='mb-3 row'>
                    <label htmlFor="issue_price" className="col-lg-2 ">Issue Price</label>
                    <div className="col-lg-1">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={60} maxLength="12" nameAtr="issue_price"
                            dynamicOnBlur={(e) => {
                                const targetVal = e.target.value
                                setFieldValue(e.target.name, targetVal)
                                if (e.target.value !== "" && targetVal < parseInt(values.face_value)) {
                                    setFieldValue('issue_price', "")
                                    alert("Issue price should be greater than or equal to face value")
                                }
                            }}

                            dynamicOnInput={(e) => {
                                const val = e.target.value;
                                const issueSize = values.issue_size_in_shares;
                                if (val !== "" && issueSize !== "") {
                                    const sizeInAmt = parseFloat(val) * parseFloat(issueSize);
                                    setFieldValue("issue_size_in_amt", sizeInAmt)
                                }
                                else {
                                    setFieldValue("issue_size_in_amt", "")
                                }
                            }} />
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="issue_size_in_shares" className="col-lg-2 ">Issue Size (Shares)</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={65} maxLength="20" nameAtr="issue_size_in_shares"
                            dynamicOnInput={(e) => {
                                const val = e.target.value;
                                const issuePrice = values.issue_price;
                                if (val !== "" && issuePrice !== "") {
                                    const sizeInAmt = parseFloat(val) * parseFloat(issuePrice);
                                    setFieldValue("issue_size_in_amt", sizeInAmt)
                                }
                                else {
                                    setFieldValue("issue_size_in_amt", "")
                                }
                            }} />
                    </div>
                    <div className="col-lg-2"> Rs. {values.issue_size_in_shares == "" || values.issue_size_in_shares == null ? 0.00 : (parseFloat(values.issue_size_in_shares) / 10000000).toFixed(2)} Crores</div>

                </div>

                <div className='mb-3 row'>

                    <label htmlFor="issue_size_in_amt" className="col-lg-2 ">Issue Size (Amount)</label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={70} maxLength="20" nameAtr="issue_size_in_amt" />
                    </div>
                    <div className="col-lg-2">Rs. {values.issue_size_in_amt == "" || values.issue_size_in_amt == null ? 0.00 : (parseFloat(values.issue_size_in_amt) / 10000000).toFixed(2)} Crores</div>

                    <label htmlFor="listing_dt" className="col-lg-2 ">Listing Date</label>
                    <div className="col-lg-2">
                        <Field as="input" type="date" name="listing_dt" className="form-control" tabIndex={75}
                            onBlur={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                                if (new Date(e.target.value).getDay() === 0) {
                                    setFieldValue("listing_dt", "");
                                    alert("Selected date is a Sunday, which is a holiday.");
                                }
                                if (values.issue_close_date !== "" && values.issue_close_date !== null && e.target.value != "" && e.target.value <= values.issue_close_date) {
                                    alert("Listing date should be after issue closing date")
                                    setFieldValue("issue_close_date", "");
                                }
                            }} />
                    </div>

                </div>

                <hr></hr>

                <div className="mb-3 row">

                    <label htmlFor="entitlement_rights_equity_share" className="col-lg-2 ">Rights Issue Ratio</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={80} maxLength="12" nameAtr="entitlement_rights_equity_share"
                            dynamicOnChange={createEntitlement} idAtr="entitlement_rights_equity_sharetxt" />
                    </div>

                    <label htmlFor="entitlement_fully_paid_equity_share" className="col-lg-2 ">Rights Equity Share(s) for every</label>

                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={85} maxLength="12" nameAtr="entitlement_fully_paid_equity_share"
                            dynamicOnChange={createEntitlement} idAtr="entitlement_fully_paid_equity_sharetxt"
                        />
                    </div>
                    <div className='col-lg-4'>Fully paid-up Equity Shares held on Record Date.</div>
                </div>


                <div className="mb-3 row">

                    <label htmlFor="entitlement" className="col-lg-2 ">Entitlement</label>
                    <div className="col-lg-10">
                        <Field as="input" name="entitlement" className="form-control" tabIndex={90} readOnly>
                        </Field>
                    </div>
                </div>

                <hr></hr>
                <h2>Document Links</h2>

                <div className='mb-3 row'>
                    <label htmlFor="application_form_url" className="col-lg-2 ">Application Form URL</label>
                    <div className="col-lg-4">
                        <Field as="input" name="application_form_url" className="form-control" tabIndex={95}>
                        </Field>
                    </div>

                    <label htmlFor="letter_of_offer_url" className="col-lg-2 ">Letter_of_Offer_URL</label>
                    <div className="col-lg-4">
                        <Field as="input" name="letter_of_offer_url" className="form-control" tabIndex={100}>
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="faqs_url" className="col-lg-2 ">FAQs URL</label>
                    <div className="col-lg-4">
                        <Field as="input" name="faqs_url" className="form-control" tabIndex={105}>
                        </Field>
                    </div>
                </div>

                <hr></hr>
                <h2>Issue Date (YYYY/MM/DD - Date Only Field)</h2>

                <div className='mb-3 row'>
                    <label htmlFor="record_dt" className="col-lg-2 ">Record Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="record_dt" className="form-control" tabIndex={110}
                            onBlur={(e) => {
                                if (compareDates(e.target.value, new Date().toLocaleString("en-CA", { timeZone: "Asia/Kolkata" }).split(",")[0]) == -1) {
                                    alert("Record date cannot be an earlier date")
                                    setFieldValue("record_dt", initialValues.record_dt)
                                    return;
                                }
                                handleLastDateToBuyShare(e);
                                if (values.issue_open_date !== "" && values.issue_open_date !== null && e.target.value != "" && e.target.value > values.issue_open_date) {
                                    alert("Record date should be before issue open date")
                                    setFieldValue("issue_open_date", "")
                                }
                            }} />
                    </div>

                    <label htmlFor="last_day_to_buy_shares_dt" className="col-lg-2 ">Last day to buy shares date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="last_day_to_buy_shares_dt" className="form-control" tabIndex={115}>
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_open_date" className="col-lg-2 ">Issue Open Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="issue_open_date" className="form-control" tabIndex={120}
                            onBlur={(e) => {
                                setFieldValue(e.target.name, e.target.value)
                                if (new Date(e.target.value).getDay() === 0) {
                                    setFieldValue("issue_open_date", "");
                                    alert("Selected date is a Sunday, which is a holiday.");
                                }
                                if (values.record_dt !== "" && values.record_dt !== null && e.target.value != "" && e.target.value < values.record_dt) {
                                    alert("Record date should be before issue open date")
                                    setFieldValue(e.target.name, "")
                                }
                            }}>
                        </Field>
                    </div>

                    <label htmlFor="issue_close_date" className="col-lg-2 ">Issue Close Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="issue_close_date" className="form-control" tabIndex={125}
                            onBlur={(e) => {
                                setFieldValue(e.target.name, e.target.value); if (new Date(e.target.value).getDay() === 0) {
                                    setFieldValue("issue_close_date", "");
                                    alert("Selected date is a Sunday, which is a holiday.");
                                }
                                if (values.listing_dt !== "" && values.listing_dt !== null && e.target.value != "" && e.target.value >= values.listing_dt) {
                                    setFieldValue("issue_close_date", "");
                                    alert("Listing date should be after issue closing date")
                                }
                            }}>
                        </Field>
                        <p className="text-danger">{checkOpenAndCloseDate}</p>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="rights_entitlements_credit_dt" className="col-lg-2 ">Last date for credit of Rights Entitlements</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="rights_entitlements_credit_dt" className="form-control" tabIndex={130}>
                        </Field>
                    </div>

                    <label htmlFor="timetable_renunciation_dt" className="col-lg-2 ">Renunciation Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="timetable_renunciation_dt" className="form-control" tabIndex={135}>
                        </Field>
                    </div>
                </div>
                <hr></hr>
                <h2>Tentative Timetable (YYYY/MM/DD - Date Only Field)</h2>

                <div className='mb-3 row'>
                    <label htmlFor="timetable_allotment_dt" className="col-lg-2 ">Allotment Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="timetable_allotment_dt" className="form-control" tabIndex={140}>
                        </Field>
                    </div>

                    <label htmlFor="timetable_credit_dt" className="col-lg-2 ">Credit Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="timetable_credit_dt" className="form-control" tabIndex={145}>
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="timetable_listing_dt" className="col-lg-2 ">Listing Date</label>
                    <div className="col-lg-4">
                        <Field as="input" type="date" name="timetable_listing_dt" className="form-control" tabIndex={150}>
                        </Field>
                    </div>
                </div>

                <hr></hr>
                <div className='mb-3 row'>
                    <label htmlFor="other_issue_detail" className="col-lg-2 ">Other Issue Details (HTML)</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"other_issue_detail"} className="form-control" rows="5" tabIndex={155} />
                    </div>

                    <label htmlFor="notices" className="col-lg-2 ">Notices (HTML)</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"notices"} className="form-control" rows="5" tabIndex={160} />
                    </div>
                </div>

                <div className='mb-3 row'>
                    <label htmlFor="terms_of_payment" className="col-lg-2 ">Terms of Payments</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"terms_of_payment"} className="form-control" rows="5" tabIndex={165} />
                    </div>
                </div>

                <hr></hr>

                <h2>Registrar & Lead Managers</h2>

                <div className="mb-3 row">

                    <label htmlFor="registrar_1" className="col-lg-2 ">Registrar 1</label>
                    <div className="col-lg-4">
                        <Field as="select" name="registrar_1" className="form-select" tabIndex={170}>
                            <option></option>
                            {result.registrarList !== undefined && result.registrarList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.comp_name}
                                </option>
                            ))}
                        </Field>
                    </div>

                    <label htmlFor="registrar_1_email" className="col-lg-2 ">Registrar Email 1</label>
                    <div className="col-lg-4">
                        <Field as="input" name="registrar_1_email" className="form-control" tabIndex={175}>

                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">

                    <label htmlFor="registrar_2" className="col-lg-2 ">Registrar 2</label>
                    <div className="col-lg-4">
                        <Field as="select" name="registrar_2" className="form-select" tabIndex={180}>
                            <option></option>
                            {result.registrarList !== undefined && result.registrarList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.comp_name}
                                </option>
                            ))}
                        </Field>
                    </div>

                    <label htmlFor="registrar_2_email" className="col-lg-2 ">Registrar Email 2</label>
                    <div className="col-lg-4">
                        <Field as="input" name="registrar_2_email" className="form-control" tabIndex={185}>
                        </Field>
                    </div>
                </div>
                <MultiSelectTransfer dataList={result.leadManagerList} labelAttr={"Lead Managers"} initialItemsNameAttr={"initialItems"} selectedItemsNameAttr={"selectedItems"} selectedItemsIdsAttr={"selectedItemsIds"} tabIndex={190} />

                <hr></hr>
                <h2>Company Info</h2>
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="logo_url" className="col-lg-2  ">Logo Image Name</label>
                    <div className="col-lg-3">
                        <Field as="input" name="logo_url" className="form-control" maxLength="100" tabIndex={195} />
                    </div>

                    <label htmlFor="logo_length" className="col-lg-1 ">Width</label>
                    <div className="col-lg-1">
                        <NumberInput nameAtr={"logo_length"} classNameAtr={"form-control"} tabIndexAtr={200} />
                    </div>

                    <label htmlFor="logo_height" className="col-lg-1 ">Height</label>
                    <div className="col-lg-1">
                        <NumberInput nameAtr={"logo_height"} classNameAtr={"form-control"} tabIndexAtr={205} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="address_1" className="col-lg-2 ">Address 1</label>
                    <div className="col-lg-4">
                        <Field as="input" name="address_1" className="form-control" tabIndex={210} maxLength="50" />
                    </div>

                    <label htmlFor="address_2" className="col-lg-2 ">Address 2</label>
                    <div className="col-lg-4">
                        <Field maxLength="50" as="input" name="address_2" className="form-control" tabIndex={215} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="address_3" className="col-lg-2 ">Address 3</label>
                    <div className="col-lg-4">
                        <Field as="input" name="address_3" className="form-control" maxLength="50" tabIndex={220} />
                    </div>

                    <label htmlFor="state" className="col-lg-2 ">State</label>
                    <div className="col-lg-4">
                        <Field as="select" name="state" className="form-select" tabIndex={225} >
                            <option></option>
                            {result.stateList !== undefined && result.stateList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="phone" className="col-lg-2 ">Phone</label>
                    <div className="col-lg-4">
                        <NumberInput nameAtr="phone" classNameAtr="form-control" maxLength="15" tabIndex={230} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="website" className="col-lg-2 ">Website (include http:// or https:// )</label>
                    <div className="col-lg-4">
                        <Field as="input" name="website" className="form-control" maxLength="50" tabIndex={235} />
                    </div>

                    <label htmlFor="email" className="col-lg-2 ">Email</label>
                    <div className="col-lg-4">
                        <Field as="input" name="email" className="form-control" maxLength="50" tabIndex={240} />
                    </div>
                </div>


                <div className='mb-3 row'>
                    <label htmlFor="company_desc" className="col-lg-2 ">Company Description (HTML)</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"company_desc"} className="form-control" rows="5" tabIndex={245} />
                    </div>

                    <label htmlFor="issue_objects" className="col-lg-2 ">Objects of the Issue</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"issue_objects"} className="form-control" rows="5" tabIndex={250} />
                    </div>
                </div>

                <div className='mb-3 row'>
                    <label htmlFor="financial" className="col-lg-2 ">Company Financial</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput nameattr={"financial"} className="form-control" rows="5" tabIndex={255} />
                    </div>
                </div>

                <div className='mb-3 row'>
                    <label htmlFor="display_order" className="col-lg-2 ">Display Order</label>
                    <div className="col-lg-4">
                        <NumberInput nameAtr={"display_order"} classNameAtr={"form-control"} tabIndexAtr={260} />
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox" name="display_status" tabIndex={265}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
                    </div>

                    <label htmlFor="issue_withdraw_status" className="col-lg-2">Issue Withdraw</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox" name="issue_withdraw_status" tabIndex={270}
                            onChange={(e) => { setFieldValue('issue_withdraw_status', e.target.checked) }} /> (Check if issue is withdraw from the market)
                    </div>

                    <label htmlFor="lock_status" className="col-lg-2">Lock status</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox" name="lock_status" tabIndex={275}
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
                    </div>
                </div>
                <div className="mb-3 text-center">
                    {initialValues.lock_status != 1 &&
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={280}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.rightissue !== undefined && result.rightissue.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    }

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={285}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>



        </>
    )
}

export default RightIssueForm
