import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { closePopUpWindow, formatNewLineMessage, funCloseWebWindow, validateId } from '../../admintool9x/Common/CommonFunction';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';

const IPOEditComment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const handleDisable = (id, source) => {

        if (validateId(id)) {
            console.log("This is the id : ", id)
            const confirmDelete = window.confirm("Are you sure you want to delete this message?");

            if (confirmDelete) {
                dispatch(startLoading());

                api.post('/ipoeditcomment-save', {
                    values: {
                        id: id,
                        source: source
                    }
                })
                    .then(response => {
                        if (response.data.success) {
                            setResult(prevState => ({
                                ...prevState,
                                tableData: prevState.tableData.map(row =>
                                    row.id === id
                                        ? { ...row, display_status: 0, deleteButtonFlag: 0 } // Update display_status to 0
                                        : row
                                )
                            }));
                            console.log("Deleted the row", id);
                        } else {
                            dispatch(setMessage(response.data.error));
                            console.log("Deletion failed:", response.data.error);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        dispatch(setMessage('An error occurred please try again later...'));
                    })
                    .finally(() => {
                        dispatch(finishLoading());
                    });
            } else {
                console.log("Deletion canceled");
            }
        }
    }

    let columns = [
        {
            Header: 'Message',
            accessor: 'message',
            Cell: ({ cell }) => (
                <>

                    <div className='my-2'><b>{cell.row.original.company_name}</b></div>
                    <div className='w-auto p-2 border border-1 message-bkg' dangerouslySetInnerHTML={{ __html: formatNewLineMessage(cell.row.original.message) }}></div>
                    <div className='text-end mt-2'><mark><small>{cell.row.original.date_posted_format}</small></mark></div>
                </>
            ),
        },

        {
            Header: 'Disable',
            accessor: 'id',
            Cell: ({ cell }) => (
                <>
                    {cell.row.original.deleteButtonFlag == 1 ? (cell.row.original.display_status == '1' ? <button className="btn btn-sm btn-danger" onClick={() => handleDisable(cell.row.original.id, cell.row.original.source)}>
                        <i className="fa fa-trash"></i>
                    </button> : <span style={{ color: '#ff0000' }}>Disabled</span>) : (cell.row.original.display_status == '1' ? <></> : <span style={{ color: '#ff0000' }}>Disabled</span>)}
                </>
            ),
            className: 'text-center align-middle',
            disableSortBy: true,
        },
    ];

    const [result, setResult] = useState({
        tableColumns: columns,
        tableData: [],
        pageNumber: 1,
        totalPages: 1,
        totalRecords: 0,
        pageIndex: 0,
        recordPerPage: 20,
        orderBy: "",
        pageDataLoaded: 0,
        currentSort: "1"
    });

    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },

    } = useTable(
        {
            columns: result.tableColumns,
            data: result.tableData,
            manualSortBy: true,
            initialState: {
                pageIndex: result.pageIndex,
                pageSize: result.recordPerPage
            },
            manualPagination: true,
            pageCount: result.totalPages,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "My Message" })

            getTableData();
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const getTableData = () => {
        dispatch(startLoading());
        api.post('/ipoeditcomment-read', {
            pagenumber: result.pageIndex + 1,
            orderby: result.currentSort,
        }).then(response => {
            if (response.data.msg === 1) {
                setResult((prevState) => ({
                    ...prevState,
                    tableColumns: columns,
                    tableData: response.data.commentList,
                    totalRecords: response.data.TotalRecords,
                    recordPerPage: response.data.RecordPerPage,
                    pageNumber: response.data.PageNumber,
                    orderBy: response.data.Orderby,
                    totalPages: response.data.TotalPages,
                    pageDataLoaded: 1,
                }))
            } else {
                dispatch(setMessage(response.data.error));
            }

            dispatch(finishLoading());
        }).catch(error => {
            console.error('Error:', error);
            dispatch(finishLoading());
        });
    }

    useEffect(() => {
        if (result.pageDataLoaded === 1) {
            getTableData();
        }
    }, [result.pageIndex, result.currentSort]);

    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            let currentSort = "";
            if (sortBy.length > 0) {
                currentSort = sortBy[0].id + (sortBy[0].desc ? " desc" : " asc");
            }
            setResult((prevState) => ({
                ...prevState,
                currentSort: currentSort
            }));
        }
    }, [sortBy]);

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <div className='row main mt-3'>
                <div className='col-sm-12'>
                    <h1 className="mb-3">My Message</h1>
                    <p>Note: You could disable a message with in <b>2 hours</b> of posting.</p>

                    <div className='table-responsive'>
                        <div className={`table-container ${loading ? 'blur' : ''}`}>
                            <Table {...getTableProps()} striped bordered hover id='report_table'>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th className={column.className} {...column.getHeaderProps()}>
                                                    <div
                                                        {...column.getSortByToggleProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {column.render('Header')}
                                                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                                    </div>
                                                </th>

                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length}>
                                                No Record<br /><br /></td>
                                        </tr>
                                    ) : (
                                        rows.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className={`${cell.column.className} ${row.original.display_status != 1 ? 'danger' : ''}`}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </div>

                    {result.totalPages > 1 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Prev onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex - 1 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Item
                                key={result.pageIndex}
                                active={true}
                                onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex }))}
                            >
                                {result.pageIndex + 1}
                            </Pagination.Item>

                            <Pagination.Next onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                            <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                        </Pagination>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-end">
                    <button
                        type="button"
                        onClick={btnClick}
                        className="btn btn-secondary btn-sm"
                        name="closeButton"
                        value="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    )
}

export default IPOEditComment
