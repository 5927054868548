import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ReportCreateForm from './ReportCreateForm';
import { Button, Modal, Nav, Tab, Table } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import NumberInput from '../Common/NumberInput';
import * as Yup from 'yup';


const ReportCreate = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleShowNew = () => {
        resetParam();
        setShowModal(true);
    }

    const [initialValues, setInitialValues] = useState({
        id: "",
        report_title: "",
        report_type_id: "",
        report_db_id: "",
        security_level_id: "",
        report_sql_count: "",
        report_sql: "",
        pre_sql: "",
        group_by: "",
        having_clause: "",
        default_sort: "",
        page_size: "",
        link_field_name: "",
        link_file_name: "",
        update_status_table: "",
        update_status_id_field: "",
        update_status_field_name: "",
        text_area_field_name: "",
        additional_link: "",
        search_column_names: "",
        display_order: "",
        lock_status: false,
        display_status: false,
        disable_display_status_feature: false,
        report_description: "",
        report_disclaimer: "",
        additional_parameter: "",
        sql_dropdown: "",
        update_display_order_field_name: ""
    });

    const [initialModalValues, setInitialModalValues] = useState({
        id: "",
        reports_master_id: id,
        code: "",
        code_desc: "",
        report_title: "",
        report_description: "",
        report_disclaimer: "",
        display_order: "",
        display_status: false
    })

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/57");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            setReportPage();
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const setReportPage = () => {
        dispatch(startLoading());
        console.log("id : " + id);
        if (isAddMode) {
            setMetaData({ "title": "Add New Report" })
        } else {
            setMetaData({ "title": "Edit Report" })
        }
        api.post('/admin-report-get', {
            id: id
        }).then(response => {
            console.log(response.data)
            if (response.data.msg === 1) {
                setResult(response.data);
                if (!isAddMode) {
                    setInitialValues(response.data.report[0]);
                }
            } else {
                dispatch(setMessage(response.data.error));
            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }
    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-report-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/57");
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);

            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };
    const handleModalSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-report-parameter-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {

                setReportPage();
                handleClose();
                resetParam();
                dispatch(setMessage(response.data.success));
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);

            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };
    const resetParam = () => {
        setInitialModalValues({
            id: "",
            reports_master_id: id,
            code: "",
            code_desc: "",
            report_title: "",
            report_description: "",
            report_disclaimer: "",
            display_order: "",
            display_status: false
        })
    }
    const editParam = (id) => {
        dispatch(startLoading());
        console.log("id : " + id);
        api.post('/admin-report-parameter-read', {
            id: id
        }).then(response => {
            console.log(response.data)
            if (response.data.msg === 1) {
                setInitialModalValues(response.data.reportParameter[0]);
                handleShow();
            } else {
                dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>

            {(result !== undefined && result.report !== undefined && result.report.length > 0 && result.report[0].additional_parameter == 1) ?
                <Tab.Container defaultActiveKey="link-1">
                    <Nav variant="tabs" className="mt-2">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2">Parameter</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="link-1" className='pt-3 px-2'>
                            <ReportCreateForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-2" className='pt-3'>

                            {result !== undefined &&
                                result.report !== undefined &&
                                result.report.length > 0 &&
                                result.report[0].lock_status == 1 && (
                                    <h3 className="text-danger">
                                        Record Is Locked , you can't update it.
                                    </h3>
                                )}
                            <div className="row">
                                <div className="col-12 mb-2">
                                    {initialValues.lock_status !== 1 && (
                                        <button type="button" className="btn btn-primary btn-sm mt-2"
                                            onClick={handleShowNew}>
                                            Add New
                                        </button>
                                    )}
                                </div>
                                <div className="col-12">
                                    {result.reportParameters !== undefined && result.reportParameters.length > 0 &&
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    {Object.keys(result.reportParameters[0]).map((key) => (
                                                        <th key={key}>{key}</th>
                                                    ))}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.reportParameters.map((item) => (
                                                    <tr key={item.id}>
                                                        {Object.values(item).map((value, index) => (
                                                            <td key={index}>
                                                                <div dangerouslySetInnerHTML={{ __html: value }} />
                                                            </td>
                                                        ))}
                                                        <td>

                                                            <button type="button" className="btn btn-secondary btn-xs mt-2" onClick={() => editParam(item.id)}>Edit</button>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            </div>

                            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                                <Modal.Header closeButton>
                                    <Modal.Title>Report Parameter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={initialModalValues}
                                        onSubmit={handleModalSubmit}
                                    >
                                        {({ setFieldValue }) => (

                                            <Form id='myModalForm'>

                                                <Field type="hidden" name="reports_master_id" />

                                                <div className="mb-3 row">
                                                    <label htmlFor="code" className="col-lg-2">Code</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code" maxLength="100" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="code_desc" className="col-lg-2">Code Desc (For Admin Only)</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code_desc" maxLength="150" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_title" className="col-lg-2">Report Title</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="report_title" maxLength="100" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="5" name="report_description" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="3"
                                                            name="report_disclaimer"
                                                            id="report_disclaimer" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                                                    <div className="col-lg-10">
                                                        <NumberInput classNameAtr={"form-control"} nameAtr={"display_order"} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-check-input" type="checkbox" name="display_status" onChange={(e) => {
                                                            setFieldValue('display_status', e.target.checked);
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <div className="col-lg-12 text-end">
                                                        <Button variant="secondary" onClick={handleClose} className='me-2'>
                                                            Close
                                                        </Button>
                                                        {initialValues.lock_status !== 1 && (
                                                            <Button variant="primary" type='submit' onclick="submitParam(); return false;">Submit</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal.Body>

                            </Modal>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                :
                <>
                    <hr />
                    <ReportCreateForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                </>
            }

        </>
    )
}

export default ReportCreate
