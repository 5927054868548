import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import CronjobForm from './CronjobForm';

const Cronjob = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        title: "",
        filename: "",
        cron_id: "",
        display_status: false,
        output_log: "",
        selected_script: "",
        cron_time_type: "",
        selected_time: "",
        selected_day: "",
        selected_month: "",
        expression: "",
        target_url: "",
        admin_comment: ""
    });
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {

            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Cronjob" })
            } else {
                setMetaData({ "title": "Edit Cronjob" })

            }
            dispatch(startLoading());
            api.post('/admin-cronjob-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.cronjob);
                    if (!isAddMode) {
                        setInitialValues(response.data.cronjob[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        dispatch(startLoading());


        api.post('/admin-cronjob-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());

        });
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <CronjobForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
        </>
    )
}

export default Cronjob
