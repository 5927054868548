import { useEffect } from "react";
import DOMPurify from 'dompurify';

export const FormatNumber = (value, decimalPlaces = 0) => {
    return Number(value).toLocaleString('en-IN', { maximumFractionDigits: decimalPlaces });
}

export const funCreateSlug = (input) => {
    return input
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^a-zA-Z0-9-]/g, '') // Remove non-alphanumeric characters except hyphens
        .replace(/-{2,}/g, '-'); // Replace consecutive hyphens with a single hyphen
};

export const funNoSpaces = (fieldName) => ({
    message: `${fieldName} cannot contain spaces`,
    test: (value) => !/\s/.test(value),
});

export const funCheckTrim = (fieldName) => ({
    message: `${fieldName} must not be empty or contain only spaces`,
    test: (value) => value.trim() !== '',
});


export const funTrimString = (value) => {
    return typeof value === 'string' ? value.trim() : (typeof value === 'boolean' ? (value === true ? 1 : 0) : value);
};

export const funTrimNestedStrings = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(funTrimNestedStrings);
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, funTrimNestedStrings(value)])
        );
    } else {
        return funTrimString(obj);
    }
};

export const customReplace = (inputString, search, replacement) => {
    const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSearch, 'g');
    return inputString.replace(regex, replacement);
};

export const convertNullToBlank = (obj) => {
    if (obj === null) {
        return "";
    } else if (typeof obj === "object") {
        // If obj is an array, iterate over its elements
        if (Array.isArray(obj)) {
            return obj.map((item) => convertNullToBlank(item));
        }
        // If obj is an object, iterate over its keys
        else {
            const newObj = {};
            Object.keys(obj).forEach((key) => {
                newObj[key] = convertNullToBlank(obj[key]);
            });
            return newObj;
        }
    } else {
        return obj;
    }
}

export const compareDates = (date1, date2) => {
    // Convert both dates to milliseconds since Epoch
    const date1Time = date1 instanceof Date ? date1.getTime() : new Date(date1).getTime();
    const date2Time = date2 instanceof Date ? date2.getTime() : new Date(date2).getTime();

    // Compare the milliseconds since Epoch
    if (date1Time < date2Time) {
        return -1; // date1 is before date2
    } else if (date1Time > date2Time) {
        return 1; // date1 is after date2
    } else {
        return 0; // date1 is equal to date2
    }
}

export const replaceSpaceWithHiphen = (fileName) => {
    fileName = fileName.trim();
    fileName = fileName.replace(/\s+/g, "-");
    return fileName;
}

export const isValidImageFileName = (fileName) => {
    // Supported image file extensions
    if (isValidVariable(fileName)) {
        const supportedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.pdf'];

        // Regular expression pattern to match alphanumeric characters, underscore, hyphen, and space, disallowing dots within the file name but allowing them between the file name and extension
        const regex = /^[^.\s][a-z0-9_-]*\.[a-z0-9]+$/;
        // Get the file extension
        const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

        // Check if the file name matches the regex pattern and has a supported image file extension
        if (regex.test(fileName) && supportedExtensions.includes(fileExtension.toLowerCase())) {
            return true;
        } else {
            alert('File Name Error , Must be in "a-Z 1..90 - _" and extension in .jpg, .jpeg, .png, .svg, .gif, .pdf');
            return false;
        }
    }
    return true;
}

export const isValidVariable = (varname) => {
    if (varname !== undefined && varname !== null && varname !== "") {
        return true;
    }
    return false;
}

export const validateId = (id) => {

    // Check if id is not null or undefined
    if (id === null || id === undefined) {
        return false;
    }

    id = String(id).trim();
    // Check if id is not empty string
    if (typeof id === 'string' && id.trim() === '') {
        return false;
    }
    id = parseInt(id);
    // Check if id is an integer
    if (!Number.isInteger(id) || id === 0) {
        return false;
    }

    // If all checks pass, return true
    return true;
}

export const funCloseWebWindow = (refeeral_url) => {
    setTimeout(() => {
        window.close();
        window.location.href = refeeral_url;
    }, 1000);
};

export const closePopUpWindow = () => {
    window.close();
};

export const validateInput = (inputValue, type) => {
    switch (type) {
        case 'phoneNumber':
            const phoneRegex = /^\d{10,}$/; // Regular expression for at least 10 digits
            if (!phoneRegex.test(inputValue)) {
                return 'Please enter a valid phone number with at least 10 digits.';
            }
            return '';
        // Add cases for other types of validation (e.g., email, name) as needed
        default:
            return '';
    }
};

export const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
};

export const funFormatDateTime = (strDate) => {
    if (strDate && isValidDate(strDate)) {
        const dateObj = new Date(strDate);
        const strTime = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
        const strDate1 = dateObj.toDateString().split(' ').slice(1).join(' ');
        return `${strDate1} ${strTime}`;
    }
    return '';
};

export function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export function useScript(src, id) {
    useEffect(() => {
        if (document.getElementById(id)) return; // If script already exists, do nothing

        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src, id]);
}

export function useScriptTinyMce(src, id, pathname, setFieldValue, values) {
    useEffect(() => {
        const loadAndInitTinyMCE = () => {
            if (window.tinymce) {
                initTinyMCE();
            } else {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.onload = initTinyMCE;
                document.body.appendChild(script);
            }
        };

        const initTinyMCE = () => {
            window.tinymce.init({
                selector: '#review',
                resize: false,
                height: 500,
                menubar: true,
                promotion: false,
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat |',
                setup: (editor) => {
                    editor.on('Blur', () => {
                        setFieldValue('review', editor.getContent());
                    });
                },
                init_instance_callback: (editor) => {
                    editor.setContent(values.review || '');
                }
            });
        };

        loadAndInitTinyMCE();

        return () => {
            if (window.tinymce) {
                window.tinymce.remove('#review');
            }
            const script = document.querySelector(`script[src="${src}"]`);
            if (script) {
                document.body.removeChild(script);
            }
        };

    }, [pathname, values]);
}


export function useStyle(href, id) {
    useEffect(() => {
        if (document.getElementById(id)) return; // If style already exists, do nothing

        const link = document.createElement('link');
        link.href = href;
        link.id = id;
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [href, id]);
}

export function formatNewLineMessage(message) {
    return message
        .replace(/\n/g, '<br />') // Replace newlines with <br> tags
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // Replace tabs with spaces
}

export const isValidMonth = (month) => {
    // Check if month is not null or undefined
    if (month === null || month === undefined || !isNumeric(month)) {
        return false;
    }

    // Convert to string and trim whitespace
    month = String(month).trim();

    // Check if month is empty after trimming
    if (month === '') {
        return false;
    }

    // Parse month to integer
    month = parseInt(month, 10);

    // Check if month is an integer between 1 and 12
    return Number.isInteger(month) && month >= 1 && month <= 12;
}

export const isValidYear = (year) => {
    // Check if year is not null or undefined
    if (year === null || year === undefined || !isNumeric(year)) {
        return false;
    }

    // Convert to string and trim whitespace
    year = String(year).trim();

    // Check if year is empty after trimming
    if (year === '') {
        return false;
    }

    // Parse year to integer
    year = parseInt(year, 10);

    // Check if year is a positive integer
    if (!Number.isInteger(year) || year <= 0) {
        return false;
    }

    // Check if year is within a reasonable range
    const currentYear = new Date().getFullYear();
    const earliestYear = 2000; // Change this if needed
    const latestYear = currentYear + 10; // Change this if needed

    if (year < earliestYear || year > latestYear) {
        return false;
    }

    return true;
}

export const handlePopupWin = (url) => {
    // Implement your popup logic here
    window.open(url, 'popup', 'width=600,height=400');
    return false;
};

export const validateCustomHtml = (input) => {
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(input, "text/html");
    const errors = parsedDoc.querySelectorAll("parsererror");
    const unclosedTags = checkUnclosedTags(input);

    if (errors.length === 0 && unclosedTags.length === 0) {
        return input;
    }

    if (window.confirm("Invalid HTML detected! Do you want to fix it automatically?")) {
        const fixedHtml = autoFixHtml(input);
        return processHtml(DOMPurify.sanitize(fixedHtml));
    }

    console.warn("Invalid HTML detected:", {
        unclosedTags: unclosedTags.join(", "),
        parsingErrors: errors.length > 0,
    });

    return null;
};

const autoFixHtml = (html) => {
    let fixedHtml = html;

    checkUnclosedTags(html).forEach((tag) => {
        fixedHtml += `</${tag}>`; // Append missing closing tags
    });

    return fixedHtml;
};

const processHtml = (html) => {
    return html
        .replace(/<img([^>]*)>/gi, (match, attributes) => {
            attributes = attributes.replace(/src="([^"]*)"/i, (srcMatch, srcValue) =>
                `src="${srcValue.toLowerCase()}"`
            );
            if (/class="([^"]*)"/i.test(attributes)) {
                attributes = attributes.replace(/class="([^"]*)"/i, (classMatch, classValue) =>
                    classValue.includes("img-fluid") ? classMatch : `class="${classValue} img-fluid"`
                );
            } else {
                attributes += ' class="img-fluid"';
            }

            return `<img${attributes} />`;
        })
        .replace(/<(br|hr|input|meta|link)([^>]*)>/gi, "<$1$2 />") // Ensure self-closing tags
        .replace(/<a([^>]*)href="([^"]*)"([^>]*)>/gi, (match, beforeHref, href, afterHref) => {
            if (!href.startsWith("/") && !href.startsWith("#") && !href.startsWith("javascript")) {
                if (/target="_blank"/i.test(beforeHref + afterHref)) {
                    return match; // Already has target="_blank"
                }
                return `<a${beforeHref}href="${href}"${afterHref} target="_blank">`;
            }
            return match; // Keep internal links unchanged
        });
};

const checkUnclosedTags = (html) => {
    const tagRegex = /<\/?([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g;
    const selfClosingTags = new Set(["br", "hr", "img", "meta", "link", "input"]);
    const stack = [];
    const unclosedTags = [];
    let match;

    while ((match = tagRegex.exec(html)) !== null) {
        const tag = match[1].toLowerCase(); // Case-insensitive tag matching
        const isClosingTag = match[0].startsWith("</");

        if (selfClosingTags.has(tag)) {
            continue; // Ignore self-closing tags
        }

        if (isClosingTag) {
            if (stack.length > 0 && stack[stack.length - 1] === tag) {
                stack.pop();
            } else {
                unclosedTags.push(tag);
            }
        } else {
            stack.push(tag);
        }
    }

    return [...new Set([...unclosedTags, ...stack])];
};