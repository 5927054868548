import React from "react";
import Footer from "./Common/Footer";
import Header from "./Common/Header";
import { Outlet } from "react-router-dom";
import Message from "../common/Message";
import LoadingBar from 'react-top-loading-bar';
import { useSelector } from "react-redux";
import { selectLoading } from "../../state/slices/loadingSlice";

const Index = () => {
    const loading = useSelector(selectLoading);

    return (
        <> 
        <LoadingBar progress={loading ? 70 : 100} height={2} color="#f11946" />
            <Header />
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-sm-12 main'>
                        <Message/>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Index;
