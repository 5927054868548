import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';
import { customReplace } from '../Common/CommonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingCompanyCommonChargeForm from './TradingCompanyCommonChargeForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, Button } from 'react-bootstrap';

const TradingCompanyCommonCharge = () => {

    const validationSchema = Yup.object({

    });
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        i_stamp_d_min: "",
        i_stamp_d_max: "",
        d_stamp_d_min: "",
        d_stamp_d_max: "",
        f_stamp_d_min: "",
        f_stamp_d_max: "",
        o_stamp_d_min: "",
        o_stamp_d_max: "",
        cf_stamp_d_min: "",
        cf_stamp_d_max: "",
        co_stamp_d_min: "",
        co_stamp_d_max: "",
        b_i_stt: "",
        s_i_stt: "",
        b_d_stt: "",
        s_d_stt: "",
        b_f_stt: "",
        s_f_stt: "",
        b_o_stt: "",
        s_o_stt: "",
        b_cf_stt: "",
        s_cf_stt: "",
        b_co_stt: "",
        s_co_stt: "",
        sebi_charges: "",
        service_tax: "",
        n_i_etc: "",
        n_d_etc: "",
        n_f_etc: "",
        n_o_etc: "",
        n_cf_etc: "",
        n_co_etc: "",
        b_i_etc: "",
        b_d_etc: "",
        b_f_etc: "",
        b_o_etc: "",
        b_cf_etc: "",
        b_co_etc: "",
        common_brokerage_charge: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            if (isAddMode) {
                setMetaData({ "title": "Add New Trading Company Common Charge" })
            } else {
                setMetaData({ "title": "Edit Trading Company Common Charge" })
            }
            api.post('/admin-tradingCompanyCommonCharges-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        setInitialValues((prev) => ({
                            ...prev,
                            ...response.data.tradingCompanyCommonCharges[0],
                        }));
                    }

                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (values, { setFieldValue }) => {
        let res = commonBrokerageTable(values);
        setFieldValue("common_brokerage_charge", res)
        setShowModal(true);
    };

    const handleConfirmSubmit = (values, setSubmitting) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        dispatch(startLoading());
        api.post('/admin-tradingCompanyCommonCharges-save', {
            values: trimmedValues,
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        setShowModal(false);
        setSubmitting(false);
    };

    const commonBrokerageTable = (values) => {

        // console.log("Brokerage Tealbe Vlaue : ", values)

        let fldn_i_etc = values.n_i_etc ?? "";
        let fldb_i_etc = values.b_i_etc ?? "";
        let fldn_d_etc = values.n_d_etc ?? "";
        let fldb_d_etc = values.b_d_etc ?? "";
        let fldn_f_etc = values.n_f_etc ?? "";
        let fldb_f_etc = values.b_f_etc ?? "";
        let fldn_o_etc = values.n_o_etc ?? "";
        let fldb_o_etc = values.b_o_etc ?? "";
        let fldn_cf_etc = values.n_cf_etc ?? "";
        let fldb_cf_etc = values.b_cf_etc ?? "";
        let fldn_co_etc = values.n_co_etc ?? "";
        let fldb_co_etc = values.b_co_etc ?? "";

        let fldN_I_ETC_per = "";
        if (fldn_i_etc.toString().includes("%")) {
            fldN_I_ETC_per = fldn_i_etc;
            fldn_i_etc = parseFloat(customReplace(fldn_i_etc, "%", "")) * 10000000 / 100;
        } else {
            fldN_I_ETC_per = (parseFloat(fldn_i_etc) / 100000) + "%";
        }

        let fldb_i_etc_per = ""
        if (fldb_i_etc.toString().includes("%")) {
            fldb_i_etc_per = fldb_i_etc
            fldb_i_etc = parseFloat(customReplace(fldb_i_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_i_etc_per = (parseFloat(fldb_i_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Equity_Intraday = ""
        if (fldn_i_etc != "" && fldn_i_etc != "0" && fldb_i_etc != "" && fldb_i_etc != "0") {
            fldTransaction_Charges_Equity_Intraday = "NSE Rs " + fldn_i_etc + " per Cr (" + fldN_I_ETC_per + ") | BSE Rs " + fldb_i_etc + " per Cr (" + fldb_i_etc_per + ") (sell side)"
        } else if (fldn_i_etc != "" && fldn_i_etc != "0") {
            fldTransaction_Charges_Equity_Intraday = fldTransaction_Charges_Equity_Intraday + "NSE Rs " + fldn_i_etc + " per Cr (" + fldN_I_ETC_per + ")"
        } else if (fldb_i_etc != "" && fldb_i_etc != "0") {
            fldTransaction_Charges_Equity_Intraday = fldTransaction_Charges_Equity_Intraday + "BSE Rs " + fldb_i_etc + " per Cr (" + fldb_i_etc_per + ")"
        }

        //  Delivery
        let fldn_d_etc_per = "";
        if (fldn_d_etc.toString().includes("%")) {
            fldn_d_etc_per = fldn_d_etc
            fldn_d_etc = parseFloat(customReplace(fldn_d_etc, "%", "")) * 10000000 / 100
        } else {
            fldn_d_etc_per = (parseFloat(fldn_d_etc) / 100000) + "%"
        }

        let fldb_d_etc_per = "";
        if (fldb_d_etc.toString().includes("%")) {
            fldb_d_etc_per = fldb_d_etc
            fldb_d_etc = parseFloat(customReplace(fldb_d_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_d_etc_per = (parseFloat(fldb_d_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Equity_Delivery = ""
        if (fldn_d_etc != "" && fldn_d_etc != "0" && fldb_d_etc != "" && fldb_d_etc != "0") {
            fldTransaction_Charges_Equity_Delivery = "NSE Rs " + fldn_d_etc + " per Cr (" + fldn_d_etc_per + ") | BSE Rs " + fldb_d_etc + " per Cr (" + fldb_d_etc_per + ") (each side)"
        } else if (fldn_d_etc != "" && fldn_d_etc != "0") {
            fldTransaction_Charges_Equity_Delivery = fldTransaction_Charges_Equity_Delivery + "NSE Rs " + fldn_d_etc + " per Cr (" + fldn_d_etc_per + ")"
        } else if (fldb_d_etc != "" && fldb_d_etc != "0") {
            fldTransaction_Charges_Equity_Delivery = fldTransaction_Charges_Equity_Delivery + "BSE Rs " + fldb_d_etc + " per Cr (" + fldb_d_etc_per + ")"
        }

        //  Futures
        let fldn_f_etc_per = "";
        if (fldn_f_etc.toString().includes("%")) {
            fldn_f_etc_per = fldn_f_etc
            fldn_f_etc = parseFloat(customReplace(fldn_f_etc, "%", "")) * 10000000 / 100
        } else {
            fldn_f_etc_per = (parseFloat(fldn_f_etc) / 100000) + "%"
        }

        let fldb_f_etc_per = "";
        if (fldb_f_etc.toString().includes("%")) {
            fldb_f_etc_per = fldb_f_etc
            fldb_f_etc = parseFloat(customReplace(fldb_f_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_f_etc_per = (parseFloat(fldb_f_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Equity_Futures = ""
        if (fldn_f_etc != "" && fldn_f_etc != "0" && fldb_f_etc != "" && fldb_f_etc != "0") {
            fldTransaction_Charges_Equity_Futures = "NSE Rs " + fldn_f_etc + " per Cr (" + fldn_f_etc_per + ") | BSE Rs " + fldb_f_etc + " per Cr (" + fldb_f_etc_per + ") "
        } else if (fldn_f_etc != "" && fldb_f_etc == "0") {
            fldTransaction_Charges_Equity_Futures = "NSE Rs " + fldn_f_etc + " per Cr (" + fldn_f_etc_per + ") | BSE Rs 0 "
        } else if (fldn_f_etc != "" && fldn_f_etc != "0") {
            fldTransaction_Charges_Equity_Futures = fldTransaction_Charges_Equity_Futures + "NSE Rs " + fldn_f_etc + " per Cr (" + fldn_f_etc_per + ")"
        } else if (fldb_f_etc != "" && fldb_f_etc != "0") {
            fldTransaction_Charges_Equity_Futures = fldTransaction_Charges_Equity_Futures + "BSE Rs " + fldb_f_etc + " per Cr (" + fldb_f_etc_per + ")"
        }

        //  Options
        let fldn_o_etc_per = "";
        if (fldn_o_etc.toString().includes("%")) {
            fldn_o_etc_per = fldn_o_etc
            fldn_o_etc = parseFloat(customReplace(fldn_o_etc, "%", "")) * 10000000 / 100
        } else {
            fldn_o_etc_per = (parseFloat(fldn_o_etc) / 100000) + "%"
        }

        let fldb_o_etc_per = "";
        if (fldb_o_etc.toString().includes("%")) {
            fldb_o_etc_per = fldb_o_etc
            fldb_o_etc = parseFloat(customReplace(fldb_o_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_o_etc_per = (parseFloat(fldb_o_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Equity_Options = ""
        if (fldn_o_etc != "" && fldn_o_etc != "0" && fldb_o_etc != "" && fldb_o_etc != "0") {
            fldTransaction_Charges_Equity_Options = "NSE Rs " + fldn_o_etc + " per Cr (" + fldn_o_etc_per + ") | BSE Rs " + fldb_o_etc + " per Cr (" + fldb_o_etc_per + ") "
        } else if (fldn_o_etc != "" && fldn_o_etc != "0") {
            fldTransaction_Charges_Equity_Options = fldTransaction_Charges_Equity_Options + "NSE Rs " + fldn_o_etc + " per Cr (" + fldn_o_etc_per + ")"
        } else if (fldb_o_etc != "" && fldb_o_etc != "0") {
            fldTransaction_Charges_Equity_Options = fldTransaction_Charges_Equity_Options + "BSE Rs " + fldb_o_etc + " per Cr (" + fldb_o_etc_per + ")"
        }
        fldTransaction_Charges_Equity_Options = fldTransaction_Charges_Equity_Options + " (on premium)"

        //  Currency Future
        let fldn_cf_etc_per = "";
        if (fldn_cf_etc.toString().includes("%")) {
            fldn_cf_etc_per = fldn_cf_etc
            fldn_cf_etc = parseFloat(customReplace(fldn_cf_etc, "%", "")) * 10000000 / 100
        } else {
            fldn_cf_etc_per = (parseFloat(fldn_cf_etc) / 100000) + "%"
        }

        let fldb_cf_etc_per = "";
        if (fldb_cf_etc.toString().includes("%")) {
            fldb_cf_etc_per = fldb_cf_etc
            fldb_cf_etc = parseFloat(customReplace(fldb_cf_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_cf_etc_per = (parseFloat(fldb_cf_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Currency_Futures = ""
        if (fldn_cf_etc != "" && fldn_cf_etc != "0" && fldb_cf_etc != "" && fldb_cf_etc != "0") {
            fldTransaction_Charges_Currency_Futures = "NSE Rs " + fldn_cf_etc + " per Cr (" + fldn_cf_etc_per + ") | BSE Rs " + fldb_cf_etc + " per Cr (" + fldb_cf_etc_per + ") "
        } else if (fldn_cf_etc != "" && fldn_cf_etc != "0") {
            fldTransaction_Charges_Currency_Futures = fldTransaction_Charges_Currency_Futures + "NSE Rs " + fldn_cf_etc + " per Cr (" + fldn_cf_etc_per + ")"
        } else if (fldb_cf_etc != "" && fldb_cf_etc != "0") {
            fldTransaction_Charges_Currency_Futures = fldTransaction_Charges_Currency_Futures + "BSE Rs " + fldb_cf_etc + " per Cr (" + fldb_cf_etc_per + ")"
        }

        //  Currency Options
        let fldn_co_etc_per = "";
        if (fldn_co_etc.toString().includes("%")) {
            fldn_co_etc_per = fldn_co_etc
            fldn_co_etc = parseFloat(customReplace(fldn_co_etc, "%", "")) * 10000000 / 100
        } else {
            fldn_co_etc_per = (parseFloat(fldn_co_etc) / 100000) + "%"
        }

        let fldb_co_etc_per = "";
        if (fldb_co_etc.toString().includes("%")) {
            fldb_co_etc_per = fldb_co_etc
            fldb_co_etc = parseFloat(customReplace(fldb_co_etc, "%", "")) * 10000000 / 100
        } else {
            fldb_co_etc_per = (parseFloat(fldb_co_etc) / 100000) + "%"
        }

        let fldTransaction_Charges_Currency_Options = ""
        if (fldn_co_etc != "" && fldn_co_etc != "0" && fldb_co_etc != "" && fldb_co_etc != "0") {
            fldTransaction_Charges_Currency_Options = "NSE Rs " + fldn_co_etc + " per Cr (" + fldn_co_etc_per + ") | BSE Rs " + fldb_co_etc + " per Cr (" + fldb_co_etc_per + ") "
        } else if (fldn_co_etc != "" && fldn_co_etc != "0") {
            fldTransaction_Charges_Currency_Options = fldTransaction_Charges_Currency_Options + "NSE Rs " + fldn_co_etc + " per Cr (" + fldn_co_etc_per + ")"
        } else if (fldb_co_etc != "" && fldb_co_etc != "0") {
            fldTransaction_Charges_Currency_Options = fldTransaction_Charges_Currency_Options + "BSE Rs " + fldb_co_etc + " per Cr (" + fldb_co_etc_per + ")"
        }
        fldTransaction_Charges_Currency_Options = fldTransaction_Charges_Currency_Options + " (on premium)";

        return (
            // {
            //     fldTransaction_Charges_Equity_Delivery,
            //     fldTransaction_Charges_Equity_Intraday,
            //     fldTransaction_Charges_Equity_Futures,
            //     fldTransaction_Charges_Equity_Options,
            //     fldTransaction_Charges_Currency_Futures,
            //     fldTransaction_Charges_Currency_Options,
            // }

            `<div class='col-12'>
                <hr />
                <a id="table_transaction_charges"> </a>
                <h2>
                    [[Broker_Name]] Transaction Charges (Exchange Turnover Change)
                </h2>
                <p>A combination of Exchange Turnover Charge and Trade Clearing Charge. Know more about <a href="/article/transaction_charges_by_stock_brokers_in_india/82/" title="Transaction Charges by Brokers">Transaction Charges </a>.</p>
            </div>

            <div class='col-12' itemscope itemtype='http://schema.org/Table'>
                <h2 itemProp='about'>[[Broker_Name]] Transaction Charges ${new Date().getFullYear()}</h2>
                <div class='table-responsive'>
                    <table class='table table-bordered table-striped table-hover w-auto'>
                        <thead>
                            <tr><th>Segment</th><th>Transaction Fee</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>Equity Intraday</td><td>${fldTransaction_Charges_Equity_Intraday}</td></tr>
                            <tr><td> Equity Delivery </td><td>${fldTransaction_Charges_Equity_Delivery}</td></tr>
                            <tr><td> Equity Futures </td><td>${fldTransaction_Charges_Equity_Futures}</td></tr>
                            <tr><td> Equity Options </td><td>${fldTransaction_Charges_Equity_Options}</td></tr>
                            <tr><td> Currency Future </td><td>${fldTransaction_Charges_Currency_Futures}</td></tr>
                            <tr><td> Currency Option </td><td>${fldTransaction_Charges_Currency_Options}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>`
        )
    }

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setSubmitting }) => (
                    <>
                        <TradingCompanyCommonChargeForm
                            result={result}
                            handleGoBack={handleGoBack}
                            loading={loading}
                            initialValues={initialValues}
                        />
                        <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
                            <Modal.Header closeButton>
                                <Modal.Title>Do you want to update this Transaction Table ?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {<div dangerouslySetInnerHTML={{ __html: values.common_brokerage_charge }}></div>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    No
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => handleConfirmSubmit(values, setSubmitting)}
                                >
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    )
}

export default TradingCompanyCommonCharge;