import React from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import NumberInput from "../Common/NumberInput";
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const TradingCompanyDetailForm = ({ result, handleGoBack, loading }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Form id="myForm">

        <div className="mb-3 row">
          <label htmlFor="service_type" className="col-lg-2" >
            Service Type
          </label>
          <div className="col-lg-2">
            <Field
              as="input"
              className="form-control"
              type="text"
              name="service_type"
              maxLength="50"
              tabIndex={5}
            />
          </div>
          <div className="col-lg-2">
            i.e. Full Service Broker, Discount Broker
          </div>
          <label htmlFor="exchange_supported" className="col-lg-2">
            Exchange Supported
          </label>
          <div className="col-lg-2">
            <Field
              as="input"
              className="form-control"
              type="text"
              name="exchange_supported"
              maxLength="50"
              tabIndex={10}
            />
          </div>
          <div className="col-lg-2">i.e. BSE, NSE, MCX</div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="year_of_incorporation" className="col-lg-2">
            Year of Incorporation:
          </label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={15} maxLength="4" nameAtr="year_of_incorporation" />
          </div>
          <div className="col-lg-2">i.e. 2018, 2019</div>
          <label htmlFor="branch_count" className="col-lg-2">
            Number of Branches:
          </label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={20} maxLength="10" nameAtr="branch_count" />
          </div>
          <div className="col-lg-2">i.e. 20, 30</div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="ucc_count" className="col-lg-2">
            Client Count (Auto)
          </label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={25} maxLength="10" nameAtr="ucc_count" />
          </div>
          <div className="col-lg-2">i.e. 5000</div>
          <label htmlFor="ucc_changed" className="col-lg-2">
            Clients Changed (Auto)
          </label>
          <div className="col-lg-2">
            <Field
              as="input"
              className="form-control"
              type="text"
              name="ucc_changed"
              tabIndex={30}
            />
          </div>
          <div className="col-lg-2">i.e. 20%</div>
        </div>

        <fieldset>
          <legend>Fees</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="fees_account_opening" className="col-lg-2">
              Account Opening Fee
            </label>
            <div className="col-lg-2">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="fees_account_opening"
                maxLength="50"
                tabIndex={35}
              />
            </div>
            <div className="col-lg-2">i.e. Rs 975</div>
            <label htmlFor="amc_brokerage_account" className="col-lg-2">
              Brokerage Account AMC
            </label>
            <div className="col-lg-2">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="amc_brokerage_account"
                maxLength="50"
                tabIndex={40}
              />
            </div>
            <div className="col-lg-2">i.e. Rs 0</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="fees_demat_opening" className="col-lg-2">
              Demat Acct Opening Fee
            </label>
            <div className="col-lg-2">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="fees_demat_opening"
                maxLength="50"
                tabIndex={45}
              />
            </div>
            <div className="col-lg-2">i.e. Rs 400</div>
            <label htmlFor="amc_demat_account" className="col-lg-2">
              Demat Account AMC
            </label>
            <div className="col-lg-2">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="amc_demat_account"
                maxLength="50"
                tabIndex={50}
              />
            </div>
            <div className="col-lg-2">i.e. Rs 500</div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Brokerage Plan 1</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="account_type" className="col-lg-2">
              Account Type/ Plan:
            </label>
            <div className="col-lg-2">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="account_type"
                maxLength="50"
                tabIndex={55}
              />
            </div>
            <div className="col-lg-2">i.e. I - Secure Plan</div>
            <label htmlFor="brokerage_flat_monthly_fee" className="col-lg-2">
              Flat Monthly Fee:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={60}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_flat_monthly_fee"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 3,999</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_delivery" className="col-lg-2">
              Equity Delivery:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={65}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_delivery"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.55%</div>
            <label htmlFor="brokerage_equity_intraday" className="col-lg-2">
              Equity Intraday:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={70}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_intraday"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.275%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_future" className="col-lg-2">
              Equity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={75}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_future"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_equity_options" className="col-lg-2">
              Equity Options:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={80}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_options"
                maxLength="100"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 95 - Rs 65</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_currency_futures" className="col-lg-2">
              Currency Futures:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={85}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_futures"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_currency_options" className="col-lg-2">
              Currency Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={90}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_options"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_commodity_future_1" className="col-lg-2">
              Commodity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={95}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_future_1"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_commodity_option_1" className="col-lg-2">
              Commodity Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={100}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_option_1"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Brokerage Plan 2</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="account_type_2" className="col-lg-2">
              Account Type/ Plan:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={105}
                as="input"
                className="form-control"
                type="text"
                name="account_type_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">
              i.e. 3-in-1 account (I - Secure Plan)
            </div>
            <label htmlFor="brokerage_flat_monthly_fee_2" className="col-lg-2">
              Flat Monthly Fee:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={110}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_flat_monthly_fee_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 3,999</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_delivery_2" className="col-lg-2">
              Equity Delivery:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={115}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_delivery_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.55%</div>
            <label htmlFor="brokerage_equity_intraday_2" className="col-lg-2">
              Equity Intraday:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={120}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_intraday_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.275%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_future_2" className="col-lg-2">
              Equity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={125}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_future_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_equity_options_2" className="col-lg-2">
              Equity Options:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={130}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_options_2"
                maxLength="100"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 95 - Rs 65</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_currency_futures_2" className="col-lg-2">
              Currency Futures:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={135}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_futures_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_currency_options_2" className="col-lg-2">
              Currency Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={140}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_options_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_commodity_future_2" className="col-lg-2">
              Commodity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={145}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_future_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_commodity_option_2" className="col-lg-2">
              Commodity Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={150}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_option_2"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Brokerage Plan 3</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="account_type_3" className="col-lg-2">
              Account Type/ Plan:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={155}
                as="input"
                className="form-control"
                type="text"
                name="account_type_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">
              i.e. 3-in-1 account (I - Secure Plan)
            </div>
            <label htmlFor="brokerage_flat_monthly_fee_3" className="col-lg-2">
              Flat Monthly Fee:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={160}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_flat_monthly_fee_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 3,999</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_delivery_3" className="col-lg-2">
              Equity Delivery:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={165}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_delivery_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.55%</div>
            <label htmlFor="brokerage_equity_intraday_3" className="col-lg-2">
              Equity Intraday:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={170}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_intraday_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.275%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_equity_future_3" className="col-lg-2">
              Equity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={175}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_future_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_equity_options_3" className="col-lg-2">
              Equity Options:{" "}
            </label>
            <div className="col-lg-2">
              <Field tabIndex={180}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_equity_options_3"
                maxLength="100"
              />
            </div>
            <div className="col-lg-2">i.e. Rs 95 - Rs 65</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_currency_futures_3" className="col-lg-2">
              Currency Futures:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={185}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_futures_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_currency_options_3" className="col-lg-2">
              Currency Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={190}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_currency_options_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_commodity_future_3" className="col-lg-2">
              Commodity Future:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={195}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_future_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
            <label htmlFor="brokerage_commodity_option_3" className="col-lg-2">
              Commodity Options:
            </label>
            <div className="col-lg-2">
              <Field tabIndex={200}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_commodity_option_3"
                maxLength="50"
              />
            </div>
            <div className="col-lg-2">i.e. 0.050% - 0.030%</div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Other Brokerage Charges</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="brokerage_minimum" className="col-lg-2">
              Minimum Brokerage
            </label>
            <div className="col-lg-4">
              <Field tabIndex={205}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_minimum"
                maxLength="100"
              />
            </div>
            <label htmlFor="call_trade_charges" className="col-lg-2">
              Call & Trade Fee
            </label>
            <div className="col-lg-4">
              <Field tabIndex={210}
                as="input"
                className="form-control"
                type="text"
                name="call_trade_charges"
                maxLength="100"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="brokerage_others" className="col-lg-2">
              Other Brokerage Charges
            </label>
            <div className="col-lg-4">
              <Field tabIndex={215}
                as="input"
                className="form-control"
                type="text"
                name="brokerage_others"
                maxLength="100"
              />
            </div>
            <label htmlFor="demat_debit_trans_charges" className="col-lg-2">
              Demat Debit Transaction Fee
            </label>
            <div className="col-lg-4">
              <Field tabIndex={220}
                as="input"
                className="form-control"
                type="text"
                name="demat_debit_trans_charges"
                maxLength="100"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Transaction Charges</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="transaction_charges_equity_delivery" className="col-lg-2">
              Equity Delivery:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={225}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_equity_delivery"
                maxLength="100"
                readOnly={true}
              />
            </div>
            <label htmlFor="transaction_charges_equity_intraday" className="col-lg-2">
              Equity Intraday:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={230}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_equity_intraday"
                maxLength="100"
                readOnly={true}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="transaction_charges_equity_futures" className="col-lg-2">
              Equity Future:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={235}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_equity_futures"
                maxLength="100"
                readOnly={true}
              />
            </div>
            <label htmlFor="transaction_charges_equity_options" className="col-lg-2">
              Equity Options:{" "}
            </label>
            <div className="col-lg-4">
              <Field tabIndex={240}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_equity_options"
                maxLength="100"
                readOnly={true}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="transaction_charges_currency_futures" className="col-lg-2">
              Currency Futures:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={245}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_currency_futures"
                maxLength="100"
                readOnly={true}
              />
            </div>
            <label htmlFor="transaction_charges_currency_options" className="col-lg-2">
              Currency Options:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={250}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_currency_options"
                maxLength="100"
                readOnly={true}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="transaction_charges_commodity" className="col-lg-2">
              Commodity:
            </label>
            <div className="col-lg-4">
              <Field tabIndex={255}
                as="input"
                className="form-control"
                type="text"
                name="transaction_charges_commodity"
                maxLength="100"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Investment Options</legend>
          <hr />

          <div className="mb-3 row">
            <label htmlFor="inv_options_stock" className="col-lg-2">
              Stock
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_stock" className="form-select" tabIndex={260}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>


            <label htmlFor="inv_options_debt" className="col-lg-2">
              Debt
            </label>

            <div className="col-lg-2">
              <Field as="select" name="inv_options_debt" className="form-select" tabIndex={265}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>

          </div>

          <div className="mb-3 row">
            <label htmlFor="inv_options_ipo" className="col-lg-2">
              IPO
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_ipo" className="form-select" tabIndex={270}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>

            <label htmlFor="inv_options_mutual_fund" className="col-lg-2">
              Mutual Fund
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_mutual_fund" className="form-select" tabIndex={275}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="inv_options_bonds" className="col-lg-2">
              Bonds (NCD)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_bonds" className="form-select" tabIndex={280}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="inv_options_commodity" className="col-lg-2">
              Commodity
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_commodity" className="form-select" tabIndex={285}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="inv_options_currency" className="col-lg-2">
              Currency
            </label>
            <div className="col-lg-2">
              <Field as="select" name="inv_options_currency" className="form-select" tabIndex={290}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="inv_options_others" className="col-lg-2">
              Others
            </label>
            <div className="col-lg-8">
              <Field tabIndex={295}
                as="input"
                className="form-control"
                type="text"
                name="inv_options_others"
                maxLength="100"
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Features</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="features_portfolio" className="col-lg-2">
              Portfolio
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_portfolio" className="form-select" tabIndex={300}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="features_charting" className="col-lg-2">
              Charting
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_charting" className="form-select" tabIndex={305}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_demo" className="col-lg-2">
              Demo
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_demo" className="form-select" tabIndex={310}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_3in1_account" className="col-lg-2">
              3-in-1 Account
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_3in1_account" className="form-select" tabIndex={315}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="features_automated_trading" className="col-lg-2">
              Trading API
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_automated_trading" className="form-select" tabIndex={320}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_sms_alerts" className="col-lg-2">
              SMS Alerts
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_sms_alerts" className="form-select" tabIndex={325}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="feature_margin_trading_funding" className="col-lg-2">
              Margin Funding
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_margin_trading_funding" className="form-select" tabIndex={330}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="feature_nri_trading" className="col-lg-2">
              NRI Trading
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_nri_trading" className="form-select" tabIndex={335}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="features_direct_mutual_fund" className="col-lg-2">
              Direct Mutual Fund
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_direct_mutual_fund" className="form-select" tabIndex={340}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="demat_account_mandatory" className="col-lg-2">
              Demat Account Mandatory?
            </label>
            <div className="col-lg-2">
              <Field as="select" name="demat_account_mandatory" className="form-select" tabIndex={345}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="features_intraday_square_off_time" className="col-lg-2">
              Intraday Sq-off Time
            </label>
            <div className="col-lg-2">
              <Field tabIndex={350}
                as="input"
                className="form-control"
                type="text"
                name="features_intraday_square_off_time"
                maxLength="100"
              />
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_lifetime_free_amc" className="col-lg-2">
              LifeTime Free AMC
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_lifetime_free_amc" className="form-select" tabIndex={355}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="feature_trading_in_sme_shares" className="col-lg-2">
              Trading in sme shares
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_trading_in_sme_shares" className="form-select" tabIndex={360}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_trading_platform" className="col-lg-2">
              Trading Platform
            </label>
            <div className="col-lg-8">
              <Field tabIndex={365}
                as="input"
                className="form-control"
                type="text"
                name="features_trading_platform"
                maxLength="100"
              />
            </div>
            <div className="col-lg-2">(i.e. NEST, NOW)</div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="features_others" className="col-lg-2">
              Other Features
            </label>
            <div className="col-lg-8">
              <Field tabIndex={370}
                as="input"
                className="form-control"
                type="text"
                name="features_others"
                maxLength="100"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="feature_margin_against_shares_eq_cash" className="col-lg-2">
              Margin Against Shares (Equity Cash)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_margin_against_shares_eq_cash" className="form-select" tabIndex={375}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="feature_margin_against_shares_eq_fo" className="col-lg-2">
              Margin Against Shares (F&O)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_margin_against_shares_eq_fo" className="form-select" tabIndex={380}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="feature_free_equity_delivery" className="col-lg-2">
              Brokerage Free Equity Delivery Trades
            </label>
            <div className="col-lg-2">
              <Field as="select" name="feature_free_equity_delivery" className="form-select" tabIndex={385}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2"></div>
            <label htmlFor="features_unlimited_monthly_trading_plan" className="col-lg-2">
              Unlimited Monthly Trading Plan
            </label>
            <div className="col-lg-2">
              <Field as="select" name="features_unlimited_monthly_trading_plan" className="form-select" tabIndex={390}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">
              Referral Program
            </label>
            <div className="col-lg-2">
              <Field tabIndex={395}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="feature_referral_program"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('feature_referral_program', newValue);
                }}
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Customer Services</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="cust_service_24_7" className="col-lg-2">
              24x7 Suppport:
            </label>
            <div className="col-lg-2">
              <Field as="select" name="cust_service_24_7" className="form-select" tabIndex={400}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="cust_service_chat" className="col-lg-2">
              Chat:{" "}
            </label>
            <div className="col-lg-2">
              <Field as="select" name="cust_service_chat" className="form-select" tabIndex={405}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="cust_service_branches" className="col-lg-2">
              Branches
            </label>
            <div className="col-lg-2">
              <Field as="select" name="cust_service_branches" className="form-select" tabIndex={410}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="cust_service_phone_number" className="col-lg-2">
              Customer Care Number
            </label>
            <div className="col-lg-2">
              <Field tabIndex={415}
                as="input"
                className="form-control"
                type="text"
                name="cust_service_phone_number"
                maxLength="50"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Research</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="research_daily_market_report" className="col-lg-2">
              Daily Market Report
            </label>
            <div className="col-lg-2">
              <Field as="select" name="research_daily_market_report" className="form-select" tabIndex={425}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="research_free_tips" className="col-lg-2">
              Free Tips
            </label>
            <div className="col-lg-2">
              <Field as="select" name="research_free_tips" className="form-select" tabIndex={430}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="research_quarterly_results" className="col-lg-2">
              Quarterly_Results
            </label>
            <div className="col-lg-2">
              <Field as="select" name="research_quarterly_results" className="form-select" tabIndex={435}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="research_news_alerts" className="col-lg-2">
              News_Alerts
            </label>
            <div className="col-lg-2">
              <Field as="select" name="research_news_alerts" className="form-select" tabIndex={440}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Investor Category</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">
              Casual
            </label>
            <div className="col-lg-1">
              <Field tabIndex={445}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="investor_category_casual"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('investor_category_casual', newValue);
                }}
              />
            </div>
            <label htmlFor="investor_category_active" className="col-lg-2">
              Active
            </label>
            <div className="col-lg-1">
              <Field tabIndex={450}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="investor_category_active"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('investor_category_active', newValue);
                }}
              />
            </div>
            <label htmlFor="investor_category_trader" className="col-lg-2">
              Trader
            </label>
            <div className="col-lg-1">
              <Field tabIndex={455}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="investor_category_trader"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('investor_category_trader', newValue);
                }}
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Order Type</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="order_type_bo" className="col-lg-2">
              Bracket Order (BO)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_bo" className="form-select" tabIndex={460}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="order_type_co" className="col-lg-2">
              Cover Order (CO)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_co" className="form-select" tabIndex={465}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="order_type_btst" className="col-lg-2">
              Buy Today Sell Tomorrow (BTST)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_btst" className="form-select" tabIndex={470}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="order_type_stbt" className="col-lg-2">
              Sell Today Buy Tomorrow (STBT)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_stbt" className="form-select" tabIndex={475}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="order_type_amo" className="col-lg-2">
              After Market Order (AMO)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_amo" className="form-select" tabIndex={480}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
            <label htmlFor="order_type_gtc" className="col-lg-2">
              Good Till Cancelled (GTC)
            </label>
            <div className="col-lg-2">
              <Field as="select" name="order_type_gtc" className="form-select" tabIndex={485}>
                <option></option>
                {result.optionList !== undefined && result.optionList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Risk</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="risk_prop_trading" className="col-lg-2">
              Propritory Trading?
            </label>
            <div className="col-lg-1">
              <Field tabIndex={490}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="risk_prop_trading"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('risk_prop_trading', newValue);
                }}
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Partner Account</legend>
          <hr />

          <div className="mb-3 row">
            <label htmlFor="referral_link" className="col-lg-2">
              Referral Link
            </label>
            <div className="col-lg-4">
              <Field tabIndex={495}
                as="input"
                className="form-control"
                type="text"
                name="referral_link"
                maxLength="500"
              />
            </div>
            <label htmlFor="referral_link_bitly" className="col-lg-2">
              Referral Link Bitly
            </label>
            <div className="col-lg-4">
              <Field tabIndex={500}
                as="input"
                className="form-control"
                type="text"
                name="referral_link_bitly"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="referral_link_nri" className="col-lg-2">
              Referral Link (NRI)
            </label>
            <div className="col-lg-4">
              <Field tabIndex={505}
                as="input"
                className="form-control"
                type="text"
                name="referral_link_nri"
              />
            </div>
            <label htmlFor="referral_link_nri_bitly" className="col-lg-2">
              Referral Link Bitly (NRI)
            </label>
            <div className="col-lg-4">
              <Field tabIndex={510}
                as="input"
                className="form-control"
                type="text"
                name="referral_link_nri_bitly"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>API Details</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="api_trading_fee" className="col-lg-2">
              Trading API Price
            </label>
            <div className="col-lg-4">
              <Field tabIndex={515}
                as="input"
                className="form-control"
                type="text"
                name="api_trading_fee"
              />
            </div>

            <label htmlFor="api_historic_fee" className="col-lg-2">
              Historic API Price
            </label>
            <div className="col-lg-4">
              <Field tabIndex={520}
                as="input"
                className="form-control"
                type="text"
                name="api_historic_fee"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Detail Text (HTML)</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="reviews" className="col-lg-2">
              Reviews (Comparison Page)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="reviews"
                type="textarea"
                tabIndex="525"
                rows="5"
                className="form-control"
              />

            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="pros" className="col-lg-2">
              Pros (Comparison Page)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="pros"
                type="textarea"
                tabIndex="530"
                rows="5"
                cols="50"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="cons" className="col-lg-2">
              Cons (Comparison Page)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="cons"
                type="textarea"
                tabIndex="535"
                rows="5"
                cols="50"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="promotions_offers_brief" className="col-lg-2">
              Promotions Offers (Brief)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="promotions_offers_brief"
                type="textarea"
                tabIndex="540"
                rows="3"
                cols="50"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="promotions_offers" className="col-lg-2">
              Promotions Offers
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="promotions_offers"
                type="textarea"
                tabIndex="545"
                rows="5"
                cols="50"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="demat_charges" className="col-lg-2">
              Demat Charges (Use Demat Tab)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="demat_charges"
                type="textarea"
                tabIndex="550"
                rows="5"
                cols="50"
                className="form-control"
                readOnly={true}
              />

              <p className="text-danger">
                Readonly field, auto fill from demat charges tabs (structure
                in config){" "}
              </p>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="usp" className="col-lg-2">
              Unique Selling Proposition (USP) (Main Review Page)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="usp"
                type="textarea"
                tabIndex="555"
                rows="5"
                cols="50"
                className="form-control"
              />

            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="news_alert" className="col-lg-2">
              News Alert (Main Review Page)
            </label>
            <div className="col-lg-10">
              <CharacterCountTextarea
                nameattr="news_alert"
                type="textarea"
                tabIndex="560"
                rows="5"
                cols="50"
                className="form-control"
              />

            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Display</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="display_order" className="col-lg-2">
              Display Order
            </label>
            <div className="col-lg-4">
              <NumberInput classNameAtr="form-control" tabIndexAtr={565} maxLength="5" nameAtr="display_order" />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="compare_display_status" className="col-lg-2">
              Show on Comparison Page?
            </label>
            <div className="col-lg-4">
              <Field tabIndex={570}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="compare_display_status"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('compare_display_status', newValue);
                }}
              />
            </div>
            <label htmlFor="display_status" className="col-lg-2">
              Display Status
            </label>
            <div className="col-lg-4">
              <Field tabIndex={575}
                as="input"
                className="form-check-input"
                type="checkbox"
                name="display_status"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('display_status', newValue);
                }}
              />
            </div>
          </div>
        </fieldset>

        <div className="mb-3 text-center">
          {result != undefined && result.tradingCompany != undefined && result.tradingCompany[0].lock_status !== 1 && (
            <button type="submit" className="btn btn-primary btn-block" tabIndex={580}>
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              Update
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            tabIndex={585}
            onClick={handleGoBack}
          >
            Cancel
          </button>
        </div>

        <ValidateFormFields />
      </Form>
    </>
  );
};

export default TradingCompanyDetailForm;
