import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import { funCreateSlug } from '../Common/CommonFunction';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';


const FaqForm = ({ result, handleGoBack, loading, initialValues }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.faq !== undefined && result.faq.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.faq[0].faq_id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="faq_category_id" className="col-lg-2">Category</label>
                    <div className="col-lg-4">
                        <Field as="select" name="faq_category_id" className="form-select" tabIndex={1}>
                            <option></option>
                            {result.categoryList !== undefined && result.categoryList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.cat_name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="faq_question" className="col-lg-2">Question (Text)</label>
                    <div className="col-lg-4">
                        <Field type='text' className="form-control" name="faq_question" tabIndex={5} maxLength="225"
                            onBlur={(e) => {
                                const text = e.target.value;
                                if (text !== "") {
                                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                    if (userConfirmed) {
                                        var slug = funCreateSlug(text);
                                        setFieldValue('urlrewrite_folder_name', slug);
                                    } else {
                                        setFieldTouched('faq_question', true);
                                    }
                                }
                            }}
                        />
                    </div>

                    <label htmlFor="urlrewrite_folder_name required" className="col-lg-2">URLRewrite_Folder_Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type="text" name="urlrewrite_folder_name" maxLength="100" tabIndex={10} />
                    </div>
                </div>
                <hr />
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="image_name" className="col-lg-2">Image Name</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="text" maxLength="100" name="image_name" tabIndex={15} />
                    </div>
                    <label htmlFor="image_width" className="col-lg-1">Width</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} type="text" size="5" nameAtr={"image_width"} tabIndex={20} />
                    </div>
                    <label htmlFor="image_height" className="col-lg-1">Height</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} type="text" size="5" nameAtr={"image_height"} tabIndex={25} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="faq_answer" className="col-lg-2">Answer (HTML)</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="faq_answer"
                            type="textarea"
                            tabIndex="30"
                            rows="15"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="faq_order" className="col-lg-2">Display Order</label>
                    <div className="col-lg-4">
                        <NumberInput classNameAtr={"form-control"} type="textbox" nameAtr={"faq_order"} size="4" tabIndex={35} />
                    </div>

                </div>
                <div className="mb-3 row">
                    <label htmlFor="faq_is_approved" className="col-lg-2">Dislay Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input ms-3" type="checkbox" name="faq_is_approved" tabIndex={40} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('faq_is_approved', newValue);
                        }} />
                    </div>

                    <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input ms-3" type="checkbox" name="lock_status" tabIndex={45} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('lock_status', newValue);
                        }} />
                    </div>

                </div>
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={50}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.faq !== undefined && result.faq.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={55}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default FaqForm
