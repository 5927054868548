import React from 'react';
import { useField, useFormikContext } from 'formik';
import { isValidVariable, validateCustomHtml } from './CommonFunction';

const DynamicCharacterCountInput = ({ nameattr, maxLength = 99999, placeholderattr, ...props }) => {
  const [field, meta, helpers] = useField(nameattr);
  const formik = useFormikContext();

  const fieldValue = isValidVariable(field.value) ? field.value : '';

  const handleChange = (e) => {
    field.onChange(e);
  };

  const getColor = () => {
    if (fieldValue.length >= maxLength) return 'red';
    if (fieldValue.length >= maxLength * 0.8) return 'orange';
    return '#6c757d';
  };

  const validateAndSanitizeHtml = (e) => {
    let htmlText = validateCustomHtml(e.target.value);
    if (htmlText) {
      console.log("HTML is valid and sanitized");
      formik.setFieldValue(nameattr, htmlText);
    }
  };

  return (
    <div className="position-relative">
      <textarea
        {...field}
        {...props}
        name={nameattr}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholderattr}
        className="form-control"
        style={{ paddingRight: '3rem' }}
        onBlur={validateAndSanitizeHtml}
      ></textarea>
      <div
        className="position-absolute end-0 bottom-0 translate-middle-y pe-2"
        style={{ pointerEvents: 'none', color: getColor(), fontSize: '0.9rem' }}
      >
        {fieldValue.length}/{maxLength}
      </div>
    </div>
  );
};

export default DynamicCharacterCountInput;
