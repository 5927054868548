import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BuybackBiddingForm from './BuybackBiddingForm';

const BuybackBidding = () => {


    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        ipo_id: "",
        bidding_time: "",
        bid_date: "",
        company_name: "",
        qib_offered: "",
        nii_offered: "",
        nse_symbol: "",
        bse_scripcode: "",
        nii_offered_big: "",
        nii_offered_small: "",
        rii_offered: "",
        emp_offered: "",
        other_offered: "",
        total_offered: "",
        qib: "",
        nii: "",
        nii_big: "",
        nii_small: "",
        rii: "",
        emp: "",
        other: "",
        total: "",
        qib_shares_bid_for: "",
        nii_shares_bid_for: "",
        nii_shares_bid_for_big: "",
        nii_shares_bid_for_small: "",
        rii_shares_bid_for: "",
        emp_shares_bid_for: "",
        other_shares_bid_for: "",
        total_shares_bid_for: "",
        display_status: "",
        rii_by_application: "",
        total_application: "",
        no_of_application: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Buyback Bidding" })
            } else {
                setMetaData({ "title": "Edit Buyback Bidding" })
            }
            api.post('/admin-buybackbidding-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.buybackBidDetail);
                    if (!isAddMode) {
                        setInitialValues(response.data.buybackBidDetail[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-buybackbidding-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);

    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.buybackBidDetail !== undefined &&
                result.buybackBidDetail.length > 0 &&
                result.buybackBidDetail[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <br />

            <BuybackBiddingForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />



        </>
    )
}

export default BuybackBidding
