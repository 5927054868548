import React from 'react';
import { useField } from "formik";

const CheckboxGroup = ({ options, field, attrDisabled = false, className, tabIndexAttr, ...props }) => {
    const [field2, , helpers] = useField(field.name);

    const handleCheckboxChange = (value) => {
        const checkedValues = initialValuesArray;
        console.log(field2)
        const newCheckedValues = checkedValues.includes(value)
            ? checkedValues.filter((v) => v !== value)
            : [...checkedValues, value];
        helpers.setValue(newCheckedValues);
    };

    const initialValuesArray = field.value;

    return (
        <>
            {options.map((option) => (
                <div key={option.code} className='d-inline me-3'>
                    <input
                        className={className}
                        type="checkbox"
                        name={field.name}
                        value={option.code}
                        checked={initialValuesArray.includes(option.code)}
                        onChange={() => handleCheckboxChange(option.code)}
                        disabled={attrDisabled}
                        tabIndex={tabIndexAttr}
                    />
                    <label htmlFor={option.code}>{option.code}</label>
                </div>
            ))}
        </>
    );
};

export default CheckboxGroup;