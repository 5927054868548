import { Field, Form, Formik } from "formik";
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';
import * as Yup from 'yup';
import ValidateFormFields from "../Common/ValidateFormFields";

const CacheMasterForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {
    const validationSchema = Yup.object({
        category: Yup.string().trim().required("Category is required")
    });
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(handleSubmit)}
        >
            {({ setFieldValue }) => (

                <Form id='myForm'>
                    <>
                        {result !== undefined && result.cacheMaster !== undefined && result.cacheMaster.length > 0 &&
                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">ID:</label>
                                <div className="col-lg-4">
                                    {result.cacheMaster[0].id}
                                </div>
                            </div>
                        }
                        <div className="mb-3 row">

                            <label htmlFor="category" className="col-lg-2 required">Catergory</label>
                            <div className="col-lg-4">
                                <Field className="form-control" name="category" tabIndex={5} maxLength="50" />

                            </div>
                        </div >


                        <div className="mb-3 row">
                            <label htmlFor="cache_urls" className="col-lg-2 ">Cache Urls </label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInputText className="form-control" nameattr={"cache_urls"} tabIndex={10} rows="4" maxLength="2000" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="nodejs_urls" className="col-lg-2">NodeJS Urls </label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInputText className="form-control" nameattr={"nodejs_urls"} tabIndex={20} rows="4" maxLength="2000" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="redis_patterns" className="col-lg-2">Redis Patterns </label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInputText className="form-control" nameattr={"redis_patterns"} tabIndex={30} rows="4" maxLength="2000" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="extra_urls_to_clean" className="col-lg-2">Extra Url </label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInputText className="form-control" nameattr={"extra_urls_to_clean"} tabIndex={40} rows="4" maxLength="2000" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                            <div className="col-lg-4">
                                <Field className="form-check-input" type='checkbox' name="display_status" tabIndex={50} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue('display_status', newValue);
                                }} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                            <div className="col-lg-4">
                                <Field className="form-check-input" type='checkbox' name="lock_status" tabIndex={60} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue('lock_status', newValue);
                                }} />
                            </div>
                        </div>
                        <div className="mb-3 text-center">
                            {(
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={65}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        {result.cacheMaster !== undefined && result.cacheMaster.length > 0 ? 'Update' : 'Create'}
                                    </span>
                                </button>
                            )}

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={70}
                            >Cancel</button>
                        </div>
                    </>
                    <ValidateFormFields />

                </Form>
            )}
        </Formik>
    )
}

export default CacheMasterForm;