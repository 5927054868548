import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TradingCompanyTabs from './TradingCompanyTabs.js';
import { Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import PlanComponent from './PlanComponent';
import EtcChargesTable from './EtcChargesTable';
import { FieldArray } from 'formik';
import { funTrimNestedStrings } from '../Common/CommonFunction.js';

const validationSchema = Yup.object({});

const TradingCompanyPlans = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const validate = (e) => {
        for (let i = 0; i < e.tradingCompanyPlans.length; i++) {
            if (!e.tradingCompanyPlans[i].plan_name) {
                alert(`Plan Name ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        display_status: false,
        tradingCompanyPlans: [{
            id: "",
            trading_company_id: "",
            plan_name: "",
            plan_price: "",
            display_status: "",
            i_bro_min: "",
            i_bro_max: "",
            d_bro_min: "",
            d_bro_max: "",
            f_bro_min: "",
            f_bro_max: "",
            o_bro_min: "",
            o_bro_max: "",
            cf_bro_min: "",
            cf_bro_max: "",
            co_bro_min: "",
            co_bro_max: "",
            n_i_etc: "",
            b_i_etc: "",
            n_d_etc: "",
            b_d_etc: "",
            n_f_etc: "",
            b_f_etc: "",
            n_o_etc: "",
            b_o_etc: "",
            n_cf_etc: "",
            b_cf_etc: "",
            n_co_etc: "",
            b_co_etc: "",

        }],
        n_i_etc: "",
        b_i_etc: "",
        n_d_etc: "",
        b_d_etc: "",
        n_f_etc: "",
        b_f_etc: "",
        n_o_etc: "",
        b_o_etc: "",
        n_cf_etc: "",
        b_cf_etc: "",
        n_co_etc: "",
        b_co_etc: "",
        common_n_i_etc: "",
        common_n_d_etc: "",
        common_n_f_etc: "",
        common_n_o_etc: "",
        common_n_cf_etc: "",
        common_n_co_etc: "",
        common_b_i_etc: "",
        common_b_d_etc: "",
        common_b_f_etc: "",
        common_b_o_etc: "",
        common_b_cf_etc: "",
        common_b_co_etc: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/19");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Update Broker Plans" })

            api.post('/admin-tradingcompanyplans-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data);
                    if (!isAddMode) {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: response.data.id,
                            company_name: response.data.tradingCompany[0].company_name,
                            lock_status: response.data.tradingCompany[0].lock_status,
                            display_status: response.data.tradingCompany[0].display_status,
                            tradingCompanyPlans: response.data.tradingCompanyPlans,

                            n_i_etc: response.data.tradingCompanyPlans?.[0]?.n_i_etc ?? "",
                            n_d_etc: response.data.tradingCompanyPlans?.[0]?.n_d_etc ?? "",
                            n_f_etc: response.data.tradingCompanyPlans?.[0]?.n_f_etc ?? "",
                            n_o_etc: response.data.tradingCompanyPlans?.[0]?.n_o_etc ?? "",
                            n_cf_etc: response.data.tradingCompanyPlans?.[0]?.n_cf_etc ?? "",
                            n_co_etc: response.data.tradingCompanyPlans?.[0]?.n_co_etc ?? "",
                            b_i_etc: response.data.tradingCompanyPlans?.[0]?.b_i_etc ?? "",
                            b_d_etc: response.data.tradingCompanyPlans?.[0]?.b_d_etc ?? "",
                            b_f_etc: response.data.tradingCompanyPlans?.[0]?.b_f_etc ?? "",
                            b_o_etc: response.data.tradingCompanyPlans?.[0]?.b_o_etc ?? "",
                            b_cf_etc: response.data.tradingCompanyPlans?.[0]?.b_cf_etc ?? "",
                            b_co_etc: response.data.tradingCompanyPlans?.[0]?.b_co_etc ?? "",

                            common_n_i_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_i_etc ?? "",
                            common_n_d_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_d_etc ?? "",
                            common_n_f_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_f_etc ?? "",
                            common_n_o_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_o_etc ?? "",
                            common_n_cf_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_cf_etc ?? "",
                            common_n_co_etc: response.data.tradingCompanyCommonCharges?.[0]?.n_co_etc ?? "",
                            common_b_i_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_i_etc ?? "",
                            common_b_d_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_d_etc ?? "",
                            common_b_f_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_f_etc ?? "",
                            common_b_o_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_o_etc ?? "",
                            common_b_cf_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_cf_etc ?? "",
                            common_b_co_etc: response.data.tradingCompanyCommonCharges?.[0]?.b_co_etc ?? "",
                        }))
                        console.log("set");
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = funTrimNestedStrings(values);

        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());


            api.post('/admin-tradingcompanyplans-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/19");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        }
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <TradingCompanyTabs idAtr={result.id} />

            <h1>
                {metaData.title} (
                <span style={{ color: 'blue' }}>
                    {result.tradingCompany != undefined && result.tradingCompany.length > 0 && result.tradingCompany[0].company_name}
                </span>
                )
            </h1>
            <hr></hr>
            {result !== undefined &&
                result.tradingCompany !== undefined &&
                result.tradingCompany.length > 0 &&
                result.tradingCompany[0].lock_status == 1 && (
                    <h3 className="text-danger mb-3">
                        Record Is Locked , you can't update it.
                    </h3>
                )}


            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form id='myForm'>
                    <fieldset>
                        <div className="mt-3 row">
                            <label htmlFor="company_name" className="col-lg-2">Broker</label>
                            <div className="col-lg-4">
                                <Field type="text" className="form-control" readOnly={true} name="company_name" />
                            </div>
                        </div>
                    </fieldset>
                    <hr />
                    <fieldset id="repeater1">
                        <FieldArray
                            name="tradingCompanyPlans"
                            render={({ push, remove }) => (
                                <>
                                    <PlanComponent remove={remove} initialValues={initialValues} />
                                    <div className="text-end">
                                        {initialValues.lock_status !== 1 && (
                                            <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({
                                                id: "",
                                                trading_company_id: "",
                                                plan_name: "",
                                                plan_price: "",
                                                display_status: "",
                                                i_bro_min: "",
                                                i_bro_max: "",
                                                d_bro_min: "",
                                                d_bro_max: "",
                                                f_bro_min: "",
                                                f_bro_max: "",
                                                o_bro_min: "",
                                                o_bro_max: "",
                                                cf_bro_min: "",
                                                cf_bro_max: "",
                                                co_bro_min: "",
                                                co_bro_max: "",
                                                n_i_etc: "",
                                                b_i_etc: "",
                                                n_d_etc: "",
                                                b_d_etc: "",
                                                n_f_etc: "",
                                                b_f_etc: "",
                                                n_o_etc: "",
                                                b_o_etc: "",
                                                n_cf_etc: "",
                                                b_cf_etc: "",
                                                n_co_etc: "",
                                                b_co_etc: ""
                                            })}>
                                                Add +
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                        />
                    </fieldset>
                    <fieldset id="repeater1">
                        <EtcChargesTable result={result} />
                    </fieldset>
                    <div className="mb-5 text-center">
                        {initialValues.lock_status !== 1 && (
                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                <span>
                                    Update
                                </span>
                            </button>
                        )}

                        <button
                            type="button"
                            className="btn btn-secondary btn-block ms-2"
                            onClick={handleGoBack}
                        >Cancel</button>
                    </div>
                    <ValidateFormFields />
                </Form>
            </Formik>
        </>
    )
}

export default TradingCompanyPlans
