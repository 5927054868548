import { useFormikContext, Field } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberDecimalPercentageInput from '../Common/NumberDecimalPercentageInput';

const PlanComponent = ({ remove, initialValues }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            {values.tradingCompanyPlans.map((tradingCompanyPlan, index) => (
                <div key={index}>
                    <div className="mb-2 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.plan_name`} className="col-lg-2">Plan Name</label>
                        <div className="col-lg-4">
                            <Field className="form-control" type="text" name={`tradingCompanyPlans.${index}.plan_name`} maxLength="100" data-pattern-name="plan_name_++" data-pattern-id="plan_name_++" placeholder="Plan Name" />
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.plan_price`} className="col-lg-2">Price (Monthly Fixed)</label>
                        <div className="col-lg-2">
                            <NumberDecimalInput classNameAtr="form-control two-decimals" type="text" nameAtr={`tradingCompanyPlans.${index}.plan_price`} maxLength="7" data-pattern-name="plan_price_++" data-pattern-id="plan_price_++" placeholder="Plan Price" />
                        </div>
                    </div>
                    <br></br>
                    <h3>Brokerage Charges</h3>

                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.i_bro_min`} className="col-lg-2">Intraday BRO MIN12</label>
                        <div className="col-lg-2">
                            <NumberDecimalPercentageInput classNameAtr="form-control" nameAtr={`tradingCompanyPlans.${index}.i_bro_min`} maxLength="10" decimalPoint="6" placeholder="Intraday BRO MIN" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.i_bro_max`} className="col-lg-2">Intraday BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.i_bro_max`} maxLength="10" data-pattern-name="I_BRO_MAX_++" data-pattern-id="I_BRO_MAX_++" placeholder="Intraday BRO MAX" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.d_bro_min`} className="col-lg-2">Delivery BRO MIN</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.d_bro_min`} data-pattern-name="D_BRO_MIN_++" data-pattern-id="D_BRO_MIN_++" placeholder="Delivery BRO MIN"
                                maxLength="10" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.d_bro_max`} className="col-lg-2">Delivery BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.d_bro_max`} data-pattern-name="D_BRO_MAX_++" data-pattern-id="D_BRO_MAX_++" placeholder="Delivery BRO MAX"
                                maxLength="10" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.f_bro_min`} className="col-lg-2">Future BRO MIN</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.f_bro_min`} data-pattern-name="F_BRO_MIN_++" data-pattern-id="F_BRO_MIN_++" placeholder="Future BRO MIN"
                                maxLength="10" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.f_bro_max`} className="col-lg-2">Future BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.f_bro_max`} data-pattern-name="F_BRO_MAX_++" data-pattern-id="F_BRO_MAX_++" placeholder="Future BRO MAX"
                                maxLength="10" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.o_bro_min`} className="col-lg-2">Options BRO MIN</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.o_bro_min`} data-pattern-name="O_BRO_MIN_++" data-pattern-id="O_BRO_MIN_++" placeholder="Options BRO MIN"
                                maxLength="10" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.o_bro_max`} className="col-lg-2">Options BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.o_bro_max`} data-pattern-name="O_BRO_MAX_++" data-pattern-id="O_BRO_MAX_++" placeholder="Options BRO MAX"
                                maxLength="10" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.cf_bro_min`} className="col-lg-2">Currency Futures BRO MIN</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.cf_bro_min`} data-pattern-name="CF_BRO_MIN_++" data-pattern-id="CF_BRO_MIN_++" placeholder="Currency Futures BRO MIN"
                                maxLength="10" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.cf_bro_max`} className="col-lg-2">Currency Futures BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.cf_bro_max`} data-pattern-name="CF_BRO_MAX_++" data-pattern-id="CF_BRO_MAX_++" placeholder="Currency Futures BRO MAX"
                                maxLength="10" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.co_bro_min`} className="col-lg-2">Currency Options BRO MIN</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.co_bro_min`} data-pattern-name="CO_BRO_MIN_++" data-pattern-id="CO_BRO_MIN_++" placeholder="Currency Options BRO MIN"
                                maxLength="10" />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <label htmlFor={`tradingCompanyPlans.${index}.co_bro_max`} className="col-lg-2">Currency Options BRO MAX</label>
                        <div className="col-lg-2">
                            <Field className="form-control text_num_per" type="text" name={`tradingCompanyPlans.${index}.co_bro_max`} data-pattern-name="CO_BRO_MAX_++" data-pattern-id="CO_BRO_MAX_++" placeholder="Currency Options BRO MAX"
                                maxLength="10" />
                        </div>
                    </div>
                    <div className="text-end my-3">
                        {initialValues.lock_status !== 1 && (
                            <button type="button" className="r-btnRemove btn btn-danger btn-sm"
                                onClick={() => remove(index)}
                            ><i
                                className="fa fa-trash"></i></button>
                        )}
                    </div>
                    <div className="my-3 row">
                        <label htmlFor={`tradingCompanyPlans.${index}.display_status`} className="col-lg-2">Display Status</label>
                        <div className="col-lg-2">
                            <Field className="form-check-input" type="checkbox"
                                name={`tradingCompanyPlans.${index}.display_status`} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue(e.target.name, newValue);
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <hr />
                </div>
            ))}
        </>
    )
}

export default PlanComponent
