import React, { useState, useEffect, useRef, useCallback } from "react";
import '../Ipo/Ipo.css';
import ValidateFormFields from "../Common/ValidateFormFields";
import CheckboxGroup from '../Common/CheckboxGroup';
import { useFormikContext, Field, Form } from 'formik';
import NumberInput from '../Common/NumberInput';
import { funCreateSlug } from '../Common/CommonFunction';
import useApi from '../../../common/useApi';
import DynamicCharacterCountInput from '../Common/CharacterCountInput';
import AutocompleteField from "../Common/AutocompleteField";
import { debounce } from 'lodash';

const ForthcomingIPOForm = ({ result, handleGoBack, initialValues, loading, stockExchage, setStockExchage, getIpoPrice }) => {

    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    const [autoComplteOptions, setAutoComplteOptions] = useState([]);
    const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);
    const api = useApi();

    useEffect(() => {
        if (result.issueStockExchangeList != undefined && result.issueStockExchangeList.length > 0) {
            let arr = values.ipo_listing_at_array;
            if (values.issue_category == "Mainline") {
                setStockExchage(result.issueStockExchangeList.filter(item => item.code !== "BSE SME" && item.code !== "NSE SME"));
                if (arr?.includes("BSE SME")) {
                    arr = arr.filter(item => item !== "BSE SME");
                }
                if (arr?.includes("NSE SME")) {
                    arr = arr.filter(item => item !== "NSE SME");
                }
                setFieldValue('ipo_listing_at_array', arr)
            }
            else if (values.issue_category == "SME") {
                setStockExchage(result.issueStockExchangeList.filter(item => item.code !== "BSE" && item.code !== "NSE"));
                if (arr?.includes("BSE")) {
                    arr = arr.filter(item => item !== "BSE");
                }
                if (arr?.includes("NSE")) {
                    arr = arr.filter(item => item !== "NSE");
                }
                setFieldValue('ipo_listing_at_array', arr)
            }
        }
    }, [values.issue_category, result.issueStockExchangeList]);

    const handleAutoSearch = async (query) => {
        debouncedHandleAutoSearch(query);
    };

    const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
        console.log(query);
        setIsAutocomplteLoading(true);
        try {
            const response = await api.post('/admin-industry-list', { search: query });
            if (response.data.msg === 1) {

                const options = response.data.industrylist.map(item => ({
                    label: item.code_desc.trim(),
                    value: item.code
                }));
                setAutoComplteOptions(options);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsAutocomplteLoading(false);
        }
    }, 300), []);

    const handleAutoSelect = (selected, index) => {
        handleAutoBlur(selected, index);
    };

    const handleAutoBlurText = async (e, i) => {
        setFieldValue(`ipo_industry_name`, e.target.value);
    }

    const handleAutoBlur = async (selected, i) => {
        console.log("chk seleected : ", selected, i)
        const item = selected ? selected.value : null;
        const companyName = item ? selected.label : "";
        setFieldValue(`ipo_industry`, item ?? "");
        setFieldValue(`ipo_industry_name`, companyName ?? "");

    };

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);


    return (
        <>
            <Form id="myForm">
                {result !== undefined &&
                    result.ipo !== undefined &&
                    result.ipo.length > 0 && (
                        <div className="mb-3 row">
                            <label htmlFor="" className="col-lg-2">
                                ID:
                            </label>
                            <div className="col-lg-4">{result.ipo[0].id}</div>
                        </div>
                    )}

                <div className="my-3 row">
                    <label htmlFor="company_name" className="col-lg-2 required">
                        Company Name
                    </label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput
                            className="form-control"
                            type="text"
                            maxLength="100"
                            nameattr="company_name"
                            tabIndex={1}
                        />
                    </div>
                    <label htmlFor="natc" className="col-lg-2">Short Name (NATC)</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type="text" maxLength="50" name="natc" tabIndex={3} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="ipo_news_title" className="col-lg-2 required">
                        IPO News Title
                    </label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput
                            className="form-control"
                            type="text"
                            maxLength="50"
                            nameattr="ipo_news_title"
                            tabIndex={5}
                            onBlur={(e) => {
                                var news_title = e.target.value;
                                if (news_title !== "") {
                                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                    if (userConfirmed) {
                                        var slug = funCreateSlug(news_title)
                                        setFieldValue('urlrewrite_folder_name', slug);
                                    } else {
                                        setFieldTouched('ipo_news_title', true);
                                    }
                                }
                            }}
                        />
                    </div>
                    <label
                        htmlFor="urlrewrite_folder_name"
                        className="col-lg-2 required"
                    >
                        URLRewrite Folder Name
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            type="text"
                            maxLength="50"
                            name="urlrewrite_folder_name"
                            tabIndex={10}
                        />
                    </div>
                </div>

                <div className="mb-3 row">


                    <label htmlFor="ipo_industry_name" className="col-lg-2">
                        Industry
                    </label>
                    <div className="col-lg-4">
                        <Field
                            as={AutocompleteField}
                            id="ipo-industry"
                            options={autoComplteOptions}
                            onInputChange={(query) => handleAutoSearch(trimValue(query))}
                            onInputSelect={(selected) => handleAutoSelect(selected)}
                            onBlur={(e) => handleAutoBlurText(e)}
                            selected={result.ipo?.company_name ? [result?.ipo?.company_name] : []}
                            isAutocomplteLoading={isAutocomplteLoading}
                            nameatr="ipo_industry_name"
                            tabIndex={11}
                        />
                    </div>
                </div>

                <hr />
                <h3>IPO Detail</h3>

                <div className="my-3 row">

                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_category" className="col-lg-2 required">
                        Issue Category
                    </label>
                    <div className="col-lg-4">
                        {result.issueCategoryList !== undefined && result.issueCategoryList.map((option, index) => (
                            <div key={option.code} className="d-inline">
                                <Field className="form-check-input" type="radio" name="issue_category" tabIndex={15 + index} value={option.code} />&nbsp;{option.code} &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_type" className="col-lg-2 required">
                        Issue Type
                    </label>
                    <div className="col-lg-4">
                        {result.issueTypeList !== undefined && result.issueTypeList.map((option, index) => (
                            <div key={option.code} className="d-inline">
                                <Field className="form-check-input" type="radio" name="issue_type" tabIndex={20 + index} value={option.code} />&nbsp;{option.code} &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_process_type" className="col-lg-2 required">
                        Process Type
                    </label>
                    <div className="col-lg-4">
                        {result.issueProcessTypeList !== undefined && result.issueProcessTypeList.map((option, index) => (
                            <div key={option.code} className="d-inline">
                                <Field className="form-check-input" type="radio" name="issue_process_type" tabIndex={25 + index} value={option.code} />&nbsp;{option.code_desc} &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="offerType" className="col-lg-2 required">Offer Type : </label>
                    <div className="col-lg-10">

                        {result.offerTypeList !== undefined && result.offerTypeList.map((option, index) => (
                            <div key={option.code} className="d-inline">
                                <Field className="form-check-input" type="radio" name="offer_type" tabIndex={35 + index} value={option.code} />&nbsp;{option.code_desc} &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        ))}
                    </div>

                </div>

                <div className="mb-3 row">
                    <label htmlFor="ipo_listing_at_array" className="col-lg-2 required">
                        IPO Listing At
                    </label>
                    <div className="col-lg-4">
                        {stockExchage !== undefined && (
                            <Field
                                name="ipo_listing_at_array"
                                label="IPO Listing At"
                                component={CheckboxGroup}
                                options={stockExchage}
                                className='form-check-input Stock_Exchange_Type_Checkbox me-2'
                                tabIndexAttr={30}
                            />
                        )}
                    </div>
                </div>



                <hr />

                {/* Data Control Section */}

                <div className="row mb-3">
                    <label htmlFor="anchor_investor_status" className="col-lg-2">
                        Anchor Investor Participation
                    </label>

                    <div className="col-lg-6 d-flex">
                        <div className="form-check me-3">
                            <Field className="form-check-input" type="radio" name="anchor_investor_status" value="1" id="anchor_yes" tabIndex={40} />
                            <label className="form-check-label" htmlFor="anchor_yes">Yes</label>
                        </div>

                        <div className="form-check">
                            <Field
                                className="form-check-input" type="radio" name="anchor_investor_status" value="0" id="anchor_no" tabIndex={45} />
                            <label className="form-check-label" htmlFor="anchor_no"> No </label>
                        </div>
                    </div>
                </div>



                <div className="mb-3 row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h3>Firm Reservation</h3>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" tabIndex={50} name="firm_emp_status"
                                onChange={(e) => setFieldValue('firm_emp_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="firm_emp_status">Employee</label>
                        </div>

                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" tabIndex={55} name="mf_status"
                                onChange={(e) => setFieldValue('mf_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="mf_status">MF/FIs</label>
                        </div>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" name="market_makar_status" tabIndex={60}
                                onChange={(e) => setFieldValue('market_makar_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="market_makar_status">Market Maker</label>
                        </div>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" name="private_promotor_status" tabIndex={65}
                                onChange={(e) => setFieldValue('private_promotor_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="private_promotor_status">Private Promotor</label>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <h3>Preferential Reservation</h3>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" name="Preferential_emp_status" tabIndex={70}
                                onChange={(e) => setFieldValue('Preferential_emp_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="Preferential_emp_status">Employee</label>
                        </div>

                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" name="shareholders_status" tabIndex={75}
                                onChange={(e) => setFieldValue('shareholders_status', e.target.checked)} />
                            <label className="form-check-label ms-4" htmlFor="shareholders_status">Shareholders</label>
                        </div>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox" name="others_status"
                                onChange={(e) => setFieldValue('others_status', e.target.checked)} tabIndex={80} />
                            <label className="form-check-label ms-4" htmlFor="others_status">Others</label>
                        </div>
                    </div>
                </div>

                <hr />
                <h3>Document Links</h3>
                <div className="mb-3 row">
                    <label htmlFor="prospectus_drhp" className="col-lg-2">
                        DRHP Link
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            type="text"
                            maxLength="255"
                            name="prospectus_drhp"
                            tabIndex={90}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="addendum_drhp" className="col-lg-2">
                        Addendum DRHP Link
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            type="text"
                            size="55"
                            maxLength="255"
                            name="addendum_drhp"
                            tabIndex={95}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="prospectus_rhp" className="col-lg-2">
                        RHP Link
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            type="text"
                            size="55"
                            maxLength="255"
                            name="prospectus_rhp"
                            tabIndex={100}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="final_prospectus" className="col-lg-2">
                        Final Prospectus
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            type="text"
                            size="55"
                            maxLength="255"
                            name="final_prospectus"
                            tabIndex={105}
                        />
                    </div>
                </div>
                <hr />

                <div className="mb-3 row">
                    <label htmlFor="display_order" className="col-lg-2">
                        Display Order
                    </label>
                    <div className="col-lg-1">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={110} maxLength="4" nameAtr="display_order" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">
                        Display Status
                    </label>
                    <div className="col-lg-1">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            name="display_status"
                            tabIndex={115}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="ipo_confirmation_status" className="col-lg-2">
                        IPO Confirmation Status
                    </label>
                    <div className="col-lg-4">
                        <Field className="form-check-input me-2" onChange={(e) => { setFieldValue("ipo_confirmation_status", e.target.checked) }} type="checkbox" name="ipo_confirmation_status" tabIndex={116} />
                        (<b>Pre IPO DRHP Section</b>)
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="hidden_drhp_status" className="col-lg-2">
                        Hidden DRHP Status

                    </label>
                    <div className="col-lg-4">
                        <Field className="form-check-input me-2" onChange={(e) => { setFieldValue("hidden_drhp_status", e.target.checked) }} type="checkbox" name="hidden_drhp_status" tabIndex={117} />(<b>if DRHP not available)</b>)
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_withdraw" className="col-lg-2">
                        Issue Withdraw
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-check-input me-2"
                            type="checkbox"
                            name="issue_withdraw"
                            tabIndex={120}
                            onChange={(e) => { setFieldValue('issue_withdraw', e.target.checked) }}
                        />
                        (Check if issue is withdrawn from the market)
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="hide_temporary" className="col-lg-2">
                        Hide temporary
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-check-input me-2"
                            type="checkbox"
                            name="hide_temporary"
                            tabIndex={125}
                            onChange={(e) => { setFieldValue('hide_temporary', e.target.checked) }}
                        />
                        (Check if issue need to be hidden from list)
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="lock_status" className="col-lg-2">
                        Lock Status
                    </label>
                    <div className="col-lg-4">
                        <Field
                            className="form-check-input me-2"
                            type="checkbox"
                            name="lock_status"
                            tabIndex={130}
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }}
                        />
                    </div>
                </div>
                <hr />

                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={135}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.ipo !== undefined && result.ipo.length > 0
                                    ? "Update"
                                    : "Create"}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={140}
                    >
                        Cancel
                    </button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    );
};

export default ForthcomingIPOForm;
