import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import { Link } from 'react-router-dom';
import NumberInput from "../../admintool9x/Common/NumberInput";
import DynamicCharacterCountInputText from '../../admintool9x/Common/CharacterCountTextarea';

const IPOFaqAskQuestionForm = ({ result, btnClick, loading, recaptchaRef, registeredUser, isLoggedIn }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                <p>Ask a question and get answers from our expert team. Please make sure to check posted FAQ's before asking a question.</p>

                <div className="mb-3 row">
                    <label htmlFor="faq_question" className="col-sm-2"><b>Your Question:</b></label>
                    <div className="col-sm-8">
                        <DynamicCharacterCountInputText maxLength={1000} className="form-control" rows="5" nameattr={"faq_question"} tabIndex={10} placeholderattr="Your Question" />
                    </div>
                </div>

                {!isLoggedIn &&
                    <>
                        <div className="mb-2 row">
                            <label htmlFor="faq_added_by_phone" className="col-sm-2">
                                Phone
                            </label>
                            <div className="col-sm-8">
                                <NumberInput maxLength={12} classNameAtr="form-control" tabIndexAtr={15} nameAtr="faq_added_by_phone" placeholderAtr="Your Phone Number" />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label htmlFor="faq_added_by_email" className="col-sm-2">
                                Email
                            </label>
                            <div className="col-sm-8">
                                <Field
                                    type="text"
                                    tabIndex={20}
                                    maxLength={50}
                                    name="faq_added_by_email"
                                    placeholder="Your Email Address"
                                    className="form-control"
                                />
                            </div>
                        </div>

                    </>
                }

                <div className="mb-2 row">
                    <label htmlFor="" className="col-md-2">
                        Verify Code
                    </label>
                    <div className="col-md-3">
                        <span id="divLoading">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                tabIndex={35}
                                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                onChange={(value) => setFieldValue('recaptcha', value)}
                            />
                        </span>
                    </div>
                </div>


                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={45}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Submit
                        </span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={btnClick}
                        tabIndex={50}
                    >close</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default IPOFaqAskQuestionForm
