import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import IpoTabs from "../IPONew/IPONewPageTabs";
import { finishLoading, selectLoading, startLoading } from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../common/useApi";
import { Formik } from "formik";
import IpoQibAnchorMasterForm from "./IpoQibAnchorMasterForm";

const IpoQibAnchorMaster = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });

    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        id: "",
        ipocompany: [
            {
                ipo_id: "",
                company_name: "",
                lock_status: false
            }
        ],
        jsonInput: "",
        ipoQibAnchorData: [
            {
                code: "",
                code_desc: "",
                ipo_id: "",
                anchor_value: "",
                qib_value: "",
                total: ""
            }
        ]
    });
    const validate = (e) => {
        // for (let i = 0; i < e.ipoQibAnchorData.length; i++) {
        //     if (!e.ipoQibAnchorData[i].qib_value) {
        //         alert(`Excluding Anchot at index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        //     if (!e.ipoQibAnchorData[i].anchor_value) {
        //         alert(`Anchor At index ${i + 1} cannot be blank`);
        //         return false;
        //     }
        // }
        return true;
    }
    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Edit Ipo Bidding" })

            api.post('/admin-ipo-qib-anchor-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setInitialValues(response.data);
                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        ipocompany: response.data.ipocompany,
                        ipoQibAnchorData: response.data.ipoQibAnchorData
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {

                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (validate(trimmedValues)) {
            dispatch(startLoading());
            trimmedValues.id = id;
            api.post('/admin-ipo-qib-anchor-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    // navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again


            setSubmitting(false);
        }

    };
    return (
        <>
            <IpoTabs idAtr={result.id} ipo_confirm={result?.ipocompany?.[0]?.ipo_confirmation_status}/>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <IpoQibAnchorMasterForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} />
            </Formik>
        </>
    )
}
export default IpoQibAnchorMaster;