import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import NumberInput from '../Common/NumberInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';
import FileUpload from '../Common/FileUpload';
import { setMessage } from '../../../state/slices/message';

const validationSchema = Yup.object({
  comp_name: Yup.string().trim().required('Please enter Company Name'),
  urlrewrite_folder_name: Yup.string().trim().required('Please enter URL Rewrite Folder Name'),
  comp_short_name: Yup.string().trim().required('Please enter Short Name'),
  logo_url: Yup.string().trim().max(200, 'Company Logo URL must be at most 200 characters'),
  // phone: Yup.string().trim().required('Please enter Phone Number'),
  fax: Yup.string().trim().max(50, 'Company Fax must be at most 50 characters'),
  website: Yup.string().trim().url('Please enter a valid URL for Company Website'),
  email: Yup.string().trim().email('Please enter a valid Email Address')
});

const IPOAnchorForm = ({ result, handleGoBack, initialValues, handleSubmit, loading, setResult, api, dispatch }) => {

  const populateCityList = (e) => {
    let state_id = e;
    api.post('/cityList-read', {
      state_id: state_id
    }).then(response => {
      if (response.data.msg === 1) {
        setResult((prevValues) => ({
          ...prevValues,
          cityList: response.data.cityList
        }));
      } else {
        dispatch(setMessage(response.data.error));
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched}) => (

          <Form id='myForm'>
            {result !== undefined && result.ipomanager !== undefined && result.ipomanager.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="" className="col-lg-2">ID:</label>
                <div className="col-lg-4">
                  {result.ipomanager[0].id}
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="comp_name" className="col-lg-2 required">Company Name</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="100" tabIndex={5} name="comp_name" id="Comp_Name" />
              </div>
              <label htmlFor="anchor_investor_type" className="col-lg-2 required">Anchor Investor Type</label>
              <div className="col-lg-4">
                <Field as="select" className="form-select" tabIndex={15} name="anchor_investor_type">
                  <option></option>
                  {result && result.anchorInvestorType && result.anchorInvestorType.length !== 0 && result.anchorInvestorType.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="comp_short_name" className="col-lg-2 required">Company Short Name</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="100" tabIndex={10} name="comp_short_name" onBlur={(e) => {
                  const text = e.target.value;
                  if (text !== "") {
                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                    if (userConfirmed) {
                      var slug = funCreateSlug(text);
                      setFieldValue('urlrewrite_folder_name', slug);
                    } else {
                      setFieldTouched('Comp_Short_Name', true);
                    }
                  }
                }} />
              </div>
              <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewriteFolderName</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="50" tabIndex={15} name="urlrewrite_folder_name" id="URLRewrite_Folder_Name" />
              </div>
            </div>
            <hr />
            <div className='mb-3 row'>
              <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
              <div className="col-lg-10">
                <FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="logo_url" className="col-lg-2">Logo URL</label>
              <div className="col-lg-3">
                <Field className="form-control" type="text" maxLength="200" tabIndex={20} name="logo_url" id="Logo_URL" />
              </div>
              <label htmlFor="logo_width" className="col-lg-2">Width</label>
              <div className="col-lg-1">
                <NumberInput classNameAtr={"form-control"} maxLength={"3"} tabIndex={25} nameAtr={"logo_width"} id="Logo_Width" />
              </div>
              <label htmlFor="logo_height" className="col-lg-2">Height</label>
              <div className="col-lg-1">
                <NumberInput classNameAtr={"form-control"} maxLength={"3"} tabIndex={30} nameAtr={"logo_height"} id="Logo_Width" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="address_1" className="col-lg-2">Address Line 1</label>
              <div className="col-lg-8">
                <Field className="form-control" type="text" maxLength="50" tabIndex={35} name="address_1" id="Address_1" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="address_2" className="col-lg-2">Address Line 2</label>
              <div className="col-lg-8">
                <Field className="form-control" type="text" maxLength="50" tabIndex={40} name="address_2" id="Address_2" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="address_3" className="col-lg-2">Address Line 3</label>
              <div className="col-lg-8">
                <Field className="form-control" type="text" maxLength="50" tabIndex={45} name="address_3" id="Address_3" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="state_id" className="col-lg-2">State</label>
              <div className="col-lg-4">
                <Field as="select" name="state_id" className="form-select" tabIndex={50} 
                onChange={(e) => {
                  let stateId = e.target.value;
                  setFieldValue('state_id', stateId);
                  if(stateId == "") setFieldValue("city_id", "");
                  else populateCityList(stateId);
                }}
                >
                  <option value=""></option>
                  {result.stateList !== undefined && result.stateList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Field>
              </div>
              <label htmlFor="city_id" className="col-lg-2">City</label>
              <div className="col-lg-4">
                <Field as="select" name="city_id" className="form-select" tabIndex={55}>
                  <option value=""></option>
                  {result && result.cityList && result.cityList.length !== 0 && result.cityList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="zip_code" className="col-lg-2">Zip Code</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="10" name="zip_code" tabIndex={55} />
              </div>
              <label htmlFor="registration_number" className="col-lg-2">Registration </label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="50" tabIndex={56} name="registration_number" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="phone" className="col-lg-2">Phone</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="50" tabIndex={60} name="phone" id="Phone"
                  onKeyUp={(e) => {
                    const value = e.target.value;
                    let newValue = value.replace(/,/g, '');
                    newValue = newValue.replace(/[^0-9+]/g, '');
                    setFieldValue('phone', newValue);
                  }} />
              </div>
              <label htmlFor="fax" className="col-lg-2">Fax</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="50" tabIndex={65} name="fax" id="Fax"
                  onKeyUp={(e) => {
                    const value = e.target.value;
                    let newValue = value.replace(/,/g, '');
                    newValue = newValue.replace(/[^0-9+]/g, '');
                    setFieldValue('fax', newValue);
                  }} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="website" className="col-lg-2">Website</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="100" tabIndex={70} name="website" id="Website" />
              </div>
              <label htmlFor="email" className="col-lg-2">Email</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="50" tabIndex={75} name="email" id="Email" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="comp_desc" className="col-lg-2">Description (HTML)</label>
              <div className="col-lg-10">
                <CharacterCountTextarea
                  nameattr="comp_desc"
                  type="textarea"
                  tabIndex={76}
                  rows="10"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="display_order" className="col-lg-2">Display Order</label>
              <div className="col-lg-2">
                <Field className="form-control" type="number" maxLength="5" tabIndex={80} name="display_order" id="Display_Order" />
              </div>
              <label htmlFor="display_status" className="col-lg-2">Display Status</label>
              <div className="col-lg-2">
                <Field className="form-check-input" type="checkbox" tabIndex={85} name="display_status" id="Display_Status" onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('display_status', newValue);
                }} />
              </div>
              <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
              <div className="col-lg-2">
                <Field className="form-check-input" type="checkbox" tabIndex={90} name="lock_status" onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('lock_status', newValue);
                }} />
              </div>
            </div>

            <div className="mb-3 text-center">
              {!initialValues.lock_status == 1 &&
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={95}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.anchorInvestor !== undefined && result.anchorInvestor.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              }

              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={100}
              >Cancel</button>
            </div>
            <ValidateFormFields />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default IPOAnchorForm
