import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';

const IPORecommendationDetailForm = ({ result, handleGoBack, loading }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">IPO Name</label>
                    <div className="col-lg-4">
                        <b>{values.company_name}</b>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Member Name</label>
                    <div className="col-lg-4">
                        <b>{values.display_name}</b>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="recommendation" className="col-lg-2">Your recommendation:<font color="red">*</font></label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select form-select-sm" tabIndex={1} name="recommendation">
                            <option ></option>
                            {result.recommendationTypeLists !== undefined && result.recommendationTypeLists.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="hold_position" className="col-lg-2">Hold Position:</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select form-select-sm" tabIndex={5} name="hold_position">
                            <option ></option>
                            {result.recommendationHoldLists !== undefined && result.recommendationHoldLists.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="subscribe_at_price_band" className="col-lg-2">Subscribe at Price Band:</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select form-select-sm" tabIndex={10} name="subscribe_at_price_band">
                            <option ></option>
                            {result.recommendationSubscribeLists !== undefined && result.recommendationSubscribeLists.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="recommendation_desc" className="col-lg-2">Recommendation Desc</label>
                    <div className="col-lg-4">
                        <DynamicCharacterCountInput rows="5" cols="50" className="form-control" nameattr={"recommendation_desc"} maxlength="500" tabIndex={15} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">Dislay Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={20}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-12">
                        <p className="mb-0">Posted Date : {values.posted_date}</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-12">
                        <p>Last Updated Date: {values.last_updated_date}</p>
                    </div>
                </div>


                <div className="mb-3 text-center">
                    {result.iporecommendation !== undefined && result.iporecommendation.length > 0 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={25}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>Update</span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={30}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default IPORecommendationDetailForm
