import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingCompanyDetailForm from './TradingCompanyDetailForm.js';
import * as Yup from "yup";
import { Formik } from 'formik';
import TradingCompanyTabs from './TradingCompanyTabs.js';


const validationSchema = Yup.object({
    display_order: Yup.string().required("Please Enter Display Order"),
    service_type: Yup.string().required("Please Define service type"),
});

const TradingCompanyDetail = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        service_type: "",
        exchange_supported: "",
        fees_account_opening: "",
        amc_brokerage_account: "",
        fees_demat_opening: "",
        amc_demat_account: "",
        demat_account_mandatory: "",
        account_type: "",
        brokerage_flat_monthly_fee: "",
        brokerage_equity_delivery: "",
        brokerage_equity_intraday: "",
        brokerage_equity_future: "",
        brokerage_equity_options: "",
        brokerage_currency_futures: "",
        brokerage_currency_options: "",
        brokerage_commodity_future_1: "",
        brokerage_commodity_option_1: "",
        account_type_2: "",
        brokerage_flat_monthly_fee_2: "",
        brokerage_equity_delivery_2: "",
        brokerage_equity_intraday_2: "",
        brokerage_equity_future_2: "",
        brokerage_equity_options_2: "",
        brokerage_currency_futures_2: "",
        brokerage_currency_options_2: "",
        brokerage_commodity_future_2: "",
        brokerage_commodity_option_2: "",
        account_type_3: "",
        brokerage_flat_monthly_fee_3: "",
        brokerage_equity_delivery_3: "",
        brokerage_equity_intraday_3: "",
        brokerage_equity_future_3: "",
        brokerage_equity_options_3: "",
        brokerage_currency_futures_3: "",
        brokerage_currency_options_3: "",
        brokerage_commodity_future_3: "",
        brokerage_commodity_option_3: "",
        brokerage_others: "",
        brokerage_minimum: "",
        demat_debit_trans_charges: "",
        transaction_charges_equity_delivery: "",
        transaction_charges_equity_intraday: "",
        transaction_charges_equity_futures: "",
        transaction_charges_equity_options: "",
        transaction_charges_currency_futures: "",
        transaction_charges_currency_options: "",
        transaction_charges_commodity: "",
        inv_options_stock: "",
        inv_options_debt: "",
        inv_options_ipo: "",
        inv_options_mutual_fund: "",
        inv_options_bonds: "",
        inv_options_commodity: "",
        inv_options_currency: "",
        inv_options_others: "",
        features_portfolio: "",
        features_charting: "",
        features_demo: "",
        features_3in1_account: "",
        features_automated_trading: "",
        features_sms_alerts: "",
        features_trading_platform: "",
        feature_margin_trading_funding: "",
        features_intraday_square_off_time: "",
        feature_nri_trading: "",
        features_direct_mutual_fund: "",
        features_lifetime_free_amc: "",
        feature_trading_in_sme_shares: "",
        features_others: "",
        feature_margin_against_shares_eq_cash: "",
        feature_margin_against_shares_eq_fo: "",
        feature_free_equity_delivery: "",
        features_unlimited_monthly_trading_plan: "",
        cust_service_24_7: "",
        cust_service_chat: "",
        cust_service_branches: "",
        cust_service_phone_number: "",
        research_daily_market_report: "",
        research_free_tips: "",
        research_quarterly_results: "",
        research_news_alerts: "",
        investor_category_casual: "",
        investor_category_active: "",
        investor_category_trader: "",
        risk_prop_trading: "",
        call_trade_charges: "",
        reviews: "",
        pros: "",
        cons: "",
        promotions_offers_brief: "",
        promotions_offers: "",
        demat_charges: "",
        display_order: "",
        compare_display_status: "",
        display_status: "",
        order_type_bo: "",
        order_type_co: "",
        order_type_btst: "",
        order_type_stbt: "",
        order_type_amo: "",
        order_type_gtc: "",
        news_alert: "",
        api_trading_fee: "",
        api_historic_fee: "",
        usp: "",
        year_of_incorporation: "",
        branch_count: "",
        ucc_count: "",
        ucc_changed: "",
        feature_referral_program: "",
        referral_link: "",
        referral_link_bitly: "",
        referral_link_nri: "",
        referral_link_nri_bitly: "",
        brokerage_margin_trading: "",
        inv_options_forex: "",
        cust_service_email: "",
        exposure_eq_delivery: "",
        exposure_eq_intraday: "",
        exposure_eq_futures: "",
        exposure_eq_options: "",
        exposure_curr_futures: "",
        exposure_curr_options: "",
        exposure_commodities_futures: "",
        exposure_commodities_options: "",
        mf_pg_desc: "",

    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/19");
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            
            setMetaData({ "title": "Update Trading Company Detail" })

            api.post('/admin-tradingcompanydetail-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    
                    if (!isAddMode) {
                        setInitialValues(response.data.tradingCompanyDetail[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        
        dispatch(startLoading());
        

        api.post('/admin-tradingcompanydetail-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/19");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
            
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <TradingCompanyTabs idAtr={result.id} />
            <h1>
                {metaData.title} (
                <span style={{ color: 'blue' }}>
                    {result.tradingCompany != undefined && result.tradingCompany[0].company_name}
                </span>
                )
            </h1>
            <hr/>

            {result.tradingCompany != undefined && result.tradingCompany &&
                result.tradingCompany.length > 0 &&
                result.tradingCompany[0].lock_status !== undefined &&
                result.tradingCompany[0].lock_status == 1 && (
                    <h3 className="text-danger mb-3">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <TradingCompanyDetailForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}

export default TradingCompanyDetail
