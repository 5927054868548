import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ContactUsForm from './ContactUsForm.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow, validateInput } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const ContactUs = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";

    const recaptchaRef = useRef(null);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const validationSchema = (user) => {
        return Yup.object({
            name: user ? "" : Yup.string().trim().required('Your Name is required'),
            email: user ? "" : Yup.string().trim().email('Invalid email address').required('Your Email is required'),
            phone: user ? "" : Yup.string().trim().matches(/^\d{10}$/, 'Phone number must be 10 digits').required('Your phone Number is required'),
            city: user ? "" : Yup.string().trim().required('Your city is required'),
            message: Yup.string().required('Message is required').min(3, 'Message must be at least 3 characters long'),
            recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
        });
    };

    const [initialValues, setInitialValues] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        recaptcha: '',
    });

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "Contact Us" });
        } else {
            didMountRef.current = "true";
        }
    }, [dispatch, api]);

    const handleSubmit = async (values, { setSubmitting }) => {
        if (!currentUser) {
            let phoneError = validateInput(values.phone, 'phoneNumber');
            if (phoneError) {
                alert(phoneError);
                setSubmitting(false);
                return;
            }
        }

        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;
        trimmedValues.referrer = document.referrer || location.state?.referrer || '';

        dispatch(startLoading());

        api.post('/contactus-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=contactus&ref=` + ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row main mt-2'>
                <div className='col-12'>
                    <h1>
                        Contact Us
                    </h1>
                    <hr />


                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema(currentUser)}
                        onSubmit={handleSubmit}
                    >
                        <ContactUsForm result={result} btnClick={btnClick} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} currentUser={currentUser} />
                    </Formik>
                </div>
            </div>

        </>
    )
}

export default ContactUs
