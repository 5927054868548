
import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';

const IpoAnchorInvestorForm = ({ remove, result }) => {
  const { setFieldValue, values } = useFormikContext();

  const calculateAnchorPortion = () => {
    const repeaterCount = values.ipoAnchorInvestor.length;
    let totalShares = 0;
    // Calculate totalShares
    for (let i = 0; i < repeaterCount; i++) {
      if (values.ipoAnchorInvestor[i].display_status) {
        const noOfShares = parseFloat(values.ipoAnchorInvestor[i].shares_allocated) || 0;
        totalShares += noOfShares;
      }
    }

    // Calculate and update percentage
    for (let i = 0; i < repeaterCount; i++) {
      if (values.ipoAnchorInvestor[i].display_status) {
        const noOfShares = parseFloat(values.ipoAnchorInvestor[i].shares_allocated) || 0;
        const percentage = (noOfShares / totalShares) * 100 || 0;
        setFieldValue(`ipoAnchorInvestor.[${i}].anchor_investor_portion`, percentage.toFixed(2));
      } else {
        setFieldValue(`ipoAnchorInvestor.[${i}].anchor_investor_portion`, '0');
      }
    }
  };

  const calculateTotalAmtAllocated = () => {
    const repeaterCount = values.ipoAnchorInvestor.length;

    for (let i = 0; i < repeaterCount; i++) {
      const noOfShares = parseFloat(values.ipoAnchorInvestor[i].shares_allocated) || 0;
      const bidPrice = parseFloat(values.ipoAnchorInvestor[i].bid_price) || 0;
      setFieldValue(`ipoAnchorInvestor.[${i}].total_amount_allotted`, (noOfShares * bidPrice).toFixed(2));
    }
  };

  useEffect(() => {
    calculateAnchorPortion();
    calculateTotalAmtAllocated();
  }, [values])

  let sr_no = 1;

  return (
    <>
      {values.ipoAnchorInvestor.map((anchor_investor, index) => (


        <tr key={index}>
          <td>
            <Field className="form-control" maxlength="3"
              value={sr_no++}
            />
          </td>
          <td>
            <Field as="select" name={`ipoAnchorInvestor.${index}.anchor_investor_id`} className="form-select">
              <option></option>
              {result.anchorInvestorList !== undefined && result.anchorInvestorList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </td>
          <td>
            <NumberInput classNameAtr="form-control" maxLength="10"
              nameAtr={`ipoAnchorInvestor.${index}.shares_allocated`} placeholder="Number of shares allocated"
            />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`ipoAnchorInvestor.${index}.bid_price`} placeholder="Bid price per share" />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`ipoAnchorInvestor.${index}.anchor_investor_portion`} placeholder="Anchor Investor Portion"
            />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`ipoAnchorInvestor.${index}.total_amount_allotted`} placeholder="Total Amount Allotted"
            />
          </td>

          <td className="text-center">
            <div className="form-check form-switch fs-4">
              <Field className="form-check-input" type="checkbox"
                name={`ipoAnchorInvestor.${index}.display_status`}
                onChange={(e) => { setFieldValue(e.target.name, e.target.checked) }}
              />
            </div>
          </td>

          <td>
            <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
              <i className="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      ))}
    </>
  )
}

export default IpoAnchorInvestorForm;
