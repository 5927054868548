import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import MonitoringMasterForm from './MonitoringMasterForm';
import * as Yup from 'yup';
import { Formik } from 'formik';

const MonitoringMaster = () => {

    const validationSchema = Yup.object({
        monitoring_name: Yup.string().required('Please enter monitoring name'),
        condition_cd: Yup.string().required('Please select monitoring condition.'),
        category_cd: Yup.string().required('Please select monitoring Category.'),
        notification_type: Yup.string().required('Please select notification type.'),
        sql_query: Yup.string().required('Please enter sql query.'),
        start_time: Yup.string().required('Please enter start time.'),
        end_time: Yup.string().required('Please enter end time.'),
    });
    function validate(values) {
        var errors = {};

        if ((values.category_cd === 'greater_count' || values.category_cd === 'less_than_count' || values.category_cd === 'equal_to') && !values.count_size) {
            errors.count_size = 'Please enter count size';
        }

        if (values.category_cd === 'json_insert' && !values.json_table_name) {
            errors.json_table_name = 'Please enter Table Name';
        }

        if (values.category_cd === 'json_insert' && !values.json_validate_column) {
            errors.json_validate_column = 'Please enter Validate/Unique Column Name';
        }

        if (values.category_cd === 'json_insert' && !values.json_url) {
            errors.json_url = 'Please enter JSON URL';
        }

        if (values.category_cd === 'select_column' && !values.columns_validate) {
            errors.columns_validate = 'Please enter Columns To Validate';
        }

        return errors;
    }
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        monitoring_name: "",
        category_cd: "",
        condition_cd: "",
        count_size: "",
        sql_query: "",
        notification_message: "",
        monitoring_desc: "",
        columns_validate: "",
        start_time: "",
        end_time: "",
        json_table_name: "",
        json_url: "",
        json_validate_column: "",
        display_status: false,
        run_on_holiday: false,
        notification_type: "",
        error_status: "0",
        json_min_day: 0,
        json_max_day: 0,
        lock_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New MonitoringMaster" })
            } else {
                setMetaData({ "title": "Edit MonitoringMaster" })
            }
            api.post('/admin-monitoringMaster-read', {
                id: id
            }).then(response => {
                console.log(response.data)
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.monitoringMaster);
                    if (!isAddMode) {
                        setInitialValues(response.data.monitoringMaster[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        
        var errors = validate(trimmedValues);
        if (Object.keys(errors).length != 0) {
            var erorrMessage = "";
            Object.entries(errors).map(([key, value]) => (
                erorrMessage += `<p className="mb-1"><strong>${key}: </strong> ${value}.</p>`
            ))
            dispatch(setMessage(erorrMessage));
            return;
        }
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());


        api.post('/admin-monitoringMaster-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.monitoringMaster !== undefined &&
                result.monitoringMaster.length > 0 &&
                result.monitoringMaster[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <MonitoringMasterForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />

            </Formik>
        </>
    )
}

export default MonitoringMaster
