import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import FaqForm from './FaqForm';
import { Formik } from 'formik';
import * as Yup from 'yup';


const Faq = () => {
    const validationSchema = Yup.object({
        faq_category_id: Yup.string().trim().required('faq_category is required'),
        faq_question: Yup.string().trim().required('faq_question is required').max(255, 'faq_question must be at most 255 characters'),
        faq_answer: Yup.string().trim().required('faq_answer is required'),
        urlrewrite_folder_name: Yup.string().trim().required('urlrewrite_folder_name is required').max(100, 'URLRewrite_Folder_Name must be at most 100 characters'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        faq_id: "",
        faq_question: "",
        url: "",
        urlrewrite_folder_name: "",
        image_name: "",
        image_width: "",
        image_height: "",
        faq_answer: "",
        faq_order: "",
        faq_category_id: "",
        faq_is_approved: false,
        lock_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New FAQ" })
            } else {
                setMetaData({ "title": "Edit FAQ" })
            }
            api.post('/admin-faq-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.faq);
                    if (!isAddMode) {
                        setInitialValues(response.data.faq[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.faqNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-faq-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.faq !== undefined &&
                result.faq.length > 0 &&
                result.faq[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>
                )}


            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <FaqForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues} />
            </Formik>


        </>
    )
}

export default Faq
