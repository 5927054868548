import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import OptionStrategyForm from './OptionStrategyForm';
import { Formik } from 'formik';

const OptionStrategy = () => {
    const validationSchema = Yup.object({
        name: Yup.string().trim().required('Please enter Strategy Name.'),
        short_name: Yup.string().trim().required('Please enter Strategy Short Name.'),
        strategy_level_cd: Yup.string().trim().required('Please choose Strategy Level.'),
        description: Yup.string().trim().required('Please enter description.'),
        urlrewrite_folder_name: Yup.string().trim().required('Please enter URLRewrite Folder Name.'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        strategy_level_cd: "",
        trading_instrument_cd: "",
        name: "",
        short_name: "",
        description: "",
        when_to_use: "",
        market_view_cd: "",
        market_view_desc: "",
        number_of_positions: "",
        action: "",
        action_desc: "",
        risk_cd: "",
        risk_desc: "",
        reward_cd: "",
        reward_desc: "",
        breakeven_point: "",
        breakeven_point_desc: "",
        max_profit_scenario: "",
        max_loss_scenario: "",
        example: "",
        payoff_chart_name: "",
        payoff_chart_width: "",
        payoff_chart_height: "",
        advantage: "",
        disadvantage: "",
        how_to_exit: "",
        simillar_strategies: "",
        urlrewrite_folder_name: "",
        display_order: "",
        display_status: false,
        lock_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Option Strategy" })
            } else {
                setMetaData({ "title": "Edit Option Strategy" })
            }
            api.post('/admin-optionStrategy-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.optionStrategy);
                    if (!isAddMode) {
                        setInitialValues(response.data.optionStrategy[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.optionStrategyNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());
        

        api.post('/admin-optionStrategy-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
                
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };



    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.optionStrategy !== undefined &&
                result.optionStrategy.length > 0 &&
                result.optionStrategy[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <OptionStrategyForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
            </Formik>


        </>
    )
}

export default OptionStrategy
