import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
// import CharacterCountTextarea from '../Common/CharacterCountTextarea';
// import { Link } from 'react-router-dom';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import ReCAPTCHA from 'react-google-recaptcha';
import NumberInput from '../../admintool9x/Common/NumberInput';

const BrokerRatingForm = ({ result, btnClick, loading, initialValues, recaptchaRef, currentUser }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const ratingMapping = {
    'Brokerage': 'rate_brokerage',
    'Customer Service': 'rate_customer_service',
    'Yearly Maintenance Fees': 'rate_fees',
    'Website Usability': 'rate_usability',
    'Research Capabilities': 'rate_research_capabilities',
    'Overall Experience': 'rate_overall'
  };

  return (
    <>
      <Form id='myForm'>

        <fieldset>
          <div id="legend">
            <p>Please share your experience with <strong>{result.companyName}</strong>.</p>
          </div>


          <div className="mb-2 row">
            <div className="table-responsive">
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th className='text-center'><strong>Very Poor (1/5)</strong></th>
                    <th className='text-center'><strong>Below Average (2/5)</strong></th>
                    <th className='text-center'><strong>Average (3/5)</strong></th>
                    <th className='text-center'><strong>Above Average (4/5)</strong></th>
                    <th className='text-center'><strong>Excellent (5/5)</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(ratingMapping).map((item, index) => (
                    <tr key={index} className="text-center" style={item === 'Overall Experience' ? { backgroundColor: '#ffffcc' } : {}}>
                      <td className="text-start" style={item === 'Overall Experience' ? { color: '#cc3300' } : {}}>
                        <strong>{item}</strong>
                      </td>
                      {[1, 2, 3, 4, 5].map((value) => (
                        <td key={value}>
                          <Field type="radio" tabIndex={0} name={ratingMapping[item]} value={String(value)} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <p>Describe <strong>rationale for above ratings</strong> for { } below:</p>

          <div className="mb-2 row">
            <div className="col-sm-12 controls">
              <Field as="textarea" rows="7" cols="70"  maxLength={1000} tabIndex={5} name="message_content" className="form-control" placeholder={`What did you like (or dislike) about ${result.companyName}?`} />
            </div>
          </div>
          {!currentUser &&
            <>
              <div className="mb-2 row">
                <label htmlFor="author_name" className="col-sm-2 control-label">Name</label>
                <div className="col-sm-10">
                  <Field type="text" id="author_name" tabIndex={10} name="author_name" placeholder="Your Name" className="form-control" maxLength={50} />
                </div>
              </div>
              <div className="mb-2 row">
                <label htmlFor="posted_by_email" className="col-sm-2 control-label">Phone</label>
                <div className="col-sm-10">
                  <NumberInput classNameAtr="form-control" placeholderAtr="Your Phone no" tabIndexAtr={15} maxLength="13" nameAtr="author_phone" placeholder="Your Phone Number" />
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="author_email" className="col-sm-2 control-label">Email</label>
                <div className="col-sm-10">
                  <Field type="text" id="author_email" tabIndex={20} name="author_email" placeholder="Your Email" className="form-control" maxLength={50} />
                </div>
              </div>
            </>
          }
          <div className="mb-2 row">
            <label htmlFor="" className="col-sm-2 control-label">Verify Code</label>
            <div className="col-sm-10">
              <span id="divLoading">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  tabIndex={25}
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  onChange={(value) => setFieldValue('recaptcha', value)}
                />
              </span>
            </div>
          </div>

        </fieldset>
        <hr />
        <div className="mb-3 text-center">
          {result.pageDataLoaded === 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={30}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                Post Your Rating
              </span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={btnClick}
            tabIndex={35}
          >Close</button>
        </div>
        <ValidateFormFields />
      </Form>

    </>
  )
}

export default BrokerRatingForm
