import React, { useState, useEffect } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { useFormikContext, Field, Form } from 'formik';
import MemberSameTable from "./MemberSameTable";
import MessagesTable from "./MessagesTable";
import DynamicCharacterCountInput from "../Common/CharacterCountTextarea";
import PhoneInput from "../Common/PhoneInput";


const MemberForm = ({ result, handleGoBack, initialValues, loading, callMemberData, id }) => {
	const { values, setFieldValue } = useFormikContext();
	const [userDisabledReasonReadOnly, setUserDisabledReasonReadOnly] = useState(true)

	console.log("This is the received data: ", initialValues);

	useEffect(() => {
		console.log("The value of status is: ", values.status)
		if (values.status == 1) {
			setUserDisabledReasonReadOnly(true);
			console.log(userDisabledReasonReadOnly)
			setFieldValue("member_disable_reason", "");
		}
		else {
			setUserDisabledReasonReadOnly(false);
		}
	}, [values.status]);

	let statusList = [{
		"id": 0,
		"name": "Inactive"
	},
	{
		"id": 1,
		"name": "Active"
	}]

	return (
		<>

			<Form id="myForm">

				<div className="mb-3 row">
					<label htmlFor="name" className="col-lg-2">Name</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" name="name" maxLength="50" tabIndex={1} />
					</div>
					<label htmlFor="display_name" className="col-lg-2">Display Name</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" name="display_name" maxLength="50" tabIndex={5} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="email" className="col-lg-2">Email</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" name="email" maxLength="50" tabIndex={10}></Field>
					</div>
					{result !== undefined &&
						result.member !== undefined &&
						result.member.length > 0 && (
							<>
								<label htmlFor="" className="col-lg-2">
									Password
								</label>
								<div className="col-lg-4">{result.member[0].password}</div>
							</>
						)}
				</div>
				<div className="mb-3 row">
					<label htmlFor="city" className="col-lg-2">City</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" size="25" name="city" maxLength="25" tabIndex={15}></Field>
					</div>
					<label htmlFor="state" className="col-lg-2">State</label>
					<div className="col-lg-4">
						<Field as="select" name="state" className="form-select" tabIndex={20} >
							<option></option>
							{result.stateList !== undefined && result.stateList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</Field>

					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="phone" className="col-lg-2">Phone</label>
					<div className="col-lg-4">
						<PhoneInput classNameAtr={"form-control"} nameAtr={"phone"} tabIndex={25} maxLength = {"13"} />
					</div>
					<label htmlFor="status" className="col-lg-2">Status</label>
					<div className="col-lg-4">
						<Field as="select" name="status" className="form-select" tabIndex={30} >
							<option></option>
							{statusList !== undefined && statusList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</Field>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="member_disable_reason" className="col-lg-2">Member Disable Reason</label>
					<div className="col-lg-4">
						<Field as="select" name="member_disable_reason" className="form-select" tabIndex={35} disabled={userDisabledReasonReadOnly}>
							<option></option>
							{result.memberDisableReasons !== undefined && result.memberDisableReasons.map((option) => (
								<option key={option.code} value={option.code}>
									{option.code_desc}
								</option>
							))}
						</Field>
					</div>
					<label htmlFor="is_member_active" className="col-lg-2">Is Member Active</label>
					<div className="col-lg-4">
						<Field as="select" name="is_member_active" className="form-select" tabIndex={40} >
							<option></option>
							{statusList !== undefined && statusList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</Field>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="signature" className="col-lg-2">Signature? (HTML) <b></b>
						<Field
							className="form-check-input"
							type="checkbox"
							name="signature_status"
							tabIndex={45}
							onChange={(e) => {
								let newVal = e.target.checked;
								setFieldValue('signature_status', newVal);
							}}
						/></label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput className="form-control" rows="5" nameattr={"signature"} tabIndex={50} />
					</div>
					<label htmlFor="comments" className="col-lg-2">Comment</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput className="form-control" rows="5" nameattr={"comments"} tabIndex={55} />
					</div>
				</div>
				<div className="mb-3 text-center">
					<button
						type="submit"
						className="btn btn-primary btn-block"
						disabled={loading}
						tabIndex={60}
					>
						{loading && (
							<span className="spinner-border spinner-border-sm me-2"></span>
						)}
						<span>
							{result.member !== undefined && result.member.length > 0
								? "Update"
								: "Create"}
						</span>
					</button>

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={65}
					>
						Cancel
					</button>
				</div>

				<hr />

				<ValidateFormFields />
				<div className="row">
					<div className="col-6">
						<h2>Members with the same Login IP Address</h2>
						<MemberSameTable list={"memberSameIpList"} tabIndex={70} />
						<br />
						<h2>Messages</h2>
						<MessagesTable list={"issueStockExchangeList"} id={id} tabIndex={75} callMemberData={callMemberData} />
					</div>
					<div className="col-6">
						<h2>Members with the same password</h2>
						<MemberSameTable list={"memberSamePasswordList"} tabIndex={80} />
					</div>
				</div>
			</Form>
		</>
	);
};

export default MemberForm;
