import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../common/useApi";
import {
  finishLoading,
  startLoading,
  selectLoading,
} from "../../../state/slices/loadingSlice";
import { setMessage } from "../../../state/slices/message";
import IPOPECalcForm from "./IPOPECalcForm";
import { Formik } from "formik";
import * as Yup from "yup";
import IpoTabs from "./IPONewPageTabs";
import { isValidVariable } from "../Common/CommonFunction";

const IPOPECalc = () => {
  const validationSchema = Yup.object({
  });

  const validate = (values) => {

    const validateNumber = (field, errorMessage) => {
      if (values[field] !== null && values[field] !== '' && isNaN(Number(values[field]))) {
        alert(errorMessage)
        return false;
      }
    };

    validateNumber('kpi_debt_equity', 'Debt/Equity must be a number');
    validateNumber('total_shareholding_post_issue', 'Total Shareholding Post-Issue Must be a number');
    validateNumber('total_shareholding_pre_issue', 'Total Shareholding Pre-Issue Must be a number');
    validateNumber('nav', 'NAV Must be a number');

    validateNumber('calc_pre_issue_eps', 'Pre Issue EPS Must be a number');
    validateNumber('calc_post_issue_eps_latest', 'Post Issue EPS TTM Basis Must be a number');
    validateNumber('calc_pre_ipo_pe_fy', 'Pre IPO PE Basis Must be a number');
    validateNumber('calc_post_ipo_pe_latest', 'Post IPO PE TTM Basis Must be a number');
    validateNumber('calc_price_to_book_value', 'Price to Book Value Must be a number');


    return true;
  };

  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [metaData, setMetaData] = useState({
    title: "",
  });
  const { id } = useParams();
  const isAddMode = !id;
  const api = useApi();
  const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    id: "",
    company_name: "",
    kpi_as_of_date: "",
    total_shareholding_pre_issue: "",
    total_shareholding_post_issue: "",
    promoter_shareholding_pre_issue: "",
    promoter_shareholding_post_issue: "",
    kpi_roe: "",
    kpi_roce: "",
    kpi_debt_equity: "",
    kpi_ronw: "",
    nav: "",
    kpi_pat_margin: "",

    kpi_comment: "",
    // KPI Endned

    pe_ratio: "",
    post_pe_ratio: "",
    market_cap: "",
    kpi_eps: "",
    kpi_eps_post: "",
    price_to_book_value: "",
    latest_fy_dt: "",
    latest_financial_dt: "",
    // Last Saved P/E and market cap value

    latest_fy: "",
    latest_eps: "",
    latest_financial_eps: "",
    latest_pat: "",
    latest_revenue: "",
    latest_financial: "",
    latest_financial_pat: "",
    latest_financial_revenue: "",
    auto_nav: "",
    issue_price: "",
    // auto fill end

    calc_derived_pat: "",
    calc_derived_revenue: "",
    calc_pre_issue_eps: "",
    calc_post_issue_eps: "",
    calc_post_issue_eps_latest: "",
    calc_pre_ipo_pe_fy: "",
    calc_post_ipo_pe_fy: "",
    calc_post_ipo_pe_latest: "",
    calc_market_cap: "",
    calc_price_to_book_value: "",
    // caclulation field end

    lock_status: 0,
    ipo_confirmation_status: false,
    action: "",
    ipoFinancial: [
      {
        financial_date: "",
        profit_after_tax: "",
        total_revenue: "",
      },
    ],
    ipoFinancialMarch: [
      {
        financial_date: "",
        profit_after_tax: "",
        total_revenue: "",
      },
    ],
  });

  const handleGoBack = () => {
    dispatch(setMessage(""));
    navigate(-1); // Navigate back one step in history
  };

  useEffect(() => {
    if (didMountRef.current == "true") {
      dispatch(startLoading());
      console.log("id : " + id);
      setMetaData({ title: " Edit IPO PE Parameter" });

      api
        .post("/admin-ipopecalc-read", {
          id: id,
        })
        .then((response) => {
          if (response.data.msg === 1) {
            console.log("The result is: ", response.data);
            setResult(response.data);

            setInitialValues((prevValues) => ({
              ...prevValues,
              ...response.data.ipoDetail[0],

              latest_fy: response.data.ipoFinancialMarch.length > 0 ? response.data.ipoFinancialMarch[0].financial_date ?? "" : "",
              latest_pat: response.data.ipoFinancialMarch.length > 0 ? response.data.ipoFinancialMarch[0].profit_after_tax ?? "" : "",
              latest_revenue: response.data.ipoFinancialMarch.length > 0 ? response.data.ipoFinancialMarch[0].total_revenue ?? "" : "",
              ipoFinancialMarch: response.data.ipoFinancialMarch.length > 0 ? response.data.ipoFinancialMarch ?? '' : "",

              latest_financial: response.data.ipoFinancial.length > 0 ? response.data.ipoFinancial[0].financial_date ?? "" : "",
              latest_financial_pat: response.data.ipoFinancial.length > 0 ? response.data.ipoFinancial[0].profit_after_tax ?? "" : "",
              latest_financial_revenue: response.data.ipoFinancial.length > 0 ? response.data.ipoFinancial[0].total_revenue ?? '' : "",
              ipoFinancial: response.data.ipoFinancial.length > 0 ? response.data.ipoFinancial ?? '' : "",

              auto_nav: isValidVariable(response.data.ipoDetail[0].nav) ? response.data.ipoDetail[0].nav : (response.data.ipoNaV.length > 0 ? response.data.ipoNaV[0].nav ?? "" : ''),

            }));
          } else {
            dispatch(setMessage(response.data.error));
          }
          dispatch(finishLoading());
        })
        .catch((error) => {
          console.error("Error:", error);
          dispatch(finishLoading());
        });
    } else {
      didMountRef.current = "true";
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {

    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === "string"
          ? values[key].trim()
          : typeof values[key] === "boolean"
            ? values[key] === true
              ? 1
              : 0
            : values[key];
      return acc;
    }, {});
    dispatch(startLoading());

    if (validate(trimmedValues)) {

      console.log(
        "the value of action paramter is this : ",
        trimmedValues.action
      );
      console.log("Form submitted with values: ", trimmedValues)

      api
        .post("/admin-ipopecalc-save", {
          values: trimmedValues,
        })
        .then((response) => {
          if (response.data.msg === 1) {
            dispatch(setMessage(response.data.success));
            // navigate(-1);
          } else {
            dispatch(setMessage(response.data.error));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          dispatch(finishLoading());
        });
      // Set submitting to false to allow the form to be submitted again
      setSubmitting(false);
    }
  };

  return (
    <>
      {isAddMode ? "" : <IpoTabs idAtr={result.id} ipo_confirm={result.ipoDetail?.[0]?.ipo_confirmation_status} />}

      <Helmet>
        <title>{metaData.title}</title>
        <meta name="title" content={metaData.title} />
      </Helmet>

      <h1>{metaData.title}</h1>
      <hr />

      {result !== undefined &&
        result.ipoDetail !== undefined &&
        result.ipoDetail.length > 0 &&
        result.ipoDetail[0].lock_status == 1 && (
          <>
            <h3 className="text-danger">
              Record Is Locked , you can't update it.
            </h3>
            <br />
          </>
        )}

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <IPOPECalcForm
          result={result}
          handleGoBack={handleGoBack}
          loading={loading}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      </Formik>
    </>
  );
};

export default IPOPECalc;