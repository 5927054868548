import React from 'react'
import { Field, Form } from 'formik';
import { funCreateSlug } from "../Common/CommonFunction";
import { useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const OptionStrategyForm = ({ result, handleGoBack, loading, initialValues }) => {

    const { setFieldValue, setFieldTouched } =
        useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.optionStrategy !== undefined && result.optionStrategy.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.optionStrategy[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="name" className="col-lg-2 required">Strategy Name</label>
                    <div className="col-lg-4">

                        <Field as="input" name="name" className="form-control" tabIndex={1} maxLength="50" onBlur={(e) => {
                            const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                            if (userConfirmed) {
                                var title = e.target.value;
                                var slug = funCreateSlug(title)
                                setFieldValue('urlrewrite_folder_name', slug);
                            } else {
                                setFieldTouched('title', true);
                            }
                        }} />

                    </div>
                    <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={5} name="urlrewrite_folder_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="short_name" className="col-lg-2 required">Short Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={10} name="short_name" />
                    </div>

                    <label htmlFor="strategy_level_cd" className="col-lg-2 required">Strategy Level</label>
                    <div className="col-lg-4">

                        <Field as="select" name="strategy_level_cd" className="form-select" tabIndex={15}>
                            <option></option>
                            {result.strategyLevelList !== undefined && result.strategyLevelList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="trading_instrument_cd" className="col-lg-2">Trading Instrument</label>
                    <div className="col-lg-4">

                        <Field as="select" name="trading_instrument_cd" className="form-select" tabIndex={15}>
                            <option></option>
                            {result.tradingInstrumentList !== undefined && result.tradingInstrumentList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                    </div>
                    <label htmlFor="number_of_positions" className="col-lg-2">No. of Positions</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={25} name="number_of_positions" />
                    </div>
                </div>
                <hr />
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="payoff_chart_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="payoff_chart_name" className="col-lg-2">Payoff Chart</label>
                    <div className="col-lg-3">
                        <Field className="form-control" type="text" tabIndex={30} name="payoff_chart_name" />
                    </div>
                    <label htmlFor="payoff_chart_width" className="col-lg-1">Width:</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="number" size="5" tabIndex={35} name="payoff_chart_width" />
                    </div>
                    <label htmlFor="payoff_chart_height" className="col-lg-1">Height:</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="number" size="5" tabIndex={40} name="payoff_chart_height" />
                    </div>
                </div>
                <hr />

                <div className="mb-3 row">
                    <label htmlFor="description" className="col-lg-2 required">Description<br /> (in HTML)</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="description"
                            type="textarea"
                            tabIndex="45"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="when_to_use" className="col-lg-2">When to use?</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="when_to_use"
                            type="textarea"
                            tabIndex="50"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="example" className="col-lg-2">Example</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="example"
                            type="textarea"
                            tabIndex="55"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="market_view_cd" className="col-lg-2">Market View</label>
                    <div className="col-lg-4">

                        <Field as="select" name="market_view_cd" className="form-select" tabIndex={60}>
                            <option></option>
                            {result.optionsMarketList !== undefined && result.optionsMarketList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                    </div>
                    <label htmlFor="market_view_desc" className="col-lg-2">Market View Description</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={70} name="market_view_desc" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="action" className="col-lg-2">Action Brief</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={75} name="action" />
                    </div>
                    <label htmlFor="action_desc" className="col-lg-2">Action Description</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={80} name="action_desc" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="risk_cd" className="col-lg-2">Risk</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select" tabIndex={85} name="risk_cd">
                            <option></option>
                            {result.optionsRiskProfileList !== undefined && result.optionsRewardProfileList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code}
                                </option>
                            ))}
                        </Field>
                    </div>
                    <label htmlFor="risk_desc" className="col-lg-2">Risk Description</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={90} name="risk_desc" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="reward_cd" className="col-lg-2">Reward</label>
                    <div className="col-lg-4">

                        <Field as="select" className="form-select" tabIndex={95} name="reward_cd">
                            <option></option>
                            {result.optionsRewardProfileList !== undefined && result.optionsRewardProfileList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code}
                                </option>
                            ))}
                        </Field>
                    </div>
                    <label htmlFor="reward_desc" className="col-lg-2">Reward Description</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={100} name="reward_desc" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="breakeven_point" className="col-lg-2">Breakeven Point Brief</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={105} name="breakeven_point" />
                    </div>
                    <label htmlFor="breakeven_point_desc" className="col-lg-2">Breakeven Point Desc</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={110} name="breakeven_point_desc" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="max_profit_scenario" className="col-lg-2">Max Profit Scenario</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={115} name="max_profit_scenario" rows="4" />
                    </div>
                    <label htmlFor="max_loss_scenario" className="col-lg-2">Max Loss Scenario</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={120} name="max_loss_scenario" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="advantage" className="col-lg-2">Advantage</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={125} name="advantage" rows="4" />
                    </div>
                    <label htmlFor="disadvantage" className="col-lg-2">Disadvantage</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={130} name="disadvantage" rows="4" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="how_to_exit" className="col-lg-2">How to exit?</label>
                    <div className="col-lg-4">
                        <Field as="textarea" className="form-control" tabIndex={135} name="how_to_exit" rows="4" />
                    </div>
                    <label htmlFor="simillar_strategies" className="col-lg-2">Simillar Strategies Ids</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type='text' tabIndex={140} name="simillar_strategies" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type='number' tabIndex={145} name="display_order" />
                    </div>
                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-2">
                        <Field className="form-check" type="checkbox" tabIndex={150} name="display_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }} />
                    </div>
                    <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-2">
                        <Field className="form-check" type="checkbox" tabIndex={150} name="lock_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('lock_status', newValue);
                        }} />
                    </div>
                </div>
                <hr />

                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={155}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.optionStrategy !== undefined && result.optionStrategy.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={160}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>



        </>
    )
}

export default OptionStrategyForm





