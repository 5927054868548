import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import SQLFindInAllTablesForm from './SQLFindInAllTablesForm';
import { Formik } from 'formik';
import * as Yup from 'yup';


const SQLFindInAllTables = () => {

    const validationSchema = Yup.object({
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    const [initialValues, setInitialValues] = useState({
        sqlquery: "",
    });
    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            setMetaData({ "title": "Find Text in All Tables" })

            api.post('/admin-sqlfindintables-read', {

            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                <SQLFindInAllTablesForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}

export default SQLFindInAllTables
