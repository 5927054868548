import React from 'react'
import { Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const TradingCompanyPagesForm = ({ result, handleGoBack, loading }) => {

  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.tradingCompany !== undefined && result.tradingCompany.length > 0 &&
          <div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">ID:</label>
              <div className="col-lg-4">
                {result.tradingCompany[0].id}
              </div>
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="brokerage_pg_desc" className="col-lg-2">Brokerage Page</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="brokerage_pg_desc"
              type="textarea"
              tabIndex="5"
              rows="5"
              className="form-control"
            />

          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="account_opening_pg_desc" className="col-lg-2">Account Opening Page</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="account_opening_pg_desc"
              type="textarea"
              tabIndex="10"
              rows="5"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="branches_pg_desc" className="col-lg-2">Branches Page</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="branches_pg_desc"
              type="textarea"
              tabIndex="15"
              rows="5"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="demat_account_pg_desc" className="col-lg-2">Demat Account</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="demat_account_pg_desc"
              type="textarea"
              tabIndex="20"
              rows="5"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="ipo_pg_desc" className="col-lg-2">IPO Page</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="ipo_pg_desc"
              type="textarea"
              tabIndex="25"
              rows="5"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="mobile_app_pg_desc" className="col-lg-2">Mobile App</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="mobile_app_pg_desc"
              type="textarea"
              tabIndex="30"
              rows="5"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="trading_software_pg_desc" className="col-lg-2">Trading Platform</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="trading_software_pg_desc"
              type="textarea"
              tabIndex="40"
              rows="5"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="mf_pg_desc" className="col-lg-2">Mutual Funds</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="mf_pg_desc"
              type="textarea"
              tabIndex="45"
              rows="5"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="safety_pg_desc" className="col-lg-2">Safety of Broker</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="safety_pg_desc"
              type="textarea"
              tabIndex="50"
              rows="5"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="api_pg_desc" className="col-lg-2">API</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="api_pg_desc"
              type="textarea"
              tabIndex="55"
              rows="5"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 text-center">
          {result != undefined && result.tradingCompany != undefined && result.tradingCompany[0].lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={60}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                Update
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={65}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default TradingCompanyPagesForm





