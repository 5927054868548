import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../state/slices/loadingSlice';
import { setMessage } from '../../state/slices/message';
import * as Yup from 'yup';
import { Formik } from 'formik';
import IpoReviewEditPublisherForm from './IpoReviewEditPublisherForm';

const IpoReviewEditPublisher = () => {

    const [formState, setFormState] = useState({
        ipoDisabled: false,
        ncdDisabled: false,
        riDisabled: false,
        bbDisabled: false,
    })

    const [submitForm, setSubmitForm] = useState(false);
    const [numberOfReports, setnumberOfReports] = useState("1");

    const validationSchema = Yup.object({
        category_cd: Yup.string().required('Category is Required.')
    })
        .test(
            function (value) {
                const { ipo_id, ncd_id, ri_id, bb_id } = value;
                if ((!formState.ipoDisabled && ipo_id == null) || (!formState.ncdDisabled && ncd_id == null) ||
                    (!formState.riDisabled && ri_id == null) || (!formState.bbDisabled && bb_id == null)) {
                    setSubmitForm(false);
                    return false;
                }
                setSubmitForm(true)
                return true;
            })

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var id = queryParams.get("id") ?? "";

    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        category_cd: "",
        topic_id: "",
        title: "",
        review: "",
        report_link: "",
        recommendation: "",
        review_author_signature_cd: '',
        review_author_name: '',
        lock_status: "",
        display_status: 0,
        ipo_id: "",
        ncd_id: "",
        ri_id: "",
        lock_status: 0,
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/8"); // Navigate back one step in history
    };

    useEffect(() => {
        console.log("The id is: ", id)
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            if (isAddMode) {
                setMetaData({ "title": "Add New Reviews" })
            } else {
                setMetaData({ "title": "Edit Reviews" })
            }
            api.post('/ipo-report/dd-review-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    const review = response.data.review[0];
                    if (!isAddMode) {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: review.id,
                            category_cd: review.category_cd,
                            topic_id: review.topic_id,
                            title: review.title,
                            recommendation: review.recommendation,
                            review: review.review,
                            conclusion: review.conclusion,
                            report_link: review.report_link,
                            review_author_name: review.review_author_name,
                            display_status: review.display_status,
                            review_author_signature_cd: review.review_author_signature_cd,
                            lock_status: review.lock_status,
                            ipo_id: review.category_cd == 1 ? review.topic_id : "",
                            ncd_id: review.category_cd == 2 ? review.topic_id : "",
                            ri_id: review.category_cd == 3 ? review.topic_id : "",
                        }))
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                    if (response.data.msg == -2) {
                        navigate("/")
                    }
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());
        setSubmitting(true);
        api.post('/ipo-report/dd-review-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                // navigate("/admintool9x/report/8");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <br />
            <h1>
                {metaData.title}
            </h1>

            <hr></hr>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoReviewEditPublisherForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} formState={formState} setFormState={setFormState} isAddMode={isAddMode} numberOfReports={numberOfReports} setnumberOfReports={setnumberOfReports} />
            </Formik>
        </>
    )
}

export default IpoReviewEditPublisher
