import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../state/slices/auth";
import routeConfig from "../../routeConfig";
import Cookies from 'js-cookie';

const Header = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [welcomename, setWelcomeName] = useState("");
  const alphaUrl = process.env.REACT_APP_ALPHA_URL;
  const webUrl = process.env.REACT_APP_WEB_URL;
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      setWelcomeName(currentUser.display_name);
    } else {
      setWelcomeName("Guest");
    }

    // EventBus.on("logout", () => {
    //   logOut();
    // });

    // return () => {
    //   EventBus.remove("logout");
    // };
  }, [currentUser]);

  const sRefererApp = "";

  const logoutFunction = (e) => {
    e.preventDefault();
    Cookies.remove('rememberMeEmail');
    Cookies.remove('rememberMePassword');
    dispatch(logout())
  };

  return (
    <div className="row header-admin">
      <div className="col-6">
        <a href={webUrl} title="Chittorgarh.com">
          <strong>www.Chittorgarh.com</strong>
        </a>
      </div>
      <div className="col-6 text-end">
        <small>
          <span className="header-logged-name">
            Welcome, <b>{welcomename}</b>
          </span>
          &nbsp;&nbsp;

          {currentUser ? (
            <>
              <Link to={`${alphaUrl}ipo_edit_comment.asp?ref=${sRefererApp}`} alt="My Posts" className="mx-1" title="My Posts"><i className="fa fa-comments"></i></Link>
              |
              <Link to={`${alphaUrl}ipo_edit_bookmark.asp?ref=${sRefererApp}`} alt="My Bookmarks" className="mx-1" title="My Bookmarks"><i className="fa fa-bookmark"></i></Link>
              |
              <Link to={routeConfig.profile() + `?ref=${sRefererApp}`} alt='Update Profile' className="mx-1"><i className='fa fa-user' title='Update Profile'></i></Link>
              |
              <a href="#" onClick={(e) => logoutFunction(e)} className="ms-1"><i className='fa fa-sign-out' title='Logout'></i></a>
            </>
          ) : (
            <>
              <Link to={routeConfig.registration()} alt='Register with us'>Register</Link> | <Link to={routeConfig.accessdenied()}>Login</Link>
            </>
          )}

        </small>
      </div>
    </div>
  );
};

export default Header;
