import { useDispatch, useSelector } from "react-redux";
import { finishLoading, selectLoading, startLoading } from "../../../state/slices/loadingSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../common/useApi";
import { setMessage } from "../../../state/slices/message";
import CacheMasterForm from "./CacheMasterForm";

import { Helmet } from "react-helmet-async";
const CacheMaster = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        category: "",
        cache_urls: "",
        nodejs_urls: "",
        redis_patterns: "",
        extra_urls_to_clean: "",
        display_status: true,
        lock_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Cache Detail" })
            } else {
                dispatch(startLoading());
                setMetaData({ "title": "Edit Cache Detail" })
                api.post('/admin-cachemaster-read', {
                    id: id
                }).then(response => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        setInitialValues(response.data.cacheMaster[0]);
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                    dispatch(finishLoading());
                }).catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
            }

        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        console.log("handle submit")
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        dispatch(startLoading());

        api.post('/admin-cachemaster-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />

            <CacheMasterForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />

        </>
    )
}
export default CacheMaster;