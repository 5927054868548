import { Field, Form, useFormikContext, FieldArray } from 'formik';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import ValidateFormFields from '../admintool9x/Common/ValidateFormFields';
import { useScriptTinyMce } from '../admintool9x/Common/CommonFunction';
import { useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const IpoReviewEditPublisherForm = ({ result, handleGoBack, initialValues, loading, formState, setFormState, isAddMode, numberOfReports, setnumberOfReports }) => {
  const location = useLocation();
  const { values, setFieldValue } = useFormikContext();
  //const [companyName, setCompanyName] = useState("");

  useScriptTinyMce("https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.4/tinymce.min.js", "script", location.pathname, setFieldValue, initialValues);

  useEffect(() => {
    if (values.category_cd == 1) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ipoDisabled: false
      }));
      setFieldValue('ncd_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is IPO, IPO field is mandatory!")
    }

    if (values.category_cd == 2) {
      setFormState((prevState) => ({
        ...prevState,
        ipoDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ncdDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is NCD, NCD field is mandatory!")
    }

    if (values.category_cd == 3) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        ipoDisabled: true,
        bbDisabled: true,
        riDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ncd_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is RI, RI field is mandatory!")
    }
  }, [values.category_cd])


  return (
    <>
      <Form id='myForm'>
        <fieldset>
          {result !== undefined && result.review !== undefined && result.review.length > 0 &&
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">ID:</label>
              <div className="col-lg-4">
                {result.review[0].id}
              </div>
            </div>
          }

          <div className="mb-3 row">
            <label htmlFor="category_cd" className="col-lg-2 required">Category</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={5} name="category_cd" >
                <option value=""></option>
                {result.reviewLists !== undefined && result.reviewLists.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="ipo_id" className="col-lg-2 required">IPO Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={10} name="ipo_id" disabled={formState.ipoDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  setFieldValue('ipo_id', e.target.value);
                }}>
                <option value=""></option>
                {result.ipoLists !== undefined && result.ipoLists.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.company_name}
                  </option>
                ))}
              </Field >
            </div>

            <label htmlFor="ncd_id" className="col-lg-2 required">NCD Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={15} name="ncd_id" disabled={formState.ncdDisabled}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setFieldValue('ncd_id', selectedValue);
                  setFieldValue('topic_id', selectedValue); // Assuming 'topic_id' is set to 'ncd_id'           
                }}>
                <option value=""></option>
                {result.ncdLists !== undefined && result.ncdLists.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option["concat(company_name ,' - ' ,  date_format(Issue_Open_Date,'%Y-%m-%d'))"]}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="ri_id" className="col-lg-2 required">RI Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" tabIndex={20} name="ri_id" disabled={formState.riDisabled}
                onChange={(e) => {
                  setFieldValue('topic_id', e.target.value);
                  setFieldValue('ri_id', e.target.value);
                }}>
                <option value=""></option>
                {result.riLists !== undefined && result.riLists.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.company_name}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-2 row">
            <label className="col-lg-2">Review Title*</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" name="title" tabIndex={25} />
            </div>
            <label className="col-lg-2">Recommendation*</label>
            <div className="col-lg-4">
              <Field as="select" name="recommendation" className="form-select" tabIndex={30}>
                <option></option>
                {result.recommendationLists !== undefined && result.recommendationLists.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-2 row">
            <label className="col-lg-2">Author Name</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" name="review_author_name" tabIndex={35}>
                <option></option>
                {result.authorLists !== undefined && result.authorLists.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
              </Field>
            </div>

            <label className="col-lg-2">Signature</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" name="review_author_signature_cd" tabIndex={40}>
                <option></option>
                {result.authorSignatureLists !== undefined && result.authorSignatureLists.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="review" className="col-lg-2">Review</label>
            <div className="col-lg-10">
              <textarea id="review" name="review" rows="10" tabIndex={45} />
            </div>
          </div>

          <div className="mb-2 row">
            <label className="col-lg-2" htmlFor="Conclusion">Conclusion</label>
            <div className="col-lg-10">
              <Field as="textarea" className="form-control" name="conclusion" id="conclusion" rows="2" tabIndex={50} />
            </div>
          </div>

        </fieldset>

        {/* {isAddMode || !isAddMode && ( */}

        {/* )} */}

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={55} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>

        </div>

        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={65}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              {result.review !== undefined && result.review.length > 0 ? 'Update' : 'Create'}
            </span>
          </button>

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={70}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>

      <div className='mb-2'>
        <h2>Instructions:</h2>
        <ol>
          <li><b>Steps to upload Image</b>
            <ul>
              <li>Go to <a href="https://imgbb.com/" target="_blank">https://imgbb.com/</a></li>
              <li>Upload the image</li>
              <li>In 'Embed codes' dropdown, select 'HTML Full Linked'.</li>
              <li>Copy content of text box in a notepad.</li>
              <li style={{ lineBreak: "anywhere" }}>Cut only the image url highlighted in bold (without double quotes) i.e. src="<u><b>https://image.ibb.co/fX8yyb/Amber.png</b></u>" image path.</li>
              <li>Go back to review window above and click 'Insert/Edit' Image button.</li>
              <li>Enter the image URL copied above and click ok.</li>
            </ul>
          </li>
        </ol>
      </div>
    </>
  )
}

export default IpoReviewEditPublisherForm
