import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import routeConfig from "./routeConfig.js";

import Login from "./components/root/Login";
import Register from "./components/root/Register/Register";
import Home from "./components/root/Home";
import Profile from "./components/root/Profile/Profile";
// import BoardUser from "./components/BoardUser";
// import BoardModerator from "./components/BoardModerator";
// import BoardAdmin from "./components/BoardAdmin";

import { login, logout, syncAuthState } from "./state/slices/auth";
import Index from "./components/root/Index";
import Contact from "./components/root/ContactUs/ContactUs.js";

// admin
import AdminIndex from "./components/admintool9x/Index";
import AdminHome from "./components/admintool9x/Home/AdminHome";

// Other
import AdminCode from "./components/admintool9x/Code/Code.js";
import AdminCategory from "./components/admintool9x/Category/Category.js";
import AdminConfig from "./components/admintool9x/Config/Config.js";
import AdminCalendar from "./components/admintool9x/Calendar/Calendar.js";
import AdminPhotoGallery from "./components/admintool9x/PhotoGallery/PhotoGallery.js";
import AdminGoogleSitemap from "./components/admintool9x/GoogleSitemap/GoogleSitemap.js";
import AdminHolidays from "./components/admintool9x/Holiday/Holidays.js";
import AdminReportAccess from "./components/admintool9x/ReportAccess/ReportAccess.js";
import AdminSQLFind from "./components/admintool9x/SQLFind/SQLFindInAllTables.js";
import AdminSQLEditor from "./components/admintool9x/SQLEditor/SQLEditor.js";
import AdminTodo from "./components/admintool9x/Todo/Todo.js";
import AdminFileUpload from "./components/admintool9x/FileUpload/FileUpload.js";
import AdminReportConfigKeywords from "./components/admintool9x/ReportConfigKeywords/ReportConfigKeywords.js";
// --------------------------------------------------------------------------------------------------------------
// alert
import AdminAlert from "./components/admintool9x/Alert/Alert.js";
// --------------------------------------------------------------------------------------------------------------
// messages
import AdminIPOFeedback from "./components/admintool9x/IPOFeedback/IPOFeedbackUpdate.js";
import AdminIPOFeedbackArchive from "./components/admintool9x/IPOFeedback/IPOFeedbackArchive.js";
import AdminIPORecommendation from "./components/admintool9x/IPORecommendation/IPORecommendationMaster.js";
import AdminIPORecommendationDetail from "./components/admintool9x/IPORecommendation/IPORecommendationDetail.js";
import AdminFeedbackUpdate from "./components/admintool9x/Feedback/FeedbackUpdate.js";
import AdminSpamFeedback from "./components/admintool9x/SpamFeedback/SpamFeedback.js";
import AdminSpamRecommendation from "./components/admintool9x/SpamRecommendation/SpamRecommendation.js";
// --------------------------------------------------------------------------------------------------------------
// New IPO Form
import AdminForthcomingIPO from "./components/admintool9x/IPONew/ForthcomingIPO.js";
import AdminIpoDate from "./components/admintool9x/IPONew/IpoDate";
import AdminIpoDrhp from "./components/admintool9x/IPONew/IpoDrhp";
import AdminIpoCompany from "./components/admintool9x/IPONew/IpoCompany";
import AdminIPOIssueDetail from "./components/admintool9x/IPONew/IpoIssueDetail";
import AdminNewIpoAnchorInvestor from "./components/admintool9x/IPONew/IpoAnchorInvestor";
// ipo
import AdminIpo from "./components/admintool9x/Ipo/Ipo";
import AdminIpoListing from "./components/admintool9x/IPONew/IpoListing";

import AdminIpoObjectives from "./components/admintool9x/IpoObjectives/IpoObjectives";
import AdminIpoExpenses from "./components/admintool9x/IpoExpenses/Expenses.js";
import AdminIpoPeerGroup from "./components/admintool9x/IPONew/IpoPeerGroup.js";
import AdminIpoAnchorInvestor from "./components/admintool9x/Ipo/IpoAnchorInvestor";
import AdminIpoMutualFund from "./components/admintool9x/IPONew/IpoMutualFund.js";
import AdminIpoAllotment from "./components/admintool9x/Ipo/IPOAllotment";
import AdminIPOManagerIPO from "./components/admintool9x/Ipo/IPOManagerIPO";
import AdminIPOAnchor from "./components/admintool9x/IpoAnchor/IPOAnchor.js";
import AdminIPOAnchorScheme from "./components/admintool9x/IpoAnchor/IPOAnchorScheme.js";
import AdminIPOBOA from "./components/admintool9x/IPOBOA/IPOBOA";
import AdminIPOPECalc from "./components/admintool9x/IPONew/IPOPECalc.js";
import AdminIPOBidding from "./components/admintool9x/Ipo/IPOBidding";
import AdminIPOListDayTrade from "./components/admintool9x/IPOListDayTrade/IPOListDayTrade";
import AdminReportCDN from "./components/admintool9x/ReportCDN/ReportCDN.js";

import AdminSMEMigrated from "./components/admintool9x/SMEMigrated/SMEMigrated";


// Financial
import AdminFinancial from "./components/admintool9x/Financial/Financial.js";
// review
import AdminReview from "./components/admintool9x/Ipo/IPOReviews";

// --------------------------------------------------------------------------------------------------------------
// NCD
import AdminNCD from "./components/admintool9x/NCD/NCD";
import AdminNCDListing from "./components/admintool9x/NCDListing/NCDListing";
import AdminNCDCompany from "./components/admintool9x/NCD/NCDCompany";
import AdminNCDBidding from "./components/admintool9x/NCDBidding/NCDBidding";
import AdminNCDCouponRate from "./components/admintool9x/NCD/NCDCouponRate";
// --------------------------------------------------------------------------------------------------------------
// RI
import AdminRightIssue from "./components/admintool9x/RightIssue/RightIssue.js";
// --------------------------------------------------------------------------------------------------------------
// Buyback
import AdminBuyback from "./components/admintool9x/Buyback/Buyback.js";
import AdminBuybackHistory from "./components/admintool9x/BuybackHistory/BuybackHistory.js";
import AdminBuybackBidding from "./components/admintool9x/BuybackBidding/BuybackBidding";

// --------------------------------------------------------------------------------------------------------------
// report tabs
import AdminReport from "./components/admintool9x/Report/Report";
import AdminReportCreate from "./components/admintool9x/Report/ReportCreate.js";
import AdminWebReportCreate from "./components/admintool9x/WebReport/WebReport.js";
// --- calendar
import AdminReportCalendarShow from "./components/admintool9x/ReportCalendar/ReportCalendarShow";
import AdminReportCalendar from "./components/admintool9x/ReportCalendar/ReportCalendar";
import AdminReportCalendarWeb from "./components/admintool9x/ReportCalendarWeb/ReportCalendarWeb";

import AdminMonitoringMaster from "./components/admintool9x/MonitoringMaster/MonitoringMaster.js";
import AdminAlertActionMaster from "./components/admintool9x/AlertActionMaster/AlertActionMaster.js";
import AdminCompareReport from "./components/admintool9x/CompareReport/CompareReport.js";
import AdminCronjob from "./components/admintool9x/Cronjob/Cronjob.js";

import ErrorReport from "./components/admintool9x/ErrorReport/ErrorReport.js";
// --------------------------------------------------------------------------------------------------------------

// Misc Tabs
import AdminArticle from "./components/admintool9x/Article/Article.js";
import AdminOptionStrategy from "./components/admintool9x/OptionStrategy/OptionStrategy.js";
import AdminGlossary from "./components/admintool9x/Glossary/Glossary.js";
import AdminTradingPlatform from "./components/admintool9x/TradingPlatform/TradingPlatform.js";
import AdminFaqPage from "./components/admintool9x/FaqPage/FaqPage.js";
import AdminFaq from "./components/admintool9x/Faq/Faq.js";

import AdminTradingCompanyMaster from "./components/admintool9x/Broker/TradingCompanyMaster.js";
import AdminTradingCompanyDetail from "./components/admintool9x/Broker/TradingCompanyDetail.js";
import AdminTradingCompanyPages from "./components/admintool9x/Broker/TradingCompanyPages.js";
import AdminTradingCompanyNRITrading from "./components/admintool9x/Broker/TradingCompanyNRITrading.js";
import AdminTradingCompanyDematCharges from "./components/admintool9x/Broker/TradingCompanyDematCharges.js";
import AdminTradingCompanyPlans from "./components/admintool9x/Broker/TradingCompanyPlans.js";
import AdminTradingCompanyCommonCharge from "./components/admintool9x/TradingCompanyCommonCharge/TradingCompanyCommonCharge.js";
import AdminBrokerComplaint from "./components/admintool9x/BrokerComplaint/BrokerComplaint";
import AdminBrokerComplaintUpload from "./components/admintool9x/BrokerComplaint/BrokerComplaintUpload";
import AdminZerodhaUpload from "./components/admintool9x/ZerodhaUpload/ZerodhaUpload";

import AdminScriptEdit from "./components/admintool9x/Script/ScriptEdit";

import AdminMember from "./components/admintool9x/Member/Member";

import AdminBookChapter from "./components/admintool9x/Book/BookChapter.js";
import AdminBookModule from "./components/admintool9x/Book/BookModule.js";
import AdminBook from "./components/admintool9x/Book/Book.js";


// import AdminIpoAlerts from "./components/admintool9x/IpoAlerts/IpoAlerts.js";

// --------------------------------------------------------------------------------------------------------------

import EventBus from "./common/EventBus";
import withAuthEditor from './common/withAuthEditor.js';
import withAuthMember from './common/withAuthMember';
import withAuthAdmin from './common/withAuthAdmin';
import Submission from "./components/root/Submission.js";
import ChangePassword from "./components/root/ChangePassword/ChangePassword.js";
import IPORecommendationList from "./components/root/IPORecommendation/IPORecommendationList.js";
import IPORecommendation from "./components/root/IPORecommendation/IPORecommendation.js";
import LoginForgetPassword from "./components/root/LoginForgetPassword/LoginForgetPassword.js";
import PostCommentChr from "./components/root/PostCommentChr/PostCommentChr.js";
import BrokerRating from "./components/root/BrokerRating/BrokerRating.js";
import IPOPostComment from "./components/root/IPOPostComment/IPOPostComment.js";
import IPOEditComment from "./components/root/IPOEditComment/IPOEditComment.js";
import IPOFaqAskQuestion from "./components/root/IPOFaqAskQuestion/IPOFaqAskQuestion.js";
import IPOEditBookmark from "./components/root/IPOEditBookmark/IPOEditBookmark.js";
import GMPList from "./components/IPOReport/GMPList.js";
import GMPEdit from "./components/IPOReport/GMPEdit.js";
import IPOReport from "./components/IPOReport/IPOReport.js";
import IpoReviewEditPublisher from "./components/IPOReport/IpoReviewEditPublisher.js";
import AuthorPeport from "./components/IPOReport/AuthorReport.js";
import IPOReviewList from "./components/IPOReport/IPOReviewList.js";
import LeadSmeIpoLm from "./components/root/LeadSmeIpoLm/LeadSmeIpoLm.js";
import NotFoundPage from "./components/common/NotFoundPage.js";
import ErrorPage from "./components/common/ErrorPage.js";
import { setMessage } from "./state/slices/message.js";
import SubscriptionFile from "./components/admintool9x/SubscriptionFile/SubscriptionFile.js";
import LogFile from "./components/admintool9x/LogFile/LogFile.js";
import TmpFile from "./components/admintool9x/TmpFile/TmpFile.js";
import LikeSpamFeed from "./components/root/LikeReportFeed/LikeReport.js";
import Cookies from "js-cookie";
import AdminOFS from "./components/admintool9x/OFS/OFS.js";
import ArticleReport from "./components/IPOReport/ArticleReport.js";
import CacheMaster from "./components/admintool9x/CacheMaster/CacheMaster.js";
import IpoFinalBidding from "./components/admintool9x/IpoFinalBidding/IpoFinalBidding.js";
import IpoQibAnchorMaster from "./components/admintool9x/IpoQibAnchorMaster/IpoQibAnchorMaster.js";
import AdminIpoListingNew from "./components/admintool9x/IPONew/IpoListingNew.js";


const ErrorPageWrapper = () => {
  const location = useLocation();
  const { errorDetails } = location.state || { errorDetails: 'Unknown error' };

  return <ErrorPage errorDetails={errorDetails} />;
};


const App = () => {
  const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(syncAuthState());

    const handleStorageEvent = () => {
      if (!Cookies.get("user")) {
        autoLogin();
      } else {
        dispatch(syncAuthState()); // Sync auth state if still logged in
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [dispatch]);

  const autoLogin = useCallback(() => {
    const storedEmail = Cookies.get("rememberMeEmail");
    const storedPassword = Cookies.get("rememberMePassword");

    if (storedEmail && storedPassword) {
      dispatch(login({ username: storedEmail, password: storedPassword, recaptcha: "" }))
        .unwrap()
        .then((data) => {
          if (data && data.user) {
            console.log("Auto-login successful!");
          }
        })
        .catch(() => {
          console.error("Auto-login failed. Redirecting to login page.");
        });
    } else {
      dispatch(logout());
      console.warn("No credentials found for auto-login.");
    }
  }, [dispatch]);

  useEffect(() => {

    // if (currentUser) {
    //   setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
    //   setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    // } else {
    //   setShowModeratorBoard(false);
    //   setShowAdminBoard(false);
    // }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Router>
      <Routes>

        <Route path='/' element={<Index />}>
          <Route path='/' element={<Home />} />
          <Route path='/default.asp' element={<Home />} />
          <Route path="/error" element={<ErrorPageWrapper />} />
          <Route path={routeConfig.contactus()} element={<Contact />} />
          <Route path={routeConfig.ipo_faq_ask_question()} element={<IPOFaqAskQuestion />} />
          <Route path={routeConfig.accessdenied()} element={<Login />} />
          <Route path={routeConfig.registration()} element={<Register />} />
          <Route path={routeConfig.forgot_password()} element={<LoginForgetPassword />} />
          <Route path={routeConfig.submission()} element={<Submission />} />
          <Route path={routeConfig.post_comment_chr()} element={<PostCommentChr />} />
          <Route path={routeConfig.broker_rating()} element={<BrokerRating />} />
          <Route path={routeConfig.leads_sme_ipo_lm()} element={<LeadSmeIpoLm />} />

        </Route>

        <Route path='/' Component={withAuthMember(Index)}>
          {/* Tools */}
          <Route path={routeConfig.tool_gmp_list()} element={<GMPList />} />
          <Route path={routeConfig.tool_gmp_edit()} element={<GMPEdit />} />
          {/* IPO - Reports  */}
          <Route path={`/LikeSpamFeed/:id/:pageType/:actionType`} element={<LikeSpamFeed />} />
          <Route path={routeConfig.author_report_home()} element={<IPOReport />} />
          <Route path={routeConfig.author_report()} element={<IPOReport />} />
          <Route path={routeConfig.author_report_edit()} element={<AdminReportCDN />} />
          <Route path={routeConfig.dd_review_list()} element={<IPOReviewList />} />
          <Route path={routeConfig.dd_review_edit()} element={<IpoReviewEditPublisher />} />


          {/* Root */}
          <Route path={routeConfig.ipo_edit_bookmark()} element={<IPOEditBookmark />} />
          <Route path={routeConfig.ipo_post_comment()} element={<IPOPostComment />} />
          <Route path={routeConfig.ipo_edit_comment()} element={<IPOEditComment />} />
          <Route path={routeConfig.profile()} element={<Profile />} />
          <Route path={routeConfig.change_pwd()} element={<ChangePassword />} />
          <Route path={routeConfig.ipo_recommendation_list()} element={<IPORecommendationList />} />
          <Route path={routeConfig.ipo_recommendation_post()} element={<IPORecommendation />} />
          <Route path="/author/article-list/:id?" element={<AdminArticle />} />
          <Route path="/author/article-report/" element={<ArticleReport />} />

        </Route>


        <Route path='/admintool9x' Component={withAuthEditor(AdminIndex)}>
          <Route path='' element={<AdminHome />} />
          <Route path='/admintool9x/default.asp' element={<AdminHome />} />
          <Route path='/admintool9x/errorreport/:id' element={<ErrorReport />} />
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* Other Tabs */}
          <Route path='/admintool9x/photogallery/:id?' element={<AdminPhotoGallery />} />
          <Route path='/admintool9x/googlesitemap/:id?' element={<AdminGoogleSitemap />} />
          <Route path='/admintool9x/code/:id?' element={<AdminCode />} />
          <Route path='/admintool9x/category/:id?' element={<AdminCategory />} />
          <Route path='/admintool9x/config/:id?' element={<AdminConfig />} />
          <Route path='/admintool9x/holidays/:id?' element={<AdminHolidays />} />
          <Route path={routeConfig.sqlfind()} element={<AdminSQLFind />} />
          <Route path={routeConfig.sql_editor()} element={<AdminSQLEditor />} />
          <Route path={routeConfig.subscriptionfile()} element={<SubscriptionFile />} />
          <Route path={routeConfig.logsfile()} element={<LogFile />} />
          <Route path={routeConfig.tmpsfile()} element={<TmpFile />} />
          <Route path='/admintool9x/fileupload' element={<AdminFileUpload />} />

          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* alert */}
          <Route path='/admintool9x/alert/:id?' element={<AdminAlert />} />
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* Messages */}
          <Route path='/admintool9x/ipofeedback/:id' element={<AdminIPOFeedback />} />
          <Route path='/admintool9x/ipofeedbackarchive/:id' element={<AdminIPOFeedbackArchive />} />
          <Route path='/admintool9x/iporecommendationmaster/:id?' element={<AdminIPORecommendation />} />
          <Route path='/admintool9x/iporecommendationdetail/:id?' element={<AdminIPORecommendationDetail />} />
          <Route path='/admintool9x/feedbackupdate/:id' element={<AdminFeedbackUpdate />} />
          <Route path='/admintool9x/spamfeedback' element={<AdminSpamFeedback />} />
          <Route path='/admintool9x/spamrecommendation' element={<AdminSpamRecommendation />} />
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* IPO New Tabs */}
          <Route path='/admintool9x/forthcomingipo/:id?' element={<AdminForthcomingIPO />} />
          <Route path='/admintool9x/ipo_date/:id?' element={<AdminIpoDate />} />
          <Route path='/admintool9x/ipodrhp/:id?' element={<AdminIpoDrhp />} />
          <Route path='/admintool9x/ipoobjectives/:id' element={<AdminIpoObjectives />} />
          <Route path='/admintool9x/ipoexpenses/:id' element={<AdminIpoExpenses />} />
          <Route path='/admintool9x/ipocompany/:id' element={<AdminIpoCompany />} />
          <Route path='/admintool9x/ipoissuedetail/:id' element={<AdminIPOIssueDetail />} />
          <Route path='/admintool9x/newipoanchorinvestor/:id' element={<AdminNewIpoAnchorInvestor />} />
          <Route path='/admintool9x/ipolisting/:id' element={<AdminIpoListing />} />
          <Route path='/admintool9x/ipolistingnew/:id' element={<AdminIpoListingNew />} />
          <Route path='/admintool9x/ipopeergroup/:id' element={<AdminIpoPeerGroup />} />
          <Route path='/admintool9x/pecalc/:id' element={<AdminIPOPECalc />} />

          {/* IPO Tabs */}
          <Route path='/admintool9x/ipo/:id?' element={<AdminIpo />} />


          <Route path='/admintool9x/ipoanchorinvestor/:id' element={<AdminIpoAnchorInvestor />} />
          <Route path='/admintool9x/ipomutualfund/:id' element={<AdminIpoMutualFund />} />
          <Route path='/admintool9x/ipoallotment/:id' element={<AdminIpoAllotment />} />
          <Route path='/admintool9x/ipoboa/:category_id/:id' element={<AdminIPOBOA />} />
          <Route path='/admintool9x/ipolistdaytrade/:id' element={<AdminIPOListDayTrade />} />
          <Route path='/admintool9x/ipobidding/:id?' element={<AdminIPOBidding />} />
          <Route path='/admintool9x/reportscdn/:id?' element={<AdminReportCDN />} />

          <Route path='/admintool9x/smemigrated/:id?' element={<AdminSMEMigrated />} />
          <Route path='/admintool9x/ipomanageripo/:id?' element={<AdminIPOManagerIPO />} />
          <Route path='/admintool9x/ipoanchor/:id?' element={<AdminIPOAnchor />} />
          <Route path='/admintool9x/ipoanchorscheme/:id?' element={<AdminIPOAnchorScheme />} />

          <Route path='/admintool9x/ipoqibanchormaster/:id' element={<IpoQibAnchorMaster />} />
          <Route path='/admintool9x/finalbidding/:id' element={<IpoFinalBidding />} />
          {/* Financial */}
          <Route path='/admintool9x/financial/:category/:id' element={<AdminFinancial />} />

          <Route path='/admintool9x/review/:id?' element={<AdminReview />} />
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* NCD */}
          <Route path='/admintool9x/ncd/:id?' element={<AdminNCD />} />
          <Route path='/admintool9x/ncdcompany/:id' element={<AdminNCDCompany />} />
          <Route path='/admintool9x/ncdlisting/:id' element={<AdminNCDListing />} />
          <Route path='/admintool9x/ncdbidding/:id' element={<AdminNCDBidding />} />
          <Route path='/admintool9x/ncdcouponrate/:id' element={<AdminNCDCouponRate />} />

          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* RI */}
          <Route path='/admintool9x/rightissue/:id?' element={<AdminRightIssue />} />

          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* Buyback */}
          <Route path='/admintool9x/buyback/:id?' element={<AdminBuyback />} />
          <Route path='/admintool9x/buybackbidding/:id?' element={<AdminBuybackBidding />} />
          {/* --------------------------------------------------------------------------------------------------------- */}

          {/* Report */}
          <Route path='/admintool9x/calendar/:category_cd/:id' element={<AdminCalendar />} />
          <Route path='/admintool9x/report-calendar/:id' element={<AdminReportCalendarShow />} />
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* Misc */}
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* --------------------------------------------------------------------------------------------------------- */}


          <Route path={routeConfig.report(":id")} element={<AdminReport />} />
          <Route path={routeConfig.report(":id", ":parameter_id")} element={<AdminReport />} />

          {/* <Route path='/admintool9x/ipoalerts/:id?' element={<AdminIpoAlerts />} /> */}

          {/* calendar */}

          {/* Misc Tabs */}
          {/* article */}
          <Route path='/admintool9x/article/:id?' element={<AdminArticle />} />
          {/* optionstrategy */}
          <Route path='/admintool9x/optionstrategy/:id?' element={<AdminOptionStrategy />} />
          {/* glossary */}
          <Route path='/admintool9x/glossary/:id?' element={<AdminGlossary />} />
          {/* Trading Platform */}
          <Route path='/admintool9x/tradingplatform/:id?' element={<AdminTradingPlatform />} />
          {/* faqpage */}
          <Route path='/admintool9x/faqpage/:id?' element={<AdminFaqPage />} />
          {/* OFS Page */}
          <Route path='/admintool9x/ofs/:id?' element={<AdminOFS />} />
          <Route path='/admintool9x/faq/:id?' element={<AdminFaq />} />

          {/* Broker */}
          <Route path='/admintool9x/tradingcompanymaster/:id?' element={<AdminTradingCompanyMaster />} />
          <Route path='/admintool9x/tradingcompanydetail/:id' element={<AdminTradingCompanyDetail />} />
          <Route path='/admintool9x/tradingcompanypages/:id' element={<AdminTradingCompanyPages />} />
          <Route path='/admintool9x/tradingcompanynritrading/:id' element={<AdminTradingCompanyNRITrading />} />
          <Route path='/admintool9x/tradingcompanydematcharges/:id' element={<AdminTradingCompanyDematCharges />} />
          <Route path='/admintool9x/tradingcompanyplans/:id?' element={<AdminTradingCompanyPlans />} />
          <Route path='/admintool9x/tradingcompanycommoncharges/:id' element={<AdminTradingCompanyCommonCharge />} />
          <Route path='/admintool9x/brokercomplaint/:id' element={<AdminBrokerComplaint />} />
          <Route path='/admintool9x/brokercomplaintupload' element={<AdminBrokerComplaintUpload />} />

          <Route path='/admintool9x/scriptedit/:id?' element={<AdminScriptEdit />} />


          {/* Book */}
          <Route path='/admintool9x/bookchapter/:id?' element={<AdminBookChapter />} />
          <Route path='/admintool9x/bookmodule/:id?' element={<AdminBookModule />} />
          <Route path='/admintool9x/book/:id?' element={<AdminBook />} />
          {/* Messages */}
          {/* 
          
          {/* --------------------------------------------------------------------------------------------------------- */}

          <Route path='/admintool9x/comparereport/:id?' element={<AdminCompareReport />} />
          <Route path='/admintool9x/buybackhistory/:id?' element={<AdminBuybackHistory />} />
        </Route>

        <Route path='/admintool9x' Component={withAuthAdmin(AdminIndex)} >
          <Route path='' element={<AdminHome />} />
          <Route path='/admintool9x/default.asp' element={<AdminHome />} />
          {/* Other */}
          <Route path='/admintool9x/reportaccess/:id?' element={<AdminReportAccess />} />
          <Route path='/admintool9x/todo' element={<AdminTodo />} />
          <Route path='/admintool9x/reportconfig/:id?' element={<AdminReportConfigKeywords />} />

          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* Report Tabs */}
          {/* calendar */}
          <Route path='/admintool9x/report-calendar/:id' element={<AdminReportCalendarShow />} />
          <Route path='/admintool9x/reportcalendar-admin/:id?' element={<AdminReportCalendar />} />
          <Route path='/admintool9x/reportcalendar-web/:id?' element={<AdminReportCalendarWeb />} />
          <Route path='/admintool9x/report-create/:id?' element={<AdminReportCreate />} />
          <Route path='/admintool9x/webreport-create/:id?' element={<AdminWebReportCreate />} />

          <Route path='/admintool9x/monitoringmaster/:id?' element={<AdminMonitoringMaster />} />
          <Route path='/admintool9x/alertactionmaster/:id?' element={<AdminAlertActionMaster />} />
          <Route path='/admintool9x/cronjob/:id?' element={<AdminCronjob />} />
          <Route path='/admintool9x/comparereport/:id?' element={<AdminCompareReport />} />

          {/* --------------------------------------------------------------------------------------------------------- */}

          <Route path='/admintool9x/member/:id' element={<AdminMember />} />
          <Route path={routeConfig.zerodha_upload()} element={<AdminZerodhaUpload />} />

          <Route path='/admintool9x/cache/:id?' element={<CacheMaster />} />
        </Route>


        {/* <Route path="/user" element={<BoardUser />} />
        <Route path="/mod" element={<BoardModerator />} />
        <Route path="/admin" element={<BoardAdmin />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>


    </Router >
  );
};

export default App;