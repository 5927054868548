import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';
import FileUpload from '../Common/FileUpload';
import { isValidImageFileName } from '../Common/CommonFunction';
import { replaceSpaceWithHiphen } from '../Common/CommonFunction';
import PhoneInput from '../Common/PhoneInput';

const NCDCompanyForm = ({ result, handleGoBack, initialValues, loading, handleSubmit }) => {

    const { values, setFieldValue } = useFormikContext()

    return (
        <>
            <Form id='myForm'>
                <div className="my-3 row">
                    <label htmlFor="company_name" className="col-lg-2">ID:</label>
                    <div className="col-lg-4">
                        <b>{values.ncd_id}</b>
                    </div>
                </div>

                <div className="my-3 row">
                    <label htmlFor="company_name" className="col-lg-2">Company Name</label>
                    <div className="col-lg-4">
                        <b>{values.company_name}</b>
                    </div>
                </div>
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="logo_url" className="col-lg-2">Logo Image Name:</label>
                    <div className="col-lg-3">
                        <Field as="input" className="form-control" type="text" maxLength="100" name="logo_url" tabIndex={1} onBlur={(e) => {
                            let name = replaceSpaceWithHiphen(e.target.value);
                            setFieldValue(e.target.name, name);
                            isValidImageFileName(name)
                        }} />
                    </div>
                    <label htmlFor="logo_length" className="col-lg-1">Width:</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} type="text" size="5" nameAtr={"logo_length"} tabIndex={5} />
                    </div>
                    <label htmlFor="logo_height" className="col-lg-1">Height:</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr={"form-control"} nameAtr={"logo_height"} tabIndex={10} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="address_1" className="col-lg-2">Address 1</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="address_1" maxLength="50" tabIndex={15} />
                    </div>
                    <label htmlFor="address_2" className="col-lg-2">Address 2</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="address_2" maxLength="50" tabIndex={20} />
                    </div>

                </div>
                <div className="mb-3 row">
                    <label htmlFor="address_3" className="col-lg-2">Address 3</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="address_3" maxLength="50" tabIndex={25} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="phone" className="col-lg-2">Phone</label>
                    <div className="col-lg-4">
                        <PhoneInput classNameAtr={"form-control"} nameAtr={"phone"} tabIndex={25} />
                    </div>
                    <label htmlFor="fax" className="col-lg-2">Fax</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="fax" maxLength="25" tabIndex={35} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="website" className="col-lg-2">Website</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="website" maxLength="50" tabIndex={40} />
                    </div>
                    <label htmlFor="email" className="col-lg-2">Email</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" name="email" maxLength="50" tabIndex={42} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="company_desc" className="col-lg-2">Company Desc. (html)</label>
                    <div className="col-lg-4">
                        <CharacterCountTextarea
                            nameattr="company_desc"
                            type="textarea"
                            tabIndex="45"
                            rows="8"
                            className="form-control"
                        />
                    </div>
                    <label htmlFor="issue_objects" className="col-lg-2">Objects Of The Issue (html)</label>
                    <div className="col-lg-4">
                        <CharacterCountTextarea
                            nameattr="issue_objects"
                            type="textarea"
                            tabIndex="50"
                            rows="8"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="financial" className="col-lg-2">Company Financial (html)</label>
                    <div className="col-lg-4">
                        <CharacterCountTextarea
                            nameattr="financial"
                            type="textarea"
                            tabIndex="55"
                            rows="8"
                            className="form-control"
                        />
                    </div>
                    <label htmlFor="promoters" className="col-lg-2">Company Promoters (html)</label>
                    <div className="col-lg-4">
                        <CharacterCountTextarea
                            nameattr="promoters"
                            type="textarea"
                            tabIndex="60"
                            rows="8"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="faqs" className="col-lg-2">FAQs</label>
                    <div className="col-lg-4">
                        <CharacterCountTextarea
                            nameattr="faqs"
                            type="textarea"
                            tabIndex="65"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>

                <hr />
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.ncdcompany !== undefined && result.ncdcompany.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}



export default NCDCompanyForm
