import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import FileUpload from '../Common/FileUpload';
import CharacterCountInput from '../Common/CharacterCountInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const ArticleForm = ({ pageData, result, handleGoBack, loading, initialValues }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.article !== undefined && result.article.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.article[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2 required">Category</label>
                    <div className="col-lg-4">
                        <Field as="select" name="category_cd" className="form-select" tabIndex={1}>
                            <option></option>
                            {result.articleCategory !== undefined && result.articleCategory.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.cat_name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div >

                <div className="mb-3 row">
                    <label htmlFor="title" className="col-lg-2 required">Title</label>
                    <div className="col-lg-4">
                        <CharacterCountInput type='text' className="form-control" maxLength="500" nameattr="title" tabIndex={5}
                            onBlur={(e) => {
                                const text = e.target.value;
                                if (text !== "") {
                                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                    if (userConfirmed) {
                                        var slug = funCreateSlug(text);
                                        setFieldValue('urlrewrite_folder_name', slug);
                                    } else {
                                        setFieldTouched('title', true);
                                    }
                                }
                            }}
                        />
                    </div>
                    <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
                    <div className="col-lg-4">
                        <CharacterCountInput className="form-control" type='text' nameattr="urlrewrite_folder_name" maxLength="100" tabIndex={10} />
                    </div>
                </div>

                <hr />
                <h3>Meta Data</h3>

                <div className="mb-3 row">
                    <label htmlFor="meta_title" className="col-lg-2 required">Meta Title (Max 80)</label>
                    <div className="col-lg-4">
                        <CharacterCountInput
                            nameattr="meta_title"
                            type="text"
                            maxLength="80"
                            tabIndex="15"
                            className="form-control"
                        />
                    </div>
                    <label htmlFor="meta_parent_page_url" className="col-lg-2">Parent Page URL</label>
                    <div className="col-lg-4">
                        <CharacterCountInput
                            nameattr="meta_parent_page_url"
                            type="text"
                            maxLength="200"
                            tabIndex="20"
                            className="form-control"
                        />
                        {/* <Field className="form-control" type='text' name="meta_parent_page_url" maxLength="200" tabIndex={20} /> */}
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2 required">Meta Description<br /> (Text 160 Char)</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="meta_desc"
                            maxLength="250"
                            type="textarea"
                            tabIndex="25"
                            rows="5"
                            className="form-control"
                        />
                    </div>
                </div>

                <hr />
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Image Name:</label>
                    <div className="col-lg-4">
                        <CharacterCountInput
                            nameattr="image_name"
                            type="text"
                            maxLength="100"
                            tabIndex="35"
                            className="form-control"
                        />
                        {/* <Field className="form-control" type="text" maxLength="100" name="image_name" tabIndex={35} /> */}
                    </div>
                    <label htmlFor="" className="col-lg-1">Width:</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="text" size="5" name="image_width" tabIndex={40} />
                    </div>
                    <label htmlFor="" className="col-lg-1">Height:</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="text" size="5" name="image_height" tabIndex={45} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="description" className="col-lg-2 required">Description<br /> (HTML)</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="description"
                            type="textarea"
                            tabIndex="50"
                            rows="15"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Author Name:</label>
                    <div className="col-lg-4">
                        <CharacterCountInput
                            nameattr="author_name"
                            type="text"
                            maxLength="40"
                            tabIndex="55"
                            disabled={pageData.disabled}
                            className="form-control"
                        />
                    </div>
                    <label htmlFor="" className="col-lg-2">Author Signature</label>
                    <div className="col-lg-4">

                        <Field
                            as="input"
                            list="authorSignatureList"
                            disabled={pageData.disabled}
                            name="author_signature_cd"
                            className="form-select"
                            tabIndex={60}
                        />
                        <datalist id="authorSignatureList">
                            {result.authorSignature?.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code}
                                </option>
                            ))}
                        </datalist>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Report IDs</label>
                    <div className="col-lg-2">
                        <CharacterCountInput
                            nameattr="report_ids"
                            type="text"
                            maxLength="40"
                            tabIndex="65"
                            className="form-control"
                        />
                        {/* <Field className="form-control" type="text" maxLength="10" name="report_ids" tabIndex={65} /> */}
                    </div>
                    <label htmlFor="" className="col-lg-2">Broker</label>
                    <div className="col-lg-4">
                        <Field as="select" name="broker_id" className="form-select" tabIndex={70}>
                            <option></option>
                            {result.brokerName !== undefined && result.brokerName.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.Company_Short_Name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type='number' name="display_order" tabIndex={75} />
                    </div>
                    <label htmlFor="display_status" className="col-lg-2">Display Status
                        <Field className="form-check-input ms-3" type="checkbox" name="display_status" tabIndex={80} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }} />
                    </label>

                    <label htmlFor="featured" className="col-lg-2">Featured
                        <Field className="form-check-input ms-3" type="checkbox" name="featured" tabIndex={85} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('featured', newValue);
                        }} />
                    </label>
                    <label htmlFor="sponsoured_status" className="col-lg-2">Sponsoured Status
                        <Field className="form-check-input ms-3" type="checkbox" name="sponsoured_status" tabIndex={80} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('sponsoured_status', newValue);
                        }} />
                    </label>
                </div>
                <hr />
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.article !== undefined && result.article.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default ArticleForm
