import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, setMessage } from "../../state/slices/message";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Message = () => {
    const { message } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const location = useLocation();
    const [urlChangeCount, setUrlChangeCount] = useState(0);

    useEffect(() => {
        console.log("urlChangeCount : " + urlChangeCount)
        // Increment the URL change count
        setUrlChangeCount(prevCount => prevCount + 1);

        // Clear the message after the second URL change
        if (urlChangeCount >= 1) {
            dispatch(clearMessage());
            setUrlChangeCount(0);
        }
    }, [location]);

    const handleClose = () => {
        setUrlChangeCount(0);
        dispatch(clearMessage());
    };

    const getAlertVariant = () => {
        return message.toLowerCase().includes("success") ? "success" : "danger";
    };

    return (
        <>
            {message && (
                <Alert
                    variant={getAlertVariant()}
                    className='mt-2 py-2'
                    dismissible
                    onClose={handleClose}
                >
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: message }} />
                </Alert>
            )}
        </>
    );

}

export default Message
