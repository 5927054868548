import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import SubscriptionFileForm from './SubscriptionFileForm';


const SubscriptionFile = () => {
    const location = useLocation();
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [directoryDomnain, setDirectoryDomnain] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    const [initialValues, setInitialValues] = useState({
        sqlquery: "",
    });

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            setMetaData({ "title": "Subscriptions Html File" })

            api.post('/admin-get-subscriptions-files', {
                folder_name: "subscription"
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data.results);
                    setDirectoryDomnain(response.data.directoryDomnain);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, [location]); // Run once on mount

    const handleOpenFile = (path) => {
        // Handle file opening (e.g., show file content or download)
        window.open(path, '_blank');
    };

    const handleDeleteFile = async (path) => {

        if (window.confirm("Are you sure to delete")) {
            dispatch(startLoading());
            api.post('/admin-delete-subscriptions-files', {
                path: path
            }).then(response => {
                if (response.data.msg === 1) {
                    const newResult = deleteFromResult(result, path);
                    setResult(newResult);
                    dispatch(setMessage('File deleted successfully'));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                dispatch(setMessage('Failed to delete file'));
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }
    };

    const deleteFromResult = (result, pathToDelete) => {
        return result.filter(item => {
            if (item.path === pathToDelete) {
                return false; // Remove this item
            }
            if (item.subItems) {
                item.subItems = deleteFromResult(item.subItems, pathToDelete);
            }
            return true; // Keep this item
        });
    };
    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row'>
                <div className='col-12'>
                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />
                    <a target="_blank" href="https://chittorgarh.ipoplus.in/api/GetIPOList">Api All Acive IPO</a> | <a target="_blank" href="https://chittorgarh.ipoplus.in/api/BulkSubscription">IPO Bulk Api</a> | <a target="_blank" href="https://chittorgarh.ipoplus.in/api/IPOallSubscription?iponame=MCON">Api specific ipo</a>

                    <ListGroup className="mt-2">
                        {result.map((item) => (
                            <SubscriptionFileForm key={item.path} item={item} onOpen={handleOpenFile} onDelete={handleDeleteFile} directoryDomnain={directoryDomnain} />
                        ))}
                    </ListGroup>
                </div>
            </div>

        </>
    )
}

export default SubscriptionFile
