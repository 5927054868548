import React, { useState } from 'react';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import { funFormatDateTime } from '../Common/CommonFunction';

function LogFileForm({ item, onOpen, onDelete, directoryDomain, parentItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const totalFolders = item.subItems.filter(sub => sub.isdirectory).length;
  const totalFiles = item.subItems.filter(sub => !sub.isdirectory).length;

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOpen = (e) => {
    e.preventDefault();
    if (!item.isdirectory) {
      onOpen(item.path);
    }
  };

  const handleDelete = () => onDelete(item.path);

  return (
    <ListGroup.Item className="p-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {item.isdirectory && (
            <Button
              style={{
                fontSize: '1.5rem',
                color: '#007bff',
                border: 'none',
                background: 'none',
                transition: 'color 0.3s ease',
              }}
              onMouseEnter={(e) => e.currentTarget.style.color = '#0056b3'}
              onMouseLeave={(e) => e.currentTarget.style.color = '#007bff'}
              variant="link"
              onClick={handleToggle}
              aria-expanded={isOpen}
              aria-controls={`collapse-${item.path}`} // Added accessibility
              className="me-2 text-decoration-none"
            >
              {isOpen ? '-' : '+'}
            </Button>
          )}
          <div>
            {item.isdirectory ? (
              <>
                <strong className='me-5'>{item.name}</strong>
                <small className='me-5'>Creation Time: {funFormatDateTime(item.ctime)}</small>
                <small className='me-5'>Last Modified: {funFormatDateTime(item.mtime)}</small>
                <small>Total Folder & Files: <strong>{totalFolders}/{totalFiles}</strong></small>
              </>
            ) : (
              <a
                href="#"
                onClick={handleOpen}
                className="text-decoration-none"
                aria-label={`Open ${item.name}`}
              >
                {item.name}
              </a>
            )}
          </div>
        </div>
        <Button variant="danger" size="sm" onClick={handleDelete} aria-label={`Delete ${item.name}`}>
          <i className='fa fa-trash'></i>
        </Button>
      </div>
      {item.isdirectory && (
        <Collapse in={isOpen} id={`collapse-${item.path}`}>
          <div>
            {item.subItems.length > 0 && (
              item.subItems.map((subItem) => (
                <LogFileForm
                  key={subItem.path}
                  parentItem={item}
                  item={subItem}
                  onOpen={onOpen}
                  onDelete={onDelete}
                  directoryDomain={directoryDomain}
                />
              ))
            )}
          </div>
        </Collapse>
      )}
    </ListGroup.Item>
  );
}

export default LogFileForm;
