import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPOBiddingForm from './IPOBiddingForm';
import routeConfig from '../../../routeConfig';

const IPOBidding = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        ipo_id: "",
        bidding_time: "",
        bidding_date: "",
        company_name: "",
        qib_offered: "",
        nii_offered: "",
        nse_symbol: "",
        bse_scripcode: "",
        issue_category: "",
        ipo_listing_at: "",
        nii_offered_big: "",
        nii_offered_small: "",
        rii_offered: "",
        emp_offered: "",
        other_offered: "",
        total_offered: "",
        qib: "",
        nii: "",
        nii_big: "",
        nii_small: "",
        rii: "",
        emp: "",
        other: "",
        total: "",
        qib_shares_bid_for: "",
        nii_shares_bid_for: "",
        nii_shares_bid_for_big: "",
        nii_shares_bid_for_small: "",
        rii_shares_bid_for: "",
        emp_shares_bid_for: "",
        other_shares_bid_for: "",
        total_shares_bid_for: "",
        display_status: false,
        rii_by_application: "",
        total_application: "",
        no_of_application: "",
        date_added: "",
        lock_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New IPOBidding" })
            } else {
                setMetaData({ "title": "Edit IPOBidding" })
            }
            api.post('/admin-ipobidding-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.ipoBidDetail);
                    if (!isAddMode) {
                        setInitialValues(response.data.ipoBidDetail[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ipoBidDetailNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-ipobidding-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);

    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            
            <div>
                {result && result.length !== 0 && result.ipoBidDetail && result.ipoBidDetail[0] && result.ipoBidDetail[0].nse_symbol && result.ipoBidDetail[0].nse_symbol !== "" && <p class="mb-1"><a href={"https://chittorgarh.ipoplus.in/api/IPOallSubscription?iponame=" + result.ipoBidDetail[0].nse_symbol} target="_blank">{result.ipoBidDetail[0].nse_symbol} API link</a></p>}
                <p class="mb-1"><a href="https://chittorgarh.ipoplus.in/api/GetIPOList" target="_blank">All Active IPOs Today</a></p>
                <p class="mb-0"><a href={routeConfig.subscriptionfile()} target="_blank">Subscription Folder link</a></p>
            </div>
            <br />
            {result !== undefined &&
                result.ipoBidDetail !== undefined &&
                result.ipoBidDetail.length > 0 &&
                result.ipoBidDetail[0].lock_status == 1 && (
                    <h3 className="text-danger ">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <br />

            <IPOBiddingForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />

        </>
    )
}

export default IPOBidding
