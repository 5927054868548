import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const validationSchema = Yup.object({
    config_variable: Yup.string().trim().required('config_variable is required').max(100, 'config_variable must be at most 100 characters'),

});

const ConfigForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >


                <Form id='myForm'>
                    {result !== undefined && result.config !== undefined && result.config.length > 0 &&
                        <div className="mb-3 row">
                            <label htmlFor="" className="col-lg-2">ID:</label>
                            <div className="col-lg-4">
                                {result.config[0].id}
                            </div>
                        </div>
                    }

                    <div className="mb-3 row">
                        <label htmlFor="config_variable" className="col-lg-2 required">Config Variable Name</label>
                        <div className="col-lg-4">
                            <Field name="config_variable" type="text" size="100" maxLength="100" className="form-control" tabIndex={1}
                            />
                        </div>
                    </div >

                    <div className="mb-3 row">
                        <label htmlFor="config_value" className="col-lg-2">Config Value</label>
                        <div className="col-lg-10">

                            <CharacterCountTextarea
                                nameattr="config_value"
                                type="textarea"
                                tabIndex="10"
                                rows="15"
                                className="form-control"
                            />
                        </div>

                    </div>


                    <div className="mb-3 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.config !== undefined && result.config.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>

                        <button
                            type="button"
                            className="btn btn-secondary btn-block ms-2"
                            onClick={handleGoBack}
                        >Cancel</button>
                    </div>
                    <ValidateFormFields />
                </Form>


            </Formik>
        </>
    )
}

export default ConfigForm
