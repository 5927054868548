import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';

const validationSchema = Yup.object({
    code: Yup.string().trim().required('code is required'),
    code_type: Yup.string().trim().required('Code type is required'),
    code_desc: Yup.string().trim().required('Code desc is required').max(3000, "Code description can be at max 3000 characters long"),
    display_order: Yup.string().trim().required('Display Order is required')

});

const CodeForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (

                    <Form id='myForm'>
                        {result !== undefined && result.code !== undefined && result.code.length > 0 &&
                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">ID:</label>
                                <div className="col-lg-4">
                                    {result.code[0].id}
                                </div>
                            </div>
                        }
                        <div className="mb-3 row">
                            <label htmlFor="code" className="col-lg-2 required">Code</label>
                            <div className="col-lg-4">
                                <Field name="code" className="form-control" tabIndex={1} maxLength="50" />

                            </div>
                            <label htmlFor="code_type" className="col-lg-2 required">Code Type</label>
                            <div className="col-lg-4">
                                <Field className="form-control" list="code_typelist" name="code_type" tabIndex={5} maxLength="25" />
                                <datalist id='code_typelist'>
                                    {result.codeTypeList !== undefined && result.codeTypeList.map((option) => (
                                        <option key={option.code_type} value={option.code_type}></option>
                                    ))}
                                </datalist>
                            </div>
                        </div >


                        <div className="mb-3 row">
                            <label htmlFor="code_desc" className="col-lg-2 required">Code Desc. (Max 3000) </label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInputText className="form-control" nameattr={"code_desc"} tabIndex={10} rows="10" maxLength="3000" />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="display_order" className="col-lg-2 required">Display Order</label>
                            <div className="col-lg-4">
                                <NumberInput classNameAtr={"form-control"} nameAtr={"display_order"} maxLength={"4"} tabIndexAtr={"15"} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="code_status" className="col-lg-2">Code Status</label>
                            <div className="col-lg-4">
                                <Field className="form-check-input" type='checkbox' name="code_status" tabIndex={20} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue('code_status', newValue);
                                }} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                            <div className="col-lg-4">
                                <Field className="form-check-input" type='checkbox' name="lock_status" tabIndex={25} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue('lock_status', newValue);
                                }} />
                            </div>

                        </div>


                        <div className="mb-3 text-center">
                            {initialValues.lock_status !== 1 && (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={30}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        {result.code !== undefined && result.code.length > 0 ? 'Update' : 'Create'}
                                    </span>
                                </button>
                            )}

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={35}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>

                )}
            </Formik>
        </>
    )
}

export default CodeForm
