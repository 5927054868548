import React from 'react'
import { Field, Form } from 'formik';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';

const ChangePasswordForm = ({ btnClick, loading, initialValues, currentUser }) => {

  return (
    <>
      <Form id='myForm' className='main'>
        <div className="row">
          <div className="col-12">
            <legend>Change Password</legend>
            <p>Hello {currentUser && currentUser.display_name}, <br />Please fill the below information to change your password.</p>
          </div>
        </div>
        <div className="mb-3 row">
          <label for="login_id" className="col-md-2">Login ID:</label>
          <div className="col-md-4">
            <b>{currentUser && currentUser.email}</b>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="name" className="col-md-2">Current Password</label>
          <div className="col-md-4">
            <Field type="password" name="old_password" placeholder="Your Current Password" className="form-control" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="name" className="col-md-2">New Password</label>
          <div className="col-md-4">
            <Field type="password" name="new_password" placeholder="Your New Password" className="form-control" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="name" className="col-md-2">Confirm Password</label>
          <div className="col-md-4">
            <Field type="password" name="confirm_password" placeholder="Your Confirm Password" className="form-control" maxLength="50" />
          </div>
        </div>

        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-warning btn-sm btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                Change Password
              </span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary btn-block btn-sm ms-2"
            onClick={btnClick}
          >Close</button>
        </div>
        <ValidateFormFields />
      </Form >

    </>
  )
}

export default ChangePasswordForm
