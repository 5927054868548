import React from 'react'
import { Field, Formik, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import * as Yup from 'yup';
import NumberDecimalInput from '../Common/NumberDecimalInput';


const validationSchema = Yup.object({
  ipo_id: Yup.string().trim().required('Buyback Company is required'),
  bidding_date: Yup.string().trim().required('Bidding Date is required'),
  bidding_time: Yup.string().trim().required('Bidding Time is required'),
});

const buybackBiddingForm = ({ result, handleGoBack, initialValues, handleSubmit, loading, }) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (

          <Form id='myForm'>
            {result !== undefined && result.buybackBidDetail !== undefined && result.buybackBidDetail.length > 0 &&
              <div>
                <div className="mb-3 row">
                  <label htmlFor="" className="col-lg-2">ID:</label>
                  <div className="col-lg-4">
                    {result.buybackBidDetail[0].id}
                  </div>
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="ipo_id" className="col-lg-2 required">Issuer Company</label>
              <div className="col-lg-4">
                <Field className="form-select" as="select" tabIndex={1} name="ipo_id">

                  <option >Select company</option>
                  {result.buybackList !== undefined && result.buybackList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.company_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NSE Symbol</label>
              <div className="col-lg-2">
                <p>{result.buybackBidDetail?.[0]?.nse_symbol}</p>
              </div>
              <label htmlFor="" className="col-lg-2">BSE Scripcode</label>
              <div className="col-lg-2">
                <p>{result.buybackBidDetail?.[0]?.bse_scripcode}</p>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="mb-3 row">
                <label htmlFor="" className="col-lg-2 required">Bid Date</label>
                <div className="col-lg-2">
                  <Field type="date" tabIndex={2} name="bidding_date" className="form-control" placeholderText="yyyy/mm/dd" />
                </div>

                <label htmlFor="" className="col-lg-2 required">Bid Time (HH:MM) IST</label>
                <div className="col-lg-1">
                  <Field className="form-control" type="time" tabIndex={5} name="bidding_time" defaultValue="17:00:00" />
                </div>
              </div>
            </div>
            <hr />
            <br />
            <div className="mb-3 row">
              <label htmlhtmlFor="" className="col-lg-2"></label>
              <div className="col-lg-2"><b>Shares Offered (Total)</b></div>
              <div className="col-lg-2"><b>Shares Bid For (BSE + NSE)</b></div>
              <div className="col-lg-2"><b>Number of Time Subscribed (BSE + NSE)</b></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2" required>QIB</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={10} nameAtr='qib_offered'
                  name="qib_offered" />
              </div>
              <div className="col-lg-2 ">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={15} nameAtr='qib_shares_bid_for'
                />
              </div>
              <div className="col-lg-2 ">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={20} nameAtr={'qib'} maxLength="8" />
                <span className="form-control-feedback required">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={25} nameAtr='nii_offered'
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={30} nameAtr='nii_shares_bid_for'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={35} nameAtr={'nii'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII (Bid&lt;10L)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={40} nameAtr='nii_offered_big'
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={45} nameAtr='nii_shares_bid_for_big'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={50} nameAtr={'nii_big'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>

            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">NII (Bid&lt;10L)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={55} nameAtr='nii_offered_small'
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={60} nameAtr='nii_shares_bid_for_small'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={65} nameAtr={'nii_small'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Retail</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={70} nameAtr='rii_offered' />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={75} nameAtr='rii_shares_bid_for' />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={80} nameAtr={'rii'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Employee</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={85} nameAtr='emp_offered' />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={90} nameAtr='emp_shares_bid_for'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={95} nameAtr={'emp'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Others</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={100} nameAtr='other_offered'
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={105} nameAtr='other_shares_bid_for'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={110} nameAtr={'other'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Total</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={115} nameAtr='total_offered'
                />
              </div>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={120} nameAtr='total_shares_bid_for'
                />
              </div>
              <div className="col-lg-2">
                <NumberDecimalInput classNameAtr={"form-control"} tabIndexAtr={125} nameAtr={'total'} maxLength="8" />
                <span className="form-control-feedback">times</span>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">RII By Applications (x)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" type='text' tabIndexAtr={130} nameAtr='rii_by_application' />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Total Application</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr="form-control" tabIndexAtr={135} nameAtr='total_application' maxLength="10" />

              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">No Of Application (x)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} tabIndexAtr={140} nameAtr={"no_of_application"} maxLength="10" />
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">Display Status</label>
              <div className="col-lg-4">
                <Field className="form-check-input" type="checkbox" tabIndex={145} name="display_status" onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
              </div>
            </div>

            <div className="mb-3 text-center">
              {initialValues.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={150}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.buybackBidDetail !== undefined && result.buybackBidDetail.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              )}

              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={155}
              >Cancel</button>
            </div>

            <p>Note:</p>
            <ol>
              <li>If you missed inserting a record, use <b>9999</b> as place holder. On web page it will look like <b>-</b>.</li>
            </ol>
            <ValidateFormFields />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default buybackBiddingForm





