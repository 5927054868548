import React from 'react'
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';

const BuybackHistoryForm = ({ remove }) => {

    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            {values.buybackHistoryChild.map((child, index) => (
                <tr key={index}>
                    <td>
                        <Field className="form-control dateInput" type="date" maxlength="15"
                            name={`buybackHistoryChild.${index}.bb_dt`} />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`buybackHistoryChild.${index}.bb_share_capital`} placeholder=""
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`buybackHistoryChild.${index}.bb_price`} placeholder="" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`buybackHistoryChild.${index}.bb_total_amt`} placeholder="" />

                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`buybackHistoryChild.${index}.bb_acceptance_ratio`} placeholder="" />
                    </td>

                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`buybackHistoryChild.${index}.display_status`} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue(e.target.name, newValue);
                                }}
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default BuybackHistoryForm
