import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const OFSTabs = ({ idAtr }) => {

  return (
    <Nav variant="tabs" className='mb-4'>
    <Nav.Item>
      <NavLink to={`/admintool9x/ofs/${idAtr}`} className={`nav-link`}>OFS Details</NavLink>
    </Nav.Item>
    <Nav.Item>
      <NavLink to={`/admintool9x/financial/ofs/${idAtr}`} className={`nav-link`}>Financial</NavLink>
    </Nav.Item>
  </Nav>
  );
};

export default OFSTabs;
