import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL + "auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password, recaptcha) => {
  return axios
    .post(API_URL + "signin", {
      "username": username,
      "password": password,
      "recaptcha": recaptcha
    })
    .then((response) => {

      if (response.data.msg === 1) {
        if (response.data.accessToken) {
          Cookies.set("user", JSON.stringify(response.data), {
            domain: process.env.REACT_APP_COOKIEE_URL,
            path: '/',
            expires: 30,
            secure: true,
            sameSite: 'Lax'
          });

          if (response.data.role_id == 3) {
            Cookies.set("isSecondVerificationNeeded", "true", { secure: true, sameSite: 'Strict', expires: 30 });
          }
        }
      }
      return response.data;
    })
    .catch((error) => {
      // Handle errors
      console.error('Error during login:', error.message);
    });
  ;
};

const googleLoginFunc = (googleToken) => {

  return axios
    .post(API_URL + "google-signin", {
      googleToken: googleToken
    })
    .then((response) => {

      if (response.data.msg === 1) {
        if (response.data.accessToken) {
          Cookies.set("user", JSON.stringify(response.data), {
            domain: process.env.REACT_APP_COOKIEE_URL,
            path: '/',
            expires: 30,
            secure: true,
            sameSite: 'Lax'
          });
          if (response.data.role_id == 3) {
            Cookies.set("isSecondVerificationNeeded", "true", { secure: true, sameSite: 'Strict', expires: 30 });
          }
        }
      }
      return response.data;
    })
    .catch((error) => {
      // Handle errors
      console.error('Error during login:', error.message);
    });
  ;
};

const login2 = (username, password) => {
  return axios
    .post(API_URL + "signin2", {
      "username": username,
      "password": password,
    })
    .then((response) => {

      if (response.data.msg === 1) {
        Cookies.set("isSecondVerificationNeeded", "false", { secure: "true", sameSite: 'Strict', expires: 30 });
      }
      return response.data;
    })
    .catch((error) => {
      // Handle errors
      console.error('Error during second step login:', error.message);
    });
};

const logout = () => {
  Cookies.remove("user");
  Cookies.remove("isSecondVerificationNeeded");
  // window.dispatchEvent(new Event("storage"));
  localStorage.setItem('logoutEvent', Date.now());
};

const isTokenExpired = () => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    if (!user || !user.accessToken) return true;

    const decoded = jwtDecode(user.accessToken);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

const isTokenExpiringSoon = (thresholdInMinutes = 5) => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    if (!user || !user.accessToken) return false;

    const decoded = jwtDecode(user.accessToken);
    const tokenExpiryTime = decoded.exp * 1000;
    const currentTime = Date.now();
    const timeUntilExpiry = tokenExpiryTime - currentTime;
    const thresholdInMs = thresholdInMinutes * 60 * 1000;

    const expiringSoon = timeUntilExpiry > 0 && timeUntilExpiry <= thresholdInMs;
    const minutesRemaining = Math.floor(timeUntilExpiry / (60 * 1000));
    const secondsRemaining = Math.floor((timeUntilExpiry % (60 * 1000)) / 1000);
    return {
      expiringSoon,
      minutesRemaining,
      secondsRemaining,
      tokenExpiryTime,
      currentTime,
      timeUntilExpiry,
      thresholdInMs,
    };
  } catch (error) {
    return false;
  }
};

const authService = {
  register,
  login,
  login2,
  logout,
  isTokenExpired,
  isTokenExpiringSoon,
  googleLoginFunc
};

export default authService;
