import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { selectLoading } from '../../../state/slices/loadingSlice';
import { useSelector } from 'react-redux';
const IpoTabs = ({ idAtr }) => {

  const loading = useSelector(selectLoading);
  return (
    <>
      {loading ? (
        <div className='ms-4 mb-4' style={{ height: '42px' }}>
          <span className="spinner-border spinner-border-sm me-2"></span>
        </div>
      ) :

        <Nav variant="tabs" className='mb-4'>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipo/${idAtr}`} className={`nav-link`}>Info</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipocompany/${idAtr}`} className="nav-link">Company</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipoobjectives/${idAtr}`} className="nav-link">Objective</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipodrhp/${idAtr}`} className="nav-link">DRHP</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipolisting/${idAtr}`} className="nav-link">Listing</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/financial/ipo/${idAtr}`} className="nav-link">Financial</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipopeergroup/${idAtr}`} className={`nav-link`}>Peer Group</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipoanchorinvestor/${idAtr}`} className={`nav-link`}>Anchor Investor</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipomutualfund/${idAtr}`} className={`nav-link`}>Mutual Fund</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/pecalc/${idAtr}`} className={`nav-link`}>PE Calc</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/finalbidding/${idAtr}`} className={`nav-link`}>Final Bidding</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/ipoqibanchormaster/${idAtr}`} className={`nav-link`}>Qib Nii</NavLink>
          </Nav.Item>
        </Nav>
      }
    </>
  );
};

export default IpoTabs;
