import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import NCDBiddingForm from './NCDBiddingForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const NCDBidding = () => {
    const validationSchema = Yup.object({
        bid_date: Yup.string().trim().required('Bidding Date is required'),
        bid_time: Yup.string().trim().required('Bidding Time is required'),
        category1: Yup.number('Please type Numeric Digits in Category 1'),
        category2: Yup.number('Please type Numeric Digits in Category 2'),
        category3: Yup.number('Please type Numeric Digits in Category 3'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        ncd_id: "",
        company_name: "",
        bid_date: "",
        bid_time: "",
        category1_offered: "",
        category2_offered: "",
        category3_offered: "",
        category4_offered: "",
        other_offered: "",
        total_offered: "",
        category1_shares_bid_for: "",
        category2_shares_bid_for: "",
        category3_shares_bid_for: "",
        category4_shares_bid_for: "",
        other_shares_bid_for: "",
        total_shares_bid_for: "",
        category1: "",
        category2: "",
        category3: "",
        category4: "",
        other: "",
        total: "",
        display_status: "",
        listing_at: "",
        nse_symbol: "",
        bse_scriptcode: "",
        lock_status: "",
        date_added: "",
        digitInput: '1',
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New NCD Bidding" })
            } else {
                setMetaData({ "title": "Edit NCD Bidding" })
            }
            api.post('/admin-ncdbidding-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.ncdBidDetail);
                    if (!isAddMode) {
                        setInitialValues(response.data.ncdBidDetail[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ncdBidDetailNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.bid_date = `${trimmedValues.bid_date} ${trimmedValues.bid_time}`;

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-ncdbidding-save', {
            values: trimmedValues,
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.ncdBidDetail !== undefined &&
                result.ncdBidDetail.length > 0 &&
                result.ncdBidDetail[0].lock_status == 1 && (
                    <h3 className="text-danger mt-3 mb-0">
                        Record Is Locked , you can't update it.
                    </h3>

                )}

            <br />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <NCDBiddingForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}

export default NCDBidding
