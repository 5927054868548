
import React from 'react';
import useApi from '../../../common/useApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../state/slices/message';

const SpamRecommendationTable = ({ spam_message, index, updateArray, setUpdateArray, callSpamList }) => {
    const api = useApi();
    const dispatch = useDispatch();

    const handleArray = (c) => {
        if (c) {
            if (!updateArray.includes(`${spam_message.id}-${spam_message.recommendation}`)) {
                setUpdateArray([...updateArray, `${spam_message.id}-${spam_message.recommendation}`]);
            }
        }
        if (!c && updateArray.length > 0) {
            setUpdateArray(prevArray => prevArray.filter(item => item != `${spam_message.id}-${spam_message.recommendation}`));
        }
    }

    const fnAction = (spam) => {
        if (window.confirm("Are you sure")) {
            api.post('/admin-recommendation-feedback-spam-action', {
                "values": {
                    "id": spam_message.id,
                    "spam": spam,
                    "recommendation": spam_message.recommendation
                }
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    callSpamList();
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
            });
        }
    }
    return (
        <>
            <tr key={index}>
                <td>
                    <div><p>{spam_message.id}</p></div>
                </td>
                <td>
                    <div>{spam_message.ipo_id}</div>
                </td>
                <td>
                    <div><p>{spam_message.company_name}</p></div>
                </td>
                <td>
                    <a href="#" style={{ color: 'red' }} title="Yes, It's spam." onClick={() => fnAction(1)}>Yes</a> &nbsp;| &nbsp;
                    <a href="#" style={{ color: 'green' }} title="No, It's not spam." onClick={() => fnAction(0)}>No</a>
                </td>
                <td>
                    <div><p>{spam_message.recommendation}</p></div>
                </td>
                <td>
                    <div><p>{spam_message.hold_position}</p></div>
                </td>
                <td>
                    <div>{spam_message.subscribe_at_price_band}</div>
                </td>
                <td>
                    <div>{spam_message.recommendation_desc}</div>
                </td>
                <td>
                    <div>{spam_message.posted_date}</div>
                </td>
                <td>
                    <div>{spam_message.member_id}</div>
                </td>
                <td>
                    <div>{spam_message.posted_by_ip}</div>
                </td>
                <td className="text-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={updateArray.includes(`${spam_message.id}-${spam_message.recommendation}`)}
                        onChange={(e) => {
                            handleArray(e.target.checked);
                        }}
                    />

                </td>
            </tr>
        </>
    )
}

export default SpamRecommendationTable;
