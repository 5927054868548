import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi.js';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice.js';
import { setMessage } from '../../../state/slices/message.js';
import WebReportForm from './WebReportForm.js';
import * as Yup from "yup";
import { Button, Modal, Nav, Tab, Table } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import './Report.css';
import NumberInput from '../Common/NumberInput.js';


const validationSchema = Yup.object({
    report_name: Yup.string().trim().required('Please enter the Report Name'),
    report_title: Yup.string().trim().required('Please enter the Report Title'),
    report_type_id: Yup.string().trim().required('Please enter Report Type Id'),
    category_cd: Yup.string().trim().required('Please enter Report Category'),
    urlrewrite_folder_name: Yup.string().trim().required('Urlrewrite Folder Name is required'),
});

const WebReport = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const handleShowNew = () => {
        resetParam();
        setShowModal(true);
    }
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [initialValues, setInitialValues] = useState({

        id: "",
        report_name: "",
        report_title: "",
        report_type_id: "",
        category_cd: "",
        logo_url: "",
        logo_width: "",
        logo_height: "",

        // ==== Web Report Values ====

        meta_title: "",
        meta_desc: "",

        // ==== Meta Tags Value ======

        table_heading: "",
        report_sql: "",
        group_by: "",
        default_sort: "",
        having_clause: "",
        pre_sql: "",
        field_mapping_for_sorting: "",
        report_sql_count: "",

        // ===== Query Setup Values =====

        report_description_keyword: "",
        report_description: "",
        report_disclaimer: "",
        sponsored_rows: "",

        // ==== Description Values =====

        minimum_nav_year: "",
        page_size: "",
        link_field_name: "",
        link_file_name: "",
        search_column_names: "",
        sorting_disable_columns: "",
        disable_formating_columns: "",
        additional_link: "",
        status_field_name: "",

        // ====== Others Values =======

        additional_parameter: "",
        sql_dropdown: "",

        // ==== Additional Param Values =====

        chart_type_id: "",
        chart_x_axis: "",
        chart_y_axis: "",

        // ===== Chart Values ======

        compare_status: "",
        compare_link: "",

        // ===== Compare Features ====

        display_order: "",
        report_ids: "",
        urlrewrite_folder_name: "",
        meta_parent_page_url: "",
        list_view_status: false,
        featured_status: false,
        display_status: false,
        lock_status: false,
        page_faqs_status: false,
        feedback_status: false,

        // ==== Display Setup Values ====
    });

    const [initialModalValues, setInitialModalValues] = useState({
        id: "",
        reports_master_web_id: id,
        code: "",
        code_desc: "",
        table_heading: "",
        report_title: "",
        report_description: "",
        meta_desc: "",
        meta_title: "",
        columns_not_needed: "",
        report_disclaimer: "",
        display_order: "",
        display_status: false
    })

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/58"); // Navigate back one step in history
    };

    const setWebReportPage = () => {
        dispatch(startLoading());
        console.log("id : " + id);
        if (isAddMode) {
            setMetaData({ "title": "Add New Web Report" })
        } else {
            setMetaData({ "title": "Edit Existing Web Report" })
        }
        api.post('/admin-webreport-read', {
            id: id
        }).then(response => {
            if (response.data.msg === 1) {
                setResult(response.data);
                console.log("This is result: ", result)
                console.log(response.data.webReport);
                if (!isAddMode) {
                    setInitialValues(response.data.webReport[0]);
                }
            } else {
                dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
        }).catch(error => {
            console.error('Error:', error);
            dispatch(finishLoading());
        });
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            setWebReportPage();
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-webreport-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/58");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());

        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    const handleModalSubmit = (values, { setSubmitting, }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-webreport-parameter-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                setWebReportPage();
                handleClose();
                resetParam();
                dispatch(setMessage(response.data.success));
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);

            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    const resetParam = () => {
        setInitialModalValues({
            id: "",
            reports_master_web_id: id,
            code: "",
            meta_desc: "",
            code_desc: "",
            report_title: "",
            table_heading: "",
            meta_title: "",
            columns_not_needed: "",
            report_description: "",
            report_disclaimer: "",
            display_order: "",
            display_status: false
        })
    }
    const editParam = (id) => {
        dispatch(startLoading());
        console.log("id : " + id);
        api.post('/admin-webreport-parameter-read', {
            id: id
        }).then(response => {
            console.log(response.data);
            if (response.data.msg === 1) {
                setInitialModalValues(response.data.reportParameter[0]);
                handleShow();
            } else {
                dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {(result !== undefined && result.webReport !== undefined && result.webReport.length > 0 && result.webReport[0].additional_parameter == 1) ?
                <Tab.Container defaultActiveKey="link-1">
                    <Nav variant="tabs" className="mt-2">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2">Parameter</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="link-1" className='pt-3 px-2'>
                            <h1>
                                {metaData.title}
                            </h1>
                            <hr />

                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                <WebReportForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                            </Formik>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-2" className='pt-3'>
                            <div className="row">
                                {result != undefined && result.webReport != undefined &&
                                    result.webReport.length > 0 &&
                                    result.webReport[0].lock_status !== undefined &&
                                    result.webReport[0].lock_status == 1 && (
                                        <h3 className="text-danger">
                                            Record Is Locked , you can't update it.
                                        </h3>
                                    )}
                                <br />
                                <div className="col-12 mb-2">
                                    {initialValues.lock_status !== 1 && (
                                        <button type="button" className="btn btn-primary btn-sm mt-2"
                                            onClick={handleShowNew}>
                                            Add New
                                        </button>
                                    )}
                                </div>
                                <div className="col-12">
                                    {result.reportParameters !== undefined && result.reportParameters.length > 0 &&
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    {Object.keys(result.reportParameters[0]).map((key) => (
                                                        <th key={key}>{key}</th>
                                                    ))}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.reportParameters.map((item) => (
                                                    <tr key={item.id}>
                                                        {Object.values(item).map((value, index) => (
                                                            <td key={index}>
                                                                <div dangerouslySetInnerHTML={{ __html: value }} />
                                                            </td>
                                                        ))}
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs mt-2 mr-3" onClick={() => editParam(item.id)}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            </div>

                            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>

                                <Modal.Header closeButton>
                                    <Modal.Title>Report Parameter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={initialModalValues}
                                        onSubmit={handleModalSubmit}
                                    >
                                        {({ setFieldValue }) => (

                                            <Form id='myModalForm'>

                                                <Field type="hidden" name="reports_master_id" />

                                                <div className="mb-3 row">
                                                    <label htmlFor="code" className="col-lg-2">Code</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code" maxLength="100" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="meta_title" className="col-lg-2">Meta Title</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="meta_title" maxLength="100" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="meta_desc" className="col-lg-2">Meta Desc</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="meta_desc" maxLength="100" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="code_desc" className="col-lg-2">Code Desc (For Admin Only)</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="code_desc" maxLength="150" required />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_title" className="col-lg-2">Report Title</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="report_title" maxLength="100" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="table_heading" className="col-lg-2">Table Heading</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="table_heading" maxLength="100" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="5" name="report_description" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
                                                    <div className="col-lg-10">
                                                        <Field as="textarea" className="form-control" rows="3"
                                                            name="report_disclaimer"
                                                            id="report_disclaimer" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="columns_not_needed" className="col-lg-2">Columns Hide in Paramter Report</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-control" type='text' name="columns_not_needed" maxLength="250" />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                                                    <div className="col-lg-10">
                                                        <NumberInput classNameAtr="form-control" nameAtr={"display_order"} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                                                    <div className="col-lg-10">
                                                        <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={80} onChange={(e) => {
                                                            setFieldValue('display_status', e.target.checked);
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="mb-3 row">
                                                    <div className="col-lg-12 text-end">
                                                        <Button variant="secondary" onClick={handleClose} className='me-2'>
                                                            Close
                                                        </Button>
                                                        {initialValues.lock_status !== 1 && (
                                                            <Button variant="primary" type='submit' >Submit</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal.Body>
                            </Modal>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                :
                <>
                    <h1>
                        {metaData.title}
                    </h1>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <WebReportForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                    </Formik>
                </>
            }
        </>
    )
}

export default WebReport;
