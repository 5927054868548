import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import RegisterForm from './RegisterForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { validateInput } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const Register = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);

    const recaptchaRef = useRef(null);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        name: Yup.string().trim().required('Your Name is required'),
        display_name: Yup.string().trim().required('Your Display Name is required'),
        email: Yup.string().trim().email('Invalid email address').required('Your Email is required'),
        phone: Yup.string().trim().matches(/^\d{10,12}$/, 'Phone number must be between 10 and 12 digits').required('Your phone number is required'),
        city: Yup.string().trim().required('Your city is required'),
        state: Yup.string().trim().required('Your state is required').notOneOf([''], 'Please select a state'),
        member_agree: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
        recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
    });

    const [initialValues, setInitialValues] = useState({
        name: "",
        display_name: "",
        password: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        member_agree: false,
        recaptcha: '',
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {

            if (isLoggedIn) {
                navigate(routeConfig.accessdenied());
            }
            dispatch(startLoading());

            setMetaData({ "title": "Create New Account" });

            api.post('/auth/signup-form')
                .then(response => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        console.log("this is a api data", response.data.stateList);
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                    dispatch(finishLoading());
                })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, [dispatch, api]);

    const handleSubmit = async (values, { setSubmitting }) => {
        let phoneError = validateInput(values.phone, 'phoneNumber');
        if (phoneError) {
            alert(phoneError);
            setSubmitting(false);
            return;
        }

        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();
        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;

        dispatch(startLoading());

        api.post('/auth/signup', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=registration&ref=` + ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className="row main">
                <div className="col-md-12 col-sm-12 pt-3">
                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />


                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <RegisterForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} />
                    </Formik>
                </div>
            </div>

        </>
    )
}

export default Register
