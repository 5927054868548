import React, { useEffect } from 'react'
import { Field, Form, useFormikContext } from 'formik';

import ValidateFormFields from '../Common/ValidateFormFields';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';
import FileUpload from '../Common/FileUpload';


const IPOBOA = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        if (values.filebase64 != null) {
            setBOAText();
        }
        if (values.image_name == "") {
            let str = `<a href="${process.env.REACT_APP_ASSET_URL}images/screenshots/${values.image_name}" title="${values.company_name} ${values.category_name} Basis of Allotment" target="_blank"><img style="min-height:500px;" src="${process.env.REACT_APP_ASSET_URL}images/screenshots/${values.image_name}" title="${values.company_name} ${values.category_name} Basis of Allotment" alt="${values.company_name} ${values.category_name} Basis of Allotment" class="img-responsive img-thumbnail center-block"></a>`;
            setFieldValue('ipo_basis_of_allotment', str);
        }
    }, [values.filebase64, values.image_name]);

    const setBOAText = () => {
        console.log("called")
        if (values.image_name !== null && values.image_name !== '') {
            values.image_name = values.image_name.toLowerCase();
            let str = `<a href="${process.env.REACT_APP_ASSET_URL}images/screenshots/${values.image_name}" title="${values.company_name} ${values.category_name} Basis of Allotment" target="_blank"><img style="min-height:500px;" src="${process.env.REACT_APP_ASSET_URL}images/screenshots/${values.image_name}" title="${values.company_name} ${values.category_name} Basis of Allotment" alt="${values.company_name} ${values.category_name} Basis of Allotment" class="img-responsive img-thumbnail center-block"></a>`;
            setFieldValue('ipo_basis_of_allotment', str);
        }
    }

    return (
        <>
            <Form id='myForm'>
                {result !== undefined &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
                    <div className="col-lg-4">
                        {values.company_name}
                    </div>
                </div >
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="image_name" className="col-lg-2 required">Image Name</label>
                    <div className="col-lg-4">
                        <Field as="input" name="image_name" className="form-control" tabIndex={1} onBlur={(e) => {
                            setBOAText()
                        }} />
                    </div>
                </div >

                <div className='mb-3 row'>
                    <label htmlFor="ipo_basis_of_allotment" className="col-lg-2 required">Basis Of Allotment</label>
                    <div className="col-lg-10">
                        <DynamicCharacterCountInput nameattr={"ipo_basis_of_allotment"} className="form-control" rows="20" tabIndex={5} id="contentBOADiv"
                            onBlur={(e) => {
                                if (e.target.value.length > 500) {
                                    const contentBOADiv = document.getElementById('contentBOADiv');
                                    contentBOADiv.innerHTML = e.target.value;
                                    contentBOADiv.querySelectorAll('a').forEach((a) => {
                                        a.outerHTML = a.innerHTML;
                                    });
                                    setFieldValue('ipo_basis_of_allotment', contentBOADiv.innerHTML);
                                }
                            }} />
                    </div>
                </div>

                <div className='mb-3 row'>

                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox" name="display_status" tabIndex={10}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
                    </div>

                </div>


                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={15}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            {result !== undefined && result.ipoDetail !== undefined && result.ipoDetail.length > 0 ? 'Update' : 'Create'}
                        </span>
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={20}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>



        </>
    )
}

export default IPOBOA
