import React from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import NumberInput from "../Common/NumberInput";

const BrokerComplaintForm = ({
  result,
  handleGoBack,
  loading,
}) => {

  const { setFieldValue } =
    useFormikContext();

  return (
    <Form id="myForm">

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Broker ID:
        </label>
        <div className="col-lg-4">
          {result.brokerComplaint && result.brokerComplaint[0] && result.brokerComplaint[0].broker_id}
        </div>
      </div>
      {result !== undefined &&
        result.brokerComplaint !== undefined &&
        result.brokerComplaint.length > 0}

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">ID</label>
        <div className="col-lg-4">
          {result.brokerComplaint && result.brokerComplaint[0] && result.brokerComplaint[0].id}
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
        <div className="col-lg-4">
          <Field as="input" className="form-control" type="text" name="company_name" maxLength="50" tabIndex={1} />
        </div>
        <label htmlFor="broker_unique_id" className="col-lg-2 required">Broker Unique ID</label>
        <div className="col-lg-4">
          <NumberInput classNameAtr={"form-control"} maxLength={15} tabIndexAtr={5} nameAtr={"broker_unique_id"} />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="broker_id" className="col-lg-2 required">Trading Company ID</label>
        <div className="col-lg-4">
          <NumberInput classNameAtr={"form-control"} maxLength={10} tabIndexAtr={10} nameAtr={"broker_id"} />
        </div>

        <label htmlFor="exchange" className="col-lg-2 required">Exchange</label>
        <div className="col-lg-4">
          <Field as="input" className="form-control" type="text" name="exchange" maxLength="15" tabIndex={15} />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="financial_year" className="col-lg-2 required">Financial Year</label>
        <div className="col-lg-4">
          <Field as="input" className="form-control" type="text" name="financial_year" maxLength="15" tabIndex={20} />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="ucc_count" className="col-lg-2 required">UCC_Count</label>
        <div className="col-lg-4">
          <NumberInput classNameAtr={"form-control"} placeholderAtr="Total Asset" maxLength={15} tabIndexAtr={25} nameAtr={"ucc_count"} />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="complaint_count" className="col-lg-2 required">Complaint_Count</label>
        <div className="col-lg-4">
          <NumberInput classNameAtr={"form-control"}  maxLength={15} tabIndexAtr={30} nameAtr={"complaint_count"} />
        </div>

        <label htmlFor="display_status" className="col-lg-2">Display Status</label>
        <div className="col-lg-4">
          <Field as="input" className="form-check-input" type="checkbox" name="display_status" tabIndex={35}
            onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
          />
        </div>
      </div>
      <hr />

      <div className="mb-3 text-center">
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={loading}
          tabIndex={35}
        >

          {loading && (
            <span className="spinner-border spinner-border-sm me-2"></span>
          )}
          <span>
            Update
          </span>
        </button>

        <button
          type="button"
          className="btn btn-secondary btn-block ms-2"
          onClick={handleGoBack}
          tabIndex={40}
        >
          Cancel
        </button>
      </div>

      <ValidateFormFields />
    </Form>
  );
};

export default BrokerComplaintForm;
