import React, { useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../state/slices/message';
import { isValidVariable } from '../Common/CommonFunction';

const FinancialForm = ({ remove, previousValues }) => {
    const { setFieldValue, values } = useFormikContext();
    const dispatch = useDispatch();

    const inputSetInTable = (event) => {
        const { name, value } = event.target;
        const prevValue = previousValues.current[name];

        if (!isNaN(value) && isValidVariable(value) && prevValue != value) {
            let finalValue = parseFloat(value);
            if (finalValue.toString().length > 0) {
                const multipliedValue = values.digitInput * finalValue;
                const roundedValue = parseFloat(multipliedValue.toFixed(10)); // Adjust decimal places as needed
    
                if (roundedValue.toString().length < 20) {
                    setFieldValue(name, roundedValue);
                    previousValues.current[name] = roundedValue;
                } else {
                    dispatch(setMessage("Out of Limit Value"));
                }
            } else {
                setFieldValue(name, "");
                previousValues.current[name] = "";
            }
        }

        if (prevValue !== undefined && prevValue !== value) {
            console.log(`Previous value of ${name} was ${prevValue}`);
        }
    }

    return (
        <>
            {values.financial.map((financial, index) => (
                <tr key={index}>
                    <td>
                        <Field className="form-control dateInput" type="date" maxLength="15"
                            name={`financial.${index}.financial_date`}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    const selectedDate = new Date(e.target.value);
                                    const today = new Date();
                                    if (selectedDate > today) {
                                        setFieldValue(e.target.name, "");
                                        alert("Date cannot be greater than today's date.");
                                        return;
                                    }
                                }
                                setFieldValue(e.target.name, e.target.value);
                            }}
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.total_assets`} placeholder="Total Asset"
                            dynamicOnBlur={inputSetInTable}
                        />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.total_assets !== undefined ? (financial.total_assets / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.total_assets !== undefined ? (financial.total_assets / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.total_assets !== undefined ? (financial.total_assets / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.total_revenue`} placeholder="Total Asset" dynamicOnBlur={inputSetInTable} />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.total_revenue !== undefined ? (financial.total_revenue / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.total_revenue !== undefined ? (financial.total_revenue / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.total_revenue !== undefined ? (financial.total_revenue / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.profit_after_tax`} placeholder="Total Asset" dynamicOnBlur={inputSetInTable} />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.profit_after_tax !== undefined ? (financial.profit_after_tax / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.profit_after_tax !== undefined ? (financial.profit_after_tax / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.profit_after_tax !== undefined ? (financial.profit_after_tax / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.net_worth`} placeholder="Total Asset" dynamicOnBlur={inputSetInTable} />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.net_worth !== undefined ? (financial.net_worth / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.net_worth !== undefined ? (financial.net_worth / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.net_worth !== undefined ? (financial.net_worth / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.reserves_and_surplus`} placeholder="Total Asset" dynamicOnBlur={inputSetInTable} />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.reserves_and_surplus !== undefined ? (financial.reserves_and_surplus / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.reserves_and_surplus !== undefined ? (financial.reserves_and_surplus / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.reserves_and_surplus !== undefined ? (financial.reserves_and_surplus / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="20"
                            nameAtr={`financial.${index}.total_borrowing`} placeholder="Total Asset" dynamicOnBlur={inputSetInTable} />
                        <p className="curreny-crores mb-0 mt-2">&#8377; {financial.total_borrowing !== undefined ? (financial.total_borrowing / 10000000).toFixed(2) : ''} Crores</p>
                        <p className="curreny-million mb-0">&#8377; {financial.total_borrowing !== undefined ? (financial.total_borrowing / 1000000).toFixed(2) : ''} million</p>
                        <p className="curreny-lakhs mb-0">&#8377; {financial.total_borrowing !== undefined ? (financial.total_borrowing / 100000).toFixed(2) : ''} Lakhs</p>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`financial.${index}.display_status`} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue(e.target.name, newValue);
                                }}
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}
        </>
    )
}

export default FinancialForm;
