import React, { useRef, useState, useEffect } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { useFormikContext, Field, Form, FieldArray } from "formik";
import NumberInput from "../Common/NumberInput";
import NumberDecimalInput from "../Common/NumberDecimalInput";
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';

const IpoFinalBiddingForm = ({
  result,
  handleGoBack,
  initialValues,
  loading,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [formState, setFormState] = useState({
    anchorInvestorUrlDisabledStatus: false,
  });

  const autoPopulateValues = (val) => {
    if (val !== "") {
      try {
        const jsonData = JSON.parse(val);
        jsonData.forEach(item => {
          let inputCodeDesc = item.category_name.toLowerCase();
          const index = values.finalIpoBiddingData.findIndex(obj => obj.category_name.toLowerCase() == inputCodeDesc || obj.category_name.toLowerCase().includes(inputCodeDesc));
          if (item.no_of_applications !== "") setFieldValue(`finalIpoBiddingData.${index}.no_of_applications`, item.no_of_applications);
          if (item.shares_applied !== "") setFieldValue(`finalIpoBiddingData.${index}.shares_applied`, item.shares_applied);
          if (item.no_of_shares_allotted !== "") setFieldValue(`finalIpoBiddingData.${index}.no_of_shares_allotted`, item.no_of_shares_allotted);
          if (item.no_of_allottees !== "") setFieldValue(`finalIpoBiddingData.${index}.no_of_allottees`, item.no_of_allottees);
          if (item.updated !== "") setFieldValue(`finalIpoBiddingData.${index}.updated`, item.updated);
          if (item.offered_price !== "") setFieldValue(`finalIpoBiddingData.${index}.offered_price`, item.offered_price);

          if (item.shares_applied !== "" && item.updated !== "") updateTimesSubscribed(item.shares_applied, item.updated, index);
        })
      } catch (error) {
        console.error("Invalid JSON format:", error);
      }
    }
  }

  const textRefRIITable = useRef(null);
  const textRef1STTable = useRef(null);

  const handleCopy = (refName) => {
    if (refName == "RII" && textRefRIITable.current) {
      navigator.clipboard.writeText(textRefRIITable.current.innerText);
    }
    else if (refName == "1ST" && textRef1STTable.current) {
      navigator.clipboard.writeText(textRef1STTable.current.innerText);
    }
    alert("Copied to clipboard!");
  };

  useEffect(() => {
    autoPopulateValues(values.json_text_input)
  }, [values.json_text_input])

  useEffect(() => {
    let status = true;
    if (values.issue_process_type == "BB") status = !status;
    setFormState((prevState) => ({
      ...prevState,
      anchorInvestorUrlDisabledStatus: status,
    }));
  }, [values.issue_process_type]);

  const handleInputChange = (event, index, attribute) => {
    setFieldValue(`finalIpoBiddingData.${index}.${attribute}`, event);
    const updatedData = [...values.finalIpoBiddingData];
    updatedData[index][attribute] = event;
    calculateTotalValue(attribute, updatedData);
  };


  const calculateTotalValue = (attribute, dataArray = values.finalIpoBiddingData) => {
    let totalValue = 0;

    dataArray.forEach(item => {
      if (item.bidding_category != 9) {
        if ((attribute !== "updated")
          || (attribute == "updated" && item.bidding_category !== "7" && item.bidding_category !== "3")) {
          let data = item[attribute];
          if (data !== "") totalValue += parseInt(data, 10) || 0;
        }
      }
    });
    if (totalValue == 0) totalValue = "";
    const indexTotal = values.finalIpoBiddingData.findIndex(item => item.bidding_category == 9)
    setFieldValue(`finalIpoBiddingData.${indexTotal}.${attribute}`, totalValue);
  };

  const updateTimesSubscribed = (applications, numShares, index) => {
    if ((applications !== "" && applications !== null) && (numShares !== "" && numShares !== null)) {
      let val = parseInt(applications, 10) / parseInt(numShares, 10);
      val = val.toFixed(2);
      setFieldValue(`finalIpoBiddingData.${index}.times_subscribed`, val);
    } else {
      setFieldValue(`finalIpoBiddingData.${index}.times_subscribed`, "");
    }
  };

  useEffect(() => {
    updateTimesSubscribed(values.finalIpoBiddingData[values.finalIpoBiddingData.length - 1].updated, values.finalIpoBiddingData[values.finalIpoBiddingData.length - 1].shares_applied, values.finalIpoBiddingData.length - 1)
  }, [values.finalIpoBiddingData[values.finalIpoBiddingData.length - 1].updated, values.finalIpoBiddingData[values.finalIpoBiddingData.length - 1].shares_applied])

  return (
    <>
      <Form id="myForm">
        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 &&
          result.ipocompany[0].lock_status == 1 && (
            <h3 className="text-danger mt-3 mb-0">
              Record Is Locked , you can't update it.
            </h3>
          )}

        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 && (
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">
                ID:
              </label>
              <div className="col-lg-4">{result.ipocompany[0].ipo_id}</div>
            </div>
          )}

        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 && (
            <div className="mb-3 row">
              <label htmlFor="company_name" className="col-lg-2">
                Name
              </label>
              <div className="col-lg-4" tabIndex={5}>
                {result.ipocompany[0].company_name}
              </div>
            </div>
          )}
        <hr />

        <div className="mb-3 row">
          <label htmlFor="json_text_input" className="col-lg-2">Json Input (Auto populate values) :</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="15" cols="40" onChange={(e) => { const val = e.target.value ?? ""; setFieldValue("json_text_input", val) }} nameattr={"json_text_input"} tabIndex={5} />
          </div>
          <div className="col-lg-6">
            <p>
              Please visit the below link and follow the steps given :
              <br />
              {result && result.ipocompany && result.ipocompany.length > 0 && <a href={`${process.env.REACT_APP_WEB_URL}ipo_basis_of_allotment/${result.ipocompany[0].urlrewrite_folder_name}/${result.ipocompany[0].ipo_id}/`} target="_blank" rel="noopener noreferrer">{result.ipocompany[0].company_name} BOA</a>}
            </p>
            <button
              type="button"
              onClick={() => { handleCopy("1ST") }}
              className="btn btn-secondary btn-sm px-2 py-1"
              style={{ fontSize: "12px", lineHeight: "1", padding: "2px 6px", marginLeft: "8px" }}
            >
              Prompt 1st Table
            </button>
            <button
              type="button"
              onClick={() => { handleCopy("RII") }}
              className="btn btn-secondary btn-sm px-2 py-1"
              style={{ fontSize: "12px", lineHeight: "1", padding: "2px 6px", marginLeft: "8px" }}
            >
              Prompt RII Table
            </button>
            <br /><br />
            <p>
              <strong>STEP 1:</strong> Crop/ take screenshot of the first table unser the section BID/OFFER PROGRAMME. (The table is followed by the line - "The details of the applications received in the Offer from various categoris are as under:")<br /><br />
              <strong>STEP 2:</strong> Click on the button (Prompt 1st Table) to copy the prompt to clipboard and paste both screenshot and prompt in ai tool.<br /><br />
              <strong>STEP 3:</strong> Crop/ take screenshot of the table and the text under the heading - "Allotment to Retail Individual Investors...".<br /><br />
              <strong>STEP 4:</strong> Clear the text box and Click on the button (Prompt RII Table) to copy the prompt to clipboard and paste both THE 2nd screenshot and prompt in ai tool.<br /><br />
              <br />
            </p>
            <pre ref={textRef1STTable} style={{ display: 'none' }} className="bg-light p-2">
              From the given table, extract data as explained below:{"\n"}

              There are multiple rows marked with Alphabets in the first column except the last row.{"\n"}
              From each row (including the last row), create a JSON object extracting data from the 3rd, 4th and 5th column and insert into keys no_of_applications, shares_applied and updated respectively.{"\n"}
              Now for each of these objects, also add a key - category_name. The value of this will be decided using the text in the 2nd column of the row-{"\n"}

              The format of the JSON will be like below -

              {`{
                "category_name": "",
                "no_of_applications": "",
                "shares_applied": "",
                "updated": "",
                "offered_price": "",
                "no_of_shares_allotted": "",
                "no_of_allottees": ""
              }`}{"\n"}

              If the text contains Retail individual Investors or RII then value is "Retail".
              If the text contains More than ₹0.20 million Up to ₹1.00 million and Non-Institutional  then value is "NII - upto 10 lacs".
              If the text contains Above ₹1.00 million and Non-Institutional then value is "NII - upto 10 lacs".
              If the text contains Above ₹1.00 million and Non-Institutional then value is "NII - Above 10 lacs".
              If the text contains "Qualified Institutional Bidders" and "(excluding Anchor Investors)" both, then value is "QIB (ex.Anchor)".
              If the text contains "Qualified Institutional Bidders" or "QIB" and no "(excluding Anchor Investors)" then, value is "QIB".
              If the text contains Anchor Investors then value is "Anchor Investor".
              If the text contains Total then value is "Total".

              Remove commas if any from the numerical values. Keep the values of rest of the keys as empty string.
              Return an array of  JSON objects.
            </pre>

            <pre ref={textRefRIITable} style={{ display: 'none' }} className="bg-light p-2">
              From the given table and text, extract data as explained below:{"\n"}

              Extract data from the last row and insert into the keys using the below rule.{"\n"}

              data from 3rd column → no_of_applications{"\n"}
              data from 5th column → shares_applied{"\n"}
              data from 9th column → no_of_shares_alloted{"\n"}

              The category name should be "Retail"

              The format of the JSON will be like below -

              {`{
                "category_name": "",
                "no_of_applications": "",
                "shares_applied": "",
                "updated": "",
                "offered_price": "",
                "no_of_shares_allotted": "",
                "no_of_allottees": ""
              }`}{"\n"}

              From the text above the table, extract the value coming just after Offer Price for ₹ and insert into offered_price. Keep the values of rest of the keys as empty string.
              Remove commas if any from the numerical values.

              Return an array of this JSON object.
            </pre>
          </div>
        </div>

        <table className="table table-striped table-bordered text-center align-middle">
          <thead className="table-dark">
            <tr>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                Category
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                No. of Shares <small>(as per DB)</small>
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                Updated <small>(as per Pros. / BOA)</small>
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                No. of Applications
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                No. Of Shares Applied
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                No. of Shares Allotted
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                No. of Allottees
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                Offer Price
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                Subscription
              </th>
            </tr>
          </thead>
          <FieldArray
            name="finalIpoBiddingData"
            render={({ push, remove }) => (
              <>
                <tbody>
                  {values.finalIpoBiddingData &&
                    values.finalIpoBiddingData.map((finalData, index) => (
                      <tr key={index}>
                        <td>
                          <strong>{finalData.category_name}</strong>
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"No of share reserved"}
                            nameAtr={`finalIpoBiddingData.${index}.no_of_shares`}
                            readOnlyAtr={true}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "no_of_shares") }}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"Updated"}
                            nameAtr={`finalIpoBiddingData.${index}.updated`}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "updated"); updateTimesSubscribed(values.finalIpoBiddingData[index].shares_applied, e, index) }}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"No of application"}
                            nameAtr={`finalIpoBiddingData.${index}.no_of_applications`}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "no_of_applications") }}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"No. Shares Applied"}
                            nameAtr={`finalIpoBiddingData.${index}.shares_applied`}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "shares_applied"); updateTimesSubscribed(e, values.finalIpoBiddingData[index].updated, index) }}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"No of shares allotted"}
                            nameAtr={`finalIpoBiddingData.${index}.no_of_shares_allotted`}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "no_of_shares_allotted") }}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"No of Allottees"}
                            nameAtr={`finalIpoBiddingData.${index}.no_of_allottees`}
                            dynamicOnChange={(e) => { handleInputChange(e, index, "no_of_allottees") }}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"Offered Price"}
                            nameAtr={`finalIpoBiddingData.${index}.offered_price`}
                            readOnlyAtr={finalData.category_name == "Total"}
                          />
                        </td>
                        <td>
                          <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            decimalPoint={2}
                            placeholderAtr={"Times Subscribed"}
                            nameAtr={`finalIpoBiddingData.${index}.times_subscribed`}
                            dynamicOnChange={(e) => { handleInputChange(e.target.value, index, "times_subscribed") }}
                            readOnlyAtr={true}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          />
        </table>

        <div className="mb-3 text-center">
          {/* {result !== undefined &&
            result.ipocompany !== undefined &&
            result.ipocompany.length > 0 &&
            result.ipocompany[0].lock_status == 0 && ( */}
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={145}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>update</span>
          </button>
          {/* )} */}
        </div>
        <ValidateFormFields />
      </Form>
    </>
  );
};

export default IpoFinalBiddingForm;



{/* <div className="mb-3 row">
          <label htmlFor="json_text_input" className="col-lg-2">Json Input (Auto populate values) :</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="40" cols="40" onChange={(e) => { const val = e.target.value ?? ""; setFieldValue("json_text_input", val) }} nameattr={"json_text_input"} tabIndex={5} />
          </div>
          <div className="col-lg-6">
            <p>
            https://www.capitalmarket.com/pub/ba/bashow.asp?code=12111
              Please use the following prompt every time with the image input. Try to include a single section (along with the heading) in one image. :
            </p>
            <pre className="bg-light p-2">
              Extract information from the tables under headings starting with "Allotment to" in the provided images. The images are in{"\n"}
              order. So if there is a table that ends abruptly in an image, assume that the next image has the next part of that table.
              {"\n"}
              Extraction Rules:{"\n"}
              For all tables except:{"\n"}

              "Allotment to QIBs"{"\n"}
              "Allotment to Anchor Investors"{"\n"}
              Extract data from the last row:{"\n"}

              3rd column → no_of_applications{"\n"}
              5th column → no_of_shares_applied{"\n"}
              9th column → no_of_shares_alloted{"\n"}
              For "Allotment to QIBs" and "Allotment to Anchor Investors":{"\n"}

              The total value from the table is saved in no_of_shares_alloted.{"\n"}
              Other values are extracted from the table followed by the text "(before rejections)":{"\n"}
              Find the row where category contains "Anchor Investors" or "QIB/Quality Institutional Bidders".{"\n"}
              Extract:{"\n"}
              3rd column → no_of_applications{"\n"}
              4th column → no_of_shares_applied{"\n"}
              Output Format (JSON array with the following structure):{"\n"}

              {`[
              {
                "category_name": "",
              "no_of_applications": "",
              "shares_applied": "",
              "no_of_shares_allotted": "",
              "no_of_allottees": ""
               }
              ]`}{"\n"}
              Each object represents a category.{"\n"}
              If a category is not found, keep all values as an empty string except category_name.{"\n"}
              Categories to Extract:{"\n"}

              Category Name	Description{"\n"}
              Retail	Allotment to Retail Individual Investors{"\n"}
              NII	Allotment to Non-Institutional Bidders{"\n"}
              NII - upto 10 lacs	Allotment to Non-Institutional Bidders (More than 0.20 million Up to 1.00 million){"\n"}
              NII - Above 10 lacs	Allotment to Non-Institutional Bidders (More than 1.00 million){"\n"}
              Employee	Allotment to Employee Reservation{"\n"}
              QIB	Allotment to QIBs{"\n"}
              Anchor Investor	Allotment to Anchor Investors{"\n"}
              OTHERS	Allotment to Others{"\n"}
              Shareholders	Allotment to Shareholders{"\n"}
              Market Maker	Allotment to Market Makers{"\n"}{"\n"}{"\n"}

              Remove comma from all the numerical values. If a value is missing or represented as "-", store it as an empty string (""). {"\n"}
            </pre>
          </div>
        </div> */}
