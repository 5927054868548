import { Field, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap';

const FileUpload = ({ indexattr = "fileoverwrite", nameattr, imageField, namebase64attr, pdfOnly = false, hideOverwrite = 0, fileConfirmation = 1 }) => {
    const [isDragging, setIsDragging] = useState(false);
    const imagePreviewRef = useRef(null);

    const allowedExtensions = pdfOnly ? ['.pdf'] : ['.jpg', '.jpeg', '.png', '.gif']; // Define allowed file extensions
    const allowedExtensionsString = allowedExtensions.join(', ');
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFile = event.dataTransfer.files[0];
        if (!validateExtension(droppedFile.name)) {
            alert(`Invalid file type. Only ${allowedExtensionsString} files are allowed.`);
            return;
        }
        setFieldValue(nameattr, droppedFile);
        if (fileConfirmation && window.confirm("Do you want to change Image Name")) {
            setFieldValue(imageField, droppedFile.name.toLowerCase());
        }
        if (fileConfirmation == 0) {
            setFieldValue(imageField, droppedFile.name.toLowerCase());
        }

        // else {
        //     setFieldValue(imageField, droppedFile.name);
        // }
        previewImage(droppedFile);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (!validateExtension(selectedFile.name)) {
                alert(`Invalid file type. Only ${allowedExtensionsString} files are allowed.`);
                return;
            }
            setFieldValue(nameattr, selectedFile);
            if (fileConfirmation && window.confirm("Do you want to change Image Name")) {
                setFieldValue(imageField, selectedFile.name.toLowerCase());
            }
            if (fileConfirmation == 0) {
                setFieldValue(imageField, selectedFile.name.toLowerCase());
            }
            // else {
            //     setFieldValue(imageField, selectedFile.name);
            // }

            previewImage(selectedFile);
        } else {
            setFieldValue(nameattr, null);
            setFieldValue(namebase64attr, null);
            setFieldValue(imageField, "");
            clearImagePreview();
        }
    };

    const validateExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(`.${fileExtension}`);
    };

    const handleRemove = (event) => {
        event.stopPropagation(); // Prevent click event from propagating to the parent div
        setFieldValue(nameattr, null);
        setFieldValue(namebase64attr, null);
        setFieldValue(imageField, "");
        clearImagePreview();
    };

    const handleRemoveDefaultImg = (event) => {
        setFieldValue(nameattr, null);
        setFieldValue(namebase64attr, null);
        setFieldValue(imageField, "");
        clearImagePreview();
    }

    const previewImage = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (imagePreviewRef.current) {
                const result = reader.result;
                if (result.includes('data:application/pdf;')) {
                    imagePreviewRef.current.src = "/images/file_icon.png";
                } else {
                    imagePreviewRef.current.src = result;
                }
                imagePreviewRef.current.style.display = 'inline';
                setFieldValue(namebase64attr, result);
            }
        };
        reader.readAsDataURL(file);
    };

    const clearImagePreview = () => {
        if (imagePreviewRef.current) {
            imagePreviewRef.current.src = '';
            imagePreviewRef.current.style.display = 'none';
        }
    };

    const { errors, touched, values, setFieldValue, handleBlur } = useFormikContext();
    return (
        <div className='row'>
            <div className='col-6'>
                <input type="file" name={nameattr} onChange={handleFileChange}
                    onBlur={handleBlur}
                    style={{ display: 'none' }}
                    isinvalid={touched[nameattr] && !!errors[nameattr]}
                />
                <div
                    className={`d-flex align-items-center justify-content-center drop-area p-3 text-center border rounded ${isDragging ? 'dragging' : ''
                        } ${touched[nameattr] && !!errors[nameattr] ? 'border-danger' : ''
                        } ${values[nameattr] ? 'image-hover' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{ height: '100px' }}
                    onClick={() => document.querySelector(`input[name="${nameattr}"][type="file"]`).click()}
                >
                    {values[nameattr] ? (
                        <>
                            <p>Selected file: {values[nameattr].name}</p>
                            <Button variant="outline-danger" className="ms-2" size="sm" onClick={handleRemove}>
                                Remove
                            </Button>
                        </>
                    ) : (
                        <p>Drag and drop a file here, or click to select one</p>
                    )}
                </div>
            </div>
            <div className='col-4 ps-3'>
                {values.img_final != undefined && values.img_final != "" ?
                    <>
                        {
                            (values.img_final.match(/\.[0-9a-z]+$/i) ? values.img_final.match(/\.[0-9a-z]+$/i)[0] : "") == ".pdf" ?
                                <>
                                    <img ref={imagePreviewRef}
                                        id="image-preview"
                                        alt="Preview"
                                        src="/images/file_icon.png"
                                        style={{ height: '80px', marginTop: '5px' }}
                                    />
                                </>
                                :
                                <>
                                    <img ref={imagePreviewRef}
                                        id="image-preview"
                                        alt="Preview"
                                        src={values.img_final}
                                        style={{ height: '80px', marginTop: '5px' }}
                                    />
                                </>
                        }

                        <Button variant="outline-danger" className="ms-4" size="sm" onClick={handleRemoveDefaultImg}>
                            Remove
                        </Button>
                    </>
                    :
                    <>
                        {
                            values[imageField] != undefined && values[imageField].length > 0 && (values[imageField].match(/\.[0-9a-z]+$/i) ? values[imageField].match(/\.[0-9a-z]+$/i)[0] : "") == ".pdf" ?
                                <>
                                    <img ref={imagePreviewRef}
                                        id="image-preview"
                                        alt="Preview"
                                        src="/images/file_icon.png"
                                        style={{ height: '80px', marginTop: '5px' }}
                                    />
                                </>
                                :
                                <>
                                    <img ref={imagePreviewRef}
                                        id="image-preview"
                                        alt="Preview"
                                        style={{ display: 'none', height: '80px', marginTop: '5px' }}
                                    />
                                </>
                        }
                    </>
                }

            </div>
            {hideOverwrite != 1 &&
                <div className='col-12 mt-2'>
                    <label htmlFor="display_status" className="col-lg-2">Overwrite
                        <Field className="form-check-input ms-3" type="checkbox" name={`${indexattr}`} onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue(`${indexattr}`, newValue);
                        }} />
                    </label>
                </div>
            }
        </div>
    )
}

export default FileUpload;
