import React from 'react'
import { useFormikContext, Field, Form, FieldArray } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const CompareReportForm = ({ result, handleGoBack, initialValues, loading }) => {

  const { setFieldValue } = useFormikContext();

  return (
    <>

      <Form id='myForm'>
        {result !== undefined && result.compareReport !== undefined && result.compareReport.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.compareReport[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="report_id" className="col-lg-2">Report</label>
          <div className="col-lg-4">
            <Field as="select" name="report_id" className="form-select" tabIndex={1}>
              <option></option>
              {result.reportList !== undefined && result.reportList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.report_name}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Meta Title (Max 60)</label>
          <div className="col-lg-8">
            <Field as="input" className="form-control" type="text" name="meta_title" maxLength=" 60" tabIndex={5} />
          </div>
          <div className="col-lg-2 text-primary">
            (Use [[title1]] and [[title2]] for topic name replace)
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Meta Description<br /> (Text 160 - 180 Char)</label>
          <div className="col-lg-8">
            <Field as="input" className="form-control" type="text" name="meta_desc" maxLength="250" tabIndex={10} />
          </div>
          <div className="col-lg-2 text-primary">
            (Use [[title1]] and [[title2]] for topic name replace)
          </div>
        </div>

        <hr />
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">SQL Query</label>
          <div className="col-lg-8">
            <Field as="textarea" className="form-control" rows="5" name="sql_query" tabIndex={15} />
          </div>
          <div className="col-lg-2 text-primary">
            (Use [[URLRewrite_Folder_Name]] in query for selected topic search)
            <br />
            (Use `page_link` in query for table header)
            <br />
            (Use alias `title` in query for topic name)
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Dropdown Query</label>
          <div className="col-lg-8">
            < Field as="textarea" className="form-control" rows="5" name="ddl_search_query" tabIndex={20} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Compare List Query</label>
          <div className="col-lg-8">
            < Field as="textarea" className="form-control" rows="5" name="compare_list_query" tabIndex={25} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2">Breadcrumb Description</label>
          <div className="col-lg-8">
            < Field as="textarea" className="form-control" rows="5" name="breadcrumb_desc" tabIndex={30} />
          </div>
        </div>
        <hr />


        <div id="repeater1">

          <FieldArray name="compareReportChild" tabIndex={35}>

            {({ push, remove, form }) => (
              <div className='r-group'>
                {/* Map over the compareReportChild array */}
                {form.values.compareReportChild.map((child, index) => (
                  <div className='card mb-3' key={index}>
                    <div className="card-body">
                      {/* Your input fields */}
                      <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">Section Title</label>
                        <div className="col-lg-10">
                          <Field
                            as="input"
                            className="form-control repeater"
                            type="text"
                            name={`compareReportChild[${index}].section_title`}
                            maxLength="100"
                          />
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">Section Description</label>
                        <div className="col-lg-10">
                          <Field
                            as="textarea"
                            className="form-control"
                            rows="3"
                            name={`compareReportChild[${index}].section_desc`}
                          />
                          <label className="text-primary">Use columns in Section Description like [[column Name1]] [[column Name2]]. Note: column name must be mentioned in Column Name</label>
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">Column Name</label>
                        <div className="col-lg-10">
                          <Field
                            as="input"
                            className="form-control"
                            type="text"
                            name={`compareReportChild[${index}].columns_name`}
                            maxLength="500"
                          />
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">Display Order</label>
                        <div className="col-lg-1">
                          <Field
                            as="input"
                            className="form-control"
                            type="text"
                            name={`compareReportChild[${index}].display_order`}
                            maxLength="10"
                            onKeyUp={(e) => { if (/\D/g.test(e.target.value)) e.target.value = e.target.value.replace(/\D/g, '') }}
                          />
                        </div>

                        <label htmlFor="" className="col-lg-2">Section Display Status</label>
                        <div className="col-lg-2">
                          <Field
                            as="input"
                            className="form-check-input"
                            type="checkbox"
                            name={`compareReportChild[${index}].display_status`}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setFieldValue(`compareReportChild[${index}].display_status`, newValue);
                            }}
                          />
                        </div>
                      </div>

                      {index !== 0 && (
                        <button type="button" className="btn btn-danger btn-sm mb-2" onClick={() => remove(index)}>Remove</button>
                      )}
                    </div>
                  </div>
                ))}
                <button type="button" className="r-btnAdd btn btn-primary btn-sm text-center mt-2 mb-2" onClick={() => push({
                  section_title: '',
                  section_desc: '',
                  columns_name: '',
                  display_order: '',
                  display_status: false
                })}>Add +</button>
              </div>
            )}
          </FieldArray>

        </div>

        <div className="mb-3 text-center">
          <div className="mb-3 row">
            <label htmlFor="display_status" className="col-lg-2">Display Status</label>
            <div className="col-lg-2">
              <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={40} onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue('display_status', newValue);
              }} />
            </div>
            <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
            <div className="col-lg-2">
              <Field className="form-check-input" type="checkbox" name="lock_status" tabIndex={41} onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue('lock_status', newValue);
              }} />
            </div>
          </div>

          {initialValues.lock_status !== 1 && (
            <button
              tabIndex={45} type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.compareReport !== undefined && result.compareReport.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            tabIndex={50} type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}


export default CompareReportForm;
