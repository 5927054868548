import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const ReportConfigKeywordsForm = ({ result, initialValues, handleGoBack, loading }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.reportConfig !== undefined && result.reportConfig.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.reportConfig[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Keyword Name</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" maxlength="100" tabIndex={1} name="keyword_name"
                        />
                    </div>
                    <label htmlFor="cron_type" className="col-lg-2">Cron Run</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select" id="cron_type" tabIndex={5} name="cron_type"
                        >
                            <option></option>
                            {result.monitoringConditionList !== undefined && result.monitoringConditionList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}

                        </Field>
                        <p className="text-danger" id="cronNotify">On weekly and monthly Run on Holidays by default
                            yes</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">SQL Query</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="5" tabIndex={10} name="sql_query" />
                        <p className="text-danger">Use [[CurrentIST]] for current timestamps</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Description</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="3" tabIndex={15} name="description" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Comment (Admin Use)</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="3" tabIndex={20} name="comment" />
                    </div>
                </div>
                <div className="mb-3 row" id="timeDiv">
                    <label htmlFor="" className="col-lg-2">Start Time</label>
                    <div className="col-lg-4">
                        <Field type="time" className="form-control" id="start_time" tabIndex={25} name="start_time"
                        />
                    </div>
                    <label htmlFor="" className="col-lg-2">End Time</label>
                    <div className="col-lg-4">
                        <Field type="time" className="form-control" id="end_time" tabIndex={30} name="end_time"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="run_on_holiday" className="col-lg-2">Run on Holidays</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" tabIndex={35} name="run_on_holiday" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('run_on_holiday', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" tabIndex={40} name="display_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-2">
                        <Field className="form-check-input" type="checkbox" tabIndex={45} name="lock_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('lock_status', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 text-center">
                    {!initialValues.lock_status == 1 &&
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={50}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.reportConfig !== undefined && result.reportConfig.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    }

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={55}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default ReportConfigKeywordsForm
