import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { selectLoading } from '../../../state/slices/loadingSlice';
import { useSelector } from 'react-redux';
const RITabs = ({ idAtr }) => {
  const loading = useSelector(selectLoading);
  return (
    <>
      {loading ? (
        <div className='ms-4 mb-4' style={{ height: '42px' }}>
          <span className="spinner-border spinner-border-sm me-2"></span>
        </div>
      ) :

        <Nav variant="tabs" className='mb-4'>
          <Nav.Item>
            <NavLink to={`/admintool9x/rightissue/${idAtr}`} className={`nav-link`}>RI Detail</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/admintool9x/financial/ri/${idAtr}`} className={`nav-link`}>Financial</NavLink>
          </Nav.Item>
        </Nav>
      }
    </>

  );
};

export default RITabs;
