import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import FileUploadMultiple from '../Common/FileUploadMultiple';

const FileUploadForm = ({ handleGoBack, loading, initialValues }) => {


    return (
        <>
            <Form id='myForm'>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2 required">Folder</label>
                    <div className="col-lg-4">
                        <Field as="select" name="folder" className="form-select" tabIndex={1}>
                            <option value=""></option>
                            <option value="logo">Logo</option>
                            <option value="screenshots">Screenshots</option>
                            <option value="photogallery">Photo Gallery</option>
                            <option value="">-----------------</option>
                            <option value="reports-ipo-notes">Reports - IPO Notes</option>
                            <option value="reports-ncd">Reports - NCD</option>
                            <option value="reports-rights-issue">Reports - Rights Issue</option>
                            <option value="reports-buyback">Reports - Buyback</option>
                            <option value="reports-others">Reports - Others</option>
                            <option value="">-----------------</option>
                            <option value="book-main">Book - Main</option>
                            <option value="book-module">Book - Module</option>
                            <option value="book-chapter">Book - Chapter</option>
                            <option value="">-----------------</option>
                            <option value="list-of-scripts-bse">BSE List of Scripts</option>
                            <option value="bhav-copy">Bhav Copy .CSV (BSE/NSE) </option>
                        </Field>
                    </div>
                </div >

                <FileUploadMultiple nameattr="file" namebase64attr="filebase64" imageField="image_name" />


                <hr />
                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Upload
                        </span>
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default FileUploadForm
