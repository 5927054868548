import React, { useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';
import PhoneInput from "../Common/PhoneInput";

const FeedbackUpdateForm = ({ result, handleGoBack, loading, initialValues }) => {
	const { values, setFieldValue } = useFormikContext();

	console.log(initialValues.topic)

	const [caseType, setCaseType] = useState('lower');

	const handleConvertCase = () => {
		const currentValue = initialValues.posted_by_name;
		let newValue = '';

		if (caseType === 'lower') {
			newValue = currentValue.toLowerCase();
			setCaseType('upper');
		} else if (caseType === 'upper') {
			newValue = currentValue.toUpperCase();
			setCaseType('camel');
		} else if (caseType === 'camel') {
			newValue = toCamelCase(currentValue);
			setCaseType('lower');
		}

		setFieldValue('posted_by_name', newValue);
	};

	// Function to convert string to camel case
	const toCamelCase = (str) => {
		return str.replace(/\b\w/g, function (match) {
			return match.toUpperCase();
		});
	};


	return (
		<>
			<Form id='myForm'>
				{result !== undefined && result.feedback !== undefined && result.feedback.length > 0 &&
					<div className="mb-3 row">
						<label htmlFor="" className="col-lg-2">ID:</label>
						<div className="col-lg-4">
							{result.feedback[0].id}
						</div>
					</div>
				}
				<div className="mb-3 row">
					<label htmlFor="request_type_id" className="col-lg-2 control-label">Feedback Page</label>
					<div className="col-lg-2">
						<Field as="select" name="request_type_id" className="form-select" tabIndex={1}>
							<option></option>
							{result.requestType !== undefined && result.requestType.map((option) => (
								<option key={option.code} value={option.code}>
									{option.code_desc}
								</option>
							))}
						</Field>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="topic_id" className="col-lg-2 control-label">Feedback Topic</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr={"form-control"} nameAtr={"topic_id"} tabIndexAtr={"5"} />
					</div>
					<div className="col-lg-5">
						{result !== undefined && result.topicData !== undefined && result.topicData.length > 0 && (
							<Field as='input' className="form-control" type="text" name="topic" readOnly tabIndex={10} value={result.topicData[1]} />)}
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="parent_message_id" className="col-lg-2 control-label">Parent Message ID</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr={"form-control"} nameAtr={"parent_message_id"} maxLength={"10"} tabIndexAtr={"15"} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="posted_by_name" className="col-lg-2 control-label">Posted By</label>
					<div className="col-lg-2">
						<Field as='input' className="form-control" type="text" name="posted_by_name" maxLength="50" size="20" tabIndex={20} />
					</div>
					<div className="col-lg-1">
						<img
							src="/images/editlowercase.png"
							width="16"
							alt="Click here to Convert to Lowercase"
							title="Click here to Convert to Lowercase"
							onClick={handleConvertCase}
							tabIndex={25}
						/>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="posted_by_email" className="col-lg-2 control-label">Posted By Email</label>
					<div className="col-lg-2">
						<Field as='input' className="form-control" type="text" name="posted_by_email" maxLength="50" size="40" tabIndex={30} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="posted_by_phone" className="col-lg-2 control-label">Posted By Phone</label>
					<div className="col-lg-2">
						<PhoneInput classNameAtr={"form-control"} nameAtr={"posted_by_phone"} tabIndex={35} maxLength = {"15"} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="message" className="col-lg-2 control-label">Message</label>
					<div className="col-lg-8">
						<DynamicCharacterCountInput className="form-control" nameattr={"message"} rows="10" tabIndex={40} />
					</div>

				</div>
				<div className="mb-3 row">
					<label htmlFor="display_status" className="col-lg-2 control-label">Display Status</label>
					<div className="col-lg-1">
						<Field className="form-check-input ms-3" type="checkbox" name="display_status" tabIndex={45} onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue('display_status', newValue);
						}} />
					</div>
					<label htmlFor="spam_reported" className="col-lg-2 control-label">Spam Reported?</label>
					<div className="col-lg-1">
						<Field className="form-check-input ms-3" type="checkbox" name="spam_reported" tabIndex={50} onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue('spam_reported', newValue);
						}} />
					</div>
					<label htmlFor="track_replies" className="col-lg-2 control-label">Track Replies</label>
					<div className="col-lg-1">
						<Field className="form-check-input ms-3" type="checkbox" name="track_replies" tabIndex={55} onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue('track_replies', newValue);
						}} />
					</div>
				</div>

				<hr />
				<div className="mb-3 text-center">
					{loading || (result.feedback !== undefined && result.feedback.length > 0) ? (
						<button
							type="submit"
							className="btn btn-primary btn-block"
							disabled={loading}
							tabIndex={60}
						>
							{loading && (
								<span className="spinner-border spinner-border-sm me-2"></span>
							)}
							{result.feedback !== undefined && result.feedback.length > 0 && 'Update'}
						</button>
					) : null}

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={65}
					>Cancel</button>
				</div>
				<ValidateFormFields />
			</Form>

		</>
	)
}

export default FeedbackUpdateForm
