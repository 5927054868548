import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const IPORecommendationMasterForm = ({ result, handleGoBack, loading, initialValues }) => {
    const { setFieldValue } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                <div className="mb-3 row">
                    <label htmlFor="ipo_id" className="col-lg-2 required">IPO Name</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select form-select-sm" tabIndex={1} name="ipo_id">
                            <option ></option>
                            {result.ipoList !== undefined && result.ipoList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.company_name}
                                </option>
                            ))}
                        </Field>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">
                        Display Status
                    </label>
                    <div className="col-lg-1">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            name="display_status"
                            tabIndex={5}
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="lock_status" className="col-lg-2">
                        Lock Status
                    </label>
                    <div className="col-lg-1">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            name="lock_status"
                            tabIndex={10}
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }}
                        />
                    </div>
                </div>

                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={15}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>Update</span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={20}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default IPORecommendationMasterForm
