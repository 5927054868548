import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ReportConfigKeywordsForm from './ReportConfigKeywordsForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const ReportConfigKeywords = () => {

    const validationSchema = Yup.object({
        keyword_name: Yup.string().trim().required('Key Word Name is required'),
        sql_query: Yup.string().trim().required('SQL Query is required'),
        description: Yup.string().trim().required('Description is required'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        keyword_name: "",
        sql_query: "",
        cron_type: "",
        start_time: "",
        end_time: "",
        description: "",
        comment: "",
        run_on_holiday: false,
        lock_status: false,
        display_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Report Config Keywords" })
            } else {
                setMetaData({ "title": "Edit Report Config Keywords" })
            }
            api.post('/admin-reportconfig-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.reportConfig);
                    if (!isAddMode) {
                        setInitialValues(response.data.reportConfig[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            // display_order: response.data.reportConfigNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-reportconfig-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>

            <hr />
            {result !== undefined &&
                result.reportConfig !== undefined &&
                result.reportConfig.length > 0 &&
                result.reportConfig[0].lock_status == 1 && (
                    <>
                        <h3 className="text-danger">
                            Record Is Locked , you can't update it.
                        </h3>
                        <br />
                    </>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ReportConfigKeywordsForm result={result} initialValues={initialValues} handleGoBack={handleGoBack} loading={loading} />
            </Formik>


        </>
    )
}

export default ReportConfigKeywords
