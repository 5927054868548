import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import ReCAPTCHA from "react-google-recaptcha";
import DynamicCharacterCountInputText from '../../admintool9x/Common/CharacterCountTextarea';

const PostCommentChrForm = ({ result, btnClick, loading, initialValues, recaptchaRef, currentUser }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <div className='row main mt-2'>
        <div className='col-md-6 col-sm-12'>
          <Form id='myForm'>
            {loading ? (
              <div
                className="spinner-container d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }} // Full viewport height for vertical centering
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              result && result.parentMessages && result.parentMessages.length > 0 && (
                <div className="card mt-2">
                  <div className="card-header">
                    <h3 className="mb-0">Parent Message</h3>
                  </div>
                  <div className="card-body">
                    {result.parentMessages.map((option) => (
                      <div key={option.id} style={option.parent_message_id == 0 ? null : { paddingLeft: "25px" }}>
                        <h3>{option.posted_by_name} &nbsp;&nbsp; (<small>{option.date_posted}</small>)</h3>
                        <p dangerouslySetInnerHTML={{ __html: option.message }}></p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}

            <legend className="pt-2">
              {result && result.pageData && result.pageData[0]}
            </legend>
            <hr />
            <p>Message for: <b>{result && result.pageData && result.pageData[1]}</b></p>

            <div className="mb-2 row">
              <div className="controls">
                <DynamicCharacterCountInputText className="form-control" rows="7" nameattr={"message"} maxLength={2000} tabIndex={5} placeholderattr="Your Message" />
              </div>
            </div>


            <div className="mb-2 row">
              <label>
                <Field className="form-check-input" type="checkbox" name="track_replies" tabIndex={10} onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('track_replies', newValue);
                }} /> <mark>Track replies</mark>
              </label>
              <small style={{ color: "#828282" }}>(Get an email when someone responds to this message in 30 days.)</small>
            </div>


            {!currentUser ? (
              <>
                <div className="mb-2 row">
                  <label htmlFor="posted_by_name" className="col-sm-2">Name</label>
                  <div className="col-sm-10">
                    <Field type="text" id="posted_by_name" name="posted_by_name" placeholder="Your Name" maxLength={50} tabIndex={10} className="form-control" />
                  </div>
                </div>

                <div className="mb-2 row">
                  <label htmlFor="posted_by_email" className="col-sm-2">Email</label>
                  <div className="col-sm-10">
                    <Field type="text" id="posted_by_email" name="posted_by_email" placeholder="Your Email" maxLength={50} tabIndex={15} className="form-control" />
                  </div>
                </div>


              </>
            ) : null
            }

            <div className="mb-2 row">
              <label htmlFor="divLoading" className="col-md-2">
                Verify Code
              </label>
              <div className="col-md-3">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  tabIndex={35}
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  onChange={(value) => setFieldValue('recaptcha', value)}
                />

              </div>
            </div>
            <hr />
            <div className="mb-3 text-center">
              {initialValues.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={25}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    Post Comment
                  </span>
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={btnClick}
                tabIndex={30}
              >Close</button>
            </div>
            <ValidateFormFields />
          </Form>
        </div>
      </div>
    </>
  )
}

export default PostCommentChrForm
