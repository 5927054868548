import React, { useState, useEffect } from 'react';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import { funFormatDateTime } from '../Common/CommonFunction';

function SubscriptionFileForm({ item, onOpen, onDelete, directoryDomnain, parentItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const totalFiles = item.subItems.filter(sub => !sub.isdirectory).length;
  const handleToggle = () => setIsOpen(!isOpen);
  return (
    <ListGroup.Item className="p-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {item.isdirectory && (
            <Button style={{
              fontSize: '1.5rem',
              color: '#007bff', // Bootstrap primary color
              border: 'none',
              background: 'none',
              transition: 'color 0.3s ease',
            }}
              onMouseEnter={(e) => e.currentTarget.style.color = '#0056b3'} // Darker color on hover
              onMouseLeave={(e) => e.currentTarget.style.color = '#007bff'}
              variant="link" onClick={handleToggle} className="mr-2 text-decoration-none">
              {isOpen ? '-' : '+'}
            </Button>
          )}
          {item.isdirectory ? (

            <div>
              Folder Name :
              <strong className='me-5'>
                {item.name}
              </strong>
              <small className='me-5'>Creation Time: {funFormatDateTime(item.ctime)}</small>
              <small className='me-5'>Last Modified: {funFormatDateTime(item.mtime)}</small>
              <small>Total Files: <strong>{totalFiles}</strong> </small>
            </div>
          ) : (
            <a href="#" onClick={(e) => {
              e.preventDefault();
              onOpen(directoryDomnain + "/" + parentItem.name + "/" + item.name);
            }} className="text-decoration-none">
              {item.name}
            </a>
          )}
        </div>
        <Button variant="danger" size="sm" onClick={() => onDelete(item.path)}>
          <i className='fa fa-trash'></i>
        </Button>
      </div>
      {item.isdirectory && (
        <Collapse in={isOpen}>
          {item.subItems.length > 0 && (
            <div>
              {item.subItems.map((subItem) => (
                <SubscriptionFileForm key={subItem.path} parentItem={item} item={subItem} onOpen={onOpen} onDelete={onDelete} directoryDomnain={directoryDomnain} />
              ))}
            </div>
          )}
        </Collapse>
      )}
    </ListGroup.Item>
  );
}

export default SubscriptionFileForm;
