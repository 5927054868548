
import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';

const validationSchema = Yup.object({
    ipo_id: Yup.string().trim().required('Please select Company Name.'),
    migration_date : Yup.string().trim().required('Please enter migration date.'),
});

const SMEMigratedForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values,setFieldValue }) => (

                    <Form id='myForm'>
                       
                        <div className="mb-3 row">
                            <label htmlFor="ipo_id" className="col-lg-2 required">Company Name</label>
                            <div className="col-lg-4">
                            <Field as="select" className="form-select" tabIndex={1} name="ipo_id" >
                                <option>choose company</option>
                                {result.ipoList !== undefined && result.ipoList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </Field>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="bse_scrip_name" className="col-lg-2">BSE Script Name</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text"  tabIndex={5} name="bse_scrip_name" maxLength="100" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="nse_symbol" className="col-lg-2">NSE Symbol</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text"  tabIndex={10} name="nse_symbol" 
                                    maxLength="100" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="" className="col-lg-2">Migration Date</label>
                            <div className="col-lg-2">
                                <Field className="form-control dateInput" type="date" data-id="txtmigration_date"  tabIndex={15} name="migration_date" title="YYYY/MM/DD"  />
                            </div>
                        </div>
                      
                        <div className="mb-3 text-center">
                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={loading}
                                tabIndex={20}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                <span>
                                    {result.smeMigrated !== undefined && result.smeMigrated.length > 0 ? 'Update' : 'Create'}
                                </span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={25}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>

                )}
            </Formik>
        </>
    )
}

export default SMEMigratedForm





