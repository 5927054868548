import React, { useState, useEffect, useRef, useCallback } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { useFormikContext, Field, Form } from "formik";
import NumberDecimalInput from "../Common/NumberDecimalInput";
import NumberInput from "../Common/NumberInput";
import { FormatNumber, isNumeric, isValidVariable } from "../Common/CommonFunction";
import AutocompleteField from "../Common/AutocompleteField";
import { debounce } from 'lodash';
import useApi from '../../../common/useApi';

const IpoIssueDetailForm = ({ result, handleGoBack, initialValues, loading, getIpoPrice }) => {

    const [blurState, setBlurState] = useState({
        isBlurring: false,
        blurredTextbox: null,
        blurredTextValue: null,
    });

    const api = useApi();
    const { values, setFieldValue } = useFormikContext();

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const [autoComplteOptions, setAutoComplteOptions] = useState([]);
    const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);

    const handleBlurWrapper = (e) => {
        if (!blurState.isBlurring) {
            setBlurState({ isBlurring: true, blurredTextbox: e.target.name, blurredTextValue: e.target.value });
        }
    };

    useEffect(() => {
        const handleBlurLogic = async () => {

            if (blurState.blurredTextbox == "issue_price_upper" || blurState.blurredTextbox == "issue_price_final") {
                await delay(100);
                console.log(`Processed blur for: ${blurState.blurredTextbox}`);

                const issueCategory = result?.ipoIssueDetail?.[0].issue_category;
                console.log("issue category : ", issueCategory);

                if (issueCategory === 'SME') {
                    let issuePriceFinal = parseFloat(values.issue_price_final) || 0;
                    let issuePriceUpper = parseFloat(values.issue_price_upper) || 0;

                    if (issuePriceFinal <= 0) {
                        issuePriceFinal = (isNaN(issuePriceUpper) || issuePriceUpper <= 0) ? 0 : issuePriceUpper;
                    }
                    if (window.confirm('Are you sure you want to update Market Lot Size and Min. Order Quantity? ' + blurState.blurredTextbox)) {
                        if (issuePriceFinal > 0) {
                            const updatedMarketLotSize = calculateMarketLotSize(issuePriceFinal);
                            setFieldValue('market_lot_size', updatedMarketLotSize);
                            setFieldValue('minimum_order_quantity', updatedMarketLotSize);
                        } else {
                            setFieldValue('market_lot_size', '');
                            setFieldValue('minimum_order_quantity', '');
                            console.log('Invalid issuePriceFinal:', issuePriceFinal);
                        }
                    } else {
                        console.log('Confirmation declined:', issuePriceFinal);
                    }
                }
            }
            else if (blurState.blurredTextbox == "issue_size_fresh_in_amt") {
                let price = getIpoPrice();
                console.log("fresh issue blure ... ", price)
                if (price > 0 && isNumeric(blurState.blurredTextValue)) {
                    console.log("fresh issue blure insise ... ")
                    if (window.confirm("Are you want to update Fresh Issue Shares")) {
                        setFieldValue("issue_size_fresh_in_shares", parseInt(blurState.blurredTextValue) / parseFloat(price));
                    }
                }
            }
            else if (blurState.blurredTextbox == "issue_size_fresh_in_shares") {
                let price = getIpoPrice();
                if (price > 0 && isNumeric(blurState.blurredTextValue)) {
                    if (window.confirm("Are you want to update Fresh Issue Amount")) {
                        setFieldValue("issue_size_fresh_in_amt", parseInt(blurState.blurredTextValue) * parseFloat(price));
                    }
                }
            }
            else if (blurState.blurredTextbox == "issue_size_ofs_in_amt") {
                let price = getIpoPrice();
                if (price > 0 && isNumeric(blurState.blurredTextValue)) {
                    if (window.confirm("Are you want to update OFS Issue Shares")) {
                        setFieldValue("issue_size_ofs_in_shares", parseInt(blurState.blurredTextValue) / parseFloat(price));
                    }
                }
            }
            else if (blurState.blurredTextbox == "issue_size_ofs_in_shares") {
                let price = getIpoPrice();
                if (price > 0 && isNumeric(blurState.blurredTextValue)) {
                    if (window.confirm("Are you want to update OFS Issue Amount")) {
                        setFieldValue("issue_size_ofs_in_amt", parseInt(blurState.blurredTextValue) * parseFloat(price));
                    }
                }
            }

            if (["issue_size_ofs_in_shares", "issue_size_fresh_in_shares"].includes(blurState.blurredTextbox)) {
                let price = getIpoPrice();
                if (price > 0 && window.confirm("Are you want to update Total Shares And Amount")) {
                    let ofs_shares = document.getElementsByName('issue_size_ofs_in_shares')[0].value;
                    let fresh_share = document.getElementsByName('issue_size_fresh_in_shares')[0].value;

                    if (!isValidVariable(ofs_shares)) {
                        ofs_shares = 0;
                    }
                    if (!isValidVariable(fresh_share)) {
                        fresh_share = 0;
                    }

                    setFieldValue(
                        'issue_size_in_shares', parseFloat(ofs_shares) + parseFloat(fresh_share)
                    );

                    setFieldValue(
                        'issue_size_in_amt', parseFloat(ofs_shares) * parseFloat(price) + parseFloat(fresh_share) * parseFloat(price)
                    );
                }
            }
            if (["issue_size_ofs_in_amt", "issue_size_fresh_in_amt"].includes(blurState.blurredTextbox)) {
                let price = getIpoPrice();
                if (price > 0 && window.confirm("Are you want to update Total Shares And Amount")) {
                    let ofs_size = document.getElementsByName('issue_size_ofs_in_amt')[0].value;
                    let fresh_size = document.getElementsByName('issue_size_fresh_in_amt')[0].value;

                    if (!isValidVariable(ofs_size)) {
                        ofs_size = 0;
                    }
                    if (!isValidVariable(fresh_size)) {
                        fresh_size = 0;
                    }

                    setFieldValue(
                        'issue_size_in_shares', (parseFloat(ofs_size) / parseFloat(price)) + (parseFloat(fresh_size) / parseFloat(price))
                    );

                    setFieldValue(
                        'issue_size_in_amt', parseFloat(ofs_size) + parseFloat(fresh_size)
                    );
                }
            }
            setBlurState({ isBlurring: false, blurredTextbox: null, blurredTextValue: null });
        };

        if (blurState.isBlurring) {
            handleBlurLogic();
        }
    }, [blurState]);

    const updateshareholdingpostissue = (e) => {
        let pre, freshShares;
        e.target.value = e.target.value.replace(/[ ,]/g, '');

        if (e.target.name == "issue_size_fresh_in_shares") {
            freshShares = parseInt(e.target.value) || 0;
            pre = parseInt(values.total_shareholding_pre_issue) || 0;
        }

        else if (e.target.name == "total_shareholding_pre_issue") {
            pre = parseInt(e.target.value) || 0;
            freshShares = parseInt(values.issue_size_fresh_in_shares) || 0;
        }

        console.log("setting field", pre + freshShares)
        setFieldValue('total_shareholding_post_issue', pre + freshShares);
    }

    const calculateMarketLotSize = (issuePrice) => {
        if (issuePrice <= 14) return "10000";
        else if (issuePrice <= 18) return "8000";
        else if (issuePrice <= 25) return "6000";
        else if (issuePrice <= 35) return "4000";
        else if (issuePrice <= 50) return "3000";
        else if (issuePrice <= 70) return "2000";
        else if (issuePrice <= 90) return "1600";
        else if (issuePrice <= 120) return "1200";
        else if (issuePrice <= 150) return "1000";
        else if (issuePrice <= 180) return "800";
        else if (issuePrice <= 250) return "600";
        else if (issuePrice <= 350) return "400";
        else if (issuePrice <= 500) return "300";
        else if (issuePrice <= 600) return "240";
        else if (issuePrice <= 750) return "200";
        else if (issuePrice <= 1000) return "160";
        else return "100"; // Default value for any other condition
    };

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

    const handleAutoSearch = async (query) => {
        debouncedHandleAutoSearch(query);
        console.log("The options are: ", autoComplteOptions)
    };

    const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
        console.log(query);
        setIsAutocomplteLoading(true);
        try {
            const response = await api.post('/admin-listofscript-list', { search: query });
            if (response.data.msg === 1) {
                const options = response.data.details.map(item => ({
                    label: `${item.company_name} (${item.bse_script_id ?? ""}) (${item.bse_isin ?? ""}) (${item.bse_script_code ?? ""}) (${item.nse_script_id ?? ""}) (${item.nse_isin ?? ""})`,
                    value: item.id
                }));
                setAutoComplteOptions(options);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsAutocomplteLoading(false);
        }
    }, 300), []);

    const handleAutoBlur = async (selected) => {
        if (selected) {
            let item = selected.value;
            setFieldValue("shareholder_company_id", item ?? "")
        }
    };

    const [companyId, setCompanyId] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState([]);

    const handleTransferClick = () => {
        if (values.shareholder_company_text) {
            const newCompany = values.shareholder_company_text;

            // Ensure companyId is always an array and check for duplicates
            if (!Array.isArray(companyId)) {
                setCompanyId([newCompany]);
            } else if (!companyId.some(company => company.value === newCompany.value)) {
                setCompanyId((prev) => [...prev, newCompany]);

                // Update the company_name_array field, ensuring no duplicates
                const updatedCompanyArray = values.company_name_array || [];
                if (!updatedCompanyArray.some(company => company.value === newCompany.value)) {
                    setFieldValue("company_name_array", [...updatedCompanyArray, newCompany]);
                }
            }

            console.log("company id: ", companyId);
        }
    };

    useEffect(() => {
        if (Array.isArray(values.company_name_array)) {
            setCompanyId((prevCompanyId) => [
                ...prevCompanyId,
                ...values.company_name_array.filter(id => !prevCompanyId.includes(id))
            ]);
        }
    }, [values, values.company_name_array]);

    const handleRemoveSelected = () => {
        if (selectedCompanyId.length > 0) {
            const updatedIds = companyId.filter((company) => !selectedCompanyId.includes(String(company.value)));
            setCompanyId(updatedIds);

            const updatedShareholderCompanies = values.company_name_array.filter(
                (company) => !selectedCompanyId.includes(String(company.value))
            );
            setFieldValue('company_name_array', updatedShareholderCompanies);
            setSelectedCompanyId([]);
        }
    };

    const setIssueSizeAmt = () => {
        let price = getIpoPrice();

        if (price > 0) {
            let fresh_size = document.getElementsByName('issue_size_fresh_in_shares_readonly')[0].value;
            let issue_size_fresh_in_amt = document.getElementsByName('issue_size_fresh_in_amt_readonly')[0].value;
            if (!isValidVariable(fresh_size))
                fresh_size = 0;
            else
                fresh_size = parseInt(fresh_size);

            if (!isValidVariable(issue_size_fresh_in_amt))
                issue_size_fresh_in_amt = 0;
            else
                issue_size_fresh_in_amt = parseInt(issue_size_fresh_in_amt);

            if (fresh_size * price > issue_size_fresh_in_amt) {
                issue_size_fresh_in_amt = fresh_size * price;
                setFieldValue('issue_size_fresh_in_amt', issue_size_fresh_in_amt);
            } else if (fresh_size == 0 && issue_size_fresh_in_amt > 0) {
                fresh_size = issue_size_fresh_in_amt / price;
                setFieldValue('issue_size_fresh_in_shares', fresh_size);
            }


            let ofs_shares = document.getElementsByName('issue_size_ofs_in_shares_readonly')[0].value;
            let issue_size_ofs_in_amt = document.getElementsByName('issue_size_ofs_in_amt_readonly')[0].value;
            if (!isValidVariable(ofs_shares))
                ofs_shares = 0;
            else
                ofs_shares = parseInt(ofs_shares);

            if (!isValidVariable(issue_size_ofs_in_amt))
                issue_size_ofs_in_amt = 0;
            else
                issue_size_ofs_in_amt = parseInt(issue_size_ofs_in_amt);

            if (ofs_shares > 0 && issue_size_ofs_in_amt == 0) {
                issue_size_ofs_in_amt = ofs_shares * price;
                setFieldValue('issue_size_ofs_in_amt', issue_size_ofs_in_amt);
            } else if (ofs_shares == 0 && issue_size_ofs_in_amt > 0) {
                ofs_shares = issue_size_ofs_in_amt / price;
                setFieldValue('issue_size_ofs_in_shares', ofs_shares);
            }


            let total_size = fresh_size + ofs_shares;
            if (!isValidVariable(ofs_shares)) {
                total_size = 0;
            } else {
                total_size = parseInt(total_size);
            }

            setFieldValue('issue_size_in_shares', total_size);
            setFieldValue('issue_size_in_amt', total_size * price);
        } else {
            setFieldValue('issue_size_fresh_in_shares', values.issue_size_fresh_in_shares_readonly);
            setFieldValue('issue_size_fresh_in_amt', values.issue_size_fresh_in_amt_readonly);
            setFieldValue('issue_size_ofs_in_shares', values.issue_size_ofs_in_shares_readonly);
            setFieldValue('issue_size_ofs_in_amt', values.issue_size_ofs_in_amt_readonly);
            setFieldValue('issue_size_in_shares', values.issue_size_in_shares_readonly);
            setFieldValue('issue_size_in_amt', values.issue_size_in_amt_readonly);
        }
    }






    const getCalcFun = (values) => {
        let price = values.issue_price_final || values.issue_price_upper;
        let total_firm_shares = Number(values.private_promoter_shares) + Number(values.shares_offered_market_maker) + Number(values.firm_mf_shares) + Number(values.firm_emp_shares);
        let total_preferential_share = Number(values.prefer_other_shares) + Number(values.prefer_shareholder_shares) + Number(values.prefer_emp_shares);

        let total_firm_amount = Number((values.private_promoter_shares) * price) + Number((values.shares_offered_market_maker) * price) + Number((values.firm_mf_shares) * price) + Number((values.firm_emp_shares) * price);

        let total_preferential_amt = Number((values.prefer_emp_shares) * price) + Number((values.prefer_shareholder_shares) * price) + Number((values.prefer_other_shares) * price);

        let total_public_issue_amt = values.issue_size_in_amt - total_firm_amount;
        let total_public_issue_shares = values.issue_size_in_shares - total_firm_shares;

        let total_net_issue_share = total_public_issue_shares - total_preferential_share;

        let total_net_issue_amt = total_public_issue_amt - total_preferential_amt;

        return {
            total_firm_shares,
            total_preferential_share,
            total_firm_amount,
            total_preferential_amt,
            total_public_issue_amt,
            total_public_issue_shares,
            total_net_issue_share,
            total_net_issue_amt,
        }
    }

    const calcFun = getCalcFun(values);

    return (
        <>
            <Form id="myForm">
                {result !== undefined &&
                    result.ipoIssueDetail !== undefined &&
                    result.ipoIssueDetail.length > 0 && (
                        <>
                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">
                                    ID:
                                </label>
                                <div className="col-lg-4">{result?.ipoIssueDetail[0]?.id}</div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">
                                    Company Name:
                                </label>
                                <div className="col-lg-4">
                                    <strong>{result?.ipoIssueDetail[0]?.company_name}</strong>
                                </div>
                            </div>
                        </>
                    )}
                <hr />

                <div className="mb-3 row">
                    <label htmlFor="face_value" className={`col-lg-2`}>
                        Face Value
                    </label>

                    <div className="col-lg-1">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            maxLength="15"
                            nameAtr="face_value"
                        />
                    </div>
                    <div className="col-lg-3"></div>
                    <label htmlFor="issue_price_final" className="col-lg-2">
                        IPO Final Price
                    </label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={50} maxLength="7" nameAtr="issue_price_final"
                            dynamicOnInput={setIssueSizeAmt} dynamicOnBlur={(e) => {
                                handleBlurWrapper(e);
                            }} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_price_lower" className={`col-lg-2`}>
                        Price Band-Minimum (Rs.)
                    </label>

                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={20}
                            maxLength="15"
                            nameAtr="issue_price_lower"
                        />
                    </div>
                    <div className="col-lg-2"></div>
                    <label htmlFor="issue_price_upper" className="col-lg-2">
                        Price Band-Maximum (Rs)
                    </label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={45} maxLength="7" nameAtr="issue_price_upper" dynamicOnBlur={(e) => {
                            handleBlurWrapper(e);
                        }} dynamicOnInput={setIssueSizeAmt} />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="market_lot_size" className={`col-lg-2`}>
                        Market Lot Size
                    </label>

                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={30}
                            maxLength="15"
                            nameAtr="market_lot_size"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="minimum_order_quantity" className={`col-lg-2`}>
                        Minimum Order Quantity
                    </label>

                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={35}
                            maxLength="15"
                            nameAtr="minimum_order_quantity"
                        />
                    </div>
                </div>

                <hr />

                <h3>Discount on Offer Price</h3>

                <div className="mb-3 row">
                    <div className="col-lg-2"></div>
                    <label htmlFor="final_price" className="col-lg-3">
                        <strong>Offer Price</strong>
                    </label>
                    <label htmlFor="discount" className="col-lg-3">
                        <strong>Discount %</strong>
                    </label>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="retail" className="col-lg-2">
                        Retail
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={40}
                            maxLength="15"
                            nameAtr="discount_retail"
                            dynamicOnChange={(e) => setFieldValue("discount_retail_per", (FormatNumber(e / (values.issue_price_final || values.issue_price_upper) * 100, 2)))}

                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={45}
                            maxLength="15"
                            nameAtr="discount_retail_per"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="discount_employee" className="col-lg-2">
                        Employees
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={50}
                            maxLength="15"
                            nameAtr="discount_employee"
                            dynamicOnChange={(e) => setFieldValue("discount_employee_per", (FormatNumber(e / (values.issue_price_final || values.issue_price_upper) * 100, 2)))}
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={55}
                            maxLength="15"
                            nameAtr="discount_employee_per"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="discount_shareholder" className="col-lg-2">
                        Shareholders
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={60}
                            maxLength="15"
                            nameAtr="discount_shareholder"
                            dynamicOnChange={(e) => setFieldValue("discount_shareholder_per", (FormatNumber(e / (values.issue_price_final || values.issue_price_upper) * 100, 2)))}
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={65}
                            maxLength="15"
                            nameAtr="discount_shareholder_per"
                        />
                    </div>
                </div>

                <hr />

                <h3>Issue Size</h3>

                <div className="mb-3 row">
                    <div className="col-lg-2"></div>
                    <label htmlFor="no_of_shares" className="col-lg-3">
                        <strong>No. of Shares</strong>
                    </label><div className="col-lg-1"></div>
                    <label htmlFor="amount_in_rs" className="col-lg-3">
                        <strong>Amount (in Rs.)</strong>
                    </label>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_size_fresh_in_shares" className="col-lg-2">
                        <u>Fresh</u> Issue Size
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={55} maxLength="25" nameAtr="issue_size_fresh_in_shares"
                            dynamicOnBlur={(e) => { handleBlurWrapper(e); updateshareholdingpostissue(e); }}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={56} maxLength="25" nameAtr="issue_size_fresh_in_shares_readonly" readOnlyAtr />
                    </div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }}
                        >{parseFloat(values.issue_size_fresh_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;&nbsp;Shares</div>
                    </div>

                </div>

                <div className="mb-3 row">
                    <label htmlFor="issue_size_fresh_in_amt" className="col-lg-2">
                        <u>Fresh</u> Issue Size in Amt
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25" nameAtr="issue_size_fresh_in_amt" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
                    </div>

                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25" nameAtr="issue_size_fresh_in_amt_readonly" readOnlyAtr />
                    </div>

                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }} id="divIssue_Size_Fresh_In_Amt"
                        >Rs.&nbsp;{parseFloat(values.issue_size_fresh_in_amt / 10000000).toFixed(2)}&nbsp;Crore&nbsp;&nbsp;</div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_size_ofs_in_shares" className="col-lg-2">
                        <u>OFS</u> Issue Size (Shares)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={65} maxLength="25" nameAtr="issue_size_ofs_in_shares" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
                    </div>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={65} maxLength="25" nameAtr="issue_size_ofs_in_shares_readonly" readOnlyAtr />
                    </div>

                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }}
                        >{parseFloat(values.issue_size_ofs_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;&nbsp;Shares</div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_size_ofs_in_amt" className="col-lg-2">
                        <u>OFS</u> Issue Size (Rs)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25" nameAtr="issue_size_ofs_in_amt" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
                    </div>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25" nameAtr="issue_size_ofs_in_amt_readonly" readOnlyAtr />
                    </div>

                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }}
                        >Rs. &nbsp; {parseFloat(values.issue_size_ofs_in_amt / 10000000).toFixed(2)}&nbsp;Crores</div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_size_in_shares" className="col-lg-2">
                        Issue Size (Total) (Shares)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="25" nameAtr="issue_size_in_shares" />
                    </div>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="25" nameAtr="issue_size_in_shares_readonly" readOnlyAtr />
                    </div>

                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }}
                        >{parseFloat(values.issue_size_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="issue_size_in_amt" className="col-lg-2">
                        Issue Size (Total) (Rs)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={80} maxLength="25" nameAtr="issue_size_in_amt" idAtr="Issue_Size_In_Amt" />
                    </div>

                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={80} maxLength="25" nameAtr="issue_size_in_amt_readonly" readOnlyAtr />
                    </div>

                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: '#0000FF' }}
                        >Rs.&nbsp;{parseFloat(values.issue_size_in_amt / 10000000).toFixed(2)}&nbsp;Crore</div>
                    </div>
                    <div className="col-lg-6"></div>
                </div>

                <hr />

                {/* shareholder company */}

                <div className="mb-3 row">
                    <label htmlFor="shareholder_company_text" className="col-lg-2">Shareholder For Company</label>
                    <div className="col-lg-4">
                        <Field
                            name="shareholder_company_text"
                            as={AutocompleteField}
                            options={autoComplteOptions}
                            onInputChange={(query) => handleAutoSearch(trimValue(query))}
                            onInputSelect={handleAutoBlur}
                            tabIndex={198}
                            maxLength="10"
                            isAutocomplteLoading={isAutocomplteLoading}
                            idatr="company_name_txt"
                            nameatr="shareholder_company_text"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            value={values.shareholder_company_text}
                        />
                    </div>

                    <div className="col-lg-2 text-center">
                        <div className="mt-2">
                            <button
                                type="button"
                                onClick={() => handleTransferClick()}
                                className="btn btn-sm btn-primary mb-2"
                            >
                                &gt;&gt;
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => handleRemoveSelected()}
                                className="btn btn-sm btn-primary"
                                disabled={selectedCompanyId.length === 0}
                            >
                                &lt;&lt;
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <Field
                            as="select"
                            name="shareholder_company_name"
                            className="form-control"
                            multiple
                            value={selectedCompanyId}
                            tabIndex={199}
                            onChange={(e) => {
                                const selected = Array.from(e.target.selectedOptions, (option) => option.value);
                                setSelectedCompanyId(selected);
                            }}
                        >
                            {(!Array.isArray(values.company_name_array) || values.company_name_array.length === 0) && (
                                <option value="" disabled>
                                    Select the shareholder company name
                                </option>
                            )}
                            {/* Render company options if available */}
                            {Array.isArray(values.company_name_array) &&
                                values.company_name_array.map((company, index) => (
                                    <option key={index} value={company.value}>
                                        {company.label}
                                    </option>
                                ))}
                        </Field>
                    </div>
                </div>

                <hr />

                {/* End shareholder company */}

                {/* IPO Bidding AutoLoad */}

                <h3>For IPO Bidding Auto Load</h3>
                <div className="row mb-3">
                    <label htmlFor="nse_symbol" className="col-lg-2">NSE/BSE Symbol</label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="text"
                            name="nse_symbol"
                            title="NSE_Symbol"
                            tabIndex={175}
                        />
                    </div>
                    <div className="col-lg-2">
                        <p>
                            <a className="me-2"
                                href="https://nseindia.com/products/content/equities/ipos/homepage_ipo.htm"
                                target="_blank"
                            >
                                Mainline
                            </a>
                            |
                            <a className="mx-2"
                                href="https://www.nseindia.com/market-data/sme-market"
                                target="_blank"
                            >
                                SME
                            </a>
                            i.e. RITES, PIL
                        </p>
                    </div>
                    <label htmlFor="bse_scripcode" className="col-lg-2">
                        BSE IPO No
                    </label>

                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={180} maxLength="25" nameAtr="bse_scripcode" />
                    </div>
                    <div className="col-lg-2">
                        <p>
                            <a className="me-2"
                                href="https://www.bseindia.com/markets/PublicIssues/IPOIssues_new.aspx?expandable=9&id=1& Type=p"
                                target="_blank"
                            >
                                Mainline
                            </a>
                            |
                            <a className="mx-2"
                                href="https://www.bsesme.com/PublicIssues/PublicIssues.aspx?id=1"
                                target="_blank"
                            >
                                SME
                            </a>
                            i.e. 3733
                        </p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="capital_market_id" className="col-lg-2">
                        Capital Market ID (BoA)
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="text"
                            name="capital_market_id"
                            title="Capital_Market_ID"
                            tabIndex={185}
                        />
                    </div>
                    <div className="col-lg-2">
                        <p>
                            <a className="me-2"
                                href="https://www.capitalmarket.com/IPO/Forthcoming-IPO"
                                target="_blank"
                            >
                                Mainline
                            </a>
                            |
                            <a className="mx-2"
                                href="https://www.capitalmarket.com/smeipo/forthcoming-smeipo"
                                target="_blank"
                            >
                                SME
                            </a>
                            i.e. 24268
                        </p>
                    </div>
                    <label htmlFor="cm_rating" className="col-lg-2">
                        Capital Market Rating
                    </label>
                    <div className="col-lg-2">
                        <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={190} maxLength="5" nameAtr="cm_rating" />
                    </div>
                </div>
                <hr />

                {/* End IPO Bidding Autoload */}

                <h3>Firm Reservation</h3>

                <div className="mb-3 row">
                    <div className="col-lg-2"></div>
                    <label className="col-lg-3">
                        <strong>No. of Shares</strong>
                    </label>
                    <label className="col-lg-3">
                        <strong>Amount (in Rs.)</strong>
                    </label>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="retail" className="col-lg-2">
                        Employee
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={100}
                            maxLength="15"
                            nameAtr="firm_emp_shares"
                            disabledAtr={result?.ipoIssueDetail?.[0]?.firm_emp_status !== 1}
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.firm_emp_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">MF / Fis</label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={105}
                            maxLength="15"
                            nameAtr="firm_mf_shares"
                            disabledAtr={result?.ipoIssueDetail?.[0]?.mf_status !== 1}
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.firm_mf_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Market Maker</label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={110}
                            maxLength="15"
                            nameAtr="shares_offered_market_maker"
                            disabledAtr={
                                result?.ipoIssueDetail?.[0]?.market_makar_status !== 1
                            }
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.shares_offered_market_maker) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Private Promoter</label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={115}
                            maxLength="15"
                            nameAtr="private_promoter_shares"
                            disabledAtr={
                                result?.ipoIssueDetail?.[0]?.private_promotor_status !== 1
                            }
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.private_promoter_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Total</label>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(calcFun.total_firm_shares)}
                            &nbsp;Shares
                        </div>
                    </div>
                </div>

                <hr />

                <div className="mb-3 row ">
                    <label htmlFor="public_issue" className="col-lg-2 col-form-label">
                        Public Issue{" "}
                        <small>(Total issue size - Total firm reservation)</small>
                    </label>

                    <div className="col-lg-2 d-flex align-items-center">
                        <input
                            className="form-control"
                            maxLength="15"
                            name="public_issue_shares"
                            value={calcFun.total_public_issue_shares}
                            readOnly
                        />
                        <div className="ipo-text-notify ms-2" style={{ color: "#0000FF" }}>Shares</div>
                    </div>

                    <div className="col-lg-1"></div>
                    <div className="col-lg-2 d-flex align-items-center">
                        <input
                            className="form-control"
                            maxLength="15"
                            name="public_issue_amt"
                            value={calcFun.total_public_issue_amt}
                            readOnly
                        />
                        <div className="ipo-text-notify ms-2" style={{ color: "#0000FF" }}>Amount</div>
                    </div>
                </div>

                <hr />

                <h3>Preferential Reservation</h3>

                <div className="mb-3 row">
                    <div className="col-lg-2"></div>
                    <label className="col-lg-3">
                        <strong>No. of Shares</strong>
                    </label>
                    <label className="col-lg-3">
                        <strong>Amount (in Rs.)</strong>
                    </label>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="retail" className="col-lg-2">
                        Employee
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={125}
                            maxLength="15"
                            nameAtr="prefer_emp_shares"
                            disabledAtr={
                                result?.ipoIssueDetail?.[0]?.Preferential_emp_status !== 1
                            }
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.prefer_emp_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Shareholders</label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={130}
                            maxLength="15"
                            nameAtr="prefer_shareholder_shares"
                            disabledAtr={
                                result?.ipoIssueDetail?.[0]?.shareholders_status !== 1
                            }
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.prefer_shareholder_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Others</label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={135}
                            maxLength="15"
                            nameAtr="prefer_other_shares"
                            disabledAtr={result?.ipoIssueDetail?.[0]?.others_status !== 1}
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {FormatNumber(((values.issue_price_final || values.issue_price_upper) * values.prefer_other_shares) / 10000000, 2)}
                            &nbsp;Crores
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label className="col-lg-2">Total</label>
                    <div className="col-lg-2">
                        <div className="ipo-text-notify" style={{ color: "#0000FF" }}>
                            Rs. &nbsp;{" "}
                            {calcFun.total_preferential_share}
                            &nbsp;Shares
                        </div>
                    </div>
                </div>

                <hr />

                <div className="mb-3 row ">
                    <label htmlFor="public_issue" className="col-lg-2 col-form-label">
                        Net Issue{" "}
                        <small>(Public issue - Total Preferential reservation)</small>
                    </label>

                    <div className="col-lg-2 d-flex align-items-center">
                        <input
                            className="form-control"
                            tabIndex={140}
                            maxLength="15"
                            name="net_issue_shares"
                            value={calcFun.total_net_issue_share}
                            readOnly
                        />
                        <div className="ipo-text-notify ms-2" style={{ color: "#0000FF" }}>Shares</div>
                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-2 d-flex align-items-center">
                        <input
                            className="form-control"
                            tabIndex={145}
                            maxLength="15"
                            name="net_issue_amt"
                            value={calcFun.total_net_issue_amt}
                            readOnly
                        />
                        <div className="ipo-text-notify ms-2" style={{ color: "#0000FF" }}>Amount</div>
                    </div>
                </div>

                <hr />

                <h3>Issue Allocation</h3>

                <div className="mb-3 row">
                    <label className="col-lg-2">
                        <strong>Category</strong>
                    </label>
                    <label className="col-lg-4">
                        <strong>Share Percentage (Temp.)</strong>
                    </label>
                    <label className="col-lg-4">
                        <strong>No. of Shares Reserved</strong>
                    </label>
                </div>

                {/* QIB Section */}
                <div className="mb-3 row">
                    <div className="col-lg-2">
                        <strong>QIB</strong>
                    </div>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            tabIndex={150}
                            maxLength="100"
                            name="shares_offered_qib_percentage_temp"
                        />
                    </div>
                    <div className="col-lg-4">
                        <NumberDecimalInput
                            classNameAtr="form-control"
                            tabIndexAtr={155}
                            maxLength="15"
                            nameAtr="shares_offered_qib"
                        />
                    </div>
                </div>

                {/* Anchor Investor */}
                <div className="mb-3 row">
                    <div className="col-lg-2">Anchor Investor</div>
                    <div className="col-lg-4">
                        <input className="form-control" maxLength="100" disabled />
                    </div>
                    <div className="col-lg-4">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={160}
                            maxLength="15"
                            nameAtr="shares_offered_anchor_investor"
                        />
                    </div>
                </div>

                {/* QIB (Ex. Anchor) */}
                <div className="mb-3 row">
                    <div className="col-lg-2">QIB (Ex. Anchor)</div>
                    <div className="col-lg-4">
                        <input className="form-control" maxLength="100" disabled />
                    </div>
                    <div className="col-lg-4">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={165}
                            maxLength="15"
                            nameAtr="shares_offered_qib_ex_anchor"
                        />
                    </div>
                </div>

                {/* NII Section */}
                <div className="mb-3 row">
                    <div className="col-lg-2">
                        <strong>NII</strong>
                    </div>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            tabIndex={170}
                            maxLength="100"
                            name="shares_offered_nii_percentage_temp"
                        />
                    </div>
                    <div className="col-lg-4">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={175}
                            maxLength="15"
                            nameAtr="shares_offered_nii"
                        />
                    </div>
                </div>

                {result?.ipoIssueDetail?.[0]?.issue_category != "SME" && <>
                    <div className="mb-3 row">
                        <div className="col-lg-2">Upto Rs 10 Lacs</div>
                        <div className="col-lg-4">
                            <input
                                className="form-control"
                                tabIndex={35}
                                maxLength="100"
                                disabled
                            />
                        </div>
                        <div className="col-lg-4">
                            <NumberInput
                                classNameAtr="form-control"
                                tabIndexAtr={180}
                                maxLength="15"
                                nameAtr="shares_offered_small_nii"
                            />
                        </div>
                    </div>

                    {/* QIB (Ex. Anchor) */}
                    <div className="mb-3 row">
                        <div className="col-lg-2">Above Rs 10 Lacs</div>
                        <div className="col-lg-4">
                            <input
                                className="form-control"
                                tabIndex={35}
                                maxLength="100"
                                disabled
                            />
                        </div>
                        <div className="col-lg-4">
                            <NumberInput
                                classNameAtr="form-control"
                                tabIndexAtr={185}
                                maxLength="15"
                                nameAtr="shares_offered_big_nii"
                            />
                        </div>
                    </div>
                </>}

                {/* Retail */}
                <div className="mb-3 row">
                    <div className="col-lg-2">
                        <strong>Retail</strong>
                    </div>
                    <div className="col-lg-4">
                        <Field
                            className="form-control"
                            tabIndex={190}
                            maxLength="100"
                            name="shares_offered_rii_percentage_temp"
                        />
                    </div>
                    <div className="col-lg-4">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={195}
                            maxLength="15"
                            nameAtr="shares_offered_rii"
                        />
                    </div>
                </div>

                {/* Employee */}
                {result?.ipoIssueDetail?.[0]?.preferential_emp_status == 1 && <>
                    <div className="mb-3 row">
                        <div className="col-lg-2">
                            <strong>Employee</strong>
                        </div>
                        <div className="col-lg-4">
                            <input className="form-control" maxLength="15" disabled />
                        </div>
                        <div className="col-lg-4">
                            <NumberInput
                                classNameAtr="form-control"
                                tabIndexAtr={200}
                                maxLength="15"
                                nameAtr="shares_offered_emp"
                            />
                        </div>
                    </div>
                </>}

                {/* Shareholders */}
                {result?.ipoIssueDetail?.[0]?.shareholders_status == 1 && <>
                    <div className="mb-3 row">
                        <div className="col-lg-2">
                            <strong>Shareholders</strong>
                        </div>
                        <div className="col-lg-4">
                            <input className="form-control" maxLength="15" disabled />
                        </div>
                        <div className="col-lg-4">
                            <NumberInput
                                classNameAtr="form-control"
                                tabIndexAtr={205}
                                maxLength="15"
                                nameAtr="shares_offered_shareholders"
                            />
                        </div>
                    </div>
                </>}

                {/* Others */}
                {result?.ipoIssueDetail?.[0]?.others_status == 1 && <>
                    <div className="mb-3 row">
                        <div className="col-lg-2">
                            <strong>Others</strong>
                        </div>
                        <div className="col-lg-4">
                            <input className="form-control" maxLength="15" disabled />
                        </div>
                        <div className="col-lg-4">
                            <NumberInput
                                classNameAtr="form-control"
                                tabIndexAtr={210}
                                maxLength="15"
                                nameAtr="shares_offered_others"
                            />
                        </div>
                    </div>
                </>}

                {/* Total */}
                <div className="mb-3 row">
                    <div className="col-lg-2">
                        <strong>Total</strong>
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={215}
                            maxLength="15"
                            nameAtr="shares_offered_total"
                        />
                    </div>
                </div>

                <hr />
                <h3>Pre and Post Issue holding</h3>
                <strong> Issue Capital </strong>
                <div className="mb-3 row">
                    <label
                        htmlFor="total_shareholding_pre_issue"
                        className={`col-lg-6 mt-2`}
                    >
                        {/* Pre-Issue capital (no.of shares) */}
                        Total Shareholding Pre Issue
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={220}
                            maxLength="15"
                            nameAtr="total_shareholding_pre_issue"
                            dynamicOnChange={(e) => { setFieldValue("total_shareholding_pre_issue", e.target.value); updateshareholdingpostissue(e); }}
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="total_shareholding_post_issue" className={`col-lg-6`}>
                        {/* Post-Issue capital (no.of shares) */}
                        Total Shareholding Post-Issue

                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={225}
                            maxLength="15"
                            nameAtr="total_shareholding_post_issue"
                        />
                    </div>
                </div>

                <strong> Promoters Holding </strong>
                <div className="mb-3 row">
                    <label
                        htmlFor="promoter_shareholding_pre_issue"
                        className={`col-lg-6 mt-2`}
                    >
                        Pre-Issue Promoter Holding (no.of shares)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={230}
                            maxLength="15"
                            nameAtr="promoter_pre_issue_shares"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="pre_issue" className={`col-lg-6`}>
                        Pre-Issue Promoter Holding (%)
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            tabIndex={235}
                            maxLength="15"
                            name="promoter_shareholding_pre_issue"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label
                        htmlFor="promoter_shareholding_post_issue"
                        className={`col-lg-6 mt-2`}
                    >
                        Post-Issue Promoter Holding (no.of shares)
                    </label>
                    <div className="col-lg-2">
                        <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={240}
                            maxLength="15"
                            nameAtr="promoter_post_issue_shares"
                        />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="pre_issue" className={`col-lg-6`}>
                        Post-Issue Promoter Holding (%)
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            tabIndex={245}
                            maxLength="15"
                            name="promoter_shareholding_post_issue"
                        />
                    </div>
                </div>
                <hr />

                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={250}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.ipoIssueDetail !== undefined &&
                                    result.ipoIssueDetail.length > 0
                                    ? "Update"
                                    : "Create"}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={360}
                    >
                        Cancel
                    </button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    );
};

export default IpoIssueDetailForm;
