import React, { useEffect } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';

const HolidaysForm = ({ result, handleGoBack, loading, sessionsDisabled, setSessionsDisabled }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.category_cd == "stock") {
      setSessionsDisabled(true);
      setFieldValue('morning_session', '');
      setFieldValue('evening_session', '');
    }
    else {
      setSessionsDisabled(false);
    }
  }, [values.category_cd])

  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.holiday !== undefined && result.holiday.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.holiday[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="category_cd" className="col-lg-2">Category :</label>
          <div className="col-lg-3">
            <Field as="select" className="form-select" tabIndex={1} name="category_cd" >
              <option></option>
              {result.categoryList !== undefined && result.categoryList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>

          <label htmlFor="holiday_dt" className="col-lg-2">Holiday date :</label>
          <div className="col-lg-3">
            <Field className="form-control" type='date' tabIndex={5} name="holiday_dt" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="title" className="col-lg-2">Title :</label>
          <div className="col-lg-3">
            <Field className="form-control" type='text' tabIndex={10} name="title" maxLength="100" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="description" className="col-lg-2">Description (Max 250): </label>
          <div className="col-lg-8">
            <DynamicCharacterCountInputText className="form-control" rows="7" tabIndex={15} nameattr={"description"} maxLength="250" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="morning_session" className="col-lg-2">Morning Session :</label>
          <div className="col-lg-3">
            <Field as="select" className="form-select" tabIndex={20} name="morning_session" disabled={sessionsDisabled}>
              <option ></option>
              {result.sessionList !== undefined && result.sessionList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code}
                </option>
              ))}
            </Field>
          </div>

          <label htmlFor="evening_session" className="col-lg-2">Evening Session :</label>
          <div className="col-lg-3">
            <Field as="select" className="form-select" tabIndex={25} name="evening_session" disabled={sessionsDisabled}>
              <option></option>
              {result.sessionList !== undefined && result.sessionList.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code}
                </option>
              ))}
            </Field>
          </div>
        </div>


        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status :</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={30} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>

        </div>
        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={35}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              {result.holiday !== undefined && result.holiday.length > 0 ? 'Update' : 'Create'}
            </span>
          </button>

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={40}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default HolidaysForm
