import React, { useRef, useEffect, useState } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { Form, FieldArray, useFormikContext } from "formik";
import NumberInput from "../Common/NumberInput";
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';

const IpoQibAnchorMasterForm = ({
  result,
  loading,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [pageLoaded, setPageLoaded] = useState(false)
  const updateTotal = (qibValue, anchorValue, index) => {
    // if (qibValue == "") qibValue = 0;
    // if (anchorValue == "") anchorValue = 0;
    //if (qibValue != "" && anchorValue != "" && qibValue != null && anchorValue != null) 
    if (!((qibValue == "" || qibValue == null) && (anchorValue == "" || anchorValue == null))) {
      if (qibValue == "" || qibValue == null) qibValue = 0;
      if (anchorValue == "" || anchorValue == null) anchorValue = 0;
      let val = parseInt(qibValue, 10) + parseInt(anchorValue, 10);
      setFieldValue(`ipoQibAnchorData.${index}.total`, val);
    }
    else {
      setFieldValue(`ipoQibAnchorData.${index}.total`, "");
    }
  };

  const textRef = useRef(null);

  const handleCopy = () => {
    if (textRef.current) {
      navigator.clipboard.writeText(textRef.current.innerText);
      alert("Copied to clipboard!");
    }
  };

  useEffect(() => {
    if (values.ipoQibAnchorData.length > 1 && !pageLoaded) {
      for (let i = 0; i < values.ipoQibAnchorData.length; i++) {
        let obj = values.ipoQibAnchorData[i];
        updateTotal(obj.qib_value, obj.anchor_value, i);
      }
      setPageLoaded(true)
    }
  }, [values.ipoQibAnchorData])

  const handleInputChange = (event, index, attribute) => {
    setFieldValue(`ipoQibAnchorData.${index}.${attribute}`, event);
    const updatedData = [...values.ipoQibAnchorData];
    updatedData[index][attribute] = event;
    calculateTotalValue(attribute, updatedData);
  };


  const calculateTotalValue = (attribute, dataArray = values.ipoQibAnchorData) => {
    let totalValue = 0;

    dataArray.forEach(item => {
      if (item.category != 8) {
        let data = item[attribute];
        if (data !== "") totalValue += parseInt(data, 10) || 0;
      }
    });
    if (totalValue == 0) totalValue = ""
    const indexTotal = dataArray.findIndex(item => item.category == 8)
    setFieldValue(`ipoQibAnchorData.${indexTotal}.${attribute}`, totalValue);
  };

  const updateCumulativeTotal = (qibValue, anchorValue, index) => {
    //if (qibValue != "" && anchorValue != "" && qibValue != null && anchorValue != null) 
    if (!((qibValue == "" || qibValue == null) && (anchorValue == "" || anchorValue == null))) {
      if (qibValue == "" || qibValue == null) qibValue = 0;
      if (anchorValue == "" || anchorValue == null) anchorValue = 0;
      let val = parseInt(qibValue, 10) + parseInt(anchorValue, 10);
      setFieldValue(`ipoQibAnchorData.${index}.total`, val);
    } else {
      setFieldValue(`ipoQibAnchorData.${index}.total`, "");
    }
  };

  const autoPopulateValues = (val) => {
    if (val !== "") {
      try {
        const jsonData = JSON.parse(val);
        jsonData.forEach(item => {
          let inputCodeDesc = item.code_desc.toLowerCase();
          const index = values.ipoQibAnchorData.findIndex(obj => obj.code_desc.toLowerCase() == inputCodeDesc || inputCodeDesc.includes(obj.code_desc.toLowerCase()) || obj.code_desc.toLowerCase().includes(inputCodeDesc));
          setFieldValue(`ipoQibAnchorData.${index}.qib_value`, item.qib_value);
          setFieldValue(`ipoQibAnchorData.${index}.anchor_value`, item.anchor_value);
          updateTotal(item.qib_value, item.anchor_value, index);
        })
      } catch (error) {
        console.error("Invalid JSON format:", error);
      }
    }
  }

  useEffect(() => {
    autoPopulateValues(values.json_text_input)
  }, [values.json_text_input])

  useEffect(() => {
    updateCumulativeTotal(values.ipoQibAnchorData[values.ipoQibAnchorData.length - 1].qib_value, values.ipoQibAnchorData[values.ipoQibAnchorData.length - 1].anchor_value, values.ipoQibAnchorData.length - 1)
  }, [values.ipoQibAnchorData[values.ipoQibAnchorData.length - 1].qib_value, values.ipoQibAnchorData[values.ipoQibAnchorData.length - 1].anchor_value])

  return (
    <>
      <Form id="myForm">
        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 &&
          result.ipocompany[0].lock_status == 1 && (
            <h3 className="text-danger mt-3 mb-0">
              Record Is Locked , you can't update it.
            </h3>
          )}

        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 && (
            <div className="mb-3 row">
              <label htmlFor="" className="col-lg-2">
                ID:
              </label>
              <div className="col-lg-4">{result.ipocompany[0].ipo_id}</div>
            </div>
          )}

        {result !== undefined &&
          result.ipocompany !== undefined &&
          result.ipocompany.length > 0 && (
            <div className="mb-3 row">
              <label htmlFor="company_name" className="col-lg-2">
                Name
              </label>
              <div className="col-lg-4" tabIndex={5}>
                {result.ipocompany[0].company_name}
              </div>
            </div>
          )}
        <hr />

        <div className="mb-3 row">
          <label htmlFor="json_text_input" className="col-lg-2">Json Input (Auto populate values) :</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="5" cols="46" onChange={(e) => { const val = e.target.value ?? ""; setFieldValue("json_text_input", val) }} nameattr={"json_text_input"} tabIndex={5} />
          </div>
          <div className="col-lg-6">
            <p>
              Please use the following prompt every time with the image input:
              <button
                type="button"
                onClick={handleCopy}
                className="btn btn-secondary btn-sm px-2 py-1"
                style={{ fontSize: "12px", lineHeight: "1", padding: "2px 6px", marginLeft: "8px" }}
              >
                Copy
              </button>
            </p>
            <pre ref={textRef} className="bg-light p-2">
              Extract data from the given image and generate a JSON array where:
              {"\n"}
              Each object has the structure:
              {"\n"}{`{
                      "code_desc": "",  
                      "anchor_value": "",  
                      "qib_value": ""  
                    }`}
              {"\n"}
              - <b>code_desc</b> should be the category name from both tables.
              {"\n"}
              - <b>qib_value</b> should be the corresponding allotment value from the
              "Allotment to QIBs" table.
              {"\n"}
              - <b>anchor_value</b> should be the corresponding allotment value from the
              "Allotment to Anchor Investors" table.
              {"\n"}
              - If a value is missing or represented as "-", store it as an empty string ("").
              {"\n"}
              - Remove commas from numerical values if present.
            </pre>
          </div>
        </div>

        <table className="table table-striped table-bordered text-center align-middle">
          <thead className="table-dark">
            <tr>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                QIB ALLOTMENT
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                EXCLUDING ANCHOR
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                ANCHOR
              </th>
              <th style={{ color: "white", backgroundColor: "grey" }}>
                TOTAL
              </th>
            </tr>
          </thead>
          <FieldArray
            name="ipoQibAnchorData"
            render={({ push, remove }) => (
              <>
                <tbody>
                  {values.ipoQibAnchorData &&
                    values.ipoQibAnchorData.map((finalData, index) => (
                      <tr key={index}>
                        <td>
                          <strong>{finalData.code_desc}</strong>
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"QIB"}
                            nameAtr={`ipoQibAnchorData.${index}.qib_value`}
                            dynamicOnChange={(e) => { updateTotal(e, values.ipoQibAnchorData[index].anchor_value, index); handleInputChange(e, index, "qib_value") }}
                            readOnlyAtr={finalData.code_desc == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"Anchor"}
                            nameAtr={`ipoQibAnchorData.${index}.anchor_value`}
                            dynamicOnChange={(e) => { updateTotal(values.ipoQibAnchorData[index].qib_value, e, index); handleInputChange(e, index, "anchor_value") }}
                            readOnlyAtr={finalData.code_desc == "Total"}
                          />
                        </td>
                        <td>
                          <NumberInput
                            classNameAtr="form-control"
                            tabIndexAtr={10}
                            placeholderAtr={"Total"}
                            nameAtr={`ipoQibAnchorData.${index}.total`}
                            readOnlyAtr={true}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          />
        </table>

        <div className="mb-3 text-center">
          {/* {result !== undefined &&
            result.ipocompany !== undefined &&
            result.ipocompany.length > 0 &&
            result.ipocompany[0].lock_status == 0 && ( */}
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={145}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>update</span>
          </button>
          {/* )} */}
        </div>
        <ValidateFormFields />
      </Form>
    </>
  );
};

export default IpoQibAnchorMasterForm;
