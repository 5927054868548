import React from 'react'
import { Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberDecimalPercentageInput from '../Common/NumberDecimalPercentageInput';

const TradingCompanyCommonChargeForm = ({ result, handleGoBack, loading }) => {

    return (
        <>
            <Form id='myForm'>
                <h3 className="mb-3">Stamp Duty Charges</h3>
                {result !== undefined && result.tradingCompanyCommonCharges !== undefined && result.tradingCompanyCommonCharges.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.tradingCompanyCommonCharges[0].id}
                        </div>
                    </div>
                }
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Intraday Stamp Duty MIN</label>
                    <div className="col-lg-2">

                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={1} nameAtr={'i_stamp_d_min'} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Intraday Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={5} nameAtr={"i_stamp_d_max"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Delivery Stamp Duty MIN</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={10} nameAtr={"d_stamp_d_min"} maxLength="10" />

                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Delivery Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={15} nameAtr={"d_stamp_d_max"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Future Stamp Duty MIN</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={20} nameAtr={"f_stamp_d_min"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Future Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={20} nameAtr={"f_stamp_d_min"} maxLength="10" />

                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Options Stamp Duty MIN</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={25} nameAtr={"o_stamp_d_min"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Options Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={30} nameAtr={"o_stamp_d_max"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Currency Futures Stamp Duty MIN</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={35} nameAtr={"cf_stamp_d_min"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Currency Futures Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={40} nameAtr={"cf_stamp_d_max"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Currency Options Stamp Duty MIN</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={50} nameAtr={"co_stamp_d_min"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Currency Options Stamp Duty MAX</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={55} nameAtr={"co_stamp_d_max"} maxLength="10" />
                    </div>
                </div>
                <hr />

                <h3 className="mb-3">STT Charges</h3>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Intraday STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={60} nameAtr={"b_i_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Intraday STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={65} nameAtr={"s_i_stt"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Delivery STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={70} nameAtr={"b_d_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Delivery STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={75} nameAtr={"s_d_stt"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Futures STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={80} nameAtr={"b_f_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Futures STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={85} nameAtr={"s_f_stt"} maxLength="10" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Options STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={90} nameAtr={"b_o_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Options STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={95} nameAtr={"s_o_stt"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Currency Futures STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={100} nameAtr={"b_cf_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Currency Futures STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={105} nameAtr={"s_cf_stt"} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Currency Options STT (Buy Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={110} nameAtr={"b_co_stt"} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">Currency Options STT (Sell Side)</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" tabIndexAtr={115} nameAtr={"s_co_stt"} maxLength="10" />
                    </div>
                </div>
                <hr />
                <h3 >NSE ETC Charges</h3>
                <small className="text-warning">If ETC charges is not available in broker plan then we use this comman charges.</small>
                <div className="my-3 row">
                    <label htmlFor="" className="col-lg-2">NSE Intraday ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_i_etc" tabIndexAtr={120} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">NSE Delivery ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_d_etc" tabIndexAtr={125} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">NSE Futures ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_f_etc" tabIndexAtr={130} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">NSE Options ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_o_etc" tabIndexAtr={135} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">NSE Currency Futures ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_cf_etc" tabIndexAtr={140}
                            maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">NSE Currency Options ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="n_co_etc" tabIndexAtr={145}
                            maxLength="10" />
                    </div>
                </div>
                <hr />


                <h3 className="mb-3">BSE ETC Charges</h3>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">BSE Intraday ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_i_etc" tabIndexAtr={150} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">BSE Delivery ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_d_etc" tabIndexAtr={155} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">BSE Futures ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_f_etc" tabIndexAtr={160} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">BSE Options ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_o_etc" tabIndexAtr={165} maxLength="10" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">BSE Currency Futures ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_cf_etc" tabIndexAtr={170} maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">BSE Currency Options ETC</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="b_co_etc" tabIndexAtr={175}
                            maxLength="10" />
                    </div>
                </div>
                <hr />


                <h3 className="mb-3">Other Charges</h3>

                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">SEBI Charges</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="sebi_charges" tabIndexAtr={180}
                            maxLength="10" />
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <label htmlFor="" className="col-lg-2">GST Tax</label>
                    <div className="col-lg-2">
                        <NumberDecimalPercentageInput classNameAtr={"form-control"} decimalPoint="6" nameAtr="service_tax" tabIndexAtr={185}
                            maxLength="10" />
                    </div>
                </div>

                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={190}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            {result.tradingCompanyCommonCharges !== undefined && result.tradingCompanyCommonCharges.length > 0 ? "Update" : "Create"}
                        </span>
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={195}
                    >Cancel</button>
                </div>

                <h2>Current HTML Preview </h2>

                {result && result.tradingCompanyCommonCharges &&
                    <div dangerouslySetInnerHTML={{ __html: result.tradingCompanyCommonCharges[0].common_brokerage_charge }}></div>}

                <ValidateFormFields />

            </Form>
        </>
    )
}

export default TradingCompanyCommonChargeForm
