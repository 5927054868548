import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BBTabs = ({ idAtr }) => {

  return (

    <Nav variant="tabs" className='mb-4'>
      <Nav.Item>
        <NavLink to={`/admintool9x/buyback/${idAtr}`} className={`nav-link`}>Buyback Details</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/admintool9x/financial/bb/${idAtr}`} className={`nav-link`}>Financial</NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default BBTabs;
