
import React, { useEffect, useCallback, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import AutocompleteField from '../Common/AutocompleteField';
import NumberInput from '../Common/NumberInput';
import { funCreateSlug, isValidVariable, compareDates } from '../Common/CommonFunction';
import useApi from '../../../common/useApi';
import { debounce } from 'lodash';

const NewIpoAnchorInvestorForm = ({ remove, result, initialValues }) => {
  const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);
  const [autoComplteOptions, setAutoComplteOptions] = useState([]);
  const api = useApi();
  const { setFieldValue, values } = useFormikContext();

  const handleAutoSearch = async (query) => {
    debouncedHandleAutoSearch(query);
  };

  const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
    console.log(query);
    setIsAutocomplteLoading(true);
    try {
      const response = await api.post('/admin-anchorinvestor-list', { search: query });
      if (response.data.msg === 1) {

        const options = response.data.details.map(item => ({
          label: `${item.comp_name} `,
          value: item.id
        }));
        setAutoComplteOptions(options);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsAutocomplteLoading(false);
    }
  }, 300), []); // 300 ms debounce delay


  const handleAutoSelect = (selected, index) => {
    handleAutoBlur(selected, index);
  };

  const handleAutoBlurText = async (e, i) => {
    setFieldValue(`newIpoAnchorInvestor.${i}.anchor_scheme_name`, e.target.value);
  }

  const handleAutoBlur = async (selected, i) => {
    console.log("chk seleected : ", selected, i)
    const item = selected ? selected.value : null;
    const companyName = item ? selected.label : "";
    const companyId = item ? item : "";
    setFieldValue(`newIpoAnchorInvestor.${i}.anchor_scheme_name`, companyName ?? "");
    setFieldValue(`newIpoAnchorInvestor.${i}.anchor_investor_id`, companyId ?? "");
  };

  const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

  const calculateAnchorPortion = () => {
    const repeaterCount = values.newIpoAnchorInvestor.length;
    let totalShares = 0;
    // Calculate totalShares
    for (let i = 0; i < repeaterCount; i++) {
      if (values.newIpoAnchorInvestor[i].display_status) {
        const noOfShares = parseFloat(values.newIpoAnchorInvestor[i].shares_allocated) || 0;
        totalShares += noOfShares;
      }
    }

    // Calculate and update percentage
    for (let i = 0; i < repeaterCount; i++) {
      if (values.newIpoAnchorInvestor[i].display_status) {
        const noOfShares = parseFloat(values.newIpoAnchorInvestor[i].shares_allocated) || 0;
        const percentage = (noOfShares / totalShares) * 100 || 0;
        setFieldValue(`newIpoAnchorInvestor.[${i}].anchor_investor_portion`, percentage.toFixed(2));
      } else {
        setFieldValue(`newIpoAnchorInvestor.[${i}].anchor_investor_portion`, '0');
      }
    }
  };

  const calculateTotalAmtAllocated = () => {
    const repeaterCount = values.newIpoAnchorInvestor.length;

    for (let i = 0; i < repeaterCount; i++) {
      const noOfShares = parseFloat(values.newIpoAnchorInvestor[i].shares_allocated) || 0;
      const bidPrice = parseFloat(values.newIpoAnchorInvestor[i].bid_price) || 0;
      setFieldValue(`newIpoAnchorInvestor.[${i}].total_amount_allotted`, (noOfShares * bidPrice).toFixed(2));
    }
  };

  useEffect(() => {
    calculateAnchorPortion();
    calculateTotalAmtAllocated();
  }, [values])


  return (
    <>
      {values.newIpoAnchorInvestor?.map((anchor_investor, index) => (


        <tr key={index}>
          <td>
            <Field className="form-control" maxLength="3"
              value={index + 1}
            />
          </td>
          <td>
            <Field
              name={`newIpoAnchorInvestor.${index}.anchor_scheme_name`}
              as={AutocompleteField}
              options={autoComplteOptions}
              onInputChange={(query) => handleAutoSearch(trimValue(query))}
              onInputSelect={(selected) => { handleAutoSelect(selected, index) }}
              onBlur={(e) => handleAutoBlurText(e, index)}
              selected={values.newIpoAnchorInvestor[index]?.company_name ? [values?.newIpoAnchorInvestor[index]?.company_name] : []}
              isAutocomplteLoading={isAutocomplteLoading}
              idatr={`company_name_txt_${index}`}
              nameatr={`newIpoAnchorInvestor.${index}.anchor_scheme_name`}
            />
          </td>
          <td>
            <NumberInput classNameAtr="form-control" maxLength="10"
              nameAtr={`newIpoAnchorInvestor.${index}.shares_allocated`} placeholder="Number of shares allocated"
            />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`newIpoAnchorInvestor.${index}.bid_price`} placeholder="Bid price per share" />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`newIpoAnchorInvestor.${index}.anchor_investor_portion`} placeholder="Anchor Investor Portion" disabledAtr />
          </td>
          <td>
            <NumberDecimalInput classNameAtr="form-control" maxLength="10"
              nameAtr={`newIpoAnchorInvestor.${index}.total_amount_allotted`} placeholder="Total Amount Allotted" disabledAtr />
          </td>

          <td className="text-center">
            <div className="form-check form-switch fs-4">
              <Field className="form-check-input" type="checkbox"
                name={`newIpoAnchorInvestor.${index}.display_status`}
                onChange={(e) => { setFieldValue(e.target.name, e.target.checked) }}
              />
            </div>
          </td>

          <td>
            <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
              <i className="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      ))}
    </>
  )
}

export default NewIpoAnchorInvestorForm;
