import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoCompanyForm from './IpoCompanyForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import IpoTabs from './IPONewPageTabs';

const validationSchema = Yup.object({
    website: Yup.string().trim().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url!').max(50, "website should be at most 50 characters"),
    email: Yup.string().trim().email().max(50, "email can be at max 50 characters long").nullable(),
    registrar_email: Yup.string().trim().email().max(50, "Registrar email can be at max 50 characters long").nullable(),
});

const IpoCompany = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        //name: "", //not in the received data
        id: "",
        ipo_id: "",
        logo_url: "",
        logo_length: "",
        logo_height: "",
        company_desc: "",
        financial: "",
        promoters: "",
        issue_objects: "",
        issue_category: "",
        issue_process_type: "",
        // faqs: "",
        address_1: "",
        address_2: "",
        address_3: "",
        pin_code: "",
        state: "",
        state_id: "",
        city_id: "",
        phone: "",
        fax: "",
        email: "",
        website: "",
        peer_analysis: "",
        peer_group_disclaimer: "",
        peer_group_date: "",
        peer_group_financial_stmt: "",
        anchor_investor_detail: "",
        mutual_fund_detail: "",
        ipo_reservation_desc: "",
        registrar_id: "",
        registrar_email: "",
        lead_managers: "",
        market_maker_id: "",
        lock_status: false,
        ipo_confirmation_status: false,
        company_name: "",
        leadManagerList: [],

        initialLMValues: [],
        initialMMValues: [],
        selectedLMValues: [],
        selectedMMValues: [],
        selectedLMIds: [],
        selectedMMIds: [],

        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "IPO Issuer Company Add Info" })
            } else {
                setMetaData({ "title": "IPO Issuer Company Edit Info" })
            }
            api.post('/admin-ipocompany-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data);
                    if (!isAddMode) {
                        if (response.data.ipocompany[0].lead_managers !== undefined && response.data.ipocompany[0].lead_managers !== null && response.data.ipocompany[0].lead_managers.trim().length > 0) {
                            response.data.ipocompany[0].selectedLMIds = response.data.ipocompany[0].lead_managers.split(",").map(item => parseInt(item.trim()));
                        } else {
                            response.data.ipocompany[0].selectedLMIds = [];
                        }
                        if (response.data.ipocompany[0].market_maker_id !== undefined && response.data.ipocompany[0].market_maker_id !== null && response.data.ipocompany[0].market_maker_id.trim().length > 0) {
                            response.data.ipocompany[0].selectedMMIds = response.data.ipocompany[0].market_maker_id.split(",").map(item => parseInt(item.trim()));
                        } else {
                            response.data.ipocompany[0].selectedMMIds = [];
                        }
                        setInitialValues(response.data.ipocompany[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        trimmedValues.lead_managers = trimmedValues.selectedLMIds.join(',');
        trimmedValues.market_maker_id = trimmedValues.selectedMMIds.join(',');

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-ipocompany-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/82");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <IpoTabs idAtr={result.id} ipo_confirm={result?.ipocompany?.[0]?.ipo_confirmation_status} />
            <h1>
                {metaData.title}
            </h1>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoCompanyForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} api={api} dispatch={dispatch} setResult={setResult} />
            </Formik>
        </>
    )
}

export default IpoCompany;
