import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useField, useFormikContext } from 'formik';

const AutocompleteField = ({ options, idatr, nameatr, tabIndex, onInputChange, onInputSelect, isAutocomplteLoading, ...rest }) => {

    const { setFieldValue } = useFormikContext();
    const [field] = useField(nameatr);

    const handleSelect = (selected) => {
        const selectedValue = selected.length ? selected[0] : '';
        setFieldValue(nameatr, selectedValue);
        onInputSelect(selectedValue);
    };



    return (

        <Typeahead
            id={idatr}
            isLoading={isAutocomplteLoading}
            {...field}
            {...rest}
            selected={field.value ? [field.value] : []}
            onInputChange={onInputChange}
            onChange={handleSelect}
            options={options}
            inputProps={{ tabIndex }}
        />

    );
};

export default AutocompleteField;
