import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import NumberInput from "../Common/NumberInput";
import NumberDecimalInput from "../Common/NumberDecimalInput";
import DynamicCharacterCountInputText from "../Common/CharacterCountTextarea";


const IPOPECalcForm = ({ result, handleGoBack, loading, initialValues, handleSubmit }) => {
  const { values, setFieldValue, setSubmitting } = useFormikContext();
  useEffect(() => {
    handleCalculation();
  }, [
    values.latest_fy,
    values.latest_pat,
    values.latest_revenue,
    values.latest_financial,
    values.latest_financial_pat,
    values.latest_financial_revenue,
    values.auto_nav,
    values.issue_price
  ]);

  const funClearCalculationFileds = () => {
    setFieldValue("calc_derived_pat", "");
    setFieldValue("calc_post_issue_eps", "");
    setFieldValue("calc_pre_issue_eps", "");
    setFieldValue("calc_post_issue_eps_latest", "");
    setFieldValue("calc_post_ipo_pe_latest", "");
    setFieldValue("calc_pre_ipo_pe_fy", "");
    setFieldValue("calc_post_ipo_pe_fy", "");
    setFieldValue("calc_market_cap", "");
    setFieldValue("calc_price_to_book_value", "");
    setFieldValue("calc_derived_revenue", "");
  }

  const handleCalculation = () => {
    const txtTotalShareholdingPreIssue = values.total_shareholding_pre_issue ?? "";
    const txtTotalShareholdingPostIssue = values.total_shareholding_post_issue ?? "";
    const txtLatestPat = values.latest_pat ?? "";
    const txtLatestFinancial = values.latest_financial ?? "";
    const txtLatestFinancialPat = values.latest_financial_pat ?? 0;
    const txtLatestFinancialRevenue = values.latest_financial_revenue ?? 0;

    const txtNav = values.auto_nav ?? "";
    const txtLatestFy = values.latest_fy ?? "";
    const txtIssuePrice = values.issue_price ?? "";

    const txtLatestEps = !isNaN(values.latest_eps) && values.latest_eps !== "" ? parseFloat(values.latest_eps) : 0;
    const txtLatestFinancialEps = !isNaN(values.latest_financial_eps) && values.latest_financial_eps !== "" ? parseFloat(values.latest_financial_eps) : 0;

    let txtDerivedPat = 0;
    let txtDerivedRevenue = 0;

    if (txtLatestFy !== '' && txtLatestFinancial !== '') {
      const date1 = new Date(txtLatestFy);
      const date2 = new Date(txtLatestFinancial);
      const datediff = date2 - date1;
      const day = 1000 * 60 * 60 * 24;
      const days = Math.floor(datediff / day);
      let months = Math.round(days / 31);
      if (months === 0) {
        months = 12;
      }
      txtDerivedPat = (parseFloat(txtLatestFinancialPat) * 12 / months).toFixed(2);
      txtDerivedRevenue = (parseFloat(txtLatestFinancialRevenue) * 12 / months).toFixed(2);
    }

    if (txtDerivedPat !== 0) setFieldValue("calc_derived_pat", txtDerivedPat);
    else setFieldValue("calc_derived_pat", "");

    if (txtDerivedRevenue !== 0) setFieldValue("calc_derived_revenue", txtDerivedRevenue);
    else setFieldValue("calc_derived_revenue", "");

    let txtPostIssueEps = 0;
    let txtPreIssueEps = 0;
    let txtPostIssueEpsLatest = 0;

    if (txtLatestEps == 0) {
      if (isNumeric(txtLatestPat) && isNumeric(txtTotalShareholdingPostIssue)) {
        txtPostIssueEps = txtLatestPat / parseFloat(txtTotalShareholdingPostIssue);
      }

      if (isNumeric(txtLatestPat) && isNumeric(txtTotalShareholdingPreIssue)) {
        txtPreIssueEps = txtLatestPat / parseFloat(txtTotalShareholdingPreIssue);
      }

      if (isNumeric(txtDerivedPat) && isNumeric(txtTotalShareholdingPostIssue)) {
        txtPostIssueEpsLatest = txtDerivedPat / parseFloat(txtTotalShareholdingPostIssue);
      }
    }
    else {
      txtPostIssueEps = txtLatestEps;
      txtPostIssueEpsLatest = txtLatestFinancialEps;
    }

    if (txtPostIssueEps !== 0) setFieldValue("calc_post_issue_eps", txtPostIssueEps.toFixed(2));
    else setFieldValue("calc_post_issue_eps", "");

    if (txtPreIssueEps !== 0) setFieldValue("calc_pre_issue_eps", txtPreIssueEps.toFixed(2));
    else setFieldValue("calc_pre_issue_eps", "");

    if (txtPostIssueEpsLatest !== 0) setFieldValue("calc_post_issue_eps_latest", txtPostIssueEpsLatest.toFixed(2));
    else setFieldValue("calc_post_issue_eps_latest", "");

    let txtPostIpoPeLatest = 0;
    if (isNumeric(txtIssuePrice) && isNumeric(txtPostIssueEpsLatest) && txtPostIssueEpsLatest !== 0) {
      txtPostIpoPeLatest = txtIssuePrice / txtPostIssueEpsLatest;
    }

    if (txtPostIpoPeLatest !== 0) setFieldValue("calc_post_ipo_pe_latest", txtPostIpoPeLatest.toFixed(2));
    else setFieldValue("calc_post_ipo_pe_latest", "");

    let txtPreIpoPeFy = 0;
    if (isNumeric(txtIssuePrice) && isNumeric(txtPreIssueEps) && txtPreIssueEps != 0) {
      txtPreIpoPeFy = txtIssuePrice / txtPreIssueEps;
    }

    if (txtPreIpoPeFy !== 0) setFieldValue("calc_pre_ipo_pe_fy", txtPreIpoPeFy.toFixed(2));
    else setFieldValue("calc_pre_ipo_pe_fy", "");

    let txtPostIpoPeFy = 0;
    if (isNumeric(txtIssuePrice) && isNumeric(txtPostIssueEps)) {
      if (txtPostIssueEps != 0) txtPostIpoPeFy = txtIssuePrice / txtPostIssueEps;
    }

    if (txtPostIpoPeFy !== 0) setFieldValue("calc_post_ipo_pe_fy", txtPostIpoPeFy.toFixed(2));
    else setFieldValue("calc_post_ipo_pe_fy", "");

    let txtMarketCap = 0;
    if (isNumeric(txtIssuePrice) && isNumeric(txtTotalShareholdingPostIssue)) {
      txtMarketCap = ((txtIssuePrice * txtTotalShareholdingPostIssue) / 10000000).toFixed(2);
    }

    if (txtMarketCap !== 0 && txtMarketCap !== "0.00") setFieldValue("calc_market_cap", txtMarketCap);
    else setFieldValue("calc_market_cap", "");

    let txtPriceToBookValue = 0;
    if (isNumeric(txtIssuePrice) && isNumeric(txtNav)) {
      txtPriceToBookValue = (txtIssuePrice / txtNav).toFixed(2);
    }

    if (txtPriceToBookValue !== 0) setFieldValue("calc_price_to_book_value", txtPriceToBookValue);
    else setFieldValue("calc_price_to_book_value", "");
  };

  const isNumeric = (value) => {
    return value !== "" && /^-?\d*\.?\d+$/.test(value);
  };

  const handleKPISubmit = (action) => {
    const isConfirmed = window.confirm("Are you sure to update KPI data ?");
    if (values.kpi_as_of_date == undefined) return;
    if (values.kpi_as_of_date == null || values.kpi_as_of_date == "") {
      alert("Please enter As of date before submitting!");
      return;
    }
    console.log("The action is: ", action)

    if (isConfirmed) {
      values.action = action;
      setTimeout(() => handleSubmit(values, { setSubmitting }), 0);
    }
  };

  return (
    <>
      <Field
        as="input"
        type="hidden"
        name="process"
        value="kpi_process"
      ></Field>
      <div className="my-3 row">
        <label htmlFor="" className="col-lg-2">
          Company Name
        </label>
        <div className="col-lg-4">
          <Field
            as="input"
            className="form-control"
            type="text"
            maxLength="100"
            name="company_name"
            tabIndex={1}
            readOnly
          />
        </div>
      </div>
      <hr />

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          As Of Date
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="date"
            name="kpi_as_of_date"
            id="txtkpi_as_of_date"
            tabIndex={2}
          />
        </div>
        <div className="col-lg-2"></div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Total Shareholding Pre-Issue
        </label>
        <div className="col-lg-2">
          <NumberInput
            classNameAtr={"form-control"}
            type="text"
            maxLength="10"
            nameAtr={"total_shareholding_pre_issue"}
            tabIndexAtr={5}
          />
        </div>
        <div className="col-lg-2"></div>
        <label htmlFor="" className="col-lg-2">
          Promoter Shareholding Pre-Issue
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="promoter_shareholding_pre_issue"
            tabIndex={10}
            maxLength="8"
          />
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Total Shareholding Post-Issue
        </label>
        <div className="col-lg-2">
          <NumberInput
            classNameAtr={"form-control"}
            type="text"
            maxLength="10"
            nameAtr={"total_shareholding_post_issue"}
            tabIndexAtr={15}
          />
        </div>
        <div className="col-lg-2"></div>
        <label htmlFor="" className="col-lg-2">
          Promoter Shareholding Post-Issue
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="promoter_shareholding_post_issue"
            tabIndex={20}
            maxLength="8"
          />

        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          ROE (%)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="kpi_roe"
            tabIndex={25}
            maxLength="8"
          />

          <p className="text-warning mb-0">If value in % then add % sign.</p>
        </div>
        <div className="col-lg-2"></div>
        <label htmlFor="" className="col-lg-2">
          ROCE (%)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="kpi_roce"
            tabIndex={30}
            maxLength="8"
          />

          <p className="text-warning mb-0">If value in % then add % sign.</p>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Debt/Equity
        </label>
        <div className="col-lg-2">
          <NumberDecimalInput
            classNameAtr="form-control"
            tabIndexAtr={35}
            maxLength="10"
            nameAtr="kpi_debt_equity"
          />
        </div>

        <div className="col-lg-2"></div>
        <label htmlFor="" className="col-lg-2">
          RoNW (%)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="kpi_ronw"
            tabIndex={40}
            maxLength="8"
          />
          <p className="text-warning  mb-0">If value in % then add % sign.</p>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          NAV
        </label>
        <div className="col-lg-2">
          <NumberDecimalInput
            classNameAtr="form-control"
            type="text"
            tabIndexAtr={45}
            maxLengthAtr="10"
            nameAtr="nav"
          />
        </div>

        <div className="col-lg-2"></div>
        <label htmlFor="" className="col-lg-2">
          PAT Margin (%)
        </label>
        <div className="col-lg-2">
          <NumberDecimalInput
            as="input"
            classNameAtr="form-control"
            tabIndexAtr={50}
            maxLengthAtr="10"
            nameAtr="kpi_pat_margin"
          />
        </div>
      </div>

      <div className="row mt-2">
        <label htmlFor="kpi_comment" className="col-lg-2">KPI Comment</label>
        <div className="col-lg-6">
          <DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"kpi_comment"} tabIndex={52} />
        </div>
      </div>
      
      <div className="row mt-3">
        <div className="text-center col-12">
          <button
            type="button"
            id="kpibutton"
            className="btn btn-success btn-sm"
            tabIndex={55}
            onClick={() => handleKPISubmit("kpi_save")}
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            Update KPI
          </button>
        </div>
      </div>

      <h2 className="border-bottom pb-2">
        Last Saved P/E and market cap value
      </h2>
      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Pre Issue PE (x)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="pe_ratio"
            maxLength="15"
            tabIndex={60}
            readOnly
          />
        </div>
        <label htmlFor="" className="col-lg-2">
          Post issue P/E (x) (TTM)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="post_pe_ratio"
            maxLength="15"
            tabIndex={65}
            readOnly
          />
        </div>
        <label htmlFor="" className="col-lg-2">
          Market Cap (&#8377; Cr.)
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="market_cap"
            size="15"
            maxLength="15"
            tabIndex={70}
            readOnly
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Pre Issue EPS
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="kpi_eps"
            maxLength="10"
            tabIndex={75}
            readOnly
          />
        </div>
        <label htmlFor="" className="col-lg-2">
          Post Issue EPS TTM Basis
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="kpi_eps_post"
            maxLength="10"
            tabIndex={80}
            readOnly
          />
        </div>

        <label htmlFor="" className="col-lg-2">
          Price to Book Value
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="text"
            name="price_to_book_value"
            maxLength="10"
            tabIndex={85}
            readOnly
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="" className="col-lg-2">
          Latest FY
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="date"
            name="latest_fy_dt"
            tabIndex={90}
            readOnly
          />
        </div>
        <label htmlFor="" className="col-lg-2">
          Latest financials available
        </label>
        <div className="col-lg-2">
          <Field
            as="input"
            className="form-control"
            type="date"
            name="latest_financial_dt"
            tabIndex={95}
            readOnly
          />
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <h2 className="border-bottom pb-2">Auto fill from IPO Details</h2>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Latest FY
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control"
                type="date"
                name="latest_fy"
                id="txtlatest_fy"
                tabIndex={100}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">EPS of latest FY</label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control"
                type="text"
                name="latest_eps"
                id="txtlatest_eps"
                maxLength="25"
                style={{ backgroundColor: "#fff6d1" }}
                tabIndex={105}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              PAT of latest FY
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control"
                type="text"
                maxLength="25"
                name="latest_pat"
                id="txtlatest_pat"
                tabIndex={110}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Revenue of latest FY
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control two-decimals auto-fill calc-content"
                type="text"
                maxLength="25"
                style={{ backgroundColor: "#fff6d1" }}
                name="latest_revenue"
                id="txtlatest_revenue"
                tabIndex={115}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Latest financials available
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control auto-fill calc-content"
                type="date"
                maxLength="15"
                style={{ backgroundColor: "#fff6d1" }}
                name="latest_financial"
                id="txtlatest_financial"
                tabIndex={120}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              EPS of latest financials
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control auto-fill calc-content"
                type="text"
                maxLength="25"
                style={{ backgroundColor: "#fff6d1" }}
                name="latest_financial_eps"
                id="txtlatest_financial_eps"
                tabIndex={125}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              PAT of latest financials
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control two-decimals auto-fill calc-content"
                type="text"
                maxLength="25"
                name="latest_financial_pat"
                id="txtlatest_financial_pat"
                tabIndex={130}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Revenue of latest financials
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control two-decimals auto-fill calc-content"
                type="text"
                maxLength="25"
                name="latest_financial_revenue"
                id="txtlatest_financial_revenue"
                tabIndex={135}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Nav
            </label>
            <div className="col-lg-6">
              <NumberDecimalInput
                classNameAtr="form-control two-decimals auto-fill calc-content"
                maxLengthAtr="10"
                nameAtr="auto_nav"
                id="txtnav"
                tabIndexAtr={140}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-6">
              Upper Price Band
            </label>
            <div className="col-lg-6">
              <Field
                as="input"
                className="form-control two-decimals auto-fill calc-content"
                type="text"
                maxLength="10"
                name="issue_price"
                id="txtIssue_Price"
                tabIndex={145}
                style={{ backgroundColor: "#fff6d1" }}
              />
            </div>
          </div>
        </div>
        <div className="col-9">
          <h2 className="border-bottom pb-2">Calculated Data</h2>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Annualized PAT (TTM)
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="15"
                name="calc_derived_pat"
                tabIndex={150}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Annualized Revenue (TTM)
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="15"
                name="calc_derived_revenue"
                id="txtderived_revenue"
                tabIndex={160}
              />
            </div>
          </div>
          <h2 className="border-bottom pb-2">EPS Calculation</h2>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Pre Issue EPS{" "}
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="10"
                name="calc_pre_issue_eps"
                id="txtpre_issue_eps"
                tabIndex={165}
              />
            </div>

            <label htmlFor="" className="col-lg-3">
              Post Issue EPS{" "}
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_post_issue_eps"
                id="txtpost_issue_eps"
                tabIndex={170}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Post Issue EPS TTM Basis
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_post_issue_eps_latest"
                id="txtpost_issue_eps_latest"
                tabIndex={175}
              />
            </div>
          </div>
          <h2 className="border-bottom pb-2">PE Calculation</h2>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Pre IPO PE Basis
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_pre_ipo_pe_fy"
                id="txtpre_ipo_pe_fy"
                tabIndex={180}
              />
            </div>

            <label htmlFor="" className="col-lg-3">
              Post IPO PE Basis
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_post_ipo_pe_fy"
                id="txtpost_ipo_pe_fy"
                tabIndex={185}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Post IPO PE TTM Basis
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_post_ipo_pe_latest"
                id="txtpost_ipo_pe_latest"
                tabIndex={190}
              />
            </div>
          </div>
          <h2 className="border-bottom pb-2">Other</h2>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Market Capitalization (₹ Cr.)
            </label>
            <div className="col-lg-3">
              <Field
                as="input"
                className="form-control two-decimals"
                type="text"
                maxLength="30"
                name="calc_market_cap"
                id="txtMarket_Cap"
                tabIndex={195}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-3">
              Price to Book Value
            </label>
            <div className="col-lg-3">
              <NumberDecimalInput
                classNameAtr="form-control two-decimals"
                maxLength="30"
                idAtr="txtprice_to_book_value"
                tabIndexAtr={200}
                nameAtr="calc_price_to_book_value"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-2">
        <Field
          as="input"
          style={{ display: 'none' }}
          type="text"
          name="action"
          maxLength="20"
          tabIndex={205}
        />
      </div>

      <div className="mb-3 text-center">
        <button
          type="button"
          className="btn btn-success btn-sm mx-2"
          name="cmdSubmit"
          onClick={handleCalculation}
          tabIndex={210}
        >
          Calculation
        </button>
        {initialValues.lock_status !== 1 && (
          <button
            type="button"
            className="btn btn-warning btn-sm me-2"
            id="calcbutton"
            onClick={() => handleKPISubmit("pe_calc")}
            disabled={loading}
            tabIndex={215}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            Save Calculation
          </button>
        )}
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          name="cmdclear"
          onClick={funClearCalculationFileds}
          tabIndex={220}
        >
          Reset
        </button>
      </div>
    </>
  );
};

export default IPOPECalcForm;
