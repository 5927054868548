import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import FileUploadForm from './FileUploadForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const FileUpload = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let a = queryParams.get("a") ?? "";
    
    const validationSchema = Yup.object({
        folder: Yup.string().trim().required('Please select folder to upload'),
    });
    const [result, setResult] = useState([]);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        folder: a,
        file: [
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "File Uploads" })
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        if (values.file.length == 0) {
            alert("Select at-least one file to upload..");
            return;
        }
        var checkError = 0;
        const myRegExp = /^[^\s][a-z0-9_-]+(\.[a-z0-9]+)?$/;
        values.file.forEach(element => {
            if (element.filebase64 && element.filename != "") {
                
                const isValid = myRegExp.test(element.filename);
                if (!isValid) {
                    alert("Invalid file name \""+element.filename+"\". Make sure to upload file with name 'a-Z 1-9 0 . - _'");
                    checkError = 1;
                }
                if (values.folder == "list-of-scripts-bse" && element.filename.toLowerCase() != "equity.csv") {
                    alert("Invalid file name. Make sure to upload file with name 'Equity.csv'");
                    checkError = 1;
                }
            } else {
                alert("One of the File Name or image missing..");
                checkError = 1;
            }
        });
        if(checkError == 1){
            return;
        }

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-fileupload-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                setResult(response.data.result);
            } else {
                if(response.data.errors){
                    const errorList = response.data.errors.map((errorItem) => {
                        return `Filename: ${errorItem.filename}, Error: ${errorItem.error}`;
                    });
                
                    const errorMessage = errorList.join('\n<br>');
                    dispatch(setMessage(errorMessage));
                }else{
                    dispatch(setMessage(response.data.error));
                }
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
            setSubmitting(false);
        });
        // Set submitting to false to allow the form to be submitted again
        
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {
                result != undefined && result.length > 0 &&
                <textarea className='form-control' rows={5}>{result}</textarea>
            }
            <p></p>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <FileUploadForm handleGoBack={handleGoBack} loading={loading} initialValues={initialValues}/>
            </Formik>


        </>
    )
}

export default FileUpload
