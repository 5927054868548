import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

import CharacterCountInput from '../Common/CharacterCountInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';


const ReportCalendarWebForm = ({ result, handleGoBack, loading, initialValues }) => {

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: currentYear - 2006 + 1 }, (_, index) => 2006 + index);

  const monthOptions = Array.from({ length: 12 }, (_, index) => 1 + index);

  const { setFieldValue } = useFormikContext()

  return (
    <>
      <Form id='myForm'>
        {result !== undefined && result.reportwebcalendar !== undefined && result.reportwebcalendar.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.reportwebcalendar[0].id}
            </div>
          </div>
        }

        <fieldset>

          <div className="mb-3 row">
            <label htmlFor="report_title" className="col-lg-2 required">Reports Title</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="100" tabIndex={1} name="report_title" />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="short_name" className="col-lg-2 required">Short Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="100" tabIndex={5} name="short_name" />
            </div>

          </div>
          <div className="mb-3 row">
            <label htmlFor="table_heading" className="col-lg-2">Table Heading</label>
            <div className="col-lg-6">
              <Field className="form-control" type="text" maxLength="100" tabIndex={10} name="table_heading" />
            </div>

            <div className="col-lg-4">
              (Keep empty for default 'Report Title' as Table heading.)
            </div>

          </div>
        </fieldset>
        <fieldset>

          <legend>Meta Tags</legend>
          <hr />
          <p>Note: If <mark>meta tags are empty</mark>, the report will create meta tags from
            report title and description.</p>
          <div className="mb-3 row">
            <label htmlFor="meta_title" className="col-lg-2">Meta title (60 to 80 Char)</label>
            <div className="col-lg-5">
              <CharacterCountInput
                nameattr="meta_title"
                type="text"
                maxLength="80"
                tabIndex="15"
                className="form-control"
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="meta_desc" className="col-lg-2">Meta Description (150 to 160 Char)</label>
            <div className="col-lg-9">
              <CharacterCountTextarea
                nameattr="meta_desc"
                maxLength="250"
                type="textarea"
                tabIndex="25"
                rows="5"
                className="form-control"
              />
            </div>
            <div className="col-lg-1">
              <span id="divMetaDescCharCount"></span>
            </div>
          </div>

        </fieldset>
        <fieldset>
          <legend>Query Setup</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="report_sql" className="col-lg-2 required">Report Query</label>
            <div className="col-lg-8">
              <Field as="textarea" className="form-control" rows="5"
                tabIndex={25} name="report_sql" />
              <p className="mt-2 text-danger">Note : don't include order by</p>
            </div>

            <div className="col-lg-2">
              <span className="text-info">(Use [[Parameter1]] for additional parameter)</span>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Field Setup</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="id_field" className="col-lg-2 required">ID Field Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50" tabIndex={30} name="id_field"
              />
            </div>
            <div className="col-lg-6">
              (i.e. id)
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="title_field" className="col-lg-2 required">Title Field Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50" tabIndex={40} name="title_field"
              />
            </div>
            <div className="col-lg-6">
              (i.e. cal_title)
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="desc_field" className="col-lg-2 required">Desc Field Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50" tabIndex={45} name="desc_field"
              />
            </div>
            <div className="col-lg-6">
              (i.e. cal_desc)
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="date_field" className="col-lg-2 required">Calendar Date Field Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50" tabIndex={50} name="date_field"
              />
            </div>
            <div className="col-lg-6">
              (i.e. cal_date)
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="topic_id_field" className="col-lg-2 required">Topic ID Field Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50" tabIndex={55} name="topic_id_field" />
            </div>
            <div className="col-lg-6">
              (i.e. Topic_id)
            </div>
          </div>
        </fieldset>


        <fieldset>
          <legend>Description</legend>
          <hr />
          <div className="mb-3 row">
            <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
            <div className="col-lg-10">
              <Field as="textarea" className="form-control" rows="5"
                tabIndex={60} name="report_description" />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
            <div className="col-lg-10">
              <Field as="textarea" className="form-control" rows="3"
                tabIndex={65} name="report_disclaimer" />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="report_breadcrumb" className="col-lg-2">Reports Breadcrumb (HTML)</label>
            <div className="col-lg-10">
              <Field as="textarea" className="form-control" rows="3"
                tabIndex={70} name="report_breadcrumb" />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" size="55" maxLength="50"
                tabIndex={75} name="urlrewrite_folder_name" />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Others</legend>
          <hr />
          <div className="mb-3 row row">
            <label htmlFor="additional_parameter" className="col-lg-2">Additional Parameter</label>
            <div className="col-lg-4">
              <Field as="select" className="form-select" size="1" tabIndex={80} name="additional_parameter"
                id="additional_parameterddl">
                <option value="0" >No</option>
                <option value="1">Yes</option>
              </Field>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="minimum_nav_year" className="col-lg-2">Minimum Navigation Year</label>
            <div className="col-lg-2">
              <Field
                as="select"
                className="form-select"
                tabIndex={85}
                name="minimum_nav_year"
              >
                <option not selected>Select a year</option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Field>
            </div>

            <label htmlFor="minimum_nav_month" className="col-lg-2">Minimum Navigation Month</label>
            <div className="col-lg-2">

              <Field
                as="select"
                className="form-select"
                tabIndex={90}
                name="minimum_nav_month"
                maxLength="2"
              >
                <option not selected>Select a Month</option>
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Field>
            </div>

          </div>
          <div className="mb-3 row">
            <label htmlFor="search_column" className="col-lg-2">Search Column Names</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="150" tabIndex={100} name="search_column"
              />
            </div>
            <div className="col-lg-6">
              (i.e. company_name,display_status)
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="select_list_function_name" className="col-lg-2">Select List Function</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="50"
                tabIndex={105} name="select_list_function_name" />
            </div>
            <div className="col-lg-6">
              (i.e. IPO,RI,NCD)

            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="link_file_name" className="col-lg-2">Link URL</label>
            <div className="col-lg-4">
              <Field className="form-control" type="text" maxLength="250" tabIndex={110} name="link_file_name"
              />
            </div>
            <div className="col-lg-6">
              [AppPath]newportal/test.asp?a=[~id]<br />
              [AppPath]stockbroker/[~urlrewrite_folder_name]/[~id]/
            </div>
          </div>

        </fieldset>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-10">
            <Field className="form-check-input" type="checkbox"
              tabIndex={115} name="display_status" onChange={(e) => {
                const newValue = e.target.checked ? 1 : 0;
                setFieldValue('display_status', newValue);
              }} />
          </div>
          <label htmlFor="stock_holiday" className="col-lg-2">Include Stock Holidays</label>
          <div className="col-lg-10">
            <Field className="form-check-input" type="checkbox"
              tabIndex={120} name="stock_holiday" onChange={(e) => {
                const newValue = e.target.checked ? 1 : 0;
                setFieldValue('stock_holiday', newValue);
              }} />
          </div>
          <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={125} name="lock_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('lock_status', newValue);
            }} />
          </div>

        </div>
        <div className="mb-3 text-center">

          {!initialValues.lock_status == 1 &&
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={130}>
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.reportwebcalendar !== undefined && result.reportwebcalendar.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          }

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={135}
          >Cancel</button>

        </div>

        <ValidateFormFields />
      </Form>

    </>
  )
}

export default ReportCalendarWebForm