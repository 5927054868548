import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingCompanyDematChargesForm from './TradingCompanyDematChargesForm';
import TradingCompanyTabs from './TradingCompanyTabs';
import * as Yup from 'yup';
import { Formik } from 'formik';

const validationSchema = Yup.object({
});

const TradingCompanyDematCharges = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        depository_service_provider: "",
        dp_id: "",
        address: "",
        telephone_number: "",
        email: "",
        about_us: "",
        services: "",
        account_opening_fee: "",
        advance_deposite: "",
        amc: "",
        dematerialisation_charges: "",
        rematerialisation_charges: "",
        transaction_credit_charges: "",
        transaction_debit_charges: "",
        pledge_creation_charges: "",
        pledge_creation_confirmation_charges: "",
        pledge_invocation_charges: "",
        failed_instruction_charges: "",
        other_charges: "",
        display_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/19");
    };

    const convertMojibakeToRupee = (data) => {
        const values = Object.values(data);
        let i = 0;
        for (let key in data) {
            if (typeof values[i] === 'string') {
                data[key] = values[i].replace(/â‚¹/g, '₹ ');
            }
            i++;
        }
        return data;
    }

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Edit Trading Company Demat Charges" })

            api.post('/admin-tradingcompanydematcharges-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    //console.log(response.data.tradingCompanyDematCharges);
                    if (!isAddMode) {
                        setInitialValues(convertMojibakeToRupee(response.data.tradingCompanyDematCharges[0]));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount



    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());
        

        api.post('/admin-tradingcompanydematcharges-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/19");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
            
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <TradingCompanyTabs idAtr={result.id} />
            <h1>
                {metaData.title} (
                <span style={{ color: 'blue' }}>
                    {result.tradingCompany != undefined && result.tradingCompany[0].company_name}
                </span>
                )
            </h1>
            <hr />
            {result != undefined && result.tradingCompany != undefined &&
                result.tradingCompany.length > 0 &&
                result.tradingCompany[0].lock_status !== undefined &&
                result.tradingCompany[0].lock_status == 1 && (
                    <h3 className="text-danger mb-3">
                        Record Is Locked , you can't update it.
                    </h3>
                )}


            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <TradingCompanyDematChargesForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
            </Formik>

        </>
    )
}

export default TradingCompanyDematCharges
