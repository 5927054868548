import React, { useState, useEffect } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { useFormikContext, Field, Form } from 'formik';
import MultiSelectTransfer from '../Common/MultiSelectTransfer';
import DynamicCharacterCountInput from "../Common/CharacterCountInput";
import DynamicCharacterCountInputText from "../Common/CharacterCountTextarea";
import PhoneInput from "../Common/PhoneInput";
import { setMessage } from '../../../state/slices/message';
import NumberInput from '../Common/NumberInput';
import FileUpload from '../Common/FileUpload';

const IpoCompanyForm = ({ result, handleGoBack, initialValues, loading, api, dispatch, setResult }) => {
	const { values, setFieldValue } = useFormikContext();
	const [formState, setFormState] = useState({
		anchorInvestorUrlDisabledStatus: false
	})


	const populateCityList = (e) => {
		let state_id = e;
		api.post('/cityList-read', {
			state_id: state_id
		}).then(response => {
			if (response.data.msg === 1) {
				setResult((prevValues) => ({
					...prevValues,
					cityList: response.data.cityList
				}));
			} else {
				dispatch(setMessage(response.data.error));
			}
		}).catch(error => {
			console.error('Error:', error);
		});
	}

	useEffect(() => {
		let status = true;
		if (values.issue_process_type == 'BB') status = !status;
		setFormState((prevState) => ({
			...prevState,
			anchorInvestorUrlDisabledStatus: status
		}));

		if (initialValues.state_id !== 0 && initialValues.state_id !== "") {
			populateCityList(initialValues.state_id)
		}
	}, [values.issue_process_type, initialValues.state_id]);


	return (
		<>

			<Form id="myForm">
				{result !== undefined &&
					result.ipocompany !== undefined &&
					result.ipocompany.length > 0 &&
					result.ipocompany[0].lock_status == 1 && (
						<h3 className="text-danger mt-3 mb-0">
							Record Is Locked , you can't update it.
						</h3>
					)}

				{result !== undefined &&
					result.ipocompany !== undefined &&
					result.ipocompany.length > 0 && (
						<div className="mb-3 row">
							<label htmlFor="" className="col-lg-2">
								ID:
							</label>
							<div className="col-lg-4">{result.ipocompany[0].ipo_id}</div>
						</div>
					)}

				{result !== undefined &&
					result.ipocompany !== undefined &&
					result.ipocompany.length > 0 && (
						<div className="mb-3 row">
							<label htmlFor="company_name" className="col-lg-2">Name</label>
							<div className="col-lg-4" tabIndex={1}>{result.ipocompany[0].company_name}</div>
						</div>
					)}
				<hr />
				<div className='mb-3 row'>
					<label htmlFor="image" className='col-lg-2'>Choose an image:</label>
					<div className="col-lg-10">
						<FileUpload nameattr="file" namebase64attr="filebase64" imageField="logo_url" />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="logo_url" className="col-lg-2">Logo Image Name:</label>
					<div className="col-lg-3">
						<Field className="form-control" type="text" maxLength="100" name="logo_url" tabIndex={10} />
					</div>
					<label htmlFor="logo_length" className="col-lg-1">Width:</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr={"form-control"} tabIndexAtr={15} maxLength={"5"} nameAtr={"logo_length"} idAtr="txtlogo_Length" />
					</div>
					<label htmlFor="logo_height" className="col-lg-1">Height:</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr={"form-control"} tabIndexAtr={20} maxLength={"5"} nameAtr={"logo_height"} idAtr="txtlogo_Height" />
					</div>
				</div>
				<hr />

				<h3>Address</h3>
				<div className="mb-3">
					<div className="row mb-2">
						<label htmlFor="address_1" className="col-lg-2">Address Line 1</label>
						<div className="col-lg-10">
							<Field className="form-control" type="text" name="address_1" maxLength="50" tabIndex={2} />
						</div>
					</div>

					<div className="row mb-2">
						<label htmlFor="address_2" className="col-lg-2">Address Line 2</label>
						<div className="col-lg-10">
							<Field className="form-control" type="text" name="address_2" maxLength="50" tabIndex={5} />
						</div>
					</div>

					<div className="row mb-2">
						<label htmlFor="address_3" className="col-lg-2">Address Line 3</label>
						<div className="col-lg-10">
							<Field className="form-control" type="text" name="address_3" maxLength="50" tabIndex={10} />
						</div>
					</div>
				</div>

				<div className="mb-3 row">

					<label htmlFor="state_id" className="col-lg-2">State</label>
					<div className="col-lg-3">
						<Field as="select" name="state_id" className="form-select" tabIndex={15}
							onChange={(e) => {
								let stateId = e.target.value;
								setFieldValue('state_id', stateId);
								if (stateId == "") setFieldValue("city_id", "");
								else populateCityList(stateId);
							}}
						>
							<option></option>
							{result.stateList !== undefined && result.stateList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</Field>
					</div>

					<label htmlFor="city_id" className="col-lg-2 ">City</label>
					<div className="col-lg-3">
						<Field as="select" name="city_id" className="form-select" tabIndex={20} >
							<option></option>
							{result && result.cityList && result.cityList.length !== 0 && result.cityList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</Field>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="pin_code" className="col-lg-2">Pin Code</label>
					<div className="col-lg-3">
						<NumberInput classNameAtr="form-control" tabIndexAtr={25} maxLength="6" nameAtr="pin_code" />
					</div>

					<label htmlFor="phone" className="col-lg-2">Phone</label>
					<div className="col-lg-3">
						<PhoneInput classNameAtr="form-control" nameAtr="phone" tabIndexAtr={30} />
					</div>
				</div>


				<div className="mb-3 row">
					<label htmlFor="email" className="col-lg-2">Email</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput className="form-control" type="text" nameattr={"email"} maxLength="50" tabIndex={35} />
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="website" className="col-lg-2">Website  </label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput className="form-control" type="text" nameattr="website" maxLength="50" tabIndex={40} />
					</div>
				</div>
				<hr />
				<div className="mb-3 row">
					<label htmlFor="company_desc" className="col-lg-2">Company Desc.</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr="company_desc" tabIndex={45} />
					</div>
					<label htmlFor="issue_objects" className="col-lg-2">Objects Of The Issue</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr="issue_objects" tabIndex={50} disabled={true} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="promoters" className="col-lg-2">Company Promoters</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr="promoters" tabIndex={55} />
					</div>
					<label htmlFor="faqs" className="col-lg-2">FAQs</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr="faqs" tabIndex={60} />
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="other_issue_detail" className="col-lg-2" >Other Issue Detail</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr="other_issue_detail" tabIndex={65} />
					</div>
					<label htmlFor="ipo_notices" className="col-lg-2" >IPO Notices</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr="ipo_notices" tabIndex={70} />
					</div>
				</div>

				<hr></hr>
				<div className="mb-3 row">
					<label htmlFor="financial" className="col-lg-2">Company Financial</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"financial"} tabIndex={100} disabled={true} />
					</div>
					<label htmlFor="peer_analysis" className="col-lg-2">Peer Group Analysis</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"peer_analysis"} tabIndex={105} disabled={true} />
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="anchor_investor_detail" className="col-lg-2" >Anchor Investor Detail</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"anchor_investor_detail"} tabIndex={110} disabled={true} />
					</div>
					<label htmlFor="mutual_fund_detail" className="col-lg-2" >Mutual Fund Detail</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"mutual_fund_detail"} tabIndex={115} disabled={true} />
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="ipo_reservation_desc" className="col-lg-2" >IPO Reservation Detail</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"ipo_reservation_desc"} tabIndex={120} disabled={true} />
					</div>
					<label htmlFor="issue_expenses" className="col-lg-2">Expenses</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInputText className="form-control" rows="5" nameattr="issue_expenses" tabIndex={50} />
					</div>
				</div>

				<hr />
				<h3>IPO Managers</h3>
				<div className="mb-3 row">
					<MultiSelectTransfer dataList={result.leadManagerList} labelAttr={"Lead Managers"} initialItemsNameAttr={"initialLMValues"} selectedItemsNameAttr={"selectedLMValues"} selectedItemsIdsAttr={"selectedLMIds"} />
				</div>

				<div className="mb-3 row">
					<label htmlFor="registrar_id" className="col-lg-2" >IPO Registrar</label>
					<div className="col-lg-4">
						<Field as="select" name="registrar_id" className="form-select" tabIndex={71} >
							<option></option>
							{result.registrarList !== undefined && result.registrarList.map((option) => (
								<option key={option.id} value={option.id}>
									{option.comp_name}
								</option>
							))}
						</Field>
					</div>
					<label htmlFor="" className="col-lg-2" >Registrar Email</label>
					<div className="col-lg-4">
						<Field className="form-control" type="text" maxLength="50" name="registrar_email" tabIndex={75} />
					</div>
				</div>
				<div className="mb-3 row">
					<MultiSelectTransfer dataList={result.marketMakerList} labelAttr={"Market Maker for SME"} initialItemsNameAttr={"initialMMValues"} selectedItemsNameAttr={"selectedMMValues"} selectedItemsIdsAttr={"selectedMMIds"} disabledAttr={values.issue_category == "Mainline"} />
				</div>


				<hr />
				<div className="mb-3 text-center">
					{initialValues.lock_status !== 1 && (
						<button
							type="submit"
							className="btn btn-primary btn-block"
							disabled={loading}
							tabIndex={80}
						>
							{loading && (
								<span className="spinner-border spinner-border-sm me-2"></span>
							)}
							<span>
								{result.ipocompany !== undefined && result.ipocompany.length > 0
									? "Update"
									: "Create"}
							</span>
						</button>
					)}

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={85}
					>
						Cancel
					</button>
				</div>
				<ValidateFormFields />
			</Form>
		</>
	);
};

export default IpoCompanyForm;
