import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import AlertForm from './AlertForm';
import { Formik } from 'formik';

const validationSchema = Yup.object({
    alert_title: Yup.string().trim().required('Alert Title is required'),
    alert_desc: Yup.string().trim().required('Alert Body is required'),
    alert_url: Yup.string().trim().required('Alert URL is required'),
    send_dt: Yup.string().trim().required('Alert Date is required'),
    send_time: Yup.string().trim().required('Alert Time is required')
});

const Alert = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();
    const date = new Date();

    const [initialValues, setInitialValues] = useState({
        id: "",
        homepage_alert: false,
        mobile_alert: false,
        web_alert: false,
        alert_desc: "",
        alert_title: "",
        alert_topic: "",
        alert_type: "",
        alert_url: "",
        alert_image: "",
        send_dt: "",
        send_time: "",
        sticky_status: false,
        highlight_status: false,
        display_status: false,
    });

    const [pageData, setPageData] = useState({
        pageLoaded: 0,
    })

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            if (isAddMode) {
                setMetaData({ "title": "Add New Alert" })
            } else {
                setMetaData({ "title": "Edit Alert" })
            }
            api.post('/admin-alert-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    setPageData({
                        pageLoaded: 1,
                    })
                    if (!isAddMode) {
                        setInitialValues(response.data.alert[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const validate = (values) => {
        if (values.homepage_alert == 0 && values.mobile_alert == 0) {
            alert("Please select atleast one among mobile alert and homepage alert!")
            return false;
        }

        return true;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (validate(trimmedValues)) {
            trimmedValues["send_dt"] = trimmedValues["send_dt"] + " " + trimmedValues["send_time"];
            if (validate(trimmedValues))
                console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());

            api.post('/admin-alert-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    // navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
            // Set submitting to false to allow the form to be submitted again
            setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.alert !== undefined &&
                result.alert.length > 0 &&
                result.alert[0].lock_status == 1 && (
                    <h3 className="text-danger mt-3 mb-0">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <br />

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    pageData.pageLoaded == 1 && <AlertForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
                }

            </Formik>
        </>
    )
}

export default Alert
