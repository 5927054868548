import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Field, FieldArray, useFormikContext } from 'formik';

const FileUploadMultiple = ({ nameattr, imageField, namebase64attr, pdfOnly = false }) => {
    const [isDragging, setIsDragging] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const imagePreviewRefs = useRef([]);

    const allowedExtensions = pdfOnly ? ['.pdf'] : ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.csv'];
    const allowedExtensionsString = allowedExtensions.join(', ');



    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event, push) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);

        droppedFiles.forEach(file => {

            if (validateExtension(file.name) && fileSizeCheck(file)) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result;
                    push({ filebase64: base64Data, fileoverwrite: false, filename: file.name });
                    // previewImage(base64Data, values[nameattr].length);
                };
                reader.readAsDataURL(file);
            } else {
                alert(`Invalid file type. Only ${allowedExtensionsString} files are allowed.`);
            }
        });
    };
    const fileSizeCheck = (file) => {
        const fileSizeLimit = 35 * 1024 * 1024; // 20MB in bytes
        console.log(file.size, fileSizeLimit)

        if (file && file.size < fileSizeLimit) {
            return true;
        }
        else {
            alert("File size exceeds 20MB limit.")
            return false;
        }

    }
    const handleFileChange = (event, push) => {
        const selectedFiles = Array.from(event.target.files);
        selectedFiles.forEach(file => {
            if (!fileSizeCheck(file))
                return;
            if (validateExtension(file.name)) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result;
                    push({ filebase64: base64Data, fileoverwrite: false, filename: file.name });
                    // previewImage(base64Data, values[nameattr].length);
                };
                reader.readAsDataURL(file);
            } else {
                alert(`Invalid file type. Only ${allowedExtensionsString} files are allowed.`);
            }
        });
    };

    const validateExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(`.${fileExtension}`);
    };

    const validateDocExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return ['.pdf', '.csv'].includes(`.${fileExtension}`);
    };

    const handleRemove = (index, remove) => {
        remove(index);
        if (imagePreviewRefs.current[index] && imagePreviewRefs.current[index].current) {
            imagePreviewRefs.current[index].current.src = '';
        }
    };

    const handleRemoveAll = (form) => {
        const valuesCopy = [...form.values[nameattr]];
        valuesCopy.splice(0, valuesCopy.length); // Remove all elements
        form.setFieldValue(nameattr, valuesCopy);
    };

    return (
        <FieldArray name={nameattr}>
            {({ push, remove, form }) => (
                <div className='row'>
                    <label htmlFor="image" className='col-lg-2 required'>Choose an image:</label>
                    <div className="col-lg-10">
                        <div
                            className={`d-flex align-items-center justify-content-center drop-area p-3 text-center border rounded ${isDragging ? 'dragging' : ''}`}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e) => handleDrop(e, push)}
                            onClick={() => document.querySelector(`input[name="${nameattr}"]`).click()}
                            style={{ height: '100px', cursor: 'pointer' }}
                        >
                            <input type="file" name={nameattr} onChange={(e) => handleFileChange(e, push)} style={{ display: 'none' }} multiple />
                            <p>Drag and drop files here, or click to select</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <table className='table table-bordered table-striped mt-3 table-auto'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Image Name</th>
                                    <th>Overwrite</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {values[nameattr] && values[nameattr].map((file, index) => (
                                    <tr key={index}>
                                        <td>
                                            {
                                                validateDocExtension(values[nameattr][index].filename) ?
                                                    <img
                                                        // ref={imagePreviewRefs.current[index]}
                                                        src="/images/file_icon.png"
                                                        alt="Preview"
                                                        style={{ height: '80px', marginTop: '5px' }}
                                                    />
                                                    :
                                                    <img
                                                        // ref={imagePreviewRefs.current[index]}
                                                        src={values[nameattr][index].filebase64}
                                                        alt="Preview"
                                                        style={{ height: '80px', marginTop: '5px' }}
                                                    />
                                            }


                                        </td>

                                        <td>

                                            <label htmlFor="" className="col-12">Image Name:</label>

                                            <input className="form-control" type="text" maxLength="100" name={`filename_${index}`} value={values[nameattr][index].filename} onChange={(e) => {
                                                const newValue = e.target.value;

                                                const updatedFiles = values.file.map((file, indexIn) => {
                                                    if (indexIn === index) { // Check if this is the second object (index 1)
                                                        return { ...file, filename: newValue }; // Update the fileoverwrite field to false
                                                    }
                                                    return file;
                                                });

                                                setFieldValue('file', updatedFiles);
                                                setFieldValue('filename_' + index, newValue);
                                            }} />



                                        </td>
                                        <td>

                                            <label htmlFor="display_status" className='mt-4'>Overwrite File
                                                <Field className="form-check-input ms-3" type="checkbox" name={`fileoverwrite_${index}`} onChange={(e) => {
                                                    const newValue = e.target.checked;

                                                    const updatedFiles = values.file.map((file, indexIn) => {
                                                        if (indexIn === index) { // Check if this is the second object (index 1)
                                                            return { ...file, fileoverwrite: newValue }; // Update the fileoverwrite field to false
                                                        }
                                                        return file;
                                                    });

                                                    setFieldValue('file', updatedFiles);
                                                    setFieldValue('fileoverwrite_' + index, newValue);
                                                }} />
                                            </label>

                                        </td>
                                        <td>
                                            <Button variaznt="outline-danger" className="ms-2" size="sm" onClick={() => handleRemove(index, remove)}>
                                                Remove
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                    <div className='col-12 mt-2 text-end'>
                        <Button variant="outline-danger" size="sm" onClick={() => handleRemoveAll(form)}>
                            Remove All
                        </Button>
                    </div>
                </div>
            )
            }
        </FieldArray >
    );
};

export default FileUploadMultiple;
