import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { login, logout } from "../../state/slices/auth";
import { clearMessage, setMessage } from "../../state/slices/message";
import * as Yup from "yup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import routeConfig from "../../routeConfig";
import { closePopUpWindow, funCloseWebWindow } from "../admintool9x/Common/CommonFunction";
import Cookies from 'js-cookie';
import { useGoogleLogin } from "@react-oauth/google";
const Login = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref") ?? "";
  const email = queryParams.get("email") ?? "";

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
  const emailRef = useRef(null); // Ref for email input field

  const btnClick = () => {
    if (ref != "") {
      funCloseWebWindow(ref);
    } else {
      closePopUpWindow();
    }
  };

  const formik = useFormik({
    initialValues: {
      username: email ?? "",
      password: "",
      RemMe: true,
      recaptcha: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    }),
    onSubmit: async (formValue) => {
      if (loading) return;
      setLoading(true);

      const { username, password, RemMe, recaptcha } = formValue;

      if (!currentUser) {
        if (RemMe) {
          Cookies.set("rememberMeEmail", username, { secure: true, sameSite: "Strict", expires: 30 });
          Cookies.set("rememberMePassword", password, { secure: true, sameSite: "Strict", expires: 30 });
        } else {
          Cookies.remove("rememberMeEmail");
          Cookies.remove("rememberMePassword");
        }
      }

      try {
        const data = await dispatch(login({ username, password, recaptcha })).unwrap();

        // Clear fields after login attempt
        formik.resetForm();
        if (emailRef.current) {
          emailRef.current.focus();
        }
        if (data && data.user.login_cnt === 0) {
          navigate(`${routeConfig.change_pwd()}?ref=${ref}`);
        }
      } catch (error) {
        console.error("Login failed:", error);
        dispatch(setMessage("Login failed. Please check your credentials and try again."));
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (didMountRef.current == "true") {
      if (currentUser) {
        const isSecondVerificationNeeded = Cookies.get("isSecondVerificationNeeded") === "true";
        if (isSecondVerificationNeeded) {
          return;
        }

        if (currentUser.role_id === 2 || currentUser.role_id === 3) {
          navigate(ref || "/admintool9x");
        } else if (currentUser.role_id === 1) {
          navigate(ref || "/");
        }
      } else {
        const storedEmail = Cookies.get("rememberMeEmail");
        const storedPassword = Cookies.get("rememberMePassword");

        if (storedEmail && storedPassword && !isLoggedIn && !loading) {
          setLoading(true);

          formik.setFieldValue("username", storedEmail);
          formik.setFieldValue("password", storedPassword);

          dispatch(login({ username: storedEmail, password: storedPassword }))
            .unwrap()
            .then((data) => {
              if (data?.user?.login_cnt === 0) {
                navigate(`${routeConfig.change_pwd()}?ref=${ref}`);
              }
            })
            .catch(() => {
              console.error("Auto-login failed.");
              dispatch(setMessage("Connection Error!"));
            })
            .finally(() => setLoading(false));
        }

        dispatch(clearMessage());
      }
    } else {
      didMountRef.current = "true";
    }
  }, [currentUser]);

  const logoutFunction = () => {
    Cookies.remove('rememberMeEmail');
    Cookies.remove('rememberMePassword');
    dispatch(logout());
    formik.resetForm(); // Clear inputs on logout
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log("Success Google Login");
      // console.log(credentialResponse);
      setLoading(true);
      dispatch(
        login({
          googleToken: credentialResponse.code,
          loginType: "G",
        })
      )
        .unwrap()
        .then((data) => {
          if (data && data.user.login_cnt === 0) {
            navigate(ref ?? "");
            // navigate()
            // navigate(`${routeConfig.change_pwd()}?ref=${ref}`);
          }
        })
        .catch(() => {
          console.error("Auto-login failed.");
        }).finally(() => setLoading(false));
      // googleLoginFunc(credentialResponse.credential)
      // login
    },
    onError: () => {
      setLoading(false);
      console.log("Login Failed");
    },
    flow: "auth-code",
    ux_mode: "popup",
  });

  return (
    <section className="p-md-3 p-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
            <div className="card border-0 custom-shadow-md rounded-4">
              <div className="card-body p-3 p-md-4 p-xl-5">
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw-bold">{isLoggedIn ? "Log in 2" : "Log in"}</h2>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="pt-3">
                  <div className="row gy-3 overflow-hidden">
                    <div className="col-12">
                      <div className="form-floating mb-3">
                        <input
                          type={!isLoggedIn ? "email" : "text"}
                          id="username"
                          name="username"
                          maxLength={50}
                          placeholder={!isLoggedIn ? "Your Email Address" : "Your Username"}
                          className={`form-control ${formik.errors.username && formik.touched.username ? 'error-input' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.username}
                          ref={emailRef} // Set ref for email input
                        />
                        <label htmlFor="username" className="form-label">
                          {!isLoggedIn ? "Email" : "Username"}
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          maxLength={60}
                          placeholder="Your Password"
                          className={`form-control ${formik.errors.password && formik.touched.password ? 'error-input' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <label htmlFor="password" className="form-label">Password</label>
                      </div>
                    </div>

                    {!isLoggedIn && (
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="RemMe"
                            name="RemMe"
                            checked={formik.values.RemMe}
                            onChange={formik.handleChange}
                          />
                          <label className="form-check-label text-secondary" htmlFor="RemMe">
                            Keep me logged in
                          </label>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="d-grid">
                        <button className="btn btn-primary" type="submit" disabled={loading}>
                          {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
                          {!isLoggedIn ? "Log in now" : "Log in 2"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-12">
                    <hr className=" border-secondary-subtle" />
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`${routeConfig.registration()}?ref=${ref}`}
                        className="link-secondary text-decoration-none"
                      >
                        Create new account
                      </Link>

                      <Link
                        to={`${routeConfig.forgot_password()}?ref=${ref}`}
                        className="link-secondary text-decoration-none"
                      >
                        Forgot password
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    {!isLoggedIn &&
                      <p className="mt-2 mb-4 text-center">Or continue with</p>
                    }

                    <div className="d-flex gap-3 flex-column">
                      {/* Google Login Button */}
                      {!isLoggedIn &&
                        <a href="#!" className="btn d-flex align-items-center justify-content-center py-2 order border-primary-subtle" onClick={(e) => {
                          e.preventDefault();
                          loginGoogle(); // Call login function
                        }}
                          aria-disabled={loading}
                          style={{ pointerEvents: loading ? "none" : "auto", opacity: loading ? 0.6 : 1 }}
                        >
                          {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 48 48"
                            className="google-logo"
                          >
                            <path
                              fill="#4285F4"
                              d="M24 9.5c3.6 0 6.5 1.2 9 3.4l6.7-6.7C34.8 2.2 29.7 0 24 0 14.8 0 6.8 5.4 2.7 13.4l7.8 6c2-5.6 7.2-9.9 13.5-9.9z"
                            />
                            <path
                              fill="#34A853"
                              d="M46.1 24.5c0-1.6-.2-3.2-.6-4.7H24v9.3h12.5c-.8 4-3.5 7.4-7.1 9.3l7.8 6c6-5.5 9.9-13.6 9.9-22.1z"
                            />
                            <path
                              fill="#FBBC05"
                              d="M10.5 28.3c-.5-1.6-.7-3.3-.7-5.1s.2-3.5.7-5.1l-7.8-6C.9 15.1 0 19.4 0 23.7s.9 8.6 2.7 12.6l7.8-6z"
                            />
                            <path
                              fill="#EA4335"
                              d="M24 48c6.5 0 11.9-2.1 15.9-5.8l-7.8-6c-2.1 1.4-4.8 2.2-8.1 2.2-6.3 0-11.5-4.2-13.5-9.9l-7.8 6C6.8 42.6 14.8 48 24 48z"
                            />
                          </svg>
                          <span className="ms-2 fs-6 text-uppercase">Sign in with Google</span>
                        </a>
                      }
                      {/* Close Button */}
                      <Link
                        to="#"
                        className="btn btn-outline-secondary d-flex align-items-center justify-content-center px-3 py-2 text-nowrap"
                        onClick={btnClick}
                        style={{ border: "1px solid #ccc" }}
                      >
                        <i className="fa fa-times fs-5"></i>
                        <span className="ms-2">Close</span>
                      </Link>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
