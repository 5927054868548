import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const AlertActionMasterForm = ({ result, handleGoBack, loading, initialValues }) => {

    const { values, setFieldValue } = useFormikContext();

    return (
        <>
            <Form id='myForm'>
                {
                    result !== undefined && result.alertdb !== undefined && result.alertdb.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4" tabIndex={1}>
                            {result.alertdb[0].id}
                        </div>
                    </div>}

                <div className="mb-3 row">
                    <label htmlFor="name" className="col-lg-2">Name</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-control" type="text" maxLength="100" name="name" tabIndex={5} />
                    </div>

                    <label htmlFor="condition_cd" className="col-lg-2">Cron Run</label>
                    <div className="col-lg-4">
                        <Field as="select" name="condition_cd" className="form-select" tabIndex={10}>
                            <option></option>
                            {result.monitoringConditionList !== undefined && result.monitoringConditionList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                        <p className="text-danger mb-0" id="cronNotify">On weekly and monthly Run on Holidays by default yes</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="sql_query" className="col-lg-2">SQL Query</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="5" name="sql_query" tabIndex={15} />
                        <p className="text-danger mb-0">Use [[CurrentIST]] for current timestamps</p>
                    </div>
                </div>
                <hr />
                <div className="mb-3 row">
                    <label htmlFor="alert_title" className="col-lg-2">Alert Title</label>
                    <div className="col-lg-10">
                        <Field as="input" type='text' className="form-control" name="alert_title" tabIndex={20} maxLength="100" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="alert_url" className="col-lg-2">Alert URL</label>
                    <div className="col-lg-10">
                        <Field as="input" className="form-control" type="text" maxLength="100" name="alert_url" tabIndex={25}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="alert_desc" className="col-lg-2">Alert Description</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="3" maxLength="200" name="alert_desc" tabIndex={30} />
                    </div>
                </div>
                <hr />
                <div className="mb-3 row">
                    <label htmlFor="is_empty_fields" className="col-lg-2">Is Not Empty Fields</label>
                    <div className="col-lg-10">
                        <Field as="input" className="form-control" type="text" maxLength="250"
                            name="is_empty_fields" tabIndex={35} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="sending_time" className="col-lg-2">Sending Time</label>
                    <div className="col-lg-2">
                        <Field as="input" type="time" className="form-control" id="sending_time" name="sending_time" tabIndex={40}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="start_time" className="col-lg-2">Start Time</label>
                    <div className="col-lg-2">
                        <Field as="input" type="time" className="form-control" id="start_time" name="start_time" tabIndex={45}
                        />
                    </div>
                    <div className="col-lg-2"></div>
                    <label htmlFor="end_time" className="col-lg-2">End Time</label>
                    <div className="col-lg-2">
                        <Field as="input" type="time" className="form-control" id="end_time" name="end_time" tabIndex={50}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="mobile_alert" className="col-lg-2">Mobile Alert</label>
                    <div className="col-lg-1">
                        <Field as="input" className="form-check-input" type="checkbox" tabIndex={55}
                            name="mobile_alert" onChange={(e) => { setFieldValue('mobile_alert', e.target.checked) }} />
                    </div>
                    <label htmlFor="homepage_alert" className="col-lg-2">HomePage Alert</label>
                    <div className="col-lg-1">
                        <Field as="input" className="form-check-input" type="checkbox" tabIndex={60}
                            name="homepage_alert" onChange={(e) => { setFieldValue('homepage_alert', e.target.checked) }} />
                    </div>
                    <label htmlFor="highlight_status" className="col-lg-2">Highlight Status</label>
                    <div className="col-lg-1">
                        <Field as="input" className="form-check-input" type="checkbox" tabIndex={65}
                            name="highlight_status" onChange={(e) => { setFieldValue('highlight_status', e.target.checked) }} />
                    </div>
                    <label htmlFor="sticky_status" className="col-lg-2">Sticky Status</label>
                    <div className="col-lg-1">
                        <Field as="input" className="form-check-input" type="checkbox" tabIndex={70}
                            name="sticky_status" onChange={(e) => { setFieldValue('sticky_status', e.target.checked) }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="run_on_holiday" className="col-lg-2">Run on Holidays</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox"
                            name="run_on_holiday" tabIndex={75}
                            onChange={(e) => { setFieldValue('run_on_holiday', e.target.checked) }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field as="input" className="form-check-input" type="checkbox" tabIndex={80}
                            name="display_status" onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-2">
                        <Field as="input" className="form-check-input" type="checkbox" name="lock_status" tabIndex={85}
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
                    </div>
                </div>

                <hr />
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={90}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.alertdb !== undefined && result.alertdb.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={95}
                    >Cancel</button>
                </div>

                <ValidateFormFields />
            </Form>
        </>
    )
}

export default AlertActionMasterForm;
