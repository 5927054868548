import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoPeerGroupAnalysisForm from './IpoPeerGroupForm';
import { FieldArray, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funTrimNestedStrings } from '../Common/CommonFunction';
import IpoTabs from './IPONewPageTabs';

const IpoPeerGroupAnalysis = () => {

    const validationSchema = Yup.object({
        peer_group_date: Yup.date().required('Please select the date.'),
    });

    const validate = (e) => {
        for (let i = 0; i < e.peerGroupList.length; i++) {
            if (e.peerGroupList[i].company_name == "" || e.peerGroupList[i].company_name == null) {
                alert(`Company name ${i + 1} cannot be blank`);
                return false;
            }
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        ipo_confirmation_status: false,
        peer_group_disclaimer: "",
        pe_ratio: "",
        kpi_roe: "",
        kpi_roce: "",
        kpi_debt_equity: "",
        kpi_eps: "",
        kpi_ronw: "",
        peer_group_date: "",
        issue_price: "",
        peerGroupList: [
            {
                id: "", ipo_id: "", company_id: "", company_name: "", pe_ratio: "", eps: "", eps_diluted: ""
                , ronw: "", closing_price_period: "", p_bv_ratio: "", nav: "", display_status: false, peer_group_financial_stmt: ""
            },
        ],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/822");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": " Edit Peer Group Analysis" })

            api.post('/admin-ipopeergroup-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    console.log("The result is: ", response.data);
                    setResult(response.data);

                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.id,
                        company_name: response.data.ipoPeerGroup[0].company_name,
                        lock_status: response.data.ipoPeerGroup[0].lock_status,
                        peer_group_disclaimer: response.data.ipoPeerGroup[0].peer_group_disclaimer,
                        pe_ratio: response.data.ipoPeerGroup[0].pe_ratio,
                        kpi_roe: response.data.ipoPeerGroup[0].kpi_roe,
                        kpi_roce: response.data.ipoPeerGroup[0].kpi_roce,
                        kpi_debt_equity: response.data.ipoPeerGroup[0].kpi_debt_equity,
                        kpi_eps: response.data.ipoPeerGroup[0].kpi_eps,
                        kpi_ronw: response.data.ipoPeerGroup[0].kpi_ronw,
                        peer_group_date: response.data.ipoPeerGroup[0].peer_group_date,
                        issue_price: response.data.ipoPeerGroup[0].issue_price,
                        peerGroupList: response.data.peerGroupList
                    }));
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true"
        }
    }, []);


    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);
        if (validate(trimmedValues)) {
            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());

            api.post('/admin-ipopeergroup-save', {
                values: trimmedValues
            }
            ).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }
        setSubmitting(false);
    };

    return (
        <>
            <IpoTabs idAtr={id} ipo_confirm={result.ipoPeerGroup?.[0]?.ipo_confirmation_status} />
            <br></br>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.ipoPeerGroup !== undefined &&
                result.ipoPeerGroup.length > 0 &&
                result.ipoPeerGroup[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            {result !== undefined && result.id !== undefined && result.id.length > 0 &&
                <>
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {id}
                        </div>
                    </div>
                </>
            }

            {result !== undefined && result.ipoPeerGroup !== undefined && result.ipoPeerGroup.length > 0 &&
                <>
                    <div className="my-3 row">
                        <label htmlFor="" className="col-2">Issuer Name:</label>
                        <div className="col-8" tabIndex={5}>
                            <b>{result.ipoPeerGroup[0].company_name}</b>
                        </div>
                    </div>
                </>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >

                {({ values, setFieldValue }) => (
                    <Form id='myForm'>
                        <div className="row mb-3">
                            <label htmlFor="peer_group_date" className="col-lg-2">
                                Date (Period ended)
                            </label>
                            <div className="col-lg-2">
                                <Field
                                    className="form-control dateInput"
                                    type="date"
                                    name="peer_group_date"
                                    id="txtPeer_Group_Date"
                                    title="YYYY/MM/DD"
                                    tabIndex={10}
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value)
                                        if (new Date(e.target.value) > new Date()) {
                                            alert("Period ended date should not be post the present day date.")
                                            setFieldValue(e.target.name, "")
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <table className="table table-bordered table-striped" id="repeater1" tabIndex={15}>
                            <thead>
                                <tr style={{ backgroundColor: 'grey' }}>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "20%" }}>Company Name</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "15%" }} colSpan="2">EPS (Rs)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "8%" }}>NAV (per share) (Rs)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "8%" }}>P/E (x)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "8%" }}>RoNW (%)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "7%" }}>Closing Price As On Period Ended</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "7%" }}>P/BV Ratio</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "11%" }}>Financial Statement</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "8%" }}>Display Status</th>
                                    <th style={{ color: "white", backgroundColor: 'grey', width: "8%" }}>Action</th>
                                </tr>
                                <tr>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'green' }}>Basic</th>
                                    <th style={{ color: "white", backgroundColor: 'green' }}>Diluted (Rs)</th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                    <th style={{ color: "white", backgroundColor: 'grey' }}></th>
                                </tr>
                            </thead>


                            <FieldArray
                                name="peerGroupList"
                                render={({ push, remove }) => (
                                    <>
                                        <tbody>
                                            <IpoPeerGroupAnalysisForm remove={remove} result={result} />
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="9" className="text-end">
                                                    <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={() => push({
                                                        id: "", ipo_id: "", company_id: "", company_name: "", pe_ratio: "", eps: "", eps_diluted: ""
                                                        , ronw: "", closing_price_period: "", p_bv_ratio: "", nav: "", display_status: false, peer_group_financial_stmt: ""
                                                    })}>
                                                        Add +
                                                    </button>
                                                </td></tr>
                                        </tfoot>
                                    </>
                                )}
                            />
                        </table>

                        <p><b>Note</b> : Closing price is as per the NSE/BSE wesbite as of the date for the figures are displayed.</p>

                        <br></br>

                        <div className="my-3 row">
                            <label htmlFor="" className="col-2">Disclaimer (HTML)</label>
                            <div className="col-8">
                                <Field className="form-control" rows="5" as="textarea" tabIndex={20}
                                    name="peer_group_disclaimer"></Field>
                            </div>
                        </div>

                        <div className="mb-3 text-center">


                            {initialValues.lock_status !== 1 && (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={25}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        Update
                                    </span>
                                </button>
                            )}

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={30}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default IpoPeerGroupAnalysis;
