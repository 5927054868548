import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoDateForm from './IpoDateForm'
import { Formik } from 'formik';
import * as Yup from 'yup';
import IpoTabs from './IPONewPageTabs';
import { convertNullToBlank } from '../Common/CommonFunction';

const validationSchema = Yup.object({

});

const IpoDate = () => {

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        lock_status: false,
        ipo_confirmation_status: false,
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current === "true") {
            dispatch(startLoading());

            api.post('/admin-ipodate-read', { id: id }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        let ipoData = response.data.ipoDetail[0];

                        setInitialValues({
                            ...ipoData,
                        });

                        setMetaData({ "title": "Edit Forthcoming IPO Dates" })

                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                dispatch(finishLoading());
            });

        } else {
            didMountRef.current = "true";
        }
    }, []);
    function isWeekday(date) {
        const givenDate = new Date(date);
        const dayOfWeek = givenDate.getDay();
        return dayOfWeek !== 0 && dayOfWeek !== 6;
    }
    const validate = (e) => {
        e = convertNullToBlank(e);
        let closeDate = new Date(e.issue_close_date)
        if (e.issue_open_date != "" && e.issue_close_date != "") {
            if (!isWeekday(e.issue_open_date)) {
                alert("Issue Open date can't be weekened day.");
                return false;
            }
            if (!isWeekday(e.issue_close_date)) {
                alert("Issue Close date can't be weekened day.");
                return false;
            }
            let openDate = new Date(e.issue_open_date)
            if (openDate >= closeDate) {
                alert("Issue close date should be greater then issue open date.");
                return false;
            }
            var nextDay = openDate;
            nextDay.setDate(nextDay.getDate() + 1);
            var formattedDate = nextDay.getFullYear() + "-" + String(nextDay.getMonth() + 1).padStart(2, '0') + "-" + String(nextDay.getDate()).padStart(2, '0');
            if (formattedDate == e.issue_close_date) {
                alert("Issue open date and issue close date difference must be greater than one day");
                return false;
            }
        }
        if (e.timetable_boa_dt !== "") {
            let boaDate = new Date(e.timetable_boa_dt)
            if (boaDate <= closeDate) {
                alert("BOA date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_refunds_dt !== "") {
            let refundDate = new Date(e.timetable_refunds_dt)
            if (refundDate <= closeDate) {
                alert("Refund date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_share_credit_dt !== "") {
            let shareCreditDate = new Date(e.timetable_share_credit_dt)
            if (shareCreditDate <= closeDate) {
                alert("Credit of Equity Shares date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_listing_dt !== "") {
            let listingDate = new Date(e.timetable_listing_dt)
            if (listingDate <= closeDate) {
                alert("Listing date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_anchor_lockin_end_dt_1 !== "") {
            let lockinDateOne = new Date(e.timetable_anchor_lockin_end_dt_1)
            if (lockinDateOne <= closeDate) {
                alert("Anchor lock-in period end date (30 Days) should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_anchor_lockin_end_dt_2 !== "") {
            let lockinDateTwo = new Date(e.timetable_anchor_lockin_end_dt_2)
            if (lockinDateTwo <= closeDate) {
                alert("Anchor lock-in period end date (90 Days) should be greater than issue close date.");
                return false;
            }
        }
        return true;
    }

    const handleSubmit = (values, { setSubmitting }) => {

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});


        if (validate(trimmedValues)) {
            dispatch(startLoading());

            api.post('/admin-ipodate-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again
        }

        setSubmitting(false);
    };

    return (
        <>
            {
                isAddMode ? "" :
                    <IpoTabs idAtr={result.id} ipo_confirm={result?.ipoDetail && result?.ipoDetail[0]?.ipo_confirmation_status} />
            }

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoDateForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} />
            </Formik>
        </>
    )
}

export default IpoDate;
