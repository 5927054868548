import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../../state/slices/auth";
import { Link, useNavigate } from 'react-router-dom';
import { Toast, ToastContainer, Navbar, NavDropdown, Container, Nav, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useApi from '../../../common/useApi';
import { setMessage } from '../../../state/slices/message';
import authService from '../../../services/auth.service';
import routeConfig from '../../../routeConfig';
import { handlePopupWin } from './CommonFunction';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import Cookies from "js-cookie";
import CronjobsStatus from "./CronjobsStatus";

const Header = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [result, setResult] = useState('');
    const [query, setQuery] = useState('');
    const [cloudflareMsg, setCloudflareMsg] = useState('');
    const [options, setOptions] = useState([]);
    const debounceTime = 300;
    const api = useApi();
    const [showCFModal, setShowCFModal] = useState(false);
    const [showCronModal, setShowCronModal] = useState(false);
    const loading = useSelector(selectLoading);

    const [files, setFiles] = useState('');
    const navigate = useNavigate();
    // const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        const notifyTokenExpiry = () => {
            let { expiringSoon,
                minutesRemaining,
                secondsRemaining,
                tokenExpiryTime,
                currentTime,
                timeUntilExpiry,
                thresholdInMs
            } = authService.isTokenExpiringSoon()
            setResult((prevState) => ({
                ...prevState,
                expiringSoon: expiringSoon,
                minutesRemaining: minutesRemaining,
                secondsRemaining: secondsRemaining,
                tokenExpiryTime: tokenExpiryTime,
                currentTime: currentTime,
                timeUntilExpiry: timeUntilExpiry,
                thresholdInMs: thresholdInMs,
            }));
            // if (minutesRemaining <= 5 && secondsRemaining == 0) {
            //     setShowToast(true);
            // }
            if (minutesRemaining <= 0 && secondsRemaining <= 0) {
                dispatch(logout());
            }
        };

        notifyTokenExpiry();

        const intervalId = setInterval(notifyTokenExpiry, 1 * 1000); // Check every minute

        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        if (query != "") {
            const fetchData = async () => {
                api.post('/admin-member-search', {
                    query: query
                }).then(response => {
                    if (response.data.msg === 1) {
                        setOptions(response.data.queryData); // Set the fetched data as options
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            };

            const timeoutId = setTimeout(() => {
                if (query.trim() !== '') {
                    fetchData();
                }
            }, debounceTime);

            return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or re-render
        }
    }, [query]);

    const handleInputChange = (selected) => {
        if (selected[0].id > 0) {
            navigate("/admintool9x/member/" + selected[0].id + "/")
        }
    };

    const handleCFClose = () => setShowCFModal(false);
    const handleCFShow = (e) => {
        e.preventDefault();
        setShowCFModal(true)
    };

    const handleCronClose = () => setShowCronModal(false);
    const handleCronShow = (e) => {
        e.preventDefault();
        setShowCronModal(true)
    };

    const handleCFSubmit = (e) => {
        e.preventDefault();
        if (files != "") {
            dispatch(startLoading());
            api.post('/admin-cloudflare-file-purge', {
                urls: files,
            }).then(response => {
                dispatch(finishLoading());
                if (response.data.msg === 1) {
                    setCloudflareMsg(response.data.success)
                } else {
                    setCloudflareMsg(response.data.error)
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        } else {
            alert("Enter Urls");
        }

    };
    const handleRedisSubmit = (e) => {
        e.preventDefault();
        if (files != "") {
            dispatch(startLoading());
            api.post('/admin-radis-purge', {
                urls: files,
            }).then(response => {
                dispatch(finishLoading());
                if (response.data.msg === 1) {
                    alert(response.data.success);
                } else {
                    alert(response.data.error);
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
        } else {
            alert("Enter Urls");
        }

    };

    const logoutFunction = (e) => {
        e.preventDefault();
        Cookies.remove('rememberMeEmail');
        Cookies.remove('rememberMePassword');
        dispatch(logout())
    };
    return (
        <>
            {currentUser.role_id === 3 ? (<>
                <Navbar expand="lg" bg="dark" data-bs-theme="dark" className='mb-3' style={{ backgroundColor: "#636363" }} >
                    <Container fluid>

                        {/* {window.history.state.id > 0 && */}
                        <Button variant="dark" className='p-0' onClick={() => window.history.back()}>
                            <img className="w-50 h-50" src="/images/arrow-back.png" alt="Back" />
                        </Button>
                        {/* } */}

                        <Navbar.Brand as={Link} to="/admintool9x"><img className="ms-4 w-50 h-50" src="/images/home_l.png" alt="Home" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarSupportedContent" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavDropdown title="Errors" id="navbarDropdown10">
                                    <NavDropdown.Item as={Link} to="report/162/8">All Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/9">Critical Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/10">Info Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/11">Warning Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/12">Dilip Davada Message</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Messages" id="navbarDropdown1">
                                    <NavDropdown.Item as={Link} to="report/1">Alerts</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="spamfeedback">SPAM Message - Review</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="spamrecommendation">SPAM Message - Recommendation</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/133">IPO Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/146">IPO Recommendation</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61">Feedback - All</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/152">Leads SME IPO Manager</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/139">Contact Us</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/26">Asked Questions</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="IPO" id="navbarDropdown3" className="custom-dropdown">

                                    <Row className="d-lg-flex flex-lg-row flex-column gap-0 mx-0">
                                        <Col lg={3} className="d-flex flex-column ps-2 pe-0">
                                            <NavDropdown.Item disabled className="disabled-link">Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/22">Mainboard</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/23">SME</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/24">Upcoming</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/21">All</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column px-0">
                                            <NavDropdown.Item disabled className="disabled-link">Manual Work</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/82">IPO Detail</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/82/20">Upcoming IPO Detail</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/95">IPO Reviews (A)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/84">IPO Listing Info</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/185">IPO Allotment</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/81">Basis of Allotment</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column px-0">
                                            <NavDropdown.Item disabled className="disabled-link">Config</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/160">IPO Managers</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/190">IPO Anchors Master</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/8">Reports Manager</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column pe-2 ps-0">
                                            <NavDropdown.Item disabled className="disabled-link">Automated Work</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/6">IPO Bidding</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/1">IPO Calendar (Mainline)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/7">IPO Calendar (SME)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/83">Listing Day Trade</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/77">IPO Star Rating</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/168">IPO SME to Mainboard</NavDropdown.Item>
                                        </Col>
                                    </Row>

                                </NavDropdown>


                                <NavDropdown title="NCD" id="navbarDropdown4">
                                    <NavDropdown.Item as={Link} to="report/5">NCD Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/90">NCD Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/74">NCD Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/2">NCD Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/5">NCD Bidding</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/3">NCD Listing Info</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/4">NCD Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/2">NCD Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/7">Reports Manager</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="RI" id="navbarDropdown5">
                                    <NavDropdown.Item as={Link} to="report/128">RI Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/127">RI Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/129">RI Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/147">RI Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/3">RI Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/3">RI Feedback</NavDropdown.Item>
                                </NavDropdown>


                                <NavDropdown title="BB" id="navbarDropdown6">
                                    <NavDropdown.Item as={Link} to="report/136">BuyBack Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/132">BuyBack Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/135">Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/153">Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/4">Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/4">BB Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/164">BB History</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/8">BB Bidding</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="OFS" id="navbarDropdown6">
                                    <NavDropdown.Item as={Link} to="report/188">OFS Detail</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Reports" id="navbarDropdown7">
                                    <NavDropdown.Item as={Link} to="report/57">Admin Reports</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/58">Web Reports</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/142">Calendar Admin Reports</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/143">Calendar Web Reports</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/159">Monitoring Master</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/161">Alert Master</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/163">Compare Master</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/165">CronJobs Master</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/189">Cache Master</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Misc" id="navbarDropdown8">
                                    <NavDropdown.Item as={Link} to="report/149">E-Book </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/2">FAQ for Pages</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/114">Glossary</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/36">Article</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/115">Options Strategy</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/117">Trading Platform</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/73">List of scripts</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/19">Broker Details</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/42">Broker Complaints</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/166">Broker Brokerage Plans</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/167">Broker Common Charges</NavDropdown.Item>
                                    {/* <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/73">Stock List</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/100">Bhav Copy (NSE)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/99">Bhav Copy (BSE)</NavDropdown.Item>
                                    <NavDropdown.Divider /> */}

                                    <NavDropdown.Item as={Link} to="report/72">FAQ's Manager</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    {/* <NavDropdown.Item as={Link} to="report">IPO Managers (Old)</NavDropdown.Item>
                                    <NavDropdown.Divider /> */}
                                    <NavDropdown.Item as={Link} to="report/27">Members Manager</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="report/59">Members (By Month)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/187">Members Logs</NavDropdown.Item> */}
                                    <NavDropdown.Divider />

                                </NavDropdown>
                            </Nav>
                            <Form className="d-flex align-items-center justify-content-center">
                                {/* <a href='#' className='text-light me-2 text-decoration-none'><b>
                                    {result.minutesRemaining} : {result.secondsRemaining} | V : {process.env.REACT_APP_VERSION}
                                </b></a> */}
                                <Form.Group controlId="autocompleteTextbox">
                                    <Typeahead
                                        id="autocompleteTextbox"
                                        labelKey="name"
                                        options={options}
                                        onChange={handleInputChange}
                                        placeholder="Search a member..."
                                        onInputChange={(text) => setQuery(text)}
                                        renderInput={(inputProps, { instance }) => (
                                            <div className="d-flex form-inputs">
                                                <i className="fa fa-search text-light"></i>
                                                <input {...inputProps} className="form-control" />
                                            </div>
                                        )}
                                    />
                                </Form.Group>

                                <a href="/admintool9x/fileupload" onClick={(e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    handlePopupWin('/admintool9x/fileupload');
                                }} title="Upload Images/Docs"><img src="/images/upload1.png" width="25" height="25" style={{ marginLeft: "15px" }} title="Upload Images/Docs" /></a> &nbsp;
                                <Link to="/admintool9x/todo" title="To Do List"><img src="/images/notepad1.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="To Do List" /></Link>&nbsp;
                                <a href='#' onClick={handleCFShow} data-bs-toggle="modal" role="button" title="Clear CloudFlare Cache"><img src="/images/clear_cache.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="Clear Cache" /></a>&nbsp;
                                <a href='#' onClick={handleCronShow} data-bs-toggle="modal" role="button" title="Show Cronjobs">
                                    <img src="/images/cron.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="Show Cronjobs" /></a>&nbsp;
                                <a href='#' onClick={(e) => logoutFunction(e)} title="Logout"><img src="/images/logout_icon.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="Logout" title="Logout" /></a>&nbsp;

                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>) : (<>
                <Navbar sticky="top" expand="lg" bg="success" data-bs-theme="success" className='mb-3' style={{ color: 'white' }} >
                    <Container fluid>
                        <Button variant="dark" className='p-0' onClick={() => window.history.back()}>
                            <img className="w-50 h-50" src="/images/arrow-back.png" alt="Back" />
                        </Button>
                        <Navbar.Brand as={Link} to="/admintool9x"><img className="ms-4 w-50 h-50" src="/images/home_l.png" alt="Home" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarSupportedContent" />
                        <Navbar.Collapse id="basic-navbar-nav-editor">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="report/1">Alerts</Nav.Link>

                                <NavDropdown title="Errors" id="navbarDropdown10">
                                    <NavDropdown.Item as={Link} to="report/162/8">All Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/9">Critical Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/10">Info Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/11">Warning Errors</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/162/12">Dilip Davada Message</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Messages" id="navbarDropdown1">
                                    <NavDropdown.Item as={Link} to="report/1">Alerts</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="spamfeedback">SPAM Message - Review</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="spamrecommendation">SPAM Message - Recommendation</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/133">IPO Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/146">IPO Recommendation</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61">Feedback - All</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="report/134">Grey Market</NavDropdown.Item> */}
                                    <NavDropdown.Item as={Link} to="report/26">Asked Questions</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="IPO" id="navbarDropdown3" className="custom-dropdown">
                                    <Row className="d-lg-flex flex-lg-row flex-column gap-0 mx-0">
                                        <Col lg={3} className="d-flex flex-column ps-2 pe-0">
                                            <NavDropdown.Item disabled className="disabled-link">Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/22">Mainboard</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/23">SME</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/24">Upcoming</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/109/21">All</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column px-0">
                                            <NavDropdown.Item disabled className="disabled-link">Manual Work</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/82">IPO Detail</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/82/20">Upcoming IPO Detail</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/95">IPO Reviews (A)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/84">IPO Listing Info</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/185">IPO Allotment</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/81">Basis of Allotment</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column px-0">
                                            <NavDropdown.Item disabled className="disabled-link">Config</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/160">IPO Managers</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/190">IPO Anchors Master</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/8">Reports Manager</NavDropdown.Item>
                                        </Col>

                                        <Col lg={3} className="d-flex flex-column pe-2 ps-0">
                                            <NavDropdown.Item disabled className="disabled-link">Automated Work</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/6">IPO Bidding</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/1">IPO Calendar (Mainline)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report-calendar/7">IPO Calendar (SME)</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/83">Listing Day Trade</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/77">IPO Star Rating</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="report/168">IPO SME to Mainboard</NavDropdown.Item>
                                        </Col>
                                    </Row>

                                </NavDropdown>

                                <NavDropdown title="NCD" id="navbarDropdown4">
                                    <NavDropdown.Item as={Link} to="report/5">NCD Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/90">NCD Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/74">NCD Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/2">NCD Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/5">NCD Bidding</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/3">NCD Listing Info</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/4">NCD Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/2">NCD Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/7">Reports Manager</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="RI" id="navbarDropdown5">
                                    <NavDropdown.Item as={Link} to="report/128">RI Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/127">RI Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/129">RI Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/147">RI Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/3">RI Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/3">RI Feedback</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="BB" id="navbarDropdown6">
                                    <NavDropdown.Item as={Link} to="report/136">BuyBack Dashboard</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/132">BuyBack Detail</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/135">Reviews (Admin)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/153">Basis of Allotment</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/4">Calendar</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/61/4">BB Feedback</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/164">BB History</NavDropdown.Item>
                                    <NavDropdown.Item>Automated Work</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report-calendar/8">BB Bidding</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="OFS" id="navbarDropdown6">
                                    <NavDropdown.Item as={Link} to="report/188">OFS Detail</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Misc" id="navbarDropdown8">
                                    <NavDropdown.Item as={Link} to="report/149">E-Book</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/2">FAQ for Pages</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/114">Glossary</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/36">Article</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/115">Options Strategy</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/117">Trading Platform</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/73">List of scripts</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="report/19">Broker Details</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/166">Broker Brokerage Plans</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/167">Broker Common Charges</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    {/* <NavDropdown.Item as={Link} to="report/73">Stock List</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/100">Bhav Copy (NSE)</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="report/99">Bhav Copy (BSE)</NavDropdown.Item>
                                    <NavDropdown.Divider /> */}

                                    <NavDropdown.Item as={Link} to="report/72">FAQ's Manager</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Divider />

                                </NavDropdown>
                            </Nav>

                            <Form className="d-flex align-items-center justify-content-center">
                                {/* <a href='#' className='text-light me-2 text-decoration-none'><b>{result.minutesRemaining} : {result.secondsRemaining} | V : {process.env.REACT_APP_VERSION}</b></a> */}

                                <a href="/admintool9x/fileupload" onClick={(e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    handlePopupWin('/admintool9x/fileupload');
                                }} title="Upload Images/Docs"><img src="/images/upload1.png" width="25" height="25" style={{ marginLeft: "15px" }} title="Upload Images/Docs" /></a> &nbsp;

                                <a href='#' onClick={handleCFShow} data-bs-toggle="modal" role="button" title="Clear CloudFlare Cache"><img src="/images/clear_cache.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="Clear Cache" /></a>&nbsp;
                                <a href='#' onClick={(e) => logoutFunction(e)} title="Logout"><img src="/images/logout_icon.png" width="25" height="25" style={{ marginLeft: "15px" }} alt="Logout" title="Logout" /></a>&nbsp;

                            </Form>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>)}

            <Modal show={showCronModal} onHide={handleCronClose} size="xl" dialogClassName="modal-90w" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Cron Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CronjobsStatus handleCronClose={handleCronClose} />
                </Modal.Body>
            </Modal>

            <Modal show={showCFModal} onHide={handleCFClose} backdrop="static" size="xl" dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>CloudFlare File Purge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleCFSubmit}>
                        <Form.Group controlId="files">
                            <Form.Label>Separate tags(s) with commas, or list one per line</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={files}
                                onChange={(e) => setFiles(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCFClose}>
                        Close
                    </Button>

                    {currentUser.role_id === 3 && (
                        <Button variant="primary" onClick={handleRedisSubmit} disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            Clean Redis
                        </Button>
                    )}

                    <Button variant="primary" onClick={handleCFSubmit} disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Purge Page Cache
                    </Button>
                </Modal.Footer>

                <Modal.Body className='py-0'>
                    <p dangerouslySetInnerHTML={{ __html: cloudflareMsg }} />
                </Modal.Body>
            </Modal>

            {/* <ToastContainer position="bottom-end" className="p-3">
                <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={5000}
                    autohide
                    className="bg-danger text-white"
                >
                    <Toast.Header>
                        <strong className="me-auto">Session Expiry Warning</strong>
                    </Toast.Header>
                    <Toast.Body>Your session will expire soon. Please renew your session.
                        <div className="mt-2">

                            <p>expiringSoon : {result.expiringSoon}</p>
                            <p>minutesRemaining : {result.minutesRemaining}</p>
                            <p>secondsRemaining : {result.secondsRemaining}</p>

                            <p>tokenExpiryTime : {result.tokenExpiryTime}</p>
                            <p>currentTime : {result.currentTime}</p>
                            <p>timeUntilExpiry : {result.timeUntilExpiry}</p>
                            <p>thresholdInMs : {result.thresholdInMs}</p>
                        </div>
                    </Toast.Body>
                </Toast>
            </ToastContainer> */}
        </>
    )
}

export default Header
