import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import SpamFeedbackTable from './SpamFeedbackTable';


const SpamFeedback = () => {

    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const [updateArray, setUpdateArray] = useState([]);

    const fnUpdateArray = () => {

        if (updateArray.join(',') === "") {
            alert("select at least one checkbox");
        } else {
            if (window.confirm("Are you sure")) {
                api.post('/admin-feedback-spam-update', {
                    values: {
                        id: updateArray.join(',')
                    }
                }).then(response => {
                    if (response.data.msg === 1) {
                        dispatch(setMessage(response.data.success));
                        setUpdateArray([]);
                        callSpamList();
                    } else {
                        dispatch(setMessage(response.data.error));
                    }
                }).catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
            }
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            setMetaData({ "title": "Spam Message- Review" })
            callSpamList();

        } else {
            didMountRef.current = "true";
        }
    }, []);
    const callSpamList = () => {
        api.post('/admin-feedback-spam-read', {
            id: id
        }).then(response => {
            if (response.data.msg === 1) {
                setResult(response.data);
            } else {
                dispatch(setMessage(response.data.error));
            }
            dispatch(finishLoading());
        }).catch(error => {
            console.error('Error:', error);
            dispatch(finishLoading());
        });
    }
    const handleCheckAll = (value) => {
        if (value) {
            const updatedArray = result.spamMessages.map(message => `${message.id}-${message.source}`);
            setUpdateArray(updatedArray);
        }
        else {
            setUpdateArray([])
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            <table className="table table-bordered table-striped" id="repeater1" tabIndex={25}>
                <thead>
                    <tr>
                        <td colSpan="10" className="text-end">
                            <button type="button" className='btn btn-primary btn-sm text-center mt-2 mb-2' onClick={fnUpdateArray}>Mark Spam (Disable)</button>
                        </td>
                    </tr>
                    <tr className="bg-secondary text-white">
                        <th style={{ width: "5%" }}>Id</th>
                        <th style={{ width: "8%" }}>Source</th>
                        <th style={{ width: "12%" }}>Topic</th>
                        <th style={{ width: "7%" }}>Spam?</th>
                        <th style={{ width: "8%" }}>Spam Reason</th>
                        <th style={{ width: "25%" }}>Message</th>
                        <th style={{ width: "10%" }}>Date</th>
                        <th style={{ width: "5%" }}>Member Id</th>
                        <th style={{ width: "7%" }}>Ip</th>
                        <th style={{ width: "10%" }}>
                            <a href="#" style={{ color: 'green' }} title="Check" onClick={() => { handleCheckAll(true) }}>Check</a>&nbsp; | &nbsp;
                            <a href="#" style={{ color: 'red' }} title="Uncheck." onClick={() => { handleCheckAll(false) }}>Uncheck</a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {result !== undefined && result.spamMessages !== undefined && (result.spamMessages.map((spam_message, index) => (
                        <SpamFeedbackTable key={index} spam_message={spam_message} updateArray={updateArray} setUpdateArray={setUpdateArray} callSpamList={callSpamList} />
                    )))}
                </tbody>
            </table>
        </>
    )
}

export default SpamFeedback;
