import React, { useEffect, useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import NumberInput from '../Common/NumberInput';
import ValidateFormFields from '../Common/ValidateFormFields';

const MonitoringMasterForm = ({ result, handleGoBack, initialValues, loading }) => {

    const { setFieldValue, values } = useFormikContext()
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        if (values.category_cd != 'greater_count' && values.category_cd != 'less_than_count' && values.category_cd != 'equal_to') setFieldValue("count_size", "");
        if (values.category_cd != 'select_column' && values.category_cd != 'json_insert') setFieldValue("columns_validate", "");
        if (values.category_cd != 'json_insert') {
            setFieldValue("json_table_name", "");
            setFieldValue("json_validate_column", "");
            setFieldValue("json_url", "");
            setFieldValue("json_table_name", "");
            setFieldValue("json_table_name", "");
            setFieldValue("json_min_day", "");
            setFieldValue("json_max_day", "");
        } 
        setSelectedCategory(values.category_cd);
    }, [values.category_cd]);
    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.monitoringMaster !== undefined && result.monitoringMaster.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlhtmlfor="id" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.monitoringMaster[0].id}
                        </div>
                    </div>
                }

                <div className="mb-3 row">
                    <label htmlFor="monitoring_name" className="col-lg-2" >Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type="text" maxLength="100" tabIndex={1} name="monitoring_name" />
                    </div>

                    <label htmlFor="condition_cd" className="col-lg-2">Cron Run</label>
                    <div className="col-lg-4">

                        <Field as="select" className="form-select" id="condition_cd" tabIndex={10} name="condition_cd">
                            <option>Select a condition</option>
                            {result.monitoringCondition !== undefined && result.monitoringCondition.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                        <p className="text-danger" id="cronNotify">On weekly and monthly Run on Holidays by default yes</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="monitoring_desc" className="col-lg-2">Monitoring Description (Admin Use)</label>
                    <div className="col-lg-10" >
                        <Field as="textarea" className="form-control" rows="3" tabIndex={15} name="monitoring_desc" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="category_cd" className="col-lg-2">Category</label>
                    <div className="col-lg-4">
                        <Field as="select" className="form-select" name="category_cd" tabIndex={20} >
                            <option>Select a category</option>
                            {result.monitoringCategory !== undefined && result.monitoringCategory.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>


                    </div>
                    <label htmlFor="notification_type" className="col-lg-2">Email Notification</label>
                    <div className="col-lg-4">

                        <Field as="select" className="form-select" id="notification_type" tabIndex={22} name="notification_type">
                            <option>Select a Notification Type</option>
                            {result.notificationType !== undefined && result.notificationType.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>

                    </div>
                </div>
                <div className={`mb-3 row ${selectedCategory === 'greater_count' || selectedCategory === 'less_than_count' || selectedCategory === 'equal_to' ? '' : 'd-none'}`} id="divcount_size">
                    <label htmlFor="count_size" className="col-lg-2">Count Size</label>
                    <div className="col-lg-4">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={25} maxLength="5" nameAtr="count_size" idAtr="bseScriptCode" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="sql_query" className="col-lg-2">SQL Query</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="5" tabIndex={35} name="sql_query" />
                        <p className="text-danger">Use [[CurrentIST]] for current timestamps</p>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="notification_message" className="col-lg-2">Notification Message</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="3" tabIndex={40} name="notification_message" />
                    </div>
                </div>
                <div className={`mb-3 row jsonColumns ${selectedCategory === 'json_insert' ? '' : 'd-none'}`}>
                    <label htmlFor="json_table_name" className="col-lg-2">Table Name</label>
                    <div className="col-lg-10">
                        <Field className="form-control" type="text" maxLength="50" tabIndex={45} name="json_table_name" />
                    </div>
                </div>
                <div className={`mb-3 row jsonColumns ${selectedCategory === 'json_insert' ? '' : 'd-none'}`}>
                    <label htmlFor="json_validate_column" className="col-lg-2">Validate/Unique Column</label>
                    <div className="col-lg-10">
                        <Field className="form-control" type="text" maxLength="50" tabIndex={50} name="json_validate_column" />
                    </div>
                </div>
                <div className={`mb-3 row jsonColumns ${selectedCategory === 'json_insert' ? '' : 'd-none'}`}>
                    <label htmlFor="json_url" className="col-lg-2">JSON URL</label>
                    <div className="col-lg-10">
                        <Field className="form-control" type="text" maxLength="500" tabIndex={55} name="json_url" />
                    </div>
                </div>
                <div className={`mb-3 row jsonColumns ${selectedCategory === 'json_insert' ? '' : 'd-none'}`}>
                    <label htmlFor="json_min_day" className="col-lg-2">Min Day</label>
                    <div className="col-lg-4">
                        <NumberInput classNameAtr="form-control" type="text" maxLength="3" tabIndex={55} nameAtr="json_min_day" />
                    </div>
                    <label htmlFor="json_max_day" className="col-lg-2">Max Day</label>
                    <div className="col-lg-4">
                        <NumberInput classNameAtr="form-control" type="text" maxLength="3" tabIndex={55} nameAtr="json_max_day" />
                    </div>
                </div>
                <div className={`mb-3 row ${selectedCategory === 'select_column' || selectedCategory === 'json_insert' ? '' : 'd-none'}`} id="divColumns">
                    <label htmlFor="columns_validate" className="col-lg-2">Columns To Validate in Sub Query</label>
                    <div className="col-lg-10">
                        <Field as="textarea" className="form-control" rows="2" maxLength="150"
                            tabIndex={60} name="columns_validate" />
                    </div>
                </div>
                <div className="mb-3 row" id="timeDiv">
                    <label htmlFor="start_time" className="col-lg-2">Start Time</label>
                    <div className="col-lg-4">
                        <Field type="time" className="form-control" id="start_time" tabIndex={65} name="start_time"
                        />
                    </div>
                    <label htmlFor="end_time" className="col-lg-2">End Time</label>
                    <div className="col-lg-4">
                        <Field type="time" className="form-control" id="end_time" tabIndex={70} name="end_time"
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="run_on_holiday" className="col-lg-2">Run on Holidays</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" tabIndex={106} name="run_on_holiday" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('run_on_holiday', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" type="checkbox" tabIndex={110} name="display_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-2">
                        <Field className="form-check-input" type="checkbox" tabIndex={115} name="lock_status" onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('lock_status', newValue);
                        }} />
                    </div>
                </div>
                <div className="mb-3 text-center">
                    {!initialValues.lock_status == 1 &&
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={90}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.monitoringMaster !== undefined && result.monitoringMaster.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    }

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={95}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default MonitoringMasterForm





