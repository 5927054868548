import { useFormikContext } from 'formik';

const MemberSameTable = ({ list }) => {

    const { values } = useFormikContext();

    return (
        <>

            <table className="table table-bordered table-striped" id="repeater1" tabIndex={25}>
                <thead>
                    <tr className="bg-secondary text-white">
                        <th style={{ width: "10%" }}>ID</th>
                        <th style={{ width: "30%" }}>Display Name</th>
                        <th style={{ width: "5%" }}>Status</th>
                        <th style={{ width: "25%" }}>Email</th>
                        <th style={{ width: "15%" }}>Date Added</th>
                        <th style={{ width: "15%" }}>Last Login</th>
                    </tr>
                </thead>

                {values[list].length > 0 ? (
                    <tbody>
                        {values[list].map((member, index) => (

                            <tr key={index}>
                                <td>
                                    <div><p>{member.id}</p></div>
                                </td>
                                <td>
                                    <div><a href={`/admintool9x/member/${member.id}/`}><p>{member.display_name}</p></a></div>
                                </td>
                                <td>
                                    <div>{member.status}</div>
                                </td>
                                <td>
                                    <div>{member.email}</div>
                                </td>
                                <td>
                                    <div>{member.date_added}</div>
                                </td>
                                <td>
                                    <div>{member.total_amount_allotted}</div>
                                </td>
                            </tr>
                        ))}


                    </tbody>
                    // )}/>
                ) :
                    (
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    {list == "memberSameIpList" ? (<p>No member found with the same IP address</p>) :
                                        (<p>No member found with the same password</p>)}
                                </td>
                            </tr>
                        </tbody>
                    )}

            </table>
        </>
    )
}

export default MemberSameTable;

