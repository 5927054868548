import React from 'react';
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import MultiSelectTransfer from '../Common/MultiSelectTransfer';

const ReportAccessForm = ({ result, handleGoBack, loading, initialValues }) => {

    const { values, setFieldValue } = useFormikContext();

    return (
        <Form id='myForm'>
            {result && result.reportAccess && result.reportAccess.length > 0 &&
                <div className="mb-3 row">
                    <label htmlFor="id" className="col-lg-2">ID:</label>
                    <div className="col-lg-4">
                        {result.reportAccess[0].id}
                    </div>
                </div>
            }

            <div className="mb-3 row">
                <label htmlFor="company_name" className="col-lg-2">Name</label>
                <div className="col-lg-6">
                    <Field className="form-control" type='text' name="company_name" maxLength="100" />
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="member_ids" className="col-lg-2">Member Ids (Comma Separated)</label>
                <div className="col-lg-4">
                    <Field className="form-control" type='text' name="member_ids" maxLength="100" />
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="member_ids" className="col-lg-2">Category</label>
                <div className="col-lg-4">
                    <Field as="select" className="form-select" type='text' onChange={(e) => {
                        setFieldValue("category_cd", e.target.value)
                    }} name="category_cd" maxLength="100" >
                        <option value=""></option>
                        <option value="report_cdn">Report</option>
                        <option value="article">Article</option>
                    </Field>
                </div>
            </div>
            {
                values?.category_cd == "report_cdn" &&
                <div className="mb-3 row">
                    <MultiSelectTransfer dataList={result.authorList} labelAttr={"Authors' Name"} initialItemsNameAttr={"initialItems"} selectedItemsNameAttr={"selectedItems"} selectedItemsIdsAttr={"selectedItemsIds"} />
                </div>
            }

            <div className="mb-3 row">
                <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                <div className="col-lg-2">
                    <Field
                        className="form-check-input ms-3"
                        type="checkbox"
                        name="display_status"
                        onChange={(e) => {
                            const newValue = e.target.checked;
                            setFieldValue('display_status', newValue);
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <label htmlFor="lock_status">Lock Status</label>
                    <Field
                        className="form-check-input ms-3"
                        type="checkbox"
                        name="lock_status"
                        onChange={(e) => {
                            const newValue = e.target.checked ? 1 : 0;
                            setFieldValue('lock_status', newValue);
                        }}
                    />
                </div></div>

            <div className="mb-3 text-center">
                {initialValues.lock_status !== 1 && (
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            {result.reportAccess && result.reportAccess.length > 0 ? 'Update' : 'Create'}
                        </span>
                    </button>
                )}

                <button
                    type="button"
                    className="btn btn-secondary btn-block ms-2"
                    onClick={handleGoBack}
                >
                    Cancel
                </button>
            </div>
            <ValidateFormFields />
        </Form>
    );
};


export default ReportAccessForm;
