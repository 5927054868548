import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoForm from './IpoForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import IpoTabs from './IPOPageTabs';
import { convertNullToBlank, isValidVariable } from '../Common/CommonFunction';


const validationSchema = Yup.object({
    company_name: Yup.string().trim().required("Company name is required").max(100, "Company name must be at most 100 characters"),
    ipo_news_title: Yup.lazy((value, context) => {
        const excludedTypes = ['InvIT', 'REIT', 'SM REIT', 'FPO'];
        return !excludedTypes.includes(context.parent.issue_type)
            ? Yup.string()
                .trim()
                .required("ipo_news_title is required")
                .max(50, "ipo_news_title must be at most 50 characters")
                .matches(/.*ipo.*/i, 'IPO News Title should contain the word "IPO"')
            : Yup.string()
                .trim()
                .required("ipo_news_title is required")
                .max(50, "ipo_news_title must be at most 50 characters");
    }),
    urlrewrite_folder_name: Yup.string().trim().required("urlrewrite_folder_name is required").max(50, "urlrewrite_folder_name must be at most 50 characters"),
    issue_category: Yup.mixed().required("Please select an Issue Category"),
    issue_type: Yup.mixed().required("Please select an Issue Type"),
    issue_process_type: Yup.string().trim().required("Please select IPO Process Type"),
    ipo_listing_at_array: Yup.array().of(Yup.string().required("Please select at least one IPO Listing At option")).min(1, "Please select at least one IPO Listing At option"),
    face_value: Yup.lazy((value, context) => {
        const excludedTypes = ['InvIT', 'REIT', 'SM REIT'];
        return !excludedTypes.includes(context.parent.issue_type)
            ? Yup.string().trim().required('Face value is required')
            : Yup.string().trim(); // Optional field for excluded types
    }),
});


const Ipo = () => {
    const getIpoPrice = () => {
        let price = 0;
        try {
            const radios = document.getElementsByName('issue_process_type');
            let selectedValue = '';

            // Loop through all radio buttons and find the one that is checked
            radios.forEach(radio => {
                if (radio.checked) {
                    selectedValue = radio.value;
                }
            });
            if (selectedValue == "BB") {
                price = document.getElementsByName('issue_price_upper')[0].value;
                price = price !== "" ? parseInt(price) : 0;
            }
            if (selectedValue == "FP") {
                price = document.getElementsByName('issue_price_final')[0].value;
                price = price !== "" ? parseInt(price) : 0;
            }
        } catch (err) {
            return price;
        }
        return price;
    }
    function isWeekday(date) {
        const givenDate = new Date(date);
        const dayOfWeek = givenDate.getDay();
        return dayOfWeek !== 0 && dayOfWeek !== 6;
    }

    const validate = (e) => {
        e = convertNullToBlank(e);

        if (e.issue_category == 'SME') {
            var count_check = 0;

            e.ipo_listing_at_array.forEach(element => {
                if (element == "BSE SME" || element == "NSE SME") {
                    count_check = count_check + 1;
                }
            });

            if (count_check > 1) {
                alert("Please select only one IPO Listing At");
                return false;
            }
        }

        if (e.prospectus_rhp != "") {
            if (e.market_maker_id == "" && e.issue_category == 'SME') {
                alert('Market maker update required');
            }
        }


        let price = getIpoPrice();
        if (price > 0) {
            let ofs_size = document.getElementsByName('issue_size_ofs_in_amt')[0].value;
            let fresh_size = document.getElementsByName('issue_size_fresh_in_amt')[0].value;
            let total_size = document.getElementsByName('issue_size_in_amt')[0].value;

            let ofs_shares = document.getElementsByName('issue_size_ofs_in_shares')[0].value;
            let fresh_share = document.getElementsByName('issue_size_fresh_in_shares')[0].value;
            let total_share = document.getElementsByName('issue_size_in_shares')[0].value;

            if (!isValidVariable(ofs_size)) {
                ofs_size = 0;
            }
            if (!isValidVariable(fresh_size)) {
                fresh_size = 0;
            }
            if (!isValidVariable(total_size)) {
                total_size = 0;
            }

            if (!isValidVariable(ofs_shares)) {
                ofs_shares = 0;
            }
            if (!isValidVariable(fresh_share)) {
                fresh_share = 0;
            }
            if (!isValidVariable(total_share)) {
                total_share = 0;
            }
            if ((fresh_size > 0 || fresh_share > 0) && parseInt(fresh_size) != parseInt(fresh_share) * parseInt(price)) {
                alert("Please Check Fresh Issue Size or Amount , it not matching with Fresh Issue Size (Rs) * Price = Fresh Amount");
                return false;
            }
            if ((ofs_size > 0 || ofs_shares > 0) && parseInt(ofs_size) != parseInt(ofs_shares) * parseInt(price)) {
                alert("Please Check OFS Issue Size or Amount , it not matching with OFS Issue Size (Rs) * Price = OFS Amount");
                return false;
            }

            if ((total_size > 0 || total_share > 0) && parseInt(total_size) != parseInt(total_share) * parseInt(price)) {
                alert("Please Check Total Issue Size or Amount , it not matching with Total Issue Size (Rs) * Price = Total Amount");
                return false;
            }

            if ((parseFloat(total_size) > 0 && parseFloat(fresh_size) > 0 && parseFloat(ofs_size) > 0) || (parseFloat(total_size) > 0 && parseFloat(total_share) > 0)) {
                if (parseFloat(total_size) !== parseFloat(fresh_size) + parseFloat(ofs_size)) {
                    alert("Please Check Issue Size (Total) (Rs) , it not matching with Fresh Issue Size (Rs) + OFS Issue Size (Rs)");
                    return false;
                }
            }
        }
        let closeDate = new Date(e.issue_close_date)
        if (e.issue_open_date != "" && e.issue_close_date != "") {
            if (!isWeekday(e.issue_open_date)) {
                alert("Issue Open date can't be weekened day.");
                return false;
            }
            if (!isWeekday(e.issue_close_date)) {
                alert("Issue Close date can't be weekened day.");
                return false;
            }
            let openDate = new Date(e.issue_open_date)
            if (openDate >= closeDate) {
                alert("Issue close date should be greater then issue open date.");
                return false;
            }
            var nextDay = openDate;
            nextDay.setDate(nextDay.getDate() + 1);
            var formattedDate = nextDay.getFullYear() + "-" + String(nextDay.getMonth() + 1).padStart(2, '0') + "-" + String(nextDay.getDate()).padStart(2, '0');
            if (formattedDate == e.issue_close_date) {
                alert("Issue open date and issue close date difference must be greater than one day");
                return false;
            }
        }
        if (e.anchor_investor_status == true && e.shares_offered_anchor_investor == "") {
            alert('Enter Shares offered for Anchor investor');
            return false;
        }
        if (e.timetable_boa_dt !== "") {
            let boaDate = new Date(e.timetable_boa_dt)
            if (boaDate <= closeDate) {
                alert("BOA date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_refunds_dt !== "") {
            let refundDate = new Date(e.timetable_refunds_dt)
            if (refundDate <= closeDate) {
                alert("Refund date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_share_credit_dt !== "") {
            let shareCreditDate = new Date(e.timetable_share_credit_dt)
            if (shareCreditDate <= closeDate) {
                alert("Credit of Equity Shares date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_listing_dt !== "") {
            let listingDate = new Date(e.timetable_listing_dt)
            if (listingDate <= closeDate) {
                alert("Listing date should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_anchor_lockin_end_dt_1 !== "") {
            let lockinDateOne = new Date(e.timetable_anchor_lockin_end_dt_1)
            if (lockinDateOne <= closeDate) {
                alert("Anchor lock-in period end date (30 Days) should be greater than issue close date.");
                return false;
            }
        }
        if (e.timetable_anchor_lockin_end_dt_2 !== "") {
            let lockinDateTwo = new Date(e.timetable_anchor_lockin_end_dt_2)
            if (lockinDateTwo <= closeDate) {
                alert("Anchor lock-in period end date (90 Days) should be greater than issue close date.");
                return false;
            }
        }
        if (e.cm_rating !== "" && e.cm_rating > 32767) {
            alert("Please enter correct CM Rating.");
            return false;
        }
        if (e.bse_scripcode != "" && e.nse_symbol == "") {
            alert("Please enter NSE/BSE Symbol.");
            return false;
        }
        if (e.employee_reservation_flag == 1 && e.employee_reservation_bidding_limit == "") {
            alert("Please select the bid limit");
            return false;
        }
        if (e.shareholder_reservation_flag == 1 && e.shareholder_reservation_bidding_limit == "") {
            alert("Please select the bid limit");
            return false;
        }

        if (e.company_name.toLowerCase().includes("private") || e.company_name.toLowerCase().includes("pvt")) {
            const privateWord = e.company_name.toLowerCase().includes("private") ? "Private" : "Pvt";
            const userConfirmed = window.confirm(`Do you want to add the '${privateWord}' word to the company name?`);
            if (!userConfirmed) {
                return false;
            }
        }


        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [stockExchage, setStockExchage] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        issue_category: "Mainline",
        issue_type: "IPO",
        issue_process_type: "BB",
        issue_open_date: "",
        issue_close_date: "",
        face_value: "",
        issue_price_lower: "",
        issue_price_upper: "",
        issue_price_final: "",
        issue_size_fresh_in_shares: "",
        issue_size_fresh_in_amt: "",
        issue_size_ofs_in_shares: "",
        issue_size_ofs_in_amt: "",
        issue_size_in_shares: "",
        issue_size_in_amt: "",
        issue_size_fresh_in_shares_readonly: "",
        issue_size_fresh_in_amt_readonly: "",
        issue_size_ofs_in_shares_readonly: "",
        issue_size_ofs_in_amt_readonly: "",
        issue_size_in_shares_readonly: "",
        issue_size_in_amt_readonly: "",
        ipo_listing_at: "BSE,NSE",
        ipo_listing_at_array: ["BSE", "NSE"],
        market_lot_size: "",
        minimum_order_quantity: "",
        ipo_app_form_url: "",
        market_maker_id: "",
        prospectus_drhp: "",
        prospectus_rhp: "",
        addendum_drhp: "",
        ipo_news_title: "",
        urlrewrite_folder_name: "",
        timetable_boa_dt: "",
        timetable_refunds_dt: "",
        timetable_share_credit_dt: "",
        timetable_listing_dt: "",
        nse_symbol: "",
        bse_scripcode: "",
        capital_market_id: "",
        cm_rating: "",
        timetable_boa_dt: "",
        timetable_refunds_dt: "",
        timetable_share_credit_dt: "",
        timetable_listing_dt: "",
        nse_symbol: "",
        capital_market_id: "",
        cm_rating: "",
        shares_offered_market_maker: "",
        shares_offered_anchor_investor: "",
        shares_offered_qib: "",
        shares_offered_nii: "",
        shares_offered_big_nii: "",
        shares_offered_small_nii: "",
        shares_offered_rii: "",
        shares_offered_emp: "",
        shares_offered_others: "",
        shares_offered_total: "",
        discount_retail: "",
        discount_employee: "",
        stock_exchange_type: "",
        promoter_shareholding_pre_issue: "",
        promoter_shareholding_post_issue: "",
        total_shareholding_pre_issue: "",
        total_shareholding_post_issue: "",
        pre_issue_capital: "",
        post_issue_capital: "",
        gmp: "",
        kostak: "",
        hide_temporary: false,
        display_order: "",
        lock_status: false,
        display_status: false,
        anchor_investor_status: false,
        pe_ratio: "",
        post_pe_ratio: "",
        kpi_roe: "",
        kpi_roce: "",
        kpi_debt_equity: "",
        market_cap: "",
        kpi_eps: "",
        kpi_ronw: "",
        hni_cost_nii_oversubscription_start: "",
        hni_cost_nii_bid_amount_incremental_rs_cr: "",
        shares_offered_qib_percentage_temp: "",
        shares_offered_rii_percentage_temp: "",
        shares_offered_nii_percentage_temp: "",
        timetable_anchor_bid_dt: "",
        timetable_anchor_lockin_end_dt_1: "",
        timetable_anchor_lockin_end_dt_2: "",
        issue_withdraw: "",
        shareholder_company_id: "",
        shareholder_company_name: [],
        company_name_array: [],
        company_list_id: "",
        shareholder_reservation_flag: "",
        employee_reservation_bidding_limit: "",
        shareholder_reservation_bidding_limit: "",
        employee_reservation_flag: "",
        // shareholder_company_text : "",
        shareholder_company_text: {
            label: '',
            value: ''
        },
        ipo_confirmation_status: false,
        hidden_drhp_status: false,
        current_drhp_code_desc: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New IPO" })
            } else {
                setMetaData({ "title": "Edit IPO" })
            }
            api.post('/admin-ipo-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);

                    setStockExchage(response.data.issueStockExchangeList);

                    if (!isAddMode) {
                        if (response.data.ipo[0].ipo_listing_at !== undefined && response.data.ipo[0].ipo_listing_at !== null && response.data.ipo[0].ipo_listing_at.trim().length > 0) {
                            response.data.ipo[0].ipo_listing_at_array = response.data.ipo[0].ipo_listing_at.split(',').map((v) => v.trim())
                        }
                        response.data.ipo[0].issue_size_fresh_in_shares_readonly = response.data.ipo[0].issue_size_fresh_in_shares;
                        response.data.ipo[0].issue_size_fresh_in_amt_readonly = response.data.ipo[0].issue_size_fresh_in_amt;
                        response.data.ipo[0].issue_size_ofs_in_shares_readonly = response.data.ipo[0].issue_size_ofs_in_shares;
                        response.data.ipo[0].issue_size_ofs_in_amt_readonly = response.data.ipo[0].issue_size_ofs_in_amt;
                        response.data.ipo[0].issue_size_in_shares_readonly = response.data.ipo[0].issue_size_in_shares;
                        response.data.ipo[0].issue_size_in_amt_readonly = response.data.ipo[0].issue_size_in_amt;

                        setInitialValues({
                            ...response.data.ipo[0],
                            company_name_array: response.data.ipo[0].shareholder_company_name
                        });
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ipoNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {

                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {

        const { company_name_array, shareholder_company_name, ...trimmedValues } = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (Array.isArray(trimmedValues.shareholder_company_name)) {
            trimmedValues.shareholder_company_name = trimmedValues.shareholder_company_name.join(',');
        }

        const companyValuesOnly = (values.company_name_array || []).map(company => company.value);

        const companyNameWithComma = companyValuesOnly.join(',');

        if (validate(trimmedValues)) {
            trimmedValues.ipo_listing_at = trimmedValues.ipo_listing_at_array.join(', ');
            trimmedValues.company_list_id = companyNameWithComma;

            dispatch(startLoading());

            api.post('/admin-ipo-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
            // Set submitting to false to allow the form to be submitted again
        }

        setSubmitting(false);
    };

    return (
        <>
            {
                isAddMode ? "" :
                    <IpoTabs idAtr={result.id} />
            }

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} isAddMode={isAddMode} stockExchage={stockExchage} setStockExchage={setStockExchage} getIpoPrice={getIpoPrice} />
            </Formik>
        </>
    )
}

export default Ipo
