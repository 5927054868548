import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BookModuleForm from './BookModuleForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Tabs, Tab } from 'react-bootstrap';
import { funTrimNestedStrings, isValidImageFileName } from '../Common/CommonFunction';

const BookModule = () => {
    const validationSchema = Yup.object({
        title: Yup.string().trim().required('Please enter Chapter module title.'),
        urlrewrite_folder_name: Yup.string().trim().required('Please enter URLRewrite Folder Name.'),
        meta_desc: Yup.string().trim().required('Please enter meta description.'),
        description: Yup.string().trim().required('Please enter description.'),
        book_id: Yup.string().trim().required('Please select category.'),
    });

    const [activeTab, setActiveTab] = useState("module");
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        book_id: "",
        title: "",
        urlrewrite_folder_name: "",
        description: "",
        display_order: "",
        display_status: false,
        image_name: "",
        image_width: "",
        image_height: "",
        meta_title: "",
        meta_desc: "",
        lock_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Book Module" })
            } else {
                setMetaData({ "title": "Edit Book Module" })
            }
            api.post('/admin-bookmodule-read', {
                id: id
            }).then(response => {
                if (response && response.data && response.data.msg === 1) {

                    setResult(response.data);

                    if (!isAddMode) {
                        setInitialValues(response.data.bookModule[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.bookModuleNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = funTrimNestedStrings(values);

        if(!isValidImageFileName(trimmedValues.image_name)){
            return false;
        }

        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-bookmodule-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {!isAddMode &&
                <Tabs
                    defaultActiveKey="module"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabChange}>
                    <Tab eventKey="module" title="Module">
                    </Tab>

                    {id && <Tab eventKey="chapter" title="Chapter">

                        {activeTab === "chapter" && result.bookChapters !== undefined && result.bookChapters.length > 0 &&
                            <div>
                                <h1>Book Chapter Details</h1>
                                <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            {Object.keys(result.bookChapters[0]).map(header => (
                                                <th key={header}>{header}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {result.bookChapters.map(book => (
                                            <tr key={book.id}>
                                                {Object.keys(result.bookChapters[0]).map(header => (
                                                    <td key={header} dangerouslySetInnerHTML={{ __html: book[header] }} />
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </Tab>}

                </Tabs>
            }

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {activeTab !== "chapter" && (
                    <BookModuleForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading}
                    />
                )}
            </Formik>
        </>
    )
}

export default BookModule