import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import RightIssueForm from './RightIssueForm.js';
import { Formik } from 'formik';
import RITabs from './RITabs.js';
import { compareDates } from '../Common/CommonFunction';

const RightIssue = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('Company Name is Mandatory'),
        news_title: Yup.string().trim().required('Please Enter the News Title').matches(/Rights Issue/i, 'News Title must contain "Rights Issue"'),
        urlrewrite_folder_name: Yup.string().trim().required('URL Rewrite Folder name is required'),
        registrar_email_1: Yup.string().trim().email('Invalid registrar Email 1').nullable(),
        registrar_email_2: Yup.string().trim().email('Invalid registrar Email 2').nullable(),
        email: Yup.string().trim().email('Invalid registrar Email 2').nullable(),
        website: Yup.string().trim().url('Invalid URL for Website').nullable(),
        face_value: Yup.string().trim().required('Please Enter the face value')
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        company_name_auto: "",
        news_title: "",
        listing_at: "",
        urlrewrite_folder_name: "",

        // ==== Rights Issue Values ======

        isin: "",
        bse_ipo_no: "",
        nse_symbol: "",
        bse_scripcode: "",
        re_nse_symbol: "",
        re_bse_scripcode: "",

        // ===== Stock Details Values ======

        face_value: "",
        issue_price: "",
        issue_size_in_shares: "",
        issue_size_in_amt: "",
        listing_dt: "",

        // ======= ********** ===========

        entitlement_rights_equity_share: "",
        entitlement_fully_paid_equity_share: "",
        entitlement: "",

        // ====== Entitlement Values =======

        application_form_url: "",
        letter_of_offer_url: "",
        faqs_url: "",

        // ===== Document Links Values ======

        record_dt: "",
        last_day_to_buy_shares_dt: "",
        issue_open_date: "",
        issue_close_date: "",
        rights_entitlements_credit_dt: "",
        timetable_renunciation_dt: "",

        // ====== Issue Date Values =========

        timetable_allotment_dt: "",
        timetable_credit_dt: "",
        timetable_listing_dt: "",

        // ===== Tentative TimeTable Values ======

        other_issue_detail: "",
        notices: "",
        terms_of_payment: "",

        // ===== ****** ===========

        registrar_1: "",
        registrar_1_email: "",
        registrar_2: "",
        registrar_2_email: "",
        lead_managers: "",

        // ====== Registrar and Lead Managers Values ======

        logo_url: "",
        logo_length: "",
        logo_height: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        phone: "",
        email: "",
        website: "",

        // ======= Company Info Values ========

        company_desc: "",
        issue_objects: "",
        financial: "",

        display_order: "",
        display_status: false,
        issue_withdraw_status: false,
        lock_status: false,

        listing_at_array: [],
        initialItems: [],
        selectedItems: [],
        selectedItemsIds: [],
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/127");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Right Issue" })
            } else {
                setMetaData({ "title": "Edit Right Issue" })
            }
            api.post('/admin-rightissue-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);

                    if (!isAddMode) {
                        if (response.data.rightissue[0].lead_managers !== undefined && response.data.rightissue[0].lead_managers !== null && response.data.rightissue[0].lead_managers.trim().length > 0) {
                            response.data.rightissue[0].selectedItemsIds = response.data.rightissue[0].lead_managers.split(",").map((item) => parseInt(item.trim())).filter(Boolean);
                        } else {
                            response.data.rightissue[0].selectedItemsIds = [];
                        }
                        if (response.data.rightissue[0].listing_at !== undefined && response.data.rightissue[0].listing_at !== null && response.data.rightissue[0].listing_at.trim().length > 0) {
                            response.data.rightissue[0].listing_at_array = response.data.rightissue[0].listing_at.split(",").map((item) => item.trim());
                        } else {
                            response.data.rightissue[0].listing_at_array = [];
                        }

                        response.data.rightissue[0].company_name_auto = response.data.rightissue[0].company_name;
                        console.log(response.data);
                        setInitialValues(response.data.rightissue[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.rightissueNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const validate = (values) => {
        const dateDifference = compareDates(values.issue_close_date, values.issue_open_date);
        console.log('Comparison Result:', dateDifference);

        if (dateDifference < 0) {
            alert("Issue Close date cannot exist before the Issue Open date");
            return false;
        }

        if (values.issue_price !== "" && values.issue_price < values.face_value) {
            alert("Issue price should be greater than or equal to face value");
            return false;
        }
        
        if (values.listing_at_array.length == 0) {
            alert("Please select stock exchange");
            return false;
        }
        if (values.issue_open_date !== "" && values.issue_close_date == "") {
            alert("Please enter issue close date too.");
            return false;
        }
        if (values.issue_open_date == "" && values.issue_close_date !== "") {
            alert("Please enter issue Open date too.");
            return false;
        }
        if (values.issue_close_date !== "" && values.listing_dt !== "") {
            const dateDifferenceClosingListing = compareDates(values.listing_dt, values.issue_close_date);
            if (dateDifferenceClosingListing < 0) {
                alert("Issue listing date cannot exist before the Issue Close date");
                return false;
            }
        }
        if (values.record_dt !== "" && values.issue_open_date !== "") {
            const dateDifferenceClosingListing = compareDates(values.issue_open_date, values.record_dt);
            if (dateDifferenceClosingListing < 0) {
                alert("Record date should be before Issue Open date");
                return false;
            }
        }
        return true;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        if (trimmedValues.listing_at_array.length > 0) {
            trimmedValues.listing_at = trimmedValues.listing_at_array.join(', ');
        }
        if (trimmedValues.selectedItemsIds.length > 0) {
            trimmedValues.lead_managers = trimmedValues.selectedItemsIds.join(',');
        }
        if (validate(trimmedValues)) {

            dispatch(startLoading());

            api.post('/admin-rightissue-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/127");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
        }

        setSubmitting(false);
    };

    return (
        <>
            {
                isAddMode ? "" :
                    <RITabs idAtr={result.id} />
            }

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.rightissue !== undefined &&
                result.rightissue.length > 0 &&
                result.rightissue[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <RightIssueForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues} />
            </Formik>

        </>
    )
}

export default RightIssue


