import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../state/slices/message';
import routeConfig from '../routeConfig';
import Cookies from 'js-cookie';

const withAuthAdmin = (WrappedComponent) => {
    const WithAuthComponent = (props) => {
        const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
        const location = useLocation();
        const dispatch = useDispatch();
        const isSecondVerificationNeeded = Cookies.get("isSecondVerificationNeeded") || "false"
        const queryParams = new URLSearchParams(location.search);
        let params = "";
        queryParams.forEach((value, key) => {
            params = params + key + "=" + value + "&";
        });
        if (isLoggedIn && isSecondVerificationNeeded == "false" && currentUser && currentUser.role_id === 3) {
            return <WrappedComponent {...props} />;
        } else if (isLoggedIn && isSecondVerificationNeeded == "false") {
            dispatch(setMessage("You don't have permission to access this page."));
            return <Navigate to={`/`} />;
        } else {
            const ref = `${location.pathname}${params ? `?${params}` : ""}`;
            return <Navigate to={routeConfig.accessdenied() + `?ref=${encodeURIComponent(ref)}`} />;
        }


    };

    return WithAuthComponent;
};

export default withAuthAdmin;
