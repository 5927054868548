import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoIssueDetailForm from './IpoIssueDetailForm'
import { Formik } from 'formik';
import * as Yup from 'yup';
import IpoTabs from './IPONewPageTabs';

const validationSchema = Yup.object({
    face_value: Yup.string().trim().required("Enter Face Value"),
});

const IpoIssueDetail = () => {

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [stockExchage, setStockExchage] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const getIpoPrice = (issueProcessType) => {
        console.log("chk process type : ", issueProcessType);
        let price = 0;
        try {
            if (issueProcessType === "BB") {
                price = document.getElementsByName('issue_price_upper')[0].value;
                price = price !== "" ? parseInt(price) : 0;
            }
            if (issueProcessType === "FP") {
                price = document.getElementsByName('issue_price_final')[0].value;
                price = price !== "" ? parseInt(price) : 0;
            }
        } catch (err) {
            return price;
        }
        return price;
    };

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        face_value: "",
        issue_price_final: "",
        issue_price_lower: "",
        issue_price_upper: "",
        issue_process_type: "",

        market_lot_size: "",
        minimum_order_quantity: "",

        discount_retail: "",
        discount_retail_per: "",
        discount_employee: "",
        discount_employee_per: "",
        discount_shareholder: "",
        discount_shareholder_per: "",

        issue_size_fresh_in_shares: "",
        issue_size_fresh_in_amt: "",
        issue_size_ofs_in_shares: "",
        issue_size_ofs_in_amt: "",
        issue_size_in_shares: "",
        issue_size_in_amt: "",

        firm_emp_shares: "",
        // firm_emp_amt:"",

        firm_mf_shares: "",
        // firm_mf_amt:"",

        shares_offered_market_maker: "",
        // firm_market_maker_amt:"",

        private_promoter_shares: "",
        // private_promoter_amt:"",

        firm_total_shares: "",

        public_issue_shares: "",
        public_issue_amt: "",

        prefer_emp_shares: "",
        // prefer_emp_amt:"",

        prefer_shareholder_shares: "",
        // prefer_shareholder_amt:"",

        prefer_other_shares: "",
        // prefer_other_amt:"",

        prefer_total_shares: "",

        net_issue_shares: "",
        net_issue_amt: "",

        shares_offered_qib_percentage_temp: "",
        shares_offered_qib: "",
        shares_offered_anchor_investor: "",
        shares_offered_qib_ex_anchor: "",

        shares_offered_nii_percentage_temp: "",
        shares_offered_nii: "",
        shares_offered_big_nii: "",
        shares_offered_small_nii: "",

        shares_offered_rii_percentage_temp: "",
        shares_offered_rii: '',

        shares_offered_emp: '',
        shares_offered_shareholders: '',
        shares_offered_others: '',
        shares_offered_total: '',

        total_shareholding_pre_issue: "",
        total_shareholding_post_issue: "",

        promoter_shareholding_pre_issue: "",
        promoter_shareholding_post_issue: "",

        promoter_pre_issue_shares: "",
        promoter_post_issue_shares: "",

        shareholder_company_id: "",
        shareholder_company_name: [],
        company_name_array: [],
        company_list_id: "",
        shareholder_company_text: {
            label: '',
            value: ''
        },

        nse_symbol: "",
        bse_scripcode: "",
        capital_market_id: "",
        cm_rating: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };

    useEffect(() => {
        if (didMountRef.current === "true") {
            dispatch(startLoading());

            api.post('/admin-ipo-issue-detail-read', { id: id }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        let ipoData = response.data.ipoIssueDetail[0];

                        response.data.ipoIssueDetail[0].issue_size_fresh_in_shares_readonly = ipoData.issue_size_fresh_in_shares;
                        response.data.ipoIssueDetail[0].issue_size_fresh_in_amt_readonly = ipoData.issue_size_fresh_in_amt;
                        response.data.ipoIssueDetail[0].issue_size_ofs_in_shares_readonly = ipoData.issue_size_ofs_in_shares;
                        response.data.ipoIssueDetail[0].issue_size_ofs_in_amt_readonly = ipoData.issue_size_ofs_in_amt;
                        response.data.ipoIssueDetail[0].issue_size_in_shares_readonly = ipoData.issue_size_in_shares;
                        response.data.ipoIssueDetail[0].issue_size_in_amt_readonly = ipoData.issue_size_in_amt;

                        setInitialValues({
                            ...ipoData,
                            company_name_array: response.data.ipoIssueDetail[0].shareholder_company_name,
                            issue_process_type: response.data.ipoIssueDetail[0].issue_process_type
                        });
                        
                        setMetaData({ "title": "IPO Issue Details " })
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            }).catch(error => {
                dispatch(finishLoading());
            });

        } else {
            didMountRef.current = "true";
        }
    }, []);

    // const validate = (values) => {
    //     if (!values.issue_price_final && !values.issue_price_upper) {
    //         alert("Please Enter the Issue Price")
    //         return false;
    //     }
    //     return true;
    // }

    const handleSubmit = (values, { setSubmitting }) => {

        const { company_name_array, shareholder_company_name, ...trimmedValues } = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() :
                (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (Array.isArray(trimmedValues.shareholder_company_name)) {
            trimmedValues.shareholder_company_name = trimmedValues.shareholder_company_name.join(',');
        }

        const companyValuesOnly = (values.company_name_array || []).map(company => company.value);

        const companyNameWithComma = companyValuesOnly.join(',');

        if (trimmedValues) {
            trimmedValues.company_list_id = companyNameWithComma;
            dispatch(startLoading());
            api.post('/admin-ipo-issue-detail-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/82");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }

        setSubmitting(false);
    };

    return (
        <>
            {
                isAddMode ? "" :
                    <IpoTabs idAtr={result.id} ipo_confirm={result?.ipoIssueDetail?.[0]?.ipo_confirmation_status}/>
            }

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoIssueDetailForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading}
                    getIpoPrice={() => getIpoPrice(initialValues.issue_process_type)} />
            </Formik>
        </>
    )
}

export default IpoIssueDetail;
