import React, { useState, useEffect } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CheckboxGroup from '../Common/CheckboxGroup';
import { funCreateSlug } from '../Common/CommonFunction';
import MultiSelectTransfer from '../Common/MultiSelectTransfer';
import NumberInput from '../Common/NumberInput';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';

const NCDForm = ({ initialValues, result, handleGoBack, loading }) => {

  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [dateChangeMessage, setdateChangeMessage] = useState("");

  const [dateCompareMsg, setDateCompareMsg] = useState('');

  const [allow, setAllow] = useState('')

  const handleDateChange = (event) => {
    const openDate = values.issue_open_date;
    const closeDate = new Date(event.target.value);

    const formattedCloseDate = `${closeDate.getFullYear()}-${(closeDate.getMonth() + 1).toString().padStart(2, '0')}-${closeDate.getDate().toString().padStart(2, '0')}`;

    setFieldValue("issue_close_date", formattedCloseDate);

    if (formattedCloseDate < openDate) {
      setDateCompareMsg('Close date should be after open date');

    } else {
      setDateCompareMsg('');
    }
  };

  let initialOpenDate = initialValues.issue_open_date;
  let initialCloseDate = initialValues.issue_close_date;
  let timer = 0;

  const checkDateChanges = () => {
    if (values.issue_open_date != initialOpenDate || values.issue_close_date != initialCloseDate) {
      setdateChangeMessage("Date modified. Please check display order and NCD Calendar");
    } else {
      setdateChangeMessage("");
    }
    initialOpenDate = values.issue_open_date;
    initialCloseDate = values.issue_close_date;
  };

  useEffect(() => {
    clearTimeout(timer);
    checkDateChanges();
    timer = setTimeout(checkDateChanges, 5000);
  }, [values.issue_open_date, values.issue_close_date])

  useEffect(() => {
    if (values.issue_type === "standalone") {
      setAllow(true);
    } else {
      setAllow(false);
    }
  }, [values.issue_type]);

  return (
    <>
      <Form id='myForm'>

        <div className="my-3 row">
          <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
          <div className="col-lg-4">
            <Field className="form-control" maxLength="100" name="company_name" tabIndex={1} />
          </div>

          <label htmlFor="secured_ncd" className="col-lg-2 control-label">Security Type</label>
          <div className="col-lg-2">
            <Field tabIndex={5} className="form-check-input ms-3" type="checkbox" name="secured_ncd" onChange={(e) => {
              const newValue = e.target.checked ? 1 : 0;
              setFieldValue('secured_ncd', newValue);
            }} /> Secured NCD
          </div>

          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" name="unsecured_ncd" tabIndex={10} onChange={(e) => {
              const newValue = e.target.checked ? 1 : 0;
              setFieldValue('unsecured_ncd', newValue);
            }} /> Unsecured NCD
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listing_at_array" className="col-lg-2 required">
            Issue Listing At
          </label>
          <div className="col-lg-4">
            {result.issueStockExchangeList !== undefined &&
              <Field
                name="listing_at_array"
                label="Issue Listing At"
                component={CheckboxGroup}
                options={result.issueStockExchangeList}
                className='form-check-input Stock_Exchange_Type_Checkbox me-2'
                tabIndex={15}
              />
            }
          </div>

          <label htmlFor="news_title" className="col-lg-2 required">News Title</label>
          <div className="col-lg-4">
            <Field name="news_title" className="form-control" tabIndex={20} maxLength="50" onBlur={(e) => {
              const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
              if (userConfirmed) {
                var news_title = e.target.value;
                var slug = funCreateSlug(news_title)
                setFieldValue('urlrewrite_folder_name', slug);
              } else {
                setFieldTouched('news_title', true);
              }
            }} />
          </div>

        </div>
        <div className="mb-3 row">
          <label htmlFor="issue_type" className="col-lg-2 required">Issue Type</label>
          <div className="col-lg-4">
            <Field as="select" name="issue_type" className="form-select" tabIndex={25}>
              <option></option>
              <option value="standalone">Standalone</option>
              <option value="tranch">Tranch</option>
            </Field>
          </div>

          <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="50" name="urlrewrite_folder_name" tabIndex={25} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="prospectus_drhp" className="col-lg-2">Prospectus DRHP (Base)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="200" name="prospectus_drhp" tabIndex={30} />
          </div>
          <label htmlFor="prospectus_rhp" className="col-lg-2">Prospectus RHP (Base)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="200" name="prospectus_rhp" tabIndex={35} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="prospectus_drhp_shelf" className="col-lg-2">Prospectus DRHP (Shelf)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="200" name="prospectus_drhp_shelf" tabIndex={40} />
          </div>
          <label htmlFor="prospectus_rhp_shelf" className="col-lg-2">Prospectus RHP (Shelf)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="200" name="prospectus_rhp_shelf" tabIndex={45} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="issue_open_date" className="col-lg-2">Issue Open Date</label>
          <div className="col-lg-2">
            <Field className="form-control dateInput" type="date" name="issue_open_date" tabIndex={50} />
          </div>
          <div className="col-lg-2">
          </div>

          <label htmlFor="record_date" className="col-lg-2">Record Date</label>
          <div className="col-lg-2">
            <Field className="form-control" type="date" name="record_date" tabIndex={55} />
          </div>
          <div className="col-lg-2">

          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="issue_close_date" className="col-lg-2">Issue Close Date</label>
          <div className="col-lg-2">
            <Field className="form-control dateInput" type="date" name="issue_close_date" tabIndex={60} onBlur={(e) => handleDateChange(e)} />
            <p className="text-danger" style={{ cursor: 'pointer' }}>{dateChangeMessage} {dateCompareMsg}</p>
          </div>
          <div className="col-lg-2">

          </div>
          <label htmlFor="deemed_date_of_allotment" className="col-lg-2">Deemed Date Of Allotment</label>
          <div className="col-lg-2">
            <Field className="form-control" type="date" name="deemed_date_of_allotment" tabIndex={65} />
          </div>
          <div className="col-lg-2">

          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="face_value" className="col-lg-2">Face Value</label>
          <div className="col-lg-1">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={70} maxLength="10" nameAtr="face_value" idAtr="txtface_value" />
          </div>
          <div className="col-lg-3"></div>
          <label htmlFor="market_lot_size" className="col-lg-2">Market Lot Size</label>
          <div className="col-lg-1">
            <NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="10" nameAtr="market_lot_size" idAtr="txtmarket_lot_size" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="issue_price" className="col-lg-2">Issue Price</label>
          <div className="col-lg-1">
            <NumberDecimalInput classNameAtr="form-control" tabIndexAtr={80} maxLength="10" nameAtr="issue_price" />
          </div>
          <div className="col-lg-3"></div>
          <label htmlFor="minimum_order_quantity" className="col-lg-2">Min. Order Quantity</label>
          <div className="col-lg-1">
            <NumberInput classNameAtr="form-control" tabIndexAtr={85} maxLength="10" nameAtr="minimum_order_quantity" idAtr="txtminimum_order_quantity" />
          </div>
        </div>

        <div className="mb-3 row">

          <label htmlFor="issue_size_base" className="col-lg-2">Issue Size (Base)</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={90} maxLength="20" nameAtr="issue_size_base" idAtr="txtissue_size_base" />
          </div>
          <div className="col-lg-2">Rs. {values.issue_size_base == null || values.issue_size_base === '' ? '0.00 Cr' : (parseFloat(values.issue_size_base) / 10000000).toFixed(2) + ' Cr'}</div>


          <label htmlFor="tenor" className="col-lg-2">Tenor</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="50" name="tenor" tabIndex={95} />
          </div>
        </div>

        <div className="mb-3 row">

          <label htmlFor="issue_size_shelf" className="col-lg-2">Issue Size (Shelf)</label>

          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" tabIndexAtr={100} maxLength="20" nameAtr="issue_size_shelf" idAtr="txtissue_size_shelf" disabledAtr={allow} />
          </div>
          <div className="col-lg-2">Rs. {values.issue_size_shelf == null || values.issue_size_shelf === '' ? '0.00 Cr' : (parseFloat(values.issue_size_shelf) / 10000000).toFixed(2) + ' Cr'}</div>

          <label htmlFor="payment_frequency" className="col-lg-2">Payment Frequency</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="150" name="payment_frequency" tabIndex={105} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="option_to_retain_oversubscription" className="col-lg-2">Option to Retain Oversubscription</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr={"form-control clear-comma"} tabIndexAtr={120} maxLength={"25"} nameAtr={"option_to_retain_oversubscription"} onchange="calculateOptionToRetainInCrore();" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')" />
          </div>

          <div className="col-lg-2">Rs. {values.option_to_retain_oversubscription == null || values.option_to_retain_oversubscription === '' ? '0.00 Cr' : (parseFloat(values.option_to_retain_oversubscription) / 10000000).toFixed(2) + ' Cr'}</div>

          <label htmlFor="tranche_issue_size" className="col-lg-2">Tranche Issue Size</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr={"form-control type_standalone clear-comma"} tabIndexAtr={125} maxLength={"150"} nameAtr={"tranche_issue_size"} onchange="calculateTrancheIssueSizeInCrore();" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')" disabledAtr={allow} />
          </div>

          <div className="col-lg-2">Rs. {values.tranche_issue_size == null || values.tranche_issue_size === '' ? '0.00 Cr' : (parseFloat(values.tranche_issue_size) / 10000000).toFixed(2) + ' Cr'}</div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="overall_issue_size" className="col-lg-2">Overall Issue Size</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr={"form-control clear-comma type_tranche"} tabIndexAtr={130} maxLength={"25"} nameAtr={"overall_issue_size"} onchange="calculateOverallIssueSizeInCrore();" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')" disabledAtr={!allow} />
          </div>

          <div className="col-lg-2">Rs. {values.overall_issue_size == null || values.overall_issue_size === '' ? '0.00 Cr' : (parseFloat(values.overall_issue_size) / 10000000).toFixed(2) + ' Cr'}</div>
          <label htmlFor="tranche_number" className="col-lg-2">Tranche Number</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr={"form-control type_standalone"} maxLength={"150"} tabIndexAtr={135} nameAtr={"tranche_number"} disabledAtr={allow} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="interest_on_refund" className="col-lg-2">Interest on Refund</label>
          <div className="col-lg-2">
            <Field className="form-control" type="text" maxLength="50" name="interest_on_refund" tabIndex={140} />
          </div>

          <div className="col-lg-2"><small>i.e.5%</small></div>
          <label htmlFor="allotment_url" className="col-lg-2">Allotment Link</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="500" name="allotment_url" tabIndex={145} />
          </div>
        </div>
        <hr />

        <h2>Bidding Auto Load</h2>
        <div className="mb-3 row">
          <label htmlFor="bse_scriptcode" className="col-lg-2">BSE Scriptcode</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"bse_scriptcode"} tabIndexAtr={150} />
          </div>
          <div className="col-lg-2"><small>i.e. 1800</small></div>
          <label htmlFor="bse_script_symbol" className="col-lg-2">BSE Script Symbol</label>
          <div className="col-lg-2">
            <Field className="form-control" type="text" maxLength="50" name="bse_script_symbol" tabIndex={155} />
          </div>
          <div className="col-lg-2"><small>i.e. MFLXIX</small></div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="nse_symbol" className="col-lg-2">NSE Symbol</label>
          <div className="col-lg-2">
            <Field className="form-control" type="text" name="nse_symbol" title="NSE_Symbol" tabIndex={160} />
          </div>
        </div>

        <hr />
        <h2>NCD Shares Offered Data</h2>

        <div className="mb-3 row">
          <div className="col-sm-4">
            <h3>Share Offered (Category Name)</h3>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_name_1" className="col-lg-4">Category 1 Name</label>
              <div className="col-lg-6">
                <Field as="select" name="shares_offered_category_name_1" className="form-select" tabIndex={165}>
                  <option></option>
                  {result.issueCategoryList !== undefined && result.issueCategoryList.map((option) => (
                    <option key={option.code_desc} value={option.code_desc}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_name_2" className="col-lg-4">Category 2 Name</label>
              <div className="col-lg-6">
                <Field as="select" name="shares_offered_category_name_2" className="form-select" tabIndex={170}>
                  <option></option>
                  {result.issueCategoryList !== undefined && result.issueCategoryList.map((option) => (
                    <option key={option.code_desc} value={option.code_desc}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>

              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_name_3" className="col-lg-4">Category 3 Name</label>
              <div className="col-lg-6">
                <Field as="select" name="shares_offered_category_name_3" className="form-select" tabIndex={175}>
                  <option></option>
                  {result.issueCategoryList !== undefined && result.issueCategoryList.map((option) => (
                    <option key={option.code_desc} value={option.code_desc}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>

              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_name_4" className="col-lg-4">Category 4 Name</label>
              <div className="col-lg-6">
                <Field as="select" name="shares_offered_category_name_4" className="form-select" tabIndex={180}>
                  <option></option>
                  {result.issueCategoryList !== undefined && result.issueCategoryList.map((option) => (
                    <option key={option.code_desc} value={option.code_desc}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>

          <div className="col-sm-4">
            <h3>Share Offered (in %)</h3>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_percent_category_1" className="col-lg-4">Category 1 (%)</label>
              <div className="col-lg-6">
                <NumberDecimalInput classNameAtr={"form-control"} maxLength={"3"} nameAtr={"shares_offered_percent_category_1"} tabIndexAtr={185} />
              </div>
              <div className="col-lg-2"><small>i.e. 10</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_percent_category_2" className="col-lg-4">Category 2 (%)</label>
              <div className="col-lg-6">
                <NumberDecimalInput classNameAtr={"form-control"} maxLength={"3"} nameAtr={"shares_offered_percent_category_2"} tabIndexAtr={190} />
              </div>
              <div className="col-lg-2"><small>i.e. 40</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_percent_category_3" className="col-lg-4">Category 3 (%)</label>
              <div className="col-lg-6">
                <NumberDecimalInput classNameAtr={"form-control"} maxLength={"3"} nameAtr={"shares_offered_percent_category_3"} tabIndexAtr={195} />
              </div>
              <div className="col-lg-2"><small>i.e. 50</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_percent_category_4" className="col-lg-4">Category 4 (%)</label>
              <div className="col-lg-6">
                <NumberDecimalInput classNameAtr={"form-control"} maxLength={"3"} nameAtr={"shares_offered_percent_category_4"} tabIndexAtr={200} />
              </div>
              <div className="col-lg-2"></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_percent_others" className="col-lg-4">Others (%)</label>
              <div className="col-lg-6">
                <NumberDecimalInput classNameAtr={"form-control"} maxLength={"3"} nameAtr={"shares_offered_percent_others"} tabIndexAtr={205} />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <h3>Share Offered (Auto Populate by Bidding System)</h3>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_1" className="col-lg-4">Category 1</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_category_1"} tabIndexAtr={210} />
              </div>
              <div className="col-lg-2"><small>i.e. 100000</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_2" className="col-lg-4">Category 2</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_category_2"} tabIndexAtr={215} />
              </div>
              <div className="col-lg-2"><small>i.e. 400000</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_3" className="col-lg-4">Category 3</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_category_3"} tabIndexAtr={220} />
              </div>
              <div className="col-lg-2"><small>i.e. 500000</small></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_category_4" className="col-lg-4">Category 4</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_category_4"} tabIndexAtr={225} />
              </div>
              <div className="col-lg-2"></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_others" className="col-lg-4">Others</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_others"} tabIndexAtr={230} />
              </div>
              <div className="col-lg-2"></div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="shares_offered_total" className="col-lg-4">Total</label>
              <div className="col-lg-6">
                <NumberInput classNameAtr={"form-control"} maxLength={"5"} nameAtr={"shares_offered_total"} tabIndexAtr={235} />
              </div>
              <div className="col-lg-2"><small>i.e. 1000000</small></div>
            </div>
          </div>
        </div>

        <hr />
        <div className="mb-3 row">
          <label htmlFor="nri_allowed" className="col-lg-2">NRI Allowed?</label>
          <div className="col-lg-4">
            <Field tabIndex={245} className="form-check-input ms-3" type="checkbox" name="nri_allowed" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('nri_allowed', newValue);
            }} />
          </div>

        </div>

        <div className="mb-3 row">
          <label htmlFor="rating_brief" className="col-lg-2">Rating (Brief)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="150" name="rating_brief" tabIndex={250} />
          </div>
          <label htmlFor="rating_detail" className="col-lg-2">Rating (Detail HTML)</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr={"rating_detail"} tabIndex={255} />
          </div>
        </div>
        <hr />

        <div className="mb-3 row">
          <label htmlFor="series" className="col-lg-2">Series (Brief)</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="500" name="series" tabIndex={260} />
          </div>
          <label htmlFor="coupon_rates" className="col-lg-2">Coupon Rates (HTML Table)</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr={"coupon_rates"} tabIndex={265} readOnly />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="coupon_incentive" className="col-lg-2">Coupon Incentive</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="150" name="coupon_incentive" tabIndex={270} />
          </div>
        </div>
        <hr />

        <div className="mb-3 row">
          <label htmlFor="other_issue_detail" className="col-lg-2">Other Issue Detail (HTML)</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr={"other_issue_detail"} tabIndex={275} />
          </div>

          <label htmlFor="notices" className="col-lg-2">Notices (HTML)</label>
          <div className="col-lg-4">
            <DynamicCharacterCountInputText className="form-control" rows="5" cols="46" nameattr={"notices"} tabIndex={280} />
          </div>
        </div>
        <hr />

        <div className="mb-3 row">
          <label htmlFor="registrar_id" className="col-lg-2">Registrar</label>
          <div className="col-lg-4">
            <Field as="select" name="registrar_id" className="form-select" tabIndex={285}>
              {result.registrarList !== undefined && result.registrarList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.comp_name}
                </option>
              ))}
            </Field>
          </div>

          <label htmlFor="registrar_email" className="col-lg-2">Registrar Email</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="50" name="registrar_email" tabIndex={290} />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="debenture_trusties_id" className="col-lg-2">Debenture Trusties</label>
          <div className="col-lg-4">
            <Field as="select" name="debenture_trusties_id" className="form-select" tabIndex={295}>
              {result.debentureTrustiesList !== undefined && result.debentureTrustiesList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.comp_name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">

          <MultiSelectTransfer dataList={result.leadManagerList} labelAttr={"Lead Managers"} initialItemsNameAttr={"initialItems"} selectedItemsNameAttr={"selectedItems"} selectedItemsIdsAttr={"selectedItemsIds"} tabIndex={300} />
        </div>

        <div className="mb-3 row">
          <label htmlFor="referral_link" className="col-lg-2">Referral Link</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="200" name="referral_link" tabIndex={305} />
          </div>
          <label htmlFor="referral_short_link" className="col-lg-2">Referral Short Link</label>
          <div className="col-lg-4">
            <Field className="form-control" type="text" maxLength="100" name="referral_short_link" tabIndex={310} />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_order" className="col-lg-2">Display Order</label>
          <div className="col-lg-1">
            <NumberInput classNameAtr="form-control" tabIndexAtr={315} maxLength="4" nameAtr="display_order" idAtr="txtdisplay_order" />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">Display Status</label>
          <div className="col-lg-4">
            <Field tabIndex={320} className="form-check-input ms-3" type="checkbox" name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
          <label htmlFor="issue_withdraw" className="col-lg-2">Issue Withdraw</label>
          <div className="col-lg-4">
            <Field tabIndex={325} className="form-check-input ms-3" type="checkbox" name="issue_withdraw" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('issue_withdraw', newValue);
            }} /> (Check if issue is withdrawn from the market)
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
          <div className="col-lg-4">
            <Field tabIndex={330} className="form-check-input ms-3" type="checkbox" name="lock_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('lock_status', newValue);
            }} />
          </div>
        </div>

        <hr />
        <div className="mb-3 text-center">
          {initialValues.lock_status !== 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={335}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.ncd !== undefined && result.ncd.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={340}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default NCDForm;