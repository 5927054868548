import React, { useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';

const IPOFeedbackArchiveForm = ({ result, handleGoBack, loading }) => {
	const { values, setFieldValue } = useFormikContext();

	const [caseType, setCaseType] = useState('lower');

	const handleConvertCase = (name) => {
		const currentValue = values[name];
		let newValue = '';

		if (caseType === 'lower') {
			newValue = currentValue.toLowerCase();
			setCaseType('upper');
		} else if (caseType === 'upper') {
			newValue = currentValue.toUpperCase();
			setCaseType('camel');
		} else if (caseType === 'camel') {
			newValue = toCamelCase(currentValue);
			setCaseType('lower');
		}

		setFieldValue(name, newValue);
	};

	// Function to convert string to camel case
	const toCamelCase = (str) => {
		return str.replace(/\b\w/g, function (match) {
			return match.toUpperCase();
		});
	};

	return (
		<>
			<Form id='myForm'>

				<div className="mb-3 row">
					{result !== undefined && result.ipofeedback !== undefined && result.ipofeedback.length > 0 &&
						<div className="mb-3 row">
							<label htmlhtmlFor="" className="col-lg-2">Feedback ID:</label>
							<div className="col-lg-4">
								{result.ipofeedback[0].id}
							</div>
						</div>
					}

					<label htmlFor="topic_id" className="col-lg-2">IPO</label>
					<div className="col-lg-4">
						<Field as="select" className="form-select form-select-sm" tabIndex={1} name="topic_id">
							<option ></option>
							{result.ipoLists !== undefined && result.ipoLists.map((option) => (
								<option key={option.id} value={option.id}>
									{option.company_name}
								</option>
							))}
						</Field>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="posted_by_name" className="col-lg-2">Posted By</label>
					<div className="col-lg-3">
						<Field className="form-control" type="text" tabIndex={5} name="posted_by_name" maxLength="50" />
					</div>
					<div className="col-lg-1">
						<img
							src="/images/editlowercase.png"
							width="16"
							alt="Click here to Convert to Lowercase"
							title="Click here to Convert to Lowercase"
							onClick={() => handleConvertCase('posted_by_name')}
							tabIndex={10}
						/>
					</div>
					<label htmlFor="member_id" className="col-lg-2">Member ID</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr={"form-control"} tabIndexAtr={10} nameAtr={"member_id"} maxLength={"10"} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="message" className="col-lg-2">Description</label>
					<div className="col-lg-9">
						<Field as="textarea" tabIndex={15} name="message" className="form-control" rows="10"></Field>
					</div>
					<div className="col-lg-1">
						<img
							src="/images/editlowercase.png"
							width="16"
							alt="Click here to Convert to Lowercase"
							title="Click here to Convert to Lowercase"
							onClick={() => handleConvertCase('message')}
							tabIndex={10}
						/>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="display_status" className="col-lg-2">Display Status</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" tabIndex={20} name="display_status" onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue('display_status', newValue);
						}} />
					</div>
					<label htmlFor="spam_reported" className="col-lg-2">Spam Reported</label>
					<div className="col-lg-2">
						<Field className="form-check-input" type="checkbox" tabIndex={25} name="spam_reported" onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue('spam_reported', newValue);
						}} />
					</div>
					<label htmlFor="parent_message_id" className="col-lg-2">Parent Message ID</label>
					<div className="col-lg-2">
						<Field className="form-control" tabIndex={30} name="parent_message_id" maxLength="10" readOnly />
					</div>
				</div>
				<div className="mb-3 text-center">
					{result.ipofeedback !== undefined && result.ipofeedback.length > 0 && (
						<button
							type="submit"
							className="btn btn-primary btn-block"
							disabled={loading}
							tabIndex={35}
						>
							{loading && (
								<span className="spinner-border spinner-border-sm me-2"></span>
							)}
							<span>Update</span>
						</button>
					)}

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={40}
					>Cancel</button>
				</div>
				<div>
					<p>Note:
						<ol><li><b>Update</b> = When updating display status; if no child message then disable the message, if child message exist then comment the message.</li>
							<li><b>Disable this thread</b> = Just disable the message. No matter if it has child messages or not, just disable it.</li>
						</ol>
					</p>
				</div>
				<ValidateFormFields />
			</Form>

		</>
	)
}

export default IPOFeedbackArchiveForm