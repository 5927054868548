import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import useApi from '../../common/useApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../state/slices/message';
import Message from './Message';

const NotFoundPage = () => {
    const api = useApi();
    const dispatch = useDispatch();

    useEffect(() => {
        // Function to get referring site
        const getReferringSite = () => {
            return document.referrer || 'N/A';
        };
        // Set the values for the hidden input fields
        document.querySelector('input[name="User_Agent"]').value = navigator.userAgent;
        document.querySelector('input[name="Referring_Site"]').value = getReferringSite();
        document.querySelector('input[name="Page_Requested"]').value = window.location.href;
        document.querySelector('input[name="Error_Type"]').value = "404";
    }, []);

    const sendError = (e) => {
        e.preventDefault();

        const userAgent = document.querySelector('input[name="User_Agent"]').value;
        const referringSite = document.querySelector('input[name="Referring_Site"]').value;
        const pageRequested = document.querySelector('input[name="Page_Requested"]').value;
        const errorType = document.querySelector('input[name="Error_Type"]').value;

        const data = {
            userAgent,
            referringSite,
            pageRequested,
            errorType
        };


        api.post('/admin-page-error-found', data).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(`<p>Thank you for letting us know!</p><p>The error is reported to our technical team and it will be resolve soon.</p><p>Meanwhile you could try one of the following pages:</p><p><a href='https://www.chittorgarh.com' title='Go to the home page for www.chittorgarh.com'>Home Page</a><br /><a href='https://www.chittorgarh.com/sitemap' title='View the complete site map for www.chittorgarh.com'>Site Map</a></p>`));
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    return (
        <>
            <Helmet>
                <title>Chittorgarh.com - Error Page</title>
                <meta name="Description" content="Chittorgarh.com - Error Page" />
            </Helmet>
            <div className="containner">
                <div className="row main">
                    <div className="col-md-12 p-4">
                        <Message/>
                        <p style={{ textAlign: 'left' }}>
                            <Link to="https://www.chittorgarh.com/" title="Visit Chittorgarh.com">
                                <img
                                    src="/images/chittorgarh-logo-small.gif"
                                    width="194"
                                    height="15"
                                    alt="Visit Chittorgarh.com"
                                />
                            </Link>
                        </p>

                        <p>You were incorrectly referred to this page by: <strong><Link to="/admintool9x/default.asp">alpha.chittorgarh.com</Link></strong><br />We suggest you try one of the links below:</p><p><Link to="https://www.chittorgarh.com">Home Page</Link></p>

                        <div className="form">
                            <h2>Sorry, But We Couldn't Find That Page</h2>
                            <p>We're sorry, but we didn't find that page. The link you used may be out-of-date.</p>
                            <h3>Help us to help you ...</h3>
                            <p>In order to improve our service, you can inform us that someone else has an incorrect link to our site. We will do our best to notify them that this page has moved and ask them to update the link. <br /><strong>Just press the button!</strong></p>
                            <form onSubmit={sendError}>
                                <input
                                    type="hidden"
                                    name="User_Agent"
                                    defaultValue=""
                                />
                                <input
                                    type="hidden"
                                    name="ALL_HTTP"
                                    defaultValue=""
                                />
                                <input
                                    type="hidden"
                                    name="Referring_Site"
                                    defaultValue=""
                                />
                                <input
                                    type="hidden"
                                    name="Page_Requested"
                                    defaultValue=""
                                />
                                <input
                                    type="hidden"
                                    name="Error_Type"
                                    defaultValue=""
                                />
                                <input
                                    type="submit"
                                    name="cmdSubmit"
                                    className="btn btn-warning"
                                    value="Report this broken link >>"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFoundPage;
