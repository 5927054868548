import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import NumberInput from "../../admintool9x/Common/NumberInput";
import DynamicCharacterCountInputText from '../../admintool9x/Common/CharacterCountTextarea';

const ContactUsForm = ({ btnClick, loading, recaptchaRef, currentUser }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>

      <Form id='myForm'>

        <p>Contact us today if you have a question, comment, suggestion or praise to pass along.</p>

        <div className="mb-3 row">
          <div className="col-sm-12 controls">
            <DynamicCharacterCountInputText className="form-control" rows="5" nameattr={"message"} tabIndex={10} placeholderattr="Your Comment" />
          </div>
        </div>
        {!currentUser &&
          <>
            <div className="mb-2 row">
              <label htmlFor="name" className="col-md-2">
                Name
              </label>
              <div className="col-md-3">
                <Field
                  type="text"
                  id="name"
                  tabIndex={9}
                  maxLength={50}
                  name="name"
                  placeholder="Your Full Name"
                  className="form-control"
                />
              </div>
            </div>

            <div className="mb-2 row">
              <label htmlFor="phone" className="col-md-2">
                Phone
              </label>
              <div className="col-md-3">
                <NumberInput classNameAtr="form-control" tabIndexAtr={15} maxLength="13" nameAtr="phone" placeholderAtr="Your Phone Number" />
              </div>
            </div>

            <div className="mb-2 row">
              <label htmlFor="email" className="col-md-2">
                Email
              </label>
              <div className="col-md-3">
                <Field
                  type="text"
                  tabIndex={20}
                  maxLength={50}
                  name="email"
                  placeholder="Your Email Address"
                  className="form-control"
                />
              </div>
            </div>

            <div className="mb-2 row">
              <label htmlFor="city" className="col-md-2">
                City, State
              </label>
              <div className="col-md-3">
                <Field
                  type="text"
                  id="city"
                  tabIndex={25}
                  maxLength={50}
                  name="city"
                  placeholder="Your City, State"

                  className="form-control"
                />
              </div>
            </div>
          </>
        }
        <div className="mb-2 row">
          <label htmlFor="" className="col-md-2">
            Verify Code
          </label>
          <div className="col-md-3">
            <span id="divLoading">
              <ReCAPTCHA
                ref={recaptchaRef}
                tabIndex={35}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                onChange={(value) => setFieldValue('recaptcha', value)}
              />
            </span>
          </div>
        </div>


        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={45}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              Submit
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={btnClick}
            tabIndex={50}
          >close</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default ContactUsForm
