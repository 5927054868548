import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { Button, ListGroup, Modal, Spinner } from 'react-bootstrap';
import LogFileForm from './LogFileForm';


const LogFile = () => {
    const location = useLocation();
    
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [directoryDomnain, setDirectoryDomnain] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    
    const [showModal, setShowModal] = useState(false);
    const [fileContent, setFileContent] = useState('');

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            setMetaData({ "title": "Logs Html File" })

            api.post('/admin-get-subscriptions-files', {
                folder_name: "logs"
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data.results);
                    setDirectoryDomnain(response.data.directoryDomnain);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, [location]); // Run once on mount

    const handleOpenFile = (path) => {
        // Handle file opening (e.g., show file content or download)


        dispatch(startLoading());
        api.post('/admin-read-subscriptions-file', {
            path: path
        }).then(response => {
            if (response.data.msg === 1) {
                setFileContent(response.data.success);
                setShowModal(true);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

    };

    const handleDeleteFile = async (path) => {

        if (window.confirm("Are you sure to delete")) {
            dispatch(startLoading());
            api.post('/admin-delete-subscriptions-files', {
                path: path
            }).then(response => {
                if (response.data.msg === 1) {
                    const newResult = deleteFromResult(result, path);
                    setResult(newResult);
                    dispatch(setMessage('File deleted successfully'));
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                dispatch(setMessage('Failed to delete file'));
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        }
    };

    const deleteFromResult = (result, pathToDelete) => {
        return result.filter(item => {
            if (item.path === pathToDelete) {
                return false; // Remove this item
            }
            if (item.subItems) {
                item.subItems = deleteFromResult(item.subItems, pathToDelete);
            }
            return true; // Keep this item
        });
    };
    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row'>
                <div className='col-12'>
                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />

                    <ListGroup className="mt-2">
                        {result.map((item) => (
                            <LogFileForm key={item.path} item={item} onOpen={handleOpenFile} onDelete={handleDeleteFile} directoryDomnain={directoryDomnain} />
                        ))}
                    </ListGroup>
                </div>
            </div>
            {loading && (
                <div className="spinner-overlay">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>File Content</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4' style={{
                    overflowY: "auto",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    padding: "1rem"
                }}>
                    <pre>{fileContent}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default LogFile
