import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import LoginForgetPasswordForm from './LoginForgetPasswordForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const LoginForgetPassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email address cannot be empty'),
        // recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
    });

    const loading = useSelector(selectLoading);
    const recaptchaRef = useRef(null);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    // const { id } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const [initialValues, setInitialValues] = useState({
        email: "",
        recaptcha: ""
    });

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            if (isLoggedIn) {
                navigate(routeConfig.accessdenied());
            }
            setMetaData({ "title": "Forgot Password?" })
        } else {
            didMountRef.current = "true";
        }
    }, []);


    const handleSubmit = async (values, { setSubmitting }) => {
        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();

        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;

        dispatch(startLoading());

        api.post('/auth/forgotpassword-save', {
            values: trimmedValues,
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.accessdenied() + "?email=" + values.email)
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h2>
                Forgot Password?
            </h2>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                recaptchaRef={recaptchaRef}
            >
                <LoginForgetPasswordForm btnClick={btnClick} loading={loading} recaptchaRef={recaptchaRef} />
            </Formik>


        </>
    )
}

export default LoginForgetPassword
