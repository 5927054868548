import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';


const IPOListDayTradeForm = ({ result, handleGoBack, initialValues, loading }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>

      <Form id='myForm'>
        {result !== undefined && result.ipoListingDayTrade !== undefined && result.ipoListingDayTrade.length > 0 &&
          <div className="mb-3 row">
            <label htmlhtmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.ipoListingDayTrade[0].id}
            </div>
          </div>
        }


        <div className="mb-3 row">

          {result !== undefined && result.ipoListingDayTrade !== undefined && result.ipoListingDayTrade.length > 0 &&
            <div className="mb-3 row">
              <label htmlFor="company_name" className="col-lg-2">Company Name</label>
              <div className="col-lg-4">
                {result.ipoListingDayTrade[0].company_name}
              </div>
            </div>
          }

        </div>
        <div className="mb-4 row">
          <label htmlFor="" className="col-lg-2 ">Stock Exchange</label>
          <div className="col-lg-10 d-flex align-items-center">
            {result.stockExchangeList !== undefined && result.stockExchangeList.map((option) => (
              <div key={option.code} className="d-inline">
                <Field type="radio" className="form-check-input" tabIndex={5} name="stock_exchange_cd" value={option.code} />&nbsp;{option.code} &nbsp;&nbsp;&nbsp;&nbsp;

              </div>
            ))}
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="open_price" className="col-lg-2 ">Open Price</label>
          <div className="col-lg-6">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={10} nameAtr="open_price" maxLength="10" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">High Price</label>
          <div className="col-lg-6">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={15} nameAtr="high_price" maxLength="10" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">Low Price</label>
          <div className="col-lg-6">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={20} nameAtr="low_price" maxLength="10" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">Close Price</label>
          <div className="col-lg-6">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={25} nameAtr="close_price" maxLength="10" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">Volume Traded</label>
          <div className="col-lg-6">
            <NumberInput classNameAtr="form-control" type="text" tabIndexAtr={30} nameAtr="volume_traded" maxLength="10" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">Display status</label>
          <div className="col-lg-6">
            <Field type="checkbox" className="form-check-input" tabIndex={35} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>
        
        <div className="mb-3 text-center">
          {initialValues && initialValues.lock_status !== 1 &&
            (result.ipoListingDayTrade !== undefined && result.ipoListingDayTrade.length > 0) && (
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
                tabIndex={40}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                <span>
                  Update
                </span>
              </button>
            )
          }

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={45}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>

    </>
  )
}

export default IPOListDayTradeForm





