import React, { useState, useEffect } from "react";
import { Formik, Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";

const BrokerComplaintUploadForm = ({
  result,
  handleGoBack,
  loading,
}) => {

  return (
    <Form id="myForm">

      <div className="mb-3 row">

        <label htmlFor="exchange" className="col-lg-2">Exchange</label>

        <div className="col-lg-4">
          <Field as="select" name="exchange" className="form-select" tabIndex={1}>
            <option value=""></option>
            <option value="BSE">BSE</option>
            <option value="NSE">NSE</option>
            <option value="MCX">MCX</option>
          </Field>
        </div>
        <label htmlFor="financial_year" className="col-lg-2">Financial Year</label>
        <div className="col-lg-4">
          <Field as="select" name="financial_year" className="form-select" tabIndex={5}>
            <option value=""></option>
            <option value="2024-25">2024-25</option>
            <option value="2025-26">2025-26</option>
          </Field>
        </div>

      </div>
      <div className="mb-3 row">
        <label htmlFor="clientdata" className="col-lg-2">Data</label>
        <div className="col-lg-10">
          <Field as="textarea" className="form-control" name="clientdata" rows="10" tabIndex={10} />
        </div>
      </div>

      <div>
        <p><strong>Links to download files</strong></p>
        <ul>
          <li><a className="text-decoration-none" href="http://www.bseindia.com/investors/invgrievstats.aspx" target="_blank">BSE</a> - Redressal of complaints lodged by clients against Trading Members</li>
          <li><a className="text-decoration-none" href="https://www.nseindia.com/invest/arbitration-status" target="_blank">NSE</a> - Report 1C - Complaints received from clients against trading members during the current financial year</li>
        </ul>
        <p><strong>Note:</strong></p>
        <ul>
          <li>Keep the Excel File Columns as they are received from the exchange.</li>
          <li>Rows with UCC Count as 0 are not uploaded.</li>
        </ul>
      </div>

      <hr />
      <div className="mb-3 text-center">
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={loading}
          tabIndex={15}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm me-2"></span>
          )}
          <span>
            {result.book !== undefined && result.book.length > 0
              ? "Update"
              : "Create"}
          </span>
        </button>

        <button
          type="button"
          className="btn btn-secondary btn-block ms-2"
          onClick={handleGoBack}
          tabIndex={20}
        >
          Cancel
        </button>
      </div>

      <ValidateFormFields />
    </Form>
  );
};

export default BrokerComplaintUploadForm;
