import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import BrokerRatingForm from './BrokerRatingForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { closePopUpWindow, funCloseWebWindow, isNumeric } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';

const BrokerRating = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    var topicId = queryParams.get("a") ?? "";
    var topicType = queryParams.get("b") ?? "";

    const { user: currentUser } = useSelector((state) => state.auth);

    const validationSchema = (user) => {
        const schema = {
            rate_brokerage: Yup.string().trim().required("Please select rating for 'Brokerage'.").notOneOf([''], "Please select rating for 'Brokerage'."),
            rate_customer_service: Yup.string().trim().required("Please select rating for 'Customer Service'.").notOneOf([''], "Please select rating for 'Customer Service'."),
            rate_fees: Yup.string().trim().required("Please select rating for 'Yearly Maintenance Fees'.").notOneOf([''], "Please select rating for 'Yearly Maintenance Fees'."),
            rate_usability: Yup.string().trim().required("Please select rating for 'Website Usability'.").notOneOf([''], "Please select rating for 'Website Usability'."),
            rate_research_capabilities: Yup.string().trim().required("Please select rating for 'Research Capabilities'.").notOneOf([''], "Please select rating for 'Research Capabilities'."),
            rate_overall: Yup.string().trim().required("Please select rating for 'Overall Experiance'.").notOneOf([''], "Please select rating for 'Overall Experiance'."),
            message_content: Yup.string().trim().required("Please enter Feedback."),
            author_name: user ? "" : Yup.string().trim().required('Your Name is required'),
            author_email: user ? "" : Yup.string().trim().email('Invalid email address').required('Your Email is required'),
            author_phone: user ? "" : Yup.string().trim().required('Your Phone No is required'),
            recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
        };
        return Yup.object().shape(schema);
    };

    const loading = useSelector(selectLoading);
    const recaptchaRef = useRef(null);
    const dispatch = useDispatch();
    const [result, setResult] = useState({
        pageDataLoaded: 0,
    });
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        author_name: "",
        author_email: "",
        author_phone: "",
        rate_brokerage: '3',
        rate_customer_service: '3',
        rate_fees: '3',
        rate_usability: '3',
        rate_research_capabilities: '3',
        rate_overall: '3',
        message_content: '',
        author_name: '',
        author_email: '',
        securityCode: '',
        recaptcha: '',
    });
    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            if (topicId == "" || topicType == "") {
                navigate(routeConfig.submission() + `?a=STFR&ref=` + ref);
            }
            if (!isNumeric(topicId)) {
                navigate(routeConfig.submission() + `?a=STFR&ref=` + ref);
            }
            if (!["stock", "commodity"].includes(topicType)) {
                navigate(routeConfig.submission() + `?a=STFR&ref=` + ref);
            }
            dispatch(startLoading());

            api.post('/ratebroker-read', {
                values: {
                    company_id: topicId,
                    company_type: topicType
                }
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult({
                        ...response.data,
                        pageDataLoaded: 1
                    });
                    setMetaData({ "title": `Rate ${response.data.companyName} (${response.data.companyTypeDisplay})` })
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = async (values, { setSubmitting }) => {

        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();

        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        dispatch(startLoading());

        trimmedValues.recaptchaToken = token;
        trimmedValues.company_id = topicId;
        trimmedValues.company_type = topicType;

        api.post('/ratebroker-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=STFR&ref=` + ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <div className='row main mt-2'>
                <div className='col-12'>
                    <h1>
                        {metaData.title}
                    </h1>
                    <hr />

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema(currentUser)}
                        onSubmit={handleSubmit}
                        recaptchaRef={recaptchaRef}
                    >
                        <BrokerRatingForm result={result} currentUser={currentUser} btnClick={btnClick} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} />
                    </Formik>
                </div>
            </div>

        </>
    )
}

export default BrokerRating
