import React from 'react'
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';

const NCDCouponRateForm = ({ remove, result, setCouponDataLength }) => {

    const { values, setFieldValue } = useFormikContext();

    const inputSetInTable = (event) => {
        console.log(event)
        if (event.target.value != ".") {
            if (event.target.value !== "") {
                setFieldValue(event.target.name, values.digitInput * event.target.value)
            } else {
                setFieldValue(event.target.name, "")
            }
        }
    }

    const updateSeriesValues = (removedIndex) => {
        const updatedCouponData = values.couponData.map((coupon, index) => {
            if (index !== removedIndex) {
                if (index < removedIndex) {
                    return coupon;
                }
                return {
                    ...coupon,
                    series: (parseInt(coupon.series) - 1).toString(),
                };
            }
            return null;
        }).filter(Boolean);
        setFieldValue("couponData", updatedCouponData);
        setCouponDataLength(updatedCouponData.length);
    };

    const periodList = [
        {
            "code": "Days",
            "code_desc": "Days"
        },
        {
            "code": "Months",
            "code_desc": "Months"
        },
        {
            "code": "Years",
            "code_desc": "Years"
        }
    ]

    return (
        <>

            {values.couponData.map((NCDCoupon, index) => (
                <tr key={index}>

                    <td>
                        <Field className="form-control dateInput" type="input" maxLength="15"
                            name={`couponData.${index}.series`} />
                    </td>

                    <td>
                        <Field as="select" name={`couponData.${index}.frequency_of_interest_payment`} className="form-select" >
                            <option></option>
                            {result.frequencyOfPaymentList !== undefined && result.frequencyOfPaymentList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </td>

                    <td>
                        <Field as="select" name={`couponData.${index}.nature`} className="form-select" >
                            <option></option>
                            {result.couponNatureList !== undefined && result.couponNatureList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </td>

                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`couponData.${index}.tenor`} placeholder="Tenor" dynamicOnBlur={inputSetInTable} />
                    </td>

                    <td>
                        <Field as="select" name={`couponData.${index}.tenor_unit`} className="form-select" >
                            <option></option>
                            {periodList !== undefined && periodList.map((option) => (
                                <option key={option.code_desc} value={option.code_desc}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </td>

                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`couponData.${index}.coupon_rate`} placeholder="Coupon Rate" dynamicOnBlur={inputSetInTable}
                            dynamicOnChange={(e) => {
                                if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined && parseFloat(e.target.value) < 0) {
                                    setFieldValue(e.target.name, "");
                                    alert("The values in coupon(%) per annum cannot be negetive");
                                    return;
                                }
                            }} />
                    </td>

                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`couponData.${index}.effective_yield`} placeholder="Effective Yield" dynamicOnBlur={inputSetInTable}
                            dynamicOnChange={(e) => {
                                if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined && parseFloat(e.target.value) < 0) {
                                    setFieldValue(e.target.name, "");
                                    alert("The values in effective yield(% per annum) cannot be negetive");
                                    return;
                                }
                            }} />
                    </td>

                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="15"
                            nameAtr={`couponData.${index}.amount_maturity`} placeholder="Maturity Amount" dynamicOnBlur={inputSetInTable} />
                    </td>

                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`couponData.${index}.display_status`} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue(e.target.name, newValue);
                                }}
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => {
                            remove(index);
                            updateSeriesValues(index); // Call function to update series values
                        }}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default NCDCouponRateForm
