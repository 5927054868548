import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import SQLEditorForm from './SQLEditorForm';
import { Formik } from 'formik';

const SQLEditor = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const api = useApi();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        sqlquery: "",
    });

    const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            if (![62870, 126].includes(currentUser.id)) {
                navigate("/admintool9x")
            }
        } else {
            didMountRef.current = "true";
        }
    }, []);


    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        if (trimmedValues.sqlquery.toLowerCase().includes('delete') || trimmedValues.sqlquery.toLowerCase().includes('update')) {
            if (window.confirm("Are you sure you want to execute this query?")) {
                confirmAction(trimmedValues);
            } else {
                setSubmitting(false);
            }
        } else {
            confirmAction(trimmedValues);
        }
        setSubmitting(false);
    };

    const confirmAction = (trimmedValues) => {
        dispatch(startLoading());

        api.post('/admin-sqleditor-query', {
            values: trimmedValues
        }
        ).then(response => {

            if (response.data.msg === 1) {
                console.log(response.data.result)
                setResult(response.data.result); // Update the result state variable
                dispatch(setMessage(response.data.success));
                // navigate(-1);
            } else {
                setResult([]);
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
    };
    return (
        <>
            <Helmet>
                <title>Chittorgarh.com SQL Editor</title>
                <meta name="title" content="Chittorgarh.com SQL Editor" />
            </Helmet>

            <h1>
                Chittorgarh.com SQL Editor
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <SQLEditorForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>

            {(Array.isArray(result) && result.length > 0) ? (
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            {Object.keys(result[0]).map((columnName, index) => (
                                <th key={index}>{columnName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((rowData, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.values(rowData).map((cellData, cellIndex) => (
                                    <td key={cellIndex}>{cellData}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )
                :
                <>
                    {Object.keys(result).length > 0 &&
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(result).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </>
            }
        </>
    )
}

export default SQLEditor
