import React from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import NumberInput from "../Common/NumberInput";
import FileUpload from "../Common/FileUpload";
import CharacterCountTextarea from "../Common/CharacterCountTextarea";

const TradingCompanyMasterForm = ({ result, initialValues, handleGoBack, loading, isAddMode }) => {
	const { values, setFieldValue } = useFormikContext();

	return (
		<Form id="myForm">
			<div className="mb-3 row">
				<label htmlFor="id" className="col-lg-2">
					Trading Company ID
				</label>
				<div className="col-lg-2">
					{!isAddMode && (
						<Field
							type="number"
							className="form-control"
							name="id"
							tabIndex={5}
							readOnly={true}
						/>
					)}
				</div>

				<label htmlFor="last_update" className="col-lg-4">
					Last Updated : <b>{initialValues.last_modified_dt}</b>
				</label>
			</div>
			<div className="mb-3 row">
				<label htmlFor="company_type" className="col-lg-2 required">
					Company Type
				</label>
				<div className="col-lg-4">
					<Field
						as="input"
						className="form-control"
						type="text"
						name="company_type"
						tabIndex={10}
					/>
				</div>
				<div className="col-lg-6">i.e. Stock, Commodity</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="company_name" className="col-lg-2 required">
					Company Full Name
				</label>
				<div className="col-lg-4">
					<Field
						as="input"
						className="form-control"
						type="text"
						name="company_name"
						tabIndex={15}
					/>
				</div>
				<div className="col-lg-4">i.e. ICICI Securities Pvt Ltd.</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="company_short_name" className="col-lg-2">
					Company Short Name
				</label>
				<div className="col-lg-4">
					<Field
						as="input"
						className="form-control"
						type="text"
						tabIndex={20}
						name="company_short_name"
					/>
				</div>
				<div className="col-lg-6">i.e. ICICIDirect</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="image" className="col-lg-2">
					Choose an image:
				</label>
				<div className="col-lg-10">
					<FileUpload
						nameattr="file"
						namebase64attr="filebase64"
						imageField="logo_file_name"
						accept=".png, .jpg, .jpeg"
						capture="environment"
					/>
					<span className="text-muted">Accepted formats: PNG, JPG, JPEG</span>
				</div>
			</div>


			<div className="mb-3 row">
				<label htmlFor="logo_file_name" className="col-lg-2">
					Logo Image Name:
				</label>
				<div className="col-lg-3">
					<Field
						tabIndex={25}
						as="input"
						className="form-control"
						type="text"
						maxLength="100"
						name="logo_file_name"
					/>
				</div>
				<label htmlFor="logo_length" className="col-lg-1">
					Width:
				</label>
				<div className="col-lg-2">
					<NumberInput
						classNameAtr="form-control"
						tabIndexAtr={30}
						maxLength="3"
						nameAtr="logo_length"
					/>
				</div>
				<label htmlFor="logo_height" className="col-lg-1">
					Height:
				</label>
				<div className="col-lg-2">
					<NumberInput
						classNameAtr="form-control"
						tabIndexAtr={35}
						maxLength="3"
						nameAtr="logo_height"
					/>
				</div>
			</div>
			<hr />
			<legend>App Details</legend>
			<div className="mb-3 row">
				<label htmlFor="app_name" className="col-lg-2">
					App Name:
				</label>
				<div className="col-lg-3">
					<Field
						tabIndex={40}
						as="input"
						className="form-control"
						type="text"
						maxLength="50"
						name="app_name"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="google_play_store_link" className="col-lg-2">
					Google Play Store Link:
				</label>
				<div className="col-lg-4">
					<Field
						tabIndex={45}
						as="input"
						className="form-control"
						type="text"
						maxLength="200"
						name="google_play_store_link"
					/>
				</div>
				<label htmlFor="google_play_store_rating" className="col-lg-2">
					Google Play Store Rating:
				</label>
				<div className="col-lg-4">
					<Field
						tabIndex={50}
						as="input"
						className="form-control"
						type="text"
						maxLength="100"
						name="google_play_store_rating"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="apple_app_store_link" className="col-lg-2">
					Apple Play Store Link:
				</label>
				<div className="col-lg-4">
					<Field
						tabIndex={55}
						as="input"
						className="form-control"
						type="text"
						maxLength="200"
						name="apple_app_store_link"
					/>
				</div>
				<label htmlFor="apple_app_store_rating" className="col-lg-2">
					Apple Play Store Rating:
				</label>
				<div className="col-lg-4">
					<Field
						tabIndex={60}
						as="input"
						className="form-control"
						type="text"
						maxLength="100"
						name="apple_app_store_rating"
					/>
				</div>
			</div>
			<hr />
			<div className="mb-3 row">
				<label htmlFor="company_desc" className="col-lg-2">
					Company Description
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="company_desc"
						type="textarea"
						tabIndex="65"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="trading_platforms" className="col-lg-2">
					Trading Platforms
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="trading_platforms"
						type="textarea"
						tabIndex="70"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="account_types" className="col-lg-2">
					Account Types
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="account_types"
						type="textarea"
						tabIndex="75"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="brokerage_fees" className="col-lg-2">
					Brokerage Fees
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="brokerage_fees"
						type="textarea"
						tabIndex="80"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="how_to_open_account" className="col-lg-2">
					How to open account?
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="how_to_open_account"
						type="textarea"
						tabIndex="85"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="advantages" className="col-lg-2">
					Advantages
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="advantages"
						type="textarea"
						tabIndex="90"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="disadvantages" className="col-lg-2">
					Disadvantages
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="disadvantages"
						type="textarea"
						tabIndex="95"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="useful_links" className="col-lg-2">
					Useful Links
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="useful_links"
						type="textarea"
						tabIndex="100"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="others" className="col-lg-2">
					Others
				</label>
				<div className="col-lg-10">
					<CharacterCountTextarea
						nameattr="others"
						type="textarea"
						tabIndex="105"
						rows="5"
						className="form-control"
					/>
				</div>
			</div>
			<div className="mb-3 row">
				<label htmlFor="urlrewrite_folder_name" className="col-lg-2 requried">
					URL Rewrite Folder Name
				</label>
				<div className="col-lg-6">
					<Field
						tabIndex={110}
						as="input"
						className="form-control"
						type="text"
						name="urlrewrite_folder_name"
						maxLength="100"
					/>
				</div>
				<div className="col-lg-4">
					i.e. chittorgarh.com/<strong>zerodha</strong>/18/{" "}
					<font color="#ff0000">DO NOT CHANGE</font>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="display_order" className="col-lg-2">
					Display Order
				</label>
				<div className="col-lg-2">
					<NumberInput
						classNameAtr="form-control"
						tabIndexAtr={115}
						maxLength="5"
						nameAtr="display_order"
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="broker_calculator_tab" className="col-lg-2">
					Brokerage Calculator Tab
				</label>
				<div className="col-lg-1">
					<Field
						tabIndex={120}
						as="input"
						className="form-check-input"
						type="checkbox"
						name="broker_calculator_tab"
						onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue("broker_calculator_tab", newValue);
						}}
					/>
				</div>
			</div>

			<div className="mb-3 row">
				<label htmlFor="display_status" className="col-lg-2">
					Display Status
				</label>
				<div className="col-lg-1">
					<Field
						tabIndex={125}
						as="input"
						className="form-check-input"
						type="checkbox"
						name="display_status"
						onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue("display_status", newValue);
						}}
					/>
				</div>
				<label htmlFor="display_status" className="col-lg-2">
					Lock Status
				</label>
				<div className="col-lg-1">
					<Field
						tabIndex={125}
						as="input"
						className="form-check-input"
						type="checkbox"
						name="lock_status"
						onChange={(e) => {
							const newValue = e.target.checked;
							setFieldValue("lock_status", newValue);
						}}
					/>
				</div>
			</div>

			<div className="mb-3 text-center">
				{initialValues.lock_status !== 1 && (
					<button
						type="submit"
						className="btn btn-primary btn-block"
						disabled={loading}
						tabIndex={130}
					>
						{loading && (
							<span className="spinner-border spinner-border-sm me-2"></span>
						)}
						<span>
							{result.tradingCompany !== undefined && result.tradingCompany.length > 0 ? 'Update' : 'Create'}
						</span>
					</button>
				)}
				<button
					type="button"
					className="btn btn-secondary btn-block ms-2"
					onClick={handleGoBack}
					tabIndex={135}
				>Cancel</button>
			</div>

			<ValidateFormFields />
		</Form>
	);
};

export default TradingCompanyMasterForm;
