import React, {useEffect} from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const IPOAllotmentForm = ({ result, handleGoBack, loading, initialValues }) => {
    const { setFieldValue, setFieldTouched, values } = useFormikContext();

    useEffect(() => {
        if (result.registrarList) {
            const selectedRegistrarId = values.registrar_id;
            const selectedRegistrar = result.registrarList.find(registrar => registrar.id === parseInt(selectedRegistrarId));
    
            const selectedWebsite = selectedRegistrar ? selectedRegistrar.website : '';    
            // Log the corresponding website
            console.log("Corresponding website:", selectedWebsite);
            setFieldValue('website', selectedWebsite)
        }
    }, [values.registrar_id]);
       
    return ( 
        <>
            <Form id='myForm'>
            {result !== undefined && result.ipo !== undefined && result.ipo.length > 0 &&
            <div className="mb-3 row">
                <label htmlFor="" className="col-lg-2">ID:</label>
                <div className="col-lg-4">
                    {result.ipo[0].id}
                </div>
            </div>
            }                
                <div className="mb-3 row">
                    <label htmlFor="" className="col-lg-2">Company Name:</label>
                    <div className="col-lg-4">
                        {result && result.ipo && result.ipo.length > 0 && result.ipo[0].company_name}
                    </div>
                </div>

                <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">IPO Registrar</label>
            <div className="col-lg-3">
                <Field 
                    as="select" 
                    name="registrar_id" 
                    className="form-select" 
                    tabIndex={1} 
                >
                    <option value=""></option>
                    {result.registrarList !== undefined && result.registrarList.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.comp_name}
                        </option>
                    ))}
                </Field>
            </div>
            </div>

                <div className="mb-3 row">
                    
                    <label htmlFor="" className="col-lg-2">Website</label>
                    <div className="col-lg-3">
                        <Field as="input" className="form-control" type="text" size="55" maxLength="100" name="website" id="Website"/>
                    </div>

                    <div className="col-lg-1">
                        <Field as="input" type="button" name="cmdright" value=" >>" onClick={(e) => {
                            const websiteValue = document.getElementById('Website').value;
                            setFieldValue('ipo_allotment_url', websiteValue);
                        }} className="btn btn-sm"/><br/><br/>

                    </div>
                    <label htmlFor="" className="col-lg-2">IPO Allotment URL</label>
                    <div className="col-lg-3">
                        <Field as ='input' className="form-control" type="text" size="55" maxLength="100" name="ipo_allotment_url"
                            id="IPO_Allotment_URL" readOnly/>
                    </div>
                </div>

                <div className="mb-3 text-center">
                {loading || (result.ipo !== undefined && result.ipo.length > 0) ? (
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                    >
                        {loading && (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        {result.ipo !== undefined && result.ipo.length > 0 && 'Update'}
                    </button>
                    ) : null}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                    
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default IPOAllotmentForm
