
import React from 'react';
import { Field, useFormikContext } from 'formik';

const IpoMutualFundForm = ({  remove }) => {
    const { setFieldValue, values } = useFormikContext();

    
    let sr_no = 1;

    return (
        <>
            {values.anchorSchemeData &&  values.anchorSchemeData.map((item, index) => (
                <tr>
                    <td>
                        <Field className="form-control" maxLength="3" value={sr_no++} readOnly />
                    </td>
                    <td>
                        <Field type="text" name={`anchorSchemeData.${index}.scheme_name`} className="form-control" />
                    </td>
                    
                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`anchorSchemeData.${index}.display_status`} onChange={(e) => {
                                    setFieldValue(`anchorSchemeData.${index}.display_status`, e.target.checked)
                                }
                                }
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default IpoMutualFundForm;