import { useFormikContext } from 'formik';
import useApi from '../../../common/useApi';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../state/slices/message';
import { Link } from 'react-router-dom';

const MessagesTable = ({ list, id, callMemberData }) => {
    const { values } = useFormikContext();
    const api = useApi();
    const dispatch = useDispatch();

    const handleDisable = (category) => {
        if (window.confirm("Are you sure you want to disable?")) {
            api.post('/admin-member-message-disable-action', {
                "values": {
                    "id": id,
                    "category_cd": category
                }
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    callMemberData();
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
            });
        }
    }

    return (
        <>

            <table className="table table-bordered table-striped" id="repeater1" tabIndex={25}>
                <thead>
                    <tr className="bg-secondary text-white">
                        <th style={{ width: "60%" }}>Messages</th>
                        <th style={{ width: "10%" }}>Enabled</th>
                        <th style={{ width: "10%" }}>Disabled</th>
                        <th style={{ width: "10%" }}>Liked Count</th>
                        <th style={{ width: "20%" }}>Action</th>
                    </tr>
                </thead>

                {values[list].length > 0 ? (
                    <tbody>
                        <tr>
                            <td>
                                <div><Link to={`/admintool9x/report/133/?page=1&search_f.member_id=${id.toString()}&search_exact=1`}><p>IPO</p></Link></div>
                            </td>
                            <td>
                                <div>{values[list][0].EnabledIPOMsgs}</div>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledIPOMsgs}</p>
                            </td>
                            <td>
                                <p>{values[list][0].IPOLikedCount}</p>
                            </td>
                            <td>
                                <a href="#" style={{ color: 'red' }} onClick={() => handleDisable('ipo')}>Disable All</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div><Link to={`/admintool9x/report/61/1/?page=1&search_f.member_id=${id.toString()}&search_exact=1`}><p>Feedback</p></Link></div>
                            </td>
                            <td>
                                <div>{values[list][0].EnabledMsgs}</div>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledMsgs}</p>
                            </td>
                            <td>
                                <p>{values[list][0].FeedbackLikedCount}</p>
                            </td>
                            <td>
                                <a href="#" style={{ color: 'red' }} onClick={() => handleDisable('feedback')}>Disable All</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div><Link to={`/admintool9x/report/111/?page=1&search_member_id=${id.toString()}&search_exact=1`}><p>Archive</p></Link></div>
                            </td>
                            <td>
                                <div>{values[list][0].EnabledArchiveMsgs}</div>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledArchiveMsgs}</p>
                            </td>
                            <td>
                                <p>{values[list][0].ArchiveLikedCountIPO}</p>
                            </td>
                            <td>
                                <a href="#" style={{ color: 'red' }} onClick={() => handleDisable('archive')}>Disable All</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div><Link to={`/admintool9x/report/134/?page=1&search_f.member_id=${id.toString()}&search_exact=1`}><p>Grey Market</p></Link></div>
                            </td>
                            <td>
                                <div>{values[list][0].EnabledGMPMsgs}</div>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledGMPMsgs}</p>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledGMPLikedCount ?? 0}</p>
                            </td>
                            <td>
                                <a href="#" style={{ color: 'red' }} onClick={() => handleDisable('gmp')}>Disable All</a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div><Link to={`/admintool9x/report/146/?page=1&search_member_id=${id.toString()}&search_exact=1`}><p>Recommendation</p></Link></div>
                            </td>
                            <td>
                                <div>{values[list][0].EnabledRecommendationMsgs}</div>
                            </td>
                            <td>
                                <p>{values[list][0].DisabledRecommendationMsgs}</p>
                            </td>
                            <td>
                                <p>{values[list][0].IpoRecommendationLikedCount}</p>
                            </td>
                            <td>
                                <a href="#" style={{ color: 'red' }} onClick={() => handleDisable('recommendation')}>Disable All</a>
                            </td>
                        </tr>
                    </tbody>

                ) :
                    (
                        <tbody>
                            <tr>
                                <td colSpan={6}>
                                    <p>No data found</p>
                                </td>
                            </tr>
                        </tbody>
                    )}


            </table>
        </>
    )
}

export default MessagesTable;

