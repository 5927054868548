import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import ReCAPTCHA from 'react-google-recaptcha';

const LoginForgetPasswordForm = ({ btnClick, loading, recaptchaRef }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Form id='myForm'>

        <div className="row main">
          <div className="col-sm-12">
            <p>
              Please fill the below information. Your password will be send you
              in your email.
            </p>
            <div className="mb-2 row">
              <label htmlFor="email" className="col-sm-2">
                Email
              </label>
              <div className="col-sm-4">
                <Field
                  type="text"
                  id="email"
                  maxLength="100"
                  name="email"
                  placeholder="Your Email"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label htmlFor="" className="col-sm-2">
                Verify Code
              </label>
              <div className="col-sm-10">
                <span id="divLoading">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    tabIndex={35}
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                    onChange={(value) => setFieldValue('recaptcha', value)}
                  />
                </span>
              </div>
            </div>

          </div>
        </div>
        <hr />
        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-success btn-block"
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              Request Password
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={btnClick}
          >Close</button>
        </div>
        <ValidateFormFields />
      </Form>

    </>
  )
}

export default LoginForgetPasswordForm
