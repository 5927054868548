import React, { useState, useEffect } from "react";
import { Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";
import CharacterCountInput from "../Common/CharacterCountInput";
import CharacterCountTextarea from "../Common/CharacterCountTextarea";

const AlertForm = ({
  result,
  handleGoBack,
  initialValues,
  handleSubmit,
  loading,
}) => {
  const { setFieldValue } = useFormikContext();

  const [disabledFields, setDisabledFields] = useState({
    mobileFields: true,
    homepageFields: true,
  });

  useEffect(() => {
    const istTime = new Date(new Date().getTime() + 330 * 60 * 1000);
    const formattedDate = istTime.toISOString().slice(0, 16);
    const dt = formattedDate.slice(0, 10);
    const time = formattedDate.slice(11, 16);
    if (initialValues.send_dt === "") setFieldValue("send_dt", dt);
    if (initialValues.send_time === "") setFieldValue("send_time", time);
  }, []);

  return (
    <>
      <Form id="myForm">
        {result !== undefined &&
          result.alert !== undefined &&
          result.alert.length > 0 && (
            <div className="mb-3 row">
              <label className="col-lg-2">Alert ID:</label>
              <div className="col-lg-4">{result.alert[0].id}</div>
            </div>
          )}

        <div className="mb-3 row">
          <label className="col-lg-2 required">Type:</label>
          <div className="col-sm-8">
            <div className="form-check">
              <Field
                className="form-check-input alert_type_checkbox"
                type="checkbox"
                name="homepage_alert"
                tabIndex="5"
                id="homepage_alert"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue("homepage_alert", newValue);
                  setDisabledFields((prevState) => ({
                    ...prevState,
                    homepageFields: !newValue,
                  }));
                }}
              />
              <label htmlFor="homepage_alert" className="form-check-label">
                Home Page Alert
              </label>
            </div>
            <div className="form-check">
              <Field
                className="form-check-input alert_type_checkbox"
                type="checkbox"
                name="mobile_alert"
                tabIndex="10"
                id="mobile_alert"
                onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue("mobile_alert", newValue);
                  setDisabledFields((prevState) => ({
                    ...prevState,
                    mobileFields: !newValue,
                  }));
                }}
              />
              <label htmlFor="mobile_alert" className="form-check-label">
                Mobile Alert
              </label>
            </div>
          </div>
        </div>

        <hr />
        <div className="mb-3 row">
          <label className="col-lg-2 required">Title:</label>
          <div className="col-lg-6">
            <CharacterCountInput
              nameattr="alert_title"
              type="text"
              maxLength="250"
              className="form-control"
            />
          </div>
          <label className="col-lg-2">
            <small>(Max Length 250 - Text)</small>
          </label>
        </div>

        <div className="mb-3 row">
          <label htmlFor="alert_desc" className="col-lg-2 required">
            Body:{" "}
          </label>
          <div className="col-lg-6">
            <CharacterCountTextarea
              nameattr="alert_desc"
              maxLength="500"
              type="textarea"
              rows="5"
              className="form-control"
            />
          </div>
          <label className="col-lg-2">
            <small>(Max Length 500 - Text)</small>
          </label>
        </div>

        <div className="mb-3 row">
          <label htmlFor="alert_url" className="col-lg-2 required">
            URL:
          </label>
          <div className="col-lg-4">
            <CharacterCountInput
              nameattr="alert_url"
              type="text"
              maxLength="250"
              tabIndex="25"
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="alert_image" className="col-lg-2">
            Alert Image:
          </label>
          <div className="col-lg-4">
            <Field
              className="form-control"
              type="text"
              name="alert_image"
              maxLength="1000"
              tabIndex="30"
            />
          </div>
        </div>

        <hr />

        <h3>For Mobile Notifications</h3>
        <div className="mb-3 row">
          <label htmlFor="alert_type" className="col-lg-2">
            Open link in the app or outside?
          </label>
          <div className="col-lg-4">
            <Field
              as="select"
              name="alert_type"
              className="form-select"
              tabIndex="35"
              disabled={disabledFields.mobileFields}
            >
              <option value=""></option>
              {result.mobileNotificationType !== undefined &&
                result.mobileNotificationType.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code_desc}
                  </option>
                ))}
            </Field>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="send_dt" className="col-lg-2 required">
            Mobile alert send Time (IST):
          </label>
          <div className="col-lg-2 col-sm-4">
            <div className="input-group date">
              <Field
                type="date"
                name="send_dt"
                className="form-control mobile_fields"
                tabIndex="40"
                disabled={disabledFields.mobileFields}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div className="input-group date">
              <Field
                type="time"
                name="send_time"
                className="form-control mobile_fields"
                tabIndex="41"
                disabled={disabledFields.mobileFields}
              />
            </div>
          </div>
        </div>
        <hr />

        <h3>For Home Page alert</h3>
        <div className="mb-3 row">
          <label htmlFor="highlight_status" className="col-lg-2">
            Highlight the alert?
          </label>
          <div className="col-lg-4">
            <Field
              type="checkbox"
              className="form-check-input homepage_fields"
              tabIndex="45"
              name="highlight_status"
              disabled={disabledFields.homepageFields}
              onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue("highlight_status", newValue);
              }}
            />
            <label className="form-check-label ms-2">
              <mark> Highlight the alert like this.</mark>
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="sticky_status" className="col-lg-2">
            Sticky alert?
          </label>
          <div className="col-lg-4">
            <Field
              type="checkbox"
              className="form-check-input homepage_fields"
              name="sticky_status"
              tabIndex="50"
              disabled={disabledFields.homepageFields}
              onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue("sticky_status", newValue);
              }}
            />
            <label className="form-check-label ms-2">
              Stick this alert on top.
            </label>
          </div>
        </div>
        <hr />

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2">
            Display Status
          </label>
          <div className="col-lg-4">
            <Field
              className="form-check-input"
              type="checkbox"
              name="display_status"
              tabIndex="60"
              onChange={(e) => {
                const newValue = e.target.checked;
                setFieldValue("display_status", newValue);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="mb-3 text-center">
          <button
            tabIndex="70"
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              {result.alert !== undefined && result.alert.length > 0
                ? "Update"
                : "Create"}
            </span>
          </button>

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
          >
            Cancel
          </button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  );
};

export default AlertForm;
