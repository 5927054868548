import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { closePopUpWindow, funCloseWebWindow } from '../admintool9x/Common/CommonFunction';
import routeConfig from '../../routeConfig';

const Submission = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var pagename = queryParams.get("a") ?? "";
  var main_ref = queryParams.get("main_ref") ?? "";
  useEffect(() => {
    const loadGPTScript = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.onload = initializeGoogletag; // Initialize googletag after script is loaded
      document.head.appendChild(script);
    };

    const initializeGoogletag = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined before defining it
        if (!window.googletag.pubads().getSlots().some(slot => slot.getSlotElementId() === 'div-gpt-ad-1643200909479-0')) {
          window.googletag.defineSlot('/22674677440/rectangle-submission-success-page', [[300, 250], [336, 280]], 'div-gpt-ad-1643200909479-0')
            .addService(window.googletag.pubads());
        }
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().collapseEmptyDivs();
        window.googletag.enableServices();
        window.googletag.display('div-gpt-ad-1643200909479-0');
      });
    };

    // Load GTM script when component mounts
    loadGPTScript();

    // Clean up function to remove the script from the DOM when component unmounts
    return () => {
      const script = document.querySelector('script[src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const btnClick = () => {
    if (main_ref != "") {
      funCloseWebWindow(main_ref);
    } else {
      closePopUpWindow();
    }
  }
  return (
    <>
      <Helmet>
        {/* Optionally include meta tags, title, and other scripts */}
      </Helmet>
      <div className="container-fluid">
        <div className="row text-center pt-2">
          <div className="col-sm-12 main">
            {/* Uncomment if you have FormTitle and Message variables */}
            {/* <h1>{FormTitle}</h1> */}
            {
              pagename === "registration" ? (
                <>
                  <p>Thank you for joining us.</p>
                  <p><span style={{ backgroundColor: "#ffff99" }}>Please check your email for your temporary password.</span></p>
                  <p>
                    <Link to={`${routeConfig.accessdenied()}`} className='btn btn-sm btn-success'>Login Now</Link>
                  </p>
                </>
              ) : pagename === "profile_update" ? (
                <p>Your information has been modified successfully.</p>
              ) : pagename === "contactus" ? (
                <p>Thank you for your interest in Chittorgarh.com.<br /> Your email is important to us and will be answered as soon as possible.</p>
              ) : pagename === "fp" ? (
                <p>Your login credential has been sent to you by email.</p>
              ) : <p>Thank you for your message.</p>
            }


            <div className="text-center">
              <button type="button" className="btn btn-xs btn-secondary mb-2" onClick={btnClick}>Go Back</button>
            </div>
            <div className="col-sm-12 main">
              <div id='div-gpt-ad-1643200909479-0' style={{ minWidth: 300, minHeight: 250 }}></div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center mt-2">
        <button type="button" className="btn btn-xs btn-success" onClick={btnClick}>Close Window</button>
      </p>
    </>
  );
};

export default Submission;
