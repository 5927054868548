import React, { useEffect, useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
// import ReCAPTCHA from "react-google-recaptcha";
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import { Link } from 'react-router-dom';
import { funFormatDateTime } from '../../admintool9x/Common/CommonFunction';

const IPORecommendationForm = ({ result, setResult, btnClick, loading, currentUser }) => {
  const { values, setFieldValue } = useFormikContext();
  const [holdPositionDisabled, setHoldPositionDisabled] = useState(true);
  const [subscribeDisabled, setSubscribeDisabled] = useState(true);

  const handleRecommendationChange = (e) => {
    const value = e.target.value;
    setFieldValue("recommendation", value);
    updateDropdownStates(value);
  };

  const handleIPOChange = (e) => {
    const value = e.target.value;
    setFieldValue("ipo_id", value);
    setResult((prevState) => ({
      ...prevState,
      ipo_id: value,
    }));
  }

  const updateDropdownStates = (value) => {
    if (value === 'subscribe' || value === 'mayapply') {
      setHoldPositionDisabled(false);
      setSubscribeDisabled(false);
    } else {
      setFieldValue("subscribe_at_price_band", "");
      setFieldValue("hold_position", "");
      setHoldPositionDisabled(true);
      setSubscribeDisabled(true);
    }
  };

  useEffect(() => {
    if (result.memberRecommendationDetails && Object.keys(result.memberRecommendationDetails).length > 0) {
      updateDropdownStates(values.recommendation);
    }
  }, [values.recommendation]);

  return (
    <>

      <Form id='myForm'>

        <div className="mb-2 row">
          <label htmlFor="name" className="col-md-2 required">
            Issuer Company:
          </label>
          <div className="col-md-3">
            <Field as="select" className="form-select" tabIndex={10} name="ipo_id" onChange={handleIPOChange}>
              <option value="">Select Issuer Company</option>
              {result.ipoList !== undefined && result.ipoList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.company_name}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="recommendation" className="col-md-2  required">
            Your recommendation
          </label>
          <div className="col-md-3">
            <Field as="select" className="form-select" tabIndex={20} name="recommendation" onChange={handleRecommendationChange}>
              <option value="">Select Recommendation</option>
              {result.recommendationType !== undefined && result.recommendationType.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="hold_position" className="col-md-2 required">
            Hold Position:
          </label>
          <div className="col-md-3">
            <Field as="select" className="form-select" tabIndex={30} name="hold_position" disabled={holdPositionDisabled}>
              <option value="">Select Position</option>
              {result.holdPosition !== undefined && result.holdPosition.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="subscribe_at_price_band" className="col-md-2 required">
            Subscribe at Price Band:
          </label>
          <div className="col-md-3">
            <Field as="select" className="form-select" tabIndex={40} name="subscribe_at_price_band" disabled={subscribeDisabled}>
              <option value="">Select Price Band</option>
              {result.subscribeAtPriceBand !== undefined && result.subscribeAtPriceBand.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="recommendation_desc" className="col-md-2 required">
            Rationals behind your recommendation:
          </label>
          <div className="col-md-3">
            <Field
              as="textarea"
              rows="5"
              cols="55"
              maxLength={500}
              tabIndex={50}
              name="recommendation_desc"
              className="form-control"
              placeholder="Your Recommendation"
            />
          </div>
        </div>

        {result.memberRecommendationDetails && Object.keys(result.memberRecommendationDetails).length > 0 &&
          <>
            <div className="mb-2 row">
              <div className="col-12">
                <p className="mb-0">Posted Date : {funFormatDateTime(result.memberRecommendationDetails.posted_date) ?? ""}</p>
              </div>
              <div className="col-12">
                <p>Last Updated Date: {funFormatDateTime(result.memberRecommendationDetails.last_updated_date) ?? ""}</p>
              </div>
            </div>
          </>
        }
        {/* <div className="mb-2 row">
          <label htmlFor="" className="col-md-2">

          </label>
          <div className="col-md-3">
            <span id="divLoading">
              <ReCAPTCHA
                ref={recaptchaRef}
                tabIndex={60}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                onChange={(value) => setFieldValue('recaptcha', value)}
              />
            </span>
          </div>
        </div> */}



        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-success btn-block"
            disabled={loading}
            tabIndex={70}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              Save My Recommendation
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={btnClick}
            tabIndex={80}
          >Close</button>
        </div>
        <ValidateFormFields />
      </Form>

      <ul>
        <li><Link to={`${process.env.REACT_APP_ALPHA_URL}ipo_recommendation_list.asp`} style={{ cursor: "pointer", backgroundColor: "#FFFF99" }}>Preview you recommendations ...</Link></li>
        <li>Please do not copy paste content from other borders or analysts.</li>
        <li>We reserve the right to remove any objectionable post without providing a reason.</li>
      </ul>
    </>
  )
}

export default IPORecommendationForm
