import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingPlatformForm from './TradingPlatformForm.js';

const TradingPlatform = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        website_cd: "",
        trading_company_id: "",
        platform_name: "",
        logo_file_name: "",
        logo_length: "",
        logo_height: "",
        owner: "",
        owner_description: "",
        description_brief: "",
        description: "",
        highlight_list: "",
        feature_list: "",
        feature_desc: "",
        shortcut_keys: "",
        faqs: "",
        demo: "",
        advantage_brief: "",
        advantage: "",
        disadvantage_brief: "",
        disadvantage: "",
        customer_feedback: "",
        feature_desktop: "",
        feature_web: "",
        feature_mobile: "",
        feature_api: "",
        feature_multi_broker: "",
        feature_equity: "",
        feature_currency_derivatives: "",
        feature_commodity: "",
        feature_ipo: "",
        feature_mutual_funds: "",
        feature_integrated_backoffice: "",
        feature_trade_from_chart: "",
        segments: "",
        segment_desc: "",
        products: "",
        product_desc: "",
        order_type: "",
        order_type_desc: "",
        chart_indicator_count: "",
        chart_type_count: "",
        chart_in_same_window_count: "",
        oms: "",
        rms: "",
        exchanges_supported: "",
        user_customization: "",
        reports: "",
        charges: "",
        broker_list: "",
        resources: "",
        urlrewrite_folder_name: "",
        display_order: "",
        lock_status: false,
        display_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/117"); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Trading Platform" })
            } else {
                setMetaData({ "title": "Update Trading Platform" })
            }
            api.post('/admin-tradingplatform-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.tradingplatform[0])
                    if (!isAddMode) {
                        setInitialValues(response.data.tradingplatform[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.tradingplatformNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        const myRegExp = /^[^\s][a-zA-Z0-9_-]+(\.[a-zA-Z0-9]+)?$/;
        if (trimmedValues.filebase64 && trimmedValues.logo_file_name != "") {
            const isValid = myRegExp.test(trimmedValues.logo_file_name);
            if (!isValid) {
                alert("Invalid file name \"" + trimmedValues.logo_file_name + "\". Make sure to upload file with name 'A-Z a-Z 1-9 0 . - _'");
                return;
            }
        }

        dispatch(startLoading());


        api.post('/admin-tradingplatform-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr className='mb-2' />
            {result !== undefined &&
                result.tradingplatform !== undefined &&
                result.tradingplatform.length > 0 &&
                result.tradingplatform[0].lock_status == 1 && (
                    <h3 className="text-danger mb-2">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <TradingPlatformForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />

        </>
    )
}

export default TradingPlatform
