import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ScriptEditForm from './ScriptEditForm';
import * as Yup from 'yup';

const ScriptEdit = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('Company Name is required'),
        urlrewrite_folder_name: Yup.string().trim().required('URLRewrite Folder Name is required'),
        bse_face_value: Yup.number().required('BSE Face value is required'),
        nse_face_value: Yup.number().required('NSE Face value is required'),
        nse_paid_up_value: Yup.number().required('NSE Paid Up Value is required'),              
        nse_market_lot: Yup.number().required('NSE market lot size is required'),              
        bse_instrument: Yup.string().trim().required("Invalid BSE Instrument"),              
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        ipo_id: "",
        bse_script_code: "",
        bse_script_id: "",
        bse_script_name: "",
        bse_script_status: "",
        bse_script_group: "",
        bse_face_value: "",
        bse_date_added: "",
        bse_isin: "",
        bse_industry: "",
        bse_instrument: "",
        nse_script_id: "",
        nse_isin: "",
        nse_script_name: "",
        nse_first_listing_date: "",
        nse_face_value: "",
        nse_paid_up_value: "",
        nse_market_lot: "",
        nse_industry: "",
        nse_date_added: "",
        company_name: "",
        urlrewrite_folder_name: "",
        display_status: 0,
        nse_series: "",
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New ScriptEdit" })
            } else {
                dispatch(startLoading());
                setMetaData({ "title": "Edit ScriptEdit" })

                api.post('/admin-listOfScript-read', {
                    id: id
                }).then(response => {

                    if (response.data.msg === 1) {
                        setResult(response.data);
                        console.log(response.data.listOfScript);
                        if (!isAddMode) {
                            setInitialValues(response.data.listOfScript[0]);
                        }
                    }
                    else {
                        dispatch(setMessage(response.data.error));
                    }
                })
                    .catch(error => {
                        console.error('Error:', error);
                    }).finally(() => {
                        dispatch(finishLoading());
                    });
            }
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-listOfScript-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ScriptEditForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
            </Formik>
        </>
    )
}

export default ScriptEdit
