
import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { isNumeric } from './CommonFunction';

const MultiSelectTransfer = ({ dataList, labelAttr, initialItemsNameAttr, selectedItemsNameAttr, selectedItemsIdsAttr, disabledAttr }) => {
    const [allInitialItems, setAllInitialItems] = useState([]);
    const [allSelectedItems, setAllSelectedItems] = useState([]);
    
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        if (dataList !== undefined) {
            setAllInitialItems(dataList);
            setFieldValue(initialItemsNameAttr, dataList.map(item => item.id));
        }
    }, [dataList]); // Run once on mount

    useEffect(() => {
        if (dataList !== undefined) {
            console.log("calling times : values[selectedItemsIdsAttr]")

            if (values[selectedItemsIdsAttr] && values[selectedItemsIdsAttr].length > 0) {

                const selectedItems = dataList.filter(item => values[selectedItemsIdsAttr].includes(item.id));
                setAllSelectedItems(selectedItems);
                setFieldValue(selectedItemsNameAttr, selectedItems.map(item => item.id));

                const filteredSourceOptions = dataList.filter(item => !values[selectedItemsIdsAttr].includes(item.id));
                setAllInitialItems(filteredSourceOptions);
                setFieldValue(initialItemsNameAttr, filteredSourceOptions.map(item => item.id));
            } else {
                setAllSelectedItems([]);
                setFieldValue(selectedItemsNameAttr, []);
                setAllInitialItems(dataList);
                setFieldValue(initialItemsNameAttr, dataList.map(item => item.id));
            }
        }
    }, [values[selectedItemsIdsAttr]]);

    const handleTransferClick = () => {
        
        var initialItems = values[initialItemsNameAttr];
        // initialItems = initialItems.map(item => parseInt(item));
        initialItems = initialItems.map(item => {

            if (typeof item !== 'string') {
                return item; // Return the item unchanged if it's not a string
            }

            const trimmedItem = item.trim();
            
            if (typeof dataList[0].id === 'number') {
                return parseInt(trimmedItem);
            } else if (typeof dataList[0].id === 'string') {
                return trimmedItem
            } else {
                return trimmedItem;
            }
        });

        const selectedInitialItems = allInitialItems.filter(option => initialItems.includes(option.id));
        setFieldValue(selectedItemsIdsAttr, [...values[selectedItemsIdsAttr], ...selectedInitialItems.map(item => item.id)]);

        // setAllSelectedItems([...allSelectedItems, ...selectedInitialItems]);
        // setAllInitialItems(allInitialItems.filter(item => !selectedInitialItems.some(selectedItem => selectedItem.id === item.id)));

        // setFieldValue(initialItemsNameAttr, values[initialItemsNameAttr].filter(item => !initialItems.includes(item.id)));
        // setFieldValue(selectedItemsNameAttr, [...values[selectedItemsNameAttr], ...initialItems]);


    };

    const handleTransferBackClick = () => {

        var selectedItems = values[selectedItemsNameAttr];
        // selectedItems = selectedItems.map(item => parseInt(item));
        selectedItems = selectedItems.map(item => {

            if (typeof item !== 'string') {
                return item; // Return the item unchanged if it's not a string
            }

            const trimmedItem = item.trim();

            if (typeof dataList[0].id === 'number') {
                return parseInt(trimmedItem);
            } else if (typeof dataList[0].id === 'string') {
                return trimmedItem
            } else {
                return trimmedItem;
            }
        });

        // const transferredInitialItems = allSelectedItems.filter(option => selectedItems.includes(option.id.toString()));

        setFieldValue(selectedItemsIdsAttr, values[selectedItemsIdsAttr].filter(item => !selectedItems.includes(item)));

        // setAllInitialItems([...allInitialItems, ...transferredInitialItems]);
        // setAllSelectedItems(allSelectedItems.filter(item => !transferredInitialItems.some(selectedItem => selectedItem.id === item.id)));

        // setFieldValue(selectedItemsNameAttr, values[selectedItemsNameAttr].filter(item => !selectedItems.includes(item.id)));
        // setFieldValue(initialItemsNameAttr, [...values[initialItemsNameAttr], ...selectedItems]);
    };

    return (
        <>
            <div className="mb-3 row">
                <label htmlFor={initialItemsNameAttr} className="col-lg-2">{labelAttr}</label>
                <div className="col-lg-3">
                    <Field as="select" name={initialItemsNameAttr} multiple className="form-select"
                        onChange={(e) => setFieldValue(initialItemsNameAttr, Array.from(e.target.selectedOptions, option => option.value))}>
                        {allInitialItems !== undefined && allInitialItems.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.comp_name}
                            </option>
                        ))}

                    </Field>
                </div>

                <div className="col-lg-2 text-center">
                    <div className="mt-2">
                        <button
                            type="button"
                            onClick={handleTransferClick}
                            disabled={dataList !== undefined && dataList.length === 0 || disabledAttr}
                            className="btn btn-sm btn-primary mb-2"
                        >
                            &gt;&gt;
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={handleTransferBackClick}
                            disabled={values[selectedItemsNameAttr] !== undefined && values[selectedItemsNameAttr].length === 0 || disabledAttr}
                            className="btn btn-sm btn-primary"
                        >
                            &lt;&lt;
                        </button>
                    </div>
                </div>

                <div className="col-lg-3">
                    <Field as="select" name={selectedItemsNameAttr} className="form-select" multiple
                        onChange={(e) => setFieldValue(selectedItemsNameAttr, Array.from(e.target.selectedOptions, option => option.value))}>
                        {allSelectedItems !== undefined && allSelectedItems.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.comp_name}
                            </option>
                        ))}
                    </Field>
                </div>
            </div>
        </>
    );
};

export default MultiSelectTransfer;
