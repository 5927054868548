import React, { useState, useEffect, useRef, useCallback } from "react";
import './Ipo.css';
import ValidateFormFields from "../Common/ValidateFormFields";
import CheckboxGroup from '../Common/CheckboxGroup';
import { useFormikContext, Field, Form } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';
import { funCreateSlug, isNumeric, isValidVariable } from '../Common/CommonFunction';
import useApi from '../../../common/useApi';
import DynamicCharacterCountInput from '../Common/CharacterCountInput';
import AutocompleteField from "../Common/AutocompleteField";
import { debounce } from 'lodash';


const IpoForm = ({ result, handleGoBack, initialValues, loading, stockExchage, setStockExchage, getIpoPrice }) => {
	const [blurState, setBlurState] = useState({
		isBlurring: false,
		blurredTextbox: null,
		blurredTextValue: null,
	});

	const api = useApi();
	const { values, setFieldValue, setFieldTouched } = useFormikContext();
	const prevCategoryRef = useRef()

	const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

	const [formState, setFormState] = useState({
		isCmRatingDisabled: false,
		mainlineCategoryType: true,
		isIssuePriceLHDisabled: false,
		sharesOfferedAnchorInvestorDisable: true,
		empReserveBid: true,
		shareHolderReserveBid: true,
		openCloseDateDisplayOrderMessage: '',
		boaDateDisplayOrderMessage: '',
		disableHNIData: ''
	})

	const [autoComplteOptions, setAutoComplteOptions] = useState([]);
	const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);

	const handleBlurWrapper = (e) => {
		if (!blurState.isBlurring) {
			setBlurState({ isBlurring: true, blurredTextbox: e.target.name, blurredTextValue: e.target.value });
		}
	};

	useEffect(() => {
		const handleBlurLogic = async () => {

			if (blurState.blurredTextbox == "issue_price_upper" || blurState.blurredTextbox == "issue_price_final") {
				await delay(100);
				console.log(`Processed blur for: ${blurState.blurredTextbox}`);

				const issueCategory = values.issue_category;

				if (issueCategory === 'SME') {
					let issuePriceFinal = parseFloat(values.issue_price_final) || 0;
					let issuePriceUpper = parseFloat(values.issue_price_upper) || 0;

					if (issuePriceFinal <= 0) {
						issuePriceFinal = (isNaN(issuePriceUpper) || issuePriceUpper <= 0) ? 0 : issuePriceUpper;
					}

					if (window.confirm('Are you sure you want to update Market Lot Size and Min. Order Quantity? ' + blurState.blurredTextbox)) {

						if (issuePriceFinal > 0) {
							const updatedMarketLotSize = calculateMarketLotSize(issuePriceFinal);
							setFieldValue('market_lot_size', updatedMarketLotSize);
							setFieldValue('minimum_order_quantity', updatedMarketLotSize);
						} else {
							setFieldValue('market_lot_size', '');
							setFieldValue('minimum_order_quantity', '');
							console.log('Invalid issuePriceFinal:', issuePriceFinal);
						}
					} else {
						console.log('Confirmation declined:', issuePriceFinal);
					}
				}
			}
			else if (blurState.blurredTextbox == "issue_size_fresh_in_amt") {
				let price = getIpoPrice();
				if (price > 0 && isNumeric(blurState.blurredTextValue)) {
					if (window.confirm("Are you want to update Fresh Issue Shares")) {
						setFieldValue("issue_size_fresh_in_shares", parseInt(blurState.blurredTextValue) / parseFloat(price));
					}
				}
			}
			else if (blurState.blurredTextbox == "issue_size_fresh_in_shares") {
				let price = getIpoPrice();
				if (price > 0 && isNumeric(blurState.blurredTextValue)) {
					if (window.confirm("Are you want to update Fresh Issue Amount")) {
						setFieldValue("issue_size_fresh_in_amt", parseInt(blurState.blurredTextValue) * parseFloat(price));
					}
				}
			}
			else if (blurState.blurredTextbox == "issue_size_ofs_in_amt") {
				let price = getIpoPrice();
				if (price > 0 && isNumeric(blurState.blurredTextValue)) {
					if (window.confirm("Are you want to update OFS Issue Shares")) {
						setFieldValue("issue_size_ofs_in_shares", parseInt(blurState.blurredTextValue) / parseFloat(price));
					}
				}
			}
			else if (blurState.blurredTextbox == "issue_size_ofs_in_shares") {
				let price = getIpoPrice();
				if (price > 0 && isNumeric(blurState.blurredTextValue)) {
					if (window.confirm("Are you want to update OFS Issue Amount")) {
						setFieldValue("issue_size_ofs_in_amt", parseInt(blurState.blurredTextValue) * parseFloat(price));
					}
				}
			}

			if (["issue_size_ofs_in_shares", "issue_size_fresh_in_shares"].includes(blurState.blurredTextbox)) {
				let price = getIpoPrice();
				if (price > 0 && window.confirm("Are you want to update Total Shares And Amount")) {
					let ofs_shares = document.getElementsByName('issue_size_ofs_in_shares')[0].value;
					let fresh_share = document.getElementsByName('issue_size_fresh_in_shares')[0].value;


					if (!isValidVariable(ofs_shares)) {
						ofs_shares = 0;
					}
					if (!isValidVariable(fresh_share)) {
						fresh_share = 0;
					}

					setFieldValue(
						'issue_size_in_shares', parseFloat(ofs_shares) + parseFloat(fresh_share)
					);

					setFieldValue(
						'issue_size_in_amt', parseFloat(ofs_shares) * parseFloat(price) + parseFloat(fresh_share) * parseFloat(price)
					);
				}
			}
			if (["issue_size_ofs_in_amt", "issue_size_fresh_in_amt"].includes(blurState.blurredTextbox)) {
				let price = getIpoPrice();
				if (price > 0 && window.confirm("Are you want to update Total Shares And Amount")) {
					let ofs_size = document.getElementsByName('issue_size_ofs_in_amt')[0].value;
					let fresh_size = document.getElementsByName('issue_size_fresh_in_amt')[0].value;

					if (!isValidVariable(ofs_size)) {
						ofs_size = 0;
					}
					if (!isValidVariable(fresh_size)) {
						fresh_size = 0;
					}

					setFieldValue(
						'issue_size_in_shares', (parseFloat(ofs_size) / parseFloat(price)) + (parseFloat(fresh_size) / parseFloat(price))
					);

					setFieldValue(
						'issue_size_in_amt', parseFloat(ofs_size) + parseFloat(fresh_size)
					);
				}
			}
			setBlurState({ isBlurring: false, blurredTextbox: null, blurredTextValue: null });
		};

		if (blurState.isBlurring) {
			handleBlurLogic();
		}
	}, [blurState]);



	const [focusSet, setFocusSet] = useState(false);
	const [lockInPeriodPromptShown, setLockInPeriodPromptShown] = useState(false);

	const updateshareholdingpostissue = (e) => {
		let pre, freshShares;
		e.target.value = e.target.value.replace(/[ ,]/g, '');

		if (e.target.name == "issue_size_fresh_in_shares") {
			freshShares = parseInt(e.target.value) || 0;
			pre = parseInt(values.total_shareholding_pre_issue) || 0;
		}
		else if (e.target.name == "total_shareholding_pre_issue") {

			pre = parseInt(e.target.value) || 0;
			freshShares = parseInt(values.issue_size_fresh_in_shares) || 0;
		}
		setFieldValue('total_shareholding_post_issue', pre + freshShares);
	}

	const calculateMarketLotSize = (issuePrice) => {
		if (issuePrice <= 14) return "10000";
		else if (issuePrice <= 18) return "8000";
		else if (issuePrice <= 25) return "6000";
		else if (issuePrice <= 35) return "4000";
		else if (issuePrice <= 50) return "3000";
		else if (issuePrice <= 70) return "2000";
		else if (issuePrice <= 90) return "1600";
		else if (issuePrice <= 120) return "1200";
		else if (issuePrice <= 150) return "1000";
		else if (issuePrice <= 180) return "800";
		else if (issuePrice <= 250) return "600";
		else if (issuePrice <= 350) return "400";
		else if (issuePrice <= 500) return "300";
		else if (issuePrice <= 600) return "240";
		else if (issuePrice <= 750) return "200";
		else if (issuePrice <= 1000) return "160";
		else return "100"; // Default value for any other condition
	};
	const handleIssueOpenDateChange = (e) => {
		if (e.target.value !== '') {
			getAnchorLockingDate(e.target.value);
		}
	};
	const getAnchorLockingDate = (date) => {
		if (values.issue_process_type == 'FP') return;
		if (date != '' && values.issue_process_type != 'FP') {
			if (window.confirm('Would you like to update Anchor Investor bid date?')) {

				api.post('/admin-ipo-anchor-investor-bid-date-read', {
					date: date
				}).then(response => {
					if (response.data.msg === 1) {
						setFieldValue('timetable_anchor_bid_dt', response.data.anchor_bid_date);
					} else {
						window.alert("Error: Could not update bit date");
					}
				}).catch((error) => {
					console.error('Error fetching data:', error);
				});
			}
		}
	};

	const getLockInPeriodDate = () => {

		let date = values.timetable_boa_dt;
		if (date !== '') {
			if (!lockInPeriodPromptShown) {
				if (window.confirm('Would you like to update Anchor lock-in period end date?')) {
					setFieldValue('timetable_anchor_lockin_end_dt_1', addDays(date, 30));
					setFieldValue('timetable_anchor_lockin_end_dt_2', addDays(date, 90));
				}
				//setLockInPeriodPromptShown(true);
			}
		} else {
			if (!focusSet) {
				const element = document.getElementById("txtTimetable_BOA_dt");
				if (element) element.focus();
				setFocusSet(true);
			}
		}
	};


	function addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		var day = result.getDate();
		var month = result.getMonth() + 1; // Months are zero-based
		var year = result.getFullYear();

		return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
	}

	useEffect(() => {

		if (result.issueStockExchangeList != undefined && result.issueStockExchangeList.length > 0) {
			let arr = values.ipo_listing_at_array;
			if (values.issue_category == "Mainline") {
				setStockExchage(result.issueStockExchangeList.filter(item => item.code !== "BSE SME" && item.code !== "NSE SME"));
				if (arr.includes("BSE SME")) {
					arr = arr.filter(item => item !== "BSE SME");
				}
				if (arr.includes("NSE SME")) {
					arr = arr.filter(item => item !== "NSE SME");
				}
				setFieldValue('ipo_listing_at_array', arr)
			}
			else if (values.issue_category == "SME") {
				setStockExchage(result.issueStockExchangeList.filter(item => item.code !== "BSE" && item.code !== "NSE"));
				if (arr.includes("BSE")) {
					arr = arr.filter(item => item !== "BSE");
				}
				if (arr.includes("NSE")) {
					arr = arr.filter(item => item !== "NSE");
				}
				setFieldValue('ipo_listing_at_array', arr)
			}
		}
	}, [values.issue_category, result.issueStockExchangeList]);

	//function and state variable to handle onclick event on issue category
	const setCapitalMarket = (issueCategory) => {
		if (issueCategory != undefined) {
			const selectedValue = issueCategory;
			if (selectedValue === "SME") {
				setFieldValue('cm_rating', '');
				setFormState((prevState) => ({
					...prevState,
					isCmRatingDisabled: true,
					mainlineCategoryType: false
				}));
			} else {
				setFormState((prevState) => ({
					...prevState,
					isCmRatingDisabled: false,
					mainlineCategoryType: true
				}));
				setFieldValue('ipo_listing_at_array', ['NSE', 'BSE']);
			}
		}
		const prevCategory = prevCategoryRef.current;

		if (issueCategory !== prevCategory) {
			if (issueCategory !== 'SME' && prevCategory === 'SME') {
				setFieldTouched('market_lot_size', true);
				setFieldValue('market_lot_size', '');
				setFieldTouched('minimum_order_quantity', true);
				setFieldValue('minimum_order_quantity', '');
			}
			prevCategoryRef.current = issueCategory;
		}
	};

	function CheckIssueProcess(argThis) {
		if (argThis != undefined) {
			const selectedValue = argThis.value;
			if (selectedValue === "FP") {
				setFieldValue('issue_price_lower', '');
				setFieldValue('issue_price_upper', '');
				setFieldValue('issue_price_lower', '');
				setFieldValue('timetable_anchor_bid_dt', '');
				setFieldValue('timetable_anchor_lockin_end_dt_1', '');
				setFieldValue('timetable_anchor_lockin_end_dt_2', '');
				setFormState((prevState) => ({
					...prevState,
					isIssuePriceLHDisabled: true
				}));
			}
			else {
				setFormState((prevState) => ({
					...prevState,
					isIssuePriceLHDisabled: false
				}));
			}
		}
	}
	useEffect(() => {
		setCapitalMarket(values.issue_category)
	}, [values.issue_category]);

	useEffect(() => {

		let arr = values.ipo_listing_at_array;
		if (arr.includes("NSE SME") || arr.includes("BSE SME")) {
			setFormState((prevState) => ({
				...prevState,
				disableHNIData: true
			}));
		}
		else {
			setFormState((prevState) => ({
				...prevState,
				disableHNIData: false
			}));
		}
	}, [values.ipo_listing_at_array]);

	useEffect(() => {
		setFormState((prevState) => ({
			...prevState,
			sharesOfferedAnchorInvestorDisable: !values.anchor_investor_status
		}));
	}, [values.anchor_investor_status]);

	useEffect(() => {
		setFormState((prevState) => ({
			...prevState,
			empReserveBid: !values.employee_reservation_flag
		}));
		if (!values.employee_reservation_flag) {
			setFieldValue('employee_reservation_bidding_limit', '');
		}
	}, [values.employee_reservation_flag]);

	useEffect(() => {
		setFormState((prevState) => ({
			...prevState,
			shareHolderReserveBid: !values.shareholder_reservation_flag
		}));
		if (!values.shareholder_reservation_flag) {
			setFieldValue('shareholder_reservation_bidding_limit', '');
		}
	}, [values.shareholder_reservation_flag]);

	const getTentativeDate = (e) => {
		let date = e.target.value;
		if (date != '') {
			if (window.confirm('Would you like to update tentative date?')) {
				api.post('/admin-ipo-tentative-date-read', {
					date: date
				}).then(response => {
					if (response.data.msg === 1) {
						setFieldValue('timetable_boa_dt', response.data.timetable_boa_dt);
						setFieldValue('timetable_refunds_dt', response.data.timetable_refunds_dt);
						setFieldValue('timetable_share_credit_dt', response.data.timetable_share_credit_dt);
						setFieldValue('timetable_listing_dt', response.data.timetable_listing_dt);
						if (values.issue_process_type != 'FP') {
							getLockInPeriodDate();
							if (values.timetable_anchor_bid_dt == "") getAnchorLockingDate(values.issue_open_date);
						}
					} else {
						window.alert("Entered Date is a Holiday...");
					}
				}).catch((error) => {
					console.error('Error fetching data:', error);
				});
			}
		}
	};

	useEffect(() => {

		if (
			values.issue_open_date != initialValues.issue_open_date ||
			values.issue_close_date != initialValues.issue_close_date
		) {
			setFormState((prevState) => ({
				...prevState,
				openCloseDateDisplayOrderMessage: "Date modified. Please check display order and NDC calander.",
			}));
		}
		else {
			setFormState((prevState) => ({
				...prevState,
				openCloseDateDisplayOrderMessage: "",
			}));
		}
		if (values.timetable_boa_dt != initialValues.timetable_boa_dt) {
			setFormState((prevState) => ({
				...prevState,
				boaDateDisplayOrderMessage: "Date modified. Please check Anchor lock-in period end dates (30 days and 90 days)",
			}));
		}
		else {
			setFormState((prevState) => ({
				...prevState,
				boaDateDisplayOrderMessage: "",
			}));
		}
	}, [values.issue_open_date, values.issue_close_date, values.timetable_boa_dt]);

	const setIssueSizeAmt = () => {
		let price = getIpoPrice();

		if (price > 0) {
			let fresh_size = document.getElementsByName('issue_size_fresh_in_shares_readonly')[0].value;
			let issue_size_fresh_in_amt = document.getElementsByName('issue_size_fresh_in_amt_readonly')[0].value;
			if (!isValidVariable(fresh_size))
				fresh_size = 0;
			else
				fresh_size = parseInt(fresh_size);

			if (!isValidVariable(issue_size_fresh_in_amt))
				issue_size_fresh_in_amt = 0;
			else
				issue_size_fresh_in_amt = parseInt(issue_size_fresh_in_amt);

			if (fresh_size * price > issue_size_fresh_in_amt) {
				issue_size_fresh_in_amt = fresh_size * price;
				setFieldValue('issue_size_fresh_in_amt', issue_size_fresh_in_amt);
			} else if (fresh_size == 0 && issue_size_fresh_in_amt > 0) {
				fresh_size = issue_size_fresh_in_amt / price;
				setFieldValue('issue_size_fresh_in_shares', fresh_size);
			}


			let ofs_shares = document.getElementsByName('issue_size_ofs_in_shares_readonly')[0].value;
			let issue_size_ofs_in_amt = document.getElementsByName('issue_size_ofs_in_amt_readonly')[0].value;
			if (!isValidVariable(ofs_shares))
				ofs_shares = 0;
			else
				ofs_shares = parseInt(ofs_shares);

			if (!isValidVariable(issue_size_ofs_in_amt))
				issue_size_ofs_in_amt = 0;
			else
				issue_size_ofs_in_amt = parseInt(issue_size_ofs_in_amt);

			if (ofs_shares > 0 && issue_size_ofs_in_amt == 0) {
				issue_size_ofs_in_amt = ofs_shares * price;
				setFieldValue('issue_size_ofs_in_amt', issue_size_ofs_in_amt);
			} else if (ofs_shares == 0 && issue_size_ofs_in_amt > 0) {
				ofs_shares = issue_size_ofs_in_amt / price;
				setFieldValue('issue_size_ofs_in_shares', ofs_shares);
			}


			let total_size = fresh_size + ofs_shares;
			if (!isValidVariable(ofs_shares)) {
				total_size = 0;
			} else {
				total_size = parseInt(total_size);
			}

			setFieldValue('issue_size_in_shares', total_size);
			setFieldValue('issue_size_in_amt', total_size * price);
		} else {
			setFieldValue('issue_size_fresh_in_shares', values.issue_size_fresh_in_shares_readonly);
			setFieldValue('issue_size_fresh_in_amt', values.issue_size_fresh_in_amt_readonly);
			setFieldValue('issue_size_ofs_in_shares', values.issue_size_ofs_in_shares_readonly);
			setFieldValue('issue_size_ofs_in_amt', values.issue_size_ofs_in_amt_readonly);
			setFieldValue('issue_size_in_shares', values.issue_size_in_shares_readonly);
			setFieldValue('issue_size_in_amt', values.issue_size_in_amt_readonly);
		}
	}


	const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

	const handleAutoSearch = async (query) => {
		debouncedHandleAutoSearch(query);
		console.log("The options are: ", autoComplteOptions)
	};

	const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
		console.log(query);
		setIsAutocomplteLoading(true);
		try {
			const response = await api.post('/admin-listofscript-list', { search: query });
			if (response.data.msg === 1) {
				const options = response.data.details.map(item => ({
					label: `${item.company_name} (${item.bse_script_id ?? ""}) (${item.bse_isin ?? ""}) (${item.bse_script_code ?? ""}) (${item.nse_script_id ?? ""}) (${item.nse_isin ?? ""})`,
					value: item.id
				}));
				setAutoComplteOptions(options);
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setIsAutocomplteLoading(false);
		}
	}, 300), []);

	const handleAutoBlur = async (selected) => {
		if (selected) {
			let item = selected.value;
			setFieldValue("shareholder_company_id", item ?? "")
		}
	};

	const [companyId, setCompanyId] = useState([]);
	const [selectedCompanyId, setSelectedCompanyId] = useState([]);

	const handleTransferClick = () => {
		if (values.shareholder_company_text) {
			const newCompany = values.shareholder_company_text;

			// Ensure companyId is always an array and check for duplicates
			if (!Array.isArray(companyId)) {
				setCompanyId([newCompany]);
			} else if (!companyId.some(company => company.value === newCompany.value)) {
				setCompanyId((prev) => [...prev, newCompany]);

				// Update the company_name_array field, ensuring no duplicates
				const updatedCompanyArray = values.company_name_array || [];
				if (!updatedCompanyArray.some(company => company.value === newCompany.value)) {
					setFieldValue("company_name_array", [...updatedCompanyArray, newCompany]);
				}
			}

			console.log("company id: ", companyId);
		}
	};


	useEffect(() => {
		if (Array.isArray(values.company_name_array)) {
			setCompanyId((prevCompanyId) => [
				...prevCompanyId,
				...values.company_name_array.filter(id => !prevCompanyId.includes(id))
			]);
		}
	}, [values, values.company_name_array]);

	const handleRemoveSelected = () => {
		if (selectedCompanyId.length > 0) {
			const updatedIds = companyId.filter((company) => !selectedCompanyId.includes(String(company.value)));
			setCompanyId(updatedIds);

			const updatedShareholderCompanies = values.company_name_array.filter(
				(company) => !selectedCompanyId.includes(String(company.value))
			);
			setFieldValue('company_name_array', updatedShareholderCompanies);
			setSelectedCompanyId([]);
		}
	};


	return (
		<>

			<Form id="myForm">
				{result !== undefined &&
					result.ipo !== undefined &&
					result.ipo.length > 0 && (
						<div className="mb-3 row">
							<label htmlFor="" className="col-lg-2">
								ID:
							</label>
							<div className="col-lg-4">{result.ipo[0].id}</div>
						</div>
					)}
				<div className="my-3 row">
					<label htmlFor="company_name" className="col-lg-2 required">
						Company Name
					</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput
							className="form-control"
							type="text"
							maxLength="100"
							nameattr="company_name"
							tabIndex={1}
						/>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="ipo_news_title" className="col-lg-2 required">
						IPO News Title
					</label>
					<div className="col-lg-4">
						<DynamicCharacterCountInput
							className="form-control"
							type="text"
							maxLength="50"
							nameattr="ipo_news_title"
							tabIndex={5}
							onBlur={(e) => {
								var news_title = e.target.value;
								if (news_title !== "") {
									const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
									if (userConfirmed) {
										var slug = funCreateSlug(news_title)
										setFieldValue('urlrewrite_folder_name', slug);
									} else {
										setFieldTouched('ipo_news_title', true);
									}
								}
							}}
						/>
					</div>
					<label
						htmlFor="urlrewrite_folder_name"
						className="col-lg-2 required"
					>
						URLRewrite Folder Name
					</label>
					<div className="col-lg-4">
						<Field
							className="form-control"
							type="text"
							maxLength="50"
							name="urlrewrite_folder_name"
							tabIndex={10}
						/>
					</div>
				</div>

				<hr />

				<h3>Pre IPO DRHP Section</h3>
				<div className="mb-3 row">
					<label htmlFor="ipo_confirmation_status" className="col-lg-2">
						IPO Confirmation Status

					</label>
					<div className="col-lg-4">
						<Field className="form-check-input" onChange={(e) => { setFieldValue("ipo_confirmation_status", e.target.checked) }} type="checkbox" name="ipo_confirmation_status" tabIndex={11} />
					</div>
					{/* <label htmlFor="prospectus_rhp" className="col-lg-2">
						DRHP Filing Date
					</label>
					<div className="col-lg-4">
						<Field
							className="form-control"
							type="Date"
							size="55"
							maxLength="255"
							name="drhp_filing_date"
							tabIndex={12}
						/>
					</div> */}
				</div>
				<div className="mb-3 row">
					<label htmlFor="ipo_confirmation_status" className="col-lg-2">
						Hidden DRHP Status

					</label>
					<div className="col-lg-4">
						<Field className="form-check-input" onChange={(e) => { setFieldValue("hidden_drhp_status", e.target.checked) }} type="checkbox" name="hidden_drhp_status" tabIndex={13} />
					</div>
					<label htmlFor="ipo_confirmation_status" className="col-lg-2">
						DRHP Current Status

					</label>
					<div className="col-lg-4">
						<Field className="form-control" disabled={true} type="input" name="current_drhp_code_desc" tabIndex={13} >

						</Field>
					</div>

				</div>

				<hr />

				<h3>IPO Detail</h3>

				<div className="mb-3 row">
					<label htmlFor="issue_category" className="col-lg-2 required">
						Issue Category
					</label>
					<div className="col-lg-4">
						{result.issueCategoryList !== undefined && result.issueCategoryList.map((option, index) => (
							<div key={option.code} className="d-inline">
								<Field className="form-check-input" type="radio" name="issue_category" tabIndex={15 + index} onClick={(e) => { setCapitalMarket(e.target.value) }} value={option.code} />&nbsp;{option.code} &nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						))}
					</div>
					<label htmlFor="anchor_investor_status" className="col-lg-2">
						Anchor Investor Status
					</label>
					<div className="col-lg-1">
						<Field
							className="form-check-input"
							type="checkbox"
							name="anchor_investor_status"
							tabIndex={85}
							id="anchor_investor_checkbox"
							onChange={(e) => {
								setFieldValue('anchor_investor_status', e.target.checked);
							}}
						/>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="issue_type" className="col-lg-2 required">
						Issue Type
					</label>
					<div className="col-lg-4">
						{result.issueTypeList !== undefined && result.issueTypeList.map((option, index) => (
							<div key={option.code} className="d-inline">
								<Field className="form-check-input" type="radio" name="issue_type" tabIndex={20 + index} value={option.code} />&nbsp;{option.code} &nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						))}
					</div>
					<label htmlFor="prospectus_drhp" className="col-lg-2">
						DRHP Link
					</label>
					<div className="col-lg-4">
						<Field
							className="form-control"
							type="text"
							maxLength="255"
							name="prospectus_drhp"
							tabIndex={90}
						/>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_process_type" className="col-lg-2 required">
						Process Type
					</label>
					<div className="col-lg-4">
						{result.issueProcessTypeList !== undefined && result.issueProcessTypeList.map((option, index) => (
							<div key={option.code} className="d-inline">
								<Field className="form-check-input" type="radio" name="issue_process_type" tabIndex={25 + index} value={option.code} onClick={(e) => CheckIssueProcess(e.target)} />&nbsp;{option.code_desc} &nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						))}
					</div>
					<label htmlFor="prospectus_rhp" className="col-lg-2">
						RHP Link
					</label>
					<div className="col-lg-4">
						<Field
							className="form-control"
							type="text"
							size="55"
							maxLength="255"
							name="prospectus_rhp"
							tabIndex={95}
						/>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="ipo_listing_at_array" className="col-lg-2 required">
						IPO Listing At
					</label>
					<div className="col-lg-4">
						{stockExchage !== undefined && (
							<Field
								name="ipo_listing_at_array"
								label="IPO Listing At"
								component={CheckboxGroup}
								options={stockExchage}
								className='form-check-input Stock_Exchange_Type_Checkbox me-2'
								tabIndexAttr={30}
							/>
						)}

					</div>
					<label htmlFor="addendum_drhp" className="col-lg-2">
						Addendum DRHP Link
					</label>
					<div className="col-lg-4">
						<Field
							className="form-control"
							type="text"
							size="55"
							maxLength="255"
							name="addendum_drhp"
							tabIndex={100}
						/>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="face_value" className={`col-lg-2 ${values.issue_type === "InvIT" ? "" : "required"}`}>
						Face Value
					</label>

					<div className="col-lg-1">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={35} maxLength="15" nameAtr="face_value" />
					</div>
					<div className="col-lg-3"></div>
					<label htmlFor="market_lot_size" className="col-lg-2">
						Market Lot Size
					</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr="form-control" tabIndexAtr={105} maxLength="5" nameAtr="market_lot_size" idAtr="txtmarket_lot_size"
							dynamicOnChange={(e) => {
								setFieldValue('minimum_order_quantity', e)
							}} />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_price_lower" className="col-lg-2">
						Issue Price (Lower)
					</label>
					<div className="col-lg-1">
						<NumberDecimalInput classNameAtr="form-control two-decimals" tabIndexAtr={40} maxLength="7" nameAtr="issue_price_lower" disabledAtr={formState.isIssuePriceLHDisabled} />
					</div>
					<div className="col-lg-3"></div>
					<label htmlFor="minimum_order_quantity" className="col-lg-2">
						Min. Order Quantity
					</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr="form-control" tabIndexAtr={110} maxLength="5" nameAtr="minimum_order_quantity" idAtr="txtminimum_order_quantity" />
					</div>
					<div className="col-lg-3"></div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="issue_price_upper" className="col-lg-2">
						Issue Price (Upper)
					</label>
					<div className="col-lg-1">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={45} maxLength="7" nameAtr="issue_price_upper" disabledAtr={formState.isIssuePriceLHDisabled} dynamicOnBlur={(e) => {
							handleBlurWrapper(e);
						}} dynamicOnInput={setIssueSizeAmt} />
					</div>
					<div className="col-lg-3"></div>
					<label htmlFor="discount_retail" className="col-lg-2">
						Discount Retail
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={115} maxLength="50" nameAtr="discount_retail" />
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_price_final" className="col-lg-2">
						Issue Price (Final)
					</label>
					<div className="col-lg-1">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={50} maxLength="7" nameAtr="issue_price_final"
							dynamicOnInput={setIssueSizeAmt} dynamicOnBlur={(e) => {
								handleBlurWrapper(e);
							}} />
					</div>
					<div className="col-lg-3"></div>
					<label htmlFor="discount_employee" className="col-lg-2">
						Discount Employee
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={120} maxLength="50" nameAtr="discount_employee" />
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_size_fresh_in_shares" className="col-lg-2">
						<u>Fresh</u> Issue Size
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={55} maxLength="25" nameAtr="issue_size_fresh_in_shares"
							dynamicOnBlur={(e) => { handleBlurWrapper(e); updateshareholdingpostissue(e); }}
						/>
					</div>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={56} maxLength="25" nameAtr="issue_size_fresh_in_shares_readonly" />
					</div>
					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }}
						>{parseFloat(values.issue_size_fresh_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;&nbsp;Shares</div>
					</div>

				</div>

				<div className="mb-3 row">
					<label htmlFor="issue_size_fresh_in_amt" className="col-lg-2">
						<u>Fresh</u> Issue Size in Amt
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25" nameAtr="issue_size_fresh_in_amt" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
					</div>

					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={60} maxLength="25" nameAtr="issue_size_fresh_in_amt_readonly" />
					</div>

					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }} id="divIssue_Size_Fresh_In_Amt"
						>Rs.&nbsp;{parseFloat(values.issue_size_fresh_in_amt / 10000000).toFixed(2)}&nbsp;Crore&nbsp;&nbsp;</div>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_size_ofs_in_shares" className="col-lg-2">
						<u>OFS</u> Issue Size (Shares)
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={65} maxLength="25" nameAtr="issue_size_ofs_in_shares" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
					</div>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={65} maxLength="25" nameAtr="issue_size_ofs_in_shares_readonly" />
					</div>

					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }}
						>{parseFloat(values.issue_size_ofs_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;&nbsp;Shares</div>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_size_ofs_in_amt" className="col-lg-2">
						<u>OFS</u> Issue Size (Rs)
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25" nameAtr="issue_size_ofs_in_amt" dynamicOnBlur={(e) => handleBlurWrapper(e)} />
					</div>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={70} maxLength="25" nameAtr="issue_size_ofs_in_amt_readonly" />
					</div>

					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }}
						>Rs. &nbsp; {parseFloat(values.issue_size_ofs_in_amt / 10000000).toFixed(2)}&nbsp;Crores</div>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_size_in_shares" className="col-lg-2">
						Issue Size (Total) (Shares)
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="25" nameAtr="issue_size_in_shares" />
					</div>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={75} maxLength="25" nameAtr="issue_size_in_shares_readonly" />
					</div>

					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }}
						>{parseFloat(values.issue_size_in_shares / 100000).toFixed(2)}&nbsp;Lakh&nbsp;Shares</div>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="issue_size_in_amt" className="col-lg-2">
						Issue Size (Total) (Rs)
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={80} maxLength="25" nameAtr="issue_size_in_amt" idAtr="Issue_Size_In_Amt" />
					</div>

					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={80} maxLength="25" nameAtr="issue_size_in_amt_readonly" />
					</div>

					<div className="col-lg-2">
						<div className="ipo-text-notify" style={{ color: '#0000FF' }}
						>Rs.&nbsp;{parseFloat(values.issue_size_in_amt / 10000000).toFixed(2)}&nbsp;Crore</div>
					</div>
					<div className="col-lg-6"></div>
				</div>

				<hr />
				<h3>IPO Dates</h3>

				<div className="mb-3 row">
					<label htmlFor="issue_open_date" className="col-lg-2">
						Issue Open Date
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control dateInput"
							type="date"
							id="txtIssue_Open_Date"
							name="issue_open_date"
							title="YYYY/MM/DD"
							tabIndex={130}
							onChange={(e) => {
								setFieldValue("issue_open_date", e.target.value);
								handleIssueOpenDateChange(e)
							}}
						/>
						<p
							className="text-danger"
							id="display_order_message"
						>   {formState.openCloseDateDisplayOrderMessage}
						</p>
					</div>
					<label htmlFor="issue_close_date" className="col-lg-2">
						Issue Close Date
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control dateInput"
							type="date"
							name="issue_close_date"
							id="txtIssue_Close_Date"
							title="YYYY/MM/DD"
							tabIndex={135}
							onBlur={(e) => {
								setFieldValue("issue_close_date", e.target.value);
								getTentativeDate(e)
								setLockInPeriodPromptShown(false);
							}}
						/>
						<p
							className="text-danger"
							id="display_order_message"
						>   {formState.openCloseDateDisplayOrderMessage}
						</p>
					</div>
				</div>

				<h3>Tentative Timetable</h3>
				<p>
					<mark>
						Please double check dates from RHP (SME timetable T+3 instead of
						T+5/6/7)
					</mark>
				</p>
				<div className="mb-3 row">
					<label htmlFor="timetable_boa_dt" className="col-lg-2">
						Finalisation of Basis of Allotment
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_boa_dt"
							id="txtTimetable_BOA_dt"
							title="YYYY/MM/DD"
							tabIndex={140}
							onBlur={(e) => {
								setFieldValue("timetable_boa_dt", e.target.value);
								if (values.issue_process_type != 'FP') {
									getLockInPeriodDate()
									if (values.timetable_anchor_bid_dt == "") getAnchorLockingDate(values.issue_open_date);
								}
							}}

						/>
						<p
							className="text-danger"
							id="Timetable_BOA_dt_message"
						>
							{formState.boaDateDisplayOrderMessage}
						</p>
					</div>
					<label htmlFor="timetable_refunds_dt" className="col-lg-2">
						Initiation of refunds
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_refunds_dt"
							id="txtTimetable_Refunds_dt"
							title="YYYY/MM/DD"
							tabIndex={145}
						/>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="timetable_share_credit_dt" className="col-lg-2">
						Credit of Equity Shares
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_share_credit_dt"
							id="txtTimetable_Share_Credit_dt"
							title="YYYY/MM/DD"
							tabIndex={150}
						/>
					</div>

					<label htmlFor="timetable_listing_dt" className="col-lg-2">
						Commencement of trading
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_listing_dt"
							id="txtTimetable_Listing_dt"
							title="YYYY/MM/DD"
							tabIndex={155}
						/>
					</div>
				</div>
				<h3>Anchor Investors Timetable</h3>
				<div className="mb-3 row">
					<label htmlFor="timetable_anchor_bid_dt" className="col-lg-2">
						Anchor Investor Bid Date
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							id="txtAnchor_Bid_Date"
							name="timetable_anchor_bid_dt"
							title="YYYY/MM/DD"
							tabIndex={160}
							onBlur={(e) => {
								setFieldValue("timetable_anchor_bid_dt", e.target.value);
								if (values.timetable_boa_dt == "" || values.timetable_boa_dt == null) {
									alert("Enter Basis of Allotment Date for Anchor lock-in period end date");
									return;
								}
								if (values.issue_process_type != 'FP') getLockInPeriodDate()
								setFocusSet(false)
							}}
							readOnly={values.issue_process_type == 'FP'}
						/>
					</div>
					<label htmlFor="timetable_anchor_lockin_end_dt_1" className="col-lg-2">
						Anchor lock-in period end date (30 Days)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_anchor_lockin_end_dt_1"
							id="timetable_anchor_lockin_end_dt_1"
							title="YYYY/MM/DD"
							tabIndex={165}
							readOnly={values.issue_process_type == 'FP'}
						/>
					</div>
					<label htmlFor="timetable_anchor_lockin_end_dt_2" className="col-lg-2">
						Anchor lock-in period end date (90 Days)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="date"
							name="timetable_anchor_lockin_end_dt_2"
							id="Anchor_Lockin_End_dt_2"
							title="YYYY/MM/DD"
							tabIndex={170}
							readOnly={values.issue_process_type == 'FP'}
						/>
					</div>
				</div>
				<hr />
				<h3>For IPO Bidding Auto Load</h3>
				<div className="row mb-3">
					<label htmlFor="nse_symbol" className="col-lg-2">NSE/BSE Symbol</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="nse_symbol"
							title="NSE_Symbol"
							tabIndex={175}
						/>
					</div>
					<div className="col-lg-2">
						<p>
							<a className="me-2"
								href="https://nseindia.com/products/content/equities/ipos/homepage_ipo.htm"
								target="_blank"
							>
								Mainline
							</a>
							|
							<a className="mx-2"
								href="https://www.nseindia.com/market-data/sme-market"
								target="_blank"
							>
								SME
							</a>
							i.e. RITES, PIL
						</p>
					</div>
					<label htmlFor="bse_scripcode" className="col-lg-2">
						BSE IPO No
					</label>

					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={180} maxLength="25" nameAtr="bse_scripcode" />
					</div>
					<div className="col-lg-2">
						<p>
							<a className="me-2"
								href="https://www.bseindia.com/markets/PublicIssues/IPOIssues_new.aspx?expandable=9&id=1& Type=p"
								target="_blank"
							>
								Mainline
							</a>
							|
							<a className="mx-2"
								href="https://www.bsesme.com/PublicIssues/PublicIssues.aspx?id=1"
								target="_blank"
							>
								SME
							</a>
							i.e. 3733
						</p>
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="capital_market_id" className="col-lg-2">
						Capital Market ID (BoA)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="capital_market_id"
							title="Capital_Market_ID"
							tabIndex={185}
						/>
					</div>
					<div className="col-lg-2">
						<p>
							<a className="me-2"
								href="https://www.capitalmarket.com/IPO/Forthcoming-IPO"
								target="_blank"
							>
								Mainline
							</a>
							|
							<a className="mx-2"
								href="https://www.capitalmarket.com/smeipo/forthcoming-smeipo"
								target="_blank"
							>
								SME
							</a>
							i.e. 24268
						</p>
					</div>
					<label htmlFor="cm_rating" className="col-lg-2">
						Capital Market Rating
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={190} maxLength="5" nameAtr="cm_rating" disabledAtr={formState.isCmRatingDisabled} />
					</div>
				</div>
				<hr />
				<h3>
					Shares Percentage by Category Temp.
					<span style={{ backgroundColor: "#ffffcc" }}>
						<small>(Fill manual.)</small>
					</span>
				</h3>
				<div className="mb-3 row">
					<label
						htmlFor="shares_offered_qib_percentage_temp"
						className="col-lg-2"
					>
						Shares Offered QIB Percentage
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="shares_offered_qib_percentage_temp"
							tabIndex={191}
							maxLength="100"
						/>
					</div>
					<label
						htmlFor="shares_offered_nii_percentage_temp"
						className="col-lg-2"
					>
						Shares Offered NII Percentage
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="shares_offered_nii_percentage_temp"
							tabIndex={192}
							maxLength="100"
						/>
					</div>
					<label
						htmlFor="shares_offered_rii_percentage_temp"
						className="col-lg-2"
					>
						Shares Offered RII Percentage
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="shares_offered_rii_percentage_temp"
							tabIndex={193}
							maxLength="100"
						/>
					</div>
				</div>
				<hr />
				<h3>Shares Offered by Category</h3>

				<div className="mb-3 row">
					<label htmlFor="employee_reservation_flag" className="col-lg-2">
						Employee Reservation
					</label>
					<div className="col-lg-4">
						<Field
							className="form-check-input"
							type="checkbox"
							name="employee_reservation_flag"
							tabIndex={194}
							onChange={(e) => {
								setFieldValue('employee_reservation_flag', e.target.checked)
							}}
						/>
					</div>

					<label htmlFor="shareholder_reservation_flag" className="col-lg-2">
						Shareholder Reservation
					</label>
					<div className="col-lg-4">
						<Field
							className="form-check-input"
							type="checkbox"
							name="shareholder_reservation_flag"
							tabIndex={195}
							onChange={(e) => { setFieldValue('shareholder_reservation_flag', e.target.checked) }}
						/>
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="employee_reservation_bidding_limit" className="col-lg-2">Employee reservation max bidding limit</label>
					<div className="col-lg-2">
						<Field as="select" name="employee_reservation_bidding_limit" className="form-select" tabIndex={196} disabled={formState.empReserveBid}>
							<option></option>
							{result.empReservationBiddingList !== undefined && result.empReservationBiddingList.map((option) => (
								<option key={option.code} value={option.code}>
									{option.code_desc}
								</option>
							))}
						</Field>
					</div>

					<div className="col-lg-2"></div>

					<label htmlFor="shareholder_reservation_bidding_limit" className="col-lg-2">Shareholder reservation max bidding limit</label>
					<div className="col-lg-2">
						<Field as="select" name="shareholder_reservation_bidding_limit" className="form-select" tabIndex={197} disabled={formState.shareHolderReserveBid}>
							<option></option>
							{result.shareholderReservationBidList !== undefined && result.shareholderReservationBidList.map((option) => (
								<option key={option.code} value={option.code}>
									{option.code_desc}
								</option>
							))}
						</Field>
					</div>
				</div >

				<div className="mb-3 row">
					<label htmlFor="shareholder_company_text" className="col-lg-2">Shareholder For Company</label>
					<div className="col-lg-4">
						<Field
							name="shareholder_company_text"
							as={AutocompleteField}
							options={autoComplteOptions}
							onInputChange={(query) => handleAutoSearch(trimValue(query))}
							onInputSelect={handleAutoBlur}
							tabIndex={198}
							maxLength="10"
							isAutocomplteLoading={isAutocomplteLoading}
							idatr="company_name_txt"
							nameatr="shareholder_company_text"
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.value}
							value={values.shareholder_company_text}
						/>
					</div>

					<div className="col-lg-2 text-center">
						<div className="mt-2">
							<button
								type="button"
								onClick={() => handleTransferClick()}
								className="btn btn-sm btn-primary mb-2"
							>
								&gt;&gt;
							</button>
						</div>
						<div>
							<button
								type="button"
								onClick={() => handleRemoveSelected()}
								className="btn btn-sm btn-primary"
								disabled={selectedCompanyId.length === 0}
							>
								&lt;&lt;
							</button>
						</div>
					</div>

					<div className="col-lg-4">
						<Field
							as="select"
							name="shareholder_company_name"
							className="form-control"
							multiple
							value={selectedCompanyId}
							tabIndex={199}
							onChange={(e) => {
								const selected = Array.from(e.target.selectedOptions, (option) => option.value);
								setSelectedCompanyId(selected);
							}}
						>
							{(!Array.isArray(values.company_name_array) || values.company_name_array.length === 0) && (
								<option value="" disabled>
									Select the shareholder company name
								</option>
							)}
							{/* Render company options if available */}
							{Array.isArray(values.company_name_array) &&
								values.company_name_array.map((company, index) => (
									<option key={index} value={company.value}>
										{company.label}
									</option>
								))}
						</Field>
					</div>
				</div>

				<hr />
				<b>Reservations:</b>

				<div className="mb-3 row">
					<label htmlFor="shares_offered_market_maker" className="col-lg-2">
						Market Maker (SME Only)
					</label>
					<div className="col-lg-2">
						<NumberInput
							classNameAtr="form-control clear-comma"
							maxLength="25"
							nameAtr="shares_offered_market_maker"
							tabIndexAtr={199}
							disabledAtr={formState.mainlineCategoryType}
						/>
					</div>
					<label
						htmlFor="shares_offered_anchor_investor"
						className="col-lg-2"
					>
						Anchor Investor
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={200} maxLength="25" nameAtr="shares_offered_anchor_investor" idAtr="txtShares_Offered_Anchor_Investor" disabledAtr={formState.sharesOfferedAnchorInvestorDisable} />
					</div>
				</div>
				<b>Public Offer:</b>
				<div className="mb-3 row">
					<label htmlFor="shares_offered_qib" className="col-lg-2">
						QIB
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={205} maxLength="20" nameAtr="shares_offered_qib" />
					</div>
					<label htmlFor="shares_offered_nii" className="col-lg-2">
						NII
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={210} maxLength="20" nameAtr="shares_offered_nii" />
					</div>
					<label htmlFor="shares_offered_rii" className="col-lg-2">
						RII
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={215} maxLength="20" nameAtr="shares_offered_rii" />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="shares_offered_big_nii" className="col-lg-2">
						bNII &gt; 10L
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={220} maxLength="20" nameAtr="shares_offered_big_nii" />
					</div>
					<label htmlFor="shares_offered_small_nii" className="col-lg-2">
						sNII &gt; 10L
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={225} maxLength="20" nameAtr="shares_offered_small_nii" />
					</div>
				</div>

				<div className="mb-3 row">
					<label htmlFor="shares_offered_emp" className="col-lg-2">
						Employee
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={230} maxLength="20" nameAtr="shares_offered_emp" />
					</div>
					<label htmlFor="shares_offered_others" className="col-lg-2">
						Others
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={235} maxLength="20" nameAtr="shares_offered_others" />
					</div>
					<label htmlFor="shares_offered_total" className="col-lg-2">
						Total For Public <br />
						(including employee)
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={240} maxLength="20" nameAtr="shares_offered_total" />
					</div>
				</div>


				<hr />
				<h3>HNI Cost For & Days Data</h3>
				<div className="mb-3 row">
					<label
						htmlFor="hni_cost_nii_oversubscription_start"
						className="col-lg-2"
					>
						NII Over- Subscription (No. of Times)
					</label>
					<div className="col-lg-2">
						<NumberInput classNameAtr="form-control" tabIndexAtr={260} maxLength="25" nameAtr="hni_cost_nii_oversubscription_start" disabledAtr={formState.disableHNIData} />
					</div>
					<div className="col-lg-2">
						<p>i.e. 10 times. Default 1 times</p>
					</div>
					<label
						htmlFor="hni_cost_nii_bid_amount_incremental_rs_cr"
						className="col-lg-2"
					>
						Incremental HNI IPO Collection value
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control hni_checkbox" tabIndexAtr={265} maxLength="25" nameAtr="hni_cost_nii_bid_amount_incremental_rs_cr" disabledAtr={formState.disableHNIData} />
					</div>
					<div className="col-lg-2">
						<p>i.e. Rs 500 Cr. Default 100 Cr</p>
					</div>
				</div>

				<hr />
				<h3>Other Issue Details</h3>
				<div className="mb-3 row">
					<label
						htmlFor="total_shareholding_pre_issue"
						className="col-lg-2"
					>
						Total Shareholding Pre-Issue
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={270} maxLength="25" nameAtr="total_shareholding_pre_issue" dynamicOnChange={(e) => { setFieldValue("total_shareholding_pre_issue", e.target.value); updateshareholdingpostissue(e); }} />
					</div>
					<div className="col-lg-2"></div>
					<label
						htmlFor="total_shareholding_post_issue"
						className="col-lg-2"
					>
						Total Shareholding Post-Issue
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={275} maxLength="25" nameAtr="total_shareholding_post_issue" />
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label
						htmlFor="promoter_shareholding_pre_issue"
						className="col-lg-2"
					>
						Promoter Shareholding Pre-Issue
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="promoter_shareholding_pre_issue"
							tabIndex={280}
							maxLength="40"
						/>
					</div>
					<div className="col-lg-2"></div>
					<label
						htmlFor="promoter_shareholding_post_issue"
						className="col-lg-2"
					>
						Promoter Shareholding Post-Issue
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="promoter_shareholding_post_issue"
							tabIndex={285}
							maxLength="40"
						/>
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="pe_ratio" className="col-lg-2">
						P/E (x)
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={290} maxLength="15" nameAtr="pe_ratio" decimalPoint={4} />
					</div>
					<div className="col-lg-2"></div>
					<label htmlFor="post_pe_ratio" className="col-lg-2">
						Post P/E (x)
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={295} maxLength="15" nameAtr="post_pe_ratio" decimalPoint={4} />
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="market_cap" className="col-lg-2">
						Market Cap (&#8377; Cr.)
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={300} maxLength="15" nameAtr="market_cap" decimalPoint={4} />
					</div>
					<div className="col-lg-2"></div>
					<label htmlFor="kpi_roe" className="col-lg-2">
						ROE (%)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="kpi_roe"
							tabIndex={305}
							maxLength="10"
						/>
						<p className="text-warning mb-0">
							If value in % then add % sign.
						</p>
					</div>
					<div className="col-lg-2"></div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="kpi_roce" className="col-lg-2">
						ROCE (%)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="kpi_roce"
							tabIndex={310}
							maxLength="10"
						/>

						<p className="text-warning mb-0">
							If value in % then add % sign.
						</p>
					</div>
					<div className="col-lg-2"></div>
					<label htmlFor="kpi_debt_equity" className="col-lg-2">
						Debt/Equity
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={315} maxLength="10" nameAtr="kpi_debt_equity" />
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="kpi_eps" className="col-lg-2">
						EPS (Rupee symbol)
					</label>
					<div className="col-lg-2">
						<NumberDecimalInput classNameAtr="form-control" tabIndexAtr={320} maxLength="10" nameAtr="kpi_eps" />
					</div>
					<div className="col-lg-2"></div>
					<label htmlFor="kpi_ronw" className="col-lg-2">
						RoNW (%)
					</label>
					<div className="col-lg-2">
						<Field
							className="form-control"
							type="text"
							name="kpi_ronw"
							tabIndex={325}
							maxLength="10"
						/>

						<p className="text-warning  mb-0">
							If value in % then add % sign.
						</p>
					</div>
				</div>

				<hr />
				<h3>Tentative Timetable (YYYY/MM/DD - Date Only Fields)</h3>
				<div className="mb-3 row">
					<label htmlFor="display_order" className="col-lg-2">
						Display Order
					</label>
					<div className="col-lg-1">
						<NumberInput classNameAtr="form-control" tabIndexAtr={330} maxLength="4" nameAtr="display_order" />
					</div>

					<label htmlFor="display_status" className="col-lg-2">
						Display Status
					</label>
					<div className="col-lg-1">
						<Field
							className="form-check-input"
							type="checkbox"
							name="display_status"
							tabIndex={335}
							onChange={(e) => { setFieldValue('display_status', e.target.checked) }}
						/>
					</div>

					<label htmlFor="issue_withdraw" className="col-lg-2">
						Issue Withdraw
					</label>
					<div className="col-lg-4">
						<Field
							className="form-check-input me-2"
							type="checkbox"
							name="issue_withdraw"
							tabIndex={340}
							onChange={(e) => { setFieldValue('issue_withdraw', e.target.checked) }}
						/>
						(Check if issue is withdrawn from the market)
					</div>
				</div>
				<div className="mb-3 row">
					<label htmlFor="hide_temporary" className="col-lg-2">
						Hide temporary
					</label>
					<div className="col-lg-4">
						<Field
							className="form-check-input me-2"
							type="checkbox"
							name="hide_temporary"
							tabIndex={345}
							onChange={(e) => { setFieldValue('hide_temporary', e.target.checked) }}
						/>
						(Check if issue need to be hidden from list)
					</div>
					<label htmlFor="lock_status" className="col-lg-2">
						Lock Status
					</label>
					<div className="col-lg-4">
						<Field
							className="form-check-input me-2"
							type="checkbox"
							name="lock_status"
							tabIndex={350}
							onChange={(e) => { setFieldValue('lock_status', e.target.checked) }}
						/>
					</div>
				</div>
				<hr />
				<div className="mb-3 text-center">
					{initialValues.lock_status !== 1 && (
						<button
							type="submit"
							className="btn btn-primary btn-block"
							disabled={loading}
							tabIndex={355}
						>
							{loading && (
								<span className="spinner-border spinner-border-sm me-2"></span>
							)}
							<span>
								{result.ipo !== undefined && result.ipo.length > 0
									? "Update"
									: "Create"}
							</span>
						</button>
					)}

					<button
						type="button"
						className="btn btn-secondary btn-block ms-2"
						onClick={handleGoBack}
						tabIndex={360}
					>
						Cancel
					</button>
				</div>
				<ValidateFormFields />
			</Form>
		</>
	);
};

export default IpoForm;
