
import React, { useEffect, useState, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../state/slices/message';
import { isValidVariable } from '../Common/CommonFunction';

const IpoObjectiveIssueForm = ({ remove, previousValues }) => {
    const { setFieldValue, values } = useFormikContext();
    const dispatch = useDispatch();

    const inputSetInTable = (event) => {
        const { name, value } = event.target;
        const prevValue = previousValues.current[name];

        if (!isNaN(value) && isValidVariable(value) && prevValue != value) {
            let finalValue = parseFloat(value);
            if (finalValue.toString().length > 0) {
                const multipliedValue = values.digitInput * finalValue;
                const roundedValue = parseFloat(multipliedValue.toFixed(10));
                if (roundedValue.toString().length < 20) {
                    setFieldValue(name, roundedValue);
                    previousValues.current[name] = roundedValue;
                } else {
                    dispatch(setMessage("Out of Limit Value"));
                }
            } else {
                setFieldValue(name, "");
                previousValues.current[name] = "";
            }
        }

        if (prevValue !== undefined && prevValue !== value) {
            console.log(`Previous value of ${name} was ${prevValue}`);
        }
    }
    useEffect(() => {
        values.ipoObjectiveIssue?.forEach((_, idx) => {
            setFieldValue(`ipoObjectiveIssue.${idx}.series`, idx + 1);
        });
    }, [values.ipoObjectiveIssue]);

    return (
        <>
            {values?.ipoObjectiveIssue?.map((objIssue, index) => (
                <tr key={index}>
                    <td>
                        <Field className="form-control" maxLength="3" name={`ipoObjectiveIssue.${index}.series`} readOnly />
                    </td>
                    <td>
                        <Field type="text" name={`ipoObjectiveIssue.${index}.particulars`} className="form-control" maxLength="500" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10"
                            nameAtr={`ipoObjectiveIssue.${index}.estimated_amount`} placeholder="Estimated Obj. Issue Amount" dynamicOnBlur={inputSetInTable}
                        />
                    </td>
                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`ipoObjectiveIssue.${index}.display_status`} onChange={(e) => {
                                    console.log("Target is checked: ", e.target.checked)
                                    setFieldValue(`ipoObjectiveIssue.${index}.display_status`, e.target.checked)
                                }
                                }
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default IpoObjectiveIssueForm;