import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import PostCommentChrForm from './PostCommentChrForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import routeConfig from '../../../routeConfig';
import { closePopUpWindow, funCloseWebWindow, isNumeric, isValidVariable } from '../../admintool9x/Common/CommonFunction';

const PostCommentChr = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var main_ref = queryParams.get("main_ref") ?? "";
    var requestTypeId = queryParams.get("t") ?? "";
    var topicId = queryParams.get("a") ?? "";
    var topicId2 = queryParams.get("b") ?? "";
    var parentMessageId = queryParams.get("pid") ?? "";

    const { user: currentUser } = useSelector((state) => state.auth);

    const validationSchema = (user) => {
        const schema = {
            message: Yup.string().required('Message is required').min(3, 'Message must be at least 3 characters long'),
            recaptcha: Yup.string().required('ReCAPTCHA validation is required'),
            posted_by_name: user ? "" : Yup.string().trim().required('Your Name is required'),
            posted_by_email: user ? "" : Yup.string().trim().email('Invalid email address').required('Your Email is required'),
        };
        return Yup.object().shape(schema);
    };

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const recaptchaRef = useRef(null);
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const btnClick = () => {
        if (main_ref != "") {
            funCloseWebWindow(main_ref);
        } else {
            closePopUpWindow();
        }
    }


    const [initialValues, setInitialValues] = useState({
        message: "",
        recaptcha: "",
        track_replies: 0,
        posted_by_name: "",
        posted_by_email: ""
    });

    useEffect(() => {
        if (didMountRef.current == "true") {
            if (!isValidVariable(requestTypeId) || !isNumeric(requestTypeId)) {
                requestTypeId = 0;
            }
            if (requestTypeId == 0) {
                navigate("/");
            }
            if (!isValidVariable(topicId)) {
                topicId = 0;
            }
            if (!isValidVariable(topicId2)) {
                topicId2 = 0;
            }

            if (!isNumeric(topicId)) {
                navigate("/");
            }

            if (!isNumeric(topicId2)) {
                navigate("/");
            }

            if (!isValidVariable(parentMessageId)) {
                parentMessageId = 0;
            }

            if (!isNumeric(parentMessageId)) {
                navigate("/");
            }

            if ((requestTypeId == 13 || requestTypeId == 34 || requestTypeId == 35 || requestTypeId == 36 || requestTypeId == 37 || requestTypeId == 38 || requestTypeId == 67 || requestTypeId == 70) && (currentUser == "" || currentUser == null)) {
                navigate(routeConfig.accessdenied() + "?main_ref=" + main_ref);
            }


            dispatch(startLoading());

            setMetaData({ "title": "Leave your comment" })

            api.post('/member-comment-read', {
                values: {
                    topic_id: topicId,
                    topic_id_2: topicId2,
                    parent_message_id: parentMessageId,
                    request_type_id: requestTypeId
                }

            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    parentMessageId = response.data.parentMessageID;
                    queryParams.set("pid", parentMessageId);

                    setInitialValues(prevValues => ({
                        ...prevValues,
                        ...response.data.pageData,
                        topic_id: topicId,
                        topic_id_2: topicId2,
                        parent_message_id: parentMessageId,
                        request_type_id: requestTypeId,
                    }));

                } else {
                    if (response.data.msg == -3) {
                        navigate(routeConfig.accessdenied() + "?main_ref=" + main_ref)
                    }
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = async (values, { setSubmitting }) => {
        const token = recaptchaRef.current.getValue();
        if (!token) {
            alert('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }
        recaptchaRef.current.reset();

        let trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.recaptchaToken = token;

        dispatch(startLoading());

        api.post('/member-comment-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(routeConfig.submission() + `?a=${requestTypeId}&main_ref=` + main_ref);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema(currentUser)}
                onSubmit={handleSubmit}
            >
                <PostCommentChrForm result={result} btnClick={btnClick} loading={loading} initialValues={initialValues} recaptchaRef={recaptchaRef} currentUser={currentUser} />
            </Formik>


        </>
    )
}

export default PostCommentChr
