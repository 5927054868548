import React, { useState, useEffect } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import { useDispatch } from 'react-redux';
import { finishLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import useApi from '../../../common/useApi';

const SQLFindInAllTablesForm = ({ result, handleGoBack, loading }) => {
    const [selectAllFeedback, setSelectAllFeedback] = useState(false);
    const [tableCheckboxes, setTableCheckboxes] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const { values } = useFormikContext();
    const dispatch = useDispatch();
    const api = useApi();
    const [searchResult, setSearchResult] = useState({});

    const tablesToCheck = ['feedback', 'ipo_feedback', 'ipo_feedback_archive', 'ipo_greymarket_feedback', 'ipo_greymarket_feedback_archive', 'ipo_recommendation_detail'];
    const ipoTablesToCheck = ['ipo', 'ncd', 'rights_issue', 'buyback', 'calendar_master', 'ipo_issuer_company', 'ipo_listing', 'ipo_managers', 'ncd_issuer_company', 'ipo_reviews', 'ipo_alerts'];
    const reportTablesToCheck = ['reports_master_web_parameters', 'reports_calendar_master', 'reports_calendar_web_master', 'reports_master', 'reports_master_web'];
    const bookTablesToCheck = ['book_chapter_master', 'book_master', 'book_module_master'];
    const tradingTablesToCheck = ['trading_company_demat_charges', 'trading_company_detail_stock', 'trading_company_master', 'trading_company_nri_detail', 'trading_platform'];

    useEffect(() => {
        if (result.msg === 1 && result.sqlFindInTables && result.sqlFindInTables.length > 0) {
            const initialCheckboxes = {};
            const initialTableCheckboxes = {};
            result.sqlFindInTables.forEach(table => {
                initialCheckboxes[table.table_name] = false;
                initialTableCheckboxes[table.table_name] = false;
            });
            initialCheckboxes['check1checkbox'] = false;
            initialCheckboxes['check2checkbox'] = false;
            initialCheckboxes['check3checkbox'] = false;
            initialCheckboxes['check4checkbox'] = false;
            initialCheckboxes['check5checkbox'] = false;
            initialCheckboxes['check6checkbox'] = false;
            setCheckboxes(initialCheckboxes);
            setTableCheckboxes(initialTableCheckboxes);
        }
    }, [result]);


    const handleCheckAllFields = () => {

        const isChecked = !selectAllFeedback;
        setSelectAllFeedback(isChecked);
        const newCheckboxes = { ...checkboxes };
        const newTableCheckboxes = {};
        Object.keys(newCheckboxes).forEach(key => {
            newCheckboxes[key] = isChecked;
            if (key !== 'check1checkbox') {
                newTableCheckboxes[key] = isChecked;
            }
        });
        newCheckboxes['check2checkbox'] = isChecked;
        newCheckboxes['check3checkbox'] = isChecked;
        newCheckboxes['check4checkbox'] = isChecked;
        newCheckboxes['check5checkbox'] = isChecked;
        newCheckboxes['check6checkbox'] = isChecked;
        setCheckboxes(newCheckboxes);
        setTableCheckboxes(newTableCheckboxes);
    };

    const handleCheckboxChange = (tableName) => {
        const newCheckboxes = { ...checkboxes };
        const newTableCheckboxes = { ...tableCheckboxes };
        newTableCheckboxes[tableName] = !newTableCheckboxes[tableName];
        newCheckboxes[tableName] = !newCheckboxes[tableName];
        setCheckboxes(newCheckboxes);
        setTableCheckboxes(newTableCheckboxes);
    };

    const handleCheckMultipleTables = (id, tables) => {
        const newCheckboxes = { ...checkboxes };
        const newTableCheckboxes = { ...tableCheckboxes };
        tables.forEach(table => {
            newCheckboxes[table] = !newCheckboxes[id];
            newTableCheckboxes[table] = !newCheckboxes[id];
        });

        newCheckboxes[id] = !newCheckboxes[id];

        setCheckboxes(newCheckboxes);
        setTableCheckboxes(newTableCheckboxes);
    };

    const handleSubmit = () => {

        const trueKeys = Object.keys(tableCheckboxes).filter(key => tableCheckboxes[key] === true);

        const trueKeysString = trueKeys.join(',');

        if (values.sqlquery == "") {
            alert("Please enter text to find")
            return;
        }

        if (trueKeysString == "") {
            alert("Please select atleast on table")
            return;
        }
        setSearchResult({})
        dispatch(startLoading());

        api.post('/admin-sqlfindintables-search', {
            values: {
                search: values.sqlquery,
                tables_name: trueKeysString
            }
        }
        ).then(response => {
            if (response.data.msg === 1) {
                console.log(response.data.result)
                setSearchResult(response.data.result)
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

    };

    return (
        <>
            <Form id='myForm'>
                <div className="mb-3 row">
                    <div className="col-lg-2">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="check1checkbox"
                                checked={selectAllFeedback}
                                onChange={handleCheckAllFields}
                            />
                            <label className="form-check-label" htmlFor="check1checkbox">
                                <b>Check/Uncheck All</b>
                            </label>
                        </div>
                    </div>
                    {[{ id: 'check2checkbox', label: 'All Feedback Tables', tables: tablesToCheck },
                    { id: 'check3checkbox', label: 'IPO/NCD/BB/RI Tables', tables: ipoTablesToCheck },
                    { id: 'check4checkbox', label: 'Report Tables', tables: reportTablesToCheck },
                    { id: 'check5checkbox', label: 'Book Tables', tables: bookTablesToCheck },
                    { id: 'check6checkbox', label: 'Trading Tables', tables: tradingTablesToCheck }]
                        .map(({ id, label, tables }) => (
                            <div className="col-lg-2" key={id}>
                                <div className="form-check">
                                    <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="header_checkboxes"
                                        id={id}
                                        checked={checkboxes[id]}
                                        onChange={() => handleCheckMultipleTables(id, tables)}
                                    />
                                    <label className="form-check-label" htmlFor={id}>
                                        <b>{label}</b>
                                    </label>
                                </div>
                            </div>
                        ))}
                </div>
                {result.msg === 1 && result.sqlFindInTables && result.sqlFindInTables.length > 0 && (
                    <div className="row">
                        {result.sqlFindInTables.map((table) => (
                            <div className="col-lg-2" key={table.table_name}>
                                <div className="form-check">
                                    <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="table_checkboxes"
                                        id={`table-checkbox-${table.table_name}`}
                                        checked={tableCheckboxes[table.table_name]}
                                        onChange={() => handleCheckboxChange(table.table_name)}
                                    />
                                    <label className="form-check-label" htmlFor={`table-checkbox-${table.table_name}`}>
                                        {table.table_name}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <br />
                <div className="mb-3">
                    <Field as="textarea" className="form-control" name="sqlquery" rows="4" placeholder='Enter Data to find' maxLength={200}></Field>
                </div>
                <hr />
                <div className="mb-3 text-center">
                    <button
                        type="button"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Search
                        </span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
            {searchResult != undefined && Object.keys(searchResult).length > 0 && (
                <>
                    <h3>Search Result : </h3>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                {Object.keys(searchResult[0]).map((columnName, index) => (
                                    <th key={index}>{columnName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(searchResult).map((item, index) => (
                                <tr key={index}>
                                    {Object.values(item).map((value, subIndex) => (
                                        <td key={subIndex}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )

            }
        </>
    );
};

export default SQLFindInAllTablesForm;
