import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import { Link } from 'react-router-dom';
import NumberInput from "../../admintool9x/Common/NumberInput";

const RegisterForm = ({ result, handleGoBack, loading, recaptchaRef }) => {
  const { values, setFieldValue } = useFormikContext();
  const makeDisplayName = () => {
    setFieldValue("display_name", values.name);
  }

  const handleClick = (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_WEB_URL + 'article/rules-for-posting-comments/250/', 'popup', 'width=600,height=600');
  };
  return (
    <>

      <Form id='myForm'>
        <div className="container-fluid">
          <div className="row main">
            <div className="col-md-12 col-sm-12 pt-2">


              <p>Please fill the below information for simple registration.</p>

              <div className="mb-2 row">
                <label htmlFor="name" className="col-md-2">
                  Name
                </label>
                <div className="col-md-3">
                  <Field
                    type="text"
                    id="name"
                    tabIndex={9}
                    maxLength={50}
                    name="name"
                    placeholder="Your Full Name"
                    className="form-control"
                    onBlur={makeDisplayName}
                  />
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="display_name" className="col-md-2">
                  Display Name
                  <OverlayTrigger
                    trigger="focus"
                    placement="bottom"
                    overlay={
                      <Popover id="popover-display-name">
                        <Popover.Header as="h3">Display Name</Popover.Header>
                        <Popover.Body>
                          'Display Name' will be visible to all the users in the Discussion Forum.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="link" size="sm">
                      <img
                        src="/images/question_active.gif"
                        style={{ marginLeft: '5px', height: '15px', width: '15px' }}
                        alt="What is this?"
                        title="What is this?"
                      />
                    </Button>
                  </OverlayTrigger>
                </label>
                <div className="col-md-3">
                  <Field
                    type="text"
                    id="display_name"
                    tabIndex={10}
                    maxLength={50}
                    name="display_name"
                    placeholder="Your Display Name"
                    className="form-control"
                  />
                  <p className="text-warning">
                    Note: Restricted Display Name (Admin,Chittor,Chittorgarh)
                  </p>
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="phone" className="col-md-2">
                  Phone
                </label>
                <div className="col-md-3">
                  <NumberInput classNameAtr="form-control" tabIndexAtr={15} maxLength="12" nameAtr="phone" placeholder="Your Phone Number" />
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="email" className="col-md-2">
                  Email
                </label>
                <div className="col-md-3">
                  <Field
                    type="text"
                    tabIndex={20}
                    maxLength={50}
                    name="email"
                    placeholder="Your Email Address"
                    className="form-control"
                  />
                  <br />
                  <p className='mb-1'>
                    Note: Your <b>password</b> will be send to this email address.
                  </p>
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="city" className="col-md-2">
                  City
                </label>
                <div className="col-md-3">
                  <Field
                    type="text"
                    id="city"
                    tabIndex={25}
                    maxLength={50}
                    name="city"
                    placeholder="Your City Name"

                    className="form-control"
                  />
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="state" className="col-md-2">
                  State
                </label>
                <div className="col-md-3">
                  <Field as="select" className="form-select" tabIndex={30} name="state">
                    <option >Select State</option>
                    {result.stateList !== undefined && result.stateList.map((option) => (
                      <option key={option.name} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="" className="col-md-2">
                  Verify Code
                </label>
                <div className="col-md-3">
                  <span id="divLoading">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      tabIndex={35}
                      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                      onChange={(value) => setFieldValue('recaptcha', value)}
                    />
                  </span>
                </div>
              </div>

              <div className="mb-2 row">
                <label htmlFor="member_agree" className="col-md-2"></label>
                <div className="col-md-6">
                  <Field
                    className="form-check-input"
                    type="checkbox"
                    tabIndex={40}
                    name="member_agree"
                    onChange={(e) => { setFieldValue('member_agree', e.target.checked) }}
                  />
                  &nbsp;&nbsp;
                  <Link
                    to="#"
                    onClick={handleClick}
                    title="Terms & Conditions"
                  >
                    I have read and agree to the Terms & Conditions
                  </Link>
                  <p className="mt-4">
                    <mark>100% privacy - we will never spam you!</mark>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={45}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              Register
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={50}
          >close</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default RegisterForm
