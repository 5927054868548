import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPOReviewsForm from './IPOReviewsForm';
import * as Yup from 'yup';
import { Formik } from 'formik';

const IPOReviews = () => {

    const [formState, setFormState] = useState({
        ipoDisabled: false,
        ncdDisabled: false,
        riDisabled: false,
        bbDisabled: false
    })
    const [submitForm, setSubmitForm] = useState(false);

    const validationSchema = Yup.object({
        category_cd: Yup.string().required('Category is required'),
        title: Yup.string().required('Title is required'),
        review_author_name: Yup.string().required('Author Name is required'),
        review: Yup.string().required('Review is required'),
        recommendation: Yup.string().required('Recommendation is required'),
        conclusion: Yup.string().required('Conclusion is required'),

    }).test(
        function (value) {
            const { ipo_id, ncd_id, ri_id, bb_id } = value;
            if ((!formState.ipoDisabled && ipo_id == null) || (!formState.ncdDisabled && ncd_id == null) ||
                (!formState.riDisabled && ri_id == null) || (!formState.bbDisabled && bb_id == null)) {
                setSubmitForm(false);
                return false;
            }
            setSubmitForm(true)
            return true;
        }
    )

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        category_cd: "",
        title: "",
        recommendation: "",
        review: "",
        conclusion: "",
        review_author_name: "",
        review_author_signature_cd: "",
        added_by_member_id: "",
        report_link: "",
        lock_status: false,
        display_status: true,
        topic_id: "",
        ipo_id: "",
        ncd_id: "",
        ri_id: "",
        bb_id: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add Review" })
            } else {
                setMetaData({ "title": "Edit Reviews" })
            }
            api.post('/admin-review-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data);
                    if (!isAddMode) {
                        setInitialValues((prevValues) => ({
                            ...prevValues,
                            id: response.data.review[0].id,
                            category_cd: response.data.review[0].category_cd,
                            title: response.data.review[0].title,
                            recommendation: response.data.review[0].recommendation,
                            review: response.data.review[0].review,
                            conclusion: response.data.review[0].conclusion,
                            review_author_name: response.data.review[0].review_author_name,
                            review_author_signature_cd: response.data.review[0].review_author_signature_cd,
                            added_by_member_id: response.data.review[0].added_by_member_id,
                            report_link: response.data.review[0].report_link,
                            lock_status: response.data.review[0].lock_status,
                            display_status: response.data.review[0].display_status,
                            topic_id: response.data.review[0].topic_id,
                            ipo_id: response.data.review[0].category_cd == 1 ? response.data.review[0].topic_id : "",
                            ncd_id: response.data.review[0].category_cd == 2 ? response.data.review[0].topic_id : "",
                            ri_id: response.data.review[0].category_cd == 3 ? response.data.review[0].topic_id : "",
                            bb_id: response.data.review[0].category_cd == 4 ? result.review[0].topic_id : ""
                        }));
                    }
                    else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.reviewNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {

        if (submitForm == false) {
            dispatch(setMessage('Please fill the field related to the category selected!'));
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());


        api.post('/admin-review-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate(-1);
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());

        });
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.review !== undefined &&
                result.review.length > 0 &&
                result.review[0].lock_status == 1 && (
                    <h3 className="text-danger mt-3 mb-0">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IPOReviewsForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} formState={formState} setFormState={setFormState} />
            </Formik>
        </>
    )
}

export default IPOReviews
