import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import { funCreateSlug } from '../Common/CommonFunction';
import FileUpload from '../Common/FileUpload';
import CharacterCountInput from '../Common/CharacterCountInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const validationSchema = Yup.object({
  term: Yup.string().required('Please enter term'),
  category_cd: Yup.string().required('Please select Category'),
  definition: Yup.string().required('Definition is required'),
  description: Yup.string().required('Please enter in description'),
  urlrewrite_folder_name: Yup.string().required('URLRewrite_Folder_Name is required'),
});

const GlossaryForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldTouched }) => (


          <Form id='myForm'>
            {result !== undefined && result.glossary !== undefined && result.glossary.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="id" className="col-lg-2">ID:</label>
                <div className="col-lg-4">
                  {result.glossary[0].id}
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="term" className="col-lg-2 required">Term</label>
              <div className="col-lg-4">
                <Field className="form-control" type='text' name="term" tabIndex={1}
                  onBlur={(e) => {
                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                    if (userConfirmed) {
                      var slug = funCreateSlug(e.target.value)
                      setFieldValue('urlrewrite_folder_name', slug);
                    } else {
                      setFieldTouched('term', true);
                    }
                  }}
                />
              </div>
              <label htmlFor="category_cd" className="col-lg-2 required">Category</label>
              <div className="col-lg-4">
                <Field as="select" name="category_cd" className="form-select" tabIndex={5}>
                  <option ></option>
                  {result.categoryList !== undefined &&
                    result.categoryList.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.code_desc} {'-'} {option.cat_name}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
            <div className='mb-3 row'>
              <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
              <div className="col-lg-10">
                <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="image_name" className="col-lg-2">Image Name:</label>
              <div className="col-lg-2">
                <Field className="form-control" type="text" maxLength="100" tabIndex={10} name="image_name" />
              </div>
              <label htmlFor="image_width" className="col-lg-1">Width:</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} type="text" tabIndex={15} maxLength="3" nameAtr={"image_width"} />
              </div>
              <label htmlFor="image_height" className="col-lg-1">Height:</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} type="text" maxLength="3" tabIndex={20} nameAtr={"image_height"} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="definition" className="col-lg-2 required">Definition <br /> (Text) (Max 160 Char)</label>
              <div className="col-lg-10">
                <CharacterCountInput
                  nameattr="definition"
                  type="text"
                  maxLength="160"
                  tabIndex="25"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="description" className="col-lg-2 required">Description<br /> (in HTML)</label>
              <div className="col-lg-10">
                <CharacterCountTextarea
                  nameattr="description"
                  type="textarea"
                  tabIndex="30"
                  rows="15"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
              <div className="col-lg-4">
                <Field className="form-control" type='text' tabIndex={35} name="urlrewrite_folder_name" maxLength="50" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="report_ids" className="col-lg-2">Report IDs</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} type="text" tabIndex={40} maxLength="10" nameAtr={"report_ids"} />
              </div>
              <label htmlFor="broker_id" className="col-lg-2 control-label">Broker</label>
              <div className="col-lg-4">
                <Field as="select" name="broker_id" className="form-select" tabIndex={45}>
                  <option></option>
                  {result.brokerList !== undefined && result.brokerList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.company_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="display_order" className="col-lg-2">Display Order</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} type='text' nameAtr={"display_order"} tabIndex={50} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="display_status" className="col-lg-2">Display Status</label>
              <div className="col-lg-2">
                <Field className="form-check-input" type="checkbox" tabIndex={55} name="display_status"
                  onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
              </div>
              <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
              <div className="col-lg-2">
                <Field className="form-check-input" type="checkbox" tabIndex={60} name="lock_status"
                  onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
              </div>
            </div>
            <hr />
            <div className="mb-3 text-center">

              {values.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={65}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.glossary !== undefined && result.glossary.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={70}
              >Cancel</button>
            </div>
            <ValidateFormFields />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default GlossaryForm
