import React, { useEffect } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const IPOReviewsForm = ({ result, handleGoBack, initialValues, loading, formState, setFormState }) => {
  const { values, setFieldValue } = useFormikContext();


  useEffect(() => {
    if (values.category_cd == 1) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ipoDisabled: false
      }));
      setFieldValue('ncd_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is IPO, IPO field is mandatory!")
    }
    if (values.category_cd == 2) {
      setFormState((prevState) => ({
        ...prevState,
        ipoDisabled: true,
        riDisabled: true,
        bbDisabled: true,
        ncdDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is NCD, NCD field is mandatory!")
    }
    if (values.category_cd == 3) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        ipoDisabled: true,
        bbDisabled: true,
        riDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ncd_id', "");
      setFieldValue('bb_id', "");
      // alert("Since the chosen category is RI, RI field is mandatory!")
    }
    if (values.category_cd == 4) {
      setFormState((prevState) => ({
        ...prevState,
        ncdDisabled: true,
        riDisabled: true,
        ipoDisabled: true,
        bbDisabled: false
      }));
      setFieldValue('ipo_id', "");
      setFieldValue('ri_id', "");
      setFieldValue('ncd_id', "");
      // alert("Since the chosen category is BB, BB field is mandatory!")
    }
  }, [values.category_cd])

  const funSetReportTitle = (companyName, listing, reviewCategory) => {
    if (listing == "BSE, NSE") {
      listing = '';
    }
    const title = companyName + (listing ? " " + listing : "") + " " + reviewCategory;
    setFieldValue("title", title.trim())
  }

  return (
    <>
      <Form id='myForm'>

        {result !== undefined && result.review !== undefined && result.review.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="category_cd" className="col-lg-2 required">Category</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={5} name="category_cd" >
              <option disabled selected ></option>
              {result.reviewLists !== undefined && result.reviewLists.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}
            </Field>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="ipo_id" className="col-lg-2">IPO</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={10} name="ipo_id" disabled={formState.ipoDisabled}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedCompany = result.ipoLists.find(option => option.id == selectedId);
                setFieldValue('topic_id', e.target.value);
                setFieldValue('ipo_id', e.target.value);
                funSetReportTitle(selectedCompany?.company_name, selectedCompany?.ipo_listing_at, " IPO Review");
              }}
            >
              <option disabled selected ></option>
              {result.ipoLists !== undefined && result.ipoLists.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.company_name}
                </option>
              ))}
            </Field >
          </div>

          <label htmlFor="ncd_id" className="col-lg-2">NCD</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={15} name="ncd_id" disabled={formState.ncdDisabled}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedCompany = result.ncdLists.find(option => option.id == selectedId);
                const concatenatedField = selectedCompany ? selectedCompany['concat(company_name ,\' - \' ,  date_format(Issue_Open_Date,\'%Y/%m/%d\'))'] : '';
                const companyName = concatenatedField.split(' - ')[0];

                setFieldValue('topic_id', e.target.value);
                setFieldValue('ncd_id', e.target.value);
                funSetReportTitle(companyName, selectedCompany?.listing_at, " NCD Review");
              }}

            >
              <option disabled selected ></option>
              {result.ncdLists !== undefined && result.ncdLists.map((option) => (
                <option key={option.id} value={option.id}>
                  {option['concat(company_name ,\' - \' ,  date_format(Issue_Open_Date,\'%Y/%m/%d\'))']}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="ri_id" className="col-lg-2">RI</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={20} name="ri_id" disabled={formState.riDisabled}
              onChange={(e) => {
                const selectdId = e.target.value;
                const selectedCompany = result.riLists.find(option => option.id == selectdId)
                setFieldValue('topic_id', e.target.value);
                setFieldValue('ri_id', e.target.value);
                funSetReportTitle(selectedCompany?.company_name, selectedCompany?.listing_at, " RI Review");

              }}>
              <option disabled selected ></option>
              {result.riLists !== undefined && result.riLists.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.company_name}
                </option>
              ))}
            </Field >
          </div>

          <label htmlFor="bb_id" className="col-lg-2">BB</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={25} name="bb_id" disabled={formState.bbDisabled}
              onChange={(e) => {
                const selectdId = e.target.value;
                const selectedCompany = result.bbLists.find(option => option.id == selectdId)
                setFieldValue('topic_id', e.target.value);
                setFieldValue('bb_id', e.target.value);
                funSetReportTitle(selectedCompany?.company_name, selectedCompany?.listing_at, " BB Review");
              }}
            >
              <option disabled selected ></option>
              {result.bbLists !== undefined && result.bbLists.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.company_name}
                </option>
              ))}
              Report Title
            </Field >
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="title" className="col-lg-2 required">Review Title</label>
          <div className="col-lg-4">
            <Field className="form-control" type='text' tabIndex={30} name="title" maxLength={100} />
          </div>
          <label htmlFor="recommendation" className="col-lg-2 required">Recommendation</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={35} name="recommendation">
              <option disabled selected ></option>
              {result.recommendationLists !== undefined && result.recommendationLists.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}

            </Field >
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="review_author_name" className="col-lg-2 required">Author Name</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={40} name="review_author_name" >
              <option disabled selected ></option>
              {result.authorLists !== undefined && result.authorLists.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code_desc}
                </option>
              ))}

            </Field >
          </div>
          <label htmlFor="review_author_signature_cd" className="col-lg-2">Signature</label>
          <div className="col-lg-4">
            <Field as="select" className="form-select" tabIndex={45} name="review_author_signature_cd" >
              <option disabled selected ></option>
              {result.authorSignatureLists !== undefined && result.authorSignatureLists.map((option) => (
                <option key={option.code} value={option.code}>
                  {<span dangerouslySetInnerHTML={{ __html: option.code }}></span>}
                  {/* Signature is too long to read */}
                </option>
              ))}
            </Field >
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="review" className="col-lg-2 required">Review (html)</label>
          <div className="col-lg-10">
            <CharacterCountTextarea
              nameattr="review"
              type="textarea"
              tabIndex="50"
              rows="10"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="conclusion" className="col-lg-2 required">Conclusion (text)</label>
          <div className="col-lg-10">
            <Field as="textarea" className="form-control" tabIndex={55} name="conclusion" rows="4" />

          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-2 required">Display Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" tabIndex={60} name="display_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
          <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
          <div className="col-lg-4">
            <Field className="form-check-input" type="checkbox" tabIndex={61} name="lock_status" onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('lock_status', newValue);
            }} />
          </div>
        </div>

        <div className="mb-3 text-center">
          {!initialValues.lock_status == 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
              tabIndex={65}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              <span>
                {result.review !== undefined && result.review.length > 0 ? 'Update' : 'Create'}
              </span>
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={70}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default IPOReviewsForm





