import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';

const validationSchema = Yup.object({
    title: Yup.string().trim().required('Name is required'),
    filename: Yup.string().trim().required('File Name is required'),
    selected_script: Yup.string().trim().required('Script type is required'),
    cron_time_type: Yup.string().trim().required('Cron time type is required'),
    target_url: Yup.string().trim().required('Target url is required'),
});

const CronjobForm = ({ result, handleGoBack, initialValues, handleSubmit, loading, }) => {

    const scriptTypeList = result?.cronScriptTypeList ?? [];
    const timeTypeList = result?.cronTimeTypeList ?? [];

    const minuteList = [1, 2, 5, 10, 15, 30, 59];
    const hourList = [1, 2, 3, 4, 5, 6];
    const monthList = [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sep" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" }
    ];
    function generateCronExpression({ type, time, dayOfMonth, month }) {

        let cronExpression = '';
        switch (type) {
            case 'minute':
                if (!time) {
                    return { msg: -1, error: 'Minute interval is required for type "minute".' };
                }
                cronExpression = `*/${time} * * * *`;
                break;

            case 'hour':
                if (!time) {
                    return { msg: -1, error: 'Hour interval is required for type "hour".' };
                }
                cronExpression = `0 */${time} * * *`;
                break;

            case 'day':
                if (!time) {
                    return { msg: -1, error: 'Time is required for type "day".' };
                }
                const [hourDay, minuteDay] = time.split(':');
                cronExpression = `${minuteDay} ${hourDay} * * *`;
                break;

            case 'month':
                if (!dayOfMonth || !time) {
                    return { msg: -1, error: 'Day of month and time are required for type "month".' };
                }
                const [hourMonth, minuteMonth] = time.split(':');
                cronExpression = `${minuteMonth} ${hourMonth} ${dayOfMonth} * *`;
                break;

            case 'year':
                if (!dayOfMonth || !time) {
                    return { msg: -1, error: 'Date and time are required for type "year".' };
                }
                // const [monthYear, dayYear] = date.split('-');
                const [hour, minute] = time.split(':');
                cronExpression = `${minute} ${hour} ${dayOfMonth} ${month} *`;
                break;

            default:
                return { msg: -1, error: 'Invalid type. Supported types: minute, day, month, year.' };
        }
        // if (!cron.validate(cronExpression)) {
        //     return { msg: -1, error: 'Invalid cron time format' };
        // }
        // Successfully generated cron expression
        return { msg: 1, cronExpression };
    }




    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (

                    <Form id='myForm'>
                        {result !== undefined && result.cronjob !== undefined && result.cronjob.length > 0 &&
                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">ID:</label>
                                <div className="col-lg-4">
                                    {result.cronjob[0].id}
                                </div>
                            </div>
                        }

                        <div className="mb-3 row">
                            <label htmlFor="title" className="col-lg-2 required">Name</label>
                            <div className="col-lg-6">
                                <Field className="form-control" type="text" name="title" maxLength="100" tabIndex={1} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="filename" className="col-lg-2 required">Filename</label>
                            <div className="col-lg-6">
                                <Field className="form-control" type="text" name="filename" maxLength="150" tabIndex={5} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="filename" className="col-lg-2 required">Target URL</label>
                            <div className="col-lg-6">
                                <Field className="form-control" type="text" name="target_url" maxLength="150" tabIndex={5} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="cron_id" className="col-lg-2">Cron ID</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text" name="cron_id" maxLength="20" tabIndex={10} />
                            </div>
                        </div>


                        <div className="mb-3 row">

                            <label htmlFor="selected_script" className="col-lg-2 required">Script Type:</label>
                            <div className='col-lg-8'>
                                <select
                                    value={values?.selected_script}
                                    className="form-select"
                                    name="selected_script" onChange={(e) => {
                                        let newValue = e.target.value
                                        setFieldValue('selected_script', newValue);
                                    }}
                                >
                                    <option value="">Select Script</option>
                                    {scriptTypeList.map(script => (
                                        <option key={script.code} value={script.code}>
                                            {script.code_desc}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">

                            <label htmlFor="cronTimeType" className="col-lg-2 required">Schedule Type:</label>
                            <div className='col-lg-8'>
                                <select
                                    className="form-select"
                                    name="cronTimeType"
                                    value={values?.cron_time_type}

                                    onChange={(e) => {
                                        let newValue = e.target.value
                                        setFieldValue("selected_day", "")
                                        setFieldValue("selected_time", "")
                                        setFieldValue("selected_month", "")
                                        setFieldValue("expression", "")
                                        setFieldValue('cron_time_type', newValue);
                                    }}
                                >
                                    <option value="">Select Interval</option>
                                    {timeTypeList.map(type => (
                                        <option key={type.code} value={type.code}>
                                            {type.code_desc}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {values.cron_time_type == "minute" && (
                            <div className="mb-3 row">
                                <label htmlFor="selected_time" className="col-lg-2 required">Minute Interval:</label>
                                <div className='col-lg-8'>
                                    <select
                                        className="form-select"
                                        name="selected_time"
                                        value={values?.selected_time}
                                        onChange={(e) => {
                                            setFieldValue("selected_time", e.target.value)

                                            let result = generateCronExpression({ type: values.cron_time_type, time: e.target.value, dayOfMonth: 0 });
                                            setFieldValue("expression", result.cronExpression)
                                        }}
                                    >
                                        <option value="">Select Minute</option>
                                        {minuteList.map(minute => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        )}
                        {values.cron_time_type == "hour" && (
                            <div className="mb-3 row">
                                <label htmlFor="selected_time" className="col-lg-2 required">Hour Interval:</label>
                                <div className='col-lg-8'>
                                    <select
                                        className="form-select "
                                        name="selected_time"
                                        value={values?.selected_time}
                                        onChange={(e) => {
                                            setFieldValue("selected_time", e.target.value)
                                            let result = generateCronExpression({ type: values.cron_time_type, time: e.target.value });
                                            setFieldValue("expression", result.cronExpression)
                                        }}
                                    >
                                        <option value="">Select Hour Interval</option>
                                        {hourList.map(hour => (
                                            <option key={hour} value={hour}>
                                                {hour}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        )}
                        {values.cron_time_type == "day" && (
                            <div className="mb-3 row">
                                <label htmlFor="selected_time" className="col-lg-2 required">Time:</label>
                                <div className='col-lg-8'>
                                    <Field type="time" className="form-control" onBlur={(e) => {
                                        console.log("event", e)
                                        let result = generateCronExpression({ type: values.cron_time_type, time: e.target.value })
                                        setFieldValue("expression", result.cronExpression)
                                    }} name="selected_time" />

                                </div>
                            </div>

                        )}
                        {values.cron_time_type == "month" && (
                            <>
                                <div className="mb-3 row">
                                    <label htmlFor="selected_day" className="col-lg-2 required">Day:</label>
                                    <div className='col-lg-8'>
                                        <select
                                            className="form-select"
                                            name="selected_day"
                                            value={values.selected_day}
                                            onChange={(e) => {
                                                setFieldValue("selected_day", e.target.value)
                                                console.log(e)
                                                let result = generateCronExpression({ type: values.cron_time_type, time: values.selected_time, dayOfMonth: e.target.value });
                                                setFieldValue("expression", result.cronExpression)
                                            }}
                                        >
                                            <option value="">Select Date</option>
                                            {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                                                <option key={day} value={day}>
                                                    {day}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="selected_time" className="col-lg-2 required">Time:</label>
                                    <div className='col-lg-8'>
                                        <Field type="time" className="form-control" onBlur={(e) => {
                                            let result = generateCronExpression({ type: values.cron_time_type, time: e.target.value, dayOfMonth: values.selected_day });
                                            setFieldValue("expression", result.cronExpression)
                                        }} name="selected_time" />

                                    </div>
                                </div>
                            </>
                        )}
                        {values.cron_time_type == "year" && (
                            <>
                                <div className="mb-3 row">

                                    <label htmlFor="selected_month" className="col-lg-2 required">Month:</label>
                                    <div className='col-lg-8'>
                                        <select
                                            className="form-select"
                                            name="selected_month"
                                            value={values.selected_month}
                                            onChange={(e) => {
                                                setFieldValue("selected_month", e.target.value)
                                                let result = generateCronExpression({ type: values.cron_time_type, time: values.selected_time, dayOfMonth: values.selected_day, month: e.target.value });
                                                setFieldValue("expression", result.cronExpression)
                                            }}
                                        >
                                            <option value="">Select Month</option>
                                            {monthList.map(month => (
                                                <option key={month.id} value={month.id}>
                                                    {month.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label htmlFor="selected_day" className="col-lg-2 required">Day:</label>
                                    <div className='col-lg-8'>
                                        <select
                                            className="form-select "
                                            name="selected_day"
                                            value={values.selected_day}
                                            onChange={(e) => {
                                                setFieldValue("selected_day", e.target.value)
                                                let result = generateCronExpression({ type: values.cron_time_type, time: values.selected_time, dayOfMonth: e.target.value, month: values.selected_month });
                                                setFieldValue("expression", result.cronExpression)
                                            }}
                                        >
                                            <option value="">Select Date</option>
                                            {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                                                <option key={day} value={day}>
                                                    {day}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="selected_time" className="col-lg-2 required">Time:</label>
                                    <div className='col-lg-8'>
                                        <Field type="time" onBlur={(e) => {
                                            let result = generateCronExpression({ type: values.cron_time_type, time: e.target.value, dayOfMonth: values.selected_day, month: values.selected_month });
                                            setFieldValue("expression", result.cronExpression)
                                        }} className="form-control" name="selected_time" />

                                    </div>
                                </div>
                            </>


                        )}

                        <div className="mb-3 row">
                            <label htmlFor="selected_time" className="col-lg-2 required">Expression:</label>
                            <div className='col-lg-8'>
                                <Field type="text"
                                    disabled={values.cron_time_type != "custom"}
                                    className="form-control"
                                    name="expression"
                                    placeholder='* * * * *' />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="output_log" className="col-lg-2">Manual Page Output Log</label>
                            <div className="col-lg-8">
                                <Field as="textarea" className="form-control" rows="6" name="output_log" tabIndex={15} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="cron_output" className="col-lg-2">Cron Output Log</label>
                            <div className="col-lg-8">
                                <Field as="textarea" className="form-control" rows="6" name="cron_output" tabIndex={15} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="cron_output" className="col-lg-2">Admin Comment</label>
                            <div className="col-lg-8">
                                <Field as="textarea" className="form-control" rows="6" name="admin_comment" tabIndex={15} />
                            </div>
                        </div>

                        <div className="my-3 row">
                            <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                            <div className="col-lg-2">
                                <Field className="form-check-input" type="checkbox" name="display_status" onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue('display_status', newValue);
                                }}
                                    tabIndex={20} />
                            </div>
                        </div>


                        <div className="mb-3 text-center">
                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={loading}
                                tabIndex={25}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                <span>
                                    {result.cronjob !== undefined && result.cronjob.length > 0 ? 'Update' : 'Create'}
                                </span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={30}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CronjobForm
