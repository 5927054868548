import { React, useCallback, useState } from 'react'
import { Field, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import useApi from '../../../common/useApi';
import AutocompleteField from '../Common/AutocompleteField';
import { debounce } from 'lodash';

const IpoPeerGroupAnalysisForm = ({ remove, result }) => {
    const [isAutocomplteLoading, setIsAutocomplteLoading] = useState(false);
    const [autoComplteOptions, setAutoComplteOptions] = useState([]);
    const api = useApi();
    const { setFieldValue, values } = useFormikContext();


    const handleAutoSearch = async (query) => {
        debouncedHandleAutoSearch(query);
    };

    const debouncedHandleAutoSearch = useCallback(debounce(async (query) => {
        console.log(query);
        setIsAutocomplteLoading(true);
        try {
            const response = await api.post('/admin-listofscript-list', { search: query });
            if (response.data.msg === 1) {

                const options = response.data.details.map(item => ({
                    label: `${item.company_name} (${item.bse_script_id ?? ""}) (${item.bse_isin ?? ""}) (${item.bse_script_code ?? ""}) (${item.nse_script_id ?? ""}) (${item.nse_isin ?? ""})`,
                    value: item
                }));
                setAutoComplteOptions(options);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsAutocomplteLoading(false);
        }
    }, 300), []); // 300 ms debounce delay


    const handleAutoSelect = (selected, index) => {
        handleAutoBlur(selected, index);
    };


    const handleAutoBlurText = async (e, i) => {
        setFieldValue(`peerGroupList.${i}.company_name`, e.target.value);
    }
    const handleAutoBlur = async (selected, i) => {
        const item = selected ? selected.value : null;
        const companyName = item ? item.company_name : "";
        const companyId = item ? item.id : "";

        // Update the field value for company_name
        setFieldValue(`peerGroupList.${i}.company_name`, companyName ?? "");
        setFieldValue(`peerGroupList.${i}.company_id`, companyId ?? "");
        if (item) {
            const userConfirmed = window.confirm(`Are you sure to update closing price and Company name for row number ${i + 1}?`);

            if (userConfirmed) {
                if (values.peer_group_date == null || values.peer_group_date === "") {
                    alert("Make sure that the Date (Period Ended) is not empty.");
                    return;
                }

                let id = item ? (item.bse_script_id ?? item.nse_script_id) : null;

                if (id) {
                    try {
                        const response = await api.post('/admin-stock-closing-price', {
                            search: id,
                            date: values.peer_group_date
                        });

                        if (response.data.msg === 1 && response.data.stockPrice !== undefined) {
                            setFieldValue(`peerGroupList.${i}.closing_price_period`, response.data.stockPrice);
                            console.log(`The price at row ${i} is :`, values.peerGroupList[i].closing_price_period ?? '');
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            }
        }
    };

    const updatePBVRatio = () => {
        for (let i = 0; i < values.peerGroupList.length; i++) {
            if (values.peerGroupList[i] !== undefined && (values.peerGroupList[i].closing_price_period !== null && values.peerGroupList[i].closing_price_period != '') &&
                (values.peerGroupList[i].nav != null && values.peerGroupList[i].nav != '')) {
                let closing_price_value = values.peerGroupList[i].closing_price_period;
                let nav_value = values.peerGroupList[i].nav;
                setFieldValue(`peerGroupList.${i}.p_bv_ratio`, (parseInt(closing_price_value) / parseInt(nav_value)).toFixed(2));
            }
            else {
                setFieldValue(`peerGroupList.${i}.p_bv_ratio`, "");
            }
        }
    }

    const trimValue = (val) => (typeof val === 'string' ? val.trim() : val);

    return (
        <>
            {values.peerGroupList.map((peer_group, index) => (
                <tr key={index}>
                    <td>
                        <Field
                            name={`peerGroupList.${index}.company_name`}
                            as={AutocompleteField}
                            options={autoComplteOptions}
                            onInputChange={(query) => handleAutoSearch(trimValue(query))}
                            onInputSelect={(selected) => { handleAutoSelect(selected, index) }}
                            onBlur={(e) => handleAutoBlurText(e, index)}
                            selected={values.peerGroupList[index].company_name ? [values.peerGroupList[index].company_name] : []}
                            isAutocomplteLoading={isAutocomplteLoading}
                            idatr={`company_name_txt_${index}`}
                            nameatr={`peerGroupList.${index}.company_name`}
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.eps`} placeholder="Eps Basic"
                        />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.eps_diluted`} placeholder="Diluted EPS" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.nav`} placeholder="NAV"
                            dynamicOnBlur={() => { updatePBVRatio() }} />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.pe_ratio`} placeholder="PE Ratio" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.ronw`} placeholder="RONW" />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.closing_price_period`} placeholder="Closing Price"
                            dynamicOnBlur={() => { updatePBVRatio() }} />
                    </td>
                    <td>
                        <NumberDecimalInput classNameAtr="form-control" maxLength="10" nameAtr={`peerGroupList.${index}.p_bv_ratio`} placeholder="P BV Ratio" />
                    </td>
                    <td>
                        <Field as="select" name={`peerGroupList.${index}.peer_group_financial_stmt`} className="form-select">
                            <option></option>
                            {result.financialStmtList !== undefined && result.financialStmtList.map((option) => (
                                <option key={option.code} value={option.code}>
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </td>
                    <td className="text-center">
                        <div className="form-check form-switch fs-4">
                            <Field className="form-check-input" type="checkbox"
                                name={`peerGroupList.${index}.display_status`} onChange={(e) => {
                                    const newValue = e.target.checked;
                                    setFieldValue(e.target.name, newValue);
                                }}
                            />
                        </div>
                    </td>

                    <td>
                        <button type="button" className='btn btn-danger btn-sm' onClick={() => remove(index)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            ))}

        </>
    )
}

export default IpoPeerGroupAnalysisForm;