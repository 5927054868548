import React, { useState, useEffect } from "react";
import { Formik, Field, Form, useFormikContext } from "formik";
import ValidateFormFields from "../Common/ValidateFormFields";

const BrokerComplaintUploadForm = ({
  result,
  handleGoBack,
  loading,
}) => {

  return (
    <Form id="myForm">

      <h1>Upload Zerodha Leads Status from Dashboard CSV</h1>
      <p>Upload 20,000 records at a time. Copy paste records from excel to below box.</p>
      <hr />
      <div className="mb-3 row">
        <label htmlFor="clientdata" className="col-lg-2">Data</label>
        <div className="col-lg-10">
          <Field as="textarea" className="form-control" name="clientdata" rows="10" tabIndex={10} />
        </div>
      </div>

      <div className="mb-3 text-center">
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={loading}
          tabIndex={15}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm me-2"></span>
          )}
          <span>
            Submit
          </span>
        </button>

        <button
          type="button"
          className="btn btn-secondary btn-block ms-2"
          onClick={handleGoBack}
          tabIndex={20}
        >
          Cancel
        </button>
      </div>

      <ValidateFormFields />
    </Form>
  );
};

export default BrokerComplaintUploadForm;
