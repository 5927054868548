import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import OFSForm from './OFSForm';
import { Formik } from 'formik';
import OFSTabs from './OFSTabs';

const OFS = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('company_name is required'),
        news_title: Yup.string().trim().required('news title is required'),
        urlrewrite_folder_name: Yup.string().trim().required('URLRewrite_Folder_Name is required'),
    }
    )

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        news_title: "",
        urlrewrite_folder_name: "",
        nse_symbol: "",
        bse_scripcode: "",
        bse_ipo_no: "",

        issue_type: "Offer For Sale",
        ofs_listing_at: "",
        face_value: "",
        base_issue_size_shares: "",
        oversubscription_shares: "",
        total_issue_size_share: "",
        cutoff_price: "",
        retail_shares: "",
        non_retail_Shares: "",
        floor_price: "",
        seller_name: "",
        opening_ofs_link: "",
        live_activities_schedule: "",
        oversubscription_notice: "",
        undersubscription_notice: "",
        percentage_of_equity_shares: "",
        offer_open_date_non_retail: "",
        issue_process_type: "1",
        offer_open_date_retail: "",
        gen_category: "",
        retail_category: "",
        emp_category: "",
        logo_url: "",
        logo_length: "",
        logo_height: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        phone: "",
        email: "",
        website: "",
        company_desc: "",
        initialBrokerValues: [],
        selectedBrokerValues: [],
        selectedBrokerIds: [],
        other_issue_detail: "",
        display_order: "",
        lock_status: false,
        display_status: true,
        isin: "",
        financial: "",
        list_of_script_id: "",
        ofs_listing_at_array: ["BSE", "NSE"],
        stock_name_auto: "",
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/132"); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New OFS" })
            } else {
                setMetaData({ "title": "Edit OFS" })
            }
            api.post('/admin-ofs-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    if (!isAddMode) {
                        console.log("chk response data : ", response.data.ofs[0])
                        if (response.data.ofs[0].ofs_listing_at !== undefined && response.data.ofs[0].ofs_listing_at !== null && response.data.ofs[0].ofs_listing_at.trim().length > 0) {
                            response.data.ofs[0].ofs_listing_at_array = response.data.ofs[0].ofs_listing_at.split(",").map(item => item.trim());
                        } else {
                            response.data.ofs[0].ofs_listing_at_array = [];
                        }
                        response.data.ofs[0].company_name_auto = response.data.ofs[0].company_name;
                        if (response.data.ofs[0].offer_open_date_non_retail !== "" && response.data.ofs[0].offer_open_date_non_retail !== undefined) {
                            response.data.ofs[0].offer_open_date_non_retail = new Date(response.data.ofs[0].offer_open_date_non_retail).toISOString().split("T")[0];
                        }

                        if (response.data.ofs[0].offer_open_date_retail !== '' && response.data.ofs[0].offer_open_date_retail !== undefined) {
                            response.data.ofs[0].offer_open_date_retail = new Date(response.data.ofs[0].offer_open_date_retail).toISOString().split("T")[0];
                        }
                        if (response.data.ofs[0].broker_ids && response.data.ofs[0].broker_ids.trim().length > 0) {
                            response.data.ofs[0].selectedBrokerIds = response.data.ofs[0].broker_ids.split(",").map(item => parseInt(item.trim()));
                        } else {
                            response.data.ofs[0].selectedBrokerIds = [];
                        }
                        setInitialValues(response.data.ofs[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ofsNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            }).catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const validate = (values) => {
        if (values.ofs_listing_at_array.length == 0) {
            alert("Select at least on exchange");
            return false;
        }
        return true;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        console.log("Listing at itmes are", trimmedValues.ofs_listing_at_array)
        trimmedValues.ofs_listing_at = trimmedValues.ofs_listing_at_array.join(', ');

        if (trimmedValues.offer_open_date_non_retail !== '' && trimmedValues.offer_open_date_non_retail !== undefined) {
            trimmedValues.offer_open_date_non_retail = new Date(trimmedValues.offer_open_date_non_retail).toISOString().split('T')[0];
        }

        if (trimmedValues.offer_open_date_retail !== '' && trimmedValues.offer_open_date_retail !== undefined) {
            trimmedValues.offer_open_date_retail = new Date(trimmedValues.offer_open_date_retail).toISOString().split('T')[0];
        }
        let idStr = trimmedValues.selectedBrokerIds
            .filter(id => id) // Filters out falsy values
            .join(","); // Joins the filtered IDs with a comma

        if (idStr) {
            trimmedValues.selectedIds = idStr;
        }
        if (validate(trimmedValues)) {
            dispatch(startLoading());
            api.post('/admin-ofs-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    // navigate("/admintool9x/report/132");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());
                });
        }
        setSubmitting(false);
    };

    return (
        <>
            {!isAddMode && <OFSTabs idAtr={id} />}
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.ofs !== undefined &&
                result.ofs.length > 0 &&
                result.ofs[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <OFSForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} />
            </Formik>

        </>
    )
}

export default OFS;