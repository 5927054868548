import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { finishLoading, selectLoading, startLoading } from "../../state/slices/loadingSlice";
import useApi from "../../common/useApi";
import { clearMessage, setMessage } from "../../state/slices/message";
import routeConfig from "../../routeConfig";
import { funFormatDateTime } from "../admintool9x/Common/CommonFunction";

const GMPEdit = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const api = useApi();
    const [initialValues, setInitialValues] = useState({
        gmpDetails: []
    });

    const [result, setResult] = useState([]);
    const [touchedFields, setTouchedFields] = useState([])

    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const callReadApi = () => {
        api.post('/greymarketpage-read')
            .then(response => {
                if (response.data.msg === 1) {
                    setInitialValues({ gmpDetails: response.data.gmpDetails });
                    setResult(response.data);
                } else {
                    dispatch(setMessage(response.data.error));
                    if(response.data.msg == -2) {
                        navigate("/")
                    }
                }
                dispatch(finishLoading());
            })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());

            callReadApi();
        } else {
            didMountRef.current = "true";
        }
    }, [api, dispatch]);

    const handleSubmit = (values) => {

        for (let i = 0; i < values.gmpDetails.length; i++) {
            let stringToCheckGmp = i.toString() + "$" + 'gmp';
            let stringToCheckHni = i.toString() + "$" + 's2s';
            let stringToCheckHniS2s = i.toString() + "$" + 's2s_hni';

            if (!touchedFields.includes(stringToCheckGmp)) values.gmpDetails[i].gmp = "";
            if (!touchedFields.includes(stringToCheckHni)) values.gmpDetails[i].s2s = "";
            if (!touchedFields.includes(stringToCheckHniS2s)) values.gmpDetails[i].s2s_hni = "";
        }

        const trimmedValues = values.gmpDetails.map(item => {
            const { id, last_modified_dt, ...rest } = item; // Destructure to remove 'id' and 'last_modified_dt field
            return Object.keys(rest).reduce((acc, key) => {
                acc[key] = typeof rest[key] === 'string' ? rest[key].trim() : (typeof rest[key] === 'boolean' ? (rest[key] ? 1 : 0) : rest[key]);
                return acc;
            }, {});
        });

        let gmpDetails = trimmedValues

        console.log("Trimmed Values: ", gmpDetails);

        api.post('/greymarketpage-save', { values: { gmpDetails } })
            .then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    setInitialValues({});
                    setTouchedFields([]);
                    callReadApi();
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
            .catch(error => {
                console.error('Error:', error);
                dispatch(setMessage('An error occurred while saving the data.'));
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    };

    return (
        <>
            <Helmet>
                <title>Add New GMP</title>
            </Helmet>

            <div className='row main mt-3'>
                <div className='col-12'>
                    <h1>Add New GMP</h1>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={(values) => handleSubmit(values)}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <table className="table table-sm table-bordered table-striped" id="repeater1">
                                    <thead>
                                        <tr className="bg-secondary text-white">
                                            <th>IPO Name</th>
                                            <th>GMP</th>
                                            <th>LAST GMP</th>
                                            <th>S2S</th>
                                            <th>LAST S2S</th>
                                            <th>S2S HNI</th>
                                            <th>LAST S2S HNI</th>
                                            <th>Last Entry Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.gmpDetails && values.gmpDetails.length > 0 ? (
                                            values.gmpDetails.map((row, index) => (
                                                <tr key={index} className="r-group">
                                                    <td>
                                                        <input type="hidden" value={row.ipo_id} name={`gmpDetails[${index}].ipo_id`} id={`ipo_id_${index}`} />
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.Company_Name}
                                                            name={`gmpDetails[${index}].Company_Name`}
                                                            id={`company_name_${index}`}
                                                            style={{ minWidth: '200px' }}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control two-decimals"
                                                            maxLength="10"
                                                            id={`gmp_${index}`}
                                                            onChange={(e) => {
                                                                setFieldValue(`gmpDetails[${index}].gmp`, e.target.value);
                                                                setTouchedFields((prevState) => [
                                                                    ...new Set([...prevState, `${index}$gmp`])
                                                                ]);
                                                            }
                                                            }
                                                            placeholder="GMP"
                                                            style={{ minWidth: '100px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control two-decimals"
                                                            type="text"
                                                            maxLength="10"
                                                            value={result && result.gmpDetails[index] && result.gmpDetails[index].gmp}
                                                            id={`gmp${index}`}
                                                            style={{ minWidth: '100px' }}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control two-decimals"
                                                            maxLength="10"
                                                            id={`s2s_${index}`}
                                                            onChange={(e) => {
                                                                setFieldValue(`gmpDetails[${index}].s2s`, e.target.value);
                                                                setTouchedFields((prevState) => [
                                                                    ...new Set([...prevState, `${index}$s2s`])
                                                                ]);
                                                            }}
                                                            placeholder="S2S"
                                                            style={{ minWidth: '100px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control two-decimals"
                                                            type="text"
                                                            maxLength="10"
                                                            value={result && result.gmpDetails[index] && result.gmpDetails[index].s2s}
                                                            id={`s2s${index}`}
                                                            style={{ minWidth: '100px' }}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control two-decimals"
                                                            maxLength="10"
                                                            id={`s2s_hni_${index}`}
                                                            onChange={(e) => {
                                                                setFieldValue(`gmpDetails[${index}].s2s_hni`, e.target.value);
                                                                setTouchedFields((prevState) => [
                                                                    ...new Set([...prevState, `${index}$s2s_hni`])
                                                                ]);
                                                            }}
                                                            placeholder="S2S HNI"
                                                            style={{ minWidth: '100px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control two-decimals"
                                                            type="text"
                                                            maxLength="10"
                                                            value={result && result.gmpDetails[index] && result.gmpDetails[index].s2s_hni}
                                                            id={`s2s_hni${index}`}
                                                            style={{ minWidth: '100px' }}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={result && result.gmpDetails[index] && funFormatDateTime(result.gmpDetails[index].last_modified_dt)}
                                                            id={`last_modified_dt_${index}`}
                                                            style={{ minWidth: '200px' }}
                                                            readOnly
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="mb-3 text-center">
                                    <button type="submit" className="btn btn-success btn-sm me-2">Submit</button>
                                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => navigate(routeConfig.tool_gmp_list())}>Cancel</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </>
    );
};

export default GMPEdit;
