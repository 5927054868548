import React from 'react'
import { Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';

const SQLEditorForm = ({ result, handleGoBack, loading }) => {

    return (
        <>
            <Form id='myForm'>
                <div className="mb-3 row">
                    <label htmlFor="sqlquery" className="col-sm-2">SQL Query</label>
                    <div className="col-lg-8">
                        <Field className="form-control" as="textarea" name="sqlquery" rows={3} />
                    </div>
                </div>

                <hr />
                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}>

                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Run
                        </span>
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                    >Cancel</button>
                </div>

                <ValidateFormFields />
            </Form>

        </>
    )
}

export default SQLEditorForm
