import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    loading: false,
  },
  reducers: {
    
    startLoading: (state, action) => {
      state.loading = true;
    },
    finishLoading: (state, action) => {
      state.loading = false;
    },
  },
});

export const { startLoading, finishLoading } = loadingSlice.actions;
export const selectLoading = state => state.loading.loading;
export default loadingSlice.reducer;

