import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import ReportAccessForm from './ReportAccessForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const ReportAccess = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('Company Name is required'),
        member_ids: Yup.string().trim().required('Member ID is required'),
        category_cd: Yup.string().trim().required('Please select Category'),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        company_name: "",
        member_ids: "",
        cmdright: "",
        category_cd: "",
        existingauthor_names: "",
        author_names: "",
        lock_status: false,
        display_status: false,
        initialItems: [],
        selectedItems: [],
        selectedItemsIds: [],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/184/"); // Navigate back one step in history
    };

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New Report Access" })
            } else {
                setMetaData({ "title": "Edit Report Access" })
            }
            api.post('/admin-reportaccess-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.reportAccess);
                    if (!isAddMode) {
                        if (
                            response.data.reportAccess[0].author_names !== undefined &&
                            response.data.reportAccess[0].author_names !== null &&
                            response.data.reportAccess[0].author_names.trim().length > 0
                        ) {
                            response.data.reportAccess[0].selectedItemsIds = response.data.reportAccess[0].author_names.split(",")
                            console.log('the authors name are:', response.data.reportAccess[0].selectedItemsIds);
                        } else {
                            response.data.reportAccess[0].selectedItemsIds = [];
                        }
                        setInitialValues(response.data.reportAccess[0]);
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        trimmedValues.author_names = trimmedValues.selectedItemsIds.join(',');
        if (trimmedValues.category_cd == "report_cdn") {
            if (!trimmedValues.author_names) {
                dispatch(setMessage("Author name is required"))
                return;
            }
        }
        dispatch(startLoading());

        api.post('/admin-reportaccess-save', {
            values: trimmedValues
        }
        ).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/184/");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });

        setSubmitting(false);
    };

    return (
        <>

            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <ReportAccessForm result={result} handleGoBack={handleGoBack} loading={loading} initialValues={initialValues} />

            </Formik>
        </>
    )
}

export default ReportAccess
