import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IPOManagerIPOForm from './IPOManagerIPOForm';

const IPOManagerIPO = () => {

    const validate = (values) => {
        if (values.logo_url !== "" && (values.logo_width == "" || isNaN(values.logo_width) || values.logo_height == "" || isNaN(values.logo_height))) {
            alert("Please enter logo height and logo width.");
            return false;
        }
        return true;
    }

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        comp_name: "",
        urlrewrite_folder_name: "",
        comp_short_name: "",
        comp_type: "REG",
        logo_url: "",
        logo_width: "",
        logo_height: "",
        comp_desc: "",
        address_1: "",
        address_2: "",
        address_3: "",
        website: "",
        phone: "",
        fax: "",
        email: "",
        display_order: "",
        code_desc: "",
        display_status: false,
        lock_status: false,
        file: null,
        filebase64: null,
        fileoverwrite: false,
        img_path: "",
        city_id: "",
        state_id: "",
        zip_code: ""
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate(-1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New IPOManagerIPO" })
            } else {
                setMetaData({ "title": "Edit IPOManagerIPO" })
            }
            api.post('/admin-ipo-manager-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.ipomanager);
                    if (!isAddMode) {
                        setInitialValues(response.data.ipomanager[0]);
                    } else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ipomanagerNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        if (validate(trimmedValues)) {

            console.log('Form submitted with values:', trimmedValues);
            dispatch(startLoading());


            api.post('/admin-ipo-manager-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate(-1);
                } else {
                    dispatch(setMessage(response.data.error));
                }
            })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    dispatch(finishLoading());

                });

            setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            {result !== undefined &&
                result.ipomanager !== undefined &&
                result.ipomanager.length > 0 &&
                result.ipomanager[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            <IPOManagerIPOForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} setResult={setResult} api={api} dispatch={dispatch} />
        </>
    )
}

export default IPOManagerIPO
