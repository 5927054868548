import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import NumberInput from '../Common/NumberInput';


const ScriptEditForm = ({ result, handleGoBack, loading }) => {

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <>

      <Form id='myForm'>
        {result !== undefined && result.listOfScript !== undefined && result.listOfScript.length > 0 &&
          <div className="mb-3 row">
            <label htmlFor="id" className="col-lg-2">ID:</label>
            <div className="col-lg-4">
              {result.listOfScript[0].id}
            </div>
          </div>
        }

        <div className="mb-3 row">
          <label htmlFor="company_name" className="col-lg-2 required">Company Name</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="company_name" tabIndex={1} maxLength="250" onBlur={(e) => {
              const text = e.target.value;
              if (text !== "") {
                const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                if (userConfirmed) {
                  var slug = funCreateSlug(text);
                  setFieldValue('urlrewrite_folder_name', slug);
                } else {
                  setFieldTouched('company_name', true);
                }
              }
            }} />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Wipro Ltd</small></p>
          </div>
          <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required">URLRewrite Folder Name</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="urlrewrite_folder_name" tabIndex={5} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. wipro_ltd</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="" className="col-lg-2 ">BSE Script Name:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' size="5" name="bse_script_name" tabIndex={10} maxLength="250" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Wipro Ltd</small></p>
          </div>
          <label htmlFor="nse_script_name" className="col-lg-2 " >NSE Script Name:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="nse_script_name" tabIndex={15} maxLength="250" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Wipro Ltd</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_code" className="col-lg-2 ">BSE Script Code:</label>
          <div className="col-lg-2">
            <NumberInput classNameAtr="form-control" nameAtr="bse_script_code" tabIndexAtr={20} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. 541403</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_id" className="col-lg-2 ">BSE Script Id:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="bse_script_id" tabIndex={25} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. DOLLAR</small></p>
          </div>
          <label htmlFor="nse_script_id" className="col-lg-2 ">NSE Script Id:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="nse_script_id" tabIndex={30} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. DOLLAR</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_isin" className="col-lg-2 ">BSE Isin:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="bse_isin" tabIndex={35} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. INE325C01035</small></p>
          </div>
          <label htmlFor="nse_isin" className="col-lg-2 ">NSE Isin:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="nse_isin" tabIndex={40} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. INE325C01035</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_face_value" className="col-lg-2 ">BSE Face value:</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={45} nameAtr="bse_face_value" maxLength="10" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. 2</small></p>
          </div>
          <label htmlFor="nse_face_value" className="col-lg-2 ">NSE Face Value:</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={50} nameAtr="nse_face_value" maxLength="10" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. 2</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_industry" className="col-lg-2 ">BSE Industry:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="bse_industry" tabIndex={55} maxLength="250" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Pharmaceuticals</small></p>
          </div>
          <label htmlFor="nse_industry" className="col-lg-2 ">NSE Industry:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="nse_industry" tabIndex={60} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Pharmaceuticals</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_instrument" className="col-lg-2 ">BSE Instrument:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='text' name="bse_instrument" tabIndex={65} maxLength="50" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Equity</small></p>
          </div>
          <label htmlFor="nse_first_listing_date" className="col-lg-2">NSE First Listing date:</label>
          <div className="col-lg-2">
            <Field className="form-control" type='date' name="nse_first_listing_date" tabIndex={70} title="YYYY/MM/DD" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e YYYY/MM/DD</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_group" className="col-lg-2">BSE Script Group:</label>
          <div className="col-lg-2">
            <Field className="form-control" size="5" name="bse_script_group" tabIndex={75} maxLength="3" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. A</small></p>
          </div>
          <label htmlFor="nse_market_lot" className="col-lg-2">NSE Market Lot:</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={80} nameAtr="nse_market_lot" maxLength="10" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. 1</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="bse_script_status" className="col-lg-2">BSE Script Status:</label>
          <div className="col-lg-2">
            <Field className="form-control" size="5" name="bse_script_status" tabIndex={85} maxLength="25" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. Active</small></p>
          </div>
          <label htmlFor="nse_paid_up_value" className="col-lg-2">NSE Paid Up Value:</label>
          <div className="col-lg-2">
            <NumberDecimalInput classNameAtr="form-control" type="text" tabIndexAtr={90} nameAtr="nse_paid_up_value" maxLength="10" />
          </div>
          <div className="col-lg-2">
            <p><small>i.e. 2</small></p>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="display_status" className="col-lg-1">Display Status</label>
          <div className="col-lg-2">
            <Field className="form-check-input" type="checkbox" name="display_status" tabIndex={95} onChange={(e) => {
              const newValue = e.target.checked;
              setFieldValue('display_status', newValue);
            }} />
          </div>
        </div>
        <div className="mb-3 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            tabIndex={100}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            <span>
              {result.listOfScript !== undefined && result.listOfScript.length > 0 ? 'Update' : 'Create'}
            </span>
          </button>

          <button
            type="button"
            className="btn btn-secondary btn-block ms-2"
            onClick={handleGoBack}
            tabIndex={105}
          >Cancel</button>
        </div>
        <ValidateFormFields />
      </Form>
    </>
  )
}

export default ScriptEditForm





