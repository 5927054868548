import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import DynamicCharacterCountInputText from '../Common/CharacterCountTextarea';
import DynamicCharacterCountInput from '../Common/CharacterCountInput';
import NumberInput from '../Common/NumberInput';

const validationSchema = Yup.object({
  sitemap_name: Yup.string().trim().required("Please enter sitemap name."),
  category_cd: Yup.string().trim().required("Please enter Report Category."),
  title: Yup.string().trim().required("Please enter title/page heading."),
  urlrewrite_folder_name: Yup.string().trim().required("Please enter URLRewrite Folder Name."),
  sitemap_link: Yup.string().trim().required("Please enter sitemap link."),
  meta_title: Yup.string().trim().required("Please enter meta title."),
  meta_description: Yup.string().trim().required("Please enter meta description."),
  page_description: Yup.string().trim().required("Please enter page description."),
  sitemap_sql_query: Yup.string().trim().required("Please enter sitemap SQL query."),
  search_column_name: Yup.string().trim().required("Please enter search column name."),
  display_Order: Yup.number().integer("Display Order must be an integer."),
});

const GoogleSitemapForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched }) => (

          <Form id='myForm'>
            {result !== undefined && result.googlesitemap !== undefined && result.googlesitemap.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="" className="col-lg-2">ID:</label>
                <div className="col-lg-4">
                  {result.googlesitemap[0].id}
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="sitemap_name" className="col-lg-2 required">Sitemap Name (Text)</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" tabIndex={1} name="sitemap_name" maxLength="100" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="category_cd" className="col-lg-2 required">Category</label>
              <div className="col-lg-4">
                <Field as="select" name="category_cd" className="form-select" tabIndex={5}>
                  <option></option>
                  {result.categoryList !== undefined && result.categoryList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.cat_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="title" className="col-lg-2 required">Page Heading</label>
              <div className="col-lg-10">
                <Field className="form-control" type="text" tabIndex={10} name="title" maxLength="100"
                  onBlur={(e) => {
                    var title = e.target.value;
                    if (title !== "") {
                      const userConfirmed = window.confirm('Do you want to update urlrewrite folder name?');
                      if (userConfirmed) {
                        var slug = funCreateSlug(title);
                        setFieldValue('urlrewrite_folder_name', slug);
                      } else {
                        setFieldTouched('title', true);
                      }
                    }
                  }} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="urlrewrite_folder_name" className="col-lg-2 required" >URLRewrite Folder Name</label>
              <div className="col-lg-3">
                <Field className="form-control" type="text" maxLength="100" tabIndex={15} name="urlrewrite_folder_name" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="meta_title" className="col-lg-2 required">Meta Title (Max 80)</label>
              <div className="col-lg-10">
                <DynamicCharacterCountInput className="form-control" tabIndex={20} nameattr={"meta_title"} maxLength="80" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="meta_description" className="col-lg-2 required">Meta Description (Max 200)</label>
              <div className="col-lg-10">
                <DynamicCharacterCountInputText className="form-control" tabIndex={25} nameattr={"meta_description"} maxLength="200" rows="2" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="page_description" className="col-lg-2 required">Page Description</label>
              <div className="col-lg-10">
                <DynamicCharacterCountInputText className="form-control" tabIndex={30} nameattr={"page_description"} rows="4" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="sitemap_link" className="col-lg-2 required">Sitemap Link</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" tabIndex={35} name="sitemap_link" maxLength="100" />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="sitemap_sql_query" className="col-lg-2 required">Sitemap SQL Query (HTML)</label>
              <div className="col-lg-10">
                <DynamicCharacterCountInputText className="form-control" tabIndex={40} nameattr={"sitemap_sql_query"} cols="30" rows="4" />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="default_sort" className="col-lg-2">Order By</label>
              <div className="col-lg-2">
                <Field className="form-control" type="text" maxLength="100" tabIndex={45} name="default_sort" />
              </div>
              <div className="col-lg-2">
                (i.e. idasc or id) Default: <strong>1desc</strong>. No space allowed.
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="page_size" className="col-lg-2">Page Size (Default 25)</label>
              <div className="col-lg-2">
                <NumberInput classNameAtr={"form-control"} maxLength={"3"} tabIndexAtr={50} nameAtr={"page_size"} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="search_column_name" className="col-lg-2 required">Search Column Name (Only One)</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" tabIndex={55} name="search_column_name" />
              </div>
              <div className="col-lg-6">
                (i.e. company_name or title)
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="display_order" className="col-lg-2" >Display Order</label>
              <div className="col-lg-1">
                <NumberInput classNameAtr={"form-control"} maxLength={"4"} tabIndexAtr={60} nameAtr={"display_order"} />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="display_status" className="col-lg-2">Display Status</label>
              <div className="col-lg-4">
                <Field className="form-check-input" type="checkbox"
                  tabIndex={65} name="display_status"
                  onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
              </div>
              <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
              <div className="col-lg-4">
                <Field className="form-check-input" type="checkbox"
                  tabIndex={65} name="lock_status"
                  onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
              </div>
            </div>
            <hr />
            <div className="mb-3 text-center">
              {initialValues.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={70}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.googlesitemap !== undefined && result.googlesitemap.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              )}

              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={75}
              >Cancel</button>
            </div>
            <ValidateFormFields />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default GoogleSitemapForm





