import React, { useState, useEffect } from 'react';
import useApi from '../../../common/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../../state/slices/message';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { funFormatDateTime } from './CommonFunction';

const CronjobsStatus = ({ handleCronClose }) => {

    const dispatch = useDispatch();
    const api = useApi();
    const [cronList, setCronList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (cronList.length == 0) getTableData();
    }, [])

    const getTableData = () => {
        setIsLoading(true);
        dispatch(startLoading());
        api.get('/getcronRunningList')
            .then(response => {
                if (response.data.msg === 1) {
                    setCronList(response.data.jobsList);
                } else {
                    dispatch(setMessage(response.data.error));
                }
                dispatch(finishLoading());
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
                setIsLoading(false);
            });
    }


    return (
        <div className="container mt-4">
            {isLoading && (
                <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{
                        background: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                        top: 0,
                        left: 0
                    }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Job Id</th>
                            <th>Job Name</th>
                            <th>File Name</th>
                            <th>Last Run On</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cronList.length > 0 ? (
                            cronList.map((job, index) => (
                                <tr key={index}>
                                    <td>{job.id}</td>
                                    <td>{job.title}</td>
                                    <td>{job.filename}</td>
                                    <td>{funFormatDateTime(job.last_run_dt)}</td>
                                    <td>
                                        <span className={`badge ${job.status === 'Unknown' ? 'bg-primary' :
                                            job.status === 'Running' ? 'bg-warning' :
                                                job.status === 'Success' ? 'bg-success' :
                                                    job.status === 'Errored' ? 'bg-danger' :
                                                        job.status === 'Queued' ? 'bg-light text-dark' :
                                                            'bg-secondary'
                                            }`}>
                                            {job.status}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No cron jobs found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className='float-end'>
                <button
                    className="btn btn-primary"
                    onClick={getTableData}
                >
                    <i className="fas fa-sync-alt me-2"></i>
                    <span className="fw-bold">Refresh</span>
                </button>
                <button className="btn btn-secondary ms-2" onClick={handleCronClose}>Close</button>

            </div>
        </div>
    );
};

export default CronjobsStatus;
