import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, selectLoading, startLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import { closePopUpWindow, funCloseWebWindow } from '../../admintool9x/Common/CommonFunction';
import routeConfig from '../../../routeConfig';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination, Spinner } from 'react-bootstrap';

const IPORecommendationList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var ref = queryParams.get("ref") ?? "";
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);

    let columns = [
        {
            Header: 'Issuer Company',
            accessor: 'company_name',
            Cell: ({ cell }) => (
                <>
                    <Link
                        to={`${process.env.REACT_APP_WEB_URL}ipo/${cell.row.original.urlrewrite_folder_name}/${cell.row.original.ipo_id}/`}
                        style={{ textDecoration: "none" }}
                        title={cell.row.original.company_name}
                    >
                        <strong>{cell.row.original.company_name}</strong>
                    </Link>
                </>
            ),
        },
        {
            Header: 'Recommendation',
            accessor: 'recommendation',
        },
        {
            Header: 'Hold Position',
            accessor: 'hold_position',
        },
        {
            Header: 'Subscribe at Price',
            accessor: 'subscribe_at_price_band',
        },
        {
            Header: 'Edit',
            accessor: 'id',
            Cell: ({ cell }) => (
                <>
                    <Link to={`${routeConfig.ipo_recommendation_post()}?a=${cell.row.original.ipo_id}`} className="btn btn-sm btn-danger"><i className="fa fa-edit"></i></Link>
                </>
            ),
            className: 'text-center',
            disableSortBy: true,
        },
    ];

    const [result, setResult] = useState({
        tableColumns: columns,
        tableData: [],
        pageNumber: 1,
        totalPages: 1,
        totalRecords: 0,
        pageIndex: 0,
        recordPerPage: 20,
        orderBy: "",
        pageDataLoaded: 0,
        currentSort: "1"
    });
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);

    const btnClick = () => {
        if (ref != "") {
            funCloseWebWindow(ref);
        } else {
            closePopUpWindow();
        }
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },

    } = useTable(
        {
            columns: result.tableColumns,
            data: result.tableData,
            manualSortBy: true,
            initialState: {
                pageIndex: result.pageIndex,
                pageSize: result.recordPerPage
            },
            manualPagination: true,
            pageCount: result.totalPages,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (didMountRef.current == "true") {
            setMetaData({ "title": "My Recommendations" })

            getTableData();
        } else {
            didMountRef.current = "true";
        }
    }, []);

    const getTableData = () => {
        dispatch(startLoading());
        api.post('/member-iporecommendationlist-read', {
            pagenumber: result.pageIndex + 1,
            orderby: result.currentSort,

        }).then(response => {
            if (response.data.msg === 1) {
                setResult((prevState) => ({
                    ...prevState,
                    tableColumns: columns,
                    tableData: response.data.recommendationList,
                    totalRecords: response.data.TotalRecords,
                    recordPerPage: response.data.RecordPerPage,
                    pageNumber: response.data.PageNumber,
                    orderBy: response.data.Orderby,
                    totalPages: response.data.TotalPages,
                    pageDataLoaded: 1,
                }))
            } else {
                dispatch(setMessage(response.data.error));
            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }
    useEffect(() => {
        if (result.pageDataLoaded === 1) {
            getTableData();
        }
    }, [result.pageIndex, result.currentSort]);

    useEffect(() => {
        if (result.pageDataLoaded == 1) {
            let currentSort = "";
            if (sortBy.length > 0) {
                currentSort = sortBy[0].id + (sortBy[0].desc ? " desc" : " asc");
            }
            setResult((prevState) => ({
                ...prevState,
                currentSort: currentSort
            }));
        }
    }, [sortBy]);

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <div className='row main mt-3'>
                <div className='col-sm-12'>
                    <h1 className="mb-3">You Recommendations List</h1>
                    <p>Note: You could edit a recommendation within <b>24 hours</b> of posting.</p>

                    <div className='table-responsive'>
                        <div className={`table-container ${loading ? 'blur' : ''}`}>
                            <Table {...getTableProps()} striped bordered hover id='report_table'>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th className={column.className} {...column.getHeaderProps()}>
                                                    <div
                                                        {...column.getSortByToggleProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {column.render('Header')}
                                                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                                    </div>
                                                </th>

                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.length === 0 ? (
                                        <tr>
                                            <td colSpan={columns.length}>
                                                Sorry! No recommendation found. <br />Please check the <Link target='_blank' to="/report/ipo-recommendation-list/104/" title="IPO Recommendation">IPO Recommendation</Link> page for all comments.<br /><br /></td>
                                        </tr>
                                    ) : (
                                        rows.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className={cell.column.className}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </div>

                    {result.totalPages > 1 &&
                        <Pagination className='justify-content-center'>
                            <Pagination.First onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: 0 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Prev onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex - 1 }))} disabled={result.pageIndex === 0} />
                            <Pagination.Item
                                key={result.pageIndex}
                                active={true}
                                onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex }))}
                            >
                                {result.pageIndex + 1}
                            </Pagination.Item>

                            <Pagination.Next onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.pageIndex + 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                            <Pagination.Last onClick={() => setResult((prevState) => ({ ...prevState, pageIndex: result.totalPages - 1 }))} disabled={result.pageIndex === result.totalPages - 1} />
                        </Pagination>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-end">
                    <button
                        type="button"
                        onClick={btnClick}
                        className="btn btn-secondary btn-sm"
                        name="closeButton"
                        value="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    )
}

export default IPORecommendationList
