import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import TradingCompanyNRITradingForm from './TradingCompanyNRITradingForm';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TradingCompanyTabs from './TradingCompanyTabs';

const validationSchema = Yup.object({
});

const TradingCompanyNRITrading = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        nri_trading_company_review: "",
        trading_software_review: "",
        mutual_funds_review: "",
        account_opening_review: "",
        trading_account_opening_charges: "",
        trading_account_amc: "",
        contract_note_reporting_charges: "",
        pis_account_amc: "",
        account_type: "",
        brokerage_equity_delivery: "",
        brokerage_equity_future: "",
        brokerage_equity_options: "",
        other_charges: "",
        exposure_equity_delivery: "",
        exposure_equity_futures: "",
        exposure_equity_options: "",
        inv_options_stock: "",
        inv_options_ipo: "",
        inv_options_mutual_fund: "",
        inv_options_others: "",
        feature_3_in_1_account: "",
        feature_automated_trading: "",
        feature_free_research_and_tips: "",
        feature_others: "",
        customer_care_number: "",
        customer_care_email: "",
        conclusion: "",
        pros: "",
        cons: "",
        promotion_offer_brief: "",
        promotion_offer: "",
        demat_charges: "",
        order_type_bo: "",
        order_type_co: "",
        order_type_amo: "",
        order_type_gtc: "",
        display_order: "",
        compare_display_status: false,
        display_status: false
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/19");
    };

    const convertMojibakeToRupee = (data) => {
        const values = Object.values(data);
        let i = 0;
        for (let key in data) {
            if (typeof values[i] === 'string') {
                data[key] = values[i].replace(/â‚¹/g, '₹');
            }
            i++;
        }
        return data;
    }

    useEffect(() => {
        if (didMountRef.current=="true") {
            dispatch(startLoading());

            setMetaData({ "title": "Update Trading Company NRI Trading" })

            api.post('/admin-tradingcompanynri-read', {
                id: id
            }).then(response => {

                if (response.data.msg === 1) {
                    setResult(response.data);

                    if (!isAddMode) {
                        setInitialValues(convertMojibakeToRupee(response.data.tradingCompanyNRI[0]));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request

        dispatch(startLoading());
        

        api.post('/admin-tradingcompanynri-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/19");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
            
        });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <TradingCompanyTabs idAtr={result.id} />
            <h1>
                {metaData.title} (
                <span style={{ color: 'blue' }}>
                    {result.tradingCompany != undefined && result.tradingCompany[0].company_name}
                </span>
                )
            </h1>
            <hr />
            {result != undefined && result.tradingCompany != undefined &&
                result.tradingCompany.length > 0 &&
                result.tradingCompany[0].lock_status !== undefined &&
                result.tradingCompany[0].lock_status == 1 && (
                    <h3 className="text-danger mb-3">
                        Record Is Locked , you can't update it.
                    </h3>
                )}

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <TradingCompanyNRITradingForm result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}

export default TradingCompanyNRITrading
