import React, { useState, useEffect } from "react";
import ValidateFormFields from "../Common/ValidateFormFields";
import { useFormikContext, Field, Form } from 'formik';
import useApi from '../../../common/useApi';


const IpoDateForm = ({ result, handleGoBack, initialValues, loading }) => {

    const api = useApi();
    const { values, setFieldValue } = useFormikContext();
    const [focusSet, setFocusSet] = useState(false);
    const [lockInPeriodPromptShown, setLockInPeriodPromptShown] = useState(false);

    const [formState, setFormState] = useState({
        sharesOfferedAnchorInvestorDisable: true,
        openCloseDateDisplayOrderMessage: '',
        boaDateDisplayOrderMessage: '',
    })

    const handleIssueOpenDateChange = (e) => {
        if (e.target.value !== '') {
            getAnchorLockingDate(e.target.value);
        }
    };
    const getAnchorLockingDate = (date) => {
        if (values.issue_process_type == 'FP') return;
        if (date != '' && values.issue_process_type != 'FP') {
            if (window.confirm('Would you like to update Anchor Investor bid date?')) {

                api.post('/admin-ipo-anchor-investor-bid-date-read', {
                    date: date
                }).then(response => {
                    if (response.data.msg === 1) {
                        setFieldValue('timetable_anchor_bid_dt', response.data.anchor_bid_date);
                    } else {
                        window.alert("Error: Could not update bit date");
                    }
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            }
        }
    };

    const getLockInPeriodDate = () => {

        let date = values.timetable_boa_dt;
        if (date !== '') {
            if (!lockInPeriodPromptShown) {
                if (window.confirm('Would you like to update Anchor lock-in period end date?')) {
                    setFieldValue('timetable_anchor_lockin_end_dt_1', addDays(date, 30));
                    setFieldValue('timetable_anchor_lockin_end_dt_2', addDays(date, 90));
                }
                //setLockInPeriodPromptShown(true);
            }
        } else {
            if (!focusSet) {
                const element = document.getElementById("txtTimetable_BOA_dt");
                if (element) element.focus();
                setFocusSet(true);
            }
        }
    };


    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);

        var day = result.getDate();
        var month = result.getMonth() + 1; // Months are zero-based
        var year = result.getFullYear();

        return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }



    const getTentativeDate = (e) => {
        let date = e.target.value;
        if (date != '') {
            if (window.confirm('Would you like to update tentative date?')) {
                api.post('/admin-ipo-tentative-date-read', {
                    date: date
                }).then(response => {
                    if (response.data.msg === 1) {
                        setFieldValue('timetable_boa_dt', response.data.timetable_boa_dt);
                        setFieldValue('timetable_refunds_dt', response.data.timetable_refunds_dt);
                        setFieldValue('timetable_share_credit_dt', response.data.timetable_share_credit_dt);
                        setFieldValue('timetable_listing_dt', response.data.timetable_listing_dt);
                        if (values.issue_process_type != 'FP') {
                            getLockInPeriodDate();
                            if (values.timetable_anchor_bid_dt == "") getAnchorLockingDate(values.issue_open_date);
                        }
                    } else {
                        window.alert("Entered Date is a Holiday...");
                    }
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            }
        }
    };

    useEffect(() => {

        if (
            values.issue_open_date != initialValues.issue_open_date ||
            values.issue_close_date != initialValues.issue_close_date
        ) {
            setFormState((prevState) => ({
                ...prevState,
                openCloseDateDisplayOrderMessage: "Date modified. Please check display order and NDC calander.",
            }));
        }
        else {
            setFormState((prevState) => ({
                ...prevState,
                openCloseDateDisplayOrderMessage: "",
            }));
        }
        if (values.timetable_boa_dt != initialValues.timetable_boa_dt) {
            setFormState((prevState) => ({
                ...prevState,
                boaDateDisplayOrderMessage: "Date modified. Please check Anchor lock-in period end dates (30 days and 90 days)",
            }));
        }
        else {
            setFormState((prevState) => ({
                ...prevState,
                boaDateDisplayOrderMessage: "",
            }));
        }
    }, [values.issue_open_date, values.issue_close_date, values.timetable_boa_dt]);


    return (
        <>

            <Form id="myForm">
                {result !== undefined &&
                    result.ipoDetail !== undefined &&
                    result.ipoDetail.length > 0 && (
                        <>
                            <div className="mb-3 row">
                                <label htmlFor="" className="col-lg-2">
                                    ID:
                                </label>
                                <div className="col-lg-4">{result.ipoDetail[0].id}</div>
                            </div>

                            <div className="my-3 row">
                                <label htmlFor="company_name" className="col-lg-2 required">
                                    Company Name
                                </label>
                                <div className="col-lg-4">
                                    {result.ipoDetail[0].company_name}
                                </div>
                            </div>
                        </>
                    )}

                <hr />
                <h3>IPO Dates</h3>

                <div className="mb-3 row">
                    <label htmlFor="issue_open_date" className="col-lg-2">
                        Issue Open Date
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control dateInput"
                            type="date"
                            id="txtIssue_Open_Date"
                            name="issue_open_date"
                            title="YYYY/MM/DD"
                            tabIndex={130}
                            onChange={(e) => {
                                setFieldValue("issue_open_date", e.target.value);
                                handleIssueOpenDateChange(e)
                            }}
                        />
                        <p
                            className="text-danger"
                            id="display_order_message"
                        >   {formState.openCloseDateDisplayOrderMessage}
                        </p>
                    </div>
                    <label htmlFor="issue_close_date" className="col-lg-2">
                        Issue Close Date
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control dateInput"
                            type="date"
                            name="issue_close_date"
                            id="txtIssue_Close_Date"
                            title="YYYY/MM/DD"
                            tabIndex={135}
                            onBlur={(e) => {
                                setFieldValue("issue_close_date", e.target.value);
                                getTentativeDate(e)
                                setLockInPeriodPromptShown(false);
                            }}
                        />
                        <p
                            className="text-danger"
                            id="display_order_message"
                        >   {formState.openCloseDateDisplayOrderMessage}
                        </p>
                    </div>
                </div>

                <h3>Tentative Timetable</h3>
                <p>
                    <mark>
                        Please double check dates from RHP (SME timetable T+3 instead of
                        T+5/6/7)
                    </mark>
                </p>
                <div className="mb-3 row">
                    <label htmlFor="timetable_boa_dt" className="col-lg-2">
                        Finalisation of Basis of Allotment
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_boa_dt"
                            id="txtTimetable_BOA_dt"
                            title="YYYY/MM/DD"
                            tabIndex={140}
                            onBlur={(e) => {
                                setFieldValue("timetable_boa_dt", e.target.value);
                                if (values.issue_process_type != 'FP') {
                                    getLockInPeriodDate()
                                    if (values.timetable_anchor_bid_dt == "") getAnchorLockingDate(values.issue_open_date);
                                }
                            }}

                        />
                        <p
                            className="text-danger"
                            id="Timetable_BOA_dt_message"
                        >
                            {formState.boaDateDisplayOrderMessage}
                        </p>
                    </div>
                    <label htmlFor="timetable_refunds_dt" className="col-lg-2">
                        Initiation of refunds
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_refunds_dt"
                            id="txtTimetable_Refunds_dt"
                            title="YYYY/MM/DD"
                            tabIndex={145}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="timetable_share_credit_dt" className="col-lg-2">
                        Credit of Equity Shares
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_share_credit_dt"
                            id="txtTimetable_Share_Credit_dt"
                            title="YYYY/MM/DD"
                            tabIndex={150}
                        />
                    </div>

                    <label htmlFor="timetable_listing_dt" className="col-lg-2">
                        Commencement of trading
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_listing_dt"
                            id="txtTimetable_Listing_dt"
                            title="YYYY/MM/DD"
                            tabIndex={155}
                        />
                    </div>
                </div>
                <h3>Anchor Investors Timetable</h3>
                <div className="mb-3 row">
                    <label htmlFor="timetable_anchor_bid_dt" className="col-lg-2">
                        Anchor Investor Bid Date
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            id="txtAnchor_Bid_Date"
                            name="timetable_anchor_bid_dt"
                            title="YYYY/MM/DD"
                            tabIndex={160}
                            onBlur={(e) => {
                                setFieldValue("timetable_anchor_bid_dt", e.target.value);
                                if (values.timetable_boa_dt == "" || values.timetable_boa_dt == null) {
                                    alert("Enter Basis of Allotment Date for Anchor lock-in period end date");
                                    return;
                                }
                                if (values.issue_process_type != 'FP') getLockInPeriodDate()
                                setFocusSet(false)
                            }}
                            readOnly={values.issue_process_type == 'FP'}
                        />
                    </div>
                    <label htmlFor="timetable_anchor_lockin_end_dt_1" className="col-lg-2">
                        Anchor lock-in period end date (30 Days)
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_anchor_lockin_end_dt_1"
                            id="timetable_anchor_lockin_end_dt_1"
                            title="YYYY/MM/DD"
                            tabIndex={165}
                            readOnly={values.issue_process_type == 'FP'}
                        />
                    </div>
                    <label htmlFor="timetable_anchor_lockin_end_dt_2" className="col-lg-2">
                        Anchor lock-in period end date (90 Days)
                    </label>
                    <div className="col-lg-2">
                        <Field
                            className="form-control"
                            type="date"
                            name="timetable_anchor_lockin_end_dt_2"
                            id="Anchor_Lockin_End_dt_2"
                            title="YYYY/MM/DD"
                            tabIndex={170}
                            readOnly={values.issue_process_type == 'FP'}
                        />
                    </div>
                </div>

                <hr />
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={355}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.ipoDetail !== undefined && result.ipoDetail.length > 0
                                    ? "Update"
                                    : "Create"}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={360}
                    >
                        Cancel
                    </button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    );
};

export default IpoDateForm;