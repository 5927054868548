
import React, { useEffect } from 'react';
import { useFormikContext, Field, Form } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import { funCreateSlug } from '../Common/CommonFunction';
import useApi from '../../../common/useApi';
import NumberInput from '../Common/NumberInput';
import FileUpload from '../Common/FileUpload';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const FaqPageForm = ({ result, handleGoBack, initialValues, loading, isAddMode }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    const api = useApi();

    useEffect(() => {
        if ((values.topic_id != "" && values.topic_id1 != 0) && (values.request_type_id != "" && values.request_type_id != 0)) {
            if ((!isAddMode && (values.topic_id != result.faqpage[0].topic_id || values.request_type_id != result.faqpage[0].request_type_id)) || isAddMode) {
                api.post('/admin-faqpage-displayorder', {
                    request_type_id: values.request_type_id,
                    topic_id: values.topic_id,
                    topic_id_2: values.topic_id_2 === "" ? 0 : values.topic_id_2
                }).then(response => {
                    if (response.data.msg === 1) {
                        console.log("The dispaly order is: ", response.data.newDisplayOrder)
                        setFieldValue('display_order', response.data.newDisplayOrder ?? '');
                    }
                }).catch(error => {
                    console.error('Error:', error);
                });
            }
        }
    }, [values.topic_id, values.topic_id_2, values.request_type_id]);

    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.faqpage !== undefined && result.faqpage.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.faqpage[0].id}
                        </div>
                    </div>
                }


                <div className="mb-3 row">
                    <label htmlFor="request_type_id" className="col-lg-2 required">Request Type</label>
                    <div className="col-lg-4">
                        <Field as="select" name="request_type_id" className="form-select" tabIndex={1}
                        >
                            <option></option>
                            {result.requestList !== undefined && result.requestList.map((option) => (
                                <option key={option.code} value={option.code} >
                                    {option.code_desc}
                                </option>
                            ))}
                        </Field>
                    </div>


                    <label htmlFor="topic_id" className="col-lg-1">Topic Id 1</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={5} maxLength="12" nameAtr="topic_id" />
                    </div>
                    <label htmlFor="topic_id_2" className="col-lg-1">Topic Id 2</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={10} maxLength="12" nameAtr="topic_id_2" />
                    </div>
                </div>


                <div className="mb-3 row">
                    <label htmlFor="question" className="col-lg-2 required">Question (Text)</label>
                    <div className="col-lg-10">
                        <Field className="form-control" type="text" name="question" maxLength="255" tabIndex={15}
                            onBlur={(e) => {
                                var question = e.target.value;
                                if (question != "") {
                                    const userConfirmed = window.confirm('Do you want to update urlrewrite_folder_name?');
                                    if (userConfirmed) {

                                        var slug = funCreateSlug(question)

                                        setFieldValue('urlrewrite_folder_name', slug);
                                    } else {
                                        setFieldTouched('question', true);
                                    }
                                }
                            }}></Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="urlrewrite_folder_name" className="col-lg-2  required">URLRewrite Folder Name</label>
                    <div className="col-lg-10">
                        <Field className="form-control" type="text" name="urlrewrite_folder_name" maxLength="100" tabIndex={20} ></Field>
                    </div>
                </div>


                <div className="mb-3 row">
                    <label htmlFor="answer" className="col-lg-2 required">Answer (HTML)</label>
                    <div className="col-lg-10">
                        <CharacterCountTextarea
                            nameattr="answer"
                            type="textarea"
                            tabIndex="25"
                            rows="15"
                            className="form-control"
                        />
                    </div>
                </div>

                <hr />
                <div className='mb-3 row'>
                    <label htmlFor="image" className='col-lg-2'>Choose an image:</label>
                    <div className="col-lg-10">
                        <FileUpload nameattr="file" namebase64attr="filebase64" imageField="image_name" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="image_name" className="col-lg-2">Image Name</label>
                    <div className="col-lg-4">
                        <Field className="form-control" type="text" maxLength="100" name="image_name" tabIndex={30}></Field>
                    </div>
                    <label htmlFor="image_width" className="col-lg-1">Width</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={35} maxLength="3" nameAtr="image_width" />
                    </div>
                    <label htmlFor="image_height" className="col-lg-1">Height</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={40} maxLength="3" nameAtr="image_height" />
                    </div>
                </div>

                <hr />
                <div className="mb-3 row">
                    <label htmlFor="report_ids" className="col-lg-2">Report IDs</label>
                    <div className="col-lg-2">
                        <Field className="form-control" type="text" maxLength="10" name="report_ids" tabIndex={45}></Field>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                    <div className="col-lg-2">
                        <NumberInput classNameAtr="form-control" tabIndexAtr={50} maxLength="5" nameAtr="display_order" />
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" tabIndex={55} type="checkbox" name="display_status"
                            onChange={(e) => { setFieldValue('display_status', e.target.checked) }} />
                    </div>
                    <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
                    <div className="col-lg-4">
                        <Field className="form-check-input" tabIndex={60} type="checkbox" name="lock_status"
                            onChange={(e) => { setFieldValue('lock_status', e.target.checked) }} />
                    </div>
                </div>



                <hr />
                <div className="mb-3 text-center">
                    {initialValues.lock_status !== 1 && (
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loading}
                            tabIndex={65}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            )}
                            <span>
                                {result.faqpage !== undefined && result.faqpage.length > 0 ? 'Update' : 'Create'}
                            </span>
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={handleGoBack}
                        tabIndex={70}
                    >Cancel</button>
                </div>
                <ValidateFormFields />
            </Form>
        </>
    )
}

export default FaqPageForm