import { Field, useFormikContext } from 'formik';
import React from 'react';

const NumberDecimalInput = ({ classNameAtr, tabIndexAtr, maxLength, nameAtr, decimalPoint = 2, dynamicOnChange = () => { }, dynamicOnBlur = () => { }, disabledAtr, dynamicOnInput = () => { } }) => {
    const formik = useFormikContext();

    const handleChange = (event) => {
        const newValue = event.target.value;
        formik.setFieldValue(nameAtr, newValue);

        // Call the dynamically provided onChange function
        dynamicOnChange(event);
    };

    const handleBlur = (event) => {
        dynamicOnBlur(event);
    };

    return (
        <Field type="text"
            className={classNameAtr} tabIndex={tabIndexAtr} maxLength={maxLength} name={nameAtr}
            onChange={(event) => handleChange(event)}
            onBlur={(event) => handleBlur(event)}
            disabled={disabledAtr}
            onInput={dynamicOnInput}
            onKeyUp={(e) => {
                // Allow only numeric input with up to two decimal places
                let value = e.target.value;
                let newValue = value.replace(/,/g, '');
                newValue = newValue.replace(/[^0-9.eE-]/g, '');
                newValue = newValue.replace(/^(-.*)-/, '$1');
                newValue = newValue.replace(/e-/, 'e');

                // Ensure there is at most one decimal point
                const decimalCount = (newValue.match(/\./g) || []).length;
                if (decimalCount > 1) {
                    newValue = newValue.slice(0, -1);
                }
                
                // Ensure only two digits after the decimal point
                const decimalIndex = newValue.indexOf('.');
                if (decimalIndex !== -1) {
                    const decimalPart = newValue.substring(decimalIndex + 1);
                    if (decimalPart.length > parseInt(decimalPoint)) {
                        newValue = `${newValue.substring(0, decimalIndex + 1 + parseInt(decimalPoint))}`;
                    }
                }
                formik.setFieldValue(nameAtr, newValue);
            }}
        />
    );
};

export default NumberDecimalInput;
