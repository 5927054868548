import React, { useState } from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../../admintool9x/Common/ValidateFormFields';
import ReCAPTCHA from 'react-google-recaptcha';
import NumberInput from '../../admintool9x/Common/NumberInput';
import CharacterCountTextarea from '../../admintool9x/Common/CharacterCountTextarea';

const LeadSmeIpoLmForm = ({ result, btnClick, loading, initialValues, recaptchaRef }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [showEnquiryMessage, setShowEnquiryMessage] = useState(false);
    const [hideFields, setHideFields] = useState(false);

    const handleEnquiryChange = (event) => {
        const value = event.target.value;
        setShowEnquiryMessage(value === "investor");
        setHideFields(value === "investor");
        setFieldValue("enquiry_type", value);
    };

    const handleKeyUp = (event) => {
        event.target.value = event.target.value.replace(/\D/g, '');
    };

    return (
        <>
            <Form id='myForm'>
                <fieldset>
                    <p>Are you an SME company looking for an IPO to raise funds and get listed in the stock market? We can help...</p>

                    <div className="mb-3 row">
                        <label htmlFor="enquiry_type" className="col-sm-2">Are you an?</label>
                        <div className="col-sm-10 col-md-5">
                            <Field as="select" className="form-select" tabIndex={1} name="enquiry_type" onChange={handleEnquiryChange}>
                                <option value="">Select Type</option>
                                <option value="investor">Investor in SME Companies</option>
                                <option value="sme_company">SME Company</option>
                            </Field>
                            {showEnquiryMessage && (
                                <p className="text-danger my-1 fs-6">Sorry. this inquiry form is for SME companies who want to raise funds and get listed on the stock exchange.</p>
                            )}
                        </div>
                    </div>

                    {!hideFields && (
                        <>
                            <div className="mb-3 row ">
                                <label htmlFor="author_name" className="col-sm-2">Name</label>
                                <div className="col-sm-10 col-md-5">
                                    <Field type="text" tabIndex={5} name="author_name" placeholder="Your Name" className="form-control" maxLength={50} />
                                </div>
                            </div>
                            <div className="mb-3 row ">
                                <label htmlFor="phone" className="col-sm-2">Phone</label>
                                <div className="col-sm-10 col-md-5">

                                    <NumberInput classNameAtr="form-control" onKeyUp={handleKeyUp} tabIndexAtr={10} maxLength="15" nameAtr="phone" placeholderAtr="Your Phone Number" />
                                </div>
                            </div>
                            <div className="mb-3 row ">
                                <label htmlFor="author_email" className="col-sm-2">Email</label>
                                <div className="col-sm-10 col-md-5">
                                    <Field type="text" tabIndex={15} name="author_email" placeholder="Your Email" className="form-control" maxLength={50} />
                                </div>
                            </div>
                            <div className="mb-3 row ">
                                <label htmlFor="city" className="col-sm-2">City</label>
                                <div className="col-sm-10 col-md-5">
                                    <Field type="text" tabIndex={20} name="city" placeholder="Your City" className="form-control" maxLength={50} />
                                </div>
                            </div>

                            <div className="mb-3 row ">
                                <label htmlFor="city" className="col-sm-2">State</label>
                                <div className="col-sm-10 col-md-5">
                                    <Field as="select" className="form-select" tabIndex={25} name="state" >
                                        <option>Select State</option>
                                        {result.stateList !== undefined && result.stateList.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                            </div>
                            <div className="mb-3 row ">
                                <label htmlFor="company_name" className="col-sm-2">Company Name</label>
                                <div className="col-sm-10 col-md-5">
                                    <Field type="text" tabIndex={30} name="company_name" placeholder="Your Company Name" className="form-control" maxLength={100} />
                                </div>
                            </div>
                            <div className="mb-3 row ">
                                <label htmlFor="message_content" className="col-sm-12 mb-2">Message</label>
                                <div className="col-sm-12 col-md-7">
                                    <CharacterCountTextarea
                                        nameattr="message_content"
                                        maxLength="1000"
                                        type="textarea"
                                        tabIndex="35"
                                        rows="7"
                                        cols="70"
                                        className="form-control"
                                        placeholder="Your Message"
                                    />
                                </div>
                            </div>

                            <div className="mb-3 row ">
                                <label htmlFor="recaptcha" className="col-sm-2">Verify Code</label>
                                <div className="col-md-3">
                                    <span id="divLoading">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            tabIndex={40}
                                            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                            onChange={(value) => setFieldValue("recaptcha", value)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </fieldset>
                <hr />

                <div className="mb-3 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        tabIndex={45}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <span>
                            Submit Form
                        </span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-block ms-2"
                        onClick={btnClick}
                        tabIndex={50}
                    >Close</button>
                </div>

                <div>
                    <p className="text-end">Trouble posting message? Please send email to <strong>support@chittorgarh.com</strong></p>
                </div>
                <ValidateFormFields />
            </Form>

        </>
    )
}

export default LeadSmeIpoLmForm
