import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';

const validationSchema = Yup.object({
  report_title: Yup.string().trim().required('Please enter report title'),
  security_level_id: Yup.string().trim().required('Please select Security'),
  report_sql: Yup.string().trim().required('Please enter report SQL'),
  id_field: Yup.string().trim().required('Please enter id field'),
  title_field: Yup.string().trim().required('Please enter title field'),
  date_field: Yup.string().trim().required('Please enter date field'),
  status_field: Yup.string().trim().required('Please enter status field'),
});

const ReportCalendarForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (

          <Form id='myForm'>
            {result !== undefined && result.reportcalendar !== undefined && result.reportcalendar.length > 0 &&
              <div className="mb-3 row">
                <label htmlFor="id" className="col-lg-2">ID:</label>
                <div className="col-lg-4">
                  {result.reportcalendar[0].id}
                </div>
              </div>
            }

            <div className="mb-3 row">
              <label htmlFor="report_title" className="col-lg-2">Reports Title</label>
              <div className="col-lg-4">
                <Field className="form-control" type="text" maxLength="100" tabIndex={1} name="report_title" />

              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="security_level_id" className="col-lg-2">Security</label>
              <div className="col-lg-4">
                <Field as="select" className="form-select" tabIndex={5} name="security_level_id">
                  <option value=""></option>
                  {result.securityList !== undefined && result.securityList.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code_desc}
                    </option>
                  ))}
                </Field>

              </div>
            </div>

            <fieldset>
              <legend>Query Setup</legend>
              <hr />
              <div className="mb-3 row">
                <label htmlFor="report_sql" className="col-lg-2">Report Query</label>
                <div className="col-lg-10">
                  <DynamicCharacterCountInput className="form-control" rows="5" tabIndex={10} nameattr={"report_sql"} />
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>Field Setup</legend>
              <hr />
              <div className="mb-3 row">
                <label htmlFor="id_field" className="col-lg-2">ID Field Name</label>
                <div className="col-lg-4">
                  <Field className="form-control" maxLength="50" tabIndex={15} name="id_field" />
                </div>
                <div className="col-lg-6">
                  (i.e. id)
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="title_field" className="col-lg-2">Title Field Name</label>
                <div className="col-lg-4">
                  <Field className="form-control" type="text" maxLength="50" tabIndex={20} name="title_field" />
                </div>
                <div className="col-lg-6">
                  (i.e. cal_title)
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="date_field" className="col-lg-2">Calendar Date Field Name</label>
                <div className="col-lg-4">
                  <Field className="form-control" type="text" maxLength="50" tabIndex={25} name="date_field" />
                </div>
                <div className="col-lg-6">
                  (i.e. cal_date)
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="status_field" className="col-lg-2">Status Field Name</label>
                <div className="col-lg-4">
                  <Field className="form-control" type="text" maxLength="50" tabIndex={30} name="status_field" />
                </div>
                <div className="col-lg-6">
                  (i.e. display_status)
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Others</legend>
              <hr />

              <div className="mb-3 row">
                <label htmlFor="link_file_name" className="col-lg-2">Link URL</label>
                <div className="col-lg-4">
                  <Field className="form-control" type="text" maxLength="250" tabIndex={35} name="link_file_name" />
                </div>
                <div className="col-lg-6">
                  (i.e. ResearchCompanyMasterEdit.asp)
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="additional_link" className="col-lg-2">Additional Link</label>
                <div className="col-lg-10">
                  <Field className="form-control" type="text" maxLength="1000" tabIndex={40} name="additional_link" />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Description</legend>
              <hr />
              <div className="mb-3 row">
                <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
                <div className="col-lg-10">
                  <DynamicCharacterCountInput className="form-control" rows="5" tabIndex={45} nameattr={"report_description"} />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
                <div className="col-lg-10">
                  <DynamicCharacterCountInput className="form-control" rows="3" tabIndex={50} nameattr={"report_disclaimer"} />
                </div>
              </div>
            </fieldset>

            <div className="mb-3 row">
              <label htmlFor="display_status" className="col-lg-2">Display Status</label>
              <div className="col-lg-10">
                <Field className="form-check-input" type="checkbox" tabIndex={55} name="display_status" onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('display_status', newValue);
                }} />
              </div>
              <label htmlFor="lock_status" className="col-lg-2">Lock Status</label>
              <div className="col-lg-2">
                <Field className="form-check-input" type="checkbox" tabIndex={60} name="lock_status" onChange={(e) => {
                  const newValue = e.target.checked;
                  setFieldValue('lock_status', newValue);
                }} />
              </div>
            </div>
            <div className="mb-3 text-center">
              {initialValues.lock_status !== 1 && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                  tabIndex={65}
                >

                  {loading && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  <span>
                    {result.reportcalendar !== undefined && result.reportcalendar.length > 0 ? 'Update' : 'Create'}
                  </span>
                </button>
              )}

              <button
                type="button"
                className="btn btn-secondary btn-block ms-2"
                onClick={handleGoBack}
                tabIndex={70}
              >Cancel</button>
            </div>

            <ValidateFormFields />
          </Form>

        )}
      </Formik>
    </>
  )
}

export default ReportCalendarForm





