import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import IpoTabs from './IPONewPageTabs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { compareDates } from '../Common/CommonFunction';
import IpoListingNewForm from './IpoListingNewForm';


const validationSchema = Yup.object({
    issue_price: Yup.string().required('issue_price is required'),
});

const IpoListingNew = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        company_name: "",
        issue_close_date: "",
        issue_price: "",
        ipo_fv: "",
        lock_status: "",
        ipo_listing_at: "",
        ipo_listing_at_array: [],
        ipo_id: "",
        category_cd: "",
        ipo_listing_date: "",
        bse_script_code: "",
        bse_script_id: "",
        nse_script_symbol: "",
        bse_listing_in_group: "",
        nse_listing_in_group: "",
        sector: "",
        isin: "",
        current_face_value: "",
        suspended_delisted: false,
        display_status: false,
        current_company_name: "",

        open_price: "",
        high_price: "",
        low_price: "",
        close_price: "",
        volume_traded: "",
        open_price_bse: "",
        high_price_bse: "",
        low_price_bse: "",
        close_price_bse: "",
        volume_traded_bse: "",


    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/82");
    };
    useEffect(() => {
        // console.log("initialValues",initialValues)
    }, [initialValues])
    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New IpoListingNew" })
            } else {
                setMetaData({ "title": "Edit IpoListingNew" })
            }
            api.post('/admin-ipolistingnew-read', {
                id: id
            })
                .then(response => {
                    if (response.data.msg === 1) {
                        setResult(response.data);
                        if (!isAddMode) {
                            if (response.data.ipolisting[0].ipo_listing_at !== undefined && response.data.ipolisting[0].ipo_listing_at !== null && response.data.ipolisting[0].ipo_listing_at.trim().length > 0) {
                                response.data.ipolisting[0].ipo_listing_at_array = response.data.ipolisting[0].ipo_listing_at.split(',').map((v) => v.trim())
                            }
                            let ipo_list_day_trade_data = {

                            }
                            response.data.ipoListingDayTrade.map((data) => {
                                if (data.stock_exchange_cd.includes("NSE")) {
                                    ipo_list_day_trade_data.open_price = data.open_price
                                    ipo_list_day_trade_data.low_price = data.low_price
                                    ipo_list_day_trade_data.high_price = data.high_price
                                    ipo_list_day_trade_data.close_price = data.close_price
                                    ipo_list_day_trade_data.volume_traded = data.volume_traded
                                }
                                else {
                                    ipo_list_day_trade_data.open_price_bse = data.open_price
                                    ipo_list_day_trade_data.low_price_bse = data.low_price
                                    ipo_list_day_trade_data.high_price_bse = data.high_price
                                    ipo_list_day_trade_data.close_price_bse = data.close_price
                                    ipo_list_day_trade_data.volume_traded_bse = data.volume_traded
                                }
                            })
                            setInitialValues({
                                ...response.data.ipolisting[0],
                                ...ipo_list_day_trade_data
                            });
                        }
                    } else {
                        dispatch(setMessage(response.data.error));
                    }

                    dispatch(finishLoading());
                }).catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.ipo_listing_at = trimmedValues.ipo_listing_at_array.join(', ');
        trimmedValues.id = trimmedValues.ipo_id;
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);

        if (trimmedValues.issue_close_date == "") {
            alert("Ipo closing date not exists.");
            return false;
        }

        if (trimmedValues.ipo_listing_date != "") {
            if (compareDates(trimmedValues.ipo_listing_date, trimmedValues.issue_close_date) != 1) {
                alert("Ipo listing date must be greater than ipo closing date.");
                return false;
            }
        }
        dispatch(startLoading());

        api.post('/admin-ipolistingnew-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/82");
            } else {
                dispatch(setMessage(response.data.error));
            }
        }).catch(error => {
            console.error('Error:', error);
        }).finally(() => {
            dispatch(finishLoading());
        });
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            {!isAddMode && (<IpoTabs idAtr={result.id} ipo_confirm={result?.ipolisting?.[0]?.ipo_confirmation_status} />)}
            <h1>
                {metaData.title}
            </h1>

            {result !== undefined &&
                result.ipolisting !== undefined &&
                result.ipolisting.length > 0 &&
                result.ipolisting[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )}
            <hr></hr>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <IpoListingNewForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} handleSubmit={handleSubmit} loading={loading} />
            </Formik>

        </>
    )
}

export default IpoListingNew
