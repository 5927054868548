import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Message from "../common/Message";

const Index = () => {
  return (
    <div className='container-fluid main'>
      <Header />
      <Message/>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Index;
