import React from 'react'
import { Field, Form, useFormikContext } from 'formik';
import ValidateFormFields from '../Common/ValidateFormFields';
import NumberInput from '../Common/NumberInput';
import CharacterCountTextarea from '../Common/CharacterCountTextarea';

const TradingCompanyNRITradingForm = ({ result, handleGoBack, loading }) => {
    const { setFieldValue } = useFormikContext();
    return (
        <>
            <Form id='myForm'>
                {result !== undefined && result.tradingCompanyNRI !== undefined && result.tradingCompanyNRI.length > 0 &&
                    <div className="mb-3 row">
                        <label htmlFor="" className="col-lg-2">ID:</label>
                        <div className="col-lg-4">
                            {result.tradingCompanyNRI[0].id}
                        </div>
                    </div>
                }
                <fieldset>
                    <legend>Fees</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="trading_account_opening_charges" className="col-lg-2">Trading Account Opening Fee:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={1} name="trading_account_opening_charges" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. Rs 975
                        </div>
                        <label htmlFor="trading_account_amc" className="col-lg-2">Trdaing Account AMC:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={5} name="trading_account_amc" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. Rs 0
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="pis_account_amc" className="col-lg-2">PIS Account AMC:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={10} name="pis_account_amc" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. Rs 500
                        </div>

                        <label htmlFor="contract_note_reporting_charges" className="col-lg-2">Contract Note Reporting Charges:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={15} name="contract_note_reporting_charges" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. Rs 400
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Brokerage </legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="account_type" className="col-lg-2">Account Type/Plan:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={20} name="account_type" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. I - Secure Plan
                        </div>
                        <label htmlFor="brokerage_equity_delivery" className="col-lg-2">Equity Delivery:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={25} name="brokerage_equity_delivery" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. 0.55%
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="brokerage_equity_future" className="col-lg-2">Equity Future:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={30} name="brokerage_equity_future" maxLength="50" />
                        </div>
                        <div className="col-lg-2">
                            i.e. 0.050% - 0.030%
                        </div>
                        <label htmlFor="brokerage_equity_options" className="col-lg-2">Equity Options:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={35} name="brokerage_equity_options" maxLength="100" />
                        </div>
                        <div className="col-lg-2">
                            i.e. Rs 95 - Rs 65
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="other_charges" className="col-lg-2">Other Charges:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={40} name="other_charges" maxLength="100" />
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Investment Options</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="inv_options_stock" className="col-lg-2">Stock:</label>
                        <div className="col-lg-2">
                            <Field as="select" name="inv_options_stock" className="form-select" tabIndex={45}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <label htmlFor="inv_options_ipo" className="col-lg-2">IPO:</label>
                        <div className="col-lg-2">
                            <Field as="select" name="inv_options_ipo" className="form-select" tabIndex={50}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="inv_options_mutual_fund" className="col-lg-2">Mutual Fund:</label>
                        <div className="col-lg-2">
                            <Field as="select" name="inv_options_mutual_fund" className="form-select" tabIndex={55}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="inv_options_others" className="col-lg-2">Other Options:</label>
                        <div className="col-lg-8">
                            <Field className="form-control" type='text' tabIndex={60} name="inv_options_others" maxLength="100" />
                        </div>
                    </div>
                    <div className="col-lg-2">

                    </div>
                </fieldset>

                <fieldset>
                    <legend>Features</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="feature_3_in_1_account" className="col-lg-2">3 in 1 Account:</label>
                        <div className="col-lg-2">
                            <Field as="select" name="feature_3_in_1_account" className="form-select" tabIndex={65}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <label htmlFor="feature_automated_trading" className="col-lg-2">Automated Trading:</label>
                        <div className="col-lg-2">
                            <Field as="select" name="feature_automated_trading" className="form-select" tabIndex={70}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="feature_free_research_and_tips" className="col-lg-2">Free Research And Tips</label>
                        <div className="col-lg-2">
                            <Field as="select" name="feature_free_research_and_tips" className="form-select" tabIndex={75}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="feature_others" className="col-lg-2">Other Features:</label>
                        <div className="col-lg-8">
                            <Field className="form-control" type='text' tabIndex={80} name="feature_others" maxLength="100" />
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Customer Services</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="customer_care_number" className="col-lg-2">Customer Care Number:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={85} name="customer_care_number" maxLength="15" />
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <label htmlFor="customer_care_email" className="col-lg-2">Customer Care Email Address:</label>
                        <div className="col-lg-2">
                            <Field className="form-control" type='text' tabIndex={90} name="customer_care_email" maxLength="100" />
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Order Type</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="order_type_bo" className="col-lg-2">Bracket Order (BO):</label>
                        <div className="col-lg-2">
                            <Field as="select" name="order_type_bo" className="form-select" tabIndex={95}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <label htmlFor="order_type_co" className="col-lg-2">Cover Order (CO):</label>
                        <div className="col-lg-2">
                            <Field as="select" name="order_type_co" className="form-select" tabIndex={100}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="order_type_amo" className="col-lg-2">After Market Order (AMO):</label>
                        <div className="col-lg-2">
                            <Field as="select" name="order_type_amo" className="form-select" tabIndex={105}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <label htmlFor="order_type_gtc" className="col-lg-2">Good Till Cancelled (GTC):</label>
                        <div className="col-lg-2">
                            <Field as="select" name="order_type_gtc" className="form-select" tabIndex={110}>
                                <option></option>
                                {result.optionList !== undefined && result.optionList.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.code_desc}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className="col-lg-2">

                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Reviews</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="nri_trading_company_review" className="col-lg-2">Trading Company Review:</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="nri_trading_company_review"
                                type="textarea"
                                cols="50"
                                tabIndex="115"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="trading_software_review" className="col-lg-2">Trading Software Review:</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="trading_software_review"
                                type="textarea"
                                cols="50"
                                tabIndex="120"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="account_opening_review" className="col-lg-2">Account Opening Review:</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="account_opening_review"
                                type="textarea"
                                cols="50"
                                tabIndex="125"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="mutual_funds_review" className="col-lg-2">Mutual Funds Review:</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="mutual_funds_review"
                                type="textarea"
                                cols="50"
                                tabIndex="130"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="conclusion" className="col-lg-2">Conclusion(html):</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="conclusion"
                                type="textarea"
                                cols="50"
                                tabIndex="135"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="pros" className="col-lg-2">Pros(html):</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="pros"
                                type="textarea"
                                cols="50"
                                tabIndex="140"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="cons" className="col-lg-2">Cons(html):</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="cons"
                                type="textarea"
                                cols="50"
                                tabIndex="145"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="promotion_offer_brief" className="col-lg-2">Promotion Offer Brief(text):</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="promotion_offer_brief"
                                type="textarea"
                                cols="50"
                                tabIndex="150"
                                rows="5"
                                className="form-control"
                                maxLength="500"
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="promotion_offer" className="col-lg-2">Promotion Offers(html):</label>
                        <div className="col-lg-10">
                            <CharacterCountTextarea
                                nameattr="promotion_offer"
                                type="textarea"
                                cols="50"
                                tabIndex="155"
                                rows="5"
                                className="form-control"
                            />
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>Display</legend>
                    <hr />
                    <div className="mb-3 row">
                        <label htmlFor="display_order" className="col-lg-2">Display Order</label>
                        <div className="col-lg-2">
                            <NumberInput classNameAtr="form-control" tabIndexAtr={160} maxLength="3" nameAtr="display_order" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="compare_display_status" className="col-lg-2">Show on Comparison Page? </label>
                        <div className="col-lg-4">
                            <Field className="form-check-input" type="checkbox" tabIndex={165} name="compare_display_status" onChange={(e) => {
                                const newValue = e.target.checked;
                                setFieldValue('compare_display_status', newValue);
                            }} />
                        </div>
                        <label htmlFor="display_status" className="col-lg-2">Display Status</label>
                        <div className="col-lg-4">
                            <Field className="form-check-input" type="checkbox" tabIndex={170} name="display_status" onChange={(e) => {
                                const newValue = e.target.checked;
                                setFieldValue('display_status', newValue);
                            }} />
                        </div>
                    </div>

                    <div className="mb-3 text-center">
                        {result != undefined && result.tradingCompany != undefined && result.tradingCompany[0].lock_status !== 1 && (
                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={loading}
                                tabIndex={175}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                <span>
                                    Update
                                </span>
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-secondary btn-block ms-2"
                            onClick={handleGoBack}
                            tabIndex={180}
                        >Cancel</button>
                    </div>

                    <ValidateFormFields />
                </fieldset>
            </Form>
        </>
    )
}

export default TradingCompanyNRITradingForm





