import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import ValidateFormFields from '../Common/ValidateFormFields';
import DynamicCharacterCountInput from '../Common/CharacterCountTextarea';
import NumberInput from '../Common/NumberInput';

const validationSchema = Yup.object({
    report_title: Yup.string().trim().required('Title is required'),
    report_type_id: Yup.string().trim().required('Report type is required'),
    security_level_id: Yup.string().trim().required('Security level is required'),
    report_db_id: Yup.string().trim().required('Report DB is required'),
    report_sql: Yup.string().trim().required('Report query is required'),
});

const ReportCreateForm = ({ result, handleGoBack, initialValues, handleSubmit, loading }) => {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (

                    <Form id='myForm'>

                        {result !== undefined &&
                            result.report !== undefined &&
                            result.report.length > 0 &&
                            result.report[0].lock_status == 1 && (
                                <h3 className="text-danger">
                                    Record Is Locked , you can't update it.
                                </h3>
                            )}

                        {result !== undefined && result.report !== undefined && result.report.length > 0 &&
                            <div className="mb-3 row">
                                <label htmlFor="id" className="col-lg-2">ID:</label>
                                <div className="col-lg-4">
                                    {result.report[0].id}
                                </div>
                            </div>
                        }
                        <div className="mb-3 row">
                            <label htmlFor="report_title" className="col-lg-2">Reports Title (Max characters: 100)</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="100" tabIndex={1} name="report_title" />
                            </div>
                            <label htmlFor="report_type_id" className="col-lg-2">Reports Type</label>
                            <div className="col-lg-4">
                                <Field as="select" name="report_type_id" className="form-select" tabIndex={5}>
                                    <option value={''}></option>
                                    {result.reportTypes !== undefined && result.reportTypes.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.code_desc}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="security_level_id" className="col-lg-2">Security</label>
                            <div className="col-lg-4">
                                <Field as="select" name="security_level_id" className="form-select" tabIndex={10}>
                                    <option value={''}></option>
                                    {result.securityLevels !== undefined && result.securityLevels.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.code_desc}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <label htmlFor="report_db_id" className="col-lg-2">Report DB</label>
                            <div className="col-lg-4">
                                <Field as="select" name="report_db_id" className="form-select" tabIndex={15}>
                                    <option value={''}></option>
                                    {result.reportDBLists !== undefined && result.reportDBLists.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.code_desc}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <legend>Query Setup</legend>
                        <hr />
                        <p><b>Order of Execution</b>: FROM, WHERE, GROUP BY, HAVING, SELECT, ORDER BY</p>
                        <div className="mb-3 row">
                            <label htmlFor="report_sql_count" className="col-lg-2">Report Count Query</label>
                            <div className="col-lg-8">
                                <DynamicCharacterCountInput className="form-control" nameattr={"report_sql_count"} rows="5" tabIndex={20} />
                            </div>
                            <div className="col-lg-2">
                                (i.e. use count column only, <br /> Use [[Parameter1]] for additional parameter
                                )
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="report_sql" className="col-lg-2">Report Query</label>
                            <div className="col-lg-8">
                                <DynamicCharacterCountInput className="form-control" nameattr={"report_sql"} rows="5" tabIndex={25} />
                            </div>
                            <div className="col-lg-2">
                                (i.e. use ~ for hidden fields, <br /> Use [[Parameter1]] for additional parameter
                                )
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="group_by" className="col-lg-2">Group By</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text" maxLength="100" name="group_by" tabIndex={30} />
                            </div>
                            <label htmlFor="default_sort" className="col-lg-2">Order By</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text" maxLength="100" name="default_sort" tabIndex={35} />
                            </div>
                            <div className="col-lg-2">
                                (i.e. idasc or id) Default: <strong>1desc</strong>. No space allowed.
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="having_clause" className="col-lg-2">Having</label>
                            <div className="col-lg-2">
                                <Field className="form-control" type="text" maxLength="100" name="having_clause" tabIndex={40} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="pre_sql" className="col-lg-2">Pre Query (Semicolon (;) seperated)</label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInput className="form-control" nameattr={"pre_sql"} rows="5" tabIndex={45} />
                            </div>
                        </div>
                        <legend>Update Status Setup</legend>
                        <hr />
                        <div className="mb-3 row">
                            <label htmlFor="update_status_table" className="col-lg-2">Table Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="250"
                                    name="update_status_table" tabIndex={50} />
                            </div>
                            <div className="col-lg-6">
                                (i.e. broker_leads)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="update_status_id_field" className="col-lg-2">ID Field Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="50"
                                    name="update_status_id_field" tabIndex={55} />
                            </div>
                            <div className="col-lg-6">
                                (i.e. id)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="update_status_field_name" className="col-lg-2">Status Field Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="50"
                                    name="update_status_field_name" tabIndex={60} />
                            </div>
                            <div className="col-lg-6">
                                (i.e. display_status)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="update_display_order_field_name" className="col-lg-2">Display Order Field Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="50"
                                    name="update_display_order_field_name" tabIndex={65} />
                            </div>
                        </div>
                        <legend>Others</legend>
                        <hr />
                        <div className="mb-3 row">
                            <label htmlFor="page_size" className="col-lg-2">Page Size</label>
                            <div className="col-lg-2">
                                <NumberInput classNameAtr={"form-control"} tabIndexAtr={"70"} maxLength={"4"} nameAtr={"page_size"} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="link_field_name" className="col-lg-2">Link Field Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="50" tabIndex={75} name="link_field_name"
                                />
                            </div>
                            <div className="col-lg-6">
                                (i.e. id)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="link_file_name" className="col-lg-2">Link URL</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="250" tabIndex={80} name="link_file_name"
                                />
                            </div>
                            <div className="col-lg-6">
                                (i.e. ResearchCompanyMasterEdit.asp; Keep the field blank to just update the
                                display status)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="text_area_field_name" className="col-lg-2">Textarea Field Name</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="50"
                                    name="text_area_field_name" tabIndex={85} />
                            </div>
                            <div className="col-lg-6">
                                (i.e. comment, message)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="search_column_names" className="col-lg-2">Search Column Names</label>
                            <div className="col-lg-4">
                                <Field className="form-control" type="text" maxLength="250"
                                    name="search_column_names" tabIndex={90} />
                            </div>
                            <div className="col-lg-6">
                                (i.e. company_name,display_status)
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="additional_link" className="col-lg-2">Additional Link</label>
                            <div className="col-lg-10">
                                <Field className="form-control" type="text" maxLength="1000" name="additional_link" tabIndex={95} />
                            </div>
                        </div>
                        <legend>Additional Param</legend>
                        <hr />
                        <div className="mb-3 row row">
                            <label htmlFor="additional_parameter" className="col-lg-2">Additional Parameter</label>
                            <div className="col-lg-4">
                                <Field as="select" name="additional_parameter" className="form-select" tabIndex={100} id="additional_parameterddl">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </Field>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="sql_dropdown" className="col-lg-2">Dropdown Report Query</label>
                            <div className="col-lg-8">
                                <DynamicCharacterCountInput className="form-control" nameattr={"sql_dropdown"} rows="5" tabIndex={105} />
                            </div>
                            <div className="col-lg-2">
                                (i.e. use alias id , name for fields)
                            </div>
                        </div>
                        <legend>Description</legend>
                        <hr />
                        <div className="mb-3 row">
                            <label htmlFor="report_description" className="col-lg-2">Reports Description (HTML)</label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInput className="form-control" nameattr={"report_description"} rows="5" tabIndex={110} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="report_disclaimer" className="col-lg-2">Reports Disclaimer (HTML)</label>
                            <div className="col-lg-10">
                                <DynamicCharacterCountInput className="form-control" nameattr={"report_disclaimer"} rows="5" tabIndex={115} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="display_status" className="col-lg-2">Display Status
                                <Field className="form-check-input ms-3" type="checkbox" name="display_status" tabIndex={120} onChange={(e) => {
                                    setFieldValue('display_status', e.target.checked);
                                }} />
                            </label>

                            <label htmlFor="lock_status" className="col-lg-2">Lock Status
                                <Field className="form-check-input ms-3" type="checkbox" name="lock_status" tabIndex={125} onChange={(e) => {
                                    setFieldValue('lock_status', e.target.checked);
                                }} />
                            </label>
                            <label htmlFor="disable_display_status_feature" className="col-lg-2">Disable display status feature
                                <Field className="form-check-input ms-3" type="checkbox" name="disable_display_status_feature" tabIndex={125} onChange={(e) => {
                                    setFieldValue('disable_display_status_feature', e.target.checked);
                                }} />
                            </label>
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            {!initialValues.lock_status == 1 &&
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                    tabIndex={130}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                    )}
                                    <span>
                                        {result.report !== undefined && result.report.length > 0 ? 'Update' : 'Create'}
                                    </span>
                                </button>
                            }

                            <button
                                type="button"
                                className="btn btn-secondary btn-block ms-2"
                                onClick={handleGoBack}
                                tabIndex={135}
                            >Cancel</button>
                        </div>
                        <ValidateFormFields />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ReportCreateForm
