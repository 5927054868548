import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useApi from "../../../common/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet-async';
import {
    finishLoading,
    startLoading,
} from "../../../state/slices/loadingSlice";
import { customReplace } from '../Common/CommonFunction';

const ReportCalendarShow = () => {
    const alphaUrl = process.env.REACT_APP_ALPHA_URL;
    const styles = {
        containerStyle: {
            minHeight: "80px",
            position: 'relative',
        },
        backgroundStyle: {
            position: 'absolute',
            pointerEvents: 'none',
            fontSize: '50px',
            opacity: 0.15,
            width: '100%',
        },
        textStyle: {
            textAlign: 'center',
            pointerEvents: 'none',
        },
        calendarTd: {
            width: `calc(100% / 7)`
        }
    }
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const [result, setResult] = useState({
        reportData: [],
    });
    const api = useApi();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [title, setTitle] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var month = queryParams.get("month");
    var year = queryParams.get("year");

    const constantURLPart = "report-calendar";

    // Get the current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

    const [currentMonth, setCurrentMonth] = useState(null);
    const [currentYear, setCurrentYear] = useState(null);

    const [eventURL, setEventURL] = useState([]);

    // Function to get the number of days in a month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    // Function to generate an array of days in a month
    const generateDaysInMonth = (year, month) => {
        const daysInMonth = getDaysInMonth(year, month);
        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            console.log(currentDate);
            if (month == null) {
                month = currentDate.getMonth() + 1;
            }
            if (month > 12 || month < 1) {
                month = currentDate.getMonth() + 1;
            }
            if (year == null) {
                year = currentDate.getFullYear();
            }

            setCurrentMonth(parseInt(month));
            setCurrentYear(parseInt(year));
        } else {
            didMountRef.current = "true";
        }

    }, [id]);

    useEffect(() => {
        if (currentMonth != null) {
            dispatch(startLoading());
            api.post("/admin-reportcalendar-show", {
                month: currentMonth,
                year: currentYear,
                id: id,
            })
                .then((response) => {
                    if (response.data.msg === 1) {
                        console.log(response.data)
                        setResult(response.data);
                        if (
                            response.data.reportcalendar &&
                            response.data.reportcalendar.length > 0
                        ) {
                            setTitle(response.data.reportcalendar[0].report_title);
                            const idField =
                                response.data.reportcalendar &&
                                    response.data.reportcalendar.length > 0
                                    ? response.data.reportcalendar[0].id_field
                                    : "";
                            const eventURLs = response.data.reportData.reduce((urls, event) => {
                                const key = event[idField]; // Assuming idField is the key you want to use
                                const url = customReplace(
                                    customReplace(response.data.reportcalendar[0].link_file_name, "[" + idField + "]", key),
                                    "[alphapath]",
                                    alphaUrl
                                );
                                urls[key] = url;
                                return urls;
                            }, {});

                            setEventURL(eventURLs);
                        }
                    }
                })
                .finally(() => {
                    dispatch(finishLoading());
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [id, currentMonth, currentYear]);

    // Function to generate calendar grid
    const renderCalendarGrid = () => {

        const daysOfMonth = generateDaysInMonth(currentYear, currentMonth);

        let firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay(); // Get the day of the week for the first day of the month

        // Increment the first day of the month by one day
        firstDayOfMonth = (firstDayOfMonth + 1) % 7;

        // Calculate the number of empty cells to start from Monday
        const numEmptyCells = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

        // Generate empty cells for days before the first day of the month
        const emptyCells = Array.from({ length: numEmptyCells }, (_, i) => "");

        // Combine empty cells and days of the month
        const calendarGrid = [...emptyCells, ...daysOfMonth];

        // Generate rows and columns for the calendar grid
        const rows = [];
        let rowIndex = 0;
        let dayIndex = 0;
        while (dayIndex < calendarGrid.length) {
            rows[rowIndex] = calendarGrid.slice(dayIndex, dayIndex + 7);
            rowIndex++;
            dayIndex += 7;
        }

        // Render rows and columns with events
        return rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
                {row.map((day, colIndex) => {
                    // Check if there are events for this day
                    const eventsForDay = result.reportData.filter((event) => {
                        const date = new Date(event[result.reportcalendar[0].date_field]); // Replace this with your date object
                        const dayOfMonth = date.getDate();
                        return dayOfMonth === day;
                    });

                    var highlightToday = "";
                    var newDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                    if (formattedDate == newDate) {
                        highlightToday = "highlightCalendarToday";
                    }

                    return (
                        <td key={colIndex} style={styles.calendarTd} className={`${highlightToday}`}>
                            <div style={styles.containerStyle}>
                                <div style={styles.backgroundStyle}>
                                    <div style={styles.textStyle}>{day}</div>
                                </div>

                                <ul style={{ paddingLeft: '1rem' }}>
                                    {eventsForDay.map((event, index) => (

                                        <li key={index} style={{ listStyleType: 'none' }} className="mb-3">
                                            <a href={eventURL[event[result.reportcalendar[0].id_field]]} className="text-decoration-none">
                                                <mark>{event[result.reportcalendar[0].title_field]}</mark>
                                            </a>

                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </td>
                    );
                })}
            </tr>
        ));
    };

    // Function to handle navigation to the previous month
    const handlePrevMonth = () => {
        var previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        var previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        navigate(`/admintool9x/${constantURLPart}/${id}?month=${previousMonth}&year=${previousYear}`);
        setCurrentMonth(previousMonth);
        setCurrentYear(previousYear);
    };

    // Function to handle navigation to the next month
    const handleNextMonth = () => {
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
        navigate(`/admintool9x/${constantURLPart}/${id}?month=${nextMonth}&year=${nextYear}`);
        setCurrentMonth(nextMonth);
        setCurrentYear(nextYear);
    };

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
            </Helmet>
            <h1>{title}</h1>
            {result?.reportcalendar?.[0]?.additional_link &&
                <div dangerouslySetInnerHTML={{ __html: result.reportcalendar[0].additional_link }} />
            }
            <div className="d-flex justify-content-center mb-3">
                <button className="btn mx-2 fs-3 fw-bold" onClick={handlePrevMonth}> {"<<"} </button>
                <span className="mt-3" style={{ fontWeight: 'bold' }}>
                    {new Date(currentYear, currentMonth - 1).toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                    })}
                </span>
                <button className="btn mx-2 fs-3 fw-bold" onClick={handleNextMonth}>{">>"}</button>

            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover table-sm table-fixed">
                    <thead>
                        <tr style={{ backgroundCcolor: "#CCC" }}>
                            <th className="text-center">
                                <b>Sun</b>
                            </th>
                            <th className="text-center">
                                <b>Mon</b>
                            </th>
                            <th className="text-center">
                                <b>Tue</b>
                            </th>
                            <th className="text-center">
                                <b>Wed</b>
                            </th>
                            <th className="text-center">
                                <b>Thu</b>
                            </th>
                            <th className="text-center">
                                <b>Fri</b>
                            </th>
                            <th className="text-center">
                                <b>Sat</b>
                            </th>
                        </tr>
                    </thead>

                    <tbody style={{ fontWeight: "bold" }}>
                        {currentYear != null && currentMonth != null && renderCalendarGrid()}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReportCalendarShow;
