import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import MemberForm from './MemberForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    email: Yup.string().trim().email("Invalid Email Format").required("Email is required"),
    password: Yup.string().trim().required("Password is required"),
    name: Yup.string().trim().required("Please Enter your Name"),
    phone: Yup.string().trim().required("Please Enter your Phone Number"),
});

const Member = () => {
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        name: "",
        login_id: "",
        password: "",
        display_name: "",
        email: "",
        state: "",
        city: "",
        phone: "",
        club_status_id: "",
        login_cnt: "",
        last_logon_on: "",
        security_level_id: "",
        last_login_ip: "",
        comments: "",
        lead_sharing_status: "",
        signature_status: false,
        signature: "",
        status: "",
        member_disable_reason: "",
        is_member_active: "",
        reverification_dt: "",
        email_notification: "",
        memberSameIpList: [
            {
                id: "", login_id: "", password: "", display_name: "", email: "", date_added: "",
                last_login_ip: "", last_logon_on: "", status: ""
            }
        ],
        memberSamePasswordList: [
            {
                id: "", login_id: "", password: "", display_name: "", email: "",
                date_added: "", last_login_ip: "", last_logon_on: "", status: ""
            }
        ],
        issueStockExchangeList: [
            {
                id: "", EnabledIPOMsgs: "", DisabledIPOMsgs: "", EnabledMsgs: "", DisabledMsgs: "", EnabledGMPMsgs: "",
                DisabledGMPMsgs: "", EnabledArchiveMsgs: "", DisabledArchiveMsgs: "", EnabledRecommendationMsgs: "",
                DisabledRecommendationMsgs: ""
            }
        ]
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/27");
    };

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add member" })
            } else {
                setMetaData({ "title": "Edit Member" })
            }
            callMemberData();
        } else {
            didMountRef.current = "true";
        }
    }, [id]);
    
    const callMemberData = () => {
        api.post('/admin-member-read', {
            id: id
        }).then(response => {
            if (response.data.msg === 1) {
                setResult(response.data);
                console.log(response.data);
                if (!isAddMode) {
                    setInitialValues((prevValues) => ({
                        ...prevValues,
                        id: response.data.member[0].id,
                        name: response.data.member[0].name,
                        login_id: response.data.member[0].login_id,
                        password: response.data.member[0].password,
                        display_name: response.data.member[0].display_name,
                        email: response.data.member[0].email,
                        state: response.data.member[0].state,
                        city: response.data.member[0].city,
                        phone: response.data.member[0].phone,
                        club_status_id: response.data.member[0].club_status_id,
                        login_cnt: response.data.member[0].login_cnt,
                        last_logon_on: response.data.member[0].last_logon_on,
                        security_level_id: response.data.member[0].security_level_id,
                        last_login_ip: response.data.member[0].last_login_ip,
                        comments: response.data.member[0].comments,
                        lead_sharing_status: response.data.member[0].lead_sharing_status,
                        signature_status: response.data.member[0].signature_status,
                        signature: response.data.member[0].signature,
                        status: response.data.member[0].status,
                        member_disable_reason: response.data.member[0].member_disable_reason,
                        is_member_active: response.data.member[0].is_member_active,
                        reverification_dt: response.data.member[0].reverification_dt,
                        email_notification: response.data.member[0].email_notification,
                        memberSameIpList: response.data.memberSameIpList,
                        memberSamePasswordList: response.data.memberSamePasswordList,
                        issueStockExchangeList: response.data.issueStockExchangeList
                    }))
                }
            } else {
                dispatch(setMessage(response.data.error));
            }

            dispatch(finishLoading());
        })
            .catch(error => {
                console.error('Error:', error);
                dispatch(finishLoading());
            });
    }
    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});
        // Perform actions here, such as making an API request
        console.log('Form submitted with values:', trimmedValues);
        dispatch(startLoading());

        api.post('/admin-member-save', {
            values: trimmedValues
        }).then(response => {
            if (response.data.msg === 1) {
                dispatch(setMessage(response.data.success));
                navigate("/admintool9x/report/27");
            } else {
                dispatch(setMessage(response.data.error));
            }
        })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());
            });
        // Set submitting to false to allow the form to be submitted again
        setSubmitting(false);
    };

    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>
            <h1>
                {metaData.title}
            </h1>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <MemberForm result={result} handleGoBack={handleGoBack} initialValues={initialValues} loading={loading} isAddMode={isAddMode} callMemberData={callMemberData} id={id} />
            </Formik>
        </>
    )
}

export default Member;
