import React, { useEffect } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import NumberDecimalInput from '../Common/NumberDecimalInput';
import "./RowColor.css";

const EtcChargesTable = ({ result }) => {

    const { setFieldValue, values } = useFormikContext();

    const calculateBookwiseChargesPercentage = (field, value) => {
        setFieldValue(field, value / 100000);
    }
    const calculateActualNumber = (field, value) => {
        setFieldValue(field, value * 100000);
    }

    const setTableColor = (value, cpValue) => {
        console.log(value, cpValue)
        return value !== cpValue ? "bg-warning-custom" : '';
    }
    useEffect(() => {
        if (result.tradingCompanyCommonCharges !== undefined && result.tradingCompanyCommonCharges[0].n_i_etc !== "") {
            calculateBookwiseChargesPercentage("n_i_etc_per", values.n_i_etc);
            calculateBookwiseChargesPercentage("b_i_etc_per", values.b_i_etc);
            calculateBookwiseChargesPercentage("n_d_etc_per", values.n_d_etc);
            calculateBookwiseChargesPercentage("b_d_etc_per", values.b_d_etc);
            calculateBookwiseChargesPercentage("n_f_etc_per", values.n_f_etc);
            calculateBookwiseChargesPercentage("b_f_etc_per", values.b_f_etc);
            calculateBookwiseChargesPercentage("n_o_etc_per", values.n_o_etc);
            calculateBookwiseChargesPercentage("b_o_etc_per", values.b_o_etc);
            calculateBookwiseChargesPercentage("n_cf_etc_per", values.n_cf_etc);
            calculateBookwiseChargesPercentage("b_cf_etc_per", values.b_cf_etc);
            calculateBookwiseChargesPercentage("n_co_etc_per", values.n_co_etc);
            calculateBookwiseChargesPercentage("b_co_etc_per", values.b_co_etc);
        }
    }, [values.common_n_i_etc]); // Run once on mount

    return (
        <>
            <Form>
                <h3>ETC Charges (NSE/BSE)</h3>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover" id="etc_table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th className="text-center">Charges BSE/NSE</th>
                                <th>Common Charges (Cr.)</th>
                                <th>Copy</th>
                                <th>Brokerwise Charges (per crore)</th>
                                <th>Brokerwise Charges (in %)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Intraday</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_i_etc, values.n_i_etc)}>
                                    <Field className="form-control" type="text" name="common_n_i_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_i_etc, values.n_i_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_i_etc", values.common_n_i_etc)
                                            calculateBookwiseChargesPercentage("n_i_etc_per", values.common_n_i_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_i_etc, values.n_i_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control" type="text" nameAtr="n_i_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_i_etc_per", e.target.value) }}
                                    />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="n_i_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_i_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_i_etc, values.b_i_etc)}>
                                    <Field className="form-control" type="text" name="common_b_i_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_i_etc, values.b_i_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_i_etc", values.common_b_i_etc)
                                            calculateBookwiseChargesPercentage("b_i_etc_per", values.common_b_i_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_i_etc, values.b_i_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_i_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_i_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_i_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_i_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Delivery</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_d_etc, values.n_d_etc)}>
                                    <Field className="form-control" type="text" name="common_n_d_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_d_etc, values.n_d_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_d_etc", values.common_n_d_etc)
                                            calculateBookwiseChargesPercentage("n_d_etc_per", values.common_n_d_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_d_etc, values.n_d_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="n_d_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_d_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" id="n_d_etc_per" nameAtr="n_d_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_d_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_d_etc, values.b_d_etc)}>
                                    <Field className="form-control" type="text" name="common_b_d_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_d_etc, values.b_d_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_d_etc", values.common_b_d_etc)
                                            calculateBookwiseChargesPercentage("b_d_etc_per", values.common_b_d_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_d_etc, values.b_d_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_d_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_d_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_d_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_d_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Future</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_f_etc, values.n_f_etc)}>
                                    <Field className="form-control" type="text" name="common_n_f_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_f_etc, values.n_f_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_f_etc", values.common_n_f_etc)
                                            calculateBookwiseChargesPercentage("n_f_etc_per", values.common_n_f_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_f_etc, values.n_f_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="n_f_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_f_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="n_f_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_f_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_f_etc, values.b_f_etc)}>
                                    <Field className="form-control" type="text" name="common_b_f_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_f_etc, values.b_f_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_f_etc", values.common_b_f_etc)
                                            calculateBookwiseChargesPercentage("b_f_etc_per", values.common_b_f_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_f_etc, values.b_f_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_f_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_f_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_f_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_f_etc", e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Options</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_o_etc, values.n_o_etc)}>
                                    <Field className="form-control" type="text" name="common_n_o_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_o_etc, values.n_o_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_o_etc", values.common_n_o_etc)
                                            calculateBookwiseChargesPercentage("n_o_etc_per", values.common_n_o_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_o_etc, values.n_o_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="n_o_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_o_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="n_o_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_o_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_o_etc, values.b_o_etc)}>
                                    <Field className="form-control" type="text" name="common_b_o_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_o_etc, values.b_o_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_o_etc", values.common_b_o_etc)
                                            calculateBookwiseChargesPercentage("b_o_etc_per", values.common_b_o_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_o_etc, values.b_o_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_o_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_o_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_o_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_o_etc", e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Currency Futures</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_cf_etc, values.n_cf_etc)}>
                                    <Field className="form-control" type="text" name="common_n_cf_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_cf_etc, values.n_cf_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_cf_etc", values.common_n_cf_etc)
                                            calculateBookwiseChargesPercentage("n_cf_etc_per", values.common_n_cf_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_cf_etc, values.n_cf_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="n_cf_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_cf_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="n_cf_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_cf_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_cf_etc, values.b_cf_etc)}>
                                    <Field className="form-control" type="text" name="common_b_cf_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_cf_etc, values.b_cf_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_cf_etc", values.common_b_cf_etc)
                                            calculateBookwiseChargesPercentage("b_cf_etc_per", values.common_b_cf_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_cf_etc, values.b_cf_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_cf_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_cf_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_cf_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_cf_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" className="text-center align-middle bg-custom-gray"><b>Currency Options</b></td>
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">NSE</label></td>
                                <td className={setTableColor(values.common_n_co_etc, values.n_co_etc)}>
                                    <Field className="form-control" type="text" name="common_n_co_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_n_co_etc, values.n_co_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("n_co_etc", values.common_n_co_etc)
                                            calculateBookwiseChargesPercentage("n_co_etc_per", values.common_n_co_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_n_co_etc, values.n_co_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="n_co_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("n_co_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="n_co_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("n_co_etc", e.target.value) }} />
                                </td>
                            </tr>
                            <tr className="border-bottom-info">
                                <td className="align-middle fs-14 text-center"><label htmlFor="" className="fw-bold">BSE</label></td>
                                <td className={setTableColor(values.common_b_co_etc, values.b_co_etc)}>
                                    <Field className="form-control" type="text" name="common_b_co_etc" maxLength="10" readOnly />
                                </td>
                                <td className={setTableColor(values.common_b_co_etc, values.b_co_etc) + " text-center align-middle"}>
                                    <i className="fa fa-arrow-circle-right fw-bold fs-3" aria-hidden="true"
                                        onClick={() => {
                                            setFieldValue("b_co_etc", values.common_b_co_etc)
                                            calculateBookwiseChargesPercentage("b_co_etc_per", values.common_b_co_etc)
                                        }} ></i>
                                </td>
                                <td className={setTableColor(values.common_b_co_etc, values.b_co_etc)}>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control two-decimals" type="text" nameAtr="b_co_etc" maxLength="10"
                                        dynamicOnInput={(e) => { calculateBookwiseChargesPercentage("b_co_etc_per", e.target.value) }} />
                                </td>
                                <td>
                                    <NumberDecimalInput decimalPoint={6} classNameAtr="form-control text_num_per" type="text" nameAtr="b_co_etc_per" maxLength="10"
                                        dynamicOnInput={(e) => { calculateActualNumber("b_co_etc", e.target.value) }} />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </Form>
        </>
    );
};

export default EtcChargesTable;
