import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../common/useApi';
import { finishLoading, startLoading, selectLoading } from '../../../state/slices/loadingSlice';
import { setMessage } from '../../../state/slices/message';
import NCDForm from './NCDForm';
import NCDTabs from './NCDTabs';
import { isValidVariable } from '../Common/CommonFunction';

const NCD = () => {

    const validationSchema = Yup.object({
        company_name: Yup.string().trim().required('Company Name is Mandatory'),
        news_title: Yup.string().trim().required('News Title is required').matches(/NCD/i, 'News Title must contain "NCD"'),
        urlrewrite_folder_name: Yup.string().trim().required('URL Rewrite folder name is required'),
        registrar_email: Yup.string().email('Invalid Registar Email ').nullable(),
    });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [metaData, setMetaData] = useState({
        title: ""
    });
    const { id } = useParams();
    const isAddMode = !id;
    const api = useApi();
    const didMountRef = useRef(process.env.REACT_APP_PRODUCTION);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({

        id: "",
        company_name: "",
        secured_ncd: true,
        unsecured_ncd: "",
        listing_at_array: [],
        news_title: "",
        issue_type: "",
        urlrewrite_folder_name: "",

        prospectus_drhp: "",
        prospectus_rhp: "",
        prospectus_drhp_shelf: "",
        prospectus_rhp_shelf: "",

        issue_open_date: "",
        record_date: "",
        issue_close_date: "",
        deemed_date_of_allotment: "",

        face_value: "",
        market_lot_size: "",
        issue_price: "",
        minimum_order_quantity: "",
        issue_size_base: "",
        tenor: "",
        issue_size_shelf: "",

        payment_frequency: "",
        option_to_retain_oversubscription: '',
        tranche_issue_size: '',
        overall_issue_size: '',
        tranche_number: '',
        interest_on_refund: "",
        allotment_url: "",

        // -------NCD Values----------

        bse_scriptcode: "",
        bse_script_symbol: "",
        nse_symbol: "",

        // ----------Bidding Auto Load Values--------

        shares_offered_category_name_1: "",
        shares_offered_category_name_2: "",
        shares_offered_category_name_3: "",
        shares_offered_category_name_4: "",

        shares_offered_percent_category_1: "",
        shares_offered_percent_category_2: "",
        shares_offered_percent_category_3: "",
        shares_offered_percent_category_4: "",
        shares_offered_percent_others: "",

        shares_offered_category_1: "",
        shares_offered_category_2: "",
        shares_offered_category_3: "",
        shares_offered_category_4: "",
        shares_offered_others: "",
        shares_offered_total: "",

        // ------ NCD Share Offered Data Values ------

        nri_allowed: "",
        rating_brief: "",
        rating_detail: "",

        // =====****=========

        series: "",
        coupon_rates: "",
        coupon_incentive: "",

        // =======****========

        other_issue_detail: "",
        notices: "",
        registrar_id: "",
        registrar_email: "",
        debenture_trusties_id: "",

        lead_managers: "",
        referral_link: "",
        referral_short_link: "",
        display_order: "",

        issue_withdraw: false,
        display_status: true,
        lock_status: false,

        initialItems: [],
        selectedItems: [],
        selectedItemsIds: [],
    });

    const handleGoBack = () => {
        dispatch(setMessage(""));
        navigate("/admintool9x/report/90");
    };

    const validate = (values) => {
        if (values.issue_open_date > values.issue_close_date) {
            alert("Issue Closing Date can not be before Issue Opening Date")
            return false;
        }

        if (values.unsecured_ncd != 1 && values.secured_ncd != 1) {
            alert("Please Choose The Security Type")
            return false;
        }

        if (values.listing_at_array.length === 0) {
            alert("Please Choose the Exchange For Listing")
            return false
        }
        const category1 = isValidVariable(values.shares_offered_percent_category_1) ? parseFloat(values.shares_offered_percent_category_1) : 0;
        const category2 = isValidVariable(values.shares_offered_percent_category_2) ? parseFloat(values.shares_offered_percent_category_2) : 0;
        const category3 = isValidVariable(values.shares_offered_percent_category_3) ? parseFloat(values.shares_offered_percent_category_3) : 0;
        const category4 = isValidVariable(values.shares_offered_percent_category_4) ? parseFloat(values.shares_offered_percent_category_4) : 0;
        const others = isValidVariable(values.shares_offered_percent_others) ? parseFloat(values.shares_offered_percent_others) : 0;

        if (!isNaN(category1) && !isNaN(category2) && !isNaN(category3) && !isNaN(category4) && !isNaN(others)) {
            if (category1 > 0) {
                const total = category1 + category2 + category3 + category4 + others;
                if (total !== 100) {
                    alert("Shares offered% of all the categories should sum up to 100");
                    return false;
                }
            }
        }
        else {
            alert("Please enter valid numeric values for all categories");
            return false;
        }
        if (values.urlrewrite_folder_name.includes("tranche") && (values.tranche_issue_size == "" || values.tranche_number == "")) {
            alert("Since the ncd type is tranche, please make sure that tranche issue size and tranche number are entered!");
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (didMountRef.current == "true") {
            dispatch(startLoading());
            console.log("id : " + id);
            if (isAddMode) {
                setMetaData({ "title": "Add New NCD" })
            } else {
                setMetaData({ "title": "Edit NCD" })
            }
            api.post('/admin-ncd-read', {
                id: id
            }).then(response => {
                if (response.data.msg === 1) {
                    setResult(response.data);
                    console.log(response.data.ncd);
                    if (!isAddMode) {
                        if (response.data.ncd[0].listing_at !== undefined && response.data.ncd[0].listing_at !== null && response.data.ncd[0].listing_at.trim().length > 0) {
                            response.data.ncd[0].listing_at_array = response.data.ncd[0].listing_at.split(',').map((v) => v.trim())
                        } else {
                            response.data.ncd[0].listing_at_array = [];
                        }
                        if (response.data.ncd[0].lead_managers !== undefined && response.data.ncd[0].lead_managers !== null && response.data.ncd[0].lead_managers.trim().length > 0) {
                            response.data.ncd[0].selectedItemsIds = response.data.ncd[0].lead_managers.split(",").map(item => parseInt(item.trim()));
                        } else {
                            response.data.ncd[0].selectedItemsIds = [];
                        }
                        setInitialValues(response.data.ncd[0]);
                    }
                    else {
                        setInitialValues((prevState) => ({
                            ...prevState,
                            display_order: response.data.ncdNewDisplayOrder ?? 0,
                        }));
                    }
                } else {
                    dispatch(setMessage(response.data.error));
                }

                dispatch(finishLoading());
            })
                .catch(error => {
                    console.error('Error:', error);
                    dispatch(finishLoading());
                });
        } else {
            didMountRef.current = "true";
        }
    }, []); // Run once on mount

    const handleSubmit = (values, { setSubmitting }) => {
        const trimmedValues = Object.keys(values).reduce((acc, key) => {
            acc[key] = typeof values[key] === 'string' ? values[key].trim() : (typeof values[key] === 'boolean' ? (values[key] === true ? 1 : 0) : values[key]);
            return acc;
        }, {});

        trimmedValues.lead_managers = trimmedValues.selectedItemsIds.join(',');
        trimmedValues.listing_at = trimmedValues.listing_at_array.join(', ');
        // Perform actions here, such as making an API request
        console.log('Form submitted with trimmed values:', trimmedValues);
        if (validate(trimmedValues)) {
            dispatch(startLoading());
            api.post('/admin-ncd-save', {
                values: trimmedValues
            }).then(response => {
                if (response.data.msg === 1) {
                    dispatch(setMessage(response.data.success));
                    navigate("/admintool9x/report/90");
                } else {
                    dispatch(setMessage(response.data.error));
                }
            }).catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                dispatch(finishLoading());

            });
            // Set submitting to false to allow the form to be submitted again
            setSubmitting(false);
        }
    }

    return (
        <>
            {!isAddMode && <NCDTabs idAtr={id} />}
            <br />
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="title" content={metaData.title} />
            </Helmet>

            <h1>
                {metaData.title}
            </h1>
            <hr />

            {result !== undefined &&
                result.ncd !== undefined &&
                result.ncd.length > 0 &&
                result.ncd[0].lock_status == 1 && (
                    <h3 className="text-danger">
                        Record Is Locked , you can't update it.
                    </h3>
                )
            }
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <NCDForm initialValues={initialValues} result={result} handleGoBack={handleGoBack} loading={loading} />
            </Formik>
        </>
    )
}
export default NCD;


